define("hmis/routes/hmis-app/records/patient/shared-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    appAjax: Ember.inject.service(),
    app: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);

      this.app.startLoading();
      let cacheLink = 'sharedDataOptions.pat' + model.id;
      this.appAjax.getCacheable('/patients/' + model.id + '/sharedData', null, cacheLink).then(rec => {
        controller.set('sharedDataOptions', rec);
        this.app.stopLoading();
      }).catch(err => {
        console.error(err);
        controller.set('pageError', err);
        this.app.stopLoading();
      });
    }

  });

  _exports.default = _default;
});
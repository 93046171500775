define("hmis/templates/components/hmis-side-menu-item-sub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KLUt75yh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[0,\"    \"],[5,\"link-to\",[],[[\"@route\"],[[29,[\"hmis-app.\",[24,[\"module\",\"route\"]]]]]],{\"statements\":[[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"hmis-side-menu-item-sub \",[28,\"if\",[[24,[\"isActive\"]],\"active\",\"\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[8],[0,\"\\n            \"],[1,[28,\"svg-jar\",[\"circle-fill\"],[[\"class\",\"width\",\"height\"],[\"icon trans-white-fill\",\"10px\",\"10px\"]]],false],[0,\" \\n            \"],[7,\"p\",true],[10,\"class\",\"side-menu-item-sub-text\"],[8],[1,[24,[\"module\",\"name\"]],false],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/hmis-side-menu-item-sub.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/questionnaire/q-edit-item-input", ["exports", "hmis/utils/questionnaire"], function (_exports, _questionnaire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['q-edit-item-input'],
    searchField: null,
    type: Ember.computed('item.type', function () {
      let type = this.get('item.type');
      if (type == 'integer' || type == 'decimal') return 'number';
      return 'text';
    }),
    value: Ember.computed('valueSet.valueChanged', 'chng', function () {
      let val = this.get('valueSet') ? this.get('valueSet')[this.get('item.link')] : '';
      return val;
    }),
    actions: {
      valueChanged(value) {
        let valueSet = this.get('valueSet');
        valueSet[this.item.link] = value;

        if (this.get('item.iniValues')) {
          this.get('item.iniValues').forEach(element => {
            if ((0, _questionnaire.pass)(element.value, element.operator, value)) {
              if (!valueSet[element.question]) valueSet[element.question] = element.answer;
            }
          });
        }

        if (this.get('onChange')) this.get('onChange')(value, this.get('item'));
        this.set('valueSet', valueSet);
        this.set('valueSet.valueChanged', !this.get('valueSet.valueChanged'));
        this.set('chng', !this.get('chng'));
      }

    }
  });

  _exports.default = _default;
});
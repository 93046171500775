define("hmis/templates/components/controls/allergy-input-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FOnRlczw",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[28,\"input\",null,[[\"type\",\"value\",\"onChange\",\"class\"],[\"text\",[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"onTextChanged\"],null],\"searchbox allergy-input\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/allergy-input-text.hbs"
    }
  });

  _exports.default = _default;
});
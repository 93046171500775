define("hmis/components/viewers/visit-transaction-viewer", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/ref-component", "hmis/utils/general-util"], function (_exports, _modelTableTheme, _refComponent, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    confirmText: 'Confirm',
    app: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    isConfirm: true,
    filter: 'all',

    show(visit, onConfirm) {
      if (visit != this.visit) {
        this.set('visit', visit);
        this.send('changeFilter', 'all');
        this.set('modalWaiting', true);
        this.appAjax.ajaxGet('visits', {
          filter: {
            id: visit.id
          },
          include: ['outstandingBills', 'patientName']
        }).then(res => {
          this.set('visit', res[0]);
          this.set('modalWaiting', false);
        }); //if error dont stop loading to prevent confirm from working on error
      }

      this.set('onConfirm', onConfirm);
      this.set('_show', true);
    },

    close() {
      this.set('_show', false);
    },

    actions: {
      changeFilter(filter) {
        this.set('filter', filter);
        this.set('transactionsQuery', this.app.query('transaction', {
          filter: (0, _generalUtil.trimNullProps)({
            visitId: this.visit.id,
            isInsurance: 0,
            billableType: this.filter == 'all' ? null : this.filter
          })
        }));
      },

      onConfirm() {
        this.onConfirm && this.onConfirm(this.visit);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "propertyName": "index",
      "component": "controls/table-index"
    }, {
      "title": "Date of transaction",
      "propertyName": "dateF"
    }, {
      "title": "description",
      "propertyName": "billableItemName"
    }, {
      'title': 'Cost',
      "propertyName": "nAmount"
    }, {
      "propertyName": "paymentStatus"
    }]
  });

  _exports.default = _default;
});
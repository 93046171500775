define("hmis/components/controls/sticky-table/table", ["exports", "hmis/components/models-table/table"], function (_exports, _table) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import AfterRender from '../../mixins/after-render'
  var _default = _table.default.extend({// init() {
    //     this._super(...arguments);
    //     if(!this.get('indexOnEdit'))
    //     {
    //         this.set('indexOnEdit', -1)
    //     }
    // },
    // afterRenderEvent: function() {
    //   $(document).scroll(function () {
    //       var y = $(this).scrollTop();
    //       if (y > 40) {
    //           $('.bottomMenu').fadeIn();
    //       } else {
    //           $('.bottomMenu').fadeOut();
    //       }
    //
    //   });
    // }
  });

  _exports.default = _default;
});
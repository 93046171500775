define("hmis/templates/components/viewers/reports/insurance-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pvoZeO6C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"style\",\"margin: 10px 30px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"container_insurance\"],[10,\"style\",\"width: 100%;height: 100%\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"flash\"],[10,\"style\",\"color: #b1b1b1;margin-left: 20px;font-weight: bold\"],[8],[0,\"Report Loading...\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/viewers/reports/insurance-report.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/pickers/input-picker", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  var _default = Ember.Component.extend({
    tagName: '',
    label: 'Choose record',
    actions: {
      chooseConfirm(record) {
        this.send('setRecord', record);
        this.set('findRecord', false);
      },

      onAutocompleteChange(record) {
        this.send('setRecord', record);
      },

      setRecord(record) {
        if (this.selectedChange) this.selectedChange(record);else this.set('selected', record);
      }

    },
    columns: [{
      'propertyName': 'name'
    }]
  });

  _exports.default = _default;
});
define("hmis/mixins/model-access-right", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    facility: _emberData.default.attr(),
    currentUser: Ember.inject.service(),
    readOnly: Ember.computed('facility', 'currentUser', function () {
      return this.get('currentUser.facilityId') != this.get('facility');
    }),
    hasFacilityWriteAccess: Ember.computed('facility', 'currentUser', function () {
      return this.get('currentUser.facilityId') == this.get('facility');
    })
  });

  _exports.default = _default;
});
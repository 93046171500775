define("hmis/routes/hmis-app/admin-settings/scheduler/roster", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service('store'),
    appData: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        department: this.get('store').query('department', {}),
        schedule: this.get('store').query('facility-period', {}),
        doctor: this.appData.doctors
      });
    } // actions: {
    //   reloadModel: function() {
    //     this.refresh()
    //   }
    // }


  });

  _exports.default = _default;
});
define("hmis/utils/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = events;
  _exports.onClickOutside = onClickOutside;

  function events() {
    return true;
  }

  function onClickOutside(selector, callback) {
    const outsideClickListener = event => {
      // if (!$(selector)[0].contains(event.target) ){
      //   if(callback){
      //     callback();
      //   }
      //   removeClickListener();
      // }
      if (!$(event.target).closest(selector).length) {
        removeClickListener();

        if (callback) {
          callback();
        }
      }
    };

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener);
    }; // event.preventDefault()
    // event.stopPropagation()


    document.addEventListener('click', outsideClickListener);
    event.preventDefault();
    event.stopPropagation();
  }
});
define("hmis/authenticators/oauth2", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "hmis/config/environment", "hmis/utils/server-util"], function (_exports, _oauth2PasswordGrant, _environment, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    serverTokenEndpoint: Ember.computed(function () {
      return _serverUtil.serverUrl + "/login"; //---deployment
      // return "/login" //----test
    }),
    refreshAccessTokens: true
  });

  _exports.default = _default;
});
define("hmis/templates/components/forms/end-episode-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "okE3xa94",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"viewers/visit-summary-viewer-alert\",null,[[\"ref\",\"parent\"],[\"visitViewer\",[23,0,[]]]]],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/forms/end-episode-alert.hbs"
    }
  });

  _exports.default = _default;
});
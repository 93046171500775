define("hmis/routes/hmis-app/dashboard0", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    // session: Ember.inject.service('session'),
    // setupController: function () {
    //   // Ember.$(window).on('beforeunload', () => {
    //   //   // this.endChat();
    //   //   alert("The Window is closing!");
    //   // })l
    //
    //   window.addEventListener("beforeunload", function (event) {
    //     event.preventDefault();
    //     //event.returnValue = '';
    //     alert("The Window is closing!");
    //     this.get('session').invalidate();
    //   });
    //
    // },
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),

    model(params) {
      let oPerms = this.get('oPerms');
      return Ember.RSVP.hash({
        activeServes: oPerms.op14 && this.get('currentUser.accessLevel') == 2 ? this.get('store').query('active-serve', {
          filter: {
            served: 0
          }
        }) : []
      });
    }

  });

  _exports.default = _default;
});
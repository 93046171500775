define("hmis/templates/components/stats-full-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U9jrA1h2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"full-stats-container\"],[8],[0,\" \\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[10,\"style\",\"overflow-y:auto\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"title\",\"class\"],[[24,[\"title\"]],\"full-width\"]],{\"statements\":[[0,\"\\n           \"],[7,\"div\",true],[10,\"class\",\"full-width\"],[8],[0,\" \"],[14,1],[9],[0,\"\\n        \\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/stats-full-container.hbs"
    }
  });

  _exports.default = _default;
});
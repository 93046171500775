define("hmis/controllers/hmis-app/claims/index", ["exports", "hmis/utils/model-table-theme", "ember-data", "hmis/utils/general-util", "hmis/mixins/app-event-mixin", "hmis/mixins/page-error-handler"], function (_exports, _modelTableTheme, _emberData, _generalUtil, _appEventMixin, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_appEventMixin.default, _pageErrorHandler.default, {
    routing: Ember.inject.service('router'),
    selectedItems: [],
    claimLoader: false,
    loadedClaim: false,
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    tabIndex: 0,
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    years: Ember.computed('model.records', function () {
      let ar = [];
      let i = 0;

      for (i; i <= 20; i++) {
        var d = new Date();
        let yr = d.getFullYear() + i;
        ar.pushObject(yr);
      }

      return ar;
    }),
    allowSelect: true,
    data: Ember.computed.alias('model'),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    isOneItemSelected: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length == 1;
    }),
    patients: Ember.computed('model.records', function () {
      console.log('patients came in', this.get('model.records'));
      return this.get('model.records');
    }),
    selectedCount: Ember.computed("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    fromCTDate: Ember.computed(function () {
      return new Date();
    }),
    toCTDate: Ember.computed(function () {
      return new Date();
    }),
    fromCVDate: Ember.computed(function () {
      return new Date();
    }),
    toCVDate: Ember.computed(function () {
      return new Date();
    }),
    isToday: true,
    isTodayCV: true,

    // CTModel:computed('fromCTDate', 'toCTDate', 'isToday', 'isThisMonth', 'isDateRange', function(){
    //   let fromDate = this.get('fromCTDate')
    //   let toDate = this.get('toCTDate')
    //   return DS.PromiseArray.create({
    //     promise: this.get('store').query('transaction',{
    //        filter: {
    //            isInsurance: 1,
    //            dateRange:{
    //                fromDate:fromDate.toISOString().slice(0, 19).replace('T', ' '),
    //                toDate:toDate.toISOString().slice(0, 19).replace('T', ' ')
    //            }
    //        },
    //      })
    //      .then(function(qp){
    //          return qp
    //      })
    //   });
    // }),
    // CVModel:computed('fromCVDate', 'toCVDate', 'isTodayCV', 'isThisMonthCV', 'isDateRangeCV', function(){
    //   let fromDate = this.get('fromCVDate')
    //   let toDate = this.get('toCVDate')
    //   return DS.PromiseArray.create({
    //     promise: this.get('store').query('visit',{
    //        filter: {
    //            dateRange:{
    //                fromDate:fromDate.toISOString().slice(0, 19).replace('T', ' '),
    //                toDate:toDate.toISOString().slice(0, 19).replace('T', ' ')
    //            },
    //            hasClaim:true,
    //        },
    //    })
    //    .then(function(qp){
    //        return qp
    //    })
    //   });
    // }),
    init() {
      this._super(...arguments);

      var fd = new Date();
      fd.setHours(0, 0, 0, 0);
      var td = new Date();
      td.setHours(24, 0, 0, 0);
      this.send('filterCTNow', fd, td);
      this.send('filterCVNow', fd, td); // this.send('filterBVisits', vDate, true)
      // this.send('filterBTrans', tDate, true)
    },

    actions: {
      filterBVisits(selectedDates, force = false) {
        if (this.visitsSelectedDates === selectedDates && !force) return;
        this.set('visitsSelectedDates', selectedDates);
        let filter = selectedDates ? {
          hasClaim: 1,
          dateRange: {
            fromDate: selectedDates.fromDate.toISOString().slice(0, 19).replace('T', ' '),
            toDate: selectedDates.toDate.toISOString().slice(0, 19).replace('T', ' ')
          }
        } : {
          hasClaim: 1
        };
        (0, _generalUtil.promiseSet)(this, 'BVisitsModel', this.store.query('visit', {
          filter: filter,
          include: ['patientName', 'purposeName', 'statusName'],
          pageSize: 25
        }), 'BVisitsWaiting');
      },

      filterBTrans(selectedDates, force = false) {
        if (this.transSelectedDates === selectedDates && !force) return;
        this.set('transSelectedDates', selectedDates);
        let filter = selectedDates ? {
          isInsurance: 1,
          dateRange: {
            fromDate: selectedDates.fromDate.toISOString().slice(0, 19).replace('T', ' '),
            toDate: selectedDates.toDate.toISOString().slice(0, 19).replace('T', ' ')
          }
        } : {
          isInsurance: 1
        };
        (0, _generalUtil.promiseSet)(this, 'BTransModel', this.store.query('transaction', {
          filter: filter,
          include: ['patientName'],
          pageSize: 25
        }), 'BTransWaiting');
      },

      rowBVisitsClick(index, visit) {
        let rid = visit.get('id');
        this.get("routing").transitionTo("hmis-app.claims.claim", rid);
      },

      rowBTransClick(index, record) {
        this.set('transactRecord', record);
        this.set('billModal', true);
      },

      filterCTNow(fromDate, toDate) {
        this.set('fromCTDate', fromDate);
        this.set('toCTDate', toDate);
      },

      filterCVNow(fromDate, toDate) {
        this.set('fromCVDate', fromDate);
        this.set('toCVDate', toDate);
      },

      rowCTClick(index, record) {
        this.set('transactRecord', record);
        this.set('billModal', true);
      },

      rowCVClick(index, visit) {
        let rid = visit.get('id');
        this.get("routing").transitionTo("hmis-app.claims.claim", [rid]);
      },

      submitClaims() {
        this.set('claimsModal', true);
      },

      updateClaims() {
        this.set('claimsUpdateModal', true);
      },

      genClaim() {
        let date = new Date(this.get('startDate'));
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.set('claimLoader', true);
        this.set('loadedClaim', false);
        this.get('ajaxRequest').submitClaim(this.get('currentUser.facilityId'), moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD"), 'json', {
          success: response => {
            this.set('show', true);
            this.set('msg', response);
            let resp = JSON.parse(response);
            this.set('submsg', resp.Status_Msg);
            console.log('json:', resp);
            console.log('json:', resp.Status_Msg);
          },
          error: error => {
            this.notify.error('Sorry! Something went wrong');
          }
        });
        setTimeout(() => {
          this.set('claimLoader', false);
          this.set('loadedClaim', true);
        }, 4000);
      },

      updateClaimStatus() {
        console.log('mine');
        let that = this;
        let date = new Date(this.get('startDate'));
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.set('claimLoader', true);
        this.set('loadedClaim', false);
        that.get('ajaxRequest').updateSubmittedClaimStatus(moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD"), {
          success: response => {
            this.set('show', true);
            this.set('msg', response); // let resp = JSON.parse(response)
            // this.set('submsg',resp.Status_Msg)

            console.log('res:', response); //console.log('json:',resp.Status_Msg)
          },
          error: error => {
            this.notify.error('Sorry! Something went wrong');
          }
        });
        setTimeout(() => {
          this.set('claimLoader', false);
          this.set('loadedClaim', true);
        }, 4000);
      },

      downloadGenClaim() {
        let date = new Date(this.get('startDate'));
        let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        let lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        this.get('ajaxRequest').xmlDownloadClaim(this.get('currentUser.facilityId'), moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD"), 'xml');
      },

      onGenClick() {
        this.get('submitForm')();
      },

      onUpdateClick() {
        this.get('submitForm')();
      },

      onConfirm() {
        console.log('main');
        this.set('claimLoader', true);
      },

      clickTest() {
        alert('testing mic'); // this.get("routing").transitionTo("hmis-app.claims.claim");
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    // claimAlertProps:[
    //   {title:'Date of claim', name: 'dateF'},
    //   {title: 'Patient', name: 'patientName'},
    //   {title: 'Trasaction Type', name: 'billableType'},
    //   {title:'Claim for', name: 'billableItemName'},
    //   {title: 'Amount', name: 'amountF'},
    // ],
    // visitsColumns: [
    //   {
    //     'title':'Transaction time',
    //     "propertyName": "dateF",
    //     className:'main-column'
    //   },
    //   {
    //     title: 'Transaction type',
    //     propertyName: 'billableType'
    //   },
    //   {
    //     title: 'Transaction item',
    //     propertyName: 'billableItemName'
    //   },
    //   {
    //     title:'Patient',
    //     propertyName: "patientName"
    //   },
    //   {
    //     "propertyName": "paymentStatus"
    //   },
    //   {
    //     title:'Submitted',
    //     propertyName: "submittedStatus"
    //   }
    // ],
    // transColumns: [
    //   {
    //     'title':'Date of visit',
    //     "propertyName": "dateF",
    //     'className':'main-column'
    //   },
    //   {
    //     'title': 'Patient',
    //     'propertyName': 'patient.fullName'
    //   },
    //   {
    //     'title': 'Purpose of visit',
    //     'propertyName': 'purposeName'
    //   },
    //   {
    //     "title": "Visit status",
    //     "propertyName": "status.name",
    //   }//,
    //   // {
    //   //   title:'Submitted',
    //   //   propertyName: "submittedStatus"
    //   // }
    // ],
    visitsColumns: [{
      'title': 'Date of visit',
      "propertyName": "dateF",
      'className': 'main-column'
    }, {
      'title': 'Patient',
      'propertyName': 'patientName'
    }, {
      'title': 'Purpose of visit',
      'propertyName': 'purposeName'
    }, {
      "title": "Visit status",
      "propertyName": "statusName"
    }],
    transColumns: [{
      'title': 'Transaction time',
      "propertyName": "dateF",
      'className': 'main-column'
    }, {
      'title': 'Transaction type',
      'propertyName': 'billableType'
    }, {
      'title': 'Transaction item',
      'propertyName': 'billableItemName'
    }, {
      'title': 'Patient',
      'propertyName': 'patientName'
    }, {
      "propertyName": "paymentStatus"
    }],
    months: {
      1: "January",
      2: "February",
      3: "March",
      4: "April",
      5: "May",
      6: "June",
      17: "July",
      18: "August",
      9: "September",
      10: "October",
      11: "November",
      12: "December"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/forms/immunization-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VClJyql/",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"alerts/form-dialog\",null,[[\"title\",\"waiting\",\"show\",\"width\",\"height\",\"onConfirm\",\"onCancel\"],[[24,[\"alertTitle\"]],[24,[\"waiting\"]],[24,[\"show\"]],\"60%\",\"50%\",[28,\"action\",[[23,0,[]],\"onRecordSubmit\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"pickers/input-picker\",null,[[\"label\",\"required\",\"textField\",\"data\",\"columns\",\"selected\",\"class\",\"onConfirm\"],[\"Immunization\",true,\"name\",[24,[\"immunList\"]],[24,[\"immunColumns\"]],[24,[\"record\",\"type\"]],[24,[\"input_width\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"type\"]]],null]],null]]]],false],[0,\"\\n\"],[0,\"    \"],[1,[28,\"paper-input\",null,[[\"required\",\"label\",\"type\",\"class\",\"value\",\"onChange\"],[true,\"Vaccine\",\"text\",[24,[\"input_width\"]],[24,[\"record\",\"vaccine\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"vaccine\"]]],null]],null]]]],false],[0,\"\\n    \"],[4,\"app-select\",null,[[\"class\",\"searchEnabled\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"input_width\"]],true,\"Status\",[24,[\"record\",\"status\"]],[24,[\"immuStatus\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"status\"]]],null]],null]]],{\"statements\":[[0,\" \"],[1,[23,1,[]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[1,[28,\"paper-input\",null,[[\"required\",\"label\",\"type\",\"class\",\"value\",\"onChange\"],[true,\"Date\",\"date\",[24,[\"input_width\"]],[24,[\"record\",\"date\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"date\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/forms/immunization-alert.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/patient/family", ["exports", "hmis/utils/date-util", "hmis/utils/model-table-theme", "hmis/mixins/page-error-handler"], function (_exports, _dateUtil, _modelTableTheme, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pageErrorHandler.default, {
    notInSelection: Ember.computed("model", function () {
      console.log(this.get('model'));
    }),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    date: Ember.computed(function () {
      let timeDate = new Date();
      let date = new Date();
      date.setHours(timeDate.getHours());
      date.setMinutes(timeDate.getMinutes());
      date.setSeconds(timeDate.getSeconds());
      date = _dateUtil.default.toSqlDate(date);
      return date;
    }),
    relationshipTypes: Ember.computed(function () {
      return this.get('store').findAll('relationship-type');
    }),
    patientsData: Ember.computed(function () {
      return this.get('store').findAll('patient');
    }),
    record: {},
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      newRelative() {
        this.set('record', {
          patient: this.model.patient
        });
        this.relativeForm.validateForm(() => {
          this.get('store').createRecord('patient-relation', this.record).save().then(res => {
            this.notify.success('Saved');
            this.relativeForm.close();
            this.send('refreshModel');
          }).catch(err => {
            this.notify.error(err);
          });
        });
      },

      updateRelative(record) {
        event.preventDefault();
        event.stopPropagation();
        console.log(record);
        this.set('record', record);
        this.relativeForm.validateForm(() => {
          record.save().then(res => {
            this.relativeForm.close();
            this.notify.success('Updated');
            this.send('refreshModel');
          }).catch(err => {
            this.notify.error(err);
          });
        });
      },

      removeRelative(record) {
        event.preventDefault();
        event.stopPropagation();
        this.notify.confirmSimple("Deleting relation: " + record.relatedToName, null, () => {
          record.destroyRecord();
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/
        ,
        messages: {
          "noDataToShow": "No documents uploaded"
        }
      });
    }),
    columns: [{
      title: 'Relative name',
      "propertyName": "name"
    }, {
      title: 'Relation type',
      "propertyName": "type"
    }],
    patientsPickColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      title: 'Patient name',
      "propertyName": "fullName"
    }, {
      "propertyName": "phoneNumber"
    }]
  });

  _exports.default = _default;
});
define("hmis/models/referral", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/visit-service-model"], function (_exports, _emberData, _relationships, _dateUtil, _visitServiceModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, {
    typeId: _emberData.default.attr(),
    note: _emberData.default.attr()
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/access-m-user-m-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "//lS6MiP",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"accordion\"],[11,\"id\",[29,[\"accordion_\",[24,[\"userModulePermission\",\"id\"]]]]],[8],[0,\"\\n        \"],[1,[28,\"controls/access-m-module-permission\",null,[[\"permitted\",\"modulePermission\",\"removeMode\",\"lockClick\",\"removeClick\"],[[24,[\"userModulePermission\",\"allow\"]],[24,[\"modulePermission\"]],true,[28,\"action\",[[23,0,[]],\"onLock\"],null],[28,\"action\",[[23,0,[]],\"onRemove\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/access-m-user-m-permission.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/paged-report-filter-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KWvri+cd",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[0,\"\\n\"],[4,\"app-select\",null,[[\"class\",\"options\",\"selected\",\"label\",\"searchEnabled\",\"searchField\",\"search\",\"onChange\"],[\"rc-control\",[28,\"if\",[[24,[\"filterItem\",\"references\"]],[24,[\"options\"]],[24,[\"linkOptions\"]]],null],[28,\"or\",[[28,\"get\",[[24,[\"filterValues\",\"appSelections\",\"filters\"]],[24,[\"filterItem\",\"key\"]]],null],[24,[\"allSelection\"]]],null],[24,[\"filterItem\",\"label\"]],true,[24,[\"filterItem\",\"referenceNameProperty\"]],[28,\"if\",[[24,[\"filterItem\",\"referencesLink\"]],[28,\"action\",[[23,0,[]],\"searchLink\"],null],null],null],[28,\"action\",[[23,0,[]],\"onChange\"],null]]],{\"statements\":[[0,\" \"],[1,[28,\"get\",[[23,1,[]],[24,[\"filterItem\",\"referenceNameProperty\"]]],null],false],[0,\" \"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/paged-report-filter-select.hbs"
    }
  });

  _exports.default = _default;
});
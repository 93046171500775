define("hmis/templates/components/controls/row-file-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "il8iqttC",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"file-preview\",null,[[\"thumbnail\",\"title\",\"link\",\"isServerUpload\"],[[24,[\"thumbnail\"]],[28,\"get\",[[24,[\"record\"]],[24,[\"nameProperty\"]]],null],[28,\"get\",[[24,[\"record\"]],[24,[\"linkProperty\"]]],null],[24,[\"isServerUpload\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/row-file-preview.hbs"
    }
  });

  _exports.default = _default;
});
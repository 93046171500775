define("hmis/models/vitals-facility-defaults", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    facility: (0, _relationships.belongsTo)('facility'),
    vitalType: (0, _relationships.belongsTo)('vital-type'),
    unitOfMeasure: (0, _relationships.belongsTo)('vitals-unit-of-measure')
  });

  _exports.default = _default;
});
define("hmis/templates/page-not-found", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1HfOiAmA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"page-not-found-page\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"not-found\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"broken-heart-bleeding\"],[[\"class\",\"width\",\"height\"],[\"icon\",\"120px\",\"120px\"]]],false],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"four-o-four\"],[8],[0,\"404\"],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"info\"],[8],[0,\"The Page you are looking for does not exist\"],[9],[0,\"\\n    \"],[7,\"button\",true],[10,\"class\",\"home-button\"],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"info\",\"hmis-app\"]],{\"statements\":[[0,\"        Back Home!\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/page-not-found.hbs"
    }
  });

  _exports.default = _default;
});
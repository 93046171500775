define("hmis/templates/components/pickers/list-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vX4Miljk",
    "block": "{\"symbols\":[\"lv\"],\"statements\":[[4,\"alerts/confirm-dialog\",null,[[\"show\",\"isStatsContainer\",\"tag\",\"waiting\",\"footerInfo\",\"onConfirm\",\"onCancel\",\"width\",\"height\",\"closeOnConfirm\",\"cancelText\",\"confirmText\",\"confirmDisabled\"],[[24,[\"show\"]],true,[24,[\"selectedPatient\"]],[24,[\"waiting\"]],[28,\"concat\",[[24,[\"selectedItems\",\"length\"]],\" selected\"],null],[28,\"action\",[[23,0,[]],\"onConfirm\"],null],[24,[\"onCancel\"]],[24,[\"width\"]],[24,[\"height\"]],[24,[\"closeOnConfirm\"]],[24,[\"cancelText\"]],[24,[\"confirmText\"]],[28,\"if\",[[24,[\"selectedItems\",\"length\"]],false,true],null]]],{\"statements\":[[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\",\"message\",\"info\"],[\"full-height\",true,[24,[\"title\"]],[24,[\"message\"]],[24,[\"info\"]]]],{\"statements\":[[0,\"     \"],[7,\"div\",true],[10,\"class\",\"app-fixed-top\"],[8],[0,\"\\n       \"],[7,\"p\",true],[8],[1,[22,\"closeOnConfirm\"],false],[9],[0,\"\\n\"],[0,\"         \"],[1,[28,\"input\",null,[[\"class\",\"placeholder\",\"value\",\"placeholder\"],[\"searchbox\",\"Search\",[24,[\"query\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n     \"],[9],[0,\"\\n     \"],[7,\"div\",true],[10,\"class\",\"content content-list\"],[8],[0,\"\\n\"],[4,\"controls/list-view\",null,[[\"showSearch\",\"data\",\"multiSelect\",\"query\",\"selectable\",\"selectedItems\",\"filterParams\",\"addable\",\"main\",\"sub\",\"hideEdit\",\"hideView\",\"hideDelete\"],[false,[24,[\"data\"]],[24,[\"multiSelect\"]],[24,[\"query\"]],true,[24,[\"selectedItems\"]],[24,[\"filterParams\"]],false,[24,[\"main\"]],[24,[\"sub\"]],true,true,true]],{\"statements\":[],\"parameters\":[1]},null],[0,\"     \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/pickers/list-picker.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/managers/vitals-type-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "83DKNRBR",
    "block": "{\"symbols\":[\"item\",\"item\"],\"statements\":[[2,\"<div class=\\\"layout-row\\\">\"],[0,\"\\n  \"],[2,\"<div class=\\\"layout-column flex-40\\\">\"],[0,\"\\n\"],[0,\"  \"],[2,\"</div>\"],[0,\"\\n\"],[2,\"</div>\"],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-75\"],[10,\"style\",\"margin: 0 20px\"],[8],[0,\"\\n\"],[4,\"app-select\",null,[[\"class\",\"label\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"flex_level\"]],\"Vital Type\",\"id\",[24,[\"record\",\"vitalType\"]],[24,[\"record\",\"vitaltypes\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"vitalType\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,2,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-20\"],[8],[0,\"\\n\"],[4,\"app-select\",null,[[\"class\",\"label\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"flex_level\"]],\"Unit of Measure\",\"id\",[24,[\"record\",\"unitOfMeasure\"]],[24,[\"record\",\"units\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"unitOfMeasure\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/vitals-type-manager.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/access-manage-table-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ival: false,
    value: Ember.computed('{record.view,record.edit,record.add,record.inactivate}', function () {
      let view = this.get('record.view');
      let edit = this.get('record.edit');
      let add = this.get('record.add');
      let del = this.get('record.inactivate');
      if (view && edit && add && del) return true;else return false;
    }),
    actions: {
      onChange() {
        if (this.get('value')) {
          this.set('record.view', false);
          this.set('record.edit', false);
          this.set('record.add', false);
          this.set('record.inactivate', false); // this.set('ival',false)
        } else {
          this.set('record.view', true);
          this.set('record.edit', true);
          this.set('record.add', true);
          this.set('record.inactivate', true); // this.set('ival', true)
        }
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/type-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M4KFoTgL",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"full-width\"],[8],[0,\"\\n\"],[4,\"form-container\",null,[[\"class\"],[\"full-width\"]],{\"statements\":[[0,\"        \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[\"flex-45 flex-xs-100 flex-sm-100\",[24,[\"record\",\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"name\"]]],null]],null],\"Name\",\"person-outline\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/type-manager.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/paged-report-table", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['report-table'],
    reports: Ember.inject.service(),
    reportDataType: 'count',
    // reportDataRows:computed('serverReport' , 'columnReferenceName', 'rowReferenceName', 'otherFilters.@each.filter', 'selectedRowsArray.length', 'selectedColumnsArray.length', 'reportDataType','showNoDataRowsColumns', function() {
    //   return DS.PromiseObject.create({
    //        promise: new Promise((resolve, reject)=>{
    //            setTimeout(()=> {
    //              let serverReport =this.get('serverReport');
    //              let columnReferenceName=this.get('columnReferenceName');
    //              let rowReferenceName=this.get('rowReferenceName');
    //              let selectedRowsArray=this.get('selectedRowsArray');
    //              let selectedColumnsArray=this.get('selectedColumnsArray');
    //              let showNoDataRowsColumns=this.get('showNoDataRowsColumns');
    //              // console.log('selectedRowsArray', selectedRowsArray);
    //              // console.log('selectedColumnsArray', selectedColumnsArray);
    //              let otherFilters=this.get('otherFilters');
    //              if(!serverReport)
    //                return [];
    //              let formatedData= this.get('reports').serverReportToTableData(serverReport, columnReferenceName, rowReferenceName,otherFilters, selectedRowsArray, selectedColumnsArray, this.get('reportDataType'), showNoDataRowsColumns)
    //               resolve(formatedData);
    //            }, 1);
    //        })
    //    });
    // }),
    showTotalColumn: Ember.computed('dataReport.defaults.totalColumn', function () {
      return this.get('dataReport.defaults.totalColumn');
    }),
    showPercentage: Ember.computed('serverReport', function () {
      return this.get('serverReport.defaults.percentage');
    }) // columnsCount: computed('reportDataRows.isFulfilled', 'showTotalColumn', 'showPercentage', function() {
    //   let displayColumns = this.get('reportDataRows.displayColumns')
    //   let showTotalColumn = this.get('showTotalColumn')
    //   let showPercentage = this.get('showPercentage')
    //   let count = 0;
    //   count+=displayColumns?Number(displayColumns.length):0;
    //   count+=showTotalColumn?1:0;
    //   count+=showPercentage?1:0;
    //   return count;
    // }),

  });

  _exports.default = _default;
});
define("hmis/templates/hmis-app/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ALuZMdTZ",
    "block": "{\"symbols\":[\"lv\",\"item\"],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height\",true,\"Notification\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"app-fixed-top\"],[8],[0,\"\\n      \"],[1,[28,\"input\",null,[[\"class\",\"placeholder\",\"value\",\"placeholder\"],[\"searchbox\",\"Search\",[24,[\"query\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content content-list\"],[8],[0,\"\\n\"],[4,\"controls/list-view\",null,[[\"class\",\"showSearch\",\"data\",\"query\",\"filterParams\",\"main\",\"sub\",\"onDelete\",\"hideView\",\"hideEdit\"],[\"full-width\",false,[24,[\"currentUser\",\"notifications\"]],[24,[\"query\"]],[28,\"array\",[\"message\",\"description\"],null],\"message\",\"description\",[28,\"action\",[[23,0,[]],\"deleteRecord\"],null],true,true]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"moreContent\"]],\"expected `lv.moreContent` to be a contextual component but found a string. Did you mean `(component lv.moreContent)`? ('hmis/templates/hmis-app/notifications.hbs' @ L20:C9) \"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"primary-color\"],[8],[1,[28,\"moment-format\",[[23,2,[\"created_at\"]],\"h:mm:ss a\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/notifications.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/questionnaire/q-generator", ["exports", "hmis/utils/questionnaire"], function (_exports, _questionnaire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['q-generator', 'flex-fill', 'd-flex', 'overflow-y-auto'],

    didInsertElement() {
      this._super(...arguments);

      (0, _questionnaire.getQuestionsMap)(this.questionsMap, this.record);
    },

    questionsMap: [],
    actions: {
      onValueChange(value, prop) {
        this.send('onChange');
      },

      addQuestionBefore(item, index, parentItem) {
        let nIndex = index > 0 ? index : 0;
        let newItem = (0, _questionnaire.newQuestion)(parentItem);
        Ember.get(parentItem, 'items').insertAt(nIndex, newItem);
        this.send('onQuestionAdded', newItem, nIndex, parentItem);
      },

      addQuestionAfter(item, index, parentItem) {
        let newItem = (0, _questionnaire.newQuestion)(parentItem);
        Ember.get(parentItem, 'items').insertAt(Number(index) + 1, newItem);
        this.send('onQuestionAdded', newItem, Number(index) + 1, parentItem);
      },

      addSubQuestion(item, index, parentItem) {
        if (!Ember.get(item, 'items')) Ember.set(item, 'items', []);
        let newItem = (0, _questionnaire.newQuestion)(item);
        Ember.get(item, 'items').pushObject(newItem);
        this.send('onQuestionAdded', newItem, 0, item);
      },

      deleteQuestion(item, index, parentItem) {
        if (this.currentItem && this.currentItem.link == item.link) this.set('currentItem', null);
        Ember.get(parentItem, 'items').removeAt(index);
        this.send('onQuestionRemoved', item, index, parentItem);
      },

      onItemChange(itemComponent) {
        this.send('onChange');
      },

      onQuestionAdded(item, index, parentItem) {
        let questsMap = this.questionsMap;
        questsMap[item.link];
        Ember.set(this.questionsMap, item.link, item);
        this.send('onChange');
      },

      onQuestionRemoved(item, index, parentItem) {
        let questsMap = this.questionsMap;
        delete questsMap[item.link];
        this.set('questionsMap', questsMap);
        this.send('onChange');
      },

      onChange() {
        if (this.get('onChange')) this.get('onChange')();
      },

      onItemClick(item) {
        console.log('item', item);
      }

    }
  });

  _exports.default = _default;
});
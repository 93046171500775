define("hmis/controllers/hmis-app/admin-settings/facility-types/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    query: '',
    actions: {
      newRecord() {
        this.transitionToRoute("hmis-app.admin-settings.facility-types.add");
      },

      deleteRecord(record) {
        // this.set("allowSelect",false)
        this.get("model").removeObject(record);
        record.destroyRecord();
      },

      editRecord(record) {
        // alert(record.id);
        this.get("routing").transitionTo("hmis-app.admin-settings.facility-types.edit", [record.id]);
      },

      rowDoubleClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.facility-types.view", [rid]);
      },

      deleteRecords() {
        this.get("selectedItems").forEach((user, index) => {
          user.destroyRecord();
        });
        this.set("selectedItems", []);
      }

    }
  });

  _exports.default = _default;
});
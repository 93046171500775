define("hmis/templates/components/controls/clock-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GvMLEP+D",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"label\",true],[11,\"for\",[22,\"inputElementId\"]],[11,\"class\",[29,[[28,\"if\",[[24,[\"required\"]],\"md-required\"],null]]]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"input-group clockpicker\"],[11,\"style\",[29,[\"width:\",[22,\"width\"],\";\"]]],[8],[0,\"\\n\"],[0,\"  \"],[1,[28,\"paper-input\",null,[[\"type\",\"required\",\"class\",\"value\",\"onChange\"],[\"time\",[24,[\"required\"]],\"form-control cInput\",[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"onTimeChange\"],null]]]],false],[0,\"\\n\"],[0,\"    \"],[7,\"span\",true],[10,\"class\",\"input-group-addon \"],[8],[0,\"\\n        \"],[7,\"span\",true],[10,\"class\",\"glyphicon glyphicon-time\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/clock-picker.hbs"
    }
  });

  _exports.default = _default;
});
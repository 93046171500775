define("hmis/routes/configure", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'sign-in',
    session: Ember.inject.service(),

    beforeModel()
    /* transition */
    {
      let setupUser = this.get('session.data.authenticated.setup_user');
      console.log('Setup code', setupUser);
      if (setupUser == 0) this.transitionTo('hmis-app'); // Implicitly aborts the on-going transition.
    }

  });

  _exports.default = _default;
});
define("hmis/templates/components/stats-alert-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DMHWRL6W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"stats-alert \",[28,\"if\",[[24,[\"show\"]],\"show-alert\",\"hide-alert\"],null]]]],[8],[0,\"\\n\\n     \"],[7,\"div\",true],[10,\"class\",\"alert-container\"],[8],[0,\"\\n         \"],[7,\"div\",true],[10,\"class\",\"alert-title\"],[8],[0,\"\\n            \"],[1,[28,\"svg-jar\",[\"info\"],[[\"class\",\"width\"],[\"side-menu-item-icon icon primary-fill\",\"24px\"]]],false],[0,\" \"],[7,\"p\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n         \"],[9],[0,\"\\n         \"],[7,\"div\",true],[10,\"class\",\"alert-content\"],[8],[0,\"\\n                \"],[7,\"p\",true],[10,\"class\",\"message\"],[8],[1,[22,\"message\"],false],[9],[0,\"\\n            \"],[14,1],[0,\"\\n         \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \\n    \"],[7,\"div\",true],[10,\"class\",\"alert-unfocus\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onUnfocusedClick\"],null]],[8],[0,\"\\n        \\n    \"],[9],[0,\"\\n\\n   \\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/stats-alert-panel.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/table-link", ["exports", "hmis/utils/general-util"], function (_exports, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { get } from '@ember/object';
  function concatWithProperty(strings, property) {
    if (property) {
      strings.push(property);
    }

    return strings.join(' ');
  }

  var _default = Ember.Component.extend({
    tagName: '',
    currentUser: Ember.inject.service(),
    viewers: Ember.inject.service(),
    hasPerm: Ember.computed(function () {
      let permissions = this.permissions || this.column.permissions;
      return permissions ? (0, _generalUtil.hasPermission)(permissions, this.currentUser.operationalPermissions, this.currentUser.modularPermissions) : true;
    }),
    text: Ember.computed(function () {
      let text = this.column.propertyName ? Ember.get(this.record, this.column.propertyName) : "";
      return this.column.formatText ? this.column.formatText(text, this.record) : text;
    }),
    _route: Ember.computed('route', 'column.route', function () {
      let route = this.route || this.column.route;
      return route;
    }),
    _viewer: Ember.computed('viewer', 'column.viewer', function () {
      let viewer = this.viewer || this.column.viewer;
      return viewer;
    }),
    _viewerOptions: Ember.computed('viewerOptions', 'column.viewerOptions', function () {
      let viewerOptions = this.viewerOptions || this.column.viewerOptions;
      return (0, _generalUtil.isFunction)(viewerOptions) ? viewerOptions(this.record, this.column) : viewerOptions;
    }),
    modelProp: Ember.computed('modelProperty', 'column.modelProperty', function () {
      let modelProperty = this.modelProperty || this.column.modelProperty;
      return modelProperty || 'id';
    }),
    _model: Ember.computed('modelProp', function () {
      let model = this.model || this.column.model;
      return model || Ember.get(this.record, this.modelProp);
    }),
    actions: {
      onLinkClick() {
        event.tableLink = true; // event.stopPropagation();
        // event.preventDefault();
      },

      onClick() {
        event.stopPropagation();
        event.preventDefault();
        event.tableLink = true;

        if (this._viewer) {
          if (this.viewers[this._viewer]) {
            let options = this._viewerOptions || {};
            options.isViewer = true;
            options.selectable = false;

            this.viewers[this._viewer].show(this._viewerOptions);
          } else console.error("no viewer found by name" + this._viewer);
        }

        let onClick = this.onClick || this.column.onClick;
        onClick && onClick(this.record);
      }

    }
  });

  _exports.default = _default;
});
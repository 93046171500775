define("hmis/controllers/hmis-app/admin-settings/insurance-companies/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    router: Ember.inject.service(),
    types: Ember.computed('model', function () {
      return this.get('store').findAll('insurance-type', {}).then(function (recs) {
        return recs;
      });
    }),
    actions: {
      back() {
        history.back();
      },

      save() {
        let that = this;
        let model = this.get('model'); //this.set('model',

        this.get('store').createRecord('insurance-company', {
          name: this.get('name'),
          type: this.get('type')
        }).save().then(record => {
          that.notify.success('Saved Successfully');
          this.send('reloadModel');
        }).catch(failure => {
          that.notify.error('Unable to Save' + failure);
        });
      },

      clickRecord(record) {
        console.log(record.id);
        let str = this.get('router.currentRouteName');
        let lastIndex = str.lastIndexOf(".");
        str = str.substring(0, lastIndex);
        console.log(str + '++'); //that.transitionToRoute("configure.department.manage");

        this.get("routing").transitionTo(str + ".product", [record.id]); //,[record.id]
        //this.get("routing").transitionTo("configure.department.manage",[record.id]);
        //this.get("routing").transitionTo("hmis-app.admin-settings.insurance-companies.add",[record.id]);
      },

      saveRecord(record) {
        record.save();
      },

      newRecord() {
        this.transitionToRoute("hmis-app.admin-settings.insurance-companies.add");
      },

      deleteRecord(record) {
        // this.set("allowSelect",false)
        this.get("model").removeObject(record);
        record.destroyRecord();
      },

      editRecord(record) {// alert(record.id);
        // this.get("routing").transitionTo("hmis-app.admin-settings.insurance-companies.edit",[record.id]);
      },

      rowDoubleClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id'); //this.get("routing").transitionTo("hmis-app.admin-settings.insurance-companies.view",[rid]);
      }

    }
  });

  _exports.default = _default;
});
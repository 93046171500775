define("hmis/templates/components/controls/row-options-opt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Zz7RE+SG",
    "block": "{\"symbols\":[],\"statements\":[[0,\"    \"],[7,\"button\",true],[11,\"class\",[22,\"class\"]],[11,\"disabled\",[22,\"disabled\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"optionClicked\",[24,[\"option\"]],false],null]],[8],[1,[24,[\"option\",\"label\"]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/row-options-opt.hbs"
    }
  });

  _exports.default = _default;
});
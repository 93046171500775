define("hmis/components/hmis-side-menu-item-sub", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // text=pchild.name  route=(concat "hmis-app." pchild.route)
    currentUser: Ember.inject.service(),
    isSubShowing: false,
    hasSub: false,
    currentPathChanged: Ember.observer('currentPathHook', function () {
      if (this.get('routingOff')) return; // console.log('route: '+this.get('route')+" || currentPathHook: "+this.get("currentPathHook"));

      let routePath = this.get('module.route'); // if(this.get('activeRoutePath'))
      //     routePath = this.get('activeRoutePath');

      if (this.get('currentPathHook').includes(routePath)) {
        this.set('isActive', true);
      } else {
        this.set('isActive', false);
      }
    }),
    show: Ember.computed('module', function () {
      if (!this.get('module.levels').includes(this.get('currentUser.accessLevel'))) {
        return false;
      }

      return this.get('currentUser.modularPermissions.' + this.get('module.perm') + '.allow');
    }),
    actions: {
      onClick(event) {
        if (this.get('onMenuClick')) {
          this.get('onMenuClick')(this.get('module.route'));
        }
      },

      onArrowClick(event) {
        this.set("isSubShowing", !this.get("isSubShowing"));
        event.stopPropagation();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/paged-report-filter-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    },

    appAjax: Ember.inject.service(),
    tagName: '',
    allSelection: Ember.computed(function () {
      let data = {
        id: 'null'
      };
      data[this.filterItem.referenceNameProperty] = "Show All";
      return data;
    }),
    selected: null,
    _linkOptions: [],
    options: Ember.computed(function () {
      return new Promise((resolve, reject) => {
        if (this.filterItem.references) {
          if (this.filterItem.references[0] && this.filterItem.references[0].id != this.allSelection.id) this.filterItem.references.insertAt(0, this.allSelection);
          resolve(this.filterItem.references);
        }
      });
    }),
    linkOptions: Ember.computed('_linkOptions', function () {
      if (!this._linkOptions.length) {
        return [this.allSelection];
      }

      return this._linkOptions;
    }),
    actions: {
      searchLink(term) {
        return this.appAjax.post("/paged-reports/references/".concat(this.reportType, "/").concat(this.filterItem.key), {
          search: term
        }).then(res => {
          res.push(this.allSelection);
          this.set('_linkOptions', res);
          return res;
        });
      },

      onChange(val) {
        Ember.set(this.filterValues.appSelections.filters, this.filterItem.key, val);
        Ember.set(this.filterValues.filters, this.filterItem.key, val.id);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/stack-pages/ins-man-billables", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    billables: [{
      name: 'medicines',
      text: 'Medicines',
      item: 'prescription',
      itemName: 'genericName',
      billableItemName: 'medicine'
    }, {
      name: 'investigations',
      text: 'Investigations',
      item: 'investigation',
      itemName: 'genericName',
      billableItemName: 'investigationType'
    }, {
      name: 'procedures',
      text: 'Procedures',
      item: 'procedure',
      itemName: 'genericName',
      billableItemName: 'procedureType'
    }, {
      name: 'xrays',
      text: 'X-Ray',
      item: 'xray',
      itemName: 'genericName',
      billableItemName: 'xrayType'
    }, {
      name: 'ultrasounds',
      text: 'Ultrasound',
      item: 'ultrasound',
      itemName: 'genericName',
      billableItemName: 'ultrasoundType'
    }, {
      name: 'otherBillables',
      text: 'Other billables',
      item: 'otherbillable',
      itemName: 'name',
      billableItemName: 'otherBillable'
    }, {
      name: 'diagnoses',
      text: 'Diagnosis',
      item: 'diagnosis',
      itemName: 'genericName',
      billableItemName: 'diagnosisType'
    }],
    // selectedBillable:{name: 'medicines', text:'Medicines', item:'prescription', itemName:'genericName' , billableItemName:'medicine'},
    newRecord: {},
    medicines: Ember.computed(function () {
      return this.get('store').query('medicine', {
        pageSize: 25
      });
    }),
    investigations: Ember.computed(function () {
      return this.get('store').query('investigation-type', {
        pageSize: 25
      });
    }),
    procedures: Ember.computed(function () {
      return this.get('store').query('procedure-type', {
        pageSize: 25
      });
    }),
    xrays: Ember.computed(function () {
      return this.get('store').query('xray-type', {
        pageSize: 25
      });
    }),
    ultrasounds: Ember.computed(function () {
      return this.get('store').query('ultrasound-type', {
        pageSize: 25
      });
    }),
    otherBillables: Ember.computed(function () {
      return this.get('store').query('other-billable', {
        pageSize: 25
      });
    }),
    diagnoses: Ember.computed(function () {
      return this.get('store').query('diagnosis-type', {
        pageSize: 25
      });
    }),
    billableItems: Ember.computed('selectedBillable', function () {
      let selectedBillable = this.get('selectedBillable');
      return this.get(selectedBillable.name);
    }),
    loadIndex: 0,
    priceList: Ember.computed('product', 'selectedBillable', 'loadIndex', function () {
      //(get product selectedBillable.name)
      let selectedBillableName = this.get('selectedBillable.item');
      return _emberData.default.PromiseArray.create({
        promise: new Ember.RSVP.Promise((resolve, reject) => {
          this.set('loadingList', true);
          console.log('prod model', this.get('product.id'));
          this.get('ajaxRequest').makeRequest('GET', "/insuranceProductBillables/" + selectedBillableName + "/" + this.get('product.id'), null, {
            success: response => {
              this.set('loadingList', false);
              resolve(response);
            },
            error: error => {
              this.set('loadingList', false);
              reject(error);
            }
          });
        })
      });
    }),
    actions: {
      addItem(item) {},

      reviewThreshold(record) {
        this.set('alertwaiting', true);
        var rec = this.get('store').query('threshold', {
          filter: {
            insuranceProductId: this.get('product.id'),
            itemId: record.id,
            type: this.get('selectedBillable.billableItemName')
          }
        }).then(function (r) {
          if (r.length) {
            return r.get('firstObject');
          }

          return null;
        });
        let that = this;
        rec.then(function (sr) {
          if (sr) {
            that.set('alertwaiting', false);
            sr.set('itemName', record.genericName);
            that.set('editThreshold', sr);
          } else {
            that.set('alertwaiting', false);
            that.set('editThreshold', {
              type: that.get('selectedBillable.billableItemName'),
              insuranceProduct: that.get('product'),
              itemId: record.id,
              itemName: record.genericName
            });
          }
        });
        this.set('alertTitle', record.genericName);
        this.set('thresholdDialog', true);
      },

      editThreshold(record) {
        this.set('editThreshold', record);
        this.set('thresholdDialog', true);
      },

      onSaved(record) {//this.send('refreshModel')
      },

      removeItem(item) {
        let sBillable = this.get('selectedBillable');
        let product = this.get('product');
        this.get('product').removeBillable(sBillable.item, item.get('id')).then(() => {
          product.get(sBillable.name).removeObject(item);
        }, err => {
          console.log(err);
        });
      },

      saveNewRecord() {// this.set('listWait', true)
        // let sBillable= this.get('selectedBillable')
        // let product = this.get('product')
        // let item =this.get('newItem')
        // product.addBillable(sBillable.item, this.get('newItem.id')).then(
        //   ()=>{
        //     product.get(sBillable.name).pushObject(item)
        //     this.set('newItem', null)
        //     this.set('listWait', false)
        //     this.notify.success('Saved Successfully')
        //   },
        //   (err)=>{
        //     console.log(err)
        //     this.set('listWait', false)
        //     this.notify.error('Unable to Save')
        //   }
        // )
      },

      reviewPrice(record, editMode = false) {
        console.log('price rec', record);
        console.log('selectedBillable', this.get('selectedBillable.item'));
        this.get('previewBillable')(record, this.get('selectedBillable.item'), editMode);
        event.stopPropagation();
        event.preventDefault();
      } // reviewThreshold(record, editMode=false){
      //   this.get('previewThreshold')(record, this.get('selectedBillable.item'), editMode)
      //   event.stopPropagation()
      //   event.preventDefault()
      // },


    }
  });

  _exports.default = _default;
});
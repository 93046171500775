define("hmis/templates/components/pickers/pickers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7wRAq4mN",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"pickers/table-picker-server-paginated\",null,[[\"ref\",\"parent\"],[\"serverTable\",[24,[\"pickers\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/pickers/pickers.hbs"
    }
  });

  _exports.default = _default;
});
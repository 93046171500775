define("hmis/components/pickers/diagnosis-sub-type-input", ["exports", "hmis/utils/model-table-theme", "hmis/utils/common-data-columns"], function (_exports, _modelTableTheme, _commonDataColumns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service('store'),
    appData: Ember.inject.service(),
    icdDiagnosisTypes: Ember.computed(function () {
      return this.get('store').query('diagnosis-sub-type', {
        filter: {},
        include: ['diagnosisMajorGroupName', 'diagnosisTypeName'],
        pageSize: 10
      });
    }),
    label: "Diagnosis type",
    diagnosisSubTypePickerColumns: _commonDataColumns.diagnosisSubTypePickerColumns
  });

  _exports.default = _default;
});
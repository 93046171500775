define("hmis/controllers/hmis-app/records/visit", ["exports", "hmis/utils/date-util", "hmis/utils/model-table-theme"], function (_exports, _dateUtil, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notInSelection: Ember.computed("model", function () {
      console.log(this.get('model'));
    }),
    date: Ember.computed(function () {
      var now = new Date();
      var day = ("0" + now.getDate()).slice(-2);
      var month = ("0" + (now.getMonth() + 1)).slice(-2);
      var today = now.getFullYear() + "-" + month + "-" + day;
      return today;
    }),
    serverResponse: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    insuranceProducts: Ember.computed('property', function () {
      return this.get('store').findAll('insurance-product');
    }),
    patientInsuranceProducts: [],
    selectedPatientInsuranceProducts: [],
    // patientInsuranceProducts: computed('property', function() {
    //   return this.get('store').query('patient-insurance',{
    //     filter: {patientId: this.get('model.patient.id')}
    //   });
    // }),
    isOOP: Ember.computed('model', 'model.patient', 'model.patient.modeOfPayment', function () {
      let MOP = this.get('model.patient.modeOfPayment');
      if (('' + MOP).toLowerCase() == 'insurance') return false;
      return true;
    }),
    isOOP_observer: Ember.observer('model', 'model.patient', function () {
      let isOOP = true;
      let MOP = this.get('model.patient.modeOfPayment');
      if (('' + MOP).toLowerCase() == 'insurance') isOOP = false;
      this.set('isOOP', isOOP);
    }),
    visitPurposes: Ember.computed(function () {
      return this.get('store').findAll('visit-purpose');
    }),
    admissionType: null,
    admissionTypes: [{
      code: 'CRO',
      name: 'CRO (Chronic Follow-up)'
    }, {
      code: 'EME',
      name: 'EME (Emergency)'
    }, {
      code: 'ACU',
      name: 'ACU (Acute Episode)'
    }],
    actions: {
      back() {
        history.back();
      },

      verifyThreshold(item) {
        console.log('item', item);
        this.set('patientInsdata', item);
        this.set('alertTitle', item.insuranceName);
        this.set('thresholdDialog', true);
      },

      editThreshold(record) {
        this.set('editThreshold', record);
        this.set('thresholdDialog', true);
      },

      onSaved(record) {//this.send('refreshModel')
      },

      dragEnd({
        sourceList,
        sourceIndex,
        targetList,
        targetIndex
      }) {
        if (sourceList === targetList && sourceIndex === targetIndex) return;
        const item = sourceList.objectAt(sourceIndex);
        sourceList.removeAt(sourceIndex);
        targetList.insertAt(targetIndex, item);
      },

      selectPaymentMode(insurance) {
        let findIns = this.get('selectedPatientInsuranceProducts').findBy('id', insurance.id);
        if (findIns) this.get('selectedPatientInsuranceProducts').removeObject(findIns);else this.get('selectedPatientInsuranceProducts').pushObject(insurance);
      },

      addNewPurpose() {
        this.notify.prompt({
          title: 'Adding new pupose'
        }, value => {
          this.set('purposeWait', true);
          this.store.createRecord('visit-purpose', {
            name: value
          }).save().then(record => {
            // this.visitPurposes.pushObject(record)
            this.notify.success('Saved Successfully');
            this.set('purposeWait', false);
          }).catch(failure => {
            console.log('failure', failure);
            this.set('purposeWait', false);
            this.notify.error('Unable to Save');
          });
        });
      },

      rowClick(index, record) {
        console.log(record);
        this.set('selectedVisit', record);
        this.showVisitSummary(record);
      },

      setPayOption() {
        // console.log('record',record);
        if (!record) {
          this.set('otherIns', true);
        } else {
          this.set('otherIns', false);
        }
      },

      validateForm() {
        this.get('submitForm')();
      },

      addRecord() {
        let pat = this.get("model.patient");
        let date = this.get('date');
        let insuranceProduct = this.get('insuranceProduct');

        if (!this.get('purpose')) {
          this.notify.error('Choose a purpose');
          return;
        }

        if (!this.get('admissionType')) {
          this.notify.error('Choose an admission type');
          return;
        }

        this.appAjax.post('/startNewVisit', {
          purposeId: this.get('purpose.id'),
          date: date + ' ' + new Date().toLocaleTimeString('en-US', {
            hour12: false
          }),
          patientId: pat.id,
          admissionType: this.get('admissionType.code'),
          insuranceProducts: this.get('selectedPatientInsuranceProducts').map(a => a.id)
        }).then(data => {
          this.notify.success('New Visit Started for ' + pat.get('fullName'));
          this.send('back');
        }).catch(err => {
          this.notify.error(err);
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/
        ,
        messages: {
          "noDataToShow": "No documents uploaded"
        }
      });
    }),
    columns: [{
      title: 'Purpose',
      "propertyName": "purposeName"
    }, {
      "propertyName": "date"
    }]
  });

  _exports.default = _default;
});
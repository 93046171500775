define("hmis/models/vital", ["exports", "ember-data", "ember-data/relationships", "hmis/mixins/visit-service-model"], function (_exports, _emberData, _relationships, _visitServiceModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, {
    value: _emberData.default.attr(),
    vitalType: (0, _relationships.belongsTo)('vital-type', {
      async: false
    }),
    unitOfMeasure: (0, _relationships.belongsTo)('vitals-unit-of-measure', {
      async: false
    }),
    batch: (0, _relationships.belongsTo)('vital-batch', {
      async: false
    }),
    vitalTypeId: _emberData.default.attr(),
    vitalTypeName: _emberData.default.attr(),
    unitOfMeasureId: _emberData.default.attr(),
    unitOfMeasureName: _emberData.default.attr()
  });

  _exports.default = _default;
});
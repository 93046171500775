define("hmis/components/viewers/visit-summary-viewer-alert", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/ref-component", "hmis/utils/general-util"], function (_exports, _modelTableTheme, _refComponent, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    app: Ember.inject.service(),
    notify: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      if (!this.ref) {
        let parent = this.get('parent');

        if (parent) {
          parent.set('showVisitSummary', visit => {
            this.show(visit);
          });
        }
      }
    },

    symptomsWaiting: false,

    show(visit) {
      if (this.visit != visit) {
        this.set('visit', visit);
        this.set('vitalsSummary', this.app.query('vital-batch', {
          filter: this.getFilter(),
          include: ['vitals']
        }));
        this.set('symptomsSummary', this.app.query('symptom', {
          filter: this.getFilter(),
          include: ['symptomTypeName']
        }));
        this.set('diagnosesSummary', this.app.query('diagnosis', {
          filter: this.getFilter(),
          include: ['diagnosisTypeName']
        }));
        this.set('prescriptionsSummary', this.app.query('prescription', {
          filter: this.getFilter(),
          include: ['medicineName']
        }));
        this.set('investigationsSummary', this.app.query('investigation', {
          filter: this.getFilter(),
          include: ['investigationTypeName']
        }));
        this.set('proceduresSummary', this.app.query('procedure', {
          filter: this.getFilter(),
          include: ['procedureTypeName']
        }));
        this.set('xraysSummary', this.app.query('xray', {
          filter: this.getFilter(),
          include: ['xrayTypeName']
        }));
        this.set('ultrasoundsSummary', this.app.query('ultrasound', {
          filter: this.getFilter(),
          include: ['ultrasoundTypeName']
        }));
        this.store.query('visit', {
          filter: {
            id: visit.id
          },
          include: ['statusName', 'outcomeName']
        }).then(res => {
          this.set('visit', res.get('firstObject'));
        });
      }

      this.set('_show', true);
    },

    getFilter() {
      return {
        visitId: this.visit.id
      };
    },

    actions: {
      back() {
        history.back();
      },

      print() {
        this.set('waiting', true);
        this.appAjax.printFile('exportVisitSummary/print/' + this.visit.id).then(res => {
          this.set('waiting', false);
        }).catch(err => {
          this.set('waiting', false);
          this.notify.error(err);
        });
      },

      pdf() {
        this.set('waiting', true);
        this.appAjax.downloadFile('exportVisitSummary/pdf/' + this.visit.id).then(res => {
          this.set('waiting', false);
        }).catch(err => {
          this.set('waiting', false);
          this.notify.error(err);
        });
      },

      email() {
        this.set('waiting', true);
        this.appAjax.ajaxGet('exportVisitSummary/email/' + this.visit.id, {}).then(res => {
          this.set('waiting', false);
        }).catch(err => {
          this.set('waiting', false);
          this.notify.error(err);
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    vitalsColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Vitals',
      propertyName: 'vitalsString',
      className: "vsv-summary-string-col"
    }],
    symptomsColumn: [{
      title: ' Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Symptoms',
      propertyName: 'symptomTypeName',
      className: "vsv-summary-string-col"
    }],
    diagnosesColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Diagnoses',
      propertyName: 'diagnosisTypeName',
      className: "vsv-summary-string-col"
    }],
    prescriptionsColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Prescriptions',
      propertyName: 'medicineName',
      className: "vsv-summary-string-col"
    }],
    proceduresColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Procedure',
      propertyName: 'procedureTypeName',
      className: "vsv-item-col"
    } // {
    //     title:'Documents',
    //     propertyName:'documentsString',
    //     className: "vsv-summary-string-col"
    // }
    ],
    investigationsColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Investigations',
      propertyName: 'investigationTypeName',
      className: "vsv-summary-string-col"
    }],
    xraysColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'X-Ray',
      propertyName: 'xrayTypeName',
      className: "vsv-item-col"
    } // {
    //     title:'Documents',
    //     propertyName:'documentsString',
    //     className: "vsv-summary-string-col"
    // }
    ],
    ultrasoundsColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Ultrasound',
      propertyName: 'ultrasoundTypeName',
      className: "vsv-item-col"
    } // {
    //     title:'Documents',
    //     propertyName:'documentsString',
    //     className: "vsv-summary-string-col"
    // }
    ]
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/ipd/ward/visit-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'Visit Summary'
    },
    controllerName: 'hmis-app.reports.visits.visit-summary',
    templateName: 'hmis-app.reports.visits.visit-summary'
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/claims/claim", ["exports", "hmis/utils/download-util", "hmis/utils/model-table-theme", "hmis/utils/request-utils/bill"], function (_exports, _downloadUtil, _modelTableTheme, _bill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    medicine: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'prescription');
    }),
    diagnosis: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'diagnosis');
    }),
    lab: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'investigation');
    }),
    procedure: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'procedure');
    }),
    ultrasound: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'ultrasound');
    }),
    xray: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'xray');
    }),
    otherBillables: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filter((item, index, self) => item.get('billableType') == 'admission' || item.get('billableType') == 'consultation' || item.get('billableType') == 'ward');
      ;
    }),
    sum: arrs => {
      let total = 0;
      arrs.forEach(function (tran) {
        total += Number(tran.get('amount'));
      });
      return total;
    },
    totalMedicineCost: Ember.computed('medicines', function () {
      return this.get('sum')(this.get('medicine'));
    }),
    totalDiagnosisCost: Ember.computed('diagnosis', function () {
      return this.get('sum')(this.get('diagnosis'));
    }),
    totalLabCost: Ember.computed('lab', function () {
      return this.get('sum')(this.get('lab'));
    }),
    totalProcedureCost: Ember.computed('procedure', function () {
      return this.get('sum')(this.get('procedure'));
    }),
    totalUltrasoundCost: Ember.computed('ultrasound', function () {
      return this.get('sum')(this.get('ultrasound'));
    }),
    totalXrayCost: Ember.computed('xray', function () {
      return this.get('sum')(this.get('xray'));
    }),
    totalOtherBillablesCost: Ember.computed('otherBillables', function () {
      return this.get('sum')(this.get('otherBillables'));
    }),
    totalBill: Ember.computed('totalMedicineCost', 'totalDiagnosisCost', 'totalLabCost', 'totalProcedureCost', 'totalUltrasoundCost', 'totalXrayCost', 'totalOtherBillablesCost', function () {
      let ret = Number(this.get('totalMedicineCost')) + Number(this.get('totalDiagnosisCost')) + Number(this.get('totalLabCost')) + Number(this.get('totalProcedureCost')) + Number(this.get('totalUltrasoundCost')) + Number(this.get('totalXrayCost')) + Number(this.get('totalOtherBillablesCost'));
      return ret.toFixed(2);
    }),
    actions: {
      back() {
        history.back();
      },

      // approveBill()
      // {
      //     let that = this;
      //     let oldBill = this.get('model')
      //     this.get('store').findRecord('bill-status',2).then(function(status){
      //         that.get('store').createRecord('bill-copy',{
      //             modeOfPayment:oldBill.get('modeOfPayment'),
      //             visit:that.get('model.visit'),
      //             status:status
      //         }).save().then(function(newBillCopy){
      //             that.set('model',newBillCopy)
      //         });
      //     })
      // },
      moveToBills(transaction) {
        this.get('ajaxRequest').moveTransactionToBills(transaction, {
          success: response => {
            this.notify.success('Transaction moved to bills');
            this.send('refreshModel');
          },
          error: error => {
            this.notify.error(error);
          }
        });
      },

      print() {
        this.set('printingClaim', true);
        this.get('ajaxRequest').printClaim(this.get('model.visit'), {
          success: response => {
            this.set('printingClaim', false);
          },
          error: error => {
            this.set('printingClaim', false);
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      exportToPdf() {
        // this.set('exportingBillToPDF',true)
        this.get('ajaxRequest').exportClaimToPdf(this.get('model.visit'), {
          success: response => {// this.set('exportingBillToPDF',false)
          },
          error: error => {
            // this.set('exportingBillToPDF',false)
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      alertEmail() {
        this.set('sendingMail', true);
        this.get('ajaxRequest').emailClaim(this.get('model.visit'), {
          success: response => {
            this.notify.success('Email sent');
            this.set('sendingMail', false);
          },
          error: error => {
            this.set('sendingMail', false);
            this.notify.error('Sorry! Something went wrong');
          }
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "propertyName": "index",
      "component": "controls/table-index",
      className: 'index-col'
    }, {
      "title": "Date of transaction",
      "propertyName": "dateF",
      className: 'trans-date-col'
    }, {
      "title": "description",
      "propertyName": "billableItemName"
    }, {
      "propertyName": "price"
    }, {
      "propertyName": "quantity"
    }, {
      'title': 'Total cost',
      "propertyName": "nAmount",
      className: 'total-cost-col'
    }, {
      "propertyName": "paymentStatus",
      className: 'payment-col'
    } // {
    //   "title": "",
    //   "component": "manageRow",
    //   "className": "fixed-manage-row-col"
    // }
    ],
    columns2: [{
      "propertyName": "index",
      "component": "controls/table-index",
      className: 'index-col'
    }, {
      "title": "Date of transaction",
      "propertyName": "dateF",
      className: 'trans-date-col'
    }, {
      "title": "description",
      "propertyName": "billableItemName"
    }, {
      'title': 'Cost',
      "propertyName": "nAmount",
      className: 'total-cost-col'
    }, {
      "propertyName": "paymentStatus",
      className: 'payment-col'
    } // {
    //   "title": "",
    //   "component": "manageRow",
    //   "className": "fixed-manage-row-col"
    // }
    ],
    columns3: [{
      "propertyName": "index",
      "component": "controls/table-index",
      className: 'index-col'
    }, {
      "title": "Date of transaction",
      "propertyName": "dateF",
      className: 'trans-date-col'
    }, {
      "title": "description",
      "propertyName": "billableSummary"
    }, {
      'title': 'Cost',
      "propertyName": "nAmount",
      className: 'total-cost-col'
    }, {
      "propertyName": "paymentStatus",
      className: 'payment-col'
    } // {
    //   "title": "",
    //   "component": "manageRow",
    //   "className": "fixed-manage-row-col"
    // }
    ]
  });

  _exports.default = _default;
});
define("hmis/templates/components/dashboard-skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fg723YUb",
    "block": "{\"symbols\":[\"chartItem\",\"count\",\"index\"],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ui four column doubling grid stackable\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"counts\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"column dashboard-item\"],[10,\"style\",\"margin-top: 20px\"],[8],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"col-sm-12 dashboard-count count-item-\",[23,3,[]],\" \",[23,2,[\"colorCss\"]]]]],[8],[0,\"\\n\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[2,3]},null],[9],[0,\"\\n\\n\"],[4,\"unless\",[[24,[\"hideChart\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"ui three column doubling grid stackable\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"charts\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"column dashboard-item\"],[10,\"style\",\"margin-top: 25px\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"chartWrap\"],[8],[0,\"\\n\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/dashboard-skeleton.hbs"
    }
  });

  _exports.default = _default;
});
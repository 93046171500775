define("hmis/components/toolbar-item-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onMenuItemClick() {
        let dropdown = this.get('dropdown');
        dropdown.actions.close();
        let action = this.get('onClick');

        if (action) {
          action(this.get('item'));
        }

        event.preventDefault();
        event.stopPropagation();
      }

    }
  });

  _exports.default = _default;
});
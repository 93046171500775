define("hmis/templates/hmis-app/records/patient/sharing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qy51mjep",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n    \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Back\",[28,\"action\",[[23,0,[]],\"back\"],null],\"back-fill\"]]],false],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"disabled\",\"icon\",\"onClick\"],[\"Revert changes\",[28,\"not\",[[24,[\"hasChange\"]]],null],\"undo\",[28,\"action\",[[23,0,[]],\"onRevert\"],null]]]],false],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"waiting\",\"disabled\",\"icon\",\"onClick\"],[\"Save changes\",[24,[\"saving\"]],[28,\"not\",[[24,[\"hasChange\"]]],null],\"save\",[28,\"action\",[[23,0,[]],\"onSave\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"app-workspace\",[],[[\"@error\"],[[22,\"pageError\"]]],{\"statements\":[[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\",\"waiting\"],[\"full-height has-main-table\",true,\"Sharing Information\",[24,[\"loading\"]]]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"content content-list \"],[10,\"style\",\"font-size: 14px\"],[8],[0,\"\\n            \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"model\",\"consentGroupList\"]]],null,{\"statements\":[[0,\"                    \"],[1,[28,\"sharing/consent-group\",null,[[\"patientConsents\",\"group\",\"patientRelatives\",\"onChange\"],[[24,[\"patientConsents\"]],[23,1,[]],[24,[\"patientRelatives\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n       \\n        \\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/records/patient/sharing.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/serializers/application", ["exports", "ember-data", "hmis/mixins/data-serializer"], function (_exports, _emberData, _dataSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_dataSerializer.default, {}); // import { underscore, camelize } from '@ember/string';
  // import DS from 'ember-data';
  // export default DS.JSONSerializer.extend({
  //   normalize(typeClass, hash) {
  //     for (var field in hash) {
  //       if (Object.prototype.hasOwnProperty.call(hash, field)) {
  //           // do stuff
  //           if (field.endsWith('_JSProp')) { 
  //             if(hash[field]){
  //               let newField= field.replace('_JSProp', 'Obj');
  //               if (typeof hash[field] === 'string' || hash[field] instanceof String)
  //                 hash[newField] = JSON.parse(hash[field]);
  //               else
  //                 hash[newField] = hash[field];
  //               delete hash[field];
  //             }
  //           }
  //       }
  //     }
  //     return this._super.apply(this, arguments);
  //   },
  //     keyForAttribute: function(attr) {
  //         if(attr=='createdAt' || attr=='updatedAt')
  //           return underscore(attr);
  //         else {
  //           return camelize(attr);
  //         }
  //     },
  // });


  _exports.default = _default;
});
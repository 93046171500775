define("hmis/controllers/hmis-app/admin-settings/department-management/department/ward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import Component from '@ember/component';
  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    notify: Ember.inject.service(),
    newRecord: {},
    onConfirm: 'deleteRecord',
    waitingRoomList: false,
    wardName: Ember.computed('model.rooms', 'model.departmentName', function () {
      let depName = "Department: " + this.get('model.departmentName');
      let wn = this.get('model.rooms').get('firstObject');
      let warName = wn ? wn.wardName : '';
      return depName + ' / Ward:' + warName;
    }),
    actions: {
      deleteRecord(record) {
        console.log(record);
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete: ' + record.get('name'));
        this.set('onAlert', true);
      },

      deleteRoomRecord(record) {
        console.log(record);
        this.set('onConfirm', 'deleteRoomNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete: ' + record.get('name'));
        this.set('onAlert', true);
      },

      deleteNow(record) {
        console.log(record);
        record.destroyRecord();
      },

      deleteRoomNow(record) {
        this.get("selectedRoom.beds").removeObject(record);
        record.destroyRecord();
      },

      saveRecord(record) {
        record.save().then(record => {
          this.set('listWait', false);
          this.notify.success('Saved Successfully');
          this.send('reloadModel');
        }).catch(failure => {
          this.set('listWait', false);
          this.notify.error(failure);
        });
      },

      saveNewRecord() {
        this.set('listWait', true);
        let data = this.get('newRecord');
        data.ward = this.get('model.rooms').get('firstObject').get('ward');
        let newRecord = this.get('store').createRecord('room', data);
        newRecord.save().then(record => {
          this.set('listWait', false);
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.send('reloadModel');
        }, failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error('Unable to Save');
        });
      },

      clickRecord(record) {
        this.set('waitingRoomList', true);
        this.get('store').findRecord('room', record.id).then(data => {
          this.set('selectedRoom', data);
          this.set('waitingRoomList', false);
        });
        this.set('bedsModal', true);
      },

      addBed() {
        let model = this.get('model');
        let store = this.get('store');
        let that = this;
        let nbeds = this.get('numBeds') || 1;
        this.set('waitingRoomList', true);

        if (nbeds > 1) {
          this.get('ajaxRequest').addBeds(this.get('bedname'), this.get('selectedRoom'), nbeds, {
            success: beds => {
              this.set('waitingRoomList', false);
              that.notify.success('Saved Successfully');
              this.set('bedname', '');
              this.set('numBeds', 1);
            },
            error: err => {
              this.set('waitingRoomList', false);
              that.notify.error(err);
            }
          });
        } else {
          let post = store.createRecord('bed', {
            name: this.get('bedname'),
            room: this.get('selectedRoom')
          });
          post.save().then(record => {
            this.set('waitingRoomList', false);
            this.set('bedname', '');
            this.get('selectedRoom.beds').pushObject(record);
            that.notify.success('Saved Successfully');
          }).catch(failure => {
            this.set('waitingRoomList', false);
            that.notify.error('Unable to Save' + failure);
          });
        }
      }

    }
  });

  _exports.default = _default;
});
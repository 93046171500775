define("hmis/templates/components/controls/progress-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GYuaiYUz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"progress-list-item\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"pli-progress-bg\"],[11,\"style\",[29,[\"width:\",[22,\"progress\"],\"%\"]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"pli clickable\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[8],[0,\" \\n\"],[4,\"if\",[[24,[\"iconText\"]]],null,{\"statements\":[[0,\"            \"],[7,\"p\",true],[10,\"class\",\"pli-icon\"],[8],[1,[22,\"iconText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[7,\"div\",true],[10,\"class\",\"pli-content\"],[8],[0,\"\\n            \"],[7,\"p\",true],[10,\"class\",\"pli-main\"],[8],[1,[22,\"main\"],false],[9],[0,\"\\n            \"],[7,\"p\",true],[10,\"class\",\"pli-sub\"],[8],[1,[22,\"subText\"],false],[9],[0,\" \\n        \"],[9],[0,\"\\n\"],[0,\"            \"],[7,\"p\",true],[10,\"class\",\"pli-progress-text\"],[8],[1,[22,\"progress\"],false],[0,\"%\"],[9],[0,\"\\n        \\n\"],[4,\"unless\",[[24,[\"hideRemove\"]]],null,{\"statements\":[[0,\"                \"],[7,\"button\",true],[10,\"class\",\"pli-remove\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onRemove\"],null]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-close\"],[8],[9],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/progress-list-item.hbs"
    }
  });

  _exports.default = _default;
});
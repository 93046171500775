define("hmis/components/controls/table-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { get } from '@ember/object';
  function concatWithProperty(strings, property) {
    if (property) {
      strings.push(property);
    }

    return strings.join(' ');
  }

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      if (this.column.disabledProperties) {
        let self = this;
        this.column.disabledProperties.forEach(prop => {
          self.addObserver("record." + prop, function () {
            self.send('checkDisabled', prop);
          });
          self.send('checkDisabled', prop);
        });
      }

      if (this.column.readOnlyProperties) {
        let self = this;
        this.column.readOnlyProperties.forEach(prop => {
          self.addObserver("record." + prop, function () {
            self.send('checkReadOnly', prop);
          });
          self.send('checkReadOnly', prop);
        });
      }
    },

    notify: Ember.inject.service(),
    type: 'text',
    value: 1,
    warning: Ember.computed('column.warning', 'record', 'value', function () {
      if (!this.get('column.warning')) return false;
      return this.get('column.warning')(this.get('record'), this.get('value'));
    }),
    cancatinatedInputClasses: Ember.computed('warning', function () {
      let classes = [''];

      if (this.get('warning')) {
        classes.push('warning');
      }

      return concatWithProperty(classes, this.get('triggerClass'));
    }),
    tagName: '',
    actions: {
      checkReadOnly(prop) {
        if (this.column.readOnly) this.set('readOnly', this.column.readOnly(this.record, this.value));else {
          if (Ember.get(this.record, prop)) this.set('readOnly', true);
        }
      },

      checkDisabled(prop) {
        if (this.column.disabled) this.set('disabled', this.column.disabled(this.record, this.value));else if (Ember.get(this.record, prop)) this.set('disabled', true);
      },

      onOpen(event) {
        let property = this.get('column.propertyName');
        this.notify.prompt({
          title: 'Value',
          message: 'Enter value',
          initialValue: this.get('record.' + property)
        }, newVal => {
          console.log(newVal);
          this.set('record.' + property, newVal);
        });
      },

      onChange(event) {
        let value = event.target.value;
        this.set('value', value);
      },

      onConfirm(newVal) {
        console.log(newVal); // this.set('value')

        let record = this.get('record');
        let property = this.get('column.propertyName');
        this.set('record.' + property, newVal);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/services/consultation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(Ember.Evented, {
    consultAlertShow: false,
    that: function () {
      return this;
    }(),
    alertCall: function () {},

    callAlert(title, callBackName) {
      this.get('alertCall')(title, callBackName); // this.alertCall(title, callBackName)
      // alert(title)
    },

    vitalsChanged() {
      this.trigger('vitalsChanged');
    }

  });

  _exports.default = _default;
});
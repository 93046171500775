define("hmis/components/alerts/confirm-dialog", ["exports", "hmis/mixins/ref-component"], function (_exports, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    tagName: '',
    alertTag: 'div',
    isConfirm: true,
    backdropClosable: true,
    title: 'Confirmation required',
    message: 'Do you want to continue?',
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    footerInfo: '',
    closeOnConfirm: true,

    confirm(options, confirmCallback, cancelCallback) {
      if (!confirmCallback) {
        console.error('no confirmCallback passed');
        return;
      }

      this.set('onConfirm', confirmCallback);
      this.set('title', options.title || "Confirm!");
      this.set('message', options.message || "Do you want to continue?");
      this.set('warning', options.warning);
      this.set('confirmText', options.confirmText || "Confirm");
      this.set('cancelText', options.cancelText || "Cancel");
      this.set('footerInfoWarning', options.footerInfoWarning);
      this.set('onCancel', cancelCallback);
      this.set('show', true);
    },

    tag: '',
    actions: {
      onSubmit() {
        this.send('onConfirm');
      },

      onConfirm() {
        event.preventDefault();
        event.stopPropagation();
        if (this.closeOnConfirm) this.set('show', false);
        if (this.get('onConfirm')) this.get('onConfirm')(this.get('tag'));
      },

      onCancel() {
        event.preventDefault();
        event.stopPropagation();
        this.set('show', false);
        if (this.get('onCancel')) this.get('onCancel')();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/records/patient/referrals", ["exports", "hmis/utils/general-util", "hmis/mixins/route-error-handler", "hmis/mixins/visit-care-route"], function (_exports, _generalUtil, _routeErrorHandler, _visitCareRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, _visitCareRoute.default, {
    breadCrumb: {
      title: 'Visit History'
    },

    model(params) {
      var curPatient = this.modelFor("hmis-app.records.patient");
      let newModel = {
        patient: curPatient
      };
      newModel['data'] = this.get('store').query('referral', {
        filter: {
          patientId: curPatient.id
        },
        include: []
      });
      return (0, _generalUtil.modelHash)(newModel);
    }

  });

  _exports.default = _default;
});
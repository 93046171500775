define("hmis/components/controls/drop-downd", ["exports", "hmis/utils/events"], function (_exports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    right: '70%',
    width: '220px',
    ddStyle: function () {
      let left = this.get('left');
      let right = this.get('right');
      let width = this.get('width');
      return "\n            ".concat(left ? 'left:' + left + '; ' : 'right:' + right, "\n            ").concat(width ? '; width:' + width : '', "\n        ").htmlSafe();
    }.property('left', 'right', 'width'),
    show: Ember.computed('isOpen', function () {
      if (this.get('isOpen')) {
        event.preventDefault();
        event.stopPropagation();
        (0, _events.onClickOutside)('.dd-dropdown', () => {
          this.set('isOpen', false);
        });
        return true;
      } else return false;
    })
  });

  _exports.default = _default;
});
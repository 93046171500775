define("hmis/components/controls/property-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'column ov-property',
    // classNames:'ov-property',
    classNameBindings: ['editMode:edit-mode', 'hidden:d-none'],
    editText: 'Change',
    type: 'text',
    hidden: Ember.computed('hideOnNull', 'value', function () {
      if (!this.hideOnNull) return false;
      return this.value == null;
    }),
    actions: {
      onEditClick(event) {
        if (this.onEdit) {
          this.onEdit(this.value);
        } else {
          if (this.get('value') == 0) {
            this.set('value', 0.00);
          }

          this.set('editValue', Number.parseFloat(this.get('value')).toFixed(2));
          this.set('editMode', true);
        }
      },

      onSaveClick(event) {
        let action = this.get('onSave');
        if (action) action(Number.parseFloat(this.get('editValue')).toFixed(2));
        this.set('editMode', false);
      }

    }
  });

  _exports.default = _default;
});
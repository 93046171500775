define("hmis/controllers/hmis-app/pharmacy/dispense", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    input_width: 'flex-xs-100 flex-sm-50 flex-md-30 flex-lg-30',
    input_width_full: 'flex-100',
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    selectedItems: [],
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    allSelected: Ember.computed('model.prescriptions.@each', 'selectedItems.@each', function () {
      return this.get('model.prescriptions').length == this.get('selectedItems').length;
    }),
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    actions: {
      back() {
        history.back();
      },

      dispensePrescriptions() {
        let prescriptions = [];

        if (this.selectedItems.length == 0) {
          this.notify.error('Please select prescriptions to dispense');
          return;
        }

        for (let i = 0; i < this.selectedItems.length; i++) {
          const presc = this.selectedItems[i];

          if (presc.dispensed == 1 || presc.externalDispensed == 1) {
            this.notify.error(presc.medicineName + ' has already been dispensed');
            return;
          }

          if (presc.dispensedQuantity < 1) {
            this.notify.error(presc.medicineName + ' quantity to dispense can not be less than 1');
            return;
          }

          if (!presc.dispensedQuantity || presc.dispensedQuantity == '') {
            this.notify.error(presc.medicineName + ' quantity to dispense is empty');
            return;
          }

          prescriptions.push({
            id: presc.id,
            gdrgCode: presc.medicineGdrgCode,
            dispensedQuantity: presc.dispensedQuantity
          });
        }

        this.set('waitingDispense', true);
        console.log('prescriptions', prescriptions);
        console.log('selectedItems', this.selectedItems); // Update stock levels in openLMIS
        // this.appAjax.post( "/makeDispensedStockAdjustments/", {prescriptions})
        // .then((res)=>{
        //   this.set('waitingDispense', false)
        //   this.notify.info('Updated stock levels...')
        // })
        // .catch(err=>{
        //   this.set('waitingDispense', false)
        //   this.notify.error(err)
        // })

        this.appAjax.post("/dispenseVisitPrescriptions/" + this.model.visit.id, {
          prescriptions
        }).then(res => {
          // this.set('waitingDispense', false)
          this.send('refreshModel'); // Update stock levels in openLMIS

          this.appAjax.post("/makeDispensedStockAdjustments/", {
            prescriptions
          }).then(res => {
            this.set('waitingDispense', false);
            this.notify.info('stock levels updated');
          }).catch(err => {
            this.set('waitingDispense', false);
            this.notify.error(err);
          });
        }).catch(err => {
          this.set('waitingDispense', false);
          this.notify.error(err);
          this.send('refreshModel');
        });
      },

      checkAllToggle() {
        if (!this.get('allSelected')) {
          this.set('selectedItems', []);
          this.get('model.prescriptions').forEach(element => {
            this.get('selectedItems').pushObject(element);
          });
        } else {
          this.set('selectedItems', []);
        }
      },

      previewExternalPrescriptions() {
        let prescriptions = [];

        if (this.selectedItems.length == 0) {
          this.notify.error('Please select prescriptions to dispense');
          return;
        }

        for (let i = 0; i < this.selectedItems.length; i++) {
          const presc = this.selectedItems[i];
          prescriptions.push({
            id: presc.id,
            dispensedQuantity: presc.dispensedQuantity
          });
        }

        this.set('waitingExternalPreview', true);
        this.appAjax.post("/previewExternalPrescriptions/" + this.model.visit.id, {
          prescriptions
        }).then(res => {
          this.set('waitingExternalPreview', false);
          this.set('externalPrescriptions', res);
          this.set('externalPrescriptionsToGenerate', prescriptions);
          this.set('showPrescription', true);
        }).catch(err => {
          this.set('waitingExternalPreview', false);
          this.notify.error(err);
        });
      },

      proceedExternalPrescriptions() {
        this.appAjax.post("/generateExternalPrescriptions/" + this.model.visit.id, {
          prescriptions: this.externalPrescriptionsToGenerate
        }).then(presc => {
          let w = window.open();
          setTimeout(() => {
            w.document.write(presc);
            w.print();
            w.close();
            this.set('showPrescription', false);
            this.send('refreshModel');
          }, 300);
        }).catch(err => {
          this.notify.error(err);
        });
      }

    },
    //date , patient, requestor
    columns: [{
      "propertyName": " ",
      "component": "controls/select-row-checkbox",
      className: 'index-col'
    }, {
      'title': 'Medicine',
      "propertyName": "medicineName" //   "component": "checkBox"

    }, {
      'title': 'Dosage',
      "propertyName": "dosageString"
    }, {
      'title': 'Frequency',
      "propertyName": "frequencyString"
    }, {
      'title': 'Duration',
      "propertyName": "durationString"
    }, {
      'title': 'Quantity',
      "propertyName": "quantity"
    }, {
      'title': 'Quantity Served',
      "propertyName": "dispensedQuantity",
      "component": "tableEditable",
      // 'disabledProperties': 'dispensed',
      warning: (record, value) => {
        if (Number(value) > Number(record.get('quantity'))) {
          return Number(value) > Number(record.get('quantity'));
        }
      },
      readOnlyProperties: ['dispensed', 'externalDispensed'] // readOnly: (record, value)=>{
      //   return record.dispensed || record.externalDispensed
      // },

    }, {
      'title': 'note',
      "propertyName": "note"
    }, {
      title: 'Requested By',
      "propertyName": "requesterName"
    }]
  });

  _exports.default = _default;
});
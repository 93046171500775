define("hmis/routes/hmis-app/opd/consultation/index", ["exports", "hmis/utils/general-util"], function (_exports, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    app: Ember.inject.service(),

    model() {
      return (0, _generalUtil.modelHash)({
        queues: this.get('store').query('queued-patient', {
          filter: {
            departmentUnit: 'consultation',
            today: true,
            served: 0
          },
          include: ['patientName', 'visitPurpose']
        }).then(recs => recs).catch(err => {
          return {
            error: err
          };
        }),
        referrals: this.app.query('referral', {
          filter: {
            departmentUnit: 'consultation',
            today: true,
            served: 0
          },
          include: ['patientName', 'visitPurpose']
        }),
        activeServes: this.get('store').query('active-serve', {
          filter: {
            served: 0
          },
          include: ['patientName']
        }),
        servedPatients: this.get('store').query('active-serve', {
          filter: {
            served: 1
          },
          include: ['patientName', 'visitPurpose']
        })
      });
    },

    // model()
    // {
    //   let that=this;
    //     return RSVP.hash({
    //         queues:this.get('store').query('queued-patient', {
    //             filter: {
    //                 departmentUnit: 'consultation',
    //                 today:true,
    //                 served: 0,
    //             },
    //         }).then((recs)=>{
    //           return recs;
    //         }).catch(err=>{
    //           return {
    //             hasError: true,
    //             error: err.errors[0].detail
    //           }
    //         }),
    //         activeServes:this.get('store').query('active-serve', {
    //             filter: {
    //                 served: 0,
    //             },
    //         }).then((recs)=>{
    //           return recs;
    //         }).catch(err=>{
    //           return {
    //             hasError: true,
    //             error: err.errors[0].detail
    //           }
    //         }),
    //         servedPatients:this.get('store').query('active-serve', {
    //             filter: {
    //                 served: 1,
    //             },
    //         }).then((recs)=>{
    //           return recs;
    //         }).catch(err=>{
    //           return {
    //             hasError: true,
    //             error: err.errors[0].detail
    //           }
    //         })
    //     });
    // },
    actions: {// error(error, transition) {
      //   // transition.send('setFlashMessage', error);
      //   console.log(error)
      // }
    }
  });

  _exports.default = _default;
});
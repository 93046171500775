define("hmis/templates/components/pickers/table-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qb+pByT6",
    "block": "{\"symbols\":[\"mt\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"stats-container  full-height has-main-table\"],[10,\"fullHeight\",\"true\"],[8],[0,\"\\n\\n\"],[4,\"controls/models-table\",null,[[\"class\",\"tagName\",\"showGlobalFilter\",\"data\",\"filterString\",\"themeInstance\",\"filteringIgnoreCase\",\"useFilteringByColumns\",\"selectedItems\",\"multipleSelect\",\"filteringIgnoreCase\",\"rowClickAction\",\"rowDoubleClickAction\",\"columns\"],[\"full-width\",\"\",false,[24,[\"data\"]],[24,[\"query\"]],[24,[\"themeInstance\"]],true,false,[24,[\"selectedItems\"]],[24,[\"multipleSelect\"]],true,[28,\"action\",[[23,0,[]],\"onRowClick\"],null],[28,\"action\",[[23,0,[]],\"onRowDoubleClick\"],null],[24,[\"columns\"]]]],{\"statements\":[[0,\"    \\n        \"],[7,\"div\",true],[10,\"class\",\"app-fixed-top\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"layout-row margin-20\"],[8],[0,\"   \\n               \"],[7,\"div\",true],[10,\"class\",\"rounded-tools-group\"],[8],[0,\"\\n                    \"],[1,[28,\"input\",null,[[\"class\",\"placeholder\",\"value\",\"placeholder\"],[\"searchbox\",\"Search\",[24,[\"query\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n\"],[0,\"                \"],[9],[0,\"\\n            \"],[9],[0,\"\\n\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n                \"],[1,[23,1,[\"table\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"app-fixed-bottom\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"if\",[[28,\"eq\",[[24,[\"requestStatus\"]],3],null],false,true],null]],null,{\"statements\":[[0,\"                \"],[1,[23,1,[\"footer\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/pickers/table-picker.hbs"
    }
  });

  _exports.default = _default;
});
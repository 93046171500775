define("hmis/components/tabs-container-page", ["exports", "hmis/mixins/page-error-handler"], function (_exports, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_pageErrorHandler.default, {
    tagName: "",
    index: -1,

    didInsertElement() {
      this._super(...arguments);

      if (this.parentTab) {
        if (!this.index || this.index == -1) this.set('index', this.parentTab.tabs.length);
        this.parentTab.tabs.pushObject({
          'title': this.title,
          'index': this.index
        });
        this.parentTab.set('onTab' + this.index + "Select", this.onSelect);
      }
    }

  });

  _exports.default = _default;
});
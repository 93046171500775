define("hmis/utils/vital-validation", ["exports", "hmis/constants/vital-types"], function (_exports, _vitalTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.vitalValidation = vitalValidation;
  _exports.default = void 0;

  function vitalValidation(type, pDOB, val) {
    var age = genBDate(pDOB);

    if (type == _vitalTypes.VITAL_TYPES.BLOOD_PREASSURE) {
      // Blood Pressure
      let tempVal = [];
      let sv = val ? val.toString() : '';

      if (sv.includes("/")) {
        tempVal = sv.split("/");
      } else {
        tempVal = [val, null];
      }

      return bloodPressure(age, tempVal[0], tempVal[1]);
    }

    if (type == _vitalTypes.VITAL_TYPES.PULSE) {
      // Pulse
      return pulse(age, val);
    }

    if (type == _vitalTypes.VITAL_TYPES.RESPIRATION) {
      // respiration
      return respiration(age, val);
    }

    return false;
  }

  function bloodPressure(age, sval, dval) {
    let systolic = Math.floor(sval);
    let diastolic = Math.floor(dval);
    if (systolic <= 0) return false;

    if (age >= 96 && (systolic < 90 || systolic > 140 || diastolic < 60 || diastolic > 90)) {
      //adult
      return true;
    } else if (age >= 12 && age <= 96 && (systolic < 80 || systolic > 110)) {
      //child 80 to 110 //|| systolic > 110
      return true;
    } else if (age >= 1 && age <= 12 && (systolic < 70 || systolic > 95)) {
      //infant 70 to 95 
      return true;
    } else if (age < 1 && systolic < 60) {
      //neonate
      return true;
    }

    return false;
  }

  function pulse(age, val) {
    Math.floor(val);
    if (val < 1) return false;

    if (age >= 96 && (val < 60 || val > 100)) {
      //adult
      return true;
    } else if (age >= 12 && age <= 96 && (val < 80 || val > 100)) {
      //child 
      return true;
    } else if (age >= 1 && age <= 12 && (val < 100 || val > 120)) {
      //infant
      return true;
    } else if (age < 1 && (val < 120 || val > 160)) {
      //neonate
      return true;
    }

    return false;
  }

  function respiration(age, val) {
    Math.floor(val);
    if (val < 1) return false;

    if (age > 96 && (val < 12 || val > 20)) {
      //adult
      return true;
    } else if (age >= 12 && age <= 96 && (val < 15 || val > 30)) {
      //child 
      return true;
    } else if (age >= 1 && age <= 12 && (val < 25 || val > 50)) {
      //infant
      return true;
    } else if (age < 1 && (val < 40 || val > 60)) {
      //neonate
      return true;
    }

    return false;
  }

  function genBDate(pAge) {
    var today = new Date();
    var birthDate = new Date(pAge);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    return age * 12 + m;
  }

  var _default = {
    vitalValidation
  };
  _exports.default = _default;
});
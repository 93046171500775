define("hmis/controllers/hmis-app/records/patient/questionnaires/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);
    },

    answerRecord: null,
    store: Ember.inject.service(),
    data: Ember.inject.service(),
    notify: Ember.inject.service(),
    valueSet: {},
    actions: {
      onChange() {
        if (!this.get('answerRecord')) {
          this.set('hasChange', true);
          return;
        }

        let moItems = this.get('answerRecord').toAnswer();
        let editItems = this.get('valueSet');
        delete moItems.valueChanged;
        delete editItems.valueChanged;
        this.set('hasChange', JSON.stringify(moItems) != JSON.stringify(editItems));
      },

      onRevert() {
        if (!this.get('answerRecord')) {
          this.set('valueSet', {});
          this.set('hasChange', false);
          return;
        }

        this.set('valueSet', this.get('answerRecord').toAnswer());
        this.set('hasChange', false);
      },

      onSave() {
        let answerRecord = this.get('answerRecord');

        if (!answerRecord) {
          answerRecord = this.store.createRecord('questionnaire-response', {
            patientId: this.get('model.patient.id'),
            questionnaireId: this.get('model.questionnaire.id')
          });
        }

        this.set('saving', false);
        answerRecord.saveAnswer(this.get('valueSet'), rec => {
          this.set('answerRecord', rec);
          this.set('hasChange', false);
          this.set('saving', false);
          this.get('notify').success("Saved");
        }, () => {
          this.set('saving', false);
          this.get('notify').error("Failed to save");
        });
      },

      back() {
        history.back();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/assign-to-doctor", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    input_width: "flex-40 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    selectedItems: [],
    tagName: '',
    patient: Ember.computed(function () {
      return this.get('model.queuedPatient').get('patient');
    }),
    departmentUnits: Ember.computed(function () {
      return this.get('store').query('department-unit', {
        filter: {
          isConsultation: 1
        }
      });
    }),
    // countObserver:observer("selectedItems.length", function(){
    //   var lenght = this.get("selectedItems").length
    //   if(lenght==1){
    //     this.set('oneItemSelected',true)
    //   }
    //   else if(lenght>0)
    //     {
    //       this.set('oneItemSelected',false)
    //     }
    //   else
    //     {
    //       this.set('oneItemSelected',false)
    //     }
    // }),
    // departmentUnits:{},
    depObserver: Ember.observer('department', function () {
      // console.log('department here',this.get('department'))
      let that = this;
      if (this.get('department')) this.get('store').query('department-unit', {
        filter: {
          department: this.get('department.id')
        }
      }).then(function (records) {
        that.set('departmentUnits', records);
      });
    }),
    actions: {
      back() {
        history.back();
      },

      onRowClick(index, dataItem) {
        let unit = this.get('selectedItems').get('firstObject');
        this.set('selectedDepartmentUnit', unit);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      'title': 'Department Unit',
      "propertyName": "name"
    }, {
      "title": "Service Offered",
      "propertyName": "serviceName"
    } // {
    //   "title": "Staffs Assigned",
    //   "propertyName": "assinedStaffs"
    // },
    ]
  });

  _exports.default = _default;
});
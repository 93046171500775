define("hmis/routes/hmis-app/facility-management/edit-facility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'Edit Facility'
    },
    deactivate: function () {
      var model = this.get('controller.model');
      model.rollbackAttributes();

      if (model.get('isNew')) {
        model.deleteRecord();
      }
    },
    actions: {
      willTransition: function (transition) {
        var model = this.get('controller.model');

        if (model.get('isDirty') && !confirm('You have unsaved changes. They will be lost if you continue.')) {
          transition.abort();
        }
      }
    }
  });

  _exports.default = _default;
});
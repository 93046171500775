define("hmis/components/forms/verify-threshold-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    // modelName:'threshold',
    // init(){
    // },
    succMsg: '',
    actions: {
      saveThreshold() {
        this.set('waiting', true);
        let mod = this.get('model');
        mod.set('balance', this.get('model.threshold'));
        mod.save().then(rec => {
          this.set('succMsg', 'Threshold updated successfully');
          this.set('waiting', false);
        });
      }

    }
  });

  _exports.default = _default;
});
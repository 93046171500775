define("hmis/templates/hmis-app/management-report/prescriptions-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZZTXurlQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n  \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"icon\",\"onClick\"],[[24,[\"generateText\"]],\"report\",[28,\"action\",[[23,0,[]],\"showFilter\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"alerts/confirm-dialog\",[],[[\"@show\",\"@title\",\"@message\",\"@waiting\",\"@closeOnConfirm\",\"@onConfirm\",\"@onCancel\",\"@width\",\"@height\",\"@confirmText\",\"@cancelText\"],[[22,\"showFilterModal\"],\"Set Report filters\",\"\",[22,\"isLoadingReport\"],false,[28,\"action\",[[23,0,[]],\"confirmFilter\"],null],[28,\"action\",[[23,0,[]],\"cancelFilter\"],null],\"70%\",\"75%\",\"Generate\",\"Close\"]],{\"statements\":[[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"stats-container full-height dark-content h-100\"],[8],[0,\"\\n      \"],[1,[28,\"controls/paged-report-controls\",null,[[\"filterValues\",\"filters\",\"aggregates\",\"reportType\"],[[24,[\"filterValues\"]],[24,[\"filters\"]],[24,[\"aggregates\"]],[24,[\"reportType\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]}],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"waiting\",\"waitProgress\",\"title\"],[\"full-height has-main-table\",true,[24,[\"isLoadingReport\"]],[24,[\"reportProgress\"]],\"Diagnosis Report\"]],{\"statements\":[[0,\"\\n   \"],[1,[28,\"controls/paged-report-viewer\",null,[[\"tabIndex\",\"dataReport\",\"filterValues\",\"reportType\",\"initialLoad\",\"filters\",\"aggregates\",\"selectedRows\",\"selectedColumns\",\"showNoDataRowsColumns\"],[[24,[\"tabIndex\"]],[24,[\"dataReport\"]],[24,[\"filterValues\"]],[24,[\"reportType\"]],[24,[\"initialLoad\"]],[24,[\"filters\"]],[24,[\"aggregates\"]],[24,[\"selectedRows\"]],[24,[\"selectedColumns\"]],[24,[\"showNoDataRowsColumns\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/management-report/prescriptions-report.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/services/file-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    previewFile: null,
    isServerUpload: true,
    showPreviewFile: false,

    preview(link, options = {}) {
      this.set('previewFile', link);
      this.set('previewTitle', options && options.title ? options.title : null);
      this.set('isServerUpload', options && options.isServerUpload ? options.isServerUpload : true);
      this.set('showPreviewFile', true);
      console.log(this.showPreviewFile);
    },

    confirmShow: false,
    onPickerConfirm: () => {
      /*temp callaback*/
    },
    onPickerCancel: () => {
      /*temp callaback*/
    },

    pick(options, confirmCallback, cancelCallback) {
      if (!confirmCallback) {
        console.error('no confirmCallback passed');
        return;
      }

      this.set('onPickerConfirm', confirmCallback);
      this.set('pickerShow', true);
      this.set('pickerTitle', options && options.title ? options.title : "Select file");
      this.set('pickerType', options && options.type ? options.type : 'Any');
      this.set('pickerFooterInfo', "File type: " + this.pickerType + " - maximum size: 20mb, " + (options && options.footerInfo ? options.footerInfo : ""));
      this.set('pickerAccepts', options && options.accepts ? options.accepts : "*");
      if (cancelCallback) this.set('onPickerCancel', cancelCallback);else this.set('onPickerCancel', () => {});
    }

  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/pharmacy/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    store: Ember.inject.service('store'),

    model() {
      return Ember.RSVP.hash({// request: this.get('store').query('prescription', { filter: { dispensed: 0}}),
        // despensed: this.get('store').query('prescription', { filter: { dispensed: 1}})
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  }); // this.get('store').query('person', {
  //   filter: {
  //     name: 'Peter'
  //   }
  // }).then(function(peters) {
  //   // Do something with `peters`
  // });


  _exports.default = _default;
});
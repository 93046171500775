define("hmis/templates/components/classic/c-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VLrf7vgs",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[8],[1,[22,\"label\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"controls/power-select\",null,[[\"class\",\"required\",\"searchField\",\"allowClear\",\"disabled\",\"triggerClass\",\"contentClass\",\"optionsClass\",\"searchEnabled\",\"selected\",\"options\",\"onchange\",\"required\",\"matcher\"],[\"\",[24,[\"required\"]],[24,[\"searchField\"]],[24,[\"allowClear\"]],[24,[\"disabled\"]],[28,\"concat\",[[24,[\"triggerClass\"]],\" classic m-input\",[28,\"unless\",[[24,[\"selected\"]],\" empty\",\"\"],null]],null],[28,\"concat\",[[24,[\"contentClass\"]],\" classic\"],null],[28,\"concat\",[[24,[\"optionsClass\"]],\"classic\"],null],[24,[\"searchEnabled\"]],[24,[\"selected\"]],[24,[\"options\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null],\"true\",[24,[\"matcher\"]]]],{\"statements\":[[0,\"      \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/classic/c-select.hbs"
    }
  });

  _exports.default = _default;
});
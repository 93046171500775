define("hmis/components/pickers/table-picker", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedItems: [],
    tagName: '',
    actions: {
      onRowClick(index, dataItem) {
        let action = this.get('onSelect');
        if (action) action(dataItem);
        let record = this.get('selectedItems').get('firstObject');
        this.set('selectedRecord', record); // let record2 = this.get('selectedItems');
        // console.log('record2',record2.length)
        // // this.set('selectedRecord', record)
        // if(record2 > 1){
        //     this.get('selectedRecord').push(record2)
        // }else{
        //     this.set('selectedRecord', record)
        // }
      },

      onRowDoubleClick(index, dataItem) {
        this.set('selectedRecord', dataItem);
        let action = this.get('onDoubleClick');
        if (action) action(dataItem);
        this.set('selectedItems', [dataItem]);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    })
  });

  _exports.default = _default;
});
define("hmis/templates/components/pickers/input-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yJdyfEX1",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"flex-row \",[22,\"class\"],\" non-trigger\"]]],[8],[0,\"\\n\"],[4,\"app-select\",null,[[\"class\",\"required\",\"searchField\",\"searchEnabled\",\"label\",\"selected\",\"options\",\"onChange\",\"disabled\"],[[28,\"concat\",[\"flex-1 \",[24,[\"inputClass\"]]],null],[24,[\"required\"]],[24,[\"textField\"]],true,[24,[\"label\"]],[24,[\"selected\"]],[24,[\"data\"]],[28,\"action\",[[23,0,[]],\"setRecord\"],null],[24,[\"disabled\"]]]],{\"statements\":[[0,\"        \"],[1,[28,\"get\",[[23,1,[]],[24,[\"textField\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[1,[28,\"pickers/table-picker-alert\",null,[[\"title\",\"selectedItems\",\"show\",\"data\",\"columns\",\"onConfirm\"],[[28,\"if\",[[24,[\"label\"]],[24,[\"label\"]],[24,[\"placeholder\"]]],null],[28,\"if\",[[24,[\"selected\"]],[28,\"array\",[[24,[\"selected\"]]],null],[28,\"array\",null,null]],null],[24,[\"findRecord\"]],[24,[\"data\"]],[24,[\"columns\"]],[28,\"action\",[[23,0,[]],\"chooseConfirm\"],null]]]],false],[0,\"\\n\\n    \"],[7,\"button\",true],[11,\"disabled\",[28,\"if\",[[24,[\"disabled\"]],true,false],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"findRecord\"]],true],null]],null]],[10,\"class\",\"input-chooser\"],[10,\"type\",\"button\"],[8],[1,[28,\"svg-jar\",[\"list\"],[[\"class\",\"height\",\"width\"],[\"icon dark-fill\",\"20px\",\"20px\"]]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/pickers/input-picker.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/facility-management/facility", ["exports", "hmis/utils/date-util", "hmis/mixins/visit-care-controller"], function (_exports, _dateUtil, _visitCareController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { requestAdmission, requestDischarge } from '../../../../utils/request-utils/ipd';
  var _default = Ember.Controller.extend(_visitCareController.default, {
    routing: Ember.inject.service('router'),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    consultationService: Ember.inject.service('consultation'),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    actions: {
      empty() {},

      showVisitSummary() {
        this.showVisitSummary(this.get('model.visit'));
      },

      showVisitTransactionsForView() {
        this.get('showVisitTransactionsForView')(this.get('model.visit'));
      },

      hideModalForInsurance() {
        if (this.get('model.visit.isOOP')) {
          this.set('showVisitTransactions', true);
        } else {
          this.send('checkPendingRequests');
        }
      },

      takeVitals() {
        //console.log('ll');
        this.get('takeNewVitals')();
      },

      admitRequestClick() {
        this.set('onAdmitAlert', true);
      },

      admitRequestConfirm(value) {
        // this.set('admitNote', value)
        if (!this.get('admissionType')) {
          this.set('onAdmitAlert', true);
          this.notify.error('please enter admission type');
          return;
        }

        this.set('onConfirm', 'sendAdmitRequest');
        this.set('onCancel', 'admitRequestClick');
        this.set('alertTitle', 'Requesting to admit ' + this.get('model.patient.fullName'));
        this.set('onConfirmAlert', true);
      },

      sendAdmitRequest() {
        let visit = this.get('model.visit');
        let visitId = visit.get('id');
        let that = this; // this.get('notify').notifyInfo('Sending request, please wait', true)

        this.set('requestingAdmission', true);
        this.get('ajaxRequest').requestAdmission(visit, this.get('admitNote'), this.get('admissionType.code'), {
          success: response => {
            this.set('admitNote', '');
            this.set('requestingAdmission', false);
            this.notify.success('Admission Request sent');
            that.get('store').findRecord('visit', visitId, {
              reload: true
            });
            that.get('store').findRecord('active-serve', that.get('model.id'), {
              reload: true
            });
          },
          error: error => {
            this.set('requestingAdmission', false);
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      back() {
        history.back(); // this.get("routing").transitionTo("hmis-app.consultation");
      },

      onRefFileAdded(file) {
        this.set('refUploadFiles', []);
        this.get('refUploadFiles').pushObject(file);
      },

      referAlertConfirm() {
        let that = this;
        let visit = this.get('model.visit');
        let referHospital = this.get('referHospital');
        let referRemark = this.get('referRemark');
        let letterFile = this.get('refUploadFiles.firstObject');
        let letterLocation = null;

        if (letterFile) {
          //Save file  and get location and new name
          letterLocation = "where/file/has/been/uploaded/to/uploadedName_" + letterFile.get('name'); // console.log('letterLocation: ', letterLocation)
        }

        let referral = this.get('store').createRecord('referral', {
          visit: visit,
          letter: letterLocation,
          hospital: referHospital,
          remark: referRemark
        });
        referral.save().then(function (savedRef) {
          that.notify.success('Saved'); // visit.get().then

          that.get('store').findRecord('visit', visit.get('id'), {
            reload: true
          });
        }, function (err) {
          that.notify.error('Unable to save: ' + err);
        });
      },

      onMenuClick(route, title) {
        try {
          this.get("routing").transitionTo(route);
          this.set('title', title);
        } catch (err) {
          console.log(err);
        }
      },

      checkPendingRequests() {
        let visitId = this.get('model.visit.id');
        this.set('endingServe', true);
        this.get('ajaxRequest').makeRequest('GET', "/visitPendingRequests/" + visitId, {}, {
          success: response => {
            this.set('endingServe', false);

            if (!response.totalCount) {
              this.send('endServe');
            } else {
              this.set('pendingsResponse', response);
              this.set('showPendings', true);
            }
          },
          error: error => {
            this.set('endingServe', false);
          }
        });
      },

      endServe() {
        let activeServe = this.get('model');
        this.set('endingServe', true);
        this.get('ajaxRequest').endActiveServe(activeServe, {
          success: response => {
            this.set('endingServe', false);
            this.notify.success('Serve ended');
            history.back();
          },
          error: error => {
            this.set('endingServe', false);
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      recheduleSuccess(appointment, endedActiveServe) {
        history.back();
      },

      vitalsSuccessAction() {
        this.get('consultationService').vitalsChanged();
      }

    }
  });

  _exports.default = _default;
});
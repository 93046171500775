define("hmis/routes/hmis-app/index", ["exports", "hmis/utils/app-config"], function (_exports, _appConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'Home'
    },
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    session: Ember.inject.service(),

    beforeModel() {
      if (this.currentUser.systemPassword == 0) {
        this.transitionTo('hmis-app.dashboard');
      }
    } // redirect: function() {
    //   // this.get('session.data.authenticated.setup_user').then(()=>{
    //   //   if(setupUser==0)
    //   //    this.transitionTo('hmis-app.dashboard')
    //   //    else if(setupUser==1){
    //   //      this.transitionTo('configure')
    //   //    }else{
    //   //
    //   //    }
    //   // })
    //   let setupUser = this.get('session.data.authenticated.setup_user');
    //   let accessLevel = this.get('currentUser.accessLevel');
    //   if(setupUser==0){
    //     let mPerms=this.get('mPerms')
    //     let resolved = false;
    //     for (var i = 0; i < appModules.length; i++) {
    //       let aMod = appModules[i];
    //       if(!aMod.levels.includes(accessLevel)){
    //         console.log('aMod.levels',aMod.levels);
    //         continue;
    //       }
    //       if( (mPerms[aMod.perm]?mPerms[aMod.perm].allow:false) ){
    //         // this.transitionTo('hmis-app.'+aMod.route)
    //         // break;
    //         // console.log('aMod', aMod);
    //         if(aMod.noChildPermissions){
    //           resolved = true;
    //           console.log('aMod', aMod)
    //           this.transitionTo('hmis-app.'+aMod.childMenus[0].route)
    //           break;
    //         }
    //         if(aMod.childMenus){
    //           for (var i = 0; i < aMod.childMenus.length; i++) {
    //             let aCMod = aMod.childMenus[i];
    //             if(!aCMod.levels.includes(accessLevel))
    //               continue;
    //             if(  (mPerms[aCMod.perm]?mPerms[aCMod.perm].allow:false)  ){
    //               resolved = true;
    //               this.transitionTo('hmis-app.'+aCMod.route)
    //               break;
    //             }
    //           }
    //         }
    //         if(!resolved)
    //           this.transitionTo('hmis-app.'+aMod.route)
    //         break;
    //       }else{
    //         console.log(aMod.route, 'no perm');
    //       }
    //     }
    //   }
    //   else if(setupUser==1){
    //     this.transitionTo('configure')
    //   }else{
    //     console.log('setupUser sataus:',setupUser)
    //   }
    // }


  });

  _exports.default = _default;
});
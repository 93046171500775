define("hmis/components/forms/referral-alert", ["exports", "hmis/mixins/form-alert", "hmis/mixins/ref-component", "hmis/constants/referral-types", "hmis/utils/common-data-columns"], function (_exports, _formAlert, _refComponent, _referralTypes, _commonDataColumns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, _refComponent.default, {
    modelName: 'user-accreditation',
    appAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    simpleJsons: Ember.inject.service(),
    title: "External referral",

    show(options, onSuccessCallback, onFinallyCallback = null) {
      this.set('onSuccess', onSuccessCallback);
      this.set('onFinallyCallback', onFinallyCallback);

      if (!options.visitId) {
        console.error('options.visitId is null');
        return;
      }

      if (!options.referrableType) {
        console.error('options.referrableType is null');
        return;
      }

      if (!options.referrableId) {
        console.error('options.referrableId is null');
        return;
      }

      if (!options.source) {
        console.error('options.source is null');
        return;
      }

      this.set('referrableId', options.referrableId);
      this.set('referrableType', options.referrableType);
      this.set('source', options.source);
      this.set('record', {
        visitId: options.visitId,
        systemFacility: options.systemFacility !== false ? true : false
      }); //show referral type options

      this.notify.confirmOptions({
        title: 'Choose the type of referal',
        options: _referralTypes.ReferalTypeDetails
      }, rType => {
        if (rType.id == _referralTypes.REFERRAL_TYPES.INTERNAL_DEPARTMENTAL) {
          this.data.serverTable({
            modelName: 'department-unit',
            columns: _commonDataColumns.departmentUnitPickerColumns,
            title: 'Select a Consultation Unit',
            query: {
              filter: {
                isConsultation: 1
              },
              include: ['serviceName']
            }
          }, unit => {
            //ask for referral note
            this.notify.prompt({
              title: "Add referral note",
              textArea: true
            }, note => {
              this.refer({
                note,
                toId: unit.id,
                typeId: rType.id,
                toName: unit.name
              });
            }, () => {
              onFinallyCallback && onFinallyCallback();
            });
          }, () => {
            onFinallyCallback && onFinallyCallback();
          });
        } else {
          this.set('_show', true);
        }
      }, () => {
        onFinallyCallback && onFinallyCallback();
      });
    },

    refer(data) {
      this.set('refering', true);
      let ajaxMethod = data.typeId == _referralTypes.REFERRAL_TYPES.INTERNAL_DEPARTMENTAL ? 'post' : 'postPrintFile';
      data['source'] = this.source;
      this.appAjax[ajaxMethod]("refer/".concat(this.referrableType, "/").concat(this.referrableId), data).then(res => {
        this.set('refering', false);
        this.notify.success('Patient has been refered to ' + data.toName);
        this.set('_show', false);
        this.onSuccess && this.onSuccess(res);
        this.onFinallyCallback && this.onFinallyCallback();
      }).catch(err => {
        this.notify.error(err);
        this.set('refering', false);
        this.onFinallyCallback && this.onFinallyCallback();
      });
    },

    //accreditationType: ['a','b','c'],
    actions: {
      onSystemFacilityChange(value) {
        // record.systemFacility
        this.set('record.systemFacility', value);
        this.set('record.toId', null);
        this.set('record.toName', null);
      },

      onFacilitySelect(facility) {
        this.set('selectedFacility', facility);
        this.set('record.toId', facility.id);
        this.set('record.toName', facility.facilityName);
      },

      onRecordSubmit(record) {
        let data = Object.assign({}, this.record);
        data.typeId = data.systemFacility ? _referralTypes.REFERRAL_TYPES.EXTERNAL_SYSTEM_FACILITY : _referralTypes.REFERRAL_TYPES.EXTERNAL_NON_SYSTEM_FACILITY; // delete data.systemFacility;

        this.refer(data);
      },

      onCancel() {
        this.set('waiting', false);
        this.onFinallyCallback && this.onFinallyCallback();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/opd/consultation/consult/prescriptions", ["exports", "hmis/mixins/route-error-handler", "hmis/mixins/visit-care-route", "hmis/utils/general-util", "hmis/utils/visit-service-util"], function (_exports, _routeErrorHandler, _visitCareRoute, _generalUtil, _visitServiceUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, _visitCareRoute.default, {
    serviceName: 'prescription',
    serviceTypeName: 'medicine',
    parentCareRoute: 'hmis-app.opd.consultation.consult',

    model(params) {
      return (0, _visitServiceUtil.prescriptionOpdIpdModel)(this);
    }

  });

  _exports.default = _default;
});
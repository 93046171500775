define("hmis/components/questionnaire/q-edit", ["exports", "hmis/utils/questionnaire"], function (_exports, _questionnaire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: 'non-list',

    didInsertElement() {
      this._super(...arguments);

      (0, _questionnaire.getQuestionsMap)(this.questionsMap, this.structure);
    },

    questionsMap: [],
    actions: {
      onChange(item) {
        if (this.get('onChange')) this.get('onChange')();
      }

    }
  });

  _exports.default = _default;
});
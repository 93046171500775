define("hmis/templates/components/viewers/prescription-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uquvltLH",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[4,\"alerts/confirm-dialog\",null,[[\"show\",\"waiting\",\"title\",\"message\",\"hideConfirm\",\"cancelText\",\"width\",\"height\"],[[24,[\"showModal\"]],[24,[\"waiting\"]],\"Prescription Details\",[24,[\"record\",\"medicineName\"]],true,\"Close\",\"55%\",\"75%\"]],{\"statements\":[[4,\"stats-container\",null,null,{\"statements\":[[4,\"object-viewer\",null,[[\"grid\"],[\"three\"]],{\"statements\":[[0,\"            \"],[1,[28,\"controls/property-view\",null,[[\"key\",\"value\"],[\"Patient name\",[24,[\"record\",\"patientName\"]]]]],false],[0,\"\\n            \"],[1,[28,\"controls/property-view\",null,[[\"key\",\"value\"],[\"Medicine\",[24,[\"record\",\"medicineName\"]]]]],false],[0,\"\\n            \"],[1,[28,\"controls/property-view\",null,[[\"key\",\"value\"],[\"Dosage\",[24,[\"record\",\"dosageString\"]]]]],false],[0,\"\\n            \"],[1,[28,\"controls/property-view\",null,[[\"key\",\"value\"],[\"Frequency\",[24,[\"record\",\"frequencyString\"]]]]],false],[0,\"\\n            \"],[1,[28,\"controls/property-view\",null,[[\"key\",\"value\"],[\"Duration\",[24,[\"record\",\"durationString\"]]]]],false],[0,\"\\n            \"],[1,[28,\"controls/property-view\",null,[[\"key\",\"value\"],[\"Quantity\",[24,[\"record\",\"quantity\"]]]]],false],[0,\"\\n            \"],[1,[28,\"controls/property-view\",null,[[\"key\",\"value\"],[\"Quantity Served\",[24,[\"record\",\"dispensedQuantity\"]]]]],false],[0,\"\\n            \"],[1,[28,\"controls/property-view\",null,[[\"key\",\"value\"],[\"Note\",[24,[\"record\",\"note\"]]]]],false],[0,\"\\n            \"],[7,\"span\",true],[10,\"class\",\"full-width margin-top-30\"],[8],[0,\"   \"],[9],[0,\" \\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/viewers/prescription-viewer.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/drop-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vme886UZ",
    "block": "{\"symbols\":[\"dd\",\"&default\"],\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[4,\"basic-dropdown\",null,[[\"class\"],[\"dropdown non-trigger\"]],{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"trigger\"]],\"expected `dd.trigger` to be a contextual component but found a string. Did you mean `(component dd.trigger)`? ('hmis/templates/components/controls/drop-down.hbs' @ L18:C7) \"],null]],[[\"class\"],[\"dropdown non-trigger\"]],{\"statements\":[[0,\"        \"],[14,2,[[28,\"hash\",null,[[\"trigger\"],[[28,\"component\",[\"controls/dropdown-trigger\"],[[\"isOpen\",\"class\"],[[24,[\"isOpen\"]],[24,[\"triggerClass\"]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `dd.content` to be a contextual component but found a string. Did you mean `(component dd.content)`? ('hmis/templates/components/controls/drop-down.hbs' @ L21:C7) \"],null]],[[\"class\"],[\"dd-wrapper\"]],{\"statements\":[[4,\"controls/dropdown-page\",null,[[\"left\",\"right\",\"width\",\"isOpen\"],[[24,[\"left\"]],[24,[\"right\"]],[24,[\"width\"]],[24,[\"isOpen\"]]]],{\"statements\":[[0,\"            \"],[14,2,[[28,\"hash\",null,[[\"dropdown\",\"header\",\"content\",\"footer\"],[[23,1,[]],[28,\"component\",[\"controls/dropdown-header\"],null],[28,\"component\",[\"controls/dropdown-content\"],null],[28,\"component\",[\"controls/dropdown-footer\"],null]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/drop-down.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/services/app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init() {
      this._super(...arguments);

      if (this.get('session.isAuthenticated')) {
        this.set('loadedDashbord', true);
        this.loadDashboardFixedStats();
        this.loadDashboardPeriodicStats(0);
        this.startLongPolling();
      }
    },

    loadings: [],
    appLoading: false,
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    isLoading: Ember.computed('loadings.length', 'appLoading', function () {
      return this.get('loadings').length > 0 || this.get('appLoading');
    }),

    query(modelName, query) {
      return {
        fakeQuery: true,
        modelName,
        type: {
          modelName
        },
        query,
        store: this.store
      };
    },

    startLoading(iniContext = false) {
      // console.log('this.appLoading', this.appLoading);
      if (!this.appLoading) setTimeout(() => {
        this.set('appLoading', true);
      }, 5);
    },

    stopLoading() {
      // console.log('this.appLoading', this.appLoading);
      if (this.appLoading) setTimeout(() => {
        this.set('appLoading', false);
      }, 5);
    },

    startLongPolling() {
      console.log('long long started');
      setTimeout(() => {
        // console.log('long polling started');
        if (this.get('session.isAuthenticated')) {
          this.loadDashboardPeriodicStats(this.dashbordStatsIndex);
          this.loadDashboardFixedStats();
          this.startLongPolling();
        } // }, 1000);

      }, 60000);
    },

    dashbordStatsIndex: 0,

    loadDashboardPeriodicStats(periodIndex, successCallback = null, errorCallaback = null) {
      this.set('dashbordPeriodStatsIndex', periodIndex);
      this.get('ajaxRequest').makeRequest('POST', "/dashboardPeriodicStatistics/" + (periodIndex == 0 ? 'day' : 'week'), {}, {
        success: response => {
          this.set('dashboardPeriodicStats', response);
          successCallback && successCallback(response);
        },
        error: error => {
          errorCallaback && errorCallaback(errror);
        }
      });
    },

    loadDashboardFixedStats(successCallback = null, errorCallaback = null) {
      this.appAjax.post("/dashboardFixedStatistics", {}).then(res => {
        this.set('dashboardFixedStats', res);
        successCallback && successCallback(response);
      }).catch(error => {
        errorCallaback && errorCallaback(errror);
      });
    }

  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/admin-settings/insurance-products/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      // return {
      //   insuranceCompany:[]
      // }
      //return this.get('store').findRecord('insurance-product', params.record_id)
      return this.get('store').findAll('insurance-product');
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/templates/hmis-app/test-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zeN5CTgs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\\n\"],[7,\"h1\",true],[8],[0,\"Test User her d d 1e\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/test-user.hbs"
    }
  });

  _exports.default = _default;
});
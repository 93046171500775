define("hmis/templates/components/controls/pdf-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Pb9RIZD",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"object\",true],[11,\"data\",[22,\"link\"]],[10,\"width\",\"100%\"],[10,\"height\",\"100%\"],[10,\"type\",\"application/pdf\"],[8],[0,\"\\n  alt : \"],[7,\"a\",true],[11,\"href\",[22,\"link\"]],[8],[0,\"PDF\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/pdf-preview.hbs"
    }
  });

  _exports.default = _default;
});
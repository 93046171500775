define("hmis/components/viewers/symptoms-batch-viewer-alert", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    symptoms: Ember.computed('batch.symptoms', function () {
      return this.get('batch.symptoms');
    }),
    // vitals:computed('batch.vitals',function(){
    //     return this.get('batch.vitals');
    // }),
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      'title': 'Symptom',
      "propertyName": "symptomTypeName"
    }, {
      "propertyName": "note"
    } // {
    //   "title": "Edit",
    //   "component": "editRow",
    //   "editable": false
    // },
    // {
    //   "title": "Delete",
    //   "component": "manageRow",
    //   "editable": false
    // }
    ]
  });

  _exports.default = _default;
});
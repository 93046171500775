define("hmis/helpers/make-true", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeTrue = makeTrue;
  _exports.default = void 0;

  function makeTrue(params
  /*, hash*/
  ) {
    // return params;
    params = true;
  }

  var _default = Ember.Helper.helper(makeTrue);

  _exports.default = _default;
});
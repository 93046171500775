define("hmis/helpers/currency", ["exports", "hmis/utils/currency-util"], function (_exports, _currencyUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currency = currency;
  _exports.default = void 0;

  function currency(amount
  /*, hash*/
  ) {
    return _currencyUtil.default.toString(amount);
  }

  var _default = Ember.Helper.helper(currency);

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/opd/consultation/consult/diagnosis", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller", "hmis/mixins/visit-care-controller"], function (_exports, _modelTableTheme, _formController, _visitCareController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, _visitCareController.default, {
    store: Ember.inject.service('store'),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    currentUser: Ember.inject.service(),
    serverResponse: Ember.inject.service(),
    app: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    tabStatus: Ember.computed('oPerms', function () {
      return this.get('oPerms.op20.add') ? 0 : 1;
    }),
    notify: Ember.inject.service(),
    majorDiagnosisGroupTypes: Ember.computed(function () {
      return this.get('store').query('diagnosis-major-group', {
        pageSize: 10
      });
    }),
    majorDiagnosisTypeType: Ember.computed('majorDiagnosisGroup.id', function () {
      return this.get('store').query('diagnosis-type', {
        filter: {
          mdgCode: this.majorDiagnosisGroup ? this.majorDiagnosisGroup.id : 'n/a'
        },
        pageSize: 10
      });
    }),
    icdDiagnosisTypes: Ember.computed('majorDiagnosisType.id', function () {
      return this.get('store').query('diagnosis-sub-type', {
        filter: {
          diagnosisTypeId: this.majorDiagnosisType ? this.majorDiagnosisType.id : 'n/a'
        },
        pageSize: 10
      });
    }),
    actions: {
      back() {
        history.back();
      },

      majorGroupChange(record) {
        this.set('majorDiagnosisGroup', record);
        this.set('majorDiagnosisType', null);
        this.set('icdDiagnosis', null);
      },

      majorTypeChange(record) {
        this.set('majorDiagnosisType', record);
        this.set('icdDiagnosis', null);
      },

      onNewTabClick(route) {
        this.set('tabStatus', 0);
      },

      onHistoryTabClick(route) {
        this.set('tabStatus', 1);
      },

      clickRecord() {},

      addRecord() {
        this.get('app').startLoading();
        this.set('saving', true);
        this.get('store').createRecord('diagnosis', {
          source: this.model.source,
          note: this.note,
          diagnosisTypeId: this.icdDiagnosis.diagnosisTypeId,
          icdCode: this.icdDiagnosis.icdCode,
          icdName: this.icdDiagnosis.icdName,
          mdgCode: this.icdDiagnosis.mdgCode,
          visitId: this.model.visitId
        }).save().then(mo => {
          this.set('note', '');
          this.set('icdDiagnosis', null);
          this.get('app').stopLoading();
          this.send('refreshModel');
        }, error => {
          this.get('app').stopLoading();
          this.notify.error(error);
        }).finally(() => {
          this.set('saving', false);
        });
      },

      rowClick(index, record) {// this.send("viewRecord",record)
      },

      viewRecord(record) {
        this.set('selectedBatch', record);
        this.set('showRecord', true);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      'title': 'Date',
      "propertyName": "dateTimeF",
      "editable": false
    }, {
      'title': 'Diagnosis (icd10)',
      "propertyName": "icdName"
    }, {
      'title': 'Diagnosis Type',
      "propertyName": "diagnosisTypeName"
    }, {
      propertyName: 'note'
    }]
  });

  _exports.default = _default;
});
define("hmis/components/hmis-side-menu", ["exports", "hmis/utils/app-config"], function (_exports, _appConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    menus: Ember.computed(function () {
      dummies.mudulses;
    }),
    isCollaped: true,
    showMenuSubs: false,
    testa: "",
    currentPathHook: '',
    currentUser: Ember.inject.service(),
    perms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    // appModules:computed(function(){
    //     return appModules;
    // }),
    appModules: Ember.computed('currentUser.unreadNotifications.@each.read', function () {
      let mns = _appConfig.default;
      let notiMenu = mns.find(item => item.route == 'notifications'); // Ember.set(notiMenu, 'badge', this.currentUser.unreadNotifications.length)
      // Ember.set(notiMenu,'hasBadge', true);

      return mns;
    }),

    didInsertElement() {
      this._super(...arguments); // alert("didRender changed: "+this.get("router.currentRouteName"));


      this.set("currentPathHook", this.get("router.currentRouteName"));
    },

    // currentPathDidChange: function() {
    //     alert(this.get('navigator.currentPath'));
    //   }.observes('navigator.currentPath'),
    currentMenuChanged: Ember.observer('router.currentRouteName', function () {
      // deal with the change
      this.set("currentPathHook", this.get("router.currentRouteName")); // alert("route changed: "+this.get("router.currentRouteName"));
    }),
    actions: {
      onTest() {
        console.log('cons: ', this.get('currentUser.modularPermissions'));
      },

      onMenuClick(route) {// try {
        //     this.get("router").transitionTo('hmis-app.'+route);
        // }
        // catch(err) { 
        //     console.log(err);
        // }
      },

      toggleMenu() {
        this.set("isCollaped", !this.get("isCollaped"));
        this.set("showMenuSubs", false); // alert(this.get("isCollaped"));
      },

      psScrollLeft() {}

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/forms/end-visit-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1cI3M1UF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"alerts/form-dialog\",null,[[\"title\",\"waiting\",\"show\",\"width\",\"height\",\"onConfirm\",\"onCancel\"],[\"End Domant Visit\",[24,[\"waiting\"]],[24,[\"show\"]],\"60%\",\"50%\",[28,\"action\",[[23,0,[]],\"onRecordSubmit\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"flex-100\"],[10,\"style\",\"margin-bottom: 30px\"],[8],[0,\"\\n        \"],[1,[28,\"object-viewer\",null,[[\"class\",\"object\",\"inputWidth\",\"properties\"],[\"flex-100\",[24,[\"model\"]],\"flex-30\",[24,[\"endVisitAlertProps\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[0,\"    \"],[1,[28,\"paper-input\",null,[[\"style\",\"label\",\"textarea\",\"required\",\"class\",\"passThru\",\"value\",\"onChange\"],[\"padding:10px\",\"Remark\",true,true,\"flex-100\",[28,\"hash\",null,[[\"rows\",\"maxRows\"],[5,5]]],[24,[\"record\",\"note\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"note\"]]],null]],null]]]],false],[0,\"\\n\"],[0,\"    \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/forms/end-visit-alert.hbs"
    }
  });

  _exports.default = _default;
});
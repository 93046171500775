define("hmis/components/pickers/table-picker-server-paginated", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/ref-component", "hmis/utils/general-util"], function (_exports, _modelTableTheme, _refComponent, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    selectedItems: [],
    tagName: '',
    title: 'Pick a Record',
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    minHeight: '60%',
    minWidth: '50%',
    store: Ember.inject.service(),
    _showTable: true,

    showPicker(options, confirmCallback, cancelCallback) {
      if (!confirmCallback && !options.isViewer) {
        console.error('no confirmCallback passed while options.isViewer is null');
      }

      if (!options.modelName) {
        console.error('no options.modelName passed');
        return;
      }

      (0, _generalUtil.promiseSet)(this, 'data', this.get('store').query(options.modelName, options.query || {}), 'waiting');
      this.set('columnComponents', options.columnComponents);
      this.set('query', '');
      this.set('onConfirm', confirmCallback);
      this.set('title', options.title ? options.title : "Select A Record");
      let columns = options.columns ? options.columns : [{
        propertyName: 'name'
      }];
      if (!(options.selectable === false) && columns[0].component != "controls/select-row-checkbox") columns.insertAt(0, {
        "component": "controls/select-row-checkbox",
        "useFilter": false,
        "mayBeHidden": false
      });
      this.set('columns', columns);
      this.set('onCancel', cancelCallback);

      if (options.isViewer) {
        this.set('cancelText', 'close');
        this.set('hideConfirm', true);
      }

      this.set('_showTable', false);
      this.set('_show', true);
      setTimeout(() => {
        this.set('_showTable', true);
      }, 3);
    },

    show(options, confirmCallback, cancelCallback) {
      this.showPicker(options, confirmCallback, cancelCallback);
    },

    actions: {
      onRowClick(index, dataItem) {},

      onRowDoubleClick(index, dataItem) {
        if (!this.multiSelect) {
          this.set('selectedItems', [dataItem]);
          this.set('_show', false);
          this.send('chooseConfirm');
        }
      },

      chooseConfirm() {
        let record_S = this.multiSelect ? this.get('selectedItems') : this.get('selectedItems').get('firstObject');
        let action = this.get('onConfirm');
        if (action) action(record_S);
      },

      onCancel() {
        this.set('_showTable', false);
        this.onCancel && this.onCancel();
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    })
  });

  _exports.default = _default;
});
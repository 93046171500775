define("hmis/routes/configure/insurance/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    controllerName: 'hmis-app.admin-settings.insurance-products.add',
    templateName: 'hmis-app.admin-settings.insurance-products.add',

    model(params) {
      return this.get('store').findRecord('insurance-company', params.record_id);
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
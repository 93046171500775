define("hmis/templates/components/avatar-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vUFFXni7",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[28,\"file-chooser-alert\",null,[[\"show\",\"type\",\"onConfirm\"],[[24,[\"show\"]],\"image\",[28,\"action\",[[23,0,[]],\"onPictureChange\"],null]]]],false],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"choose-image \",[22,\"class\"]]]],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"class\",\"choose-image-image\"],[11,\"src\",[28,\"if\",[[24,[\"imagePath\"]],[24,[\"imagePath\"]],\"../../../../assets/image/dummy/man-placeholder.jpg\"],null]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"choose-image-content\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"custom-file-upload gray-button\"],[10,\"style\",\"\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"showChooser\"],null]],[8],[0,\"\\n            Change\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"style\",\"margin-top:2px; font-size: 10px;\"],[8],[1,[22,\"imageName\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/avatar-view.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/records/index", ["exports", "ember-data", "hmis/mixins/route-error-handler"], function (_exports, _emberData, _routeErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, {
    appAjax: Ember.inject.service(),
    data: Ember.inject.service(),

    model() {
      return Ember.RSVP.hash({
        records: this.get('store').query('patient', {
          filter: {
            isEmergency: 0
          },
          include: ['genderName']
        }),
        emergencyRecords: this.get('store').query('emergency-patient', {
          include: ['genderName', 'wardName']
        }),
        arcPatients: this.appAjax.getObject('/archivedPatients')
      });
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/alerts/form-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TmuuGAtV",
    "block": "{\"symbols\":[\"form\",\"&default\"],\"statements\":[[4,\"alerts/confirm-dialog\",null,[[\"closeOnConfirm\",\"alertTag\",\"alertClass\",\"title\",\"show\",\"onConfirm\",\"onCancel\",\"message\",\"confirmText\",\"cancelText\",\"promptDialog\",\"width\",\"height\",\"unClosable\",\"backdropClosable\",\"footerInfoWarning\",\"hideConfirm\",\"confirmDisabled\",\"cancelDisabled\",\"waiting\",\"contentClass\",\"darkHeader\",\"darkFooter\",\"contentPadding\",\"waitingOverlay\"],[false,\"div\",\"form\",[24,[\"title\"]],[24,[\"show\"]],[28,\"action\",[[23,0,[]],\"onConfirm\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null],[24,[\"message\"]],[24,[\"confirmText\"]],[24,[\"cancelText\"]],[24,[\"promptDialog\"]],[24,[\"width\"]],[24,[\"height\"]],[24,[\"unClosable\"]],[24,[\"backdropClosable\"]],[24,[\"footerInfoWarning\"]],[24,[\"hideConfirm\"]],[24,[\"confirmDisabled\"]],[24,[\"cancelDisabled\"]],[24,[\"waiting\"]],[24,[\"contentClass\"]],true,true,[24,[\"contentPadding\"]],[24,[\"waitingOverlay\"]]]],{\"statements\":[[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[4,\"controls/paper-form\",null,[[\"onValidFormSubmit\",\"onInvalid\",\"parent\"],[[28,\"action\",[[23,0,[]],\"onValidFormSubmit\"],null],[28,\"action\",[[23,0,[]],\"onInvalid\"],null],[23,0,[]]]],{\"statements\":[[0,\"            \"],[14,2],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/alerts/form-dialog.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/row-options-opt", ["exports", "hmis/components/controls/row-options"], function (_exports, _rowOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    hidden: Ember.computed('option.hidden', function () {
      return (0, _rowOptions.rowOptionIsHidden)(this.option, this.record, this.value);
    }),
    disabled: Ember.computed(function () {
      return (0, _rowOptions.rowOptionIsDisabled)(this.option, this.record, this.value);
    }),
    actions: {
      optionClicked(option, dropdown) {
        this.onOptionClick(option, this.record);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/models/patient-occupation", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    patient: (0, _relationships.belongsTo)('patient'),
    occupation: _emberData.default.attr(),
    employmentStatus: _emberData.default.attr(),
    company: _emberData.default.attr(),
    contract: _emberData.default.attr(),
    position: _emberData.default.attr(),
    school: _emberData.default.attr(),
    yearRegistration: _emberData.default.attr(),
    yearCompletion: _emberData.default.attr(),
    facility: _emberData.default.attr(),
    subTitle: Ember.computed('occupation', function () {
      return this.school ? this.school : this.company;
    })
  });

  _exports.default = _default;
});
define("hmis/components/viewers/prescription-viewer", ["exports", "hmis/mixins/ref-component"], function (_exports, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    show(record) {
      if (this.record != record) {
        this.set('record', record);
      }

      this.set('showModal', true);
    }

  });

  _exports.default = _default;
});
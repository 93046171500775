define("hmis/templates/components/controls/delete-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3pp86pC8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-default\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"sendAction\",\"delete\",[24,[\"record\"]]],null]],[8],[0,\"Delete\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/delete-row.hbs"
    }
  });

  _exports.default = _default;
});
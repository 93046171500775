define("hmis/controllers/hmis-app/dashboard0", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),

    init() {
      this._super(...arguments);

      this.set('appointments', []); // this.get('loadAppointments')(this);
    },

    currentUser: Ember.inject.service(),
    //scroller: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    facilitiesCount: Ember.computed('oPerms', function () {
      let oPerms = this.get('oPerms');
      if (!oPerms.op2) return '';

      if (oPerms.op2.add || oPerms.op2.edit || oPerms.op2.view || oPerms.op2.inactivate) {
        return this.get('store').findAll('facility').then(function (res) {
          return res.get('length');
        });
      } else {
        return 0;
      }
    }),
    // patientCount:this.get('store').findAll('patient')
    //     .then(function(res) {
    //         return res.get('length')
    // }),
    patientCount: Ember.computed('oPerms', function () {
      let oPerms = this.get('oPerms');

      if (oPerms.op1.add || oPerms.op1.edit || oPerms.op1.view || oPerms.op1.inactivate) {
        return this.get('store').findAll('patient').then(function (res) {
          return res.get('length');
        });
      } else {
        return 0;
      }
    }),
    // welcomeShow:true,
    modelObserver: Ember.observer('model', function () {// let that = this;
      // that.set('appointments',[])
      // that.set('allAppointments',[])
      // that.set('servedAppointments',[])
      // that.get('store').query('schedule', {
      //   filter: {
      //     staff: that.get('currentUser.user.id'),
      //     // day: dateUtil.toSqlDateOnly(new Date()),
      //   }
      // }).then(schedules=>{
      //   schedules.forEach(schedule => {
      //
      //     schedule.get('appointments').forEach(appointment => {
      //       that.get('appointments').pushObject(appointment)
      //     //   console.log(appointment.get('endDate'));1
      //     });
      //     schedule.get('servedAppointments').forEach(appointment => {
      //       that.get('servedAppointments').pushObject(appointment)
      //     //   console.log(appointment.get('endDate'));1
      //     });
      //     schedule.get('allAppointments').forEach(appointment => {
      //       that.get('allAppointments').pushObject(appointment)
      //     //   console.log(appointment.get('endDate'));1
      //     });
      //
      // });
      // })
    }),
    appointmentPurposeNames: Ember.computed.mapBy('allAppointments', 'purposeName'),
    appointmentChartLabels: Ember.computed.uniq('appointmentPurposeNames'),
    appointmentChartData: Ember.computed('appointmentChartLabels.@each', 'allAppointments.@each', function () {
      let appointments = this.get('allAppointments');
      let arr = [];
      this.get('appointmentChartLabels').forEach(label => {
        if (label) {
          let aptms = appointments.filterBy('purposeName', label);
          arr.pushObject(aptms.length);
        }
      });
      console.log('chart' + arr);
      return arr;
    }),
    appointments: [],
    servedAppointments: [],
    allAppointments: [],
    appointmentDatasets: Ember.computed('appointmentChartData', function () {
      return [{
        label: '# of Appointments',
        data: this.get('appointmentChartData'),
        backgroundColor: ['rgba(255, 255, 255, 0)' // 'rgba(255, 99, 132, 0.2)',
        // 'rgba(54, 162, 235, 0.2)',
        // 'rgba(255, 206, 86, 0.2)',
        // 'rgba(75, 192, 192, 0.2)',
        // 'rgba(153, 102, 255, 0.2)',
        // 'rgba(255, 159, 64, 0.2)'
        ],
        borderColor: ['rgba(255,99,132,1)' // 'rgba(54, 162, 235, 1)',
        // 'rgba(255, 206, 86, 1)',
        // 'rgba(75, 192, 192, 1)',
        // 'rgba(153, 102, 255, 1)',
        // 'rgba(255, 159, 64, 1)'
        ],
        borderWidth: 2
      }];
    }),
    testLabels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    events: [{
      title: 'Event 1',
      start: '2016-05-05T07:08:08',
      end: '2016-05-05T09:08:08'
    }, {
      title: 'Event 2',
      start: '2016-05-06T07:08:08',
      end: '2016-05-07T09:08:08'
    }, {
      title: 'Event 3',
      start: '2016-05-10T07:08:08',
      end: '2016-05-10T09:48:08'
    }, {
      title: 'Event 4',
      start: '2016-05-11T07:15:08',
      end: '2016-05-11T09:08:08'
    }],
    actions: {
      tester() {
        // console.log(this.get('appointmentChartLabels'));
        console.log(this.get('appointmentChartData'));
      },

      routeClick(tag) {
        this.get("routing").transitionTo(tag);
      },

      scrollToAppointments() {
        let parentElem = (0, _jquery.default)('.app-workspace');
        let childElem = (0, _jquery.default)('.appointment-view');
        var position = childElem.position().top + parentElem.scrollTop() - parentElem.position().top;
        parentElem.animate({
          scrollTop: position
        });
      }

    }
  });

  _exports.default = _default;
});
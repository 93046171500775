define("hmis/helpers/has-nested-drilldown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasNestedDrilldown = hasNestedDrilldown;
  _exports.default = void 0;

  function hasNestedDrilldown([reportRow]
  /*, hash*/
  ) {
    //reportRow.drillDown reportRow.drillDown.isDrillDownParent
    if (!reportRow.drillDown) return false;
    if (!reportRow.drillDown.length) return false;
    return reportRow.drillDown[0].isDrillDownParent;
  }

  var _default = Ember.Helper.helper(hasNestedDrilldown);

  _exports.default = _default;
});
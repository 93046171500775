define("hmis/templates/components/pickers/patient-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dUAfzSPJ",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[28,\"pickers/input-picker-server-paginated\",null,[[\"class\",\"data\",\"disabled\",\"required\",\"textField\",\"searchEnabled\",\"label\",\"selected\",\"placeholder\",\"columns\",\"selectedChange\"],[[24,[\"class\"]],[24,[\"patientsData\"]],[24,[\"disabled\"]],true,\"fullName\",true,\"Patient\",[24,[\"selected\"]],[24,[\"placeholder\"]],[24,[\"patientsPickColumns\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selected\"]]],null]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/pickers/patient-input.hbs"
    }
  });

  _exports.default = _default;
});
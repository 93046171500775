define("hmis/templates/components/controls/check-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MWTYjsGG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-checkbox\",null,[[\"disabled\",\"value\",\"onChange\"],[[24,[\"disabled\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[22,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/check-box.hbs"
    }
  });

  _exports.default = _default;
});
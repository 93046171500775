define("hmis/controllers/hmis-app/records/patient/questionnaires/index", ["exports", "hmis/mixins/page-error-handler"], function (_exports, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pageErrorHandler.default, {
    notify: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    actions: {
      onItemClick(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.records.patient.questionnaires.edit", [rid]);
      },

      onCancel() {}

    }
  });

  _exports.default = _default;
});
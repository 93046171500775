define("hmis/components/controls/list-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showSearch: true,
    classNames: ['list-view'],
    classNameBindings: ['addable:addable', 'fullHeight:full-height'],
    fullHeight: true,
    //
    hideView: true,
    //
    addRecordText: 'Add new record',
    cancelRecordText: 'Cancel Addition',
    noDataMessageSize: 'medium',
    noDataMessage: 'no records available',
    viewText: 'View',
    editText: 'Edit',
    deleteText: 'Delete',
    pageIndex: 1,
    pageLimit: 50,
    hideFooter: false,
    viewable: true,
    selectedItems: [],
    pageLimits: [10, 25, 50, 100],
    listComponent: Ember.computed('serverPaginated', function () {
      return this.serverPaginated ? 'list-filter-no-elem-server-paginated' : 'list-filter-no-elem';
    }),
    // hasNextPage: computed('pageIndex', function() {
    //   // body
    // }),
    // hasPrevPage: computed('pageIndex', function() {
    //   return this.get('pageIndex')>1
    // }),
    actions: {
      // itemEvent(record){
      // }
      decreasePageIndex() {
        let pi = this.get('pageIndex');
        if (pi > 1) this.set('pageIndex', Number(pi) - 1);
      },

      increasePageIndex() {
        let pi = this.get('pageIndex');
        this.set('pageIndex', Number(pi) + 1);
      },

      firstPage() {
        //pageLimit
        this.set('pageIndex', 1);
      },

      lastPage(listLength) {
        //pageLimit
        let pl = this.get('pageLimit');
        let pi = Math.round(listLength / Number(pl));
        pi = pi * pl < listLength ? pi + 1 : pi;
        this.set('pageIndex', pi);
      },

      pageLimitChange(value) {
        this.set('pageLimit', value);
        this.set('pageIndex', 1);
      },

      deleteRecord(record) {
        let action = this.get('onDelete');
        if (action) action(record);
      },

      saveRecord(record) {
        this.set('editItem', null);
        let action = this.get('onSave');
        if (action) action(record);
      },

      cancelRecord(record) {
        if (this.onCancel) this.onCancel(record);else if (record.rollbackAttributes) record.rollbackAttributes();
      },

      addModeSaveClick() {
        this.get('submitForm')();
      },

      saveNewRecord() {
        let action = this.get('onSaveNewRecord');
        if (action) action();
      },

      editClick(record) {
        let action = this.get('onEdit');
        if (action) action(record);else {
          this.set('editItem', record);
          this.set('viewItem', null);
        }
      },

      // onViewClick(record)
      // {
      //     let  action= this.get('onView')
      //     if(action)
      //         action(record)
      //     else{
      //       this.set('viewItem', record);
      //     }
      //     console.log('action',action);
      // },
      viewClick(item) {
        // alert('ddddddd')
        if (this.get('selectable')) {
          let sItems = this.get('selectedItems');

          if (sItems.includes(item)) {
            sItems.removeObject(item);
          } else {
            if (!this.get('multiSelect')) {
              this.set('selectedItems', [item]);
            } else {
              sItems.pushObject(item);
            }
          }
        } else {
          let action = this.get('onClick');
          if (action) action(item);else {
            this.set('viewItem', item);
          }
        }

        if (this.onItemClick) this.onItemClick(item);
      },

      doubleClickRecord(record) {
        let action = this.get('onDoubleClick');
        if (action) action(record);
      },

      onUnFocusClick() {
        this.set('editItem', null);
        this.set('viewItem', null);
      }

    }
  });

  _exports.default = _default;
});
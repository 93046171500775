define("hmis/templates/components/controls/date-range-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tPnWFv+x",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"date-range-chooser\"],[8],[0,\"\\n\"],[0,\"\\n\"],[0,\"    \"],[1,[28,\"paper-input\",null,[[\"label\",\"type\",\"class\",\"value\",\"onChange\"],[\"From\",\"date\",\"full-width margin-top-20\",[24,[\"fromDate\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"fromDate\"]]],null]],null]]]],false],[0,\"\\n\\n\"],[0,\"    \"],[1,[28,\"paper-input\",null,[[\"label\",\"type\",\"class\",\"value\",\"onChange\"],[\"To\",\"date\",\"full-width\",[24,[\"toDate\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"toDate\"]]],null]],null]]]],false],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/date-range-chooser.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/models/immunization", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util"], function (_exports, _emberData, _relationships, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    patient: (0, _relationships.belongsTo)('patient'),
    // name: DS.attr(),
    // name: belongsTo('immunization-type'),
    type: (0, _relationships.belongsTo)('immunization-type'),
    typeName: _emberData.default.attr(),
    vaccine: _emberData.default.attr(),
    status: _emberData.default.attr(),
    date: _emberData.default.attr(),
    reqDateTimeF: Ember.computed('date', function () {
      return "".concat(_dateUtil.default.formatToDateTimeString(this.get('date')));
    })
  });

  _exports.default = _default;
});
define("hmis/templates/components/empty-space", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pirHqR9M",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"style\",[29,[\"width:\",[22,\"width\"],\"; height:\",[22,\"height\"]]]],[8],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/empty-space.hbs"
    }
  });

  _exports.default = _default;
});
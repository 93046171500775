define("hmis/controllers/hmis-app/records/patient/shared-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    app: Ember.inject.service(),
    data: {
      items: []
    },
    selectedConsent: null,
    actions: {
      back() {
        history.back();
      },

      fetchData(consentOption) {
        this.set('selectedConsent', consentOption); // console.log(dataOption);

        this.set('listWait', true);
        this.app.startLoading();
        let cacheLink = 'patientSharedData.pat' + consentOption.consentId.replace('.', '_') + this.model.id;
        this.appAjax.getCacheable('/patients/' + this.model.id + '/sharedData/' + consentOption.consentId, null, cacheLink).then(rec => {
          this.set('data', rec);
          this.set('listWait', false);
          this.set('contentError', false);
          this.app.stopLoading();
        }).catch(err => {
          console.error(err);
          this.set('contentError', err);
          this.set('listWait', false);
          this.app.stopLoading();
        });
      }

    }
  });

  _exports.default = _default;
});
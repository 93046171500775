define("hmis/models/department-unit", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    department: (0, _relationships.belongsTo)('department'),
    //, {async: false}
    departmentName: _emberData.default.attr(),
    isConsultation: _emberData.default.attr(),
    service: (0, _relationships.belongsTo)('other-billable'),
    type: _emberData.default.attr(),
    //1=lab, 2 = ward
    gender: (0, _relationships.belongsTo)('gender'),
    //DS.attr(), //1=lab, 2 = ward
    rooms: (0, _relationships.hasMany)('room', {
      async: false
    }),
    serviceName: _emberData.default.attr(),
    doesConsultation: Ember.computed('isConsultation', function () {
      return this.get('isConsultation') ? 'Yes' : 'No';
    }),
    typeName: Ember.computed('type', function () {
      let type = this.get('type');

      if (type == 1) {
        return 'Lab';
      }

      if (type == 2) {
        return 'Ward';
      }

      return 'No Type';
    })
  });

  _exports.default = _default;
});
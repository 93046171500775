define("hmis/templates/components/controls/tab-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p/sCzrQR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"li\",true],[11,\"class\",[29,[\"tab-button \",[28,\"if\",[[24,[\"active\"]],\"active\",\"\"],null],\" \",[28,\"if\",[[24,[\"tabfirst\"]],\"tabfirst\",\"\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[8],[0,\"\\n    \"],[7,\"a\",true],[8],[0,\"\\n        \"],[1,[22,\"text\"],false],[0,\" \\n\"],[4,\"if\",[[24,[\"badge\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"badge-text\"],[8],[1,[22,\"badge\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/tab-button.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/models/facility-offered-service", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    offeredService: (0, _relationships.belongsTo)('offered-service'),
    facility: (0, _relationships.belongsTo)('facility'),
    name: Ember.computed('offeredService.name', function () {
      return "".concat(this.get('offeredService.name'));
    })
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/users/user/payment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      //return this.get('store').findAll('user-payment-mode');
      let mod = this.modelFor("hmis-app.users.user");
      return Ember.RSVP.hash({
        userPayment: this.get('store').query('user-payment-mode', {
          filter: {
            userId: mod.get('id')
          },
          include: ['paymentModeName', 'paymentModeTypeName'],
          pageSize: 25
        }),
        userId: mod
      });
    },

    actions: {
      reloadModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //   const { inject: { service }, Component } = Ember;
  var _default = Ember.Controller.extend({
    tagName: '',
    navigator: Ember.inject.service(),
    sideMenuCollapsed: false,
    quickActionCollapsed: true,
    currentUser: Ember.inject.service(),
    app: Ember.inject.service(),
    notify: Ember.inject.service(),
    changePassInt: 0,
    router: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.changePassInt = parseInt(this.get('currentUser.systemPassword')); //   if(this.changePassInt===0){
      //       console.log('changePassInt',this.changePassInt);
      //     this.router.transitionTo('hmis-app.dashboard')
      //   }
      //   if(this.get('currentUser.user.fullName'))
      //   this.get('notify').info('Welcome '+this.get('currentUser.user.fullName'))
    },

    actions: {
      toggleQuickAction(event) {
        this.set("quickActionCollapsed", !this.get("quickActionCollapsed"));
      },

      logout() {
        this.get('session').invalidate();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/admin-settings/department-management/department", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      console.log('params', params);
      return Ember.RSVP.hash({
        departmentUnits: this.get('store').query('department-unit', {
          filter: {
            departmentId: params.id
          },
          include: ['departmentName'],
          pageSize: 25
        }),
        wards: this.get('store').query('ward', {
          filter: {
            departmentId: params.id,
            type: 2
          },
          pageSize: 25
        }),
        id: params.id
      });
    },

    actions: {
      reloadModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
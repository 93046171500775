define("hmis/templates/components/managers/ward-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QMlqNj/X",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"form-container\",null,[[\"class\"],[\"full-width\"]],{\"statements\":[[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"style\",\"margin-top: 20px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-30\"],[10,\"style\",\"margin: 0 20px\"],[8],[0,\"\\n  \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"name\"]]],null]],null],\"Room\",\"hotel\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"style\",\"margin: 0 20px\"],[8],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"submit-button float-right\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"addRoom\"],null]],[8],[0,\"Add Room\"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\" \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/ward-manager.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/viewers/vitals-batch-viewer-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VRqRBec+",
    "block": "{\"symbols\":[\"mt\"],\"statements\":[[4,\"alerts/confirm-dialog\",null,[[\"show\",\"title\",\"message\",\"width\",\"height\",\"isConfirm\"],[[24,[\"show\"]],[28,\"concat\",[\"Vital for \",[24,[\"batch\",\"dateTimeF\"]]],null],[24,[\"note\"]],\"80%\",\"80%\",false]],{\"statements\":[[0,\"  \"],[7,\"h4\",true],[10,\"style\",\"width: 100%;text-align: center;text-transform: uppercase !important;\"],[8],[0,\"BMI: \"],[1,[22,\"bmi\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\" stats-container  full-height has-main-table\"],[8],[0,\"\\n\"],[4,\"controls/models-table\",null,[[\"tagName\",\"showGlobalFilter\",\"data\",\"filterString\",\"themeInstance\",\"filteringIgnoreCase\",\"useFilteringByColumns\",\"selectedItems\",\"multipleSelect\",\"useFilteringByColumns\",\"filteringIgnoreCase\",\"selectRowOnClick\",\"columns\"],[\"\",false,[24,[\"vitals\"]],[24,[\"query\"]],[24,[\"themeInstance\"]],true,false,[24,[\"selectedItems\"]],true,false,true,false,[24,[\"columns\"]]]],{\"statements\":[[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n                    \"],[1,[23,1,[\"table\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\\n            \"],[7,\"div\",true],[10,\"class\",\"app-fixed-bottom\"],[8],[0,\"\\n                \"],[1,[23,1,[\"footer\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n    \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/viewers/vitals-batch-viewer-alert.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/error-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9WEOgfkK",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\\n    \"],[5,\"stats-container\",[[12,\"class\",\"full-height has-main-table\"]],[[\"@fullHeight\",\"@title\"],[true,\"Unable to Access Page\"]],{\"statements\":[[0,\"\\n        \"],[5,\"error-viewer\",[],[[\"@page\",\"@errorResult\"],[[22,\"page\"],[22,\"errorResult\"]]]],[0,\"\\n    \"]],\"parameters\":[]}],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/error-page.hbs"
    }
  });

  _exports.default = _default;
});
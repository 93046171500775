define("hmis/mixins/visit-care-route", ["exports", "hmis/utils/deep-merge", "hmis/utils/patient-serve-util"], function (_exports, _deepMerge, _patientServeUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupController(controller, model) {
      this._super(...arguments);

      controller.set('serviceName', this.serviceName);

      if (controller.initCare) {
        controller.initCare();
      }
    },

    cloneParentCareModel() {
      return (0, _patientServeUtil.cloneServeModel)(this.getParentCareModel());
    },

    getParentCareModel() {
      if (!this.parentCareRoute) {
        console.error('parentCareRoute variable is undefined in route');
        return;
      }

      return this.modelFor(this.parentCareRoute);
    },

    requestDataQuery(params = {}) {
      params = (0, _deepMerge.deepmerge)({
        filter: {
          status: 0,
          visitId: this.getParentCareModel().visitId
        },
        include: [this.getServiceTypeName() + "Name", this.getServiceTypeName() + "GdrgCode", 'patientName', 'requesterName'],
        pageSize: 100
      }, params);
      return this.get('store').query(this.serviceName, params);
    },

    resultDataQuery(params = {}) {
      params = (0, _deepMerge.deepmerge)({
        filter: {
          status: 1,
          visitId: this.getParentCareModel().visitId
        },
        include: [this.getServiceTypeName() + "Name", this.getServiceTypeName() + "GdrgCode", 'patientName', 'requesterName', 'granterName'],
        pageSize: 100
      }, params);
      return this.get('store').query(this.serviceName, params);
    },

    dataTypeQuery(params = {}) {
      params = (0, _deepMerge.deepmerge)({
        pageSize: 20
      }, params);
      return this.get('store').query(this.serviceTypeName || this.serviceName + '-type', params);
    },

    getServiceTypeName() {
      return this.serviceTypeName ? this.serviceTypeName : this.serviceName + "Type";
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
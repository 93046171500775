define("hmis/routes/configure/vitalsigns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    controllerName: 'hmis-app.admin-settings.facility-examination',
    templateName: 'hmis-app.admin-settings.facility-examination'
  });

  _exports.default = _default;
});
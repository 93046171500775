define("hmis/templates/components/managers/department-unit-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hot6NkQ+",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"form-container\",null,[[\"class\"],[\"full-width\"]],{\"statements\":[[0,\"    \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"name\"]]],null]],null],\"Name\",\"person-outline\"]]],false],[0,\"\\n\"],[4,\"app-select\",null,[[\"class\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"flex_level\"]],\"Type\",[24,[\"record\",\"department\"]],[24,[\"departments\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"department\"]]],null]],null]]],{\"statements\":[[0,\"            \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/department-unit-manager.hbs"
    }
  });

  _exports.default = _default;
});
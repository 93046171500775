define("hmis/helpers/report-reference-to-array", ["exports", "hmis/utils/reports"], function (_exports, _reports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reportReferenceToArray = reportReferenceToArray;
  _exports.default = void 0;

  function reportReferenceToArray([serverReport, referenceName]) {
    return _reports.default.referenceToArray(serverReport, referenceName);
  }

  var _default = Ember.Helper.helper(reportReferenceToArray);

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/patient/profession", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { requestAdmission, requestDischarge } from '../../../utils/request-utils/ipd';
  //import dateUtil from '../../../utils/date-util';
  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    ajaxRequest: Ember.inject.service(),
    serverUploads: Ember.inject.service(),
    app: Ember.inject.service(),
    consultationService: Ember.inject.service('consultation'),
    currentUser: Ember.inject.service(),
    //showBillPaymentStatus: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    patientName: Ember.computed('model.patientName', function () {
      return this.get('model.patientName');
    }),
    visitOutcomes: Ember.computed(function () {
      return this.get('store').findAll('visit-outcome');
    }),
    title: 'Patient',
    input_width: 'flex-45',
    input_width_full: 'flex-100',
    transactions: [],
    onConfirm: 'empty',
    onCancel: 'empty',
    tabIndex: 0,
    // isEdit:true,
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      onRowClick(index, record) {
        this.set('previewFile', record.get('location'));
        this.set('previewTitle', record.get('name'));
        this.set('showPreviewFile', true);
      },

      onEditClick(even) {
        this.set('isEdit', true);
      },

      deleteRecord(record) {
        record.destroyRecord();
      },

      downloadRecord(record) {
        record.downloadFile(); // this.get('ajaxRequest').downloadFile(record.get('fileUrl'), record.get('name'))
      },

      onSaveClick(event) {
        // this.get('notify').notifyInfo('Saving please wait', true)
        let record = this.get('model');
        this.get('ajaxRequest').applyProcedure(record, {
          success: response => {
            this.set('isEdit', false);
            this.notify.success('Saved');
            this.get('app').loadDashboardStats();
          },
          error: error => {
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      onPictureChange(file) {
        this.get('serverUploads').uplaodPatientDocument(this.get('model.patient'), file, {
          success: response => {
            this.notify.success("file uploaded");
            this.send('refreshModel');
          },
          error: error => {
            this.notify.error("Unable to upload. Something went wrong" + xhr);
            console.log('sd', xhr);
          }
        });
      },

      onCancelClick(event) {
        this.set('isEdit', false);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/
        ,
        messages: {
          "noDataToShow": "No documents uploaded"
        }
      });
    }),
    //date , patient, requestor
    columns: [{
      title: 'preview',
      component: 'filePreview'
    }, // {
    //     'title':'file image',
    //   "propertyName": "type",
    // //   "component": "checkBox"
    // },
    {
      'title': 'Name',
      "propertyName": "name",
      className: 'main-column'
    }, {
      'title': 'file type',
      "propertyName": "type" //

    }, {
      'title': 'Date added',
      "propertyName": "dateF"
    }, {
      title: 'Actions',
      component: 'manageRow'
    }]
  });

  _exports.default = _default;
});
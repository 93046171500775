define("hmis/models/relationship-type", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr() // maleInverse: belongsTo('relationship-type', {async: true}),
    // femaleInverse: belongsTo('relationship-type', {async: true})

  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/paged-report-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XYhNng7x",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[0,\"  \"],[1,[28,\"controls/high-charts\",null,[[\"mode\",\"chartOptions\",\"content\",\"theme\"],[[24,[\"mode\"]],[24,[\"chartOptions\"]],[24,[\"chatData\"]],[24,[\"theme\"]]]]],false],[0,\"\\n\"],[0,\"\\n\"],[0,\"\\n\"],[1,[28,\"log\",[\"chatData---------\",[24,[\"chatData\"]]],null],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/paged-report-chart.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/report-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NLPvYkVH",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[28,\"log\",[\"isDashborad selectedChartType.options2\",[24,[\"selectedChartType\"]]],null],false],[0,\"\\n\"],[1,[28,\"log\",[\"serverReport\",[24,[\"serverReport\",\"data\",\"length\"]]],null],false],[0,\"\\n\\n\"],[4,\"unless\",[[28,\"and\",[[24,[\"isDashborad\"]],[24,[\"selectedChartType\",\"options2\"]]],null]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"serverReport\",\"data\",\"length\"]],0],null]],null,{\"statements\":[[0,\"   \"],[5,\"controls/no-chart-content\",[],[[],[]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"controls/high-charts\",null,[[\"mode\",\"chartOptions\",\"content\",\"theme\"],[[24,[\"mode\"]],[24,[\"chartOptions\"]],[24,[\"content\"]],[24,[\"theme\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"selectedChartType\",\"options2\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"controls/high-charts\",null,[[\"mode\",\"chartOptions\",\"content\",\"theme\"],[[24,[\"mode\"]],[24,[\"chartOptions2\"]],[24,[\"content2\"]],[24,[\"theme\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/report-chart.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/mixins/route-error-handler", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupController(controller, model) {
      if (model.errors && model.errors.length) {
        controller.set('error', model);
        console.error(model);
      } else if (model.stack && model.message) {
        controller.set('error', model.message);
        console.error(model.message);
      } else {
        console.log('model', model);
        controller.set('error', null);
      }

      this._super(controller, model);
    }

  });

  _exports.default = _default;
});
define("hmis/components/app-workspace", ["exports", "hmis/mixins/page-error-handler"], function (_exports, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_pageErrorHandler.default, {
    // classNames:['app-workspace']
    tagName: ""
  });

  _exports.default = _default;
});
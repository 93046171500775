define("hmis/templates/components/top-bar-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nRueDe3D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"top-bar-button-container\"],[10,\"style\",\"position: relative\"],[8],[0,\"\\n\\n    \\n\"],[4,\"if\",[[24,[\"badge\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"floating ui orange label circular\"],[8],[1,[22,\"badge\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"tb-button-wrapper\"],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"top-bar-button app-tooltip \",[28,\"if\",[[24,[\"circleBorder\"]],\"round-primary\",\"\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[8],[0,\"\\n            \"],[1,[28,\"svg-jar\",[[24,[\"icon\"]]],[[\"class\",\"width\",\"height\"],[\"icon\",\"100%\",\"100%\"]]],false],[0,\"\\n            \"],[4,\"if\",[[24,[\"tooltip\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"tooltiptext\"],[8],[1,[22,\"tooltip\"],false],[9]],\"parameters\":[]},null],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[9],[0,\"\\n \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/top-bar-button.hbs"
    }
  });

  _exports.default = _default;
});
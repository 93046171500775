define("hmis/components/stack-pages/ins-man-departments", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    billables: [{
      name: 'medicines',
      text: 'Medicines',
      item: 'prescription',
      itemName: 'genericName',
      billableItemName: 'medicine'
    }, {
      name: 'investigations',
      text: 'Investigations',
      item: 'investigation',
      itemName: 'genericName',
      billableItemName: 'investigationType'
    }, {
      name: 'procedures',
      text: 'Procedures',
      item: 'procedure',
      itemName: 'genericName',
      billableItemName: 'procedureType'
    }, {
      name: 'xrays',
      text: 'X-Ray',
      item: 'xray',
      itemName: 'genericName',
      billableItemName: 'xrayType'
    }, {
      name: 'ultrasounds',
      text: 'Ultrasound',
      item: 'ultrasound',
      itemName: 'genericName',
      billableItemName: 'ultrasoundType'
    }, {
      name: 'otherBillables',
      text: 'Other billables',
      item: 'otherbillable',
      itemName: 'name',
      billableItemName: 'otherBillable'
    }, {
      name: 'diagnoses',
      text: 'Diagnosis',
      item: 'diagnosis',
      itemName: 'genericName',
      billableItemName: 'diagnosisType'
    }],
    // selectedBillable:{name: 'medicines', text:'Medicines', item:'prescription', itemName:'genericName' , billableItemName:'medicine'},
    newRecord: {},
    medicines: Ember.computed(function () {
      return this.get('store').findAll('medicine');
    }),
    investigations: Ember.computed(function () {
      return this.get('store').findAll('investigation-type');
    }),
    procedures: Ember.computed(function () {
      return this.get('store').findAll('procedure-type');
    }),
    xrays: Ember.computed(function () {
      return this.get('store').findAll('xray-type');
    }),
    ultrasounds: Ember.computed(function () {
      return this.get('store').findAll('ultrasound-type');
    }),
    otherBillables: Ember.computed(function () {
      return this.get('store').findAll('other-billable');
    }),
    diagnoses: Ember.computed(function () {
      return this.get('store').findAll('diagnosis-type');
    }),
    billableItems: Ember.computed('selectedBillable', function () {
      let selectedBillable = this.get('selectedBillable');
      return this.get(selectedBillable.name);
    }),
    department: Ember.computed(function () {
      this.set('loadingList', true);
      return this.get('store').findAll('department');
    }),
    loadIndex: 0,
    priceList: Ember.computed('product', 'selectedBillable', 'loadIndex', function () {
      //(get product selectedBillable.name)
      let selectedBillableName = this.get('selectedBillable.item');
      return _emberData.default.PromiseArray.create({
        promise: new Ember.RSVP.Promise((resolve, reject) => {
          this.set('loadingList', true);
          this.get('ajaxRequest').makeRequest('GET', "/insuranceProductBillables/" + selectedBillableName + "/" + this.get('product.id'), null, {
            success: response => {
              this.set('loadingList', false);
              resolve(response);
            },
            error: error => {
              this.set('loadingList', false);
              reject(error);
            }
          });
        })
      });
    }),
    actions: {
      addItem(item) {},

      reviewThreshold(record) {
        this.set('alertwaiting', true);
        var rec = this.get('store').query('threshold', {
          filter: {
            insuranceProductId: this.get('product.id'),
            itemId: record.id
          }
        }).then(function (r) {
          if (r.length) {
            return r.get('firstObject');
          }

          return null;
        });
        let that = this;
        rec.then(function (sr) {
          if (sr) {
            that.set('alertwaiting', false);
            sr.set('itemName', record.name);
            that.set('editThreshold', sr);
          } else {
            that.set('alertwaiting', false);
            that.set('editThreshold', {
              type: 'department',
              insuranceProduct: that.get('product'),
              itemId: record.id,
              itemName: record.name
            });
          }
        });
        this.set('alertTitle', record.name);
        this.set('thresholdDialog', true);
      },

      editThreshold(record) {
        this.set('editThreshold', record);
        this.set('thresholdDialog', true);
      },

      onSaved(record) {//this.send('refreshModel')
      },

      deleteRecord(record) {
        this.get("selectedItems").removeObject(record);
        record.destroyRecord();
      },

      saveRecord(record) {
        record.save();
      },

      saveNewRecord() {
        this.set('listWait', true);
        let newRecord = this.get('store').createRecord('payment-mode', this.get('newRecord'));
        newRecord.save().then(record => {
          // this.get('companies').pushObject(record)
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error('Unable to Save');
        });
      },

      removeItem(item) {
        let sBillable = this.get('selectedBillable');
        let product = this.get('product');
        this.get('product').removeBillable(sBillable.item, item.get('id')).then(() => {
          product.get(sBillable.name).removeObject(item);
        }, err => {
          console.log(err);
        });
      },

      saveNewRecord() {// this.set('listWait', true)
        // let sBillable= this.get('selectedBillable')
        // let product = this.get('product')
        // let item =this.get('newItem')
        // product.addBillable(sBillable.item, this.get('newItem.id')).then(
        //   ()=>{
        //     product.get(sBillable.name).pushObject(item)
        //     this.set('newItem', null)
        //     this.set('listWait', false)
        //     this.notify.success('Saved Successfully')
        //   },
        //   (err)=>{
        //     console.log(err)
        //     this.set('listWait', false)
        //     this.notify.error('Unable to Save')
        //   }
        // )
      },

      reviewPrice(record, editMode = false) {
        this.get('previewThreshold')(record, this.get('selectedBillable.item'), editMode);
        event.stopPropagation();
        event.preventDefault();
      }

    }
  });

  _exports.default = _default;
});
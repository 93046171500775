define("hmis/models/user", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util"], function (_exports, _emberData, _relationships, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import dummies from '../../vendor/dummies';
  var _default = _emberData.default.Model.extend({
    serverUploads: Ember.inject.service(),
    username: _emberData.default.attr(),
    firstName: _emberData.default.attr(),
    lastName: _emberData.default.attr(),
    middleName: _emberData.default.attr(),
    dateOfBirth: _emberData.default.attr(),
    role: (0, _relationships.belongsTo)('role'),
    //, {async: false}
    roleName: _emberData.default.attr(),
    // accountType: belongsTo('role'),
    title: _emberData.default.attr(),
    userStatus: _emberData.default.attr(),
    gender: _emberData.default.attr(),
    nationality: _emberData.default.attr(),
    countryOfResidence: _emberData.default.attr(),
    region: _emberData.default.attr(),
    town: _emberData.default.attr(),
    postAddress: _emberData.default.attr(),
    physicalAddress: _emberData.default.attr(),
    contactEmail: _emberData.default.attr(),
    phoneNumber: _emberData.default.attr(),
    alternatePhoneNumber: _emberData.default.attr(),
    picture: _emberData.default.attr(),
    setupUser: _emberData.default.attr(),
    isSystemUser: _emberData.default.attr(),
    accessLevel: _emberData.default.attr(),
    department: (0, _relationships.belongsTo)('department'),
    //, {async: false}
    // chagLevelDenomination: belongsTo('denomination'), //, {async: false}
    emailFailed: _emberData.default.attr(),
    //to check if email was sent during first creation
    //Non-inputs
    facility: _emberData.default.attr(),
    //NOTE: should not be commented.
    tin: _emberData.default.attr(),
    giscoordinates: _emberData.default.attr(),
    specialties: _emberData.default.attr(),
    specialtiesString: Ember.computed('specialties', function () {
      console.log('specialties', this.get('specialties'));
      var arr = this.get('specialties');
      var val = [];

      if (this.get('specialties')) {
        arr.forEach(element => {
          val.push(element.noun);
        });
        return val.toString(' , ');
      }
    }),
    fullName: _emberData.default.attr(),
    dateOfBirthF: Ember.computed('dateOfBirth', function () {
      return this.get('dateOfBirth') ? _dateUtil.default.formatToDateTimeString(this.get('dateOfBirth')) : '';
    }),
    facilityName: _emberData.default.attr(),
    departmentName: _emberData.default.attr(),
    roleName: _emberData.default.attr(),
    accountTypeName: _emberData.default.attr(),
    // userOperationPermissions: computed(function(){
    //   return DS.PromiseArray.create({
    //     promise: this.get('store').query('user-operation-permission', {
    //       filter:{
    //         user: this.get('id')
    //       }
    //     }).then( recs=>{
    //       return recs.toArray()
    //     })
    //   });
    // }),
    // userModulePermissions: computed(function(){
    //   return DS.PromiseArray.create({
    //     promise: this.get('store').query('user-module-permission', {
    //       filter:{
    //         user: this.get('id')
    //       }
    //     }).then( recs=>{
    //       return recs.toArray()
    //     })
    //   });
    // }),
    pictureUrl: Ember.computed('picture', function () {
      return this.get('serverUploads.downloadUrl') + '/' + this.get('picture');
    })
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/procedures/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    selectedItems: [],
    allowSelect: true,
    data: Ember.computed.alias('model'),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    selectedCount: Ember.computed("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    actions: {
      newRecord() {
        let record = this.get('record');
        this.transitionToRoute("hmis-app.admin-settings.procedures.add");
      },

      deleteRecord(record) {
        // this.set("allowSelect",false)
        this.get("selectedItems").removeObject(record);
        record.destroyRecord();
      },

      editRecord(record) {
        // alert(record.id);
        this.get("routing").transitionTo("hmis-app.admin-settings.procedures.edit", [record.id]);
      },

      rowDoubleClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.procedures.view", [rid]);
      },

      deleteRecords() {
        this.get("selectedItems").forEach((user, index) => {
          user.destroyRecord();
        });
        this.set("selectedItems", []);
      }

    }
  });

  _exports.default = _default;
});
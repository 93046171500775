define("hmis/controllers/hmis-app/opd/consultation/consult/events", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller", "hmis/mixins/visit-care-controller", "hmis/utils/common-data-columns"], function (_exports, _modelTableTheme, _formController, _visitCareController, _commonDataColumns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, _visitCareController.default, {
    store: Ember.inject.service('store'),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    currentUser: Ember.inject.service(),
    serverResponse: Ember.inject.service(),
    app: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    pickers: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      optionClick(option, record, options) {
        this.send(option.action, record, option, options);
      },

      endEvent(record, option, options) {
        let vColumns = [{
          component: "controls/select-row-checkbox",
          "useFilter": false,
          "mayBeHidden": false
        }, {
          title: 'DIagnosis',
          propertyName: 'diagnosisTypeName'
        }, {
          title: 'Icd Code',
          propertyName: 'icdCode'
        }, {
          title: 'Visit',
          propertyName: 'visitDateF',
          className: 'vsv-date-col',
          component: 'controls/table-link',
          onClick: rec => {
            this.visitViewer.show({
              id: rec.visitId
            });
          },

          formatText(text, rec) {
            return rec.dateF + " : " + rec.visitPurpose;
          }

        }];
        this.pickers.serverTable.show({
          modelName: 'diagnosis',
          columns: vColumns,
          title: 'Select the primary diagnosis for Event',
          query: {
            filter: {
              eventId: record.id
            },
            include: ['visitPurpose', 'diagnosisTypeName', 'visitDate']
          }
        }, diagnosis => {
          this.container.startLoading();
          this.appAjax.post("endVisitEvent/".concat(record.id), {
            primaryDiagnosisId: diagnosis.id
          }).then(res => {
            this.notify.success('Event has been ended');
            Ember.set(option, 'hidden', true);
            this.send('refreshModel');
          }).catch(err => {
            this.notify.error(err);
          }).finally(() => {
            this.container.stopLoading();
          });
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    rowOptions: [{
      label: "End Event",
      inline: true,
      class: 'primary-outline',
      action: "endEvent",
      hideWhen: (record, value) => record.lastVisitDate
    }],
    columns: _commonDataColumns.eventsColumns.concat([{
      'title': '',
      "component": "manageRow"
    }])
  });

  _exports.default = _default;
});
define("hmis/components/status-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    isIn: Ember.computed("status", function () {
      return this.get("status").toLowerCase() == "in";
    }),
    isOut: Ember.computed("status", function () {
      return this.get("status").toLowerCase() == "out";
    })
  });

  _exports.default = _default;
});
define("hmis/templates/hmis-app/admin-settings/facility-types/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jsxmc+9A",
    "block": "{\"symbols\":[\"sCont\"],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"multiPages\",\"title\",\"iniComponent\",\"iniComponentProps\"],[\"full-height\",true,true,\"Facility Types\",\"stack-pages/facility-type\",[28,\"hash\",null,[[\"someProp\"],[\"\"]]]]],{\"statements\":[],\"parameters\":[1]},null],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/admin-settings/facility-types/index.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/mixins/data-serializer copy", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    keyForAttribute: function (attr) {
      if (attr == 'createdAt' || attr == 'updatedAt') return Ember.String.underscore(attr);else {
        return Ember.String.camelize(attr);
      }
    },

    keyForRelationship(key, relationship, method) {
      //going to
      // console.log('some relationship', relationship)
      if (relationship == 'belongsTo') return key + "Id";
      return key;
    },

    normalize(typeClass, hash) {
      for (var field in hash) {
        if (Object.prototype.hasOwnProperty.call(hash, field)) {
          // do stuff
          if (field.endsWith('_JSProp')) {
            if (hash[field]) {
              let newField = field.replace('_JSProp', '');
              hash[newField] = JSON.parse(hash[field]);
              delete hash[field];
            }
          }
        }
      }

      return this._super.apply(this, arguments);
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      let model = primaryModelClass.modelName;
      payload[model] = payload['data'];
      delete payload.data;
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    serializeIntoHash(data, type, record, options) {
      Ember.assign(data, this.serialize(record, options));
    }

  });

  _exports.default = _default;
});
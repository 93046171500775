define("hmis/mixins/billable-model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    billableStatus: _emberData.default.attr(),
    isPaid: _emberData.default.attr(),
    transactionId: _emberData.default.attr(),
    isInsurance: _emberData.default.attr()
  });

  _exports.default = _default;
});
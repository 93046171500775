define("hmis/routes/hmis-app/admin-settings/insurance-products/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.get('store').findRecord('insurance-product', params.record_id);
    }

  });

  _exports.default = _default;
});
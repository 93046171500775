define("hmis/components/viewers/progress-chart-alert", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/ref-component", "hmis/constants/vital-types"], function (_exports, _modelTableTheme, _refComponent, _vitalTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    store: Ember.inject.service('store'),
    tabStatus: 0,
    loading: true,

    loadchart(visitId) {
      this.set('loading', true);
      if (this.visitId != visitId) this.set('vitalBatch', null);
      this.set('visitId', visitId);
      this.get('store').query('vital-batch', {
        filter: {
          visit: visitId
        },
        include: ['vitals']
      }).then(recs => {
        this.set('loading', true);
        let s_recs = recs.toArray().sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        let gropVital = [];
        s_recs.forEach(rec => {
          rec.vitals.forEach(vit => {
            gropVital.pushObject(vit);
          });
        });
        let gvitals = gropVital.reduce((result, currentValue) => {
          //grouping vitals
          (result[currentValue['vitalTypeName']] = result[currentValue['vitalTypeName']] || []).push(currentValue);
          return result;
        }, {});
        let strVitals = [];
        Object.entries(gvitals).forEach(([key, value]) => {
          // chart data struct
          let vCat = [];
          let vArr = [];
          let vData = [];
          let vlabel = key;
          let mUnit = '';
          let nm = [];
          let dm = [];
          let bpVal = '';
          let label = '';
          value.forEach(dat => {
            mUnit = dat.unitOfMeasureName;
            vCat.push(dat.batch.get('dateF'));

            if (dat.vitalTypeId === _vitalTypes.VITAL_TYPES.BLOOD_PREASSURE) {
              bpVal = _vitalTypes.VITAL_TYPES.BLOOD_PREASSURE;
              let tempVal = dat.value.split("/");
              nm.push(Math.floor(tempVal[0]));
              dm.push(Math.floor(tempVal[1]));
            } else {
              vArr.push(Math.floor(dat.value));
            }
          });
          let dset = {};

          if (bpVal === '3') {
            dset = [{
              data: nm
            }, {
              data: dm
            }];
          } else {
            dset = [{
              data: vArr
            }];
          }

          vData = dset;
          label = vlabel + ' (' + mUnit + ')';
          strVitals.pushObject({
            label,
            vCat,
            vData
          });
        });
        this.set('vitalBatch', strVitals);
        this.set('loading', false);
      }).catch(err => {
        console.error(err);
        this.set('loading', false);
      });
      this.set('show', true);
    },

    bpData: Ember.computed('chartOptions', 'vitalBatch', function () {
      let v = this.get('vitalBatch');
      if (!v) return [];
      let vChart = []; // vitals.then(function(v){

      v.forEach(vital => {
        let options = {
          chart: {
            height: 200,
            type: 'line'
          },
          title: {
            text: null
          },
          xAxis: {
            categories: vital.vCat
          },
          yAxis: {
            title: {
              text: vital.label
            }
          },
          legend: {
            enabled: false
          },
          exporting: {
            enabled: false
          },
          plotOptions: {
            line: {
              dataLabels: {
                enabled: true
              },
              enableMouseTracking: false
            }
          }
        };
        let chartData = vital.vData;
        vChart.pushObject({
          options,
          chartData
        });
      }); // })

      return vChart;
    }),

    loadcare(ptId, vtId) {
      this.get('store').query('ipd-care', {
        filter: {
          patientId: ptId,
          visitId: vtId
        }
      }).then(recs => {
        this.set('careList', recs);
      }).catch(err => {
        console.error(err);
      });
    },

    actions: {
      onNewTabClick() {},

      onHistoryTabClick() {},

      rowClick() {}

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "title": "Administered",
      "propertyName": "value"
    }, {
      "title": "Type",
      "propertyName": "careType"
    }, {
      "title": "Time",
      "propertyName": "dateF"
    }, {
      "title": "Note",
      "propertyName": "note"
    }, {
      "title": "Administered By",
      "propertyName": "user.fullName"
    }]
  });

  _exports.default = _default;
});
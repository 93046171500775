define("hmis/helpers/upload-link", ["exports", "hmis/config/environment", "hmis/utils/server-util"], function (_exports, _environment, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.uploadLink = uploadLink;
  _exports.default = void 0;

  function uploadLink([link]
  /*, hash*/
  ) {
    return _serverUtil.uploadsUrl + '/' + link;
  }

  var _default = Ember.Helper.helper(uploadLink);

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/insurance-management/insurance/index", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tagName: '',
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    routing: Ember.inject.service('router'),
    notify: Ember.inject.service(),
    insTitle: Ember.computed('model', function () {
      let rec = this.get('model').get('firstObject');
      return rec ? rec.insuranceCompanyName : '';
    }),
    newRecord: {},
    actions: {
      deleteRecord(record) {
        this.notify.confirmSimple('Attempting to delete  ' + record.name, null, () => {
          this.set('listWait', true);
          record.destroyRecord();
          this.send('refreshModel');
          this.set('listWait', false);
        });
      },

      saveRecord(record) {
        record.save();
      },

      saveNewRecord() {
        this.set('listWait', true);
        let data = this.get('newRecord');
        data.insuranceCompany = this.get('model').get('firstObject').insuranceCompany; // console.log('mod',this.get('model').get('firstObject').insuranceCompany);

        let newRecord = this.get('store').createRecord('insurance-product', data);
        newRecord.save().then(record => {
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.send('refreshModel');
          this.set('listWait', false);
        }).catch(failure => {
          this.set('listWait', false);
          this.notify.error('Unable to Save');
        });
      },

      onItemClick(record) {
        this.get("routing").transitionTo("hmis-app.admin-settings.insurance-management.insurance.product", record.id);
      }

    }
  });

  _exports.default = _default;
});
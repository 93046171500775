define("hmis/templates/hmis-app/admin-settings/facility-types/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/x232oGT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n                \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"icon\"],[\"New\",\"add-button\"]]],false],[0,\"\\n                \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"icon\"],[\"Delete\",\"delete\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n        \\n        \"],[7,\"div\",true],[10,\"class\",\"full-stats-container\"],[8],[0,\"\\n            \"],[1,[28,\"type-manager\",null,[[\"record\"],[[24,[\"record\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/admin-settings/facility-types/view.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/models/medicine", ["exports", "ember-data", "ember-data/relationships", "hmis/mixins/billable-item", "hmis/mixins/model-access-right"], function (_exports, _emberData, _relationships, _billableItem, _modelAccessRight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_billableItem.default, _modelAccessRight.default, {
    nhiaCode: _emberData.default.attr(),
    gdrgCode: _emberData.default.attr(),
    genericName: _emberData.default.attr(),
    unitOfPricing: (0, _relationships.belongsTo)('medicine-pricing-unit', {
      async: false
    }),
    // DS.attr(),
    unitOfPricingName: _emberData.default.attr(),
    prescribingLevels: _emberData.default.attr(),
    //hasMany('level-code'), // DS.attr(),
    // prescriptions: hasMany('prescription'),
    billableName: 'prescription'
  });

  _exports.default = _default;
});
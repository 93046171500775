define("hmis/models/symptom", ["exports", "ember-data", "ember-data/relationships", "hmis/mixins/visit-service-model", "hmis/utils/date-util"], function (_exports, _emberData, _relationships, _visitServiceModel, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, {
    symptomType: (0, _relationships.belongsTo)('symptom-type', {
      async: false
    }),
    symptomTypeId: _emberData.default.attr(),
    note: _emberData.default.attr(),
    symptomTypeName: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    dateF: Ember.computed('createdAt', function () {
      // return `${this.get('firstName')} ${this.get('lastName')}`;
      return _dateUtil.default.formatToDateString(this.get('createdAt'));
    })
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/ipd/ward/investigations", ["exports", "hmis/utils/general-util", "hmis/mixins/route-error-handler", "hmis/mixins/visit-care-route", "hmis/utils/visit-service-util"], function (_exports, _generalUtil, _routeErrorHandler, _visitCareRoute, _visitServiceUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, _visitCareRoute.default, {
    controllerName: 'hmis-app.opd.consultation.consult.investigations',
    templateName: 'hmis-app.opd.consultation.consult.investigations',
    breadCrumb: {
      title: 'Laboratory & Other Investigations'
    },
    serviceName: 'investigation',
    parentCareRoute: 'hmis-app.ipd.ward',

    model(params) {
      return (0, _visitServiceUtil.investigationOpdIpdModel)(this);
    }

  });

  _exports.default = _default;
});
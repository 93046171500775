define("hmis/components/controls/paged-report-filter-rows-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    },

    appAjax: Ember.inject.service(),
    tagName: '',
    selected: Ember.computed('filterValues.appSelections.selectedRows', 'filterValues.appSelections.selectedColumns', function (params) {
      if (this.structure == 'rows') return this.filterValues.appSelections.selectedRows;else return this.filterValues.appSelections.selectedColumns;
    }),
    _linkOptions: [],
    options: Ember.computed(function () {
      return new Promise((resolve, reject) => {
        if (this.filterItem.references) {
          this.filterItem.references.insertAt(0, this.allSelection);
          resolve(this.filterItem.references);
        }
      });
    }),
    linkOptions: Ember.computed('_linkOptions', function () {
      return this._linkOptions;
    }),
    actions: {
      searchLink(term) {
        return this.appAjax.post("/paged-reports/references/".concat(this.reportType, "/").concat(this.filterItem.key), {
          search: term
        }).then(res => {
          this.set('_linkOptions', res);
          return res;
        });
      },

      onChange(val) {
        console.log(val);

        if (this.structure == 'rows') {
          this.set('filterValues.selectedRows', val.map(a => a.id));
          this.set('filterValues.appSelections.selectedRows', val);
        } else {
          this.set('filterValues.selectedColumns', val.map(a => a.id));
          this.set('filterValues.appSelections.selectedColumns', val);
        }
      }

    }
  });

  _exports.default = _default;
});
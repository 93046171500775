define("hmis/models/other-billable", ["exports", "ember-data", "ember-data/relationships", "hmis/mixins/billable-item", "hmis/mixins/model-access-right"], function (_exports, _emberData, _relationships, _billableItem, _modelAccessRight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_billableItem.default, _modelAccessRight.default, {
    name: _emberData.default.attr(),
    nhiaCode: _emberData.default.attr(),
    gdrgCode: _emberData.default.attr(),
    billableName: 'otherbillable'
  });

  _exports.default = _default;
});
define("hmis/templates/hmis-app/admin-settings/insurance-companies/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "W9G+7YON",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Cancel\",[28,\"action\",[[23,0,[]],\"back\"],null],\"close\"]]],false],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Update\",[28,\"action\",[[23,0,[]],\"save\"],null],\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height\",true,\"Edit Insurance Company\"]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n            \"],[1,[28,\"managers/insurance-company-manager\",null,[[\"class\",\"record\",\"type\"],[\"full-width\",[24,[\"model\"]],\"edit\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/admin-settings/insurance-companies/edit.hbs"
    }
  });

  _exports.default = _default;
});
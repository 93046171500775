define("hmis/components/managers/appointment-manager", ["exports", "hmis/utils/date-util"], function (_exports, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      if (this.get('appointment.staff')) {
        this.set('hideStaff', true);
      }

      if (!this.get('appointment.day')) {
        this.set('appointment.day', _dateUtil.default.toSqlDateOnly(new Date()));
      }
    },

    input_width: "flex-45 flex-xs-100 flex-sm-100",
    store: Ember.inject.service('store'),
    currentUser: Ember.inject.service(),
    appData: Ember.inject.service(),
    selectedSchedules: [],
    patientsData: Ember.computed(function () {
      return this.get('store').findAll('patient');
    }),
    visitPurposes: Ember.computed(function () {
      return this.get('store').findAll('visit-purpose');
    }),
    isValidDate: Ember.computed('appointment.day', function () {
      let date = this.get('appointment.day');
      var d1 = new Date();
      var d2 = new Date(date);
      d1.setHours(0);
      d1.setMinutes(0);
      d1.setSeconds(0);
      d1.setMilliseconds(0);

      if (d2 < d1) {
        console.log(d2 + " < " + d1);
        return false;
      }

      return true;
    }),
    schedules: Ember.computed('appointment.staff', 'appointment.day', function () {
      let staff = this.get('appointment.staff'); //?this.get('appointment.staff'):[];

      let date = this.get('appointment.day'); //?this.get('appointment.day'):[];

      if (!this.get('isValidDate')) {
        return [];
      }

      if (!date || !staff) return [];
      console.log('staff', staff.id);
      return this.get('store').query('schedule', {
        filter: {
          staff: staff.id,
          day: _dateUtil.default.toSqlDateOnly(date)
        }
      });
    }),
    noRecordMessage: Ember.computed('appointment.staff', 'currentUser.user', 'isValidDate', function () {
      //(if appointment.staff (concat (if (eq appointment.staff.id currentUser.user.id) 'You' appointment.staff.fullName)  ' have no schedule on selected date') 'Select a Doctor/Staff' )
      if (!this.get('appointment.staff')) return 'Select a Doctor/Staff';
      if (!this.get('isValidDate')) return "select a valid date";
      return (this.get('appointment.staff.id') == this.get('currentUser.user.id') ? 'You' : this.get('appointment.staff.fullName')) + ' have no schedule on selected date';
    }),
    today: Ember.computed(function () {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1; //January is 0!

      var yyyy = today.getFullYear();

      if (dd < 10) {
        dd = '0' + dd;
      }

      if (mm < 10) {
        mm = '0' + mm;
      }

      today = yyyy + '-' + mm + '-' + dd;
      return today;
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    isInputsValid: Ember.computed('selectedSchedules.length', 'appointment.purpose', 'appointment.patient', function () {
      if (this.get('selectedSchedules.length') < 1) {
        return false;
      }

      if (!this.get('appointment.purpose')) {
        return false;
      }

      if (!this.get('appointment.patient')) {
        return false;
      }

      return true;
    }),
    validInputsObserver: Ember.observer('selectedSchedules.length', 'appointment.purpose', 'appointment.patient', function () {
      let isInputsValid = this.get('isInputsValid');
      this.set('validInputs', isInputsValid);
      this.set('appointment.schedule', this.get('selectedSchedules.firstObject'));
    }),
    actions: {
      staffChange(staff) {
        this.set('appointment.staff', staff);
        this.set('selectedSchedules', []);
      },

      dayChange(day) {
        this.set('appointment.day', day);
        this.set('selectedSchedules', []);
      } // choosePatientConfirm(patient){
      //   // alert(patient.get('fullName'))
      //   // console.log(patient);
      //   this.set('patient', patient);
      //   this.set('findPatient', false)
      // },


    }
  });

  _exports.default = _default;
});
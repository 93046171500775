define("hmis/components/managers/appointment-alert", ["exports", "hmis/utils/date-util", "hmis/mixins/ref-component", "hmis/utils/general-util"], function (_exports, _dateUtil, _refComponent, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    app: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    input_width: "flex-45 flex-xs-100 flex-sm-100",
    visitPurposes: Ember.computed(function () {
      return this.get('store').findAll('visit-purpose');
    }),
    saveAction: 'saveNew',
    selectedSchedules: [],
    canConfirm: Ember.computed('selectedSchedules.length', 'appointment.patient', 'appointment.purpose', function () {
      return this.selectedSchedules.length && this.appointment.patient && this.appointment.purpose;
    }),

    _initialize() {
      if (!this.appointment.day) {
        var appointDate = new Date();
        appointDate.setDate(appointDate.getDate() + 1);
        this.set('appointment.day', (0, _generalUtil.dateToPaperDate)(appointDate));
      }

      this.loadSchedules();
    },

    newAppointment(iniData, onSuccess = null, onFailed = null) {
      this.set('appointment', iniData || {});
      this.set('saveAction', 'saveNew');
      this.set('onSuccess', onSuccess);
      this.set('onFailed', onFailed);
      this.set('_show', true);

      this._initialize();
    },

    rescheduleAppointment(appointment, onSuccess = null, onFailed = null) {
      this.set('appointment', {
        staff: this.currentUser.user
      });
      this.set('oldAppointment', appointment);
      this.set('saveAction', 'saveRescheduleAppointment');
      this.set('onSuccess', onSuccess);
      this.set('onFailed', onFailed);
      this.set('_show', true);
      this.set('disablePatient', true);
      this.set('hideStaff', true); //load promise properties

      this.set('waiting', true);
      (0, _generalUtil.modelHash)({
        patient: appointment.get('patient'),
        purpose: appointment.get('purpose')
      }).then(res => {
        this.set('appointment.patient', res.patient);
        this.set('appointment.purpose', res.purpose), this._initialize();
      }).finally(() => {
        this.set('waiting', false);
      });
    },

    rescheduleActiveServe(activeServe, onSuccess = null, onFailed = null) {
      this.set('appointment', {
        patient: activeServe.get('patient')
      });
      this.set('activeServe', activeServe);
      this.set('saveAction', 'saveRescheduleActiveServe');
      this.set('onSuccess', onSuccess);
      this.set('onFailed', onFailed);
      this.set('_show', true);
      this.set('hidePatient', true);
      this.set('hideStaff', true); //load promise properties

      this.set('waiting', true);
      (0, _generalUtil.modelHash)({
        patient: activeServe.get('patient'),
        staff: activeServe.get('staff')
      }).then(res => {
        this.set('appointment.patient', res.patient);
        this.set('appointment.staff', res.staff);

        this._initialize();
      }).finally(() => {
        this.set('waiting', false);
      });
    },

    loadSchedules() {
      if (!this.appointment.staff || !this.appointment.day) {
        this.set('schedules', []);
        return;
      }

      this.set('selectedSchedules', []);
      this.set('loadingSchedules', true);
      this.get('store').query('schedule', {
        filter: {
          staffId: this.appointment.staff.id,
          day: _dateUtil.default.toSqlDateOnly(this.appointment.day)
        },
        include: ['periodName', 'periodStartTime', 'periodEndTime', 'periodBreak']
      }).then(res => {
        this.set('schedules', res);
      }).catch(err => {
        this.set('schedulesError', err);
      }).finally(() => {
        this.set('loadingSchedules', false);
      });
    },

    getSelectedScheduleId() {
      return this.get('selectedSchedules.firstObject.id');
    },

    actions: {
      staffChange(staff) {
        this.set('appointment.staff', staff);
        this.loadSchedules();
      },

      dayChange(day) {
        this.set('appointment.day', day);
        this.loadSchedules();
      },

      saveNew() {
        this.set('waiting', true);
        let appointment = this.appointment;
        this.appAjax.post('appointments', {
          scheduleId: this.getSelectedScheduleId(),
          purposeId: appointment.purpose.id,
          patientId: appointment.patient.id,
          staffId: appointment.staff.id
        }).then(appointment => {
          this.notify.success('Appointment created ');
          this.set('_show', false);
          if (this.onSuccess) this.onSuccess(appointment);
        }).catch(err => {
          this.notify.error(err);
          this.onFailed && this.onFailed(err);
        }).finally(() => {
          this.set('waiting', false);
        });
      },

      saveRescheduleAppointment() {
        this.set('waiting', true);
        let appointment = this.appointment;
        this.appAjax.post('appointments/' + this.oldAppointment.id + '/reschedule', {
          scheduleId: this.getSelectedScheduleId(),
          purposeId: appointment.purpose.id,
          patientId: appointment.patient.id,
          staffId: appointment.staff.id
        }).then(appointment => {
          this.notify.success('Appointment rescheduled ');
          this.set('_show', false);
          if (this.onSuccess) this.onSuccess(appointment);
        }).catch(err => {
          this.notify.error(err);
          this.onFailed && this.onFailed(err);
        }).finally(() => {
          this.set('waiting', false);
        });
      },

      saveRescheduleActiveServe() {
        this.set('waiting', true);
        let appointment = this.appointment;
        this.appAjax.post('activeServes/' + this.activeServe.id + '/reschedule', {
          scheduleId: this.getSelectedScheduleId(),
          purposeId: appointment.purpose.id,
          patientId: appointment.patient.id,
          staffId: appointment.staff.id
        }).then(appointment => {
          this.notify.success('Active serve has been rescheduled ');
          this.set('_show', false);
          if (this.onSuccess) this.onSuccess(appointment);
        }).catch(err => {
          this.notify.error(err);
          this.onFailed && this.onFailed(err);
        }).finally(() => {
          this.set('waiting', false);
        });
      } /////////////////////////////////////
      // onConfirm(){
      //     if(this.get('reschedule')){
      //         if(this.get('fromActiveServe')){
      //             let activeServe = this.get('activeServe')
      //             if(!activeServe){
      //                 console.error("no activeServe provided for rescheduling. Provide activeServe or if trying to reschedule from a appointment then do not pass 'fromActiveServe=true'")
      //                 return
      //             }
      //         }else{
      //             let appointment = this.get('appointment')
      //             if(!appointment){
      //                 console.error("no appointment provided for rescheduling. Provide appointment or if trying to reschedule from a activeServe then pass 'fromActiveServe=true'")
      //                 return
      //             }
      //         }
      //     }
      //     let data = this.get('data');
      //     let that = this;
      //     this.get('store').createRecord('appointment',{
      //         schedule: data.schedule,
      //         purpose: data.purpose,
      //         patient: data.patient,
      //     }).save().then((appointment)=>{
      //         if(!this.get('reschedule')){ //for  new ppointment only
      //             this.set('data.patient', null)
      //             this.set('data.purpose', null)
      //             this.notify.success('Appointment created for '+data.schedule.get('dueRange'))
      //             let action = this.get('onSuccess')
      //             if(this.onSuccess)
      //                 this.onSuccess(appointment);
      //         }else{ //for rescheduling
      //             if(this.get('fromActiveServe')){ //from activeServe
      //                 let activeServe = this.get('activeServe')
      //               //activeServe.get('visit').end()
      //                 console.log('active visit ended')
      //               //let activeServe = this.get('model')
      //               //this.set('endingServe', true)
      //                 this.get('ajaxRequest').endActiveServe(activeServe, {
      //                   success: (oldAS)=>{
      //                     // this.set('endingServe', false)
      //                     // this.notify.success('Serve ended')
      //                     // history.back()
      //                     that.notify.success('Appointment rescheduled for '+data.schedule.get('dueRange'))
      //                     let action = that.get('onSuccess')
      //                     if(action)
      //                         action(appointment, oldAS);
      //                   },
      //                   error: (error)=>{
      //                     // this.set('endingServe', false)
      //                     // this.notify.error('Sorry! Something went wrong')
      //                     that.notify.error('Something went wrong when trying to end active serve')
      //                     console.log(error)
      //                   }
      //                 });
      //             }else{//from an existing appoinmetn
      //                 this.set('appointment.endDate', dateUtil.toSqlDate(new Date()) )
      //                 this.get('appointment').save().then((oldAppointment)=>{
      //                     that.notify.success('Appointment rescheduled for '+data.schedule.get('dueRange'))
      //                     let action = that.get('onSuccess') 
      //                     this.get('app').loadDashboardStats();
      //                     if(action)
      //                         action(appointment, oldAppointment);
      //                 },(error)=>{
      //                     that.notify.error('Something went wrong when trying to end previous appointment')
      //                     console.log(error)
      //                 })
      //             }
      //         }
      //         this.get('app').loadDashboardStats();
      //     },(error)=>{
      //         this.notify.error('Something went wrong')
      //         console.log(error)
      //     })
      // }


    }
  });

  _exports.default = _default;
});
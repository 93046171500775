define("hmis/routes/hmis-app/admin-settings/department-units/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return {};
    }

  });

  _exports.default = _default;
});
define("hmis/components/controls/month-chooser", ["exports", "hmis/utils/date-util"], function (_exports, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    monthlyYearOptions: Ember.computed(function () {
      let years = [];
      let cYear = new Date().getYear();
      if (cYear < 1900) cYear += 1900;

      for (let i = 1990; i <= cYear; i++) {
        years.pushObject(i);
      }

      return years;
    }),
    selectedYear: Ember.computed(function () {
      if ((0, _dateUtil.isValidDate)(this.fromDate)) {
        return new Date(this.get('fromDate')).getFullYear();
      }

      return new Date().getFullYear();
    }),
    selectedMonth: Ember.computed(function () {
      return (0, _dateUtil.isValidDate)(this.fromDate) ? new Date(this.get('fromDate')).getMonth() : new Date().getMonth();
    }),
    selectedMonthName: '',
    mL: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
    mS: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
    months: Ember.computed(function () {
      return this.get('mL');
    }),
    actions: {
      monthClick(month, index) {
        if (!this.get('disabled')) {
          this.set('selectedMonthName', month);
          this.set('selectedMonthIndex', index);
          this.set('selectedMonth', index);
          this.send('setDates');
        }
      },

      yearChanged(year) {
        this.set('selectedYear', year);
        this.send('setDates');
      },

      setDates() {
        let fromDate = new Date();
        fromDate.setMonth(this.get('selectedMonth'));
        fromDate.setFullYear(this.get('selectedYear'));
        fromDate.setDate(1);
        this.set('fromDate', fromDate);
        let toDate = new Date();
        toDate.setMonth(this.get('selectedMonth'));
        toDate.setFullYear(this.get('selectedYear'));
        toDate.setDate(31);
        this.set('toDate', toDate);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/users/user/specialty", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller"], function (_exports, _modelTableTheme, _formController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, {
    notify: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    selectedItems: [],
    allowSelect: false,
    //data: Ember.computed.alias('model'),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    selectedCount: Ember.computed("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    isToday: true,
    fromDate: new Date(),
    toDate: new Date(),
    tabIndex: 0,
    selectedPatient: '',
    vitalsSuccessAction: 'empty',
    onConfirm: 'deleteRecord',
    visit: null,
    departmentVal: null,

    init() {
      this._super(...arguments);
    },

    actions: {
      back() {
        history.back();
      },

      addSpecialist() {
        console.log('userId', this.get('model.userId'));
        this.set('editSpecialist', {
          userId: this.get('model.userId')
        });
        this.set('specialistDialog', true);
      },

      editSpecialist(record) {
        record.set('userId', this.get('model.userId'));
        this.set('editSpecialist', record);
        this.set('specialistDialog', true);
      },

      onSaved(record) {
        this.send('reloadModel');
        this.notify.success('Saved Successfully');
      },

      deleteRecord(record) {
        record.destroyRecord();
        this.send('reloadModel');
      },

      deleteNow(recordTag) {
        this.get("selectedItems").removeObject(recordTag);
        recordTag.destroyRecord(); //reload stats data

        setTimeout(this.get('ajaxRequest').makeRequest('POST', "/dashboardStatistics/day", {}, {
          success: response => {
            this.set('app.dashboardStats', response);
            console.log('archive donfirmed: data auto load');
          },
          error: error => {
            console.log('data auto load error');
          }
        }), 10000);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    specialistColumns: [{
      'title': 'Specialist',
      "propertyName": "specialistName",
      'className': 'main-column'
    }, {
      'title': 'Specialty',
      "propertyName": "specialtyName",
      'className': 'main-column'
    }, {
      "title": "Actions",
      "component": "manageRow",
      'className': 'main-column'
    }]
  });

  _exports.default = _default;
});
define("hmis/components/classic/c-input", ["exports", "hmis/mixins/classic-component"], function (_exports, _classicComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_classicComponent.default, {
    className: 'c-input',
    searchEnabled: false,
    type: 'text',
    actions: {
      emptyAction() {// alert('alal')
      },

      checkChanged(event) {
        setTimeout(() => {
          if (this.onChange) this.onChange(event.target.checked);
        }, 50);
      },

      inputChanged(event) {
        if (this.get('onChange')) this.get('onChange')(event.target.value);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/services/server-downloads", ["exports", "jquery", "hmis/config/environment", "hmis/utils/date-util", "hmis/utils/server-util"], function (_exports, _jquery, _environment, _dateUtil, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const url = _serverUtil.serverUrl;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    appAjax: Ember.inject.service(),

    // token: computed('session.data.authenticated.access_token', function() {
    //   return this.get('session.data.authenticated.access_token')
    // }),
    // makeAuthorizedRequest(context,requestType, urlExt, data, callBack){
    //   // context.get('session').authorize('authorizer:oauth2', (headerName, headerValue) => {
    //   let token = context.get('session.data.authenticated.access_token');
    //   let headers = {
    //     Authorization: 'Bearer ' + token,
    //   }
    //     $.ajax({
    //         headers: headers,
    //         type: requestType,
    //         url: url+urlExt,
    //         data: data
    //       }
    //     ).then(
    //       (response, status, xhr) => {
    //         if(callBack)
    //           callBack.success(response, status, xhr)
    //       },
    //       (error)=>{
    //         if(callBack)
    //           callBack.error(error)
    //           console.log("Unable to sent request: ",error)
    //       }
    //     );
    //   // });
    // },
    // makeRequest(requestType,url, data,callBack){
    //   this.get('makeAuthorizedRequest')(this,requestType, url, data, {
    //     success: (response)=>{
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    // downloadFile(url, filename, data){
    //   $.ajax({
    //        url: url,
    //        method: method,
    //        data:data,
    //        xhrFields: {
    //            responseType: 'blob'
    //        },
    //        success: function (data) {
    //            var a = document.createElement('a');
    //            var url = window.URL.createObjectURL(data);
    //            a.href = url;
    //            a.download = filename;
    //            a.click();
    //            window.URL.revokeObjectURL(url);
    //        }
    //    });
    // },
    makeAuthorizedDownload(context, urlExt, filename, data, callBack) {
      context.appAjax.downloadFile(urlExt, data, filename).then(res => {
        if (callBack) callBack.success(data);
      }).catch(err => {
        if (callBack) callBack.error(error);
      }); // context.get('session').authorize('authorizer:oauth2', (headerName, headerValue) => {
      // let token = context.get('session.data.authenticated.access_token');
      // let headers = {
      //   Authorization: 'Bearer ' + token,
      // }
      //   $.ajax({
      //       headers: headers,
      //       url: url+urlExt,
      //       method: "GET",
      //       data:data,
      //       xhrFields: {
      //           responseType: 'blob'
      //       },
      //       success: function (data) {
      //           var a = document.createElement('a');
      //           var url = window.URL.createObjectURL(data);
      //           a.href = url;
      //           a.download = filename;
      //           a.click();
      //           window.URL.revokeObjectURL(url);
      //           if(callBack)
      //             callBack.success(data)
      //       },
      //       error: function (error) {
      //         if(callBack)
      //           callBack.error(error)
      //       }
      //     })
      // // });
    },

    //////////////////////////////////////////////////////////////////////////////
    //  Billables
    //////
    downloadBillableItems(billableItemsName, insuranceProduct, withPrices, callBack) {
      this.get('makeAuthorizedDownload')(this, "/" + billableItemsName + "ExcelDownload" + (withPrices ? 'WithPrices' : ''), 'downloads', {
        insuranceProduct
      }, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    downloadMedicines(insuranceProduct, withPrices, callBack) {
      this.get('makeAuthorizedDownload')(this, "/medicinesExcelDownload" + (withPrices ? 'WithPrices' : ''), 'downloads', {
        insuranceProduct
      }, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    downloadProcedureTypes(insuranceProduct, withPrices, callBack) {
      this.get('makeAuthorizedDownload')(this, "/procedureTypesExcelDownload" + (withPrices ? 'WithPrices' : ''), 'downloads', {
        insuranceProduct
      }, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    downloadXrayTypes(insuranceProduct, withPrices, callBack) {
      this.get('makeAuthorizedDownload')(this, "/xrayTypesExcelDownload" + (withPrices ? 'WithPrices' : ''), 'downloads', {
        insuranceProduct
      }, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    downloadUltrasoundTypes(insuranceProduct, withPrices, callBack) {
      this.get('makeAuthorizedDownload')(this, "/ultrasoundTypesExcelDownload" + (withPrices ? 'WithPrices' : ''), 'downloads', {
        insuranceProduct
      }, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    downloadInvestigationTypes(insuranceProduct, withPrices, callBack) {
      this.get('makeAuthorizedDownload')(this, "/investigationTypesExcelDownload" + (withPrices ? 'WithPrices' : ''), 'downloads', {
        insuranceProduct
      }, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    downloadDiagnosisTypes(insuranceProduct, withPrices, callBack) {
      this.get('makeAuthorizedDownload')(this, "/diagnosisTypesExcelDownload" + (withPrices ? 'WithPrices' : ''), 'downloads', {
        insuranceProduct
      }, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    }

  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/access-m-role-o-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OZhFGi6t",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[28,\"controls/access-m-operation-permission\",null,[[\"operationPermission\",\"permissionChange\",\"view\",\"add\",\"edit\",\"inactivate\",\"allow\"],[[24,[\"operationPermission\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null],[24,[\"roleOperationPermission\",\"view\"]],[24,[\"roleOperationPermission\",\"add\"]],[24,[\"roleOperationPermission\",\"edit\"]],[24,[\"roleOperationPermission\",\"inactivate\"]],[24,[\"roleOperationPermission\",\"allow\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/access-m-role-o-permission.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/records/patient/questionnaires/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    data: Ember.inject.service(''),

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('loading', true);
      controller.set('hasChange', false);

      if (this.get('data.cache.questionnaireResponses.pat' + model.patient.id + model.questionnaire.id)) {
        controller.set('answerRecord', this.get('data.cache.questionnaireResponses.pat' + model.patient.id + model.questionnaire.id));
        controller.set('valueSet', this.get('data.cache.questionnaireResponses.pat' + model.patient.id + model.questionnaire.id).toAnswer());
        controller.set('loading', false);
      } else {
        this.store.query('questionnaire-response', {
          filter: {
            patientId: model.patient.id,
            questionnaireId: model.questionnaire.id
          }
        }).then(recs => {
          controller.set('loading', false);

          if (recs.get('firstObject')) {
            this.set('data.cache.questionnaireResponses.pat' + model.patient.id + model.questionnaire.id, recs.get('firstObject'));
            controller.set('answerRecord', recs.get('firstObject'));
            controller.set('valueSet', recs.get('firstObject').toAnswer());
          } else {
            controller.set('answerRecord', null);
            controller.set('valueSet', {});
          }
        }, error => {
          controller.set('pageError', error);
        });
      }
    },

    model(params) {
      var curPatient = this.modelFor("hmis-app.records.patient");
      return Ember.RSVP.hash({
        patient: curPatient,
        questionnaire: this.get('data.patientQuestionnaires').then(recs => {
          return recs.findBy('id', params.id);
        }),
        structure: this.get('data.patientQuestionnaires').then(recs => {
          return recs.findBy('id', params.id).toStructure();
        })
      });
    }

  });

  _exports.default = _default;
});
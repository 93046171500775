define("hmis/routes/hmis-app/reports/patientvisit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // setupController: function(controller, model) {
    //   let fd = new Date();
    //   let td = new Date();
    //   controller.set('fromDate', fd);
    //   controller.set('toDate', td);
    // },
    model(params) {
      return Ember.RSVP.hash({
        fdt: Ember.computed(function () {
          let date = new Date(),
              y = date.getFullYear(),
              m = date.getMonth();
          let fd = new Date(y, m, 1);
          return fd;
        }),
        tdt: Ember.computed(function () {
          let date = new Date(),
              y = date.getFullYear(),
              m = date.getMonth();
          let td = new Date(y, m + 1, 0);
          return td;
        })
      });
    }

  });

  _exports.default = _default;
});
define("hmis/mixins/form-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didInsertElement() {
      this._super(...arguments);

      let parent = this.get('parent');

      if (parent) {
        parent.set('submitForm', () => {
          this.send('submitForm');
        });
      }
    },

    actions: {
      submitForm() {
        this.get('submitForm')(); // console.log( this.get('childComponents').isEvery('isValid'))
      },

      onValidFormSubmit() {
        let action = this.get('onValidFormSubmit');
        if (action) action();
      }

    },
    emailValidation: [{
      message: 'Please provide email in a valid format',
      validate: inputValue => {
        let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return inputValue ? emailPattern.test(inputValue) : true;
      }
    }],
    phoneValidation: [{
      message: 'Please provide phone in a valid format',
      validate: inputValue => {
        let pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return inputValue ? pattern.test(inputValue) : true;
      }
    }]
  });

  _exports.default = _default;
});
define("hmis/templates/components/forms/payment-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AEKprINX",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"alerts/form-dialog\",null,[[\"title\",\"waiting\",\"show\",\"width\",\"height\",\"onConfirm\",\"onCancel\"],[[24,[\"alertTitle\"]],[24,[\"waiting\"]],[24,[\"show\"]],\"60%\",\"50%\",[28,\"action\",[[23,0,[]],\"onRecordSubmit\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null]]],{\"statements\":[[4,\"app-select\",null,[[\"required\",\"class\",\"searchField\",\"searchEnabled\",\"label\",\"selected\",\"options\",\"onChange\"],[true,[24,[\"input_width\"]],\"name\",true,\"Payment Mode\",[24,[\"record\",\"paymentMode\"]],[24,[\"paymentMode\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"paymentMode\"]]],null]],null]]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"paymentModeTypeName\"]],false],[0,\" - \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[1,[28,\"paper-input\",null,[[\"required\",\"label\",\"type\",\"class\",\"value\",\"onChange\"],[true,\"Account Number\",\"text\",[24,[\"input_width\"]],[24,[\"record\",\"accountNumber\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"accountNumber\"]]],null]],null]]]],false],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"required\",\"label\",\"type\",\"class\",\"value\",\"onChange\"],[true,\"Account Name\",\"text\",[24,[\"input_width\"]],[24,[\"record\",\"accountName\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"accountName\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/forms/payment-alert.hbs"
    }
  });

  _exports.default = _default;
});
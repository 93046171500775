define("hmis/routes/hmis-app/records/visit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    data: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('loading', true);
      controller.set('hasChange', false);
      controller.set('selectedPatientInsuranceProducts', []);
      this.getMOPs(controller, model);
      this.getPreviousVisits(controller, model);
    },

    model(params) {
      return Ember.RSVP.hash({
        params: params,
        patient: this.get('store').findRecord('patient', params.patient_id) // opdDepUnit: this.get('store').findRecord('department-unit','opd'),
        //   visits: this.get('store').query('visit', {
        //         filter: {
        //           patient: params.patient_id,
        //         //   date:'dddd'
        //         },
        //         include: 'patient'
        //       }).then(function(vis) {
        //           console.log('visits came in', vis)
        //         return vis;
        //     }), //change request to get current patient instead,
        //     // patient: this.get('store').findRecord('patient', params.record_id), //change request to get current patient instead,

      });
    },

    actions: {
      reloadModel: function () {
        this.refresh();
      }
    },

    getMOPs(controller, model) {
      let cacheLink = 'data.cache.patientInsurances.pat' + model.patient.id;

      if (this.get(cacheLink)) {
        controller.set('patientInsuranceProducts', this.get(cacheLink));
      } else {
        this.get('store').query('patient-insurance', {
          filter: {
            patientId: model.patient.id
          }
        }).then(recs => {
          let allRecs = recs.toArray();
          controller.set('patientInsuranceProducts', allRecs);
          controller.set('selectedPatientInsuranceProducts', []);
          this.set(cacheLink, allRecs);
        }, err => {});
      }
    },

    getPreviousVisits(controller, model) {
      let cacheLink = 'data.cache.patientVisits.pat' + model.patient.id;
      let dataProp = 'previousVisits';

      if (this.get(cacheLink)) {
        controller.set(dataProp, this.get(cacheLink));
      } else {
        this.get('store').query('visit', {
          filter: {
            patientId: model.params.patient_id
          },
          include: ['patientName']
        }).then(recs => {
          let allRecs = recs.toArray();
          controller.set(dataProp, allRecs);
          this.set(cacheLink, allRecs);
        }, err => {});
      }
    }

  });

  _exports.default = _default;
});
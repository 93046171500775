define("hmis/utils/visit-service-util", ["exports", "hmis/utils/general-util"], function (_exports, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getServiceTypeModelName = getServiceTypeModelName;
  _exports.getServiceTypeName = getServiceTypeName;
  _exports.vitalsOpdIpdModel = vitalsOpdIpdModel;
  _exports.symptomsOpdIpdModel = symptomsOpdIpdModel;
  _exports.investigationOpdIpdModel = investigationOpdIpdModel;
  _exports.procedureOpdIpdModel = procedureOpdIpdModel;
  _exports.xrayOpdIpdModel = xrayOpdIpdModel;
  _exports.ultrasoundOpdIpdModel = ultrasoundOpdIpdModel;
  _exports.diagnosisOpdIpdModel = diagnosisOpdIpdModel;
  _exports.prescriptionOpdIpdModel = prescriptionOpdIpdModel;
  _exports.visitOpdIpdModel = visitOpdIpdModel;
  _exports.referralsOpdIpdModel = referralsOpdIpdModel;
  _exports.visitServiceResultIncludes = _exports.visitServiceIncludes = void 0;
  const visitServiceIncludes = ['patientPhoneNumber', 'patientDeleteStatus', 'visitStatus', 'visitPurpose', 'visitDate', 'patientName', 'patientGender', 'patientDateOfBirth', 'outstandingBills'];
  _exports.visitServiceIncludes = visitServiceIncludes;
  const visitServiceResultIncludes = ['requesterName', 'requesterName'].concat(visitServiceIncludes);
  _exports.visitServiceResultIncludes = visitServiceResultIncludes;

  function getServiceTypeModelName(serviceName) {
    return serviceName + "-type";
  }

  function getServiceTypeName(serviceName) {
    return serviceName + "Type";
  }

  function vitalsOpdIpdModel(that, params) {
    let newModel = that.cloneParentCareModel();
    newModel['vitalBatches'] = that.get('store').query('vital-batch', {
      filter: {
        visitId: newModel.visitId
      },
      include: ['userName', 'vitals']
    });
    return (0, _generalUtil.modelHash)(newModel);
  }

  function symptomsOpdIpdModel(that, params) {
    let newModel = that.cloneParentCareModel();
    newModel['data'] = that.get('store').query('symptom', {
      filter: {
        visitId: newModel.visitId
      },
      include: ['symptomTypeName']
    });
    newModel['dataTypes'] = that.get('store').query('symptom-type', {
      pageSize: 20
    });
    return (0, _generalUtil.modelHash)(newModel);
  }

  function investigationOpdIpdModel(that, params) {
    let newModel = that.cloneParentCareModel();
    newModel['requestData'] = that.requestDataQuery();
    newModel['resultData'] = that.resultDataQuery();
    newModel['dataTypes'] = that.dataTypeQuery();
    return (0, _generalUtil.modelHash)(newModel);
  }

  function procedureOpdIpdModel(that, params) {
    let newModel = that.cloneParentCareModel();
    newModel['requestData'] = that.requestDataQuery();
    newModel['resultData'] = that.resultDataQuery();
    newModel['dataTypes'] = that.dataTypeQuery();
    return (0, _generalUtil.modelHash)(newModel);
  }

  function xrayOpdIpdModel(that, params) {
    let newModel = that.cloneParentCareModel();
    newModel['requestData'] = that.requestDataQuery();
    newModel['resultData'] = that.resultDataQuery();
    newModel['dataTypes'] = that.dataTypeQuery();
    return (0, _generalUtil.modelHash)(newModel);
  }

  function ultrasoundOpdIpdModel(that, params) {
    let newModel = that.cloneParentCareModel();
    newModel['requestData'] = that.requestDataQuery();
    newModel['resultData'] = that.resultDataQuery();
    newModel['dataTypes'] = that.dataTypeQuery();
    return (0, _generalUtil.modelHash)(newModel);
  }

  function diagnosisOpdIpdModel(that, params) {
    let newModel = that.cloneParentCareModel();
    newModel['data'] = that.get('store').query('diagnosis', {
      filter: {
        visitId: newModel.visitId
      },
      include: ['diagnosisTypeName', 'patientName'],
      pageSize: 100
    });
    return (0, _generalUtil.modelHash)(newModel);
  }

  function prescriptionOpdIpdModel(that, params) {
    let newModel = that.cloneParentCareModel();
    newModel['data'] = that.get('store').query('prescription', {
      filter: {
        visitId: newModel.visitId
      },
      include: ['medicineName', 'patientName', 'requesterName', 'granterName']
    });
    newModel['dataTypes'] = that.dataTypeQuery({
      include: ['unitOfPricingName']
    });
    return (0, _generalUtil.modelHash)(newModel);
  }

  function visitOpdIpdModel(that, params) {
    let newModel = that.cloneParentCareModel();
    newModel['data'] = that.get('store').query('visit', {
      filter: {
        patientId: newModel.patientId
      },
      include: ['purposeName']
    });
    return (0, _generalUtil.modelHash)(newModel);
  }

  function referralsOpdIpdModel(that, params) {
    let newModel = that.cloneParentCareModel();
    newModel['data'] = that.get('store').query('referral', {
      filter: {
        patientId: newModel.patientId
      },
      include: []
    });
    return (0, _generalUtil.modelHash)(newModel);
  }
});
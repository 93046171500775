define("hmis/templates/components/pickers/input-picker-server-paginated", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VpmJ+zWK",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"flex-row \",[22,\"class\"],\" non-trigger\"]]],[8],[0,\"\\n\"],[4,\"app-select\",null,[[\"class\",\"disabled\",\"required\",\"searchField\",\"searchEnabled\",\"label\",\"selected\",\"options\",\"onChange\",\"search\",\"parent\",\"icon\"],[[28,\"concat\",[\"flex-1 \",[24,[\"inputClass\"]]],null],[24,[\"disabled\"]],[24,[\"required\"]],[24,[\"textField\"]],true,[24,[\"label\"]],[24,[\"selected\"]],[24,[\"dataOptions\"]],[28,\"action\",[[23,0,[]],\"setRecord\"],null],[28,\"action\",[[23,0,[]],\"onInputSearch\"],null],[23,0,[]],[24,[\"icon\"]]]],{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"            \"],[14,2,[[28,\"hash\",null,[[\"select\"],[[28,\"component\",[\"controls/empty-comp\"],[[\"tag\"],[[23,1,[]]]]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[28,\"get\",[[23,1,[]],[24,[\"textField\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[0,\"\\n    \"],[1,[28,\"pickers/table-picker-server-paginated\",null,[[\"title\",\"message\",\"selectedItems\",\"columns\",\"ref\",\"parent\",\"height\"],[[28,\"if\",[[24,[\"label\"]],[24,[\"label\"]],[24,[\"placeholder\"]]],null],[24,[\"message\"]],[28,\"if\",[[24,[\"selected\"]],[28,\"array\",[[24,[\"selected\"]]],null],[28,\"array\",null,null]],null],[24,[\"columns\"]],\"tablePicker\",[23,0,[]],\"80%\"]]],false],[0,\"\\n\"],[0,\"    \"],[7,\"button\",true],[11,\"disabled\",[22,\"disabled\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"showPicker\"],null]],[10,\"class\",\"input-chooser\"],[10,\"type\",\"button\"],[8],[1,[28,\"svg-jar\",[\"list\"],[[\"class\",\"height\",\"width\"],[\"icon dark-fill\",\"20px\",\"20px\"]]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/pickers/input-picker-server-paginated.hbs"
    }
  });

  _exports.default = _default;
});
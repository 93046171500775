define("hmis/mixins/after-render", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    /*
      This hook is guaranteed to be executed when the root element of this view has been inserted into the DOM.
     */
    didInsertElement: function () {
      this._super();

      Ember.run.scheduleOnce('afterRender', this, this.afterRenderEvent);
    },
    afterRenderEvent: Ember.K
  });

  _exports.default = _default;
});
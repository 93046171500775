define("hmis/components/controls/report-rows-columns", ["exports", "hmis/utils/reports", "hmis/utils/events"], function (_exports, _reports, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    // classNames:['report-controls','app-fixed-top' , 'content-list'],
    // show:true,
    init() {
      this._super(...arguments);

      let serverReport = this.get('serverReport'); // if(!this.get('selectedRows'))
      //   this.set('selectedRows', [])
      // // if(!this.get('selectedColumns'))
      //   this.set('selectedColumns', [])
      // this.send('selectAllColumns')
      // this.send('selectAllRows')
    },

    allRows: Ember.computed('serverReport', 'rowReferenceName', function () {
      // this.set('selectedRows',[])
      return _reports.default.referenceToArray(this.get('serverReport'), this.get('rowReferenceName'), false);
    }),
    allColumns: Ember.computed('serverReport', 'columnReferenceName', function () {
      return _reports.default.referenceToArray(this.get('serverReport'), this.get('columnReferenceName'), false);
    }),
    selectedRows: [],
    selectedColumns: [],
    actions: {
      selectRows() {
        if (!this.get('showSelectRows')) {
          this.set('showSelectRows', true);
          event.preventDefault(); // event.stopPropagation()

          (0, _events.onClickOutside)('#rc-row', () => {
            // console.log('onClickOutside');
            if (this.get('showSelectRows')) this.set('showSelectRows', false);
          });
        }
      },

      selectColumns() {
        if (!this.get('showSelectColumns')) {
          this.set('showSelectColumns', true);
          event.preventDefault(); // event.stopPropagation()

          (0, _events.onClickOutside)('#rc-column', () => {
            // console.log('onClickOutside');
            if (this.get('showSelectColumns')) this.set('showSelectColumns', false);
          });
        }
      },

      selectedRowsChange(item, value) {
        if (!this.get('selectedRows').length) this.set('selectedRows', []);
        let sItems = this.get('selectedRows'); // console.log('sItems',sItems);

        if (sItems.includes(item)) {
          sItems.removeObject(item);
        } else {
          sItems.pushObject(item);
        } // console.log(sItems);

      },

      selectedColumnsChange(item, value) {
        if (!this.get('selectedColumns').length) this.set('selectedColumns', []);
        let sItems = this.get('selectedColumns');

        if (sItems.includes(item)) {
          sItems.removeObject(item);
        } else {
          sItems.pushObject(item);
        } // console.log(sItems);

      },

      selectAllRows() {
        if (this.get('allRows').length == this.get('selectedRows').length) {
          //all selected
          this.set('selectedRows', []);
        } else {
          this.set('selectedRows', []);
          this.get('allRows').forEach(item => {
            this.get('selectedRows').pushObject(item);
          });
        }
      },

      selectAllColumns() {
        if (this.get('allColumns').length == this.get('selectedColumns').length) {
          //all selected
          this.set('selectedColumns', []);
        } else {
          this.set('selectedColumns', []);
          this.get('allColumns').forEach(item => {
            this.get('selectedColumns').pushObject(item);
          });
        }
      }

    }
  });

  _exports.default = _default;
});
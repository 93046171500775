define("hmis/controllers/hmis-app/admin-settings/facility-examination", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    vitaltypes: Ember.computed(function () {
      return this.get('store').query('vital-type', {});
    }),
    defaultVitals: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: this.get('store').queryRecord('vital-type', {
          //filter: {
          name: 'BMI' //}

        }).then(function (recs) {
          let frec = recs.get("firstObject");
          console.log('fval', recs.get("firstObject"));
          return frec.toArray();
        })
      });
    }),
    facilityVitals: Ember.computed(function () {
      //console.log()
      let defVit = _emberData.default.PromiseArray.create({
        promise: this.get('store').findAll('vitals-facility-default').then(function (recs) {
          console.log('recs.toArray', recs.toArray());
          return recs.toArray();
        })
      }); // defVit.pushObject({vitalType:'',unitOfMeasure:''}
      //   //DS.PromiseArray.create({ promise:
      //   //   this.get('store').findRecord('vital-type',16).then(function(recs){
      //   //     return recs
      //   //   })
      //   //})
      // )


      return defVit;
    }),
    onConfirm: 'deleteRecord',
    newRecord: {},
    // init() {
    //   //this._super(...arguments);
    //
    //   let defaultVit =
    //     DS.PromiseArray.create({promise:
    //       this.get('store').query('vital-type',{
    //       filter: {
    //         name: 'BMI'
    //       }})
    //         .then(function(recs){
    //         console.log(recs.toArray())
    //       return recs.toArray()
    //     })
    //   //})
    //   this.get('facilityVitals').pushObject(defaultVit)
    //
    //   // tom = store.query('user', {
    //   //   filter: {
    //   //     email: 'tomster@example.com'
    //   //   }
    //   // }).then(function(users) {
    //   //   return users.get("firstObject");
    //   // });
    //
    // },
    actions: {
      deleteRecord(record) {
        console.log('ins cj', record);
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete: ' + record.get('name'));
        this.set('onAlert', true);
      },

      deleteNow(record) {
        record.destroyRecord();
        this.send('reloadModel');
      },

      // deleteRecord (record) {
      //   this.get('facilityVitals').removeObject(record)
      //   record.destroyRecord();
      // },
      saveRecord(record) {
        record.save().then(record => {
          this.set('listWait', false);
          this.notify.success('Saved Successfully');
          this.send('reloadModel');
        }).catch(failure => {
          this.set('listWait', false);
          this.notify.error(failure);
        });
      },

      saveNewRecord() {
        this.set('listWait', true);
        let newRecord = this.get('store').createRecord('vitals-facility-default', this.get('newRecord'));
        newRecord.save().then(record => {
          this.set('listWait', false); // this.get('facilityVitals').pushObject(record)

          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.send('reloadModel');
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error(failure);
        });
      }

    }
  });

  _exports.default = _default;
});
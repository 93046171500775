define("hmis/components/controls/row-file-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    view: true,
    edit: true,
    delete: true,
    editText: 'Edit',
    viewText: 'View',
    deleteText: 'Delete',
    minWidth: '140px',
    thumbnail: 'small',

    clicked(name, event) {
      // var onClick = this.get("onClick");
      // if (onClick) {
      //     alert("Passexd onclick");
      //     onClick(this.get("record"));
      //     console.log("kdks");
      //     // event.stopPropagation();
      // }
      if (this.get(name)) {
        var fun = this.get(name);
        fun(this.get("record")); // alert(name+" "+this.get("record.id"));

        event.stopPropagation();
      } // console.log(name);
      // // alert(name+" "+this.get("record.id"));

    }

  });

  _exports.default = _default;
});
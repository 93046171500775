define("hmis/controllers/hmis-app/admin-settings/insurance-products/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    medOptions: Ember.computed(function () {
      return this.get('store').findAll('medicine');
    }),
    notify: Ember.inject.service(),
    addedMaps: [],
    removedMaps: [],
    actions: {
      back() {
        history.back();
      },

      save() {
        let currentInvType = this.get('model');
        currentInvType.save().then(record => {
          let removedMaps = this.get('removedMaps');
          let addedMaps = this.get('addedMaps');
          removedMaps.forEach(function (item) {
            item.destroyRecord();
          });
          addedMaps.forEach(function (item) {
            item.save();
          });
          this.notify.success('Saved Successfully');
        }).catch(failure => {
          //alert("Unable to saved");
          console.log(failure);
          this.notify.error('Unable to Saved');
        });
      },

      addInv(invTestType) {
        //console.log(inv.id);
        let addedMaps = this.get('addedMaps');
        let invType = this.get("model");
        let testTypes = invType.get("medicines");
        let testTypeMap = this.get('store').createRecord('insurance-product-medicine', {
          insuranceProduct: invType,
          medicine: invTestType
        });
        testTypes.pushObject(testTypeMap);
        addedMaps.pushObject(testTypeMap);
      },

      removeInv(testTypeMap) {
        let removedMaps = this.get('removedMaps');
        let addedMaps = this.get('addedMaps');
        let invType = this.get("model");
        let testTypes = invType.get("insuranceProductMedicines");
        removedMaps.pushObject(testTypeMap);
        testTypes.removeObject(testTypeMap);
        addedMaps.removeObject(testTypeMap);
      },

      changeMeds(par1, par2, par3) {
        console.log(par1);
        console.log(par2);
        console.log(par3);
      }

    } // actions:{
    //     back()
    //     {
    //         history.back()
    //     },
    //     save(){
    //         this.get("model").save()
    //         .then((record)=>{
    //             record.get('medicines').invoke('save')
    //             alert("Saved");
    //             // console.log('saved product')
    //         })
    //         .catch((failure)=>{
    //             console.log(failure)
    //             alert("Unable to saved:"+failure);
    //         });
    //     }
    // }

  });

  _exports.default = _default;
});
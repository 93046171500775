define("hmis/controllers/hmis-app/bills/bill", ["exports", "hmis/utils/download-util", "hmis/utils/model-table-theme", "hmis/utils/request-utils/bill"], function (_exports, _downloadUtil, _modelTableTheme, _bill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    unpaids: Ember.computed('model.transactions', 'model.transactions.@each.isPaid', function () {
      return this.get('model.transactions').filterBy('isPaid', false);
    }),
    medicine: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'prescription');
    }),
    diagnosis: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'diagnosis');
    }),
    lab: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'investigation');
    }),
    procedure: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'procedure');
    }),
    ultrasound: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'ultrasound');
    }),
    xray: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'xray');
    }),
    otherBillables: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filter((item, index, self) => item.get('billableType') == 'admission' || item.get('billableType') == 'consultation' || item.get('billableType') == 'ward');
      ;
    }),
    sum: arrs => {
      let total = 0;
      arrs.forEach(function (tran) {
        total += Number(tran.get('amount'));
      });
      return total;
    },
    totalMedicineCost: Ember.computed('medicines', function () {
      return this.get('sum')(this.get('medicine'));
    }),
    totalDiagnosisCost: Ember.computed('diagnosis', function () {
      return this.get('sum')(this.get('diagnosis'));
    }),
    totalLabCost: Ember.computed('lab', function () {
      return this.get('sum')(this.get('lab'));
    }),
    totalProcedureCost: Ember.computed('procedure', function () {
      return this.get('sum')(this.get('procedure'));
    }),
    totalUltrasoundCost: Ember.computed('ultrasound', function () {
      return this.get('sum')(this.get('ultrasound'));
    }),
    totalXrayCost: Ember.computed('xray', function () {
      return this.get('sum')(this.get('xray'));
    }),
    totalOtherBillablesCost: Ember.computed('otherBillables', function () {
      return this.get('sum')(this.get('otherBillables'));
    }),
    totalBill: Ember.computed('totalMedicineCost', 'totalDiagnosisCost', 'totalLabCost', 'totalProcedureCost', 'totalUltrasoundCost', 'totalXrayCost', 'totalOtherBillablesCost', function () {
      return Number(Number(this.get('totalMedicineCost')) + Number(this.get('totalDiagnosisCost')) + Number(this.get('totalLabCost')) + Number(this.get('totalProcedureCost')) + Number(this.get('totalUltrasoundCost')) + Number(this.get('totalXrayCost')) + Number(this.get('totalOtherBillablesCost'))).toFixed(2);
    }),
    actions: {
      back() {
        history.back();
      },

      // approveBill()
      // {
      //
      //     let that = this;
      //     let oldBill = this.get('model')
      //     this.get('store').findRecord('bill-status',2, { reload: true }).then(function(status){
      //         // console.log('hiu');
      //         that.get('store').createRecord('bill-copy',{
      //             modeOfPayment:oldBill.get('modeOfPayment'),
      //             visit:that.get('model.visit'),
      //             status:status
      //         }).save().then(function(newBillCopy){
      //             that.set('model',newBillCopy)
      //         });
      //     }).catch(function(err){
      //         console.log(err);
      //     })
      // },
      payAll() {
        this.get('ajaxRequest').payMultiTransactions(this.get('model.transactions'), {
          success: response => {
            this.notify.success('Payment successful');
          },
          error: error => {
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      payTransaction(transaction) {
        this.get('ajaxRequest').payTransaction(transaction, {
          success: response => {
            this.notify.success('Payment successful');
            transaction.set('hasChange', !transaction.get('hasChange')); // that.get('store').findRecord('visit', visitId, {reload: true});
          },
          error: error => {
            this.notify.error(error);
          }
        });
      },

      print() {
        this.set('printingBill', true);
        this.get('ajaxRequest').printBill(this.get('model.visit'), {
          success: response => {
            this.set('printingBill', false);
          },
          error: error => {
            this.set('printingBill', false);
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      exportToPdf() {
        // this.set('exportingBillToPDF',true)
        this.get('ajaxRequest').exportBillToPdf(this.get('model.visit'), {
          success: response => {// this.set('exportingBillToPDF',false)
          },
          error: error => {
            // this.set('exportingBillToPDF',false)
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      alertEmail() {
        this.set('sendingMail', true);
        this.get('ajaxRequest').emailBill(this.get('model.visit'), {
          success: response => {
            this.notify.success('Email sent');
            this.set('sendingMail', false);
          },
          error: error => {
            this.set('sendingMail', false);
            this.notify.error(error);
          }
        });
      },

      moveToClaims(transaction) {
        this.set('transactionGoingToClaim', transaction);
        this.set('chooseInsuranceProduct', true);
      },

      insuranceProductPicked(selectedProducts) {
        let transaction = this.get('transactionGoingToClaim');
        let insuranceProduct = selectedProducts.get('firstObject');
        this.set('movingToClaim', true);
        this.get('ajaxRequest').moveTransactionToClaims(transaction, insuranceProduct.get('id'), {
          success: response => {
            this.set('movingToClaim', false);
            this.set('chooseInsuranceProduct', false);
            this.notify.success('Transaction moved to claims');
            this.send('refreshModel');
          },
          error: error => {
            this.set('movingToClaim', false);
            this.notify.error(error);
          }
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "propertyName": "index",
      "component": "controls/table-index",
      className: 'index-col'
    }, {
      "title": "Date of transaction",
      "propertyName": "dateF",
      className: 'trans-date-col'
    }, {
      "title": "description",
      "propertyName": "billableItemName"
    }, {
      "propertyName": "price"
    }, {
      "propertyName": "quantity"
    }, {
      'title': 'Total cost',
      "propertyName": "nAmount",
      className: 'total-cost-col'
    }, {
      "propertyName": "paymentStatus",
      className: 'payment-col'
    }, {
      "title": "",
      "component": "manageRow",
      'viewCheck': 'isPaid',
      'viewCheckInverse': true,
      "className": "fixed-manage-row-col"
    }],
    columns2: [{
      "propertyName": "index",
      "component": "controls/table-index",
      className: 'index-col'
    }, {
      "title": "Date of transaction",
      "propertyName": "dateF",
      className: 'trans-date-col'
    }, {
      "title": "description",
      "propertyName": "billableItemName"
    }, {
      'title': 'Cost',
      "propertyName": "nAmount",
      className: 'total-cost-col'
    }, {
      "propertyName": "paymentStatus",
      className: 'payment-col'
    }, {
      "title": "",
      "component": "manageRow",
      'viewCheck': 'isPaid',
      'viewCheckInverse': true,
      "className": "fixed-manage-row-col"
    }],
    columns3: [{
      "propertyName": "index",
      "component": "controls/table-index",
      className: 'index-col'
    }, {
      "title": "Date of transaction",
      "propertyName": "dateF",
      className: 'trans-date-col'
    }, {
      "title": "description",
      "propertyName": "billableSummary"
    }, {
      'title': 'Cost',
      "propertyName": "nAmount",
      className: 'total-cost-col'
    }, {
      "propertyName": "paymentStatus",
      className: 'payment-col'
    }, {
      "title": "",
      "component": "manageRow",
      'viewCheck': 'isPaid',
      'viewCheckInverse': true,
      "className": "fixed-manage-row-col"
    }]
  });

  _exports.default = _default;
});
define("hmis/components/charts/chart-with-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments); // this.set("datasets.borderColor", ['rgba(255,99,132,1)'])

    } //type: 'bar',
    // data: computed('labels', 'datasets', function(){
    //     return {
    //         labels: this.get('labels'),
    //         datasets: [{
    //             label: '# of Votes',
    //             data: [12, 19, 3, 5, 2, 3],
    //             backgroundColor: [
    //                 'rgba(255, 255, 255, 0)',
    //                 // 'rgba(255, 99, 132, 0.2)',
    //                 // 'rgba(54, 162, 235, 0.2)',
    //                 // 'rgba(255, 206, 86, 0.2)',
    //                 // 'rgba(75, 192, 192, 0.2)',
    //                 // 'rgba(153, 102, 255, 0.2)',
    //                 // 'rgba(255, 159, 64, 0.2)'
    //             ],
    //             borderColor:
    //             [
    //                 'rgba(255,99,132,1)',
    //                 // 'rgba(54, 162, 235, 1)',
    //                 // 'rgba(255, 206, 86, 1)',
    //                 // 'rgba(75, 192, 192, 1)',
    //                 // 'rgba(153, 102, 255, 1)',
    //                 // 'rgba(255, 159, 64, 1)'
    //             ],
    //             borderWidth: 2
    //         }]
    //     }
    // }),
    // data: {
    //     labels: ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"],
    //     datasets: [{
    //         label: '# of Votes',
    //         data: [12, 19, 3, 5, 2, 3],
    //         backgroundColor: [
    //             'rgba(255, 255, 255, 0)',
    //             // 'rgba(255, 99, 132, 0.2)',
    //             // 'rgba(54, 162, 235, 0.2)',
    //             // 'rgba(255, 206, 86, 0.2)',
    //             // 'rgba(75, 192, 192, 0.2)',
    //             // 'rgba(153, 102, 255, 0.2)',
    //             // 'rgba(255, 159, 64, 0.2)'
    //         ],
    //         borderColor:
    //         [
    //             'rgba(255,99,132,1)',
    //             // 'rgba(54, 162, 235, 1)',
    //             // 'rgba(255, 206, 86, 1)',
    //             // 'rgba(75, 192, 192, 1)',
    //             // 'rgba(153, 102, 255, 1)',
    //             // 'rgba(255, 159, 64, 1)'
    //         ],
    //         borderWidth: 2
    //     }]
    // },
    //options: this.get('options')


  });

  _exports.default = _default;
});
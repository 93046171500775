define("hmis/components/controls/access-m-role-m-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    appData: Ember.inject.service(),
    roleModulePermission: Ember.computed('role', 'role.modulePermissions.@each', 'role.hasChanges', function () {
      return this.role.modulePermissions.find((rmPerm, index, array) => {
        return rmPerm.permissionId == this.appModule.id;
      }); // console.log('hdhdhdhdhdhdh');
      // let role = this.get('role')
      // let appModule = this.get('appModule')
      // return DS.PromiseObject.create({
      //     promise: role.get('roleModulePermissions').then((permissions) => {
      //       let rolePerm = permissions.findBy('modulePermissionId', appModule.id);
      //       console.log('rolePerm here',rolePerm?rolePerm.get('allow'):'')
      //       return rolePerm;
      //     })
      // })
    }),
    modulePermission: Ember.computed('appModule.id', function () {
      return this.appData.modulePermissions[this.appModule.id];
    }),
    actions: {
      lockClick(value) {
        if (!value) value = false; // console.log('rMPermission', rMPermission)

        if (this.roleModulePermission) {
          // console.log('has content', rMPermission.content);
          this.set('roleModulePermission.allow', !value);
          this.set('role.hasChanges', true);
        } else {
          let obj = {
            allow: true,
            permissionId: this.appModule.id
          };
          this.get('role.modulePermissions').pushObject(obj);
          this.set('role.hasChanges', true);
        } // this.get('roleModulePermission').then(()=>{
        //     let rMPermission = this.get('roleModulePermission')
        //     // console.log('rMPermission', rMPermission)
        //     if(rMPermission.content && !rMPermission.content.get('isDeleted')){
        //         // console.log('has content', rMPermission.content);
        //         rMPermission.set('allow', !value)
        //         this.set('role.hasChanges', true)
        //     }else{
        //         // console.log('no content');
        //         // this.get('modulePermission').then((mPerm)=>{
        //         // })
        //         let newRoleGPerm  = this.get('store').createRecord('role-module-permission', {
        //             role: this.get('role'),
        //             modulePermission: this.get('modulePermission'),
        //             allow:true,
        //             modulePermissionId:this.get('appModule.id')
        //         })
        //         // this.set('roleModulePermission', newRoleGPerm)
        //         // newRoleGPerm.set('allow', !value)
        //         this.get('role.roleModulePermissions').pushObject(newRoleGPerm)
        //         // console.log('newRoleGPerm',newRoleGPerm)
        //         this.set('role.hasChanges', true)
        //     }
        // })

      }

    }
  });

  _exports.default = _default;
});
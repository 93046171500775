define("hmis/utils/request-utils/reports", ["exports", "jquery", "hmis/config/environment", "hmis/utils/server-util"], function (_exports, _jquery, _environment, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.generalfacilityReport = generalfacilityReport;
  _exports.default = void 0;
  const url = _serverUtil.serverUrl;

  function generalfacilityReport(facilityId, fdate, edate) {
    return DS.PromiseArray.create({
      //promise: Ember.$.get(url+"/reports/"+facilityId)
      promise: _jquery.default.get(url + "/reports/" + facilityId + "/" + fdate + "/" + edate).then(response => {
        console.log(response[0]['reports']);
        return response[0]['reports'];
      }, failure => {
        if (callBack) console.log("Unable to get reports: ", failure);
      })
    });
  }

  var _default = {
    generalfacilityReport
  };
  _exports.default = _default;
});
define("hmis/components/controls/delete-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      sendAction(actionName, record, event) {
        Ember.get(this, 'sendAction')(actionName, record);
        event.stopPropagation();
      }

    }
  });

  _exports.default = _default;
});
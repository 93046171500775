define("hmis/services/app-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notifications: Ember.inject.service('notification-messages'),
    serverResponse: Ember.inject.service(),
    successOptions: {
      autoClear: true,
      clearDuration: 3000,
      cssClasses: 'notification'
    },
    infoOptions: {// autoClear: true,
      // clearDuration: 1200
    },
    warningOptions: {// autoClear: true,
      // clearDuration: 1200
    },
    errorOptions: {
      autoClear: true,
      clearDuration: 8000
    },

    notifySuccess(message) {
      this.get('notifications').success(message, this.get('successOptions'));
    },

    notifyInfo(message, autoClear = true) {
      this.get('notifications').info(message, {
        autoClear: autoClear,
        clearDuration: 6000
      });
    },

    notifyWarning(message) {
      this.get('notifications').warning(message, this.get('warningOptions'));
    },

    notifyError(message) {
      this.get('notifications').error(this.get('serverResponse').errorMessage(message), this.get('errorOptions'));
    }

  });

  _exports.default = _default;
});
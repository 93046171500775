define("hmis/routes/hmis-app/admin-settings/facility-vitals/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.get('store').findRecord('vitals-facility-default', params.record_id); //  return RSVP.hash({
      //    model: this.get('store').findRecord('vitals-facility-default', params.record_id),
      //    units: this.get('store').findAll('vitals-unit-of-measure'),
      //    facilities: this.get('store').findAll('facility'),
      //    vitaltypes: this.get('store').findAll('vital-type')
      //  })
    }

  });

  _exports.default = _default;
});
define("hmis/components/pickers/input-picker-server-paginated", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  var _default = Ember.Component.extend({
    tagName: '',
    label: 'Choose record',
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this.send('setData');

      if (!this.columns) {
        this.set('columns', [{
          "component": "controls/select-row-checkbox",
          "useFilter": false,
          "mayBeHidden": false
        }, {
          'propertyName': this.textField
        }]);
      }
    },

    _dataObserver: Ember.observer('data', function (params) {
      // alert('dd')
      this.send('setData');
    }),
    actions: {
      setData() {
        if (this.data.promise) {
          this.data.then(re => {
            this.set('_data', this.data.arrangedContent);
          });
        } else {
          this.set('_data', this.data);
        }
      },

      chooseConfirm(record) {
        this.send('setRecord', record);
        this.set('findRecord', false);
      },

      onAutocompleteChange(record) {
        this.send('setRecord', record);
      },

      showPicker() {
        let data = Object.assign({}, this._data);
        data['columns'] = this.columns;
        this.tablePicker.showPicker(data, selection => {
          this.send('chooseConfirm', selection);
        });
      },

      onInputSearch(term) {
        let data = this._data;
        let modelName = data.modelName;
        let query = Object.assign({
          search: term
        }, data.query);
        this.store.query(modelName, query).then(recs => {
          this.set('dataOptions', recs);
        });
      },

      setRecord(record) {
        if (this.selectedChange) this.selectedChange(record);else this.set('selected', record);
      }

    }
  });

  _exports.default = _default;
});
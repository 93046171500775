define("hmis/templates/components/controls/active-serve-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RwSsFxjT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"active-serve\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[8],[0,\"\\n\"],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"infos\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"p-name\"],[8],[1,[24,[\"item\",\"patientName\"]],false],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"v-purpose\"],[8],[1,[24,[\"item\",\"visit\",\"purpose\",\"name\"]],false],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"duration\"],[8],[0,\"Since \"],[1,[24,[\"item\",\"startDateF\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/active-serve-item.hbs"
    }
  });

  _exports.default = _default;
});
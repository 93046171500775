define("hmis/templates/hmis-app/facility-management/add-facility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "19laDByU",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n      \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Cancel\",[28,\"action\",[[23,0,[]],\"back\"],null],\"close-fill\"]]],false],[0,\"\\n      \"],[4,\"if\",[[24,[\"oPerms\",\"op3\",\"add\"]]],null,{\"statements\":[[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"waiting\",\"icon\"],[\"Save New Facility\",[28,\"action\",[[23,0,[]],\"submitForm\"],null],[24,[\"isSaving\"]],\"save-fill\"]]],false]],\"parameters\":[]},null],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height\",true,\"Add Facility\"]],{\"statements\":[[4,\"if\",[[24,[\"oPerms\",\"op3\",\"add\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"content display-bock\"],[8],[0,\"\\n          \"],[1,[28,\"facility-manager\",null,[[\"type\",\"record\",\"addedInsurances\",\"removedInsurances\",\"parent\",\"onValidFormSubmit\"],[\"add\",[24,[\"model\"]],[24,[\"addedInsurances\"]],[24,[\"removedInsurances\"]],[23,0,[]],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[28,\"no-data-message\",null,[[\"message\"],[\"You don't have permissions to add facilities\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/facility-management/add-facility.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/viewers/vitals-batch-viewer-alert", ["exports", "hmis/utils/model-table-theme", "hmis/utils/bmi-calc"], function (_exports, _modelTableTheme, _bmiCalc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    vitals: Ember.computed('batch.vitals', function () {
      return this.get('batch.vitals');
    }),
    bmi: Ember.computed('batch.vitals.@each.value', function () {
      return (0, _bmiCalc.bmiFromVitalsList)(this.get('batch.vitals'));
    }),
    themeInstance: _modelTableTheme.default.create({
      table: 'table'
      /* table:'table table-striped table-bordered table-condensed',*/

    }),
    columns: [{
      'title': 'Vital type',
      "propertyName": "vitalTypeName"
    }, {
      "propertyName": "value"
    }, {
      title: 'Measure',
      "propertyName": "unitOfMeasureName"
    }]
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/no-chart-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LxkPF3nO",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"style\",\"opacity: 0.05;width:65%;margin:20px auto\"],[8],[0,\"\\n    \"],[1,[28,\"svg-jar\",[\"profit\"],[[\"class\",\"width\",\"height\"],[\"icon\",\"100%\",\"100%\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"h4\",true],[10,\"style\",\"text-align: center;opacity: 0.55;font-weight:400\"],[8],[0,\"\\n    No record found for chart\\n\"],[9],[0,\"\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/no-chart-content.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/hmis-app/admin-settings/insurance-companies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/o6b3m3t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n    \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"New\",[28,\"action\",[[23,0,[]],\"newRecord\"],null],\"add-button\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n    \\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height\",true,\"Insurance Companies\"]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"app-fixed-top\"],[8],[0,\"\\n\"],[0,\"            \"],[1,[28,\"input\",null,[[\"class\",\"placeholder\",\"value\",\"placeholder\"],[\"searchbox\",\"Search\",[24,[\"query\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n            \"],[1,[28,\"controls/list-view\",null,[[\"class\",\"showSearch\",\"data\",\"query\",\"filterParams\",\"main\",\"sub\",\"onClick\",\"onEdit\",\"onDelete\"],[\"full-width\",false,[24,[\"model\"]],[24,[\"query\"]],[28,\"array\",[\"name\"],null],\"name\",\"levelCode\",[28,\"action\",[[23,0,[]],\"viewRecord\"],null],[28,\"action\",[[23,0,[]],\"editRecord\"],null],[28,\"action\",[[23,0,[]],\"deleteRecord\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/admin-settings/insurance-companies/index.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/constants/department-unit-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.DEPARTMENT_UNIT_TYPES = void 0;
  const DEPARTMENT_UNIT_TYPES = {
    LAB: 1,
    WARD: 2
  };
  _exports.DEPARTMENT_UNIT_TYPES = DEPARTMENT_UNIT_TYPES;
});
define("hmis/routes/application", ["exports", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_applicationRouteMixin.default, {
    routeAfterAuthentication: 'configure',
    currentUser: Ember.inject.service(),
    app: Ember.inject.service(),
    session: Ember.inject.service(),

    beforeModel() {
      return this._loadCurrentUser();
    },

    afterModel() {
      this.get('data.loadIni');
    },

    sessionAuthenticated() {
      this._super(...arguments);

      this._loadCurrentUser();
    },

    _loadCurrentUser() {
      return this.get('currentUser').load().catch(err => {
        console.error(err);
        this.get('session').invalidate();
      });
    },

    actions: {// loading(transition, route) {
      //   this.get('app').set('appLoading', true)
      //   transition.finally(()=> {
      //     this.get('app').set('appLoading', false)
      //   });
      // }
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/configure-side-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JW6Cv/N5",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"hmis-side-menu \"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"menus\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"style\",\"height:45px; width:3px;\"],[8],[9],[0,\"\\n      \"],[1,[28,\"hmis-side-menu-item\",null,[[\"currentPathHook\",\"module\",\"onMenuClick\"],[[24,[\"currentPathHook\"]],[28,\"hash\",null,[[\"name\",\"route\",\"icon\"],[\"Facility\",\"configure\",\"dashboard\"]]],[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n      \"],[1,[28,\"hmis-side-menu-item\",null,[[\"currentPathHook\",\"module\",\"onMenuClick\"],[[24,[\"currentPathHook\"]],[28,\"hash\",null,[[\"name\",\"route\",\"icon\"],[\"Departments\",\"configure.department\",\"patient\"]]],[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n      \"],[1,[28,\"hmis-side-menu-item\",null,[[\"currentPathHook\",\"module\",\"onMenuClick\"],[[24,[\"currentPathHook\"]],[28,\"hash\",null,[[\"name\",\"route\",\"icon\"],[\"Examination\",\"configure.vitalsigns\",\"medical-result\"]]],[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n      \"],[1,[28,\"hmis-side-menu-item\",null,[[\"currentPathHook\",\"module\",\"onMenuClick\"],[[24,[\"currentPathHook\"]],[28,\"hash\",null,[[\"name\",\"route\",\"icon\"],[\"Insurance\",\"configure.insurance\",\"doctor\"]]],[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n\"],[0,\"      \"],[1,[28,\"hmis-side-menu-item\",null,[[\"currentPathHook\",\"module\",\"onMenuClick\"],[[24,[\"currentPathHook\"]],[28,\"hash\",null,[[\"name\",\"route\",\"icon\"],[\"Finish\",\"configure.finish\",\"shuffle\"]]],[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n  \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/configure-side-menu.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/avatar-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['avatar-view'],
    actions: {
      onPictureChange(file) {
        let action = this.get('onChange');

        if (action) {
          action(file);
        }
      },

      showChooser(event) {
        event.preventDefault();
        event.stopPropagation();
        this.set('show', true);
      }

    }
  });

  _exports.default = _default;
});
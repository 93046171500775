define("hmis/components/managers/department-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flex_level: 'flex-45 flex-xs-100 flex-sm-100',
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      save() {
        let model = this.get('model');
        let store = this.get('store');
        let that = this;
        let post = store.createRecord('department', {
          name: this.get('name'),
          facility: this.get('currentUser.user').belongsTo('facility').id()
        });
        post.save().then(record => {
          that.notify.success('Saved Successfully');
        }).catch(failure => {
          that.notify.error('Unable to Save' + failure);
        });
      }

    } // store: Ember.inject.service('store'),
    // notify: Ember.inject.service(),
    // actions:{
    //   save()
    //   {
    //     let that = this
    //     let model = this.get('model')
    //     console.log(this.get('model'))
    //     console.log(this.get('name'))
    //
    //     this.set('model', that.get('store').createRecord('department', model))
    //
    //     this.get("model").save()
    //       .then((record)=>{
    //         //alert("Saved");
    //         that.notify.success('Saved Successfully')
    //         //this.transitionToRoute("hmis-app.admin-settings.investigation-type");
    //       })
    //       .catch((failure)=>{
    //         //alert("Unable to saved");
    //         that.notify.error('Unable to Save'+ failure)
    //       });
    //   }
    // },

  });

  _exports.default = _default;
});
define("hmis/components/list-filter-no-elem", ["exports", "hmis/utils/list-util"], function (_exports, _listUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    filterParams: [],
    query: '',
    tagName: '',
    pageIndex: 1,
    debounceDataLoadTime: 500,
    filterString: Ember.computed.alias('query'),
    pageSize: Ember.computed.alias('pageLimit'),
    observedProperties: Ember.computed(function () {
      return ['query', 'data.length', 'pageIndex', 'pageLimit'];
    }),

    _loadData() {
      let filterString = this.query;
      let list = this.data;
      let filterParams = this.filterParams;
      let pageIndex = this.pageIndex;
      let pageLimit = Number(this.pageLimit) || 50;
      new Promise(resolve => {
        //search
        if (filterString && filterString.length) {
          list = _listUtil.default.filterList(filterString, list, filterParams);
        } //pagination


        let startIndex = (pageIndex - 1) * pageLimit;
        resolve(list.slice(startIndex, startIndex + pageLimit));
      }).then(res => {
        this.set('filteredContent', res); // this.set( 'lastSearchedFilteredContent', res)
      });
    },

    itemsCount: Ember.computed('filteredContent.@each', function () {
      return this.filteredContent ? this.filteredContent.length : 0;
    }),
    pagesCount: Ember.computed('filteredContent.@each', 'pageLimit', function () {
      return Math.ceil(this.itemsCount / this.pageLimit);
    }),
    lastIndex: Ember.computed('itemsCount', 'pageIndex', 'pageLimit', function () {
      let pageMax = parseInt(this.get('pageLimit'), 10) * this.get('pageIndex');
      let itemsCount = this.itemsCount;
      return Math.min(pageMax, itemsCount);
    }),

    didReceiveAttrs() {
      Ember.set(this, 'filteredContent', this.filteredContent ? this.filteredContent : this.data);
    },

    _addPropertyObserver() {
      Ember.run.debounce(this, this._loadData, Ember.get(this, 'debounceDataLoadTime'));
    },

    willInsertElement() {
      this._super(...arguments);

      let observedProperties = Ember.get(this, 'observedProperties');
      observedProperties.forEach(propertyName => this.addObserver(propertyName, this._addPropertyObserver));
    },

    willDestroyElement() {
      this._super(...arguments);

      let observedProperties = Ember.get(this, 'observedProperties');
      observedProperties.forEach(propertyName => {
        this.removeObserver(propertyName, this._addPropertyObserver);
      });
    }

  });

  _exports.default = _default;
});
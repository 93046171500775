define("hmis/models/active-serve", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/visit-service-model", "hmis/mixins/billable-model"], function (_exports, _emberData, _relationships, _dateUtil, _visitServiceModel, _billableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, _billableModel.default, {
    startDate: _emberData.default.attr(),
    endDate: _emberData.default.attr(),
    departmentUnit: (0, _relationships.belongsTo)('department-unit', {
      async: false
    }),
    staff: (0, _relationships.belongsTo)('user'),
    departmentUnitId: _emberData.default.attr(),
    departmentUnitName: _emberData.default.attr(),
    departmentName: _emberData.default.attr(),

    end(callback) {
      this.set('endDate', _dateUtil.default.toSqlDate(new Date()));
      this.set('status', status);
      this.save().then(() => {
        callback.success(this);
      }, error => {
        callback.failure(error);
      });
    },

    startDateF: Ember.computed('startDate', function () {
      // return `${this.get('firstName')} ${this.get('lastName')}`;
      return _dateUtil.default.formatToDateTimeString(this.get('startDate'));
    }),
    endDateF: Ember.computed('endDate', function () {
      // return `${this.get('firstName')} ${this.get('lastName')}`;
      return _dateUtil.default.formatToDateTimeString(this.get('endDate'));
    })
  });

  _exports.default = _default;
});
define("hmis/models/role-module-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    role: _emberData.default.belongsTo('role'),
    modulePermission: _emberData.default.belongsTo('module-permission'),
    allow: _emberData.default.attr('boolean'),
    modulePermissionId: Ember.computed('modulePermission.id', function () {
      return this.get('modulePermission.id');
    })
  });

  _exports.default = _default;
});
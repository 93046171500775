define("hmis/models/investigation", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/visit-service-model", "hmis/mixins/billable-model", "hmis/mixins/visit-request-model "], function (_exports, _emberData, _relationships, _dateUtil, _visitServiceModel, _billableModel, _visitRequestModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, _visitRequestModel.default, _billableModel.default, {
    investigationType: (0, _relationships.belongsTo)('investigation-type', {
      async: false
    }),
    warning: _emberData.default.attr(),
    result: _emberData.default.attr(),
    requestNote: _emberData.default.attr(),
    resultNote: _emberData.default.attr(),
    source: _emberData.default.attr(),
    status: _emberData.default.attr(),
    investigationTypeName: _emberData.default.attr(),
    investigationTypeGdrgCode: _emberData.default.attr(),
    investigationsString: Ember.computed('investigationTypeName', 'requestNote', function () {
      return this.get('investigationTypeName') + (this.get('requestNote') ? ': ' + this.get('requestNote') : '');
    })
  });

  _exports.default = _default;
});
define("hmis/helpers/paged-column-id", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function pagedColumnId([id, ...rest]
  /*, hash*/
  ) {
    if (typeof id === 'string' || id instanceof String) {
      let idx = 'col' + id.replace(/ /g, "_sp_");
      idx = idx.replace(/-/g, "_hi_");
      return idx;
    } else return 'col' + id;
  });

  _exports.default = _default;
});
define("hmis/mixins/visit-service-model", ["exports", "ember-data", "hmis/utils/date-util", "hmis/mixins/patient-property-model"], function (_exports, _emberData, _dateUtil, _patientPropertyModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_patientPropertyModel.default, {
    visitId: _emberData.default.attr(),
    userName: _emberData.default.attr(),
    episodeId: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    //'visitPurpose', 'visitStatus', 'visitDate', 'patientName', 'patientAge', 'patientGender', 'outstandingBills'
    visitStatus: _emberData.default.attr(),
    visitStatusId: _emberData.default.attr(),
    visitOutcome: _emberData.default.attr(),
    visitOutcomeId: _emberData.default.attr(),
    visitPurpose: _emberData.default.attr(),
    visitPurposeId: _emberData.default.attr(),
    visitDate: _emberData.default.attr(),
    outstandingBills: _emberData.default.attr(),
    dateTimeF: Ember.computed('createdAt', function () {
      return _dateUtil.default.formatToDateTimeString(this.get('createdAt'));
    }),
    dateF: Ember.computed.alias('dateTimeF'),
    visitDateF: Ember.computed('visitDate', function () {
      return _dateUtil.default.formatToDateString(this.get('visitDate'));
    })
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/scheduler/roster", ["exports", "hmis/utils/model-table-theme", "hmis/utils/date-util", "ember-data"], function (_exports, _modelTableTheme, _dateUtil, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service('store'),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    notify: Ember.inject.service(),
    tabStatus: 0,
    batch: [],
    counter: 1,
    doneLoading: true,
    batchItems: {},
    currentDate: _dateUtil.default.toSqlDateOnly(new Date()),
    depUnit: Ember.computed('selDepartment', function () {
      let dep = this.get('selDepartment.id');
      return this.get('store').query('department-unit', {
        filter: {
          departmentId: dep
        },
        include: ['departmentName']
      }).then(function (d) {
        return d;
      });
    }),
    actions: {
      back() {
        history.back();
      },

      onNewTabClick(route) {
        this.set('tabStatus', 0);
      },

      onHistoryTabClick(route) {
        this.set('tabStatus', 1);
      },

      rowClick(index, record) {
        this.set('batchItems', record);
        this.set('showPrescription', true);
      },

      changeLoad() {
        this.set('doneLoading', !this.get('doneLoading'));
      },

      showLoading(show) {
        this.set('doneLoading', show);
      },

      doSomethingWithSelectedValue() {
        console.log('done something');
      },

      onDateChange(dat) {
        let that = this;
        this.set('currentDate', dat);
        let dep = this.get('selDepartment');

        if (dep) {
          this.send('onDepartmentChange', dep);
        } else {
          that.notify.error('Select Department');
        }
      },

      onDepartmentChange(dep) {
        this.set('selDepartment', dep);
        this.set('doneLoading', false);
        let that = this;
        let columns = [{
          "title": 'Time',
          "propertyName": "periodF",
          "component": 'periodFor',
          'className': 'titleCol'
        }];
        this.depUnit.then(d => {
          if (d.length) {
            this.set('depUnitLenght', true);
            that.set('doneLoading', true);
            d.forEach(function (item, index) {
              let selDep = {
                "title": item.get('name'),
                departmentUnit: item,
                "component": 'doctorSel'
              };
              columns.pushObject(selDep);

              if (index == d.length - 1) {
                that.set('columns', columns);
                that.set('doneLoading', true);
              }
            });
          } else {
            that.set('columns', []);
            that.set('doneLoading', true);
          }
        }); // if(this.get('selDepartment').hasMany('departmentUnits').value())
        // if(depUnit.length)
        // {
        //   var millisecondsToWait = 100;
        //   setTimeout(()=> {
        //     let ds  = dep.departmentUnits // this.get('selDepartment.departmentUnits')
        //     let length = ds.get('length')
        //     if(length){
        //       ds.forEach(function(item, index) {
        //         let selDep = { "title": item.get('name'), departmentUnit:item,  "component": 'doctorSel' }
        //         columns.pushObject(selDep);
        //         if(index == length-1){
        //           that.set('columns', columns);
        //           that.set('doneLoading', true)
        //         }
        //       });
        //     }else{
        //       that.set('columns', []);
        //       that.set('doneLoading', true)
        //     }
        //   }, millisecondsToWait);
        // }else{
        //   let dss  = dep.departmentUnits
        //   depUnit.then( (ds)=>{
        //     let length = ds.get('length')
        //     if(length){
        //       ds.forEach(function(item, index) {
        //         let selDep = { "title": item.get('name'), departmentUnit:item,  "component": 'doctorSel' }
        //         columns.pushObject(selDep);
        //         if(index == length-1){
        //           // console.log('at last in new load')
        //           that.set('columns', columns);
        //           that.set('doneLoading', true)
        //         }
        //       });
        //     }else{
        //       that.set('columns', []);
        //       that.set('doneLoading', true)
        //     }
        //   })
        // }
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [] //
    // columns: [
    //   {
    //     "title":'Time',
    //     "propertyName": "periodF",
    //     "component": 'periodFor',
    //   },
    //   // {
    //   //   "title":'Monday',
    //   //   "component": 'doctorSel',
    //   // },
    //   // {
    //   //   "title":'Tuesday',
    //   //   "component": 'doctorSel',
    //   // },
    //   // {
    //   //   "title":'Wenesday',
    //   //   "component": 'doctorSel',
    //   // },
    //   // {
    //   //   "title":'Thursday',
    //   //   "component": 'doctorSel',
    //   // },
    //   // {
    //   //   "title":'Friday',
    //   //   "component": 'doctorSel',
    //   // },
    //   // {
    //   //   "title":'Saturday',
    //   //   "component": 'doctorSel',
    //   // },
    //   // {
    //   //   "title":'Sunday',
    //   //   "component": 'doctorSel',
    //   // },
    // ],

  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/opd/consultation/consult/vital-requests", ["exports", "hmis/utils/model-table-theme", "hmis/utils/date-util", "hmis/mixins/form-controller", "hmis/mixins/visit-care-controller"], function (_exports, _modelTableTheme, _dateUtil, _formController, _visitCareController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, _visitCareController.default, {
    store: Ember.inject.service('store'),
    input_width: "flex-40 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    app: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    requestStatus: Ember.computed('oPerms', function () {
      return this.get('oPerms.op18.allow') ? 0 : 1;
    }),
    data: Ember.computed('model.requestData', 'model.resultData', 'requestStatus', function () {
      let requestStatus = this.get('requestStatus');
      if (requestStatus == 1) return this.get('model.requestData');else if (requestStatus == 2) return this.get('model.resultData');else [];
    }),
    actions: {
      back() {
        history.back();
      },

      sendRequest() {
        let note = this.get('note');
        let that = this;
        this.appAjax.post('/vitalsRequests', {
          visitId: this.model.visitId,
          note: note
        }).then(savedInv => {
          that.send('refreshModel');
          that.set('note', ''); //this.get('app').loadDashboardStats();

          that.notify.success('Request sent');
        }).catch(failure => {
          console.log(failure);
          that.notify.error('Unable to send request');
        });
      },

      onRowClick(index, record) {
        if (this.get('requestStatus') != 2) return;
        this.set('selectedRequest', record);
        this.set('showRecord', true);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      'title': 'Date ',
      "propertyName": "dateTimeF",
      className: 'main-column'
    }, {
      'title': 'Note',
      "propertyName": "note",
      component: 'controls/table-long-text'
    }]
  });

  _exports.default = _default;
});
define("hmis/components/controls/change-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    ajaxRequest: Ember.inject.service(),
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    disableSave: Ember.computed('oldPassword', 'newPassword', 'verifyPassword', function () {
      let oldPassword = this.get('oldPassword');
      let newPassword = this.get('newPassword');
      let verifyPassword = this.get('verifyPassword');
      if (!oldPassword || !newPassword || !verifyPassword) return true;
    }),
    actions: {
      onSave() {
        // console.log('lalala');
        this.get('submitForm')();
      },

      onValidFormSubmit() {
        // console.log('lalal')
        let newPassword = this.get('newPassword');
        let oldPassword = this.get('oldPassword');
        this.set('changing', true);
        this.get('ajaxRequest').changePassword(oldPassword, newPassword, {
          success: () => {
            this.notify.success('Password Changed');
            this.set('changing', false);
            let action = this.get('onPasswordSaved');
            if (action) action();
            this.set('show', false);
            this.get('session').invalidate();
          },
          error: err => {
            this.set('changing', false);
            this.notify.error(err);
          }
        });
      }

    },
    verifyPasswordValidation: Ember.computed('newPassword', 'verifyPassword', function () {
      return [{
        message: 'Password not the same a new password',
        validate: inputValue => {
          let newPassword = this.get('newPassword');
          return newPassword == inputValue;
        }
      }];
    })
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/admin-settings/departments/manage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import RSVP from 'rsvp';
  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    model(params) {
      return this.get('store').findRecord('department', params.record_id);
    }

  });

  _exports.default = _default;
});
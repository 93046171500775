define("hmis/components/managers/vitals-type-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    units: Ember.computed('record.vitalType', function () {
      let vitaltype = this.get('record.vitalType');

      if (vitaltype) {
        console.log(vitaltype.get('unitOfMeasure'));
        let um = vitaltype.serialize();
        console.log(um.unitOfMeasure.split(';'));
        let ag = this.get('store').query('vitals-unit-tem-measure', {
          filter: {
            code: 'kg'
          }
        }); // .then(function(recs) {
        //   console.log('recs', recs)
        //   return recs
        // })

        console.log('ag >', ag);
        return this.get('store').query('vitals-unit-of-measure', {
          filter: {
            id: "2"
          }
        }); //   .then(function(recs) {
        //   console.log('recs', recs)
        //   return recs
        // })
      } //return this.get('store').findAll('vitals-unit-of-measure')

    }) // facilities: computed(function(){
    //   return this.get('store').findAll('facility')
    // }),
    // vitaltypes: computed(function(){
    //   return this.get('store').findAll('vital-type')
    // }),

  });

  _exports.default = _default;
});
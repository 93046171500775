define("hmis/controllers/hmis-app/reports/chag-procedures", ["exports", "hmis/utils/app-config/report-types", "hmis/mixins/report-controller"], function (_exports, _reportTypes, _reportController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportController.default, {
    reportType: 'chagProcedures',
    tabIndex: 0,
    actions: {}
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/transactions/index", ["exports", "hmis/utils/model-table-theme", "ember-data", "hmis/utils/general-util", "hmis/mixins/app-event-mixin", "hmis/mixins/page-error-handler"], function (_exports, _modelTableTheme, _emberData, _generalUtil, _appEventMixin, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_appEventMixin.default, _pageErrorHandler.default, {
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    app: Ember.inject.service(),
    selectedItems: [],
    transactRecord: [],
    allowSelect: true,
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),

    onPermissionNotification(data) {
      // console.log('onPermissionNotification', data);
      // alert('data.permission')
      if (data.permission == 'mp7') {
        this.send('filterBVisits', vDate, true);
        this.send('filterBTrans', tDate, true);
      }
    },

    actions: {
      payTransaction() {
        let transaction = this.get('transactRecord');
        this.get('ajaxRequest').payTransaction(transaction, {
          success: response => {
            this.notify.success('Payment successful');
            transaction.set('hasChange', !transaction.get('hasChange')); // that.get('store').findRecord('visit', visitId, {reload: true});
          },
          error: error => {
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      filterBVisits(selectedDates, force = false) {
        if (this.visitsSelectedDates === selectedDates && !force) return;
        this.set('visitsSelectedDates', selectedDates);
        let dateRange = (0, _generalUtil.selectedDatesToDaterange)(selectedDates);
        let filter = (0, _generalUtil.trimNullProps)({
          dateRange,
          transactionVisits: 1
        });
        this.set('BVisitsModel', this.app.query('visit', {
          filter: filter,
          include: ['patientName', 'purposeName', 'statusName']
        }));
      },

      filterBTrans(selectedDates, force = false) {
        if (this.transSelectedDates === selectedDates && !force) return;
        this.set('transSelectedDates', selectedDates);
        let dateRange = (0, _generalUtil.selectedDatesToDaterange)(selectedDates);
        let filter = (0, _generalUtil.trimNullProps)({
          dateRange
        });
        this.set('BTransModel', this.app.query('transaction', {
          filter: filter,
          include: ['patientName']
        }));
      },

      rowBVisitsClick(index, visit) {
        let rid = visit.get('id');
        this.get("routing").transitionTo("hmis-app.transactions.visit", [rid]);
      },

      rowBTransClick(index, record) {
        this.set('transactRecord', record);
        this.set('billModal', true);
      } //   loadVisits(){
      //       let fromDate = this.get('fromBVisitsDate')
      //       let toDate = this.get('toBVisitsDate')
      //       if(this.get('isLoadInit')){
      //         this.get('app').startLoading()
      //       }else{
      //         this.set('isLoadInit', true)
      //       }
      //       // console.log('datya', 'firsyt');
      //       this.store.query('visit',{
      // filter: {
      //     dateRange:{
      //         fromDate:fromDate.toISOString().slice(0, 19).replace('T', ' '),
      //         toDate:toDate.toISOString().slice(0, 19).replace('T', ' ')
      //     },
      // },
      //         })
      //         .then((qp)=>{
      //         //  console.log('datya', 'los');
      //           if(this.get('isLoadInit'))
      //            this.get('app').stopLoading()
      //             //  console.log('datya', qp);
      //           this.set('BVisitsModel', qp)
      //         }).catch((err)=>{
      //           if(this.get('isLoadInit'))
      //           this.get('app').stopLoading()
      //         })
      //     },


    },
    billAlertProps: [{
      title: 'Date of bill',
      name: 'dateF'
    }, {
      title: 'Patient',
      name: 'patientName'
    }, {
      title: 'Trasaction Type',
      name: 'billableType'
    }, {
      title: 'Bill for',
      name: 'billableItemName'
    }, {
      title: 'Amount',
      name: 'amountF'
    }],
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    visitsColumns: [{
      'title': 'Date of visit',
      "propertyName": "dateF",
      'className': 'main-column'
    }, {
      'title': 'Patient',
      'propertyName': 'patientName'
    }, {
      'title': 'Purpose of visit',
      'propertyName': 'purposeName'
    }, {
      "title": "Visit status",
      "propertyName": "statusName"
    }],
    transColumns: [{
      'title': 'Transaction time',
      "propertyName": "dateF",
      className: 'main-column'
    }, {
      title: 'Transaction type',
      propertyName: 'billableType'
    }, {
      title: 'Transaction item',
      propertyName: 'billableItemName'
    }, {
      title: 'Patient',
      propertyName: "patientName"
    }, {
      "propertyName": "paymentStatus"
    }]
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/records/patient/family", ["exports", "hmis/utils/general-util", "hmis/mixins/route-error-handler"], function (_exports, _generalUtil, _routeErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, {
    model() {
      var patient = this.modelFor("hmis-app.records.patient");
      return (0, _generalUtil.modelHash)({
        patients: this.get('store').query('patient', {}),
        // relationshipTypes: this.get('store').query('relationship-type', {}),
        relatives: this.get('store').query('patient-relation', {
          filter: {
            relatedPatient: patient.id
          },
          include: ['typeName', 'patientName', 'relatedToName', 'typeMaleInverseName', 'typeFemaleInverseName']
        }),
        patient: patient
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
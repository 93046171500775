define("hmis/components/managers/department-unit-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flex_level: 'flex-45 flex-xs-100 flex-sm-100',
    store: Ember.inject.service('store'),
    departments: Ember.computed('model', function () {
      return this.get('store').findAll('department');
    })
  });

  _exports.default = _default;
});
define("hmis/components/managers/appointment/new-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),

    init() {
      this._super(...arguments);

      if (this.get('staff')) {
        this.set('hideStaff', true);
      }
    },

    appointment: {},
    actions: {
      onConfirm() {
        let data = this.get('appointment');
        this.get('store').createRecord('appointment', {
          schedule: data.schedule,
          purpose: data.purpose,
          patient: data.patient
        }).save().then(appointment => {
          this.notify.success('Appointment created for ' + data.schedule.get('dueRange'));
          let action = this.get('onSuccess');
          if (action) action(appointment);
        }, error => {
          this.notify.error('Something went wrong');
          console.log(error);
        });
      }

    }
  });

  _exports.default = _default;
});
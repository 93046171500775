define("hmis/routes/hmis-app/users/user/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    redirect: function () {
      this.transitionTo('hmis-app.users.user.profile');
    }
  });

  _exports.default = _default;
});
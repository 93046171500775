define("hmis/components/pickers/room-picker", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    title: 'Wards',
    store: Ember.inject.service('store'),
    state: 0,
    selectedItems: [],
    selectedRooms: [],
    wards: Ember.computed('pickerPendings', 'pickerWaiting', function () {
      let pat = this.get('pickerPendings.patient') ? this.get('pickerPendings.patient') : [];
      return this.get('store').query('ward', {
        filter: {
          type: 2,
          gender: pat.get('gender.id')
        }
      });
    }),
    breadcrumbs: ['Wards'],
    breadcrumbIndex: 0,
    actions: {
      onWardClick(index, ward) {
        // console.log(ward)
        this.set('breadcrumbs', [ward.get('name')]);
        this.set('selectedWard', ward);
        this.set('selectedRooms', []);
        this.set('selectedRoom', null);
        this.get('breadcrumbs').pushObject('Room');
        this.set('title', 'Wards > Rooms');
        this.set('breadcrumbIndex', 1);
      },

      onRoomClick(index, room) {
        let unit = this.get('selectedRooms').get('firstObject');
        this.set('selectedRoom', unit);
      },

      onRoomDoubleClick(index, room) {// this.set('selectedRoom', room)
        // let action = this.get('onRoomSelected')
        // if(action){
        //   action(room)
        //
        //   this.set('title', 'Wards')
        //   this.set('state', 0)
        // }
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      'title': 'Ward Name',
      "propertyName": "name"
    }],
    rcolumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      'title': 'Room',
      "propertyName": "name"
    }]
  });

  _exports.default = _default;
});
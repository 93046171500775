define("hmis/components/appointments-view", ["exports", "hmis/utils/model-table-theme", "hmis/utils/date-util", "hmis/models/appointment", "hmis/utils/general-util", "hmis/mixins/app-event-mixin"], function (_exports, _modelTableTheme, _dateUtil, _appointment, _generalUtil, _appEventMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_appEventMixin.default, {
    init() {
      this._super(...arguments);
    },

    notify: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    tagName: '',
    pageView: true,
    routing: Ember.inject.service('-routing'),
    app: Ember.inject.service(),
    allowSelect: true,
    onConfirm: 'empty',
    freeToServe: false,

    onUserNotification(e) {
      if (e.actionValue == 'appointment') {
        this.send('loadAppointments', this.selectedDates, true);
      }
    },

    testDates: [],
    actions: {
      empty() {},

      loadAppointments(selectedDates, force = false) {
        if (this.selectedDates !== selectedDates || force) {
          this.set('selectedDates', selectedDates);
          let dateRange = (0, _generalUtil.selectedDatesToDaterange)(selectedDates);
          let filter = (0, _generalUtil.trimNullProps)({
            dateRange,
            served: 0,
            staffId: this.get('currentUser.user.id')
          });
          this.set('appointments', this.app.query('appointment', {
            filter,
            include: ['patientName', 'purposeName']
          }));
        }
      },

      onAppointmentClick(index, record) {// console.log(record);
        // this.set('onConfirm','startQueueServe')
        // this.set('recordTag',record)
        // this.set('alertTitle','Attempt to serve '+record.get('patientName'))
        // this.set('alertMessage','Are you ready to start serving a new patient?')
        // this.set('onAlert',true)
      },

      startAppointmentServe(record) {
        //startActiveServeFromAppointment
        this.notify.confirmSimple('Attempting to start serving  ' + record.patientName, null, () => {
          this.set('isLoading', true);
          this.appAjax.post('/startActiveServeFromAppointment/' + record.id, null).then(data => {
            this.notify.success('You are now serving patient');
            this.get("router").transitionTo('hmis-app.opd.consultation.consult', data.id);
            this.set('isLoading', false);
          }).catch(err => {
            this.notify.error(err);
            this.set('isLoading', false);
          });
        });
      },

      rescheduleAppointment(record) {
        // this.set('appointmentToReschedule', record)
        // this.set('rescheduleAppointment', true)
        this.appointmentManager.rescheduleAppointment(record, newAppointment => {
          this.send('loadAppointments', this.selectedDates);
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/
        ,
        noDataCell: 'no-data-cell'
      });
    }),
    columns: [{
      title: 'Patient',
      "propertyName": "patientName"
    }, {
      title: 'Purpose',
      "propertyName": "purposeName"
    }, {
      title: 'Appointment Date',
      "propertyName": "appointmentDateF"
    }, {
      title: 'Date created',
      "propertyName": "dateTimeF"
    }, {
      "title": "",
      "component": "manageRow"
    }]
  });

  _exports.default = _default;
});
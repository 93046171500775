define("hmis/components/error-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    serverResponse: Ember.inject.service(),
    message: Ember.computed('page.model', 'errorResult', function () {
      // console.log(this.page.model)
      return this.serverResponse.errorMessage(this.errorResult ? this.errorResult : this.page.model);
    })
  });

  _exports.default = _default;
});
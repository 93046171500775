define("hmis/components/controls/sticky-table-wrapper", ["exports", "hmis/mixins/after-render"], function (_exports, _afterRender) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_afterRender.default, {
    tagName: 'section',
    classNames: ['fixed-table-section'],
    afterRenderEvent: function () {
      // alert('hiii')
      $(".sticky-table thead tr").addClass("sticky-row");
      $(".sticky-table thead tr th").addClass("sticky-cell");
    }
  });

  _exports.default = _default;
});
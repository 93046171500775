define("hmis/templates/components/managers/facility-vitals-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "00HPCKGM",
    "block": "{\"symbols\":[\"item\",\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"style\",\"margin: 10px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-40\"],[8],[0,\"\\n\"],[4,\"app-select\",null,[[\"class\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"flex_level\"]],\"Facility\",[24,[\"record\",\"department\"]],[24,[\"facilities\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"facilities\"]]],null]],null]]],{\"statements\":[[0,\"      \"],[1,[23,2,[\"facilityName\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"style\",\"margin: 10px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-95\"],[8],[0,\"\\n\\n\"],[4,\"paper-chips\",null,[[\"readOnly\",\"removeItem\",\"addItem\",\"placeholder\",\"content\",\"options\",\"searchField\",\"noMatchesMessage\"],[[24,[\"readOnly\"]],[28,\"action\",[[23,0,[]],\"removeInv\"],null],[28,\"action\",[[23,0,[]],\"addInv\"],null],\"Search to add\",[24,[\"selectedInv\"]],[24,[\"options\"]],\"name\",\"Not found\"]],{\"statements\":[[0,\"      \"],[7,\"strong\",true],[8],[1,[23,1,[\"name\"]],false],[0,\" \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"name\"]]],null,{\"statements\":[[0,\"        \"],[7,\"small\",true],[8],[7,\"em\",true],[8],[7,\"strong\",true],[8],[0,\" unit: \"],[9],[1,[23,1,[\"units\"]],false],[0,\" \"],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/facility-vitals-manager.hbs"
    }
  });

  _exports.default = _default;
});
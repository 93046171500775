define("hmis/components/circle-progress-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments); // this.set('options.color',this.get('color'));


      this.colorChanged(); // this.set('options.color',this.get('color'));
      // this.set('options.text.style.color',this.get('color'));
    },

    //   color:"#7757F8",
    colorChanged: Ember.observer('color', function () {// deal with the change
      // console.log(`fullName changed to: ${this.get('fullName')}`);
      // this.set('defaultOptions.color',this.get('color'));
      // this.set('defaultOptions.text.style.color',this.get('color'));
    }),
    options: {
      color: Ember.computed('color', function () {
        return this.get('color');
      }),
      trailColor: '#D4CFE6',
      // This has to be the same size as the maximum width to
      // prevent clipping
      strokeWidth: 5,
      trailWidth: 5,
      easing: 'easeInOut',
      duration: 1400,
      text: {
        //   autoStyleContainer: false, 
        style: {
          // Text color.
          // Default: same as stroke color (options.color)
          // color: '#7757F8',
          position: 'absolute',
          fontSize: 30,
          left: '50%',
          top: '50%',
          padding: 0,
          margin: 0,
          // You can specify styles which will be browser prefixed
          transform: {
            prefix: true,
            value: 'translate(-50%, -50%)'
          }
        }
      },
      // from: { color: '#A5258C', width: 1 },
      // to: { color: '#A5258C', width: 20 },
      // // Set default step function for all animate calls
      step: function (state, circle) {
        //   circle.path.setAttribute('stroke', state.color);
        //   circle.path.setAttribute('stroke-width', state.width);
        var value = Math.round(circle.value()); // if (value === 0) {
        //   circle.setText('');
        // } else {

        circle.setText(value + "%"); // }
        // console.log('actual val: '+circle.value(), ' Rounded Val: '+value)
      }
    }
  });

  _exports.default = _default;
});
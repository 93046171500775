define("hmis/mixins/management-report-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      this.set('filterValues', {
        filters: {},
        selectedRows: [],
        selectedColumns: [],
        allRows: true,
        allColumns: true,
        appSelections: {
          filters: {},
          selectedRows: [],
          selectedColumns: []
        }
      });
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), 0, 1);
      var lastDay = new Date(date.getFullYear(), 11 + 1, 0);
      this.set('filterValues.fromDate', moment(firstDay).format("YYYY-MM-DD"));
      this.set('filterValues.toDate', moment(lastDay).format("YYYY-MM-DD"));
      this.set('otherFilters', []); // this.actions.loadFilters();
    },

    reports: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    app: Ember.inject.service(),
    notify: Ember.inject.service(),
    selectedRows: [],
    selectedColumns: [],
    tabIndex: 0,
    showNoDataRowsColumns: true,
    isLoadingReport: false,
    reportProgress: 0,
    filters: [],
    aggregates: [],
    filterValues: {},
    initialLoad: true,
    pageIndex: 0,
    generateText: Ember.computed('initialLoad', function () {
      return this.initialLoad ? "Generate" : "Change filters";
    }),
    actions: {
      loadFilters() {
        this.get('app').startLoading();
        this.set('isLoadingReport', true);
        this.appAjax.post('/paged-reports/filters/' + this.reportType).then(reportFilter => {
          this.set('filters', reportFilter.filters);
          this.set('aggregates', reportFilter.aggregates);
          console.log('aggregates', this.aggregates); // SETTINGS DEFAULTS

          this.set('filterValues.appSelections.aggregate', reportFilter.aggregates.find(item => item.key == reportFilter.defaults.aggregate));
          this.set('filterValues.aggregate', this.filterValues.appSelections.aggregate ? this.filterValues.appSelections.aggregate.key : null);
          this.set('filterValues.appSelections.row', reportFilter.filters.find(item => item.key == reportFilter.defaults.row));
          this.set('filterValues.row', this.filterValues.appSelections.row ? this.filterValues.appSelections.row.key : null);
          this.set('filterValues.appSelections.column', reportFilter.filters.find(item => item.key == reportFilter.defaults.column));
          this.set('filterValues.column', this.filterValues.appSelections.column ? this.filterValues.appSelections.column.key : null);

          if (this.filterValues.appSelections.column) {
            this.set('filterValues.allColumns', false);
          }

          this.set('isLoadingReport', false);
          this.get('app').stopLoading();
        }).catch(err => {
          console.log(err);
          this.notify.error(err);
          this.set('isLoadingReport', false);
          this.get('app').stopLoading();
        });
      },

      showFilter() {
        if (this.initialLoad) {
          this.send('confirmFilter');
        } else {
          this.set('filterValuesBackup', JSON.stringify(this.filterValues));
          this.set('showFilterModal', true);
        }
      },

      confirmFilter() {
        console.log(this.filterValues);
        this.send('loadNextPageReport');
      },

      cancelFilter() {
        this.set('filterValues', JSON.parse(this.filterValuesBackup)); // this.set('showFilterModal', false)
      },

      loadNextPageReport() {
        let filterParams = Object.assign({}, this.filterValues);
        delete filterParams.appSelections;
        filterParams.pageIndex = this.pageIndex + 1;
        filterParams.paginationMeta = this.paginationMeta; // selectedRows:[], selectedColumns:[],
        // allRows:true, allColumns: true,

        if (!filterParams.allRows && !filterParams.selectedRows.length) {
          this.notify.error('Failed! Select report rows to report');
          return;
        }

        if (!filterParams.allColumns && !filterParams.selectedColumns.length) {
          this.notify.error('Failed! Select report columns to report on');
          return;
        }

        this.get('app').startLoading();
        this.set('isLoadingReport', true);
        this.appAjax.post("/paged-reports/data/".concat(this.reportType, "/").concat(this.filterValues.fromDate, "/").concat(this.filterValues.toDate), filterParams).then(dataReport => {
          // console.log('report data', reportData );
          this.set('showFilterModal', false);
          this.set('dataReport', dataReport);
          this.set('initialLoad', false);
          this.set('isLoadingReport', false);
          this.get('app').stopLoading();
        }).catch(err => {
          console.log(err);
          this.notify.error(err);
          this.set('isLoadingReport', false);
          this.get('app').stopLoading();
        });
      }

    }
  });

  _exports.default = _default;
});
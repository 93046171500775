define("hmis/components/pickers/staff-input", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service('store'),
    appData: Ember.inject.service(),
    data: Ember.computed(function () {
      return this.get('store').findAll('user');
    }),
    label: 'Choose a staff',
    columns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      title: 'Patient name',
      "propertyName": "fullName"
    }, {
      "propertyName": "phoneNumber"
    }]
  });

  _exports.default = _default;
});
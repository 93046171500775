define("hmis/helpers/test-helpa", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.testHelpa = testHelpa;
  _exports.default = void 0;

  function testHelpa() {
    return ["Red", "Blue", "Yellow", "Green", "Purple", "Orange"];
  }

  var _default = Ember.Helper.helper(testHelpa);

  _exports.default = _default;
});
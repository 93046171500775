define("hmis/routes/hmis-app/bills/bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        visit: this.get('store').findRecord('visit', params.record_id),
        transactions: this.get('store').query('transaction', {
          filter: {
            visit: params.record_id,
            isInsurance: 0
          }
        }),
        insuranceProducts: this.get('store').findAll('insurance-product')
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      } //this.send('refreshModel')

    }
  });

  _exports.default = _default;
});
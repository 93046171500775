define("hmis/templates/components/managers/appointment/reschedule-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fcP6M4Ku",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[28,\"managers/appointment/appointment-alert\",null,[[\"show\",\"onConfirm\",\"title\",\"confirmText\"],[[24,[\"show\"]],[28,\"action\",[[23,0,[]],\"onConfirm\"],null],\"Reschedule Appointment\",\"Create Appointment\"]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/appointment/reschedule-alert.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/patient/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    init() {
      this._super(...arguments);

      this.store.query('questionnaire-response', {
        patientId: this.get('model.id')
      }).then(recs => {
        if (recs.get('firstObject')) {
          this.set('answerRecord', recs.get('firstObject'));
          this.set('valueSet', recs.get('firstObject').toAnswer());
        } else {
          this.set('answerRecord', null);
          this.set('valueSet', {});
        }
      });
    },

    answerRecord: null,
    store: Ember.inject.service(),
    data: Ember.inject.service(),
    notify: Ember.inject.service(),
    valueSet: {},
    actions: {
      onRevert() {
        if (this.get('answerRecord')) {
          console.log(this.get('answerRecord').toAnswer());
          this.set('valueSet', this.get('answerRecord').toAnswer()); // this.set('valueSet', this.answerRecord.toAnswer())
        }
      },

      onSave() {
        let answerRecord = this.get('answerRecord');

        if (!answerRecord) {
          answerRecord = this.store.createRecord('questionnaire-response', {
            patientId: this.model.id
          });
        }

        answerRecord.saveAnswer(this.get('valueSet'), rec => {
          this.set('waiting', false);
          this.notify.success("Saved");
        }, () => {
          this.set('waiting', false);
          this.notify.error("Failed to save");
        });
      },

      back() {
        history.back();
      }

    }
  }); // structure:{
  //     link: '1',
  //     items:[
  //         {
  //             link: '1.1',
  //             index:1,
  //             text: "Takes Alchohol",
  //             type: 'choice',
  //             answerValueSet:[ "yes", 'no' ],
  //             items: [
  //                 {
  //                     link:'1.1.1',
  //                     text: 'Bottle Size',
  //                     type: 'choice',
  //                     control: 'combo',
  //                     answerValueSet:[ "small", 'large', 'medium' ],
  //                     iniValues: {
  //                         'small':{ '1.1.2': 1, '1.1.3': 111  },
  //                         'medium':{ '1.1.2': 1, '1.1.3': 123  },
  //                         'large':{ '1.1.2': 1, '1.1.3': 444  }
  //                     },
  //                     enableWhen: [
  //                         {
  //                           question: "1.1",
  //                           operator: "=",
  //                           answer: 'yes'
  //                         }
  //                     ]
  //                 },
  //                 {
  //                     link:'1.1.2',
  //                     text: 'Quantity Per week ',
  //                     type: 'integer',
  //                     enableWhen: [
  //                         {
  //                           question: "1.1",
  //                           operator: "=",
  //                           answer: 'yes'
  //                         }
  //                     ],
  //                     defaultValue:1
  //                 },
  //                 {
  //                     link:'1.1.3',
  //                     text: 'Volume',
  //                     type: 'decimal',
  //                     enableWhen: [
  //                         {
  //                           question: "1.1",
  //                           operator: "=",
  //                           answer: 'yes'
  //                         }
  //                     ],
  //                     defaultValue:1
  //                 }
  //             ]
  //         },
  //         {
  //             link: '1.2',
  //             index:1,
  //             text: "Does physical Ecersice",
  //             type: 'choice',
  //             answerValueSet:[ "yes", 'no' ,'unknown' ],
  //             items: [
  //                 {
  //                     link:'1.2.1',
  //                     text: 'How many hours per week',
  //                     type: 'decimal',
  //                     enableWhen: [
  //                         {
  //                           question: "1.2",
  //                           operator: "=",
  //                           answer: 'yes'
  //                         }
  //                     ],
  //                     displayConverter:{ type:'time', from:'hour', to:'readable' }
  //                 },
  //             ]
  //         },
  //         {
  //             link: '1.3',
  //             index:1,
  //             text: "Smokes Tobacco",
  //             type: 'choice',
  //             answerValueSet:[ "yes", 'no' ,'unknown' ],
  //             items: [
  //                 {
  //                     link:'1.3.1',
  //                     text: 'How many sticks per day',
  //                     type: 'integer',
  //                     enableWhen: [
  //                         {
  //                           question: "1.3",
  //                           operator: "=",
  //                           answer: 'yes'
  //                         }
  //                     ],
  //                     displayConverter:{ type:'days', from:'day', to:'readable' }
  //                 },
  //             ]
  //         },
  //         {
  //             link: '1.4',
  //             index:1,
  //             text: "Healthy Diet (Fruits/Veg)",
  //             type: 'choice',
  //             answerValueSet:[ "yes", 'no' ,'unknown' ],
  //             items: [
  //                 {
  //                     link:'1.4.1',
  //                     text: 'How many servings per day',
  //                     type: 'integer',
  //                     enableWhen: [
  //                         {
  //                           question: "1.4",
  //                           operator: "=",
  //                           answer: 'yes'
  //                         }
  //                     ],
  //                     displayConverter:{ type:'days', from:'day', to:'readable' }
  //                 },
  //             ]
  //         },
  //         {
  //             link: '1.5',
  //             index:1,
  //             text: "Healthy Diet (Carb/Lipips)",
  //             type: 'choice',
  //             answerValueSet:[ "yes", 'no' ,'unknown' ],
  //             items: [
  //                 {
  //                     link:'1.5.1',
  //                     text: 'How many servings per day',
  //                     type: 'integer',
  //                     enableWhen: [
  //                         {
  //                           question: "1.5",
  //                           operator: "=",
  //                           answer: 'yes'
  //                         }
  //                     ],
  //                     displayConverter:{ type:'days', from:'day', to:'readable' }
  //                 },
  //             ]
  //         },
  //     ]
  // },


  _exports.default = _default;
});
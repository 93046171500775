define("hmis/models/department", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import EmberObject, { computed } from '@ember/object';
  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr() //wards: hasMany('ward', { inverse: 'department', async: false}),
    //departmentUnits: hasMany('department-unit', { inverse: 'department', async: false })

  });

  _exports.default = _default;
});
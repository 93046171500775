define("hmis/constants/visit-outcomes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VISIT_OUTCOME_OBJECTS = _exports.VISIT_OUTCOMES = void 0;
  const VISIT_OUTCOMES = {
    ABSCONDED: 2,
    DIED: 3,
    DISCHARGED: 4,
    DISCHARGED_AGAINST_MEDICAL_ADVICE: 5,
    TERMINATED: 6,
    //backend use only dont add to objects
    TRANSFERED_OUT: 7 //backend use only dont add to objects

  };
  _exports.VISIT_OUTCOMES = VISIT_OUTCOMES;
  const VISIT_OUTCOME_OBJECTS = [{
    title: "Absconded",
    id: VISIT_OUTCOMES.ABSCONDED
  }, {
    title: "Died",
    id: VISIT_OUTCOMES.DIED
  }, {
    title: "Discharged",
    id: VISIT_OUTCOMES.DISCHARGED
  }, {
    title: "Discharged againts medical advice",
    id: VISIT_OUTCOMES.DISCHARGED_AGAINST_MEDICAL_ADVICE
  }];
  _exports.VISIT_OUTCOME_OBJECTS = VISIT_OUTCOME_OBJECTS;
});
define("hmis/routes/hmis-app/transactions/index", ["exports", "hmis/mixins/route-error-handler"], function (_exports, _routeErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, {
    model(params) {
      var start = new Date();
      start.setHours(0, 0, 0, 0);
      var end = new Date();
      end.setHours(23, 59, 59, 999);
      return Ember.RSVP.hash({// BTModel:this.get('store').query('transaction', {
        //     filter: {
        //       isInsurance: 0,
        //       dateRange:{
        //           fromDate:start.toISOString().slice(0, 19).replace('T', ' '),
        //           toDate:end.toISOString().slice(0, 19).replace('T', ' ')
        //       }
        //     },
        // }),
        //
        // BVModel:this.get('store').query('visit', {
        //     filter: {
        //       dateRange:{
        //           fromDate:start.toISOString().slice(0, 19).replace('T', ' '),
        //           toDate:end.toISOString().slice(0, 19).replace('T', ' ')
        //       },
        //       hasBill:true
        //     },
        // }),
      });
    }

  });

  _exports.default = _default;
});
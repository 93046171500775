define("hmis/templates/components/controls/component-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8tYPmoJu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"full-width component-title \",[28,\"if\",[[24,[\"center\"]],\"center\",\"\"],null]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"component-title-container  \",[28,\"if\",[[24,[\"first\"]],\"first\",\"\"],null],\" \",[28,\"if\",[[24,[\"noMarginBottom\"]],\"no-margin-bottom\",\"\"],null]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"component-mark\"],[8],[9],[0,\"\\n        \"],[7,\"p\",true],[11,\"class\",[28,\"concat\",[\"comp-title \",[24,[\"color\"]],\"-color\"],null]],[8],[1,[22,\"title\"],false],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"comp-info\"],[8],[1,[22,\"info\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/component-title.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/departments/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),
    query: '',
    onConfirm: 'deleteRecord',
    actions: {
      newRecord() {
        let record = this.get('record');
        this.transitionToRoute("hmis-app.admin-settings.departments.add");
      },

      deleteRecord(record) {
        console.log(record);
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete a department: (' + record.get('name') + ')');
        this.set('onAlert', true);
      },

      deleteNow(record) {
        this.get("model").removeObject(record);
        record.destroyRecord();
      },

      saveRecord(record) {
        record.save();
      },

      editRecord(record) {// alert(record.id);
        //this.get("routing").transitionTo("hmis-app.admin-settings.departments.edit",[record.id]);
        //this.get("routing").transitionTo("hmis-app.admin-settings.departments.manage",[record.id]);
      },

      clickRecord(record) {
        //this.send("viewRecord",record)
        this.get("routing").transitionTo("hmis-app.admin-settings.departments.manage", [record.id]);
      },

      viewRecord(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.admin-settings.departments.view", [rid]);
      },

      deleteRecords() {
        this.get("selectedItems").forEach((user, index) => {
          user.destroyRecord();
        });
        this.set("selectedItems", []);
      },

      save() {
        //console.log(this.get('name'))
        let that = this;
        this.get('store').createRecord('department', {
          name: that.get('name')
        }).save().then(record => {
          that.notify.success('Saved Successfully');
        }).catch(failure => {
          that.notify.error('Unable to Save' + failure);
        });
      }

    }
  });

  _exports.default = _default;
});
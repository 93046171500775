define("hmis/templates/components/classic/c-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IOvNT1Ii",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[8],[1,[22,\"label\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"transLabel\"]]],null,{\"statements\":[[7,\"label\",true],[10,\"style\",\"color: transparent;\"],[8],[1,[22,\"transLabel\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"button\",true],[11,\"class\",[29,[\"m-input \",[22,\"buttonClass\"],\" \",[28,\"if\",[[24,[\"clear\"]],\"clear-gray-bg\",\"\"],null]]]],[11,\"disabled\",[22,\"disabled\"]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[11,\"type\",[22,\"type\"]],[8],[0,\"\\n    \"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[7,\"i\",true],[11,\"class\",[29,[\"fa fa-\",[22,\"icon\"]]]],[8],[9],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n    \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/classic/c-button.hbs"
    }
  });

  _exports.default = _default;
});
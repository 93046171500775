define("hmis/models/insurance-product", ["exports", "ember-data", "ember-data/relationships", "hmis/mixins/model-access-right"], function (_exports, _emberData, _relationships, _modelAccessRight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelAccessRight.default, {
    name: _emberData.default.attr(),
    insuranceCompany: (0, _relationships.belongsTo)('insurance-company'),
    medicines: _emberData.default.attr(),
    investigations: _emberData.default.hasMany('investigation-type'),
    procedures: _emberData.default.hasMany('procedure-type'),
    xrays: _emberData.default.hasMany('xray-type'),
    ultrasounds: _emberData.default.hasMany('ultrasound-type'),
    otherBillables: _emberData.default.hasMany('other-billable'),
    diagnoses: _emberData.default.hasMany('diagnosis-type'),
    insuranceCompanyName: _emberData.default.attr(),
    opdThreshold: _emberData.default.attr(),
    ipdThreshold: _emberData.default.attr(),
    opdInfinite: _emberData.default.attr(),
    ipdInfinite: _emberData.default.attr(),
    ajaxRequest: Ember.inject.service(),

    addBillable(billableName, billableItemId) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('ajaxRequest').makeRequest('POST', "/insuranceProductBillable/" + billableName + "/" + this.get('id'), {
          billableItem: billableItemId
        }, {
          success: response => {
            resolve(response);
          },
          error: error => {
            reject(error);
          }
        });
      });
    },

    removeBillable(billableName, billableItemId) {
      console.log(billableName, billableItemId);
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('ajaxRequest').makeRequest('DELETE', "/insuranceProductBillable/" + billableName + "/" + this.get('id'), {
          billableItem: billableItemId
        }, {
          success: response => {
            resolve(response);
          },
          error: error => {
            reject(error);
          }
        });
      });
    }

  });

  _exports.default = _default;
});
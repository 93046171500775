define("hmis/models/insurance-product-medicine", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    insuranceProduct: (0, _relationships.belongsTo)('insurance-product'),
    medicine: (0, _relationships.belongsTo)('medicine'),
    medicineName: Ember.computed('medicine.genericName', function () {
      return this.get('medicine.genericName');
    }),
    nhiaCode: Ember.computed('medicine.nhiaCode', function () {
      return this.get('medicine.nhiaCode');
    })
  });

  _exports.default = _default;
});
define("hmis/models/role", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    roleType: _emberData.default.attr(),
    roleOperationPermissions: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: this.get('store').query('role-operation-permission', {
          filter: {
            role: this.get('id')
          }
        }).then(recs => {
          return recs.toArray();
        })
      });
    }),
    roleModulePermissions: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: this.get('store').query('role-module-permission', {
          filter: {
            role: this.get('id')
          }
        }).then(recs => {
          return recs.toArray();
        })
      });
    })
  });

  _exports.default = _default;
});
define("hmis/components/viewers/reports/facility-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      visualize({
        auth: {
          name: "tradeuser",
          password: "pass_99word"
        }
      }, function (v) {
        //render report from provided resource
        v("#container_facilities").report({
          resource: "/CHAG/Reports/CHAG_facilities",
          scale: "container",
          params: {
            "facilityType": [""],
            "Region": [""]
          },
          error: handleError
        }); //show error

        function handleError(err) {
          alert(err.message);
        }
      });
    }

  });

  _exports.default = _default;
});
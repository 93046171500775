define("hmis/components/viewers/patient-report-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    queryParams: ['fromDate', 'toDate'],
    fromDate: '',
    //new Date(),
    toDate: '',

    //new Date(),
    init() {
      this._super(...arguments);

      let date = new Date(),
          y = date.getFullYear(),
          m = date.getMonth();
      let fd = new Date(y, m, 1);
      let td = new Date(y, m + 1, 0);
      this.set('fromDate', fd.toISOString().slice(0, 19).replace('T', ' '));
      this.set('toDate', td.toISOString().slice(0, 19).replace('T', ' ')); //this.visualizeReport(this.get('fromDate'),this.get('toDate'))

      console.log(fd + '-' + td);
      this.send('filterNow', fd, td);
    },

    actions: {
      back() {
        history.back();
      },

      onDateFilterSubmit(fromDate, toDate) {
        this.send('filterNow', fromDate, toDate);
        this.set('isToday', false);
        this.set('isThisMonth', false);
      },

      filterToday() {
        var fd = new Date();
        fd.setHours(0, 0, 0, 0);
        var td = new Date();
        td.setHours(24, 0, 0, 0);
        this.send('filterNow', fd, td);
        this.set('isToday', true);
        this.set('isThisMonth', false);
        this.set('dateFilterStatus', false);
      },

      filterThisMonth() {
        let date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        let fd = new Date(y, m, 1);
        let td = new Date(y, m + 1, 0);
        fd.setHours(0, 0, 0, 0);
        td.setHours(24, 0, 0, 0);
        this.send('filterNow', fd, td);
        this.set('isToday', false);
        this.set('isThisMonth', true);
        this.set('dateFilterStatus', false);
      },

      filterNow(fromDate, toDate) {
        this.set('fromDate', fromDate);
        this.set('toDate', toDate);
        let visit = this.get('visit');
        let that = this;
        let filter = {
          //visit:visit.get('id'),
          dateRange: {
            fromDate: fromDate.toISOString().slice(0, 19).replace('T', ' '),
            toDate: toDate.toISOString().slice(0, 19).replace('T', ' ')
          }
        };
        this.visualizeReport(fromDate.toISOString().slice(0, 19).replace('T', ' '), toDate.toISOString().slice(0, 19).replace('T', ' '));
      },

      queryForSummary(context, model, tableData, filter, then) {
        context.get('store').query(model, {
          filter: filter
        }).then(function (qp) {
          context.set(tableData, qp);
        });
      }

    },

    visualizeReport(fd, td) {
      visualize({
        auth: {
          name: "tradeuser",
          password: "pass_99word"
        }
      }, function (v) {
        //render report from provided resource
        v("#container").report({
          resource: "/CHAG/Reports/CHAG_patient_visit_History",
          scale: "container",
          params: {
            "date1": [fd],
            "date2": [td]
          },
          error: handleError
        }); //show error

        function handleError(err) {
          alert(err.message);
        }
      });
    }

  });

  _exports.default = _default;
});
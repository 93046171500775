define("hmis/templates/components/dashboard/main-butt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vtWQNgGF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-butt\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[29,[[22,\"image\"]]]],[10,\"class\",\"background-image\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[11,\"style\",[22,\"mbStyle\"]],[8],[0,\"\\n       \"],[7,\"p\",true],[10,\"class\",\"count\"],[8],[1,[22,\"count\"],false],[9],[0,\"\\n       \"],[7,\"p\",true],[10,\"class\",\"name\"],[8],[1,[22,\"text\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/dashboard/main-butt.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/patient/referrals", ["exports", "ember-data", "hmis/constants/referral-types", "hmis/utils/model-table-theme"], function (_exports, _emberData, _referralTypes, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import modelTableTheme from '../../../../../utils/model-table-theme';
  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    appAjax: Ember.inject.service(),

    hideOptions(refRecord) {
      return refRecord.typeId == _referralTypes.REFERRAL_TYPES.INTERNAL_DEPARTMENTAL;
    },

    actions: {
      optionClick(option, record) {
        this.send(option.action, record);
      },

      print(record) {
        this.set('waiting', true);
        this.appAjax.printFile('exportReferral/print/' + record.id).then(res => {
          this.set('waiting', false);
        }).catch(err => {
          this.set('waiting', false);
          this.notify.error(err);
        });
      },

      pdf(record) {
        this.set('waiting', true);
        this.appAjax.downloadFile('exportReferral/pdf/' + record.id).then(res => {
          this.set('waiting', false);
        }).catch(err => {
          this.set('waiting', false);
          this.notify.error(err);
        });
      },

      email(record) {
        this.set('waiting', true);
        this.appAjax.ajaxGet('exportReferral/email/' + record.id, {}).then(res => {
          this.set('waiting', false);
        }).catch(err => {
          this.set('waiting', false);
          this.notify.error(err);
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    rowOptions: [{
      label: "Print",
      inline: true,
      class: 'primary-outline',
      action: "print" // hideWhen:( record, value )=>{
      //   return record.typeId == REFERRAL_TYPES.INTERNAL_DEPARTMENTAL;
      // }

    }, {
      label: "Download pdf",
      action: "pdf"
    }, {
      label: "Email to patient",
      action: "email"
    }],
    columns: [{
      title: 'Date',
      "propertyName": "dateF"
    }, {
      title: 'Note',
      "propertyName": "note",
      component: 'controls/table-long-text'
    }, {
      "title": "",
      "component": "manageRow"
    }]
  });

  _exports.default = _default;
});
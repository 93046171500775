define("hmis/components/pickers/table-picker-alert", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedItems: [],
    tagName: '',
    title: 'Pick a Record',
    actions: {
      onRowClick(index, dataItem) {},

      onRowDoubleClick(index, dataItem) {
        if (!this.multiSelect) {
          this.set('selectedItems', [dataItem]);
          this.send('chooseConfirm');
        }
      },

      chooseConfirm() {
        let record_S = this.multiSelect ? this.get('selectedItems') : this.get('selectedItems').get('firstObject');
        let action = this.get('onConfirm');
        if (action) action(record_S);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    })
  });

  _exports.default = _default;
});
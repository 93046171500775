define("hmis/controllers/hmis-app/opd/consultation/consult/symptoms", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller", "hmis/mixins/visit-care-controller"], function (_exports, _modelTableTheme, _formController, _visitCareController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, _visitCareController.default, {
    store: Ember.inject.service('store'),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    currentUser: Ember.inject.service(),
    app: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    tabStatus: Ember.computed('oPerms', function () {
      return this.get('oPerms.op19.add') ? 0 : 1;
    }),
    // model:{},
    // optionTypes:computed('model',function(){
    //   return this.get('store').findAll('symptom-type'
    //   , {})
    //     .then(function(recs) {
    //         return recs;
    //     })
    // }),
    // optionTypes:computed('model',function(){
    //   return this.get('store').findAll('symptom-type'
    //   , {})
    //     .then(function(recs) {
    //         return recs;
    //     })
    // }),
    batchItems: {},
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      onNewTabClick(route) {
        this.set('tabStatus', 0);
      },

      onHistoryTabClick(route) {
        this.set('tabStatus', 1);
      },

      clickRecord() {
        console.log('');
      },

      addRecord() {
        this.get('app').startLoading();
        let value = this.get('note');
        let valueType = this.get('symptomType');

        if (!valueType) {
          this.notify.error('Please select a symptom type');
          return;
        }

        this.set('saving', true);
        this.get('store').createRecord('symptom', {
          note: value,
          symptomType: valueType,
          visitId: this.model.visitId
        }).save().then(rec => {
          this.get('app').stopLoading();
          this.set('note', '');
          this.send('refreshModel');
        }, error => {
          this.get('app').stopLoading();
          this.notify.error(error);
          this.send('refreshModel');
        }).finally(() => {
          this.set('saving', false);
        });
      },

      deleteSymptom(record) {
        record.destroyRecord();
      },

      rowClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        this.set('selectedBatch', record);
        this.set('showRecord', true);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "title": "Symptoms",
      "propertyName": "symptomTypeName"
    }, {
      'title': 'Date',
      "propertyName": "dateF",
      "editable": false
    }, {
      "title": "Symptoms",
      "propertyName": "symptomTypeName"
    }]
  });

  _exports.default = _default;
});
define("hmis/components/file-chooser-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: Ember.computed('type', function () {
      let type = this.get('type');

      if (type == 'image') {
        return 'Select an Image file';
      } else {
        return "Select a file";
      }
    }),
    confirmText: 'Ok',
    confirmDisabled: Ember.computed('filePath', function () {
      return this.get('fileList.length') || this.get('selectedFile') ? false : true;
    }),
    isImage: Ember.computed('selectedFile', function () {
      return this.get('selectedFile.blob.type') == 'image/jpeg';
    }),
    label: "Select Image",
    buttonText: "Choose image",
    imageName: "no image selected",
    imagePath: '',
    accepts: "*",
    fileList: [],
    selectedFile: null,
    actions: {
      uploadImage(file) {
        if (!this.get('multiple')) {
          this.set('selectedFile', file);
          file.readAsDataURL().then(url => {
            this.set('filePath', url);
            console.log('file', file);
          });
        } else {
          fileList.pushObject(file);
        }
      },

      onConfirm() {
        let action = this.get('onConfirm');

        if (action) {
          action(this.get('multiple') ? this.get('fileList') : this.get('selectedFile'));
        }

        this.set('filePath', null);
        this.set('fileList', []);
        this.set('selectedFile', null);
      },

      onCancel() {
        this.set('filePath', null);
        this.set('fileList', []);
        this.set('selectedFile', null);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/configure/finish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajaxRequest: Ember.inject.service(),
    session: Ember.inject.service(),
    notify: Ember.inject.service(),
    show: false,
    actions: {
      back() {
        history.back();
      },

      save() {
        this.get('ajaxRequest').endFacilitySetup({
          success: response => {
            this.set('show', true);
            this.set('msg', response);
            this.notify.success('Saved Successfully');
          },
          error: error => {
            //this.set('sendingMail',false)
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      logout() {
        this.get('session').invalidate();
      }

    }
  });

  _exports.default = _default;
});
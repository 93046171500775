define("hmis/components/form-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: 'layout-row layout-wrap layout-align-space-between'
  });

  _exports.default = _default;
});
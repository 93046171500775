define("hmis/routes/hmis-app/admin-settings/facility-vitals/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        units: this.get('store').findAll('vitals-unit-of-measure'),
        vitaltypes: this.get('store').findAll('vital-type'),
        facilityvitals: this.get('store').findAll('vitals-facility-default')
      });
    },

    actions: {
      reloadModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/helpers/time-difference", ["exports", "hmis/utils/date-util"], function (_exports, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.timeDifference = timeDifference;
  _exports.default = void 0;

  function timeDifference([a, b]) {
    return _dateUtil.default.stringTimeDifference(a, b);
  }

  var _default = Ember.Helper.helper(timeDifference);

  _exports.default = _default;
});
define("hmis/templates/components/pickers/staff-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gEtX/+Pp",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[1,[28,\"pickers/input-picker\",null,[[\"data\",\"columns\",\"selected\",\"inputClass\",\"textField\",\"selectedChange\",\"class\",\"label\",\"placeholder\",\"disabled\"],[[24,[\"appData\",\"doctors\"]],[24,[\"columns\"]],[24,[\"selected\"]],[24,[\"inputClass\"]],\"fullName\",[24,[\"selectedChange\"]],[24,[\"class\"]],[24,[\"label\"]],[24,[\"placeholder\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/pickers/staff-input.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/helpers/relative-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function relativeEditable([patientId, relationObject]
  /*, hash*/
  ) {
    return patientId == relationObject.patientId;
  });

  _exports.default = _default;
});
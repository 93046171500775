define("hmis/templates/components/controls/image-chooser-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "saR2Mv2a",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"choose-image \",[22,\"input_width\"]]]],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"class\",\"choose-image-image\"],[11,\"src\",[22,\"imagePath\"]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"choose-image-content\"],[8],[0,\"\\n        \"],[7,\"p\",true],[10,\"class\",\"title\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[0,\"        \"],[7,\"label\",true],[10,\"class\",\"custom-file-upload gray-button\"],[10,\"style\",\"\"],[8],[0,\"\\n            \"],[7,\"input\",true],[10,\"style\",\"display:none;\"],[11,\"accept\",[22,\"accept\"]],[11,\"onchange\",[28,\"action\",[[23,0,[]],\"readURL\"],null]],[10,\"type\",\"file\"],[8],[9],[0,\"\\n            Choose file\\n        \"],[9],[0,\"\\n        \"],[7,\"p\",true],[10,\"style\",\"margin-top:2px; font-size: 10px;\"],[8],[1,[22,\"imageName\"],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/image-chooser-sm.hbs"
    }
  });

  _exports.default = _default;
});
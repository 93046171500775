define("hmis/controllers/hmis-app/admin-settings/facility-vitals/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    //routing: Ember.inject.service('-routing'),
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      save() {
        let that = this;
        let model = this.get('model'); //this.set('model', this.get('store').createRecord('vitals-facility-defaults', model))

        model.save().then(record => {
          that.notify.success('Saved Successfully');
          this.transitionToRoute("hmis-app.admin-settings.facility-vitals");
        }).catch(failure => {
          that.notify.error('Unable to Save' + failure);
        });
      },

      deleteRecords() {
        this.get("selectedItems").forEach((user, index) => {
          user.destroyRecord();
        });
        this.set("selectedItems", []);
      },

      newRecord() {
        let record = this.get('record');
        this.transitionToRoute("hmis-app.admin-settings.investigation-type.add");
      },

      addInv(inv) {
        //console.log(this.get('facility.id'))
        //console.log(inv.unitOfMeasure)
        //let valsel = {facility: this.get('facility.id'), vitalType: inv.id}
        //console.log(valsel)
        this.get("selectedInv").pushObject(inv); //console.log(this.selectedInv)
      },

      removeInv(fInsurance) {
        this.get("removedInv").pushObject(fInsurance);
        this.get("selectedInv").removeObject(fInsurance);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/dashboard/main-butt-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1or02pvo",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\" \\n\"],[7,\"div\",true],[10,\"class\",\"main-butt-container\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/dashboard/main-butt-container.hbs"
    }
  });

  _exports.default = _default;
});
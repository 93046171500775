define("hmis/models/transaction", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/utils/currency-util", "hmis/helpers/report-count"], function (_exports, _emberData, _relationships, _dateUtil, _currencyUtil, _reportCount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function copyFromJSObject(target, source, prop) {
    console.log(prop, source[prop]);
    target.set(prop, source[prop]);
  }

  var _default = _emberData.default.Model.extend({
    visit: (0, _relationships.belongsTo)('visit', {
      async: false
    }),
    trasactionType: _emberData.default.attr(),
    billableType: _emberData.default.attr(),
    billableItemName: _emberData.default.attr(),
    amount: _emberData.default.attr(),
    price: _emberData.default.attr(),
    quantity: _emberData.default.attr(),
    isPaid: _emberData.default.attr(),
    isInsurance: _emberData.default.attr(),
    patientMopId: _emberData.default.attr(),
    patientMopName: _emberData.default.attr(),
    patientName: _emberData.default.attr(),
    patientMopAccountNumber: _emberData.default.attr(),
    mopId: _emberData.default.attr(),
    mopName: _emberData.default.attr(),
    paymentDate: _emberData.default.attr(),
    note: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    updatedAt: _emberData.default.attr(),
    claimSubmitted: _emberData.default.attr(),

    copyFromJSObject(trans) {
      copyFromJSObject(this, trans, 'isPaid');
      copyFromJSObject(this, trans, 'isInsurance');
      copyFromJSObject(this, trans, 'patientMopId');
      copyFromJSObject(this, trans, 'patientMopName');
      copyFromJSObject(this, trans, 'patientMopAccountNumber');
      copyFromJSObject(this, trans, 'mopId');
      copyFromJSObject(this, trans, 'paymentDate');
      copyFromJSObject(this, trans, 'mopName');
    },

    amountF: Ember.computed('amount', function () {
      return (0, _reportCount.reportCount)([this.get('amount'), 'amount']);
    }),
    dateF: Ember.computed.alias('dateTimeF'),
    dateTimeF: Ember.computed('createdAt', function () {
      return _dateUtil.default.formatToDateTimeString(this.get('createdAt'));
    }),
    paymentStatus: Ember.computed('isPaid', function () {
      return this.get('isPaid') ? 'Paid' : 'Unpaid';
    }),
    billableSummary: Ember.computed('billableType', 'billableItemName', function () {
      return this.get('billableType') + ' charge: ' + this.get('billableItemName');
    }),
    submittedStatus: Ember.computed('claimSubmitted', function () {
      return this.get('claimSubmitted') ? 'Yes' : 'No';
    }),
    nAmount: Ember.computed('amount', function () {
      return _currencyUtil.default.formatMoney(this.get('amount'));
    })
  });

  _exports.default = _default;
});
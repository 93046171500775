define("hmis/components/managers/insurance-product-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flex_level: 'flex-45 flex-xs-100 flex-sm-100',
    store: Ember.inject.service('store'),
    insuranceCompanies: Ember.computed('model', function () {
      // console.log(this.get('record.id'))
      //   return this.get('store').query('insurance-company', { filter: { id: this.get('record.id') } } )
      //     .then(function(recs) {
      //       //console.log('rec',recs.serialize())
      //       return recs;
      //   })
      // console.log(this.get('record').toJSON())
      //this.set('insuranceCompany',this.get('record'))
      return this.get('record');
    }),
    medicines: Ember.computed('record', function () {
      return this.get('store').findAll('medicine');
    }),
    addedMedicines: [],
    actions: {
      addMedicine() {
        let medicine = this.get('medicine');
        console.log('medicine: ', medicine);

        if (!medicine) {
          alert('Select a medicine first');
          return;
        }

        let record = this.get('record');
        let medicines = this.get('addedMedicines');

        if (!medicines) {
          if (this.get('type') != 'edit') this.set('addedMedicines', []);
          medicines = this.get('addedMedicines');
        }

        let pMed = this.get('store').createRecord('insurance-product-medicine', {
          insuranceProduct: record,
          medicine: medicine
        });
        medicines.pushObject(pMed);
        console.log(medicines);
      },

      deleteMedicine(medicine) {
        let medicines = this.get('addedMedicines');
        medicine.destroyRecord();
        medicines.removeObject(medicine);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/circle-progress-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6IBU4H+F",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"circle-progress-of stats-item\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"progress\"],[8],[0,\"\\n        \"],[1,[28,\"ember-progress-bar\",null,[[\"shape\",\"progress\",\"options\"],[\"Circle\",[24,[\"progress\"]],[28,\"hash\",null,[[\"color\",\"trailColor\",\"strokeWidth\",\"trailWidth\",\"easing\",\"duration\",\"text\",\"step\"],[[24,[\"color\"]],[24,[\"options\",\"trailColor\"]],[24,[\"options\",\"strokeWidth\"]],[24,[\"options\",\"trailWidth\"]],[24,[\"options\",\"easing\"]],[24,[\"options\",\"duration\"]],[24,[\"options\",\"text\"]],[24,[\"options\",\"step\"]]]]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-container\"],[8],[0,\"\\n            \"],[7,\"p\",true],[10,\"class\",\"text\"],[8],[1,[22,\"text\"],false],[9],[0,\"\\n            \"],[7,\"p\",true],[10,\"class\",\"info\"],[8],[1,[22,\"info\"],false],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/circle-progress-of.hbs"
    }
  });

  _exports.default = _default;
});
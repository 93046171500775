define("hmis/helpers/show-hmis-side-menu-item", ["exports", "hmis/utils/is-perm-true-util"], function (_exports, _isPermTrueUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function showHmisSideMenuItem([currentUser, module]
  /*, hash*/
  ) {
    // console.log('module', module);
    // console.log('currentUser.modularPermissions', currentUser.modularPermissions);
    if (module.perm == "alwaysAllow") return true;

    if (currentUser.isSetup) {
      return true;
    }

    if (!module.levels) {
      console.error(module.name + " has no levels property");
    }

    if (!module.levels.includes(currentUser.accessLevel)) {
      return false;
    }

    if (!module.hasChildren) {
      return currentUser.modularPermissions[module.perm] && (0, _isPermTrueUtil.default)(currentUser.modularPermissions[module.perm].allow);
    } else {
      let p = false;
      module.childMenus.forEach(child => {
        if (currentUser.modularPermissions[child.perm] && (0, _isPermTrueUtil.default)(currentUser.modularPermissions[child.perm].allow)) p = true;
      });
      return p;
    }
  });

  _exports.default = _default;
});
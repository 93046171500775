define("hmis/mixins/batch-model-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);
    },

    actions: {}
  });

  _exports.default = _default;
});
define("hmis/components/controls/list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    //class="list-item  {{if (or viewMode editMode) 'edit-mode' ''
    inEditMode: Ember.computed('editMode', 'viewMode', function () {
      if (this.get('editMode'), this.get('viewMode')) return false;
    }),
    hideView: true,
    viewText: 'View',
    editText: 'Edit',
    deleteText: 'Delete',
    actions: {
      preventBubble(event) {//   console.log(event.target)
        // if($(event.target).is("div")) {
        // event.preventDefault()
        // event.stopPropagation();
        // }
      },

      onCheck(event) {
        if (!this.get('disabled')) {
          if (this.get('onCheck')) {
            this.get('onCheck')();
          } else {
            if (this.get('onItemClick')) this.get('onItemClick')(this.get('record'));
          }
        }
      },

      onClick(event) {
        if (!this.get('disabled')) {
          if (this.get('onItemClick')) this.get('onItemClick')(this.get('record'));
          if (this.viewable) this.set('viewMode', true);
        }
      },

      onView(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.onView) this.onView(this.record);else this.set('viewMode', true);
      },

      onEdit(event) {
        if (event) {
          event.preventDefault();
          event.stopPropagation();
        }

        if (this.get('onEdit')) this.get('onEdit')(this.get('record'));
        if (this.internalEdit) this.set('editMode', true);
      },

      onDelete(event) {
        event.preventDefault();
        event.stopPropagation();
        if (this.get('onDelete')) this.get('onDelete')(this.get('record'));
      },

      onUnFocusClick() {
        event.preventDefault();
        event.stopPropagation();
        let action = this.get('onUnFocusClick');
        if (action) action();
      },

      onSave() {
        // console.log('save save')
        event.preventDefault();
        event.stopPropagation();
        let action = this.get('onSave');
        if (action) action(this.get('record'));
      },

      onCancel() {
        // console.log('save save')
        event.preventDefault();
        event.stopPropagation();
        if (this.onCancel) this.onCancel(this.get('record'));
      }

    }
  });

  _exports.default = _default;
});
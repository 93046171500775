define("hmis/components/stack-pages/department-ward-rooms", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    notify: Ember.inject.service(),
    store: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    newRecord: {},
    onConfirm: 'deleteRecord',
    waitingRoomList: false,
    actions: {
      deleteRecord(record) {
        console.log(record);
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete: ' + record.get('name'));
        this.set('onAlert', true);
      },

      deleteRoomRecord(record) {
        console.log(record);
        this.set('onConfirm', 'deleteRoomNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete: ' + record.get('name'));
        this.set('onAlert', true);
      },

      deleteNow(record) {
        console.log(record); //let that = this
        //that.get("model").removeObject(record);

        record.destroyRecord();
      },

      deleteRoomNow(record) {
        this.get("selectedRoom.beds").removeObject(record);
        record.destroyRecord();
      },

      // deleteRecord (record) {
      //   record.destroyRecord();
      // },
      saveRecord(record) {
        record.save();
      },

      saveNewRecord() {
        this.set('listWait', true);
        let data = this.get('newRecord');
        let ward = this.get('props.ward');
        data.ward = ward;
        let newRecord = this.get('store').createRecord('room', data);
        newRecord.save().then(record => {
          this.set('listWait', false);
          ward.get('rooms').pushObject(record);
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
        }, failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error('Unable to Save');
        });
      },

      clickRecord(record) {
        let roomSel = this.get('store').findRecord('room', record.id);
        this.set('selectedRoom', roomSel);
        this.set('bedsModal', true);
      },

      addBed() {
        let model = this.get('model');
        let store = this.get('store');
        let that = this;
        let nbeds = this.get('numBeds') || 1;
        this.set('waitingRoomList', true);

        if (nbeds > 1) {
          this.get('ajaxRequest').addBeds(this.get('bedname'), this.get('selectedRoom'), nbeds, {
            success: beds => {
              this.set('waitingRoomList', false);
              that.notify.success('Saved Successfully');
              this.set('bedname', '');
              this.set('numBeds', 1);
            },
            error: err => {
              this.set('waitingRoomList', false);
              that.notify.error(err);
            }
          }); // for(var i = 0; i < nbeds; ++i){
          //   let post = store.createRecord('bed', {
          //     name: this.get('bedname')+i,
          //     room: this.get('selectedRoom')
          //   });
          //   post.save().then((record)=>{
          //     //that.notify.success('Saved Successfully')
          //   })
          //     .catch((failure)=>{
          //       that.notify.error('Unable to Save'+ failure)
          //     });
          // }
          // this.set('bedname', '')
          // that.notify.success('Saved Successfully')
        } else {
          let post = store.createRecord('bed', {
            name: this.get('bedname'),
            room: this.get('selectedRoom')
          });
          post.save().then(record => {
            this.set('waitingRoomList', false);
            this.set('bedname', '');
            that.notify.success('Saved Successfully');
          }).catch(failure => {
            this.set('waitingRoomList', false);
            that.notify.error('Unable to Save' + failure);
          });
        }
      }

    }
  });

  _exports.default = _default;
});
define("hmis/models/visit-procedure", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    procedureType: (0, _relationships.belongsTo)('procedure'),
    batch: (0, _relationships.belongsTo)('procedure-batch'),
    note: _emberData.default.attr(),
    procedureTypeName: Ember.computed('procedureTypeName', function () {
      // return `${this.get('firstName')} ${this.get('lastName')}`;
      return this.get('procedureTypeName');
    })
  });

  _exports.default = _default;
});
define("hmis/templates/components/forms/service-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8RucL+QH",
    "block": "{\"symbols\":[\"item\",\"item\",\"item\"],\"statements\":[[4,\"alerts/form-dialog\",null,[[\"title\",\"waiting\",\"departmentVal\",\"show\",\"width\",\"height\",\"onConfirm\",\"onCancel\"],[[24,[\"alertTitle\"]],[24,[\"waiting\"]],[24,[\"departmentVal\"]],[24,[\"show\"]],\"60%\",\"50%\",[28,\"action\",[[23,0,[]],\"onRecordSubmit\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null]]],{\"statements\":[[0,\"    \"],[4,\"app-select\",null,[[\"class\",\"searchField\",\"searchEnabled\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"input_width\"]],\"name\",true,\"Service\",[24,[\"record\",\"serviceType\"]],[24,[\"serviceType\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"serviceType\"]]],null]],null]]],{\"statements\":[[0,\" \"],[1,[23,3,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\n    \"],[4,\"app-select\",null,[[\"class\",\"searchField\",\"searchEnabled\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"input_width\"]],\"name\",true,\"Department\",[24,[\"departmentVal\"]],[24,[\"department\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"departmentVal\"]]],null]],null]]],{\"statements\":[[0,\" \"],[1,[23,2,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[2]},null],[0,\"\\n    \"],[4,\"app-select\",null,[[\"class\",\"searchField\",\"searchEnabled\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"input_width\"]],\"name\",true,\"Department Unit\",[24,[\"record\",\"departmentUnit\"]],[24,[\"departmentUnit\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"departmentUnit\"]]],null]],null]]],{\"statements\":[[0,\" \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/forms/service-alert.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/helpers/format-money", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.formatMoney = formatMoney;
  _exports.default = void 0;

  // import currencyUtil from '../utils/currency-util';
  function formatMoney([amount, decimalCount = 2, decimal = ".", thousands = ","]) {
    return currencyUtil.formatMoney(amount, decimalCount, decimal, thousands);
  }

  var _default = Ember.Helper.helper(formatMoney);

  _exports.default = _default;
});
define("hmis/components/pickers/list-picker", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    title: 'Select items',
    confirmText: 'Choose',
    cancelText: 'Close',
    // closeOnConfirm:true,
    width: '45%',
    height: '75%',
    multiSelect: true,
    selectedItems: [],
    tagName: '',
    filterParams: [],
    actions: {
      onConfirm() {
        let action = this.get('onConfirm');

        if (action) {
          action(this.get('selectedItems'));
        }
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    })
  });

  _exports.default = _default;
});
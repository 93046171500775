define("hmis/controllers/hmis-app/admin-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('router'),
    currentUser: Ember.inject.service(),
    accessLevel: Ember.computed('currentUser.accessLevel', function () {
      return this.get('currentUser.accessLevel');
    }),
    title: 'Facility types',
    actions: {
      onMenuClick(route, title) {
        try {
          this.get("routing").transitionTo(route);
          this.set('title', title);
        } catch (err) {
          console.log(err);
        }
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/forms/payment-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    modelName: 'facility-payment-mode',
    paymentMode: Ember.computed('model', function () {
      return this.get('store').query('payment-mode', {
        include: ['paymentModeTypeName']
      });
    }),
    actions: {
      getDepartmentUnit(item) {//this.set('department', item);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/opd/consultation/consult/events", ["exports", "hmis/utils/patient-serve-util", "hmis/mixins/route-error-handler", "hmis/utils/general-util", "hmis/mixins/visit-care-route"], function (_exports, _patientServeUtil, _routeErrorHandler, _generalUtil, _visitCareRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, _visitCareRoute.default, {
    parentCareRoute: 'hmis-app.opd.consultation.consult',

    model() {
      let model = this.modelFor("hmis-app.opd.consultation.consult");
      let newModel = (0, _patientServeUtil.cloneServeModel)(model);
      newModel['data'] = this.get('store').query('visit-event', {
        filter: {
          patientId: model.get('patientId')
        },
        include: ['episodesCount', 'visitsCount', 'primaryDiagnosisTypeName']
      });
      return (0, _generalUtil.modelHash)(newModel);
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/services/simple-jsons", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    session: Ember.inject.service(),
    facilities: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('ajaxRequest.makeAuthorizedRequest')(this, 'GET', "/simpleJSONs/all_facilities", null, {
            success: response => {
              resolve(response);
            },
            error: error => {
              reject(err);
            }
          });
        })
      });
    }),
    facilityDoctors: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('ajaxRequest.makeAuthorizedRequest')(this, 'GET', "/simpleJSONs/facility_doctors", null, {
            success: response => {
              resolve(response);
            },
            error: error => {
              reject(err);
            }
          });
        })
      });
    })
  });

  _exports.default = _default;
});
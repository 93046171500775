define("hmis/routes/hmis-app/users/user/accreditation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      // return this.get('store').query('user-accreditation',{ 
      //   include:['accreditionTypeName','accreditationInstitutionName'],
      //   pageSize: 25
      // })
      let mod = this.modelFor("hmis-app.users.user");
      return Ember.RSVP.hash({
        userAccreditation: this.get('store').query('user-accreditation', {
          filter: {
            userId: mod.get('id')
          },
          include: ['accreditionTypeName', 'accreditationInstitutionName'],
          pageSize: 25
        }),
        userId: mod
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
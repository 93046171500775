define("hmis/templates/hmis-app/admin-settings/generic/type-record/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tB6kVx9L",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onItemClick\",\"icon\"],[\"Cancel\",[28,\"action\",[[23,0,[]],\"back\"],null],\"close\"]]],false],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onItemClick\",\"icon\"],[\"Update\",[28,\"action\",[[23,0,[]],\"save\"],null],\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-full-container\",null,[[\"class\",\"title\"],[\"full-height\",[24,[\"model\",\"record_type\"]]]],{\"statements\":[[0,\"        \"],[1,[28,\"type-manager\",null,[[\"record\",\"type\",\"class\"],[[24,[\"model\"]],\"edit\",\"full-width\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/admin-settings/generic/type-record/add.hbs"
    }
  });

  _exports.default = _default;
});
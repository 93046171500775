define("hmis/templates/components/tabs-container-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wc1Z+p4V",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"parentTab\",\"tabIndex\"]],[24,[\"index\"]]],null]],null,{\"statements\":[[4,\"if\",[[24,[\"pageError\"]]],null,{\"statements\":[[0,\"        \"],[5,\"error-viewer\",[],[[\"@errorResult\"],[[22,\"pageError\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"controls/overlay-wait\",null,[[\"waiting\"],[[24,[\"waiting\"]]]],{\"statements\":[[0,\"            \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"    \\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/tabs-container-page.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/alerts/form-dialog", ["exports", "hmis/mixins/ref-component"], function (_exports, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    notify: Ember.inject.service(),
    tagName: '',
    isConfirm: true,
    title: 'Fill the form',
    message: '',
    closeOnConfirm: false,
    confirmText: 'Save',
    cancelText: 'Cancel',
    footerInfo: '',
    contentPadding: true,

    validateForm(onValidSubmit, onInvalidSubmit = null) {
      this.set('show', true);
      this.set('onConfirm', onValidSubmit);
      this.set('onInvalid', onInvalidSubmit);
    },

    close() {
      this.set('show', false);
    },

    actions: {
      onConfirm() {
        this.get('submitForm')();
      },

      onValidFormSubmit() {
        this.onConfirm && this.onConfirm();
      },

      onInvalid() {
        if (this.onInvalid) this.onInvalid();
        this.get('notify').error('Form has error fields');
      },

      onCancel() {
        this.get('resetForm')();
        if (this.get('onCancel')) this.get('onCancel')();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/disabled-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SxWHf4d0",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"disabled\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"no-data-message\",null,[[\"message\"],[[24,[\"message\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/disabled-view.hbs"
    }
  });

  _exports.default = _default;
});
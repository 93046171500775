define("hmis/components/no-data-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    message: 'No data found',
    tagName: '',
    size: 'medium',
    csize: Ember.computed('size', function () {
      if (this.get('size') == 'small') {
        return {
          width: "30px",
          height: "30px"
        };
      }

      return {
        width: "60px",
        height: "60px"
      };
    })
  });

  _exports.default = _default;
});
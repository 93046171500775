define("hmis/components/controls/timeline-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      if (!this.get('indexOnEdit')) {
        this.set('indexOnEdit', -1);
      }
    },

    noRecordsMessage: 'No Records available',
    allowRollBack: true,
    indexOnEdit: -1,
    editable: true,
    multiSelect: true,
    actions: {
      unFocusClick() {
        let action = this.get('cancelAction');
        if (action) action(this.get('itemOnEdit'), this.get('indexOnEdit'));
        this.set('indexOnEdit', -1);
      },

      onAddNew(item, index) {
        let action = this.get('addNewAction');
        if (action) action(item, index);
      },

      onRemove(item, index) {
        let action = this.get('removeAction');
        if (action) action(item, index);
      },

      onItemSave(item) {
        this.onItemSave && this.onItemSave(item);
      },

      onItemClick(item, index) {
        if (this.get('selectable')) {
          let sItems = this.get('selectedItems');

          if (sItems.includes(item)) {
            sItems.removeObject(item);
          } else {
            if (!this.get('multiSelect')) {
              this.set('selectedItems', [item]);
            } else {
              sItems.pushObject(item);
            }
          }
        }

        let action = this.get('itemClickAction');
        if (action) action(item, index);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/patient/medical-info", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller", "ember-data", "hmis/mixins/page-error-handler"], function (_exports, _modelTableTheme, _formController, _emberData, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, _pageErrorHandler.default, {
    notify: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    selectedItems: [],
    allowSelect: false,
    //data: Ember.computed.alias('model'),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    selectedCount: Ember.computed("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    data: Ember.computed(function () {
      return this.get('store').findAll('allergy-type');
    }),
    isToday: true,
    fromDate: new Date(),
    toDate: new Date(),
    tabIndex: 0,
    selectedPatient: '',
    vitalsSuccessAction: 'empty',
    onConfirm: 'deleteRecord',
    visit: null,

    init() {
      this._super(...arguments); //this.set('visit', this.get('model.patient.id'))
      //this.send('filterAllDays')

    },

    actions: {
      back() {
        history.back();
      },

      showVisitSummary(record) {//console.log('this.get',record)
        //this.showVisitSummary(this.get('model.visit'));
      },

      diagnosisClick(record) {
        this.visitViewer.show({
          id: record.visitId
        });
      },

      rowDoubleClick(record) {
        console.log('this.get', record);
      },

      addAllergy() {
        this.set('editAllergy', {
          patient: this.get('model.patient')
        });
        this.set('allergyDialog', true);
      },

      editAllergy(record) {
        this.set('editAllergy', record);
        this.set('allergyDialog', true);
      },

      addImmunization() {
        this.set('editImmunization', {
          patient: this.get('model.patient')
        });
        this.set('immunizationDialog', true);
      },

      editImmunization(record) {
        record.set('date', moment(record.date).format('YYYY-MM-DD'));
        this.set('editImmunization', record);
        this.set('immunizationDialog', true);
      },

      addChronic() {
        this.set('editChronic', {
          patient: this.get('model.patient')
        });
        this.set('chronicDialog', true);
      },

      editChronic(record) {
        console.log('record det', record);
        this.set('editChronic', record);
        this.set('chronicDialog', true);
      },

      onSaved(record) {
        this.send('refreshModel');
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record); //this.set('alertTitle','Attempting to archive allergy ('+ record.get('name')+ '-' +record.get('type.name')+')')

        this.set('alertTitle', 'Attempting to archive item');
        this.set('onAlert', true);
      },

      deleteNow(recordTag) {
        this.get("selectedItems").removeObject(recordTag);
        recordTag.destroyRecord(); //reload stats data

        setTimeout(this.get('ajaxRequest').makeRequest('POST', "/dashboardStatistics/day", {}, {
          success: response => {
            this.set('app.dashboardStats', response);
            console.log('archive donfirmed: data auto load');
          },
          error: error => {
            console.log('data auto load error');
          }
        }), 10000);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    patientAllergyColumns: [{
      'title': 'Allergy',
      "propertyName": "name",
      'className': 'main-column'
    }, {
      'title': 'Type',
      "propertyName": "type.name",
      'className': 'main-column'
    }, {
      'title': 'IDC Diagnosis',
      "propertyName": "idcDiagnosis",
      'className': 'main-column'
    }, {
      'title': 'Note',
      "propertyName": "note",
      'className': 'main-column'
    }, {
      "title": "Actions",
      "component": "manageRow",
      'className': 'main-column'
    }],
    patientImmunizationColumns: [{
      'title': 'Immunization',
      "propertyName": "typeName",
      'className': 'main-column'
    }, {
      'title': 'Vaccine',
      "propertyName": "vaccine",
      'className': 'main-column'
    }, {
      "propertyName": "status",
      'className': 'main-column'
    }, {
      'title': 'Date',
      "propertyName": "reqDateTimeF",
      'className': 'main-column'
    }, {
      "title": "Actions",
      "component": "manageRow",
      'className': 'main-column'
    }],
    patientChronicColumns: [{
      'title': 'Disease',
      "propertyName": "diagnosisSubTypeIcdName",
      'className': 'main-column'
    }, {
      'title': 'Group',
      "propertyName": "name",
      'className': 'main-column'
    }, {
      'title': 'Major  Group',
      "propertyName": "typeName",
      'className': 'main-column'
    }, {
      "propertyName": "note",
      'className': 'main-column'
    }, {
      "title": "Actions",
      "component": "manageRow",
      'className': 'main-column'
    }],
    diagnosesColumn: [{
      title: 'Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Diagnoses',
      propertyName: 'diagnosisTypeName',
      className: "vsv-summary-string-col"
    }, {
      title: 'Visit',
      component: 'tableLink',

      formatText(text, record) {
        return record.visitDateF + " : " + record.visitPurpose;
      }

    }],
    prescriptionsColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Prescriptions',
      propertyName: 'prescriptionsString',
      className: "vsv-summary-string-col"
    }],
    proceduresColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Procedure',
      propertyName: 'procedureTypeName',
      className: "vsv-item-col"
    }, {
      title: 'Documents',
      propertyName: 'documentsString',
      className: "vsv-summary-string-col"
    }],
    investigationsColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Investigations',
      propertyName: 'investigationsString',
      className: "vsv-summary-string-col"
    }],
    columns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false,
      "className": 'allergy-checkbox-width'
    }, {
      "propertyName": "name",
      'className': 'main-column'
    }, {
      title: 'Details',
      "component": "controls/allergy-input-text"
    } // {
    //   "propertyName": "visitDate"
    // },
    ],
    requestColumns: [{
      'title': 'Date ',
      "propertyName": "dateF",
      className: 'main-column'
    }, {
      'title': 'Patient',
      "propertyName": "patientName"
    }, {
      'title': 'Note',
      "propertyName": "note"
    }]
  });

  _exports.default = _default;
});
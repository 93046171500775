define("hmis/components/controls/access-m-module-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      lockClick(value) {
        event.preventDefault();
        event.stopPropagation();
        let action = this.get('lockClick');
        if (action) action(value);
      },

      onRemove(value) {
        let action = this.get('removeClick');
        if (action) action(value);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/paged-report-filter-rows-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "L4E50m7U",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[0,\"\\n\"],[4,\"power-select-multiple\",null,[[\"triggerClass\",\"options\",\"selected\",\"label\",\"searchEnabled\",\"placeholder\",\"searchField\",\"search\",\"onchange\"],[[28,\"concat\",[[24,[\"class\"]],\" mt-2\"],null],[28,\"if\",[[24,[\"filterItem\",\"references\"]],[24,[\"filterItem\",\"references\"]],[24,[\"linkOptions\"]]],null],[24,[\"selected\"]],[24,[\"filterItem\",\"label\"]],true,[28,\"concat\",[\"Search \",[24,[\"filterItem\",\"label\"]],\" \",[24,[\"structure\"]],\" to include\"],null],[24,[\"filterItem\",\"referenceNameProperty\"]],[28,\"if\",[[24,[\"filterItem\",\"referencesLink\"]],[28,\"action\",[[23,0,[]],\"searchLink\"],null],null],null],[28,\"action\",[[23,0,[]],\"onChange\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"get\",[[23,1,[]],[24,[\"filterItem\",\"referenceNameProperty\"]]],null],false],[0,\" \\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/paged-report-filter-rows-columns.hbs"
    }
  });

  _exports.default = _default;
});
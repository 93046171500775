define("hmis/mixins/billable-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajaxRequest: Ember.inject.service(),
    price: _emberData.default.attr(),
    //for facility saving
    priceReview: Ember.computed(function () {
      let billableName = this.get('billableName');
      if (!billableName) console.error('No billable name set');
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('ajaxRequest').billablePriceReview("/billablePriceReview/" + billableName + "/" + this.get('id'), {
          success: response => {
            resolve(response);
          },
          error: error => {
            reject(error);
          }
        });
      });
    }),

    insuranceProductPriceReview(insuranceProductId) {
      let billableName = this.get('billableName');
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('ajaxRequest').makeRequest('GET', "/billableInsurancePriceReview/" + insuranceProductId + "/" + billableName + "/" + this.get('id'), null, {
          success: response => {
            resolve(response);
          },
          error: error => {
            reject(error);
          }
        });
      });
    },

    saveFacilityPrice(price) {
      let billableName = this.get('billableName');
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('ajaxRequest').makeRequest('POST', "/billableFacilityPrice/" + billableName + "/" + this.get('id'), {
          price: price
        }, {
          success: response => {
            resolve(response);
          },
          error: error => {
            reject(error);
          }
        });
      });
    },

    saveFacilityInsurancePrice(insuranceProductId, price) {
      let billableName = this.get('billableName');
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('ajaxRequest').makeRequest('POST', "/billableFacilityInsurancePrice/" + insuranceProductId + "/" + billableName + "/" + this.get('id'), {
          price: price
        }, {
          success: response => {
            resolve(response);
          },
          error: error => {
            reject(error);
          }
        });
      });
    },

    saveChagInsurancePrices(insuranceProductId, pricesObject) {
      let billableName = this.get('billableName');
      console.log('prices', pricesObject);
      return new Ember.RSVP.Promise((resolve, reject) => {
        this.get('ajaxRequest').makeRequest('POST', "/billableChagInsurancePrices/" + insuranceProductId + "/" + billableName + "/" + this.get('id'), {
          prices: pricesObject
        }, {
          success: response => {
            resolve(response);
          },
          error: error => {
            reject(error);
          }
        });
      });
    }

  });

  _exports.default = _default;
});
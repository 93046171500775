define("hmis/templates/components/report-pages/_chag-facility-types-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hvpHgmAd",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\\n\"],[4,\"if\",[[24,[\"chartColums\"]]],null,{\"statements\":[[1,[28,\"high-charts\",null,[[\"mode\",\"chartOptions\",\"content\",\"theme\"],[[24,[\"mode\"]],[24,[\"chartOptions\"]],[24,[\"chatData\"]],[24,[\"theme\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/report-pages/_chag-facility-types-chart.hbs"
    }
  });

  _exports.default = _default;
});
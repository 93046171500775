define("hmis/routes/hmis-app/diagnostics/investigations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'Laboratory & Other Investigations'
    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/facility-management/add-facility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return {
        servicesOffered: [],
        professions: []
      };
    }

  });

  _exports.default = _default;
});
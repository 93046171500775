define("hmis/controllers/sign-in/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    info: '',
    error: false,
    actions: {
      submitForm() {
        this.get('submitForm')();
      },

      authenticate() {
        const {
          username,
          password
        } = this.getProperties('username', 'password');
        var authenticator = 'authenticator:oauth2';

        if (!this.get('authenticating')) {
          setTimeout(() => {
            this.set('authenticating', true);
          }, 500);
          this.get('session').authenticate(authenticator, username, password).then(() => {
            this.set('info', 'Login successful');
            this.set('error', false);
            setTimeout(() => {
              this.set('authenticating', false);
            }, 1000); // this.set('session.store.cookieExpirationTime', null);
          }, err => {
            // console.log('the err', err)
            if (err) this.set('info', err.error);else {
              this.set('info', 'Something went wrong! Please check connection and try again');
            }
            this.set('error', true);
            setTimeout(() => {
              this.set('authenticating', false);
            }, 1000);
          });
        }
      }

    }
  });

  _exports.default = _default;
});
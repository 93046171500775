define("hmis/controllers/hmis-app/users/index", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    routing: Ember.inject.service('router'),
    selectedItems: [],
    arcSelectedItems: [],
    allowSelect: true,
    data: Ember.computed.alias('model'),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    selectedCount: Ember.computed("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    arcUsr: [],
    onConfirm: 'deleteRecord',
    actions: {
      addUser() {
        this.set('editRecord', {});
        this.set('editRecordDialog', true);
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to archive user: ' + record.username);
        this.set('onAlert', true);
      },

      archivedRecords() {
        this.set('deactivatedModal', true);
      },

      activateUser(record) {
        this.notify.prompt({
          title: 'Activating archived user: ' + record.username,
          hideInput: false,
          message: 'Do you want to continue?'
        }, () => {
          this.set('auwaiting', true);
          this.appAjax.post("/activateArchivedUser/" + record.id, {}).then(response => {
            this.set('auwaiting', false);
            this.send('refreshModel');
            this.notify.success('User Activated Successfully');
          }).catch(err => {
            this.set('auwaiting', false);
            this.notify.error(err);
          });
        });
      },

      deleteNow(recordTag) {
        // this.get("selectedItems").removeObject(recordTag);
        recordTag.destroyRecord();
        this.send('refreshModel');
        this.notify.success('User Activated Successfully');
      },

      editRecord(record) {
        //this.get("routing").transitionTo("hmis-app.users.edit-user",[record.id]);
        //var record=this.get("model")
        this.set('editRecord', record);
        this.set('editRecordDialog', true);
      },

      rowDoubleClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id');
        console.log('rid', rid);
        this.get("routing").transitionTo("hmis-app.users.user", rid);
      },

      deleteRecords() {
        let pCount = parseInt(this.get("selectedItems").length);
        this.set('onConfirm', 'deleteRecordsNow');
        this.set('alertTitle', 'Attempting to archive ' + pCount + ' user' + (pCount > 1 ? 's' : ''));
        this.set('onAlert', true);
      },

      deleteRecordsNow() {
        this.get("selectedItems").forEach((patient, index) => {
          patient.destroyRecord();
        });
        this.set("selectedItems", []);
      },

      activateRecords() {
        let pCount = parseInt(this.get("selectedItems").length);
        this.set('onConfirm', 'activateRecordsNow');
        this.set('alertTitle', 'Attempting to activate ' + pCount + ' user' + (pCount > 1 ? 's' : ''));
        this.set('onAlert', true);
      },

      activateRecordsNow() {
        this.get("selectedItems").forEach((patient, index) => {
          patient.set('deleteStatus', 0);
          patient.save();
        });
        this.set("selectedItems", []);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      "propertyName": "username",
      className: 'main-column'
    }, {
      "propertyName": "fullName",
      component: 'controls/table-link',
      route: 'hmis-app.users.user',
      permissions: ['op2.view', 'op2.add']
    }, {
      "title": "Role",
      "propertyName": "roleName"
    }, {
      "title": "Date of birth",
      "propertyName": "dateOfBirthF"
    }, {
      "propertyName": "gender"
    }, {
      "propertyName": "contactEmail"
    }, {
      "propertyName": "phoneNumber"
    }, {
      "title": "Actions",
      "component": "manageRow"
    }],
    columns1: [{
      "propertyName": "username",
      className: 'main-column'
    }, {
      "propertyName": "firstName",
      className: 'main-column'
    }, {
      "propertyName": "lastName",
      className: 'main-column'
    }, {
      "propertyName": "roleName"
    }, // {
    //   "propertyName": "userStatus"
    // },
    {
      "propertyName": "gender"
    }, {
      "propertyName": "contactEmail"
    }, {
      "propertyName": "phoneNumber"
    }, {
      "title": "Actions",
      "component": "manageRow"
    }]
  });

  _exports.default = _default;
});
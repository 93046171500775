define("hmis/templates/hmis-app/records/add-patient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "16CsoDX2",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Back\",[28,\"action\",[[23,0,[]],\"back\"],null],\"back-fill\"]]],false],[0,\"\\n        \"],[4,\"if\",[[24,[\"oPerms\",\"op1\",\"add\"]]],null,{\"statements\":[[1,[28,\"toolbar-item\",null,[[\"text\",\"waiting\",\"onClick\",\"icon\"],[\"Save New Patient\",[24,[\"saving\"]],[28,\"action\",[[23,0,[]],\"submitForm\"],null],\"save-fill\"]]],false]],\"parameters\":[]},null],[0,\"\\n\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height\",true,\"Add Patient\"]],{\"statements\":[[4,\"if\",[[24,[\"oPerms\",\"op1\",\"add\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n            \"],[1,[28,\"patient-manager\",null,[[\"type\",\"record\",\"parent\",\"onValidFormSubmit\"],[\"add\",[24,[\"model\"]],[23,0,[]],[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[28,\"no-data-message\",null,[[\"message\"],[\"You don't have permissions to add patients\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/records/add-patient.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/visit-purpose/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      save() {
        let that = this;
        let model = this.get('model');
        this.set('model', this.get('store').createRecord('visit-purpose', model));
        this.get("model").save().then(record => {
          that.notify.success('Saved Successfully'); //this.transitionToRoute("hmis-app.admin-settings.vitals-type");
        }).catch(failure => {
          //alert("Unable to saved");
          that.notify.error('Unable to Save' + failure);
        });
      },

      deleteRecords() {
        this.get("selectedItems").forEach((user, index) => {
          user.destroyRecord();
        });
        this.set("selectedItems", []);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/stack-pages/visit-purpose", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    newRecord: {},
    store: Ember.inject.service(),
    purpose: Ember.computed(function () {
      return this.get('store').findAll('visit-purpose');
    }),
    notify: Ember.inject.service(),
    onConfirm: 'deleteRecord',
    actions: {
      deleteRecord(record) {
        console.log(record);
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete: ' + record.get('name'));
        this.set('onAlert', true);
      },

      deleteNow(record) {
        //this.get("model").removeObject(record);
        record.destroyRecord();
      },

      saveRecord(record) {
        record.save();
      },

      clickRecord(record) {//this.get('statsContainer').addPage('stack-pages/department-man', record.get('name'),{department: record})
      },

      saveNewRecord() {
        this.set('listWait', true);
        let newRecord = this.get('store').createRecord('visit-purpose', this.get('newRecord'));
        newRecord.save().then(record => {
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error('Unable to Save');
        });
      }

    }
  });

  _exports.default = _default;
});
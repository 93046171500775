define("hmis/components/toolbar-item", ["exports", "hmis/mixins/page-error-handler"], function (_exports, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function concatWithProperty(strings, property) {
    if (property) {
      strings.push(property);
    }

    return strings.join(' ');
  }

  var _default = Ember.Component.extend(_pageErrorHandler.default, {
    tagName: '',
    routing: Ember.inject.service('-routing'),
    icon: 'no-icon',
    //toolbar-item {{if (or disable disabled) "disabled"}}  {{if waiting "waiting"}} {{if primary 'primary'}}"
    concatenatedTriggerClasses: Ember.computed('triggerClass', 'disable', 'disabled', 'waiting', 'primary', function () {
      let classes = ['toolbar-item ', 'app-tooltip '];

      if (this.get('disable') || this.get('disabled')) {
        classes.push('disabled');
      }

      if (this.get('waiting')) {
        classes.push('waiting');
      }

      if (this.get('primary')) {
        classes.push('primary');
      }

      return concatWithProperty(classes, this.get('triggerClass'), this.get('class'));
    }),
    primary: true,
    actions: {
      click(event) {
        if (this.get('disabled') || this.get('disable') || this.get('waiting')) {} else {
          // this.onMenuClick();
          let action1 = this.get('onClick'); // let action2 = this.get('click')

          if (action1) action1(this.get('route'), event); // else if(action2)
          //     action2(this.get('route'), event)

          if (this.get('route')) {
            this.get("routing").transitionTo(this.get('route'));
          } // event.stopPropagation();
          // this.transitionToRoute(this.get("route"))

        }
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/opd/triage", ["exports", "hmis/utils/model-table-theme", "hmis/utils/common-data-columns", "hmis/mixins/page-error-handler", "hmis/utils/general-util", "hmis/mixins/app-event-mixin", "hmis/utils/notifications-util"], function (_exports, _modelTableTheme, _commonDataColumns, _pageErrorHandler, _generalUtil, _appEventMixin, _notificationsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pageErrorHandler.default, _appEventMixin.default, {
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    data: Ember.inject.service(),
    notify: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    selectedItems: [],
    allowSelect: true,
    // data: alias('model'),
    notInSelection: Ember.computed("selectedItems.length", "selectedItems.@each", function () {
      return this.get("selectedItems").length < 1;
    }),
    selectedCount: Ember.computed("selectedItems.length", "selectedItems.@each", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    selectedPatient: '',
    selectedVisit: [],
    selectedDormantVisits: [],
    assignedFilter: 'all',

    onPermissionNotification(e) {
      if (e.actionValue == _notificationsUtil.NOTIFICATION_ACTION_VALUES.NEW_VISIT) {
        this.send('onQueuedPatientsTab', true);
      }

      if (e.actionValue == _notificationsUtil.NOTIFICATION_ACTION_VALUES.VITALS_REQUEST) {
        this.send('onVitalRequestsTab', true);
      }
    },

    vitalsSuccessAction: 'empty',
    pickDoctorConfirmAction: 'empty',
    actions: {
      onQueuedPatientsTab(force = false) {
        if (!this.queuedPatients || force) (0, _generalUtil.promiseSet)(this, 'queuedPatients', this.store.query('queued-patient', {
          filter: {
            departmentUnit: 'opd',
            today: true,
            served: 0
          }
        }), 'queuedPatientsWaiting');
      },

      onVitalRequestsTab(force = false) {
        if (!this.vitalRequests || force) (0, _generalUtil.promiseSet)(this, 'vitalRequests', this.store.query('vitals-request', {
          filter: {
            served: 0
          },
          include: ['patientName']
        }), 'vitalRequestsWaiting');
      },

      onDormantVisitsTab(force = false) {
        if (!this.dormantVisits || force) (0, _generalUtil.promiseSet)(this, 'dormantVisits', this.store.query('visit', {
          filter: {
            isDormant: 1
          },
          include: ['patientName', 'purposeName', 'opdQueuedId']
        }), 'dormantVisitsWaiting');
      },

      onAssignedPatientsTab(force = false) {
        if (!this.assignedPatients || force) {
          let served = this.assignedFilter == 'all' ? null : this.assignedFilter == 'attended' ? 1 : 0;
          (0, _generalUtil.promiseSet)(this, 'assignedPatients', this.store.query('queued-patient', {
            filter: {
              departmentUnit: 'assigned',
              today: true,
              served
            },
            include: ['visitPurpose', 'departmentUnitName']
          }), 'assignedPatientsWaiting');
        }
      },

      changeAssignFilter(filter) {
        this.set('assignedFilter', filter);
        this.send('onAssignedPatientsTab', true);
      },

      newAppointment() {
        this.appointmentManager.newAppointment({}, appointment => {});
      },

      ////
      addEndvisit() {
        console.log('selectedItems', this.get('selectedItems'));
        this.set('selectedVisit', this.get('selectedItems').get('firstObject'));
        this.get('selectedItems');
        this.set('editEndvisit', {});
        this.set('endvisitDialog', true);
      },

      editEndvisit(record) {
        console.log('end domant', 'just ttest');
        this.set('editEndvisit', record);
        this.set('endvisitDialog', true);
      },

      onSaved(record) {
        this.send('refreshModel');
      },

      empty() {},

      addVitalFromQueuedPatient(event) {
        let record = this.get('selectedItems').get('firstObject');
        this.vitalsTaker.show((0, _generalUtil.visitServiceToVisitData)(record), null, res => {});
      },

      addVitalFromRequest(index, record) {
        this.vitalsTaker.show((0, _generalUtil.visitServiceToVisitData)(record), record.id, res => {
          this.send('onVitalRequestsTab', true);
        });
      },

      pickUnitForQueue() {
        this.data.serverTable({
          modelName: 'department-unit',
          columns: _commonDataColumns.departmentUnitPickerColumns,
          title: 'Select a Consultation Unit',
          query: {
            filter: {
              isConsultation: 1
            },
            include: ['serviceName']
          }
        }, unit => {
          let queuedPatient = this.get('selectedItems').get('firstObject');
          this.appAjax.post('/reassignQueues', {
            queues: [queuedPatient.get('id')],
            departmentUnit: unit.get('id')
          }).then(data => {
            this.set('selectedItems', []);
            this.send('onQueuedPatientsTab', true);
            this.notify.success('Assigned');
          }).catch(err => {
            this.notify.error(err);
          });
        });
      },

      pickUnitForDormantQueues() {
        this.data.serverTable({
          modelName: 'department-unit',
          columns: _commonDataColumns.departmentUnitPickerColumns,
          title: 'Select a Consultation Unit',
          query: {
            filter: {
              isConsultation: 1
            },
            include: ['serviceName']
          }
        }, unit => {
          let dormantVisits = this.get('selectedDormantVisits');
          this.appAjax.post('/reassignQueues', {
            queues: dormantVisits.map(v => v.opdQueuedId),
            departmentUnit: unit.get('id')
          }).then(data => {
            this.send('onDormantVisitsTab', true);
            this.set('selectedDormantVisits', []);
            this.notify.success('Visits Assigned');
          }).catch(err => {
            this.notify.error(err);
          });
        });
      },

      endDormantVisits() {
        this.notify.prompt({
          title: 'Add a note to end visit(s)',
          textArea: true
        }, note => {
          let dormantVisits = this.get('selectedDormantVisits');
          this.appAjax.post('/endDormantVisits', {
            visits: dormantVisits.map(v => v.id),
            note: note
          }).then(data => {
            this.send('onDormantVisitsTab', true);
            this.set('selectedDormantVisits', []);
            this.notify.success('Visits ended');
          });
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false,
      "className": 'allergy-checkbox-width'
    }, {
      "propertyName": "patientName",
      'className': 'main-column'
    }, {
      title: 'Purpose',
      'propertyName': 'visitPurpose'
    }, {
      title: 'Visit Date',
      "propertyName": "visitDateF"
    }],
    assignedColumns: [{
      "propertyName": "patientName",
      'className': 'main-column'
    }, {
      title: 'Purpose',
      'propertyName': 'visitPurpose'
    }, {
      title: 'Department Unit',
      "propertyName": "departmentUnitName"
    }, {
      title: 'status',
      "component": "controls/table-prop-format",
      format: (value, record) => {
        return value ? 'attended' : 'unattended';
      },
      "propertyName": "dateServed"
    }, {
      title: 'Visit date',
      "propertyName": "visitDateF"
    }],
    dormantColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false,
      "className": 'allergy-checkbox-width'
    }, {
      // "component": "controls/table-props-concat",
      "title": "patient Name",
      'className': 'main-column',
      propertyName: 'patientName'
    }, {
      title: 'Purpose',
      'propertyName': 'purposeName'
    }, {
      title: 'Time of visit',
      "component": "controls/table-prop-format",
      format: "date",
      "propertyName": "createdAt"
    }],
    requestColumns: [{
      'title': 'Patient',
      "propertyName": "patientName"
    }, {
      title: 'Note',
      "propertyName": "note",
      component: 'controls/table-long-text'
    }, {
      'title': 'Request Date ',
      "propertyName": "dateTimeF",
      className: 'main-column'
    }]
  });

  _exports.default = _default;
});
define("hmis/templates/components/toolbar-item-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bXKNBdxA",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"tb-menu-tem\"],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"onMenuItemClick\"],null]],[8],[0,\"\\n\"],[0,\"  \"],[14,1,[[24,[\"item\"]]]],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/toolbar-item-menu.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/con-display-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DYjS+WPb",
    "block": "{\"symbols\":[\"filteredList\",\"item\",\"&default\"],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[10,\"id\",\"con-display-search\"],[10,\"class\",\"con-display-search con-display\"],[8],[0,\"\\n\"],[0,\"    \"],[1,[28,\"input\",null,[[\"id\",\"class\",\"focusIn\",\"placeholder\",\"value\"],[\"search-input\",\"search-input\",[28,\"action\",[[23,0,[]],\"onFocusIn\"],null],[24,[\"placeholder\"]],[24,[\"query\"]]]]],false],[0,\"  \\n\"],[4,\"if\",[[24,[\"isFocus\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"results\"],[8],[0,\"\\n\"],[4,\"list-filter-no-elem\",null,[[\"data\",\"query\",\"filterParams\"],[[24,[\"list\"]],[24,[\"query\"]],[28,\"array\",[\"name\"],null]]],{\"statements\":[[4,\"if\",[[23,1,[\"length\"]]],null,{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"                    \"],[14,3,[[23,2,[]],[28,\"action\",[[23,0,[]],\"setFocus\"],null]]],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[7,\"div\",true],[10,\"class\",\"error-cont\"],[8],[0,\"\\n                    \"],[7,\"p\",true],[10,\"class\",\"message no-result\"],[8],[0,\"No result found with name \\\"\"],[1,[22,\"query\"],false],[0,\"\\\"\"],[9],[0,\"\\n                \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/con-display-search.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/managers/vitals-taker-alert", ["exports", "hmis/utils/date-util", "hmis/utils/model-table-theme", "hmis/utils/bmi-calc", "hmis/utils/vital-validation", "hmis/mixins/ref-component"], function (_exports, _dateUtil, _modelTableTheme, _bmiCalc, _vitalValidation, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    store: Ember.inject.service('store'),
    app: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    data: Ember.inject.service(),
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    show(visit, vitalRequestId = null, onSuccess = null) {
      this.set('visit', visit);
      this.set('onSuccess', onSuccess);
      this.set('_show', true);
      this.set('waiting', true);
      this.set('vitalRequestId', vitalRequestId); //get visit

      this.appAjax.ajaxGet('visits', {
        filter: {
          id: visit.id
        },
        include: ['patientDateOfBirth', 'patientName']
      }).then(res => {
        this.set('visit', res[0]); //generate new  vitals

        this.generateVitals().then(vitals => {
          this.set('vitalsData', vitals);
        }).finally(() => {
          this.set('waiting', false);
        });
      });
    },

    close() {
      this.set('_show', false);
    },

    bmi: Ember.computed('vitalsData.@each.value', function () {
      return (0, _bmiCalc.bmiFromVitalsList)(this.vitalsData);
    }),

    generateVitals() {
      return new Promise((resolve, reject) => {
        this.appAjax.getCacheable('vitalsFacilityDefaults', {
          include: ['vitalTypeName', 'unitOfMeasureName']
        }, '_fac_vitals_cache_1').then(records => {
          let newVitals = [];
          records.forEach((item, index) => {
            newVitals.push(Object.assign({
              patientDOB: this.visit.patientDateOfBirth,
              value: null
            }, item));
          });
          resolve(newVitals);
        }).catch(err => {
          reject(err);
        });
      });
    },

    actions: {
      onConfirm() {
        this.set('waiting', true);
        let data = [];
        this.vitalsData.forEach(function (vData) {
          if (vData.value) data.push({
            vitalTypeId: vData.vitalTypeId,
            value: vData.value,
            unitOfMeasureId: vData.unitOfMeasureId
          });
        });

        if (!data.length) {
          this.notify.error('Empty fields');
          return;
        }

        this.appAjax.post('/vitalBatches/saveVisitVitals/' + this.visit.id, {
          vitals: data,
          vitalRequestId: this.vitalRequestId
        }).then(res => {
          this.notify.success('Vitals added');
          this.onSuccess && this.onSuccess(res);
          this.close();
          this.send('resetValues');
        }).catch(err => {
          this.notify.error(err);
        }).finally(() => {
          this.set('waiting', false);
        });
      },

      onCancel() {
        this.send('resetValues');
      },

      resetValues() {
        if (this.vitalsData) {
          this.vitalsData.forEach(function (vData) {
            if (vData.value) Ember.set(vData, 'value', null);
          });
        }
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      'title': 'Vital type',
      "propertyName": "vitalTypeName",
      "editable": false
    }, {
      "propertyName": "value",
      "component": "tableEditable",
      warning: (record, value) => {
        if (value == 1) {
          value = null;
        }

        return (0, _vitalValidation.vitalValidation)(record.vitalTypeId, record.patientDOB, value);
      }
    }, {
      'title': 'Measure',
      "propertyName": "unitOfMeasureName",
      "editable": false
    }]
  });

  _exports.default = _default;
});
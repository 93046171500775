define("hmis/controllers/hmis-app/ipd/ward", ["exports", "hmis/utils/request-utils/ipd", "hmis/mixins/form-controller", "hmis/utils/general-util", "hmis/mixins/visit-care-controller"], function (_exports, _ipd, _formController, _generalUtil, _visitCareController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_visitCareController.default, {
    router: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    notify: Ember.inject.service(),
    consultationService: Ember.inject.service('consultation'),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    // patientName:computed('model.patientName',function(){
    //     return this.get('model.patientName');
    // }),
    visitOutcomes: Ember.computed(function () {
      return this.get('store').findAll('visit-outcome');
    }),
    notValidForAdmissionRequest: Ember.computed('model.visitStatusId', function () {
      let visitStatusId = this.get('model.visitStatusId');
      return visitStatusId != 1;
    }),
    canServe: Ember.computed('property', function () {// body
    }),
    // visit:computed('model.visit', 'model.visit.status', function(){
    //   return this.get('store').query('transaction',
    //     {filter: {visit: this.get('model.visit').get('id'),isInsurance: 0,}
    //     })
    // }),
    // outstandingBills:computed('visit','visit.@each.isPaid', function() {
    //   return this.get('visit').filterBy('isPaid', false);
    // }),
    // hasOutstandingBills:computed('outstandingBills.length', function() {
    //   return this.get('outstandingBills').length?true:false;
    // }),
    title: 'Patient',
    input_width: 'flex-45',
    label: 'Change Department',
    onConfirm: 'empty',
    onCancel: 'empty',
    departmentUnit: Ember.computed('model', function () {
      return this.get('store').findAll('ward');
    }),
    // init(){
    //   this.set('model.dischargeDate')
    // },
    actions: {
      empty() {},

      changeServiceDepartment() {
        this.set('findRecord', true);
      },

      chooseConfirm(record) {
        this.send('setRecord', record);
        this.set('findRecord', false);
      },

      onAutocompleteChange(record) {
        this.send('setRecord', record);
      },

      setRecord(record) {
        this.set('picker-waiting', true);
        let mod = this.get('model');
        mod.set('departmentUnit', record);
        mod.save().then(rec => {
          console.log('record', rec);
          this.set('picker-waiting', false);
        }, err => {
          this.set('picker-waiting', false);
          console.log('error', err);
        });
      },

      showVisitSummary() {
        console.log('visitServiceToVisitData( this.model)', (0, _generalUtil.visitServiceToVisitData)(this.model));
        this.visitViewer.show((0, _generalUtil.visitServiceToVisitData)(this.model));
      },

      takeVitals() {
        this.vitalsTaker.show((0, _generalUtil.visitServiceToVisitData)(this.model), null, res => {});
      },

      testReload() {
        let visit = this.get('model.visit');
        let visitId = visit.get('id');
        this.get('store').findRecord('visit', visitId, {
          reload: true
        });
      },

      progressChart() {
        this.set('chartTitle', this.get('model.patientName') + '\'s Progress');
        this.vitalProgress.loadchart(this.get('model.visit.id'));
        this.vitalProgress.loadcare(this.get('model.patient.id'), this.get('model.visit.id'));
      },

      adminCare() {
        this.careTaker.show({
          visitId: this.model.visitId
        }, () => {}); // let md = this.get('model')
        // this.set('editCare', {
        //   patient: md.patient,
        //   visit: md.visit
        // })
        // this.set('careTitle','Administer Care')
        // this.set('careDialog',true)
      },

      // dischargeRequestClick(outcome){
      //   console.log(outcome);
      //     this.set('outcome', outcome)
      //     this.set('onDischargeAlert',true)
      // },
      sendDischargeRequest() {
        let admittedQueuedPatient = this.get('model'); // this.get('notify').notifyInfo('Sending request, please wait', true)

        this.set('requestingDischarge', true);
        this.get('ajaxRequest').requestDischarge(admittedQueuedPatient, {
          success: response => {
            this.set('requestingDischarge', false);
            this.notify.success('Discharge Request sent');
            that.get('store').findRecord('visit', visitId, {
              reload: true
            });
          },
          error: error => {
            this.set('requestingDischarge', false);
            this.notify.error('Sorry! Something went wrong');
          }
        }); // requestDischarge(visit.get('id'), this.get('outcome.id'), this.get('dischargeNote'));
      },

      back() {
        history.back(); // this.get("router").transitionTo("hmis-app.consultation");
      },

      onMenuClick(route, title) {
        try {
          this.get("router").transitionTo(route);
          this.set('title', title);
        } catch (err) {
          console.log(err);
        }
      }

    },
    columns: [{
      title: 'Unit',
      "propertyName": "name"
    }, {
      title: 'Department',
      "propertyName": "department.name"
    }]
  });

  _exports.default = _default;
});
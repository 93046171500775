define("hmis/controllers/hmis-app/admin-settings/departments/ward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service('store'),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    selectedRooms: [],
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    batchItems: {},
    onConfirm: 'deleteRecord',
    actions: {
      back() {
        history.back();
      },

      addBed() {
        let editbut = $("#bedbut").html();

        if (editbut == 'Save') {
          let editID = $("#bededitId").val();
          let model = this.get('model');
          let store = this.get('store');
          let that = this;
          let nbeds = this.get('numBeds');
          store.findRecord('bed', editID).then(function (post) {
            post.get('name');
            post.set('name', that.get('bedname'));
            post.save().then(record => {
              that.notify.success('Saved Successfully');
            }).catch(failure => {
              that.notify.error('Unable to Save' + failure);
            });
          });
        } else {
          let model = this.get('model');
          let store = this.get('store');
          let that = this;
          let nbeds = this.get('numBeds');

          if (nbeds > 1) {
            for (var i = 0; i < nbeds; ++i) {
              //if(i == 0) i += 1
              let post = store.createRecord('bed', {
                name: this.get('bedname') + i,
                room: this.get('selectedRooms')
              });
              post.save().then(record => {//that.notify.success('Saved Successfully')
              }).catch(failure => {
                that.notify.error('Unable to Save' + failure);
              });
            }

            that.notify.success('Saved Successfully');
          } else {
            let post = store.createRecord('bed', {
              name: this.get('bedname'),
              room: this.get('selectedRooms')
            });
            post.save().then(record => {
              that.notify.success('Saved Successfully');
            }).catch(failure => {
              that.notify.error('Unable to Save' + failure);
            });
          }
        }
      },

      saveRecord(record) {
        record.save();
      },

      editBed(record) {
        console.log(record);
        console.log(record._internalModel._data.name);
        this.set('bedname', record._internalModel._data.name); //this.set('bedbut',record._internalModel._data.name);
        //console.log(this.get('bedbut'))
        //$('#bedEditPanel').show()

        $("#bedbut").html('Save');
        $("#bededitId").val(record.id);
      },

      addRoom() {
        //let model = this.get('model')
        //console.log(this.get('model'))
        let store = this.get('store');
        let that = this;
        let post = store.createRecord('room', {
          name: this.get('name'),
          ward: this.get('model'),
          price: this.get('price')
        });
        post.save().then(record => {
          that.notify.success('Saved Successfully');
        }).catch(failure => {
          that.notify.error('Unable to Save' + failure);
        });
      },

      addWard() {
        let model = this.get('model');
        let store = this.get('store');
        let that = this;
        let post = store.createRecord('department-unit', {
          name: this.get('name'),
          department: this.get('model'),
          type: 2
        });
        post.save().then(record => {
          that.notify.success('Saved Successfully');
        }).catch(failure => {
          that.notify.error('Unable to Save' + failure);
        });
      },

      editRecord() {
        console.log('view msg');
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete a room: (' + record.get('name') + ')');
        this.set('onAlert', true);
      },

      deleteNow(record) {
        this.get("model.rooms").removeObject(record);
        record.destroyRecord();
      },

      clickRecord(record) {
        let roomSel = this.get('store').findRecord('room', record.id);
        this.set('selectedRooms', roomSel);
        this.set('bedsModal', true);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/helpers/percentage-of", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.percentageOf = percentageOf;
  _exports.default = void 0;

  function percentageOf([number, total, percentSign = false]) {
    let percent = 100 / (total / number);
    if (percentSign) return percent + '%';else {
      return percent;
    }
  }

  var _default = Ember.Helper.helper(percentageOf);

  _exports.default = _default;
});
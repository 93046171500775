define("hmis/routes/hmis-app/admin-settings/medicines/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        medicines: this.get('store').query('medicine', {
          pageSize: 60
        }).then(dt => {
          return dt;
        }),
        unitOfPricings: this.get('store').query('medicine-pricing-unit', {}),
        levelCodes: this.get('store').query('level-code', {}).then(res => res.map(({
          id
        }) => id)).catch(err => {
          console.error(err);
          return err;
        })
      });
    },

    actions: {
      reloadModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/models/schedule", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util"], function (_exports, _emberData, _relationships, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    staff: (0, _relationships.belongsTo)('user'),
    facilityPeriod: (0, _relationships.belongsTo)('facility-period', {
      async: false
    }),
    departmentUnit: (0, _relationships.belongsTo)('department-unit', {
      async: false
    }),
    startTime: _emberData.default.attr(),
    endTime: _emberData.default.attr(),
    day: _emberData.default.attr(),
    periodName: _emberData.default.attr(),
    periodStartTime: _emberData.default.attr(),
    periodEndTime: _emberData.default.attr(),
    periodBreak: _emberData.default.attr(),
    // appointments:hasMany('appointment',{ inverse: null, async: false }),
    // allAppointments:hasMany('appointment', { inverse: null, async: false  }),
    // servedAppointments:hasMany('appointment', { inverse: null, async: false  }),
    // dueRange :computed('day','facilityPeriod.startTime','facilityPeriod.endTime', function() {
    //     return dateUtil.formatToDateTimeString( this.get('day')+' '+this.get('facilityPeriod.startTime') ) +" to "+ dateUtil.formatToTimeString(this.get('day')+' '+this.get('facilityPeriod.endTime') );
    // }),
    periodDuration: Ember.computed('periodStartTime', 'periodEndTime', function () {
      return _dateUtil.default.stringTimeDifference(this.get('periodStartTime'), this.get('periodEndTime'));
    })
  });

  _exports.default = _default;
});
define("hmis/mixins/report-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);

      var date = new Date();
      var firstDay = new Date(date.getFullYear(), 0, 1);
      var lastDay = new Date(date.getFullYear(), 11 + 1, 0);
      this.set('fromDate', moment(firstDay).format("YYYY-MM-DD"));
      this.set('toDate', moment(lastDay).format("YYYY-MM-DD"));
      this.set('otherFilters', []); // this.loadReport();
    },

    reports: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    app: Ember.inject.service(),
    selectedRows: [],
    selectedColumns: [],
    tabIndex: 0,
    showNoDataRowsColumns: true,
    isLoadingReport: false,
    reportProgress: 0,

    // serverReport: {data:[]},
    loadReport() {
      //moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD")
      this._loadReport(1);
    },

    _loadReport(pageIndex, paginationMeta = {}) {
      //moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD")
      let fromDate = this.get('fromDate');
      let toDate = this.get('toDate');
      let reportType = this.get('reportType');
      this.get('app').startLoading();
      this.set('isLoadingReport', true);
      this.get('ajaxRequest').generateReport(reportType, fromDate, toDate, {
        success: serverReport => {
          if (pageIndex == 1) {} else {
            serverReport.data.push(...this.get('serverReport.data'));
            console.log(serverReport);
          }

          this.set('reportProgress', serverReport.pagination.pageIndexPercentage);
          this.set('serverReport', serverReport);
          this.setDefaults(serverReport);

          if (serverReport.pagination.pageIndexPercentage == 100) {
            this.set('isLoadingReport', false);
            this.set('reportProgress', false);
            this.get('app').stopLoading();
          } else {
            this._loadReport(Number(serverReport.pagination.pageIndex) + 1, serverReport.pagination);
          }
        },
        error: err => {
          console.log(err);
          this.set('isLoadingReport', false);
          this.get('app').stopLoading();
        }
      }, pageIndex, paginationMeta);
    },

    setDefaults(serverReport) {
      let defaultColumnKey = serverReport.defaults.column;
      let defaultRowKey = serverReport.defaults.row;
      let defaultColumn = Object.assign({}, serverReport.filterProps[defaultColumnKey]);
      defaultColumn['key'] = defaultColumnKey;
      let defaultRow = Object.assign({}, serverReport.filterProps[defaultRowKey]);
      defaultRow['key'] = defaultRowKey;
      this.set('rowFilterProp', defaultRow);
      this.set('columnFilterProp', defaultColumn);
    },

    actions: {
      generateReport() {
        this.loadReport();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/managers/procedure-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bDSm8F+e",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[0,\"    \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[24,[\"form\",\"input\"]],\"expected `form.input` to be a contextual component but found a string. Did you mean `(component form.input)`? ('hmis/templates/components/managers/procedure-manager.hbs' @ L4:C6) \"],null]],[[\"class\",\"value\",\"onChange\",\"label\",\"icon\",\"required\"],[[24,[\"flex_level\"]],[24,[\"record\",\"genericName\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"genericName\"]]],null]],null],\"Generic Name\",\"person-outline\",true]]],false],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\",\"required\"],[[24,[\"flex_level\"]],[24,[\"record\",\"nhiaCode\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"nhiaCode\"]]],null]],null],\"NHIA Code\",\"person-outline\",true]]],false],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"gdrgCode\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"gdrgCode\"]]],null]],null],\"G-DRG Code\",\"person-outline\"]]],false],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"price\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"price\"]]],null]],null],\"Price (GHS)\",\"person-outline\"]]],false],[0,\"\\n\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/procedure-manager.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/paper-autocomplete-no-result", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oqY55TbT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"ul\",true],[10,\"class\",\"ember-power-select-options md-autocomplete-suggestions\"],[10,\"role\",\"listbox\"],[8],[0,\"\\n    \"],[7,\"li\",false],[12,\"class\",\"ember-power-select-option ember-power-select-option--no-matches-message\"],[12,\"role\",\"option\"],[3,\"action\",[[23,0,[]],\"onClick\"]],[8],[0,\"\\n        \"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[1,[28,\"svg-jar\",[[24,[\"icon\"]]],[[\"class\",\"width\"],[\"icon dark-fill margin-right-6\",\"15px\"]]],false],[0,\" \"]],\"parameters\":[]},null],[0,\"\\n        \"],[1,[22,\"message\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/paper-autocomplete-no-result.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/utils/bmi-calc", ["exports", "hmis/constants/vital-types"], function (_exports, _vitalTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.bmiFromVitalsList = bmiFromVitalsList;
  _exports.bmiCalculator = bmiCalculator;
  _exports.default = void 0;

  function bmiFromVitalsList(vitalsList) {
    let weight = null;
    let height = null;
    if (!vitalsList) return "----";
    vitalsList.forEach(function (sign, index) {
      if (sign.vitalTypeId == _vitalTypes.VITAL_TYPES.WEIGHT) weight = {
        val: sign.value,
        unit: sign.unitOfMeasureName
      };
      if (sign.vitalTypeId == _vitalTypes.VITAL_TYPES.HEIGHT) height = {
        val: sign.value,
        unit: sign.unitOfMeasureName
      };
    });
    if (!weight || !height) return '--';
    console.log(weight.val, weight.unit, height.val, height.unit);
    return bmiCalculator(weight.val, weight.unit, height.val, height.unit);
  }

  function bmiCalculator(wVal, wUnit, hVal, hUnit) {
    if (!isNumeric(wVal) || !isNumeric(hVal) || hVal < 1) {
      return null;
    }

    let weightKg = null;
    if (wUnit == 'kg') weightKg = wVal;else if (wUnit == 'lbs') weightKg = wVal / 2.2046;else console.error(wUnit + ' is not supported weight unit for BMI calculation');
    let heightM = null;
    if (hUnit == 'm') heightM = hVal;else if (hUnit == 'cm') heightM = hVal / 100;else console.error(hUnit + ' is not supported height unit for BMI calculation');

    if (weightKg == null || heightM == null) {
      return null;
    }

    return (weightKg / Math.pow(heightM, 2)).toFixed(2);
  }

  function isNumeric(s) {
    return !isNaN(s - parseFloat(s));
  }

  var _default = {
    bmiCalculator
  };
  _exports.default = _default;
});
define("hmis/components/controls/access-m-role-o-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    roleOperationPermission: Ember.computed('role', 'role.operationPermissions.@each', 'operationPermission', function () {
      return this.role.operationPermissions.find((roPerm, index, array) => {
        return roPerm.permissionId == this.operationPermission.id;
      }); // let role = this.get('role')
      // return DS.PromiseObject.create({
      //     promise: role.get('roleOperationPermissions').then((permissions) => {
      //         let rolePerm = permissions.findBy('operationPermission.id', this.operationPermission.id);
      //         return rolePerm
      //     })
      // })
    }),
    actions: {
      onChange(prop, value) {
        if (this.roleOperationPermission) {
          this.set('roleOperationPermission.' + prop, value); // console.log('has content',prop, value)
        } else {
          // console.log('no content',prop, value)
          let obj = {
            permissionId: this.operationPermission.id
          };
          obj[prop] = value;
          this.get('role.operationPermissions').pushObject(obj);
        }

        this.set('role.hasChanges', true);
      }

    }
  });

  _exports.default = _default;
});
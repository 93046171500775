define("hmis/components/managers/insurance-company-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flex_level: 'flex-45 flex-xs-100 flex-sm-100',
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    store: Ember.inject.service('store'),
    types: Ember.computed('model', function () {
      return this.get('store').findAll('insurance-type', {}).then(function (recs) {
        return recs;
      });
    }),
    actions: {
      save() {
        let that = this;
        let model = this.get('model');
        this.set('model', this.get('store').createRecord('insurance-company', {
          name: this.get('name'),
          type: this.get('type'),
          facility: this.get('currentUser.user').belongsTo('facility').id()
        }));
        this.get("model").save().then(record => {
          //alert("Saved");
          that.notify.success('Saved Successfully'); //this.transitionToRoute("hmis-app.admin-settings.insurance-companies");
          //console.log('insurance comp')

          that.get('routing').send('reloadModel'); //that.get('routing').modelFor('model').reload()
        }).catch(failure => {
          //alert("Unable to saved");
          that.notify.error('Unable to Save' + failure);
        });
      }

    }
  });

  _exports.default = _default;
});
define("hmis/models/procedure-result", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    procedureTestTypeField: (0, _relationships.belongsTo)('procedure-test-type-field'),
    value: _emberData.default.attr(),
    procedure: (0, _relationships.belongsTo)('procedure'),
    procedureTestTypeField: Ember.computed('procedureTestTypeField.name', function () {
      // return `${this.get('firstName')} ${this.get('lastName')}`;
      return this.get('procedureTestTypeField.name');
    })
  });

  _exports.default = _default;
});
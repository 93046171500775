define("hmis/controllers/hmis-app/admin-settings/scheduler/timeline", ["exports", "hmis/utils/model-table-theme", "hmis/utils/list-util", "ember-data"], function (_exports, _modelTableTheme, _listUtil, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    timelineList: Ember.computed('model', function () {
      return this.get('model').toArray();
    }),
    timelineListOrdered: Ember.computed('timelineList', 'timelineList.@each.startTime', function () {
      let arr = this.timelineList ? this.timelineList.concat().sort(function (a, b) {
        return new Date('1970/01/01 ' + a.startTime) - new Date('1970/01/01 ' + b.startTime);
      }) : [];
      return arr;
    }),
    actions: {
      onAddNew(period, index) {
        console.log(index);
        let newRec = {
          startTime: period ? period.endTime : null,
          isNewItem: true
        };
        this.get('timelineList').insertAt(index + 1, newRec);
        this.set('indexOnEdit', index + 1);
        this.set('itemOnEdit', newRec);
      },

      onRemove(period, index) {
        this.notify.confirm({
          title: 'About to delete a period',
          message: 'Do you want to delete ' + period.name + '?'
        }, () => {
          period.destroyRecord();
          this.get('timelineList').removeAt(index);
        });
      },

      onCancel(period, index) {
        if (period.isNew) {
          period.deleteRecord();
          this.get('timelineList').removeAt(index);
        }
      },

      onItemSave(period) {
        console.log('period name', period.name);

        if (period.name === 'New Period') {
          this.notify.error('Schedule name require');
          return;
        } else if (period.endTime === period.startTime) {
          this.notify.error('Schedule require start and end time');
          return;
        } else {
          let that = this;

          if (!(period instanceof _emberData.default.Model)) {
            period = this.get('store').createRecord('facility-period', period);
          }

          period.save().then(() => {
            that.send('refreshModel');
            this.notify.success('Saved');
          });
        }
      },

      onTestTime(period) {
        console.log(typeof period.get('startTime'), '|', period.get('startTime'), '|');
      }

    }
  });

  _exports.default = _default;
});
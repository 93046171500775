define("hmis/templates/components/tabs-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SQCuaq92",
    "block": "{\"symbols\":[\"tab\",\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"stats-container\",null,[[\"title\",\"class\",\"styled\"],[[28,\"if\",[[24,[\"pageError\"]],\"Unable to access content\",[24,[\"title\"]]],null],\"full-height  has-main-table\",false]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"pageError\"]]],null,{\"statements\":[[0,\"        \"],[5,\"error-viewer\",[],[[\"@errorResult\"],[[22,\"pageError\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"app-fixed-top\"],[8],[0,\"\\n\"],[4,\"controls/tab-control\",null,null,{\"statements\":[[4,\"each\",[[24,[\"_tabs\"]]],null,{\"statements\":[[0,\"                    \"],[1,[28,\"controls/tab-button\",null,[[\"text\",\"active\",\"onClick\"],[[23,1,[\"title\"]],[28,\"eq\",[[23,1,[\"index\"]],[24,[\"tabIndex\"]]],null],[28,\"action\",[[23,0,[]],\"tabClicked\",[23,1,[\"index\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"content full-height\"],[8],[0,\"\\n            \"],[14,2,[[28,\"component\",[\"tabs-container-page\"],[[\"parentTab\"],[[23,0,[]]]]]]],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/tabs-container.hbs"
    }
  });

  _exports.default = _default;
});
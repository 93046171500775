define("hmis/utils/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.referenceToArray = referenceToArray;
  _exports.default = void 0;

  function referenceToArray(serverReport, referenceName, includeNone = true) {
    if (!serverReport) return [];
    let arr = [];
    if (includeNone) arr.push({
      id: 'none',
      label: 'All'
    });
    let reference = serverReport.references[referenceName];

    for (let key in reference) {
      arr.push(reference[key]);
    }

    return arr;
  }

  var _default = {
    referenceToArray
  };
  _exports.default = _default;
});
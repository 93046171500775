define("hmis/components/controls/tab-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    actions: {
      onClick(event) {
        let action = this.get('onClick');
        if (action) action();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/records/patient/medical-info", ["exports", "hmis/utils/date-util", "hmis/utils/general-util", "hmis/mixins/route-error-handler"], function (_exports, _dateUtil, _generalUtil, _routeErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, {
    model(params) {
      var curPatient = this.modelFor("hmis-app.records.patient");
      return (0, _generalUtil.modelHash)({
        patient: curPatient,
        allergies: (0, _generalUtil.innerModelPromise)(this.get('store').query('allergy', {
          filter: {
            patientId: curPatient.id
          }
        })),
        immunizations: (0, _generalUtil.innerModelPromise)(this.get('store').query('immunization', {
          filter: {
            patientId: curPatient.id
          },
          include: ['typeName']
        })),
        chronicConditions: (0, _generalUtil.innerModelPromise)(this.get('store').query('chronic-condition', {
          filter: {
            patientId: curPatient.id
          },
          include: ['typeName', 'diagnosisSubTypeIcdName', 'diagnosisSubTypeIcdCode']
        })),
        diagnoses: (0, _generalUtil.innerModelPromise)(this.get('store').query('diagnosis', {
          filter: {
            patientId: curPatient.id
          },
          include: ['diagnosisTypeName', 'visitPurpose', 'visitDate']
        }))
      });
    },

    // model(params) {
    //     var curPatient = this.modelFor("hmis-app.records.patient")
    //     return RSVP.hash({
    //         patientAllergy: this.get('store').query('allergy', {
    //           filter: {
    //             patientId: curPatient.id,
    //           },
    //         }),
    //         patientImmunization: this.get('store').query('immunization', {
    //           filter: {
    //             patientId: curPatient.id,
    //           },
    //         }),
    //         patientChronicCondition: this.get('store').query('chronic-condition', {
    //           filter: {
    //             patientId: curPatient.id,
    //           },
    //         }),
    //         patient: curPatient, 
    //     });
    //   },
    // setupController(controller, model) {
    //   this._super(controller, model);
    //   var curPatient = this.modelFor("hmis-app.records.patient")
    //   Ember.set(model, 'patient', curPatient)
    //   this.get('store').query('allergy', { filter: { patientId: curPatient.id, }, })
    //   .then(res=>{Ember.set(model, 'patientAllergy', res) })
    //   .catch(error=>{ controller.set('allergyError', error); })
    //   this.get('store').query('immunization', { filter: { patientId: curPatient.id, }, })
    //   .then(res=>{Ember.set(model, 'patientImmunization', res) })
    //   .catch(error=>{ controller.set('immunizationError', error); })
    //   this.get('store').query('chronic-condition', { filter: { patientId: curPatient.id, }, })
    //   .then(res=>{Ember.set(model, 'patientChronicCondition', res) })
    //   .catch(error=>{ controller.set('chronicError', error); })
    // },
    actions: {
      refreshModel: function () {
        this.refresh();
      } //

    }
  });

  _exports.default = _default;
});
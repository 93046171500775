define("hmis/controllers/hmis-app/facility-management/facility/services", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller"], function (_exports, _modelTableTheme, _formController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, {
    notify: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    selectedItems: [],
    allowSelect: false,
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    selectedCount: Ember.computed("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    isToday: true,
    fromDate: new Date(),
    toDate: new Date(),
    tabIndex: 0,
    selectedPatient: '',
    vitalsSuccessAction: 'empty',
    onConfirm: 'deleteRecord',
    visit: null,
    departmentVal: null,

    init() {
      this._super(...arguments);
    },

    actions: {
      back() {
        history.back();
      },

      addService() {
        this.set('departmentVal', null);
        this.set('editService', {});
        this.set('serviceDialog', true);
      },

      editService(record) {
        this.get('store').findRecord('department-unit', record.get('departmentUnit.id')).then(d => {
          this.set('departmentVal', d.department);
        });
        this.set('editService', record);
        this.set('serviceDialog', true);
      },

      onSaved(record) {
        this.send('refreshModel');
        this.notify.success('Saved Successfully');
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to archive item');
        this.set('onAlert', true);
      },

      deleteNow(recordTag) {
        // this.get("selectedItems").removeObject(recordTag);
        recordTag.destroyRecord();
        this.send('refreshModel'); //reload stats data
        // setTimeout(
        //   this.get('ajaxRequest').makeRequest('POST', "/dashboardStatistics/day", {}, {
        //     success: (response)=>{
        //       this.set('app.dashboardStats', response)
        //       console.log('archive donfirmed: data auto load')
        //     },
        //     error:(error)=>{
        //       console.log('data auto load error')
        //     }
        //   })
        // , 10000)
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    serviceColumns: [{
      'title': 'Sevice',
      "propertyName": "serviceTypeName",
      'className': 'main-column'
    }, {
      'title': 'Department',
      "propertyName": "departmentName",
      'className': 'main-column'
    }, {
      'title': 'Department Unit',
      "propertyName": "departmentUnitName",
      'className': 'main-column'
    }, {
      "title": "Actions",
      "component": "manageRow",
      'className': 'main-column'
    }]
  });

  _exports.default = _default;
});
define("hmis/templates/hmis-app/facility-management/facility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "T7umjYQs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-mini-menu\"],[8],[0,\"\\n\"],[4,\"mini-app/side-menu\",null,[[\"class\"],[\"overflow-y full-height\"]],{\"statements\":[[0,\"        \"],[1,[28,\"mini-app/side-menu-item-heading\",null,[[\"title\",\"fa_icon\"],[[24,[\"model\",\"facilityName\"]],\"fa-home\"]]],false],[0,\"\\n\"],[0,\"        \"],[1,[28,\"mini-app/side-menu-item\",null,[[\"title\",\"route\",\"onMenuClick\"],[\"General\",\"hmis-app.facility-management.facility.general\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n\"],[0,\"        \"],[1,[28,\"mini-app/side-menu-item\",null,[[\"title\",\"route\",\"onMenuClick\"],[\"Services\",\"hmis-app.facility-management.facility.services\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n\"],[0,\"        \"],[1,[28,\"mini-app/side-menu-item\",null,[[\"title\",\"route\",\"onMenuClick\"],[\"Specialists\",\"hmis-app.facility-management.facility.specialist\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n\"],[0,\"        \"],[1,[28,\"mini-app/side-menu-item\",null,[[\"title\",\"route\",\"onMenuClick\"],[\"Payment Options\",\"hmis-app.facility-management.facility.payment\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n        \"],[1,[28,\"mini-app/side-menu-item\",null,[[\"title\",\"route\",\"onMenuClick\"],[\"Accreditations\",\"hmis-app.facility-management.facility.accreditation\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/facility-management/facility.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/constants/visit-statuses", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VISIT_STATUSES = void 0;
  const VISIT_STATUSES = {
    ACTIVE: 1,
    PENDING_ADMISSION: 2,
    ADMITTED: 3,
    PENDING_DISCHARGE: 4,
    DISCHARGED: 5,
    DORMANT: 6,
    //for ended dorman visits
    PENDING_SERVE: 7
  };
  _exports.VISIT_STATUSES = VISIT_STATUSES;
});
define("hmis/routes/hmis-app/transactions/visit", ["exports", "hmis/utils/general-util", "hmis/mixins/route-error-handler"], function (_exports, _generalUtil, _routeErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, {
    setupController(controller, model) {
      this._super(...arguments);

      controller.set('selectedTransactions', []);
    },

    model(params) {
      return (0, _generalUtil.modelHash)({
        visit: this.get('store').query('visit', {
          filter: {
            id: params.record_id
          },
          include: ['purposeName', 'patientName', 'patientDateOfBirth', 'patientGender', 'patientPhysicalAddress', 'outstandingBills']
        }).then(res => res.get('firstObject')),
        transactions: this.get('store').query('transaction', {
          filter: {
            visitId: params.record_id
          }
        })
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      } //this.send('refreshModel')

    }
  });

  _exports.default = _default;
});
define("hmis/helpers/get-role-permission-prop", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getRolePermissionProp = getRolePermissionProp;
  _exports.default = void 0;

  function getRolePermissionProp([role, permissionId, prop]) {
    // console.log(permissionId, role.get('roleOperationPermissions'))
    return DS.PromiseObject.create({
      promise: role.get('roleOperationPermissions').then(permissions => {
        let rolePerm = permissions.findBy('permissionId', permissionId);

        if (rolePerm) {
          console.log(prop, rolePerm.get(prop));
          return rolePerm.get(prop);
        } else {
          return false;
        }
      })
    });
  }

  var _default = Ember.Helper.helper(getRolePermissionProp);

  _exports.default = _default;
});
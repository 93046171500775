define("hmis/routes/hmis-app/opd/consultation/consult/vital-requests", ["exports", "hmis/utils/patient-serve-util", "hmis/mixins/route-error-handler", "hmis/utils/general-util", "hmis/mixins/visit-care-route"], function (_exports, _patientServeUtil, _routeErrorHandler, _generalUtil, _visitCareRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, _visitCareRoute.default, {
    breadCrumb: {
      title: 'Examination Request'
    },
    parentCareRoute: 'hmis-app.opd.consultation.consult',
    model: function (params) {
      let model = this.modelFor("hmis-app.opd.consultation.consult");
      let visitId = model.visitId;
      let newModel = (0, _patientServeUtil.cloneServeModel)(model);
      newModel['requestData'] = this.get('store').query('vitals-request', {
        filter: {
          served: 0,
          visitId: visitId
        }
      });
      newModel['resultData'] = this.get('store').query('vitals-request', {
        filter: {
          served: 1,
          visitId: visitId
        },
        include: ['vitals']
      });
      return (0, _generalUtil.modelHash)(newModel);
    },
    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
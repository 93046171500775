define("hmis/templates/hmis-app/admin-settings/vitals-type/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/D6f/0lv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n  \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Cancel\",[28,\"action\",[[23,0,[]],\"back\"],null],\"close\"]]],false],[0,\"\\n  \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Save\",[28,\"action\",[[23,0,[]],\"save\"],null],\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height\",true,\"Add Vitals Type\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[1,[28,\"managers/vitals-type-manager\",null,[[\"record\",\"class\",\"type\"],[[24,[\"model\"]],\"full-width\",\"add\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/admin-settings/vitals-type/add.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/procedures/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      back() {
        history.back();
      },

      save() {
        this.get("model").save().then(record => {
          alert("Saved");
        }).catch(failure => {
          alert("Unable to saved");
        });
      }

    }
  });

  _exports.default = _default;
});
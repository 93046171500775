define("hmis/components/hmis-side-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    currentUser: Ember.inject.service(),
    isActive: false,
    isSubShowing: false,
    currentPathChanged: Ember.observer('currentPathHook', 'module', function () {
      let routePath = this.get('module.route');

      if (this.get('currentPathHook').includes(routePath)) {
        if (routePath == 'procedures' && this.get('currentPathHook') != 'hmis-app.procedures.index') {
          this.set('isActive', false);
        } else {
          this.set('isActive', true);
        }

        if (this.get('module.hasChildren')) this.set('isSubShowing', true);
      } else {
        this.set('isActive', false);
        if (this.get('isSubShowing')) this.set('isSubShowing', false);
      }
    }),
    // show:computed('module', 'module.perm','module.childMenus', function(){
    //   // console.log('currentUser',this.get('currentUser'))
    //     // if(this.get('module.id')=='1'){
    //     //     return true;
    //     // }
    //     // if(this.get('module.id')=='13' && this.get('currentUser.accessLevel')==2){
    //     //   //this.set('module.name','Registered Facility')
    //     //   //this.set('module.name','Registered Facility')
    //     //   return 'Registered Facility'
    //     // }
    //     if(this.get('currentUser.isSetup')){
    //       return true;
    //     }
    //     if(!this.get('module.levels'))
    //     {
    //       console.log(this.get('module'))
    //     }
    //     if(!this.get('module.levels').includes(this.get('currentUser.accessLevel'))){
    //       return false;
    //     }
    //     if(!this.get('module.hasChildren'))
    //     {
    //         return this.get('currentUser.modularPermissions.'+this.get('module.perm')+'.allow')
    //     }else{
    //         let p = false
    //             this.get('module.childMenus').forEach(child => {
    //                 if(this.get('currentUser.modularPermissions.'+child.perm+'.allow'))
    //                     p=true;
    //             });
    //         return p
    //     }
    // }),
    actions: {
      onClick(event) {
        if (this.get('module.hasChildren')) {
          this.send('onArrowClick', event);
        } else if (this.get('onMenuClick')) {
          this.get('onMenuClick')(this.get('module.route'));
        }
      },

      onArrowClick(event) {
        this.set("isSubShowing", !this.get("isSubShowing"));
        event.stopPropagation();
      }

    }
  });

  _exports.default = _default;
});
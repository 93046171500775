define("hmis/mixins/AdapterOverride", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    ajax(url, type, options) {
      let adapter = this;
      let useFetch = this.get('useFetch');
      let requestData = {
        url: url,
        method: type
      };
      let hash = adapter.ajaxOptions(url, type, options);

      if (useFetch) {
        return this._fetchRequest(hash).then(response => {
          return RSVP.hash({
            response,
            payload: determineBodyPromise(response, requestData)
          });
        }).then(({
          response,
          payload
        }) => {
          if (response.ok) {
            return fetchSuccessHandler(adapter, payload, response, requestData);
          } else {
            throw fetchErrorHandler(adapter, payload, response, null, requestData);
          }
        });
      }

      return new Ember.RSVP.Promise(function (resolve, reject) {
        hash.success = function (payload, textStatus, jqXHR) {
          let response = ajaxSuccessHandler(adapter, payload, jqXHR, requestData);
          run.join(null, resolve, response);
        };

        hash.error = function (jqXHR, textStatus, errorThrown) {
          let error = ajaxErrorHandler(adapter, jqXHR, errorThrown, requestData);
          run.join(null, reject, error);
        };

        adapter._ajax(hash);
      }, 'DS: RESTAdapter#ajax ' + type + ' to ' + url);
    }

  });

  _exports.default = _default;

  function ajaxSuccess(adapter, payload, requestData, responseData) {
    let response;

    try {
      response = adapter.handleResponse(responseData.status, responseData.headers, payload, requestData);
    } catch (error) {
      return Ember.RSVP.Promise.reject(error);
    }

    if (response && response.isAdapterError) {
      return Ember.RSVP.Promise.reject(response);
    } else {
      return response;
    }
  }

  function ajaxError(adapter, payload, requestData, responseData) {
    if (DEBUG) {
      let message = "The server returned an empty string for ".concat(requestData.method, " ").concat(requestData.url, ", which cannot be parsed into a valid JSON. Return either null or {}.");
      let validJSONString = !(responseData.textStatus === 'parsererror' && payload === '');
      warn(message, validJSONString, {
        id: 'ds.adapter.returned-empty-string-as-JSON'
      });
    }

    let error;

    if (responseData.errorThrown instanceof Error) {
      error = responseData.errorThrown;
    } else if (responseData.textStatus === 'timeout') {
      error = new TimeoutError();
    } else if (responseData.textStatus === 'abort' || responseData.status === 0) {
      error = handleAbort(requestData, responseData);
    } else {
      try {
        error = adapter.handleResponse(responseData.status, responseData.headers, payload || responseData.errorThrown, requestData);
      } catch (e) {
        error = e;
      }
    }

    return error;
  } // Adapter abort error to include any relevent info, e.g. request/response:


  function handleAbort(requestData, responseData) {
    let {
      method,
      url,
      errorThrown
    } = requestData;
    let {
      status
    } = responseData;
    let msg = "Request failed: ".concat(method, " ").concat(url, " ").concat(errorThrown || '');
    let errors = [{
      title: 'Adapter Error',
      detail: msg.trim(),
      status
    }];
    return new AbortError(errors);
  } //From http://stackoverflow.com/questions/280634/endswith-in-javascript


  function endsWith(string, suffix) {
    if (typeof String.prototype.endsWith !== 'function') {
      return string.indexOf(suffix, string.length - suffix.length) !== -1;
    } else {
      return string.endsWith(suffix);
    }
  }

  function fetchSuccessHandler(adapter, payload, response, requestData) {
    let responseData = fetchResponseData(response);
    return ajaxSuccess(adapter, payload, requestData, responseData);
  }

  function fetchErrorHandler(adapter, payload, response, errorThrown, requestData) {
    let responseData = fetchResponseData(response);
    responseData.errorThrown = errorThrown;
    return ajaxError(adapter, payload, requestData, responseData);
  }

  function ajaxSuccessHandler(adapter, payload, jqXHR, requestData) {
    let responseData = ajaxResponseData(jqXHR);
    return ajaxSuccess(adapter, payload, requestData, responseData);
  }

  function ajaxErrorHandler(adapter, jqXHR, errorThrown, requestData) {
    let responseData = ajaxResponseData(jqXHR);
    responseData.errorThrown = errorThrown;
    let payload = adapter.parseErrorResponse(jqXHR.responseText);
    return ajaxError(adapter, payload, requestData, responseData);
  }

  function fetchResponseData(response) {
    return {
      status: response.status,
      textStatus: response.textStatus,
      headers: headersToObject(response.headers)
    };
  }

  function ajaxResponseData(jqXHR) {
    return {
      status: jqXHR.status,
      textStatus: jqXHR.statusText,
      headers: parseResponseHeaders(jqXHR.getAllResponseHeaders())
    };
  }

  function headersToObject(headers) {
    let headersObject = {};

    if (headers) {
      headers.forEach((value, key) => headersObject[key] = value);
    }

    return headersObject;
  }
});
define("hmis/models/user-accreditation", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: (0, _relationships.belongsTo)('user'),
    accreditationType: (0, _relationships.belongsTo)('accreditation-type'),
    accreditationNumber: _emberData.default.attr(),
    issueDate: _emberData.default.attr(),
    expiryDate: _emberData.default.attr(),
    accreditationFee: _emberData.default.attr(),
    certificateLocation: _emberData.default.attr(),
    accreditionTypeName: _emberData.default.attr(),
    accreditationInstitutionName: _emberData.default.attr(),

    downloadFile() {
      this.get('ajaxRequest').downloadFile(this.get('fileUrl'), this.get('name'));
    }

  });

  _exports.default = _default;
});
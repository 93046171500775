define("hmis/routes/hmis-app/admin-settings/generic/type-record/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      console.log(params);
      console.log("Params: ", params.record_type);
      return params;
    }

  });

  _exports.default = _default;
});
define("hmis/components/forms/occupation-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    modelName: 'patient-occupation',
    empStatus: ['Employed', 'Self-employed', 'Unemployed'],
    empContract: ['Contract', 'Permanent'],
    empPosition: ['Workers', 'Middle Managers', 'Senior Managers', 'Executives'],
    actions: {}
  });

  _exports.default = _default;
});
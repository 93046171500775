define("hmis/models/ultrasound", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/visit-service-model", "hmis/mixins/billable-model", "hmis/mixins/visit-request-model "], function (_exports, _emberData, _relationships, _dateUtil, _visitServiceModel, _billableModel, _visitRequestModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, _visitRequestModel.default, _billableModel.default, {
    ultrasoundType: (0, _relationships.belongsTo)('ultrasound-type', {
      async: false
    }),
    source: _emberData.default.attr(),
    status: _emberData.default.attr(),
    requestNote: _emberData.default.attr(),
    resultNote: _emberData.default.attr(),
    ultrasoundTypeName: _emberData.default.attr(),
    ultrasoundTypeGdrgCode: _emberData.default.attr(),
    documents: (0, _relationships.hasMany)('ultrasound-document'),
    documentsString: Ember.computed('documents', 'documents.@each.name', function () {
      let vitals = this.get('documents');
      let vs = '';
      let i = 0;
      vitals.forEach(function (vt) {
        if (i != 0) vs += ", ";
        vs += vt.get('name');
        i++;
      });
      return vs;
    })
  });

  _exports.default = _default;
});
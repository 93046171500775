define("hmis/components/classic/c-button", ["exports", "hmis/mixins/classic-component"], function (_exports, _classicComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_classicComponent.default, {
    className: 'c-button',
    type: "button",
    actions: {
      onClick() {
        if (this.onClick) this.onClick();
      }

    }
  });

  _exports.default = _default;
});
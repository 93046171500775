define("hmis/templates/hmis-app/users/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9shPqktz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-mini-menu\"],[8],[0,\"\\n\"],[4,\"mini-app/side-menu\",null,[[\"class\"],[\"overflow-y full-height\"]],{\"statements\":[[0,\"        \"],[1,[28,\"mini-app/side-menu-item-heading\",null,[[\"title\",\"fa_icon\"],[[24,[\"model\",\"fullName\"]],\"fa-home\"]]],false],[0,\"\\n        \"],[1,[28,\"mini-app/side-menu-item\",null,[[\"title\",\"route\",\"onMenuClick\"],[\"Profile\",\"hmis-app.users.user.profile\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n        \"],[1,[28,\"mini-app/side-menu-item\",null,[[\"title\",\"route\",\"onMenuClick\"],[\"Specialties\",\"hmis-app.users.user.specialty\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n        \"],[1,[28,\"mini-app/side-menu-item\",null,[[\"title\",\"route\",\"onMenuClick\"],[\"Payment Options\",\"hmis-app.users.user.payment\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n        \"],[1,[28,\"mini-app/side-menu-item\",null,[[\"title\",\"route\",\"onMenuClick\"],[\"Accreditations\",\"hmis-app.users.user.accreditation\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"],[1,[22,\"outlet\"],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/users/user.hbs"
    }
  });

  _exports.default = _default;
});
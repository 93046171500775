define("hmis/models/facility", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    serverUploads: Ember.inject.service(),
    facilityType: (0, _relationships.belongsTo)('facility-type'),
    // facilityLevelCode:belongsTo('facility-level'),
    typeName: _emberData.default.attr(),
    facilityLevelCode: _emberData.default.attr(),
    facilityName: _emberData.default.attr(),
    postAddress: _emberData.default.attr(),
    physicalAddress: _emberData.default.attr(),
    contactEmail: _emberData.default.attr(),
    phoneNumber: _emberData.default.attr(),
    alternativePhoneNumber: _emberData.default.attr(),
    contactPerson1Name: _emberData.default.attr(),
    contactPerson1Email: _emberData.default.attr(),
    contactPerson1PhoneNumber: _emberData.default.attr(),
    contactPerson1AlternativePhoneNumber: _emberData.default.attr(),
    contactPerson2Name: _emberData.default.attr(),
    contactPerson2Email: _emberData.default.attr(),
    contactPerson2PhoneNumber: _emberData.default.attr(),
    contactPerson2AlternativePhoneNumber: _emberData.default.attr(),
    contactPerson3Name: _emberData.default.attr(),
    contactPerson3Email: _emberData.default.attr(),
    contactPerson3PhoneNumber: _emberData.default.attr(),
    contactPerson3AlternativePhoneNumber: _emberData.default.attr(),
    tin: _emberData.default.attr(),
    gps: _emberData.default.attr(),
    // servicesAvailable:hasMany(),
    numberOfBeds: _emberData.default.attr(),
    insurances: (0, _relationships.hasMany)('facility-insurance'),
    // professions:hasMany('facility-profession',{ inverse: 'facility' }),
    picture: _emberData.default.attr(),
    region: _emberData.default.attr(),
    town: _emberData.default.attr(),
    accreditationNumber: _emberData.default.attr(),
    //denomination:belongsTo('denomination'),
    configured: _emberData.default.attr(),
    deleteStatus: _emberData.default.attr(),
    servicesOffered: (0, _relationships.hasMany)('offered-service'),
    professions: (0, _relationships.hasMany)('profession-type'),
    // typeName: computed('facilityType.name', function() {
    //     return `${this.get('facilityType.name')}`;
    // }),
    pictureUrl: Ember.computed('picture', function () {
      return this.get('serverUploads.downloadUrl') + '/' + this.get('picture');
    })
  });

  _exports.default = _default;
});
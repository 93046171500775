define("hmis/routes/configure/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),

    model() {
      return this.get('store').findRecord('facility', this.get('currentUser.facilityId'));
    }

  });

  _exports.default = _default;
});
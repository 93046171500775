define("hmis/utils/request-utils/ipd", ["exports", "jquery", "hmis/config/environment", "hmis/utils/server-util"], function (_exports, _jquery, _environment, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.requestAdmission = requestAdmission;
  _exports.requestDischarge = requestDischarge;
  _exports.admitPatient = admitPatient;
  _exports.dischargePatient = dischargePatient;
  _exports.rejectPatient = rejectPatient;
  _exports.default = void 0;
  const url = _serverUtil.serverUrl;

  function requestAdmission(visitId, note, callBack) {
    // alert(visitId+" - "+note)
    // console.log(note)
    _jquery.default.post(url + "/requestAdmission", {
      'visit': visitId,
      'admissionNote': note
    }).then(response => {
      if (callBack) callBack.success(response);
    }, failure => {
      if (callBack) callBack.failure(failure);
      console.log("Unable to sent admit Patient request: ", failure);
    });
  }

  function requestDischarge(visitId, outcomeId, note, callBack) {
    _jquery.default.post(url + "/requestDischarge", {
      'visit': visitId,
      'outcome': outcomeId,
      'note': note
    }).then(response => {
      if (callBack) callBack.success(response);
    }, failure => {
      if (callBack) callBack.failure(failure);
      console.log("Unable to sent discharge Patient request: ", failure);
    });
  }

  function admitPatient(admittedQueuedPatientIds, departmentUnit, callBack) {
    // alert(visitId+" - "+note)
    // callBack.success('hii')
    _jquery.default.post(url + "/admitPatient", {
      ids: admittedQueuedPatientIds,
      ward: departmentUnit
    }).then(response => {
      if (callBack) callBack.success(response);
    }, failure => {
      if (callBack) callBack.failure(failure);
      console.log("Unable to sent admitPatient request: ", failure);
    });
  }

  function dischargePatient(visitId, note) {
    alert(visitId + " - " + note); // Ember.$.post(url+"/admitPatient", { id: queuedPatient.id })
    // .then(
    //   (response) => {
    //     this.sendAction("select", response);
    //   },
    //   (failure)=>{
    //     console.log("Unable to sent admitPatient request: ",failure)
    //   }
    // );
  }

  function rejectPatient(queuedPatient) {// Ember.$.post(url+"/rejectAdmitPatientrequest", { id: queuedPatient.id })
    //   .then(
    //     (response) => {
    //       this.sendAction("select", response);
    //     },
    //     (failure)=>{
    //       console.log("Unable to sent admitPatient request: ",failure)
    //     }
    //   );
  }

  var _default = {
    requestAdmission,
    requestDischarge,
    admitPatient,
    dischargePatient,
    rejectPatient
  };
  _exports.default = _default;
});
define("hmis/routes/hmis-app/reports/general/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({// store: Ember.inject.service('store'),
    // model() {
    //   return RSVP.hash({
    //     request: this.get('store').query('prescription-batch', {
    //       filter: {
    //         dispensed: 0
    //       }
    //     })
    //   });
    // },
    //
    // actions: {
    //   reloadModel: function() {
    //     this.refresh()
    //   }
    // }
  });

  _exports.default = _default;
});
define("hmis/models/procedure-document", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/visit-service-model"], function (_exports, _emberData, _relationships, _dateUtil, _visitServiceModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, {
    procedure: (0, _relationships.belongsTo)('procedure', {
      async: false
    }),
    procedureId: _emberData.default.attr(),
    name: _emberData.default.attr(),
    location: _emberData.default.attr(),
    type: _emberData.default.attr(),
    date: _emberData.default.attr(),
    dateF: Ember.computed('date', function () {
      // return `${this.get('firstName')} ${this.get('lastName')}`;
      return _dateUtil.default.formatToDateTimeString(this.get('date'));
    }),
    ajaxRequest: Ember.inject.service(),
    serverUploads: Ember.inject.service(),
    fileUrl: Ember.computed('location', function () {
      return this.get('serverUploads.downloadUrl') + '/' + this.get('location');
    }),

    downloadFile() {
      this.get('ajaxRequest').downloadFile(this.get('fileUrl'), this.get('name'));
    }

  });

  _exports.default = _default;
});
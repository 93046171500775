define("hmis/controllers/hmis-app/admin-settings/questionnaire/q-new", ["exports", "hmis/utils/questionnaire"], function (_exports, _questionnaire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    newItem: {},
    editQuest: {},
    showTemplatePicker: false,
    notify: Ember.inject.service(),
    actions: {
      onSelectTemplate(questionnaire) {
        this.set('editQuest', questionnaire[0].toStructure());
        this.set('editQuest.title', this.get('newItem.title'));
        this.set('editQuest.description', this.get('newItem.description'));
        this.set('showTemplatePicker', false);
        this.send('onChange');
      },

      onSelectTemplateCancel() {
        this.set('editQuest', (0, _questionnaire.newQuestionnaireStructure)(this.get('newItem.title'), this.get('newItem.description')));
        this.get('editQuest.items').pushObject((0, _questionnaire.newQuestion)(this.get('editQuest')));
        this.set('showTemplatePicker', false);
      },

      onChange() {
        this.set('hasChange', true);
      },

      doneForm() {
        this.set('showForm', false);
        this.set('showTemplatePicker', true);
      },

      onSave() {
        let quest = this.store.createRecord('questionnaire', {
          status: this.get('status')
        });
        quest.saveStructure(this.get('editQuest'), () => {
          this.set('waiting', false);
          this.get('notify').success("Saved");
          this.send('back');
        }, () => {
          this.set('waiting', false);
          this.get('notify').error("Failed to save");
        });
      },

      onSaveAs(status) {
        if (status == 'published') {
          this.get('notify').confirm("Attempting to publish after saving", null, () => {
            this.set('status', status);
            this.send('onSave');
          });
        } else {
          this.set('status', status);
          this.send('onSave');
        }
      },

      back() {
        history.back();
      }

    }
  });

  _exports.default = _default;
});
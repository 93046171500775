define("hmis/models/permission-group", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    description: _emberData.default.attr(),
    active: _emberData.default.attr(),
    route: _emberData.default.attr(),
    isChildMenu: _emberData.default.attr(),
    parentMenu: _emberData.default.belongsTo('permission-group', {
      inverse: 'childMenus'
    }),
    icon: _emberData.default.attr(),
    relatedOperations: (0, _relationships.hasMany)('operation-permission'),
    childMenus: (0, _relationships.hasMany)('permission-group', {
      inverse: 'parentMenu'
    })
  });

  _exports.default = _default;
});
define("hmis/services/current-user", ["exports", "hmis/utils/server-util", "hmis/utils/is-perm-true-util"], function (_exports, _serverUtil, _isPermTrueUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const client = feathers();

  var _default = Ember.Service.extend(Ember.Evented, {
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    appData: Ember.inject.service(),
    notify: Ember.inject.service(),
    notifications: [],
    router: Ember.inject.service(),
    operationalPermissions: {},
    unreadNotifications: Ember.computed('notifications', 'notifications.@each.read', function () {
      return this.notifications.filter(item => item.read == 0);
    }),

    load() {
      let userId = this.get('session.data.authenticated.user_id');
      this.set('facilityName', this.get('session.data.authenticated.facility'));
      this.set('systemPassword', this.get('session.data.authenticated.system_password'));
      this.set('accessLevel', this.get('session.data.authenticated.access_level'));
      this.set('facilityId', this.get('session.data.authenticated.facility_id'));
      this.set('modularPermissions', this.get('session.data.authenticated.module_permissions'));
      this.set('operationalPermissions', this.get('session.data.authenticated.operation_permissions')); // this.set('operationalPermissions', this.get('session.data.authenticated.operation_permissions'))

      this.set('isSetup', this.get('session.data.authenticated.setup_user'));

      if (!Ember.isEmpty(userId)) {
        return this.get('store').findRecord('user', userId).then(user => {
          this.set('user', user); // this.startNotification()// to be called in hmis-app route

          this.fetchNotifications().then(res => {
            let unread = res.filter(item => item.read == 0).length;

            if (unread) {
              let noti = this.notify.info('You have ' + unread + " unread notification" + (unread > 1 ? 's' : ''), () => {
                this.notify.removeNotification(noti);
                this.get("router").transitionTo('hmis-app.notifications');
              });
            }
          });
          return user;
        });
      } else {
        console.error('userId id empty');
        return Ember.RSVP.resolve();
      }
    },

    startNotification() {
      const socket = io(_serverUtil.notificationURL, {
        query: {
          userId: this.user.id,
          facilityId: this.user.facility,
          permissions: this.getNotificationPermisions()
        }
      });
      client.configure(feathers.socketio(socket));
      client.configure(feathers.authentication());
      client.service('ehmis-user-notifications').on('message', data => {
        // console.log('data from noti serve', data);
        if (data.action != 'muted') this.notify.info('new notification: ' + data.message);
        this.fetchNotifications(); // var evt = new CustomEvent('user-notification', { detail:data });
        // window.dispatchEvent(evt);
        // console.log('new user noti-------------');

        this.trigger('user-notification', data);
      });
      client.service('ehmis-permission-notifications').on('message', data => {
        if (data.action != 'muted') this.notify.warning(data.message + ': ' + data.description); // var evt = new CustomEvent('permission-notification', { detail:data });
        // window.dispatchEvent(evt);
        // console.log('new perm noti-------------');

        this.trigger('permission-notification', data);
      });
      this.notificationServerLogin();
    },

    async notificationServerLogin() {
      try {
        // First try to log in with an existing JWT
        return await client.reAuthenticate();
      } catch (error) {
        // If that errors, log in with email/password
        // Here we would normally show a login page
        // to get the login information
        return await client.authenticate({
          strategy: 'local',
          email: 'hello@feathersjs.com',
          password: 'supersecret'
        });
      }
    },

    fetchNotifications() {
      return this.appAjax.getArray("/notifications").then(res => {
        this.set('notifications', res);
        return res;
      });
    },

    getNotificationPermisions() {
      let arr = [];
      Object.keys(this.operationalPermissions).forEach(permId => {
        let permNumber = permId.replace('op', '');
        let perm = this.operationalPermissions[permId];
        if ((0, _isPermTrueUtil.default)(perm['allow']) && this.appData.operationPermissions[permNumber].notifiable) arr.push(permId);
      }); // Object.keys(this.modularPermissions).forEach(permId => {
      //     let permNumber = permId.replace('mp','')
      //     let perm = this.modularPermissions[permId]
      //     if(isPermTrueUtil(perm['allow']) && this.appData.modulePermissions[Number(permNumber)].notifiable)
      //         arr.push(permId);
      // });
      // console.log('listening to ', arr);

      return arr;
    }

  });

  _exports.default = _default;
});
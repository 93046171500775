define("hmis/controllers/hmis-app/users/user/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    notify: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),

    init() {
      this._super(...arguments);

      console.log('model.specialties', this.get('model'));
    },

    actions: {
      back() {
        history.back();
      },

      onSaved() {
        this.send('refreshModel');
      },

      resetPassword() {
        this.set('resetingPassword', true);
        this.get('ajaxRequest').resetPassword(this.get('model.id'), {
          success: () => {
            this.notify.success('Password reset successful');
            this.set('resetingPassword', false);
          },
          error: err => {
            this.set('resetingPassword', false);
            this.notify.error(err);
          }
        });
      },

      edit() {
        var record = this.get("model");
        this.set('editRecord', record);
        this.set('editRecordDialog', true);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/dropdown-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    ddStyle: Ember.computed('left', 'right', 'width', function () {
      // if(!this.get('left') && !this.get('right'))
      //     this.set('right', '70%')
      // if(!this.get('width'))
      //     this.set('width', '220px%')
      let left = this.get('left');
      let right = this.get('right');
      let width = this.get('width');
      if (!right && !left) right = '70%';
      if (!width) width = '220px';
      return "\n            ".concat(left ? 'left:' + left + '; ' : 'right:' + right, "\n            ").concat(width ? '; width:' + width : '', "\n        ").htmlSafe();
    }) // ddStyle:function(){
    //     let left = this.get('left')
    //     let right = this.get('right')
    //     let width = this.get('width')
    //     return (`
    //         ${left?'left:'+left+'; ': 'right:'+right}
    //         ${width?'; width:'+width:''}
    //     `).htmlSafe()
    // }.property('left', 'right', 'width'),

  });

  _exports.default = _default;
});
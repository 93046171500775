define("hmis/templates/components/managers/vitals-taker-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FclIg3pK",
    "block": "{\"symbols\":[\"mt\"],\"statements\":[[4,\"alerts/confirm-dialog\",null,[[\"show\",\"title\",\"message\",\"confirmText\",\"waiting\",\"width\",\"height\",\"confirmDisabled\",\"closeOnConfirm\",\"onConfirm\",\"onCancel\",\"footerInfo\"],[[24,[\"_show\"]],\"Take vitals\",[24,[\"visit\",\"patientName\"]],\"Save Vitals\",[24,[\"waiting\"]],\"80%\",\"80%\",false,false,[28,\"action\",[[23,0,[]],\"onConfirm\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null],\"\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\" stats-container full-height has-main-table\"],[8],[0,\"\\n      \"],[7,\"h4\",true],[10,\"style\",\"width: 100%;text-align: center;text-transform: uppercase !important;\"],[8],[0,\"BMI: \"],[1,[22,\"bmi\"],false],[9],[0,\"\\n\"],[4,\"controls/models-table\",null,[[\"tagName\",\"showGlobalFilter\",\"data\",\"filterString\",\"themeInstance\",\"filteringIgnoreCase\",\"useFilteringByColumns\",\"selectedItems\",\"multipleSelect\",\"useFilteringByColumns\",\"filteringIgnoreCase\",\"selectRowOnClick\",\"columnComponents\",\"columns\"],[\"\",false,[24,[\"vitalsData\"]],[24,[\"query\"]],[24,[\"themeInstance\"]],true,false,[24,[\"selectedItems\"]],true,false,true,false,[28,\"hash\",null,[[\"checkBox\",\"tableEditable\"],[[28,\"component\",[\"controls/table-check\"],[[\"disabled\"],[[28,\"if\",[[24,[\"isEdit\"]],false,true],null]]]],[28,\"component\",[\"controls/table-editable\"],[[\"isEdit\"],[true]]]]]],[24,[\"columns\"]]]],{\"statements\":[[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n                    \"],[1,[23,1,[\"table\"]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/vitals-taker-alert.hbs"
    }
  });

  _exports.default = _default;
});
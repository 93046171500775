define("hmis/controllers/hmis-app/admin-settings/insurance-companies/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    query: '',
    actions: {
      newRecord() {
        this.transitionToRoute("hmis-app.admin-settings.insurance-companies.add");
      },

      deleteRecord(record) {
        // this.set("allowSelect",false)
        this.get("selectedItems").removeObject(record);
        record.destroyRecord();
      },

      editRecord(record) {
        // alert(record.id);
        this.get("routing").transitionTo("hmis-app.admin-settings.insurance-companies.edit", [record.id]);
      },

      rowDoubleClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.admin-settings.insurance-companies.view", [rid]);
      }

    }
  });

  _exports.default = _default;
});
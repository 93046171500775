define("hmis/controllers/hmis-app/claims/private", ["exports", "hmis/utils/download-util", "hmis/utils/model-table-theme", "hmis/utils/request-utils/bill"], function (_exports, _downloadUtil, _modelTableTheme, _bill) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      approveBill() {
        let that = this;
        let oldBill = this.get('model');
        this.get('store').findRecord('bill-status', 2).then(function (status) {
          that.get('store').createRecord('bill-copy', {
            visit: that.get('model.visit'),
            status: status
          }).save().then(function (newBillCopy) {
            that.set('model', newBillCopy);
          });
        });
      },

      payBill() {
        let that = this;
        let oldBill = this.get('model');
        this.get('store').findRecord('bill-status', 3).then(function (status) {
          that.get('store').createRecord('bill-copy', {
            visit: that.get('model.visit'),
            status: status
          }).save().then(function (newBillCopy) {
            that.notify.success('Payment successful');
            that.set('model', newBillCopy);
          });
        });
      },

      print() {
        _downloadUtil.default.printBill(this.get('model.id'));
      },

      exportToPdf() {
        _downloadUtil.default.exportBillToPDF(this.get('model.id'));
      },

      moveToBills() {
        let that = this;
        this.get('model').set('modeOfPayment', 'Cash');
        this.get('model').save().then(function (billCopy) {
          that.notify.success('Moved to bills');
          that.get("routing").transitionTo("hmis-app.bills.bill", [billCopy.get('id')]);
        });
      },

      alertEmail() {
        this.set('sendingMail', true);
        let patient = this.get('model').get('visit').get('patient');

        _bill.default.emailBill(patient.get('id'), {
          success: response => {
            this.notify.success('Email sent');
            this.set('sendingMail', false);
          },
          failure: error => {
            this.notify.error(error);
            this.set('sendingMail', false);
          }
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "propertyName": "index",
      "component": "controls/table-index"
    }, {
      "propertyName": "date"
    }, {
      "title": "description",
      "propertyName": "description"
    }, {
      "propertyName": "unitCost"
    }, {
      "propertyName": "quantity"
    }, {
      'title': 'Total cost',
      "propertyName": "costF"
    }],
    columns2: [{
      "propertyName": "index",
      "component": "controls/table-index"
    }, {
      "propertyName": "date"
    }, {
      "title": "description",
      "propertyName": "description"
    }, {
      'title': 'Cost',
      "propertyName": "costF"
    }]
  });

  _exports.default = _default;
});
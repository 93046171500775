define("hmis/controllers/hmis-app/users/add-user", ["exports", "hmis/mixins/form-controller"], function (_exports, _formController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, {
    notify: Ember.inject.service(),
    notifications: Ember.inject.service('notification-messages'),
    currentUser: Ember.inject.service(),
    app: Ember.inject.service(),
    serverResponse: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    actions: {
      back() {
        history.back();
      },

      save() {
        let modelInputs = this.get('model');
        this.set('isSaving', true); // this.set('model', this.get('store').createRecord('user', modelInputs))

        this.get("model").save().then(record => {
          this.set('isSaving', false); // this.set('model', {isSystemUser:true})

          this.set('model', this.store.createRecord('user', {
            isSystemUser: true
          }));
          console.log('retured model', record);

          if (record.get('emailFailed')) {
            this.notify.warning('Saved but Wasnt able to send email to user');
          } else {
            this.notify.success('Saved Successfully');
          }

          this.get('app').loadDashboardStats();
        }, failure => {
          this.set('isSaving', false);
          let errMessage = this.get('serverResponse').errorMessage(failure);
          this.notify.error(errMessage);
        });
      }

    }
  });

  _exports.default = _default;
});
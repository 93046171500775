define("hmis/templates/components/managers/insurance-product-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Oa1bcjm1",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"form-container\",null,[[\"class\"],[\"full-width\"]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\"],[[24,[\"flex_level\"]],[24,[\"record\",\"icname\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"icname\"]]],null]],null],\"Name\"]]],false],[0,\"\\n\\n\"],[4,\"app-select\",null,[[\"class\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"flex_level\"]],\"Insurance company\",[24,[\"record\"]],[24,[\"record\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"],[0,\"\\n    \"],[2,\"<div class=\\\"flex-row full-width\\\">\"],[0,\"\\n\\n\\n\"],[0,\"\\n        \"],[2,\"<div class={{input_width2}}>\"],[0,\"\\n            \"],[2,\"<button class=\\\"submit-button fi-margin-left\\\" onclick={{action \\\"addMedicine\\\"}}>Add medicine<i class=\\\"fa fa-plus-circle\\\"></i> </button>\"],[0,\"\\n        \"],[2,\"</div>\"],[0,\"\\n\\n    \"],[2,\"</div>\"],[0,\"\\n\\n\"],[0,\"\\n\\n\"],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/insurance-product-manager.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/routes/sign-in", ["exports", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //im
  var _default = Ember.Route.extend(_unauthenticatedRouteMixin.default, {
    currentUser: Ember.inject.service(),
    routeAfterAuthentication: 'configure' // routeAfterAuthentication: computed(function () {
    //
    //   let that = this
    //   let currentUser = that.get('currentUser.user')
    //   console.log('this is the current user:', currentUser)
    //   if(currentUser.get('setupUser')==1){
    //     console.log('Setup User Authentication')
    //     //that.transitionTo('configure',that.get('currentUser.user').belongsTo('facility').id());
    //     //that.transitionTo('configure');
    //     return 'configure';
    //   }else {
    //     console.log('After Authentication')
    //     return 'configure';
    //     //that.transitionTo('hmis-app');
    //   }
    //
    //   console.log('After Authentication')
    //   // let curUser = this.get('currentUser.user')
    //   // console.log(curUser)
    //   // console.log(curUser.setupUser)
    //   // if(curUser.get('setupUser')==1){
    //   //   //return 'configure';
    //   //  this.transitionTo('configure',1);
    //   // }else {
    //   //   return 'hmis-app/dashboard';
    //   // }
    //
    // })
    // model(){
    //   re
    // }

  });

  _exports.default = _default;
});
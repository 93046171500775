define("hmis/templates/components/viewers/patient-report-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "J1cful1E",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"style\",\"margin-left: 20px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-20\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"rounded-tools-group\"],[8],[0,\"\\n      \"],[7,\"button\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"isToday\"]],\"active\",\"\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"filterToday\"],null]],[8],[0,\"Today\"],[9],[0,\"\\n      \"],[7,\"button\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"isThisMonth\"]],\"active\",\"\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"filterThisMonth\"],null]],[8],[0,\"This Month\"],[9],[0,\"\\n      \"],[1,[28,\"controls/date-filter-dropdown\",null,[[\"fromDate\",\"toDate\",\"status\",\"onStatusChanged\",\"onSubmit\"],[[24,[\"fromDate\"]],[24,[\"toDate\"]],[24,[\"dateFilterStatus\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"dateFilterStatus\"]]],null]],null],[28,\"action\",[[23,0,[]],\"onDateFilterSubmit\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-20\"],[8],[0,\"\\n\"],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"style\",\"margin-top: 0px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"container\"],[10,\"style\",\"width: 100%;height: 100%\"],[8],[0,\"\\n    \"],[7,\"h3\",true],[10,\"class\",\"flash\"],[10,\"style\",\"color: #b1b1b1;margin-left: 20px;font-weight: bold\"],[8],[0,\"Report Loading...\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/viewers/patient-report-viewer.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/clock-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    },

    classNames: ['md-default-theme', 'md-input-has-value', 'clock-picker'],
    tagName: 'md-input-container',
    width: '100px',

    didInsertElement() {
      this._super(...arguments);

      let cInput = this.$('input')[0];
      var clockpicker = this.$('.clockpicker').clockpicker({
        // placement: 'top',
        // align: 'left',
        donetext: 'Done',
        afterDone: () => {
          this.send('onChange', cInput.value);
        }
      })[0];
    },

    actions: {
      onTimeChange(event) {
        let value = event.target ? event.target.value : event;
        this.send('onChange', value);
      },

      onChange(value) {
        let action = this.get('onChange');
        if (action) action(value);
      }

    }
  });

  _exports.default = _default;
});
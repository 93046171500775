define("hmis/controllers/hmis-app/admin-settings/insurance-management/insurance/product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    //   breadcrumbs:['Insurance Companies'],
    breadcrumbIndex: 0,
    billLoadIndex: 0,
    serverDownloads: Ember.inject.service(),
    serverUploads: Ember.inject.service(),
    notify: Ember.inject.service(),
    selectedBillable: {
      name: 'medicines',
      text: 'Medicines',
      item: 'prescription',
      itemName: 'genericName',
      billableItemName: 'medicine'
    },
    //groupSelectedBillable: {name: 'medicines', text:'Medicines', item:'medicine', itemName:'genericName' , billableItemName:'medicine'},
    groupSelectedBillable: {
      name: 'diagnoses',
      text: 'Major Diagnosis',
      item: 'majorDiagnosis',
      itemName: 'genericName',
      billableItemName: 'majorDiagnosis'
    },
    routing: Ember.inject.service('router'),
    insTitle: Ember.computed('model', function () {
      return this.get('model').insuranceCompanyName + ' :: ' + this.get('model').name;
    }),
    tabIndex: 0,
    actions: {
      deleteRecord(record) {
        record.destroyRecord();
      },

      saveNewRecord() {
        this.set('listWait', true);
        let newRecord = this.get('store').createRecord('insurance-company', this.get('newRecord'));
        newRecord.save().then(record => {
          // this.get('companies').pushObject(record)
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error('Unable to Save');
        });
      },

      saveRecord(record) {
        record.save();
      },

      onItemClick(record) {//   this.get("routing").transitionTo("hmis-app.admin-settings.insurance-management.insurance",record.id);
      },

      reviewThreshold(record) {
        console.log('record', record);
        console.log('ipdInfinite', record.ipdInfinite);
        console.log('opdInfinite', record.opdInfinite);
        this.set('alertModel', record); // this.set('ipdInfinite',record.ipdInfinite);
        // this.set('opdInfinite',record.opdInfinite);

        this.set('editThreshold', record);
        this.set('alertTitle', record.name);
        this.set('thresholdDialog', true);
      },

      editThreshold(record) {
        this.set('editThreshold', record);
        this.set('thresholdDialog', true);
      },

      onSaved(record) {//this.send('refreshModel')
      },

      onCompanyClick(company) {
        console.log('company', company);
        this.set('breadcrumbs', ['Insurance Companies']);
        this.set('selectedComapany', company);
        this.set('selectedProduct', null);
        this.get('breadcrumbs').pushObject(company.get('name') + ' products');
        this.set('breadcrumbIndex', 1);
      },

      onProductClick(product) {
        this.set('selectedProduct', product);
        this.get('breadcrumbs').pushObject(product.get('name') + ' billables');
        this.set('breadcrumbIndex', 2);
      },

      onProductClick(product) {
        this.set('selectedProduct', product);
        this.get('breadcrumbs').pushObject(product.get('name') + ' billables');
        this.set('breadcrumbIndex', 2);
      },

      showChooser(event) {
        this.set('show', true);
      },

      downloadExcel() {
        this.set('bulkDownloading', true); //downloadBillableItems(billableItemsName, insuranceProduct, withPrices

        this.get('serverDownloads').downloadBillableItems(this.get('selectedBillable.billableItemName') + 's', this.model.id, true, {
          success: response => {
            this.set('bulkDownloading', false); // this.send('reloadModel')

            this.notify.success(this.get('selectedBillable.text') + " Download Successful");
          },
          error: err => {
            this.set('bulkDownloading', false);
            this.notify.error("Unable to upload. Something went wrong: " + err);
            console.log(err);
          }
        });
      },

      uploadData(file) {
        this.set('show', false);
        let that = this;
        this.set('bulkUploading', true);
        this.get('serverUploads').uploadBillableItems(file, this.get('selectedBillable.billableItemName') + 's', this.get('selectedProduct.id'), true, {
          success: response => {
            this.set('bulkUploading', false);
            this.notify.success("Medicines Bulk Upload Successful");
            this.set('billLoadIndex', this.get('billLoadIndex') + 1);
          },
          error: err => {
            this.set('bulkUploading', false);
            console.log(err);
            this.notify.error(err);
            console.log(err);
          }
        });
      }

    }
  });

  _exports.default = _default;
});
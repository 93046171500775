define("hmis/templates/hmis-app/admin-settings/department-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xF/d3et0",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n  \\n  \"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/admin-settings/department-management.hbs"
    }
  });

  _exports.default = _default;
});
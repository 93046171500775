define("hmis/models/appointment", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/visit-service-model"], function (_exports, _emberData, _relationships, _dateUtil, _visitServiceModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.AppointmentStatus = void 0;
  const AppointmentStatus = {
    refered: 0,
    terminated: 1,
    served: 2
  };
  _exports.AppointmentStatus = AppointmentStatus;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, {
    currentUser: Ember.inject.service(),
    serverResponse: Ember.inject.service(),
    schedule: (0, _relationships.belongsTo)('schedule', {
      inverse: null,
      async: false
    }),
    purpose: (0, _relationships.belongsTo)('visit-purpose'),
    endDate: _emberData.default.attr(),
    status: _emberData.default.attr(),
    //const AppointmentStatus
    // dateCreated: DS.attr(),
    appointmentDate: _emberData.default.attr(),
    purposeName: _emberData.default.attr(),
    appointmentDateF: Ember.computed('appointmentDate', function () {
      return _dateUtil.default.formatToDateTimeString(this.appointmentDate);
    }) // dateCreatedF :computed('dateCreated', function() {
    //     return dateUtil.formatToDateTimeString(this.get('dateCreated'));
    // }),
    // end(status){
    //     this.set('endDate', dateUtil.toSqlDate(new Date()))
    //     this.set('status', status )
    //     this.save()
    // },
    // createActiveServe( callBack){
    // let record = this;
    // //check if already has a visit (thus a visit was created at OPD side when trying to add vitals for the appointment)
    //   if(record.get('visit.id'))
    //   {
    //     let visit = record.get('visit')
    //     // record.end(AppointmentStatus.served)
    //     record.newActiveServeFromVisit(visit, callBack)
    //   }else{
    //       //create a new visit if it doesnt
    //     this.get('store').createRecord('visit',{
    //       patient: record.get('patient'),
    //       date: dateUtil.toSqlDate(new Date()),
    //       purpose:record.get('purpose'),
    //     })
    //     .save().then(function(visit){
    //         //set the visit for this appointment to the visit created and save
    //         record.set('visit', visit)
    //         record.save()
    //         // //end the appointment
    //         // record.end(AppointmentStatus.served)
    //         //now we can create an activeServe with the visit
    //         record.newActiveServeFromVisit(visit, callBack)
    //         }
    //         , (error)=>{
    //           let err=this.get('serverResponse').errorMessage(error) ;
    //             callBack.failure(err)
    //             if(callBack.error)
    //               callBack.error(err)
    //         }
    //     )
    //   }
    // },
    // newActiveServeFromVisit(visit, callBack){
    //     this.get('store').createRecord('active-serve', {
    //         visit: visit,
    //         staff: this.get('currentUser.user'),
    //         startDate:dateUtil.toSqlDate(new Date()),
    //       }).save().then((activeServe)=>{
    //         this.end(AppointmentStatus.served)
    //           if(callBack.success)
    //                 callBack.success(activeServe)
    //       },
    //       (err)=>{
    //         let errMessage=this.get('serverResponse').errorMessage(err) ;
    //           if(callBack.failure)
    //             callBack.failure(errMessage)
    //           if(callBack.error)
    //             callBack.error(errMessage)
    //       }
    //     )
    // }

  });

  _exports.default = _default;
});
define("hmis/models/ward", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    service: (0, _relationships.belongsTo)('other-billable', {
      async: false
    }),
    type: _emberData.default.attr(),
    //1=lab, 2 = ward
    rooms: (0, _relationships.hasMany)('room'),
    department: (0, _relationships.belongsTo)('department', {
      async: false
    }),
    gender: (0, _relationships.belongsTo)('gender') //DS.attr(), //1=male, 2 = female
    //beds: hasMany('bed')

  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/empty-comp", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n+TiUt1P",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[24,[\"tag\"]],[24,[\"item\"]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/empty-comp.hbs"
    }
  });

  _exports.default = _default;
});
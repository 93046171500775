define("hmis/templates/components/mini-app/side-menu-item-heading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q9WaySbE",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mini-side-menu-item-heading\"],[8],[7,\"span\",true],[8],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"fa_icon\"]]]],[10,\"style\",\"margin-right:6px;\"],[8],[9],[9],[1,[22,\"title\"],false],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/mini-app/side-menu-item-heading.hbs"
    }
  });

  _exports.default = _default;
});
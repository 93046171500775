define("hmis/components/forms/chronic-diseases-alert", ["exports", "hmis/mixins/form-alert", "hmis/utils/common-data-columns"], function (_exports, _formAlert, _commonDataColumns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    modelName: 'chronic-condition',
    majorGroup: Ember.computed('model', function () {
      let patient = this.get('parent.patient');
      return this.get('store').findAll('diagnosis-major-group', {}).then(function (recs) {
        let mdg = [];
        recs.forEach(mdiag => {
          console.log('patient age', patient.get('age'));
          let gender = patient.get('gender') ? patient.get('gender') : '0';
          let patAge = patient.get('age') ? patient.get('age').toString() : '';
          let age = Number(patAge.slice(0, 2));

          if (gender.get('id') == '1' && mdiag.id == 'OBGY') {
            return;
          } else if (age > 12 && mdiag.id == 'PAED' || mdiag.id == 'PSUR') {
            return;
          } else {
            mdg.push(mdiag);
          }
        });
        return mdg.toArray();
      });
    }),
    // optionTypes:computed('record.type', function(){
    //     // console.log('record---',this.get('record.type.id'));
    //     // let selMajorItem = this.get('record.type')?this.get('record.type'):[]
    //     return this.get('store').query('diagnosis-type', {
    //       filter: {
    //         mdgCode: this.get('record.type.id')?this.get('record.type.id'):'MEDI'
    //       }
    //     }).then(function(recs) {
    //       var gDiag = []
    //       recs.forEach(diag => {
    //         var gname = diag.get('gdrgCode') +' - '+ diag.get('genericName')
    //         gDiag.push({ groupName: gname, options: diag.get('diagnosisSubTypes').toArray() })
    //       });
    //       return gDiag.toArray();
    //     });
    // }),
    // majorDiagnosisGroupTypes:computed( function() {
    //   return this.get('store').query('diagnosis-major-group', {pageSize:10})
    // }),
    // majorDiagnosisTypeType: computed( 'majorDiagnosisGroup.id', function() {
    //   return this.get('store').query('diagnosis-type', { filter: { mdgCode:this.majorDiagnosisGroup? this.majorDiagnosisGroup.id: 'n/a'}, pageSize:10})
    // }),
    icdDiagnosisTypes: Ember.computed(function () {
      return this.get('store').query('diagnosis-sub-type', {
        filter: {},
        include: ['diagnosisMajorGroupName', 'diagnosisTypeName'],
        pageSize: 10
      });
    }),
    actions: {
      myMatcher(subDiag, term) {
        let searchProp = "".concat(subDiag.get('icdName'), " ").concat(subDiag.get('icdCode'), " ").concat(subDiag.get('diagnosisTypeId.genericName'), " ").concat(subDiag.get('diagnosisTypeId.gdrgCode'));
        return searchProp.toLowerCase().indexOf(term.toLowerCase());
      },

      majorGroupChange(record) {
        this.set('majorDiagnosisGroup', record);
        this.set('majorDiagnosisType', null);
        this.set('icdDiagnosis', null);
      },

      majorTypeChange(record) {
        this.set('majorDiagnosisType', record);
        this.set('icdDiagnosis', null);
      },

      onRecordSubmit() {
        let record = this.get('record'); //this.set('record.type',this.get('majorItem'))

        this.set('waiting', true);

        if (!this.get('record.id')) {
          this.set('record.name', record.diagnosisSubType.diagnosisTypeText);
          record = this.get('store').createRecord('chronic-condition', record);
        }

        record.save().then(rec => {
          this.set('record', {});
          this.set('show', false);

          if (this.get('onSaved')) {
            this.get('onSaved')(rec);
          }

          this.set('waiting', false);
        }, err => {
          this.set('waiting', false);
          console.log('error', err);
        });
      }

    },
    diagnosisSubTypePickerColumns: _commonDataColumns.diagnosisSubTypePickerColumns,
    allergyColumns: [{
      propertyName: 'name'
    }]
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/opd/consultation/consult/xrays", ["exports", "hmis/utils/model-table-theme", "hmis/utils/date-util", "hmis/mixins/form-controller", "hmis/mixins/app-event-mixin", "hmis/mixins/visit-care-controller"], function (_exports, _modelTableTheme, _dateUtil, _formController, _appEventMixin, _visitCareController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, _visitCareController.default, _appEventMixin.default, {
    store: Ember.inject.service('store'),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    notify: Ember.inject.service(),
    serverResponse: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    requestStatus: Ember.computed('oPerms', function () {
      return this.get('oPerms.op26.allow') ? 0 : 1;
    }),

    onUserNotification(e) {
      if (e.data.visitId == this.model.visitId) {
        this.send('refreshModel');
      }
    },

    actions: {
      back() {
        history.back();
      },

      sendRequest() {
        this.set('saving', true);
        this.get('store').createRecord(this.serviceName, {
          visitId: this.model.visitId,
          xrayType: this.xrayType,
          requestNote: this.note,
          source: this.model.source,
          status: 0
        }).save().then(savedInv => {
          this.send('refreshModel');
          this.set('note', '');
          this.get('notify').success('Request sent');
        }).catch(failure => {
          this.get('notify').error(failure);
        }).finally(() => {
          this.set('saving', false);
        });
      },

      onNewRequestTabClick(route) {
        this.set('requestStatus', 3);
      },

      onRequestsTabClick(route) {
        this.set('requestStatus', 0);
      },

      onResultsTabClick(route) {
        this.set('requestStatus', 1);
      },

      onRowClick(index, dataItem) {
        this.viewer.show(dataItem);
      }

    },
    themeInstance: _modelTableTheme.default.create({
      table: 'table'
      /* table:'table table-striped table-bordered table-condensed',*/

    })
  });

  _exports.default = _default;
});
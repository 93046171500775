define("hmis/components/forms/patient-alert", ["exports", "hmis/mixins/form-alert", "hmis/mixins/ref-component"], function (_exports, _formAlert, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, _refComponent.default, {
    modelName: 'patient',
    store: Ember.inject.service('store'),
    ajaxRequest: Ember.inject.service(),
    type: "add",
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    // titles:["Mr","Mrs","Miss"],
    // maritalStatus:['Single', 'Married', 'Divorced', 'Widow'],
    // habits:['late eating', 'no excercising'],
    chronicHealthConditions: ["Polio", "Athsma"],
    // isAdd:computed('type', function() {
    //     return (this.get("type")!="edit");
    // }),
    submitMethod: 'onRecordSubmit',

    new(options, onSaved) {
      this.set('submitMethod', options.saveOnSubmit === false ? '_callConfirm' : 'onRecordSubmit');
      this.set('record', Object.assign({}, options.data || {}));
      this.set('title', options.title);
      this.set('onSaved', onSaved);
      this.set('show', true);
    },

    edit(record, options, onSaved) {
      this.set('submitMethod', options.saveOnSubmit === false ? '_callConfirm' : 'onRecordSubmit');
      this.set('record', record);
      this.set('title', options.title);
      this.set('onSaved', onSaved);
      this.set('show', true);
    },

    close() {
      this.set('show', false);
    },

    maxDate: moment().format("YYYY-MM-DD"),
    insuranceExpiryYearLimit: Ember.computed(function () {
      var today = new Date();
      var year = today.getFullYear() + 30;
      return year;
    }),
    bloodGroup: ['A RhD positive (A+)', 'A RhD negative (A-)', 'B RhD positive (B+)', 'B RhD negative (B-)', 'O RhD positive (O+)', 'O RhD negative (O-)', 'AB RhD positive (AB+)', 'AB RhD negative (AB-)'],
    dateToday: new Date(),
    insuranceVeriInfo: '',
    insuranceError: false,
    // store: Ember.inject.service('store'),
    insuranceProducts: Ember.computed('record.insuranceCompany', function () {
      let company = this.get('record.insuranceCompany');

      if (company) {
        // alert('hii')
        return this.get('store').query('insurance-product', {
          filter: {
            company: company.get('id')
          }
        }).then(function (recs) {
          return recs;
        });
      }
    }),
    // store: Ember.inject.service('store'),
    insuranceCompanies: Ember.computed('model', function () {
      return this.get('store').findAll('insurance-company', {}).then(function (recs) {
        return recs;
      });
    }),
    actions: {
      _callConfirm() {
        this.onSaved(this.record);
      }

    }
  });

  _exports.default = _default;
});
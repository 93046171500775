define("hmis/components/controls/material-date-picker", ["exports", "hmis/utils/date-util"], function (_exports, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // init() {
    //     this._super(...arguments);
    //     if(!this.get('value'))
    //     {
    //         this.set('value', new Date().toLocaleTimeString())
    //         // this.send('onChange', new Date().toLocaleTimeString())
    //     }
    // },
    tagName: '',

    // width:'100px',
    didInsertElement() {
      this._super(...arguments); // Ember.$('#input-mat-dp').datepicker({
      //       calendarWeeks: true
      //   }).on('changeDate', (e)=> {
      //       this.send('onChange', e.date)
      //   });

    },

    dateValue: Ember.computed('value', {
      get(key) {
        let date = this.get('value');

        if (date && date.toLocaleDateString) {
          // if(date.toLocaleDateString())
          date = date.toLocaleDateString();
        }

        return date;
      },

      set(key, value) {
        return value;
      }

    }),
    actions: {
      onDateChange(event) {
        let value = event.target.value;
        this.send('onChange', value);
      },

      onChange(value) {
        let action = this.get('onChange');

        if (action) {
          action(value);
        } else {
          this.set('value', value);
        }
      }

    } // value:computed(function(){
    //     let timeDate = new Date()
    //     date.setHours(timeDate.getHours());
    //     date.setMinutes(timeDate.getMinutes());
    //     date.setSeconds(timeDate.getSeconds());
    //
    //     date = dateUtil.toSqlDate(date)
    //
    //     return date;
    // }),
    //
    // label:'Date',
    //
    // actions:{
    //     onDateChanged(date){
    //         if(!date)
    //         return
    //
    //         let timeDate = new Date()
    //         date.setHours(timeDate.getHours());
    //         date.setMinutes(timeDate.getMinutes());
    //         date.setSeconds(timeDate.getSeconds());
    //
    //         date = dateUtil.toSqlDate(date)
    //         this.set('value',  date)
    //
    //         // console.log(date)
    //         // return
    //
    //         if(this.get('onDateChanged')){
    //             this.get('onDateChanged')(date, event)
    //         }
    //     },
    //
    //     onClose(){
    //         if(this.get('onClose')){
    //             this.get('onClose')()
    //         }
    //     }
    //
    //
    //
    // }

  });

  _exports.default = _default;
});
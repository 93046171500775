define("hmis/routes/hmis-app/ipd/patients", ["exports", "hmis/utils/general-util"], function (_exports, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // breadCrumb: 'Patients',
    model: function (params) {
      return (0, _generalUtil.modelHash)({
        pendingAdmission: this.get('store').query('admitted-queued-patient', {
          filter: {
            status: 'pending'
          },
          include: ['patientName', 'patientGenderId']
        }),
        admitted: this.get('store').query('admitted-queued-patient', {
          filter: {
            status: 'admitted'
          },
          include: ['patientName', 'patientGenderId', 'visitDate', 'visitPurpose']
        }),
        pendingDischarge: this.get('store').query('admitted-queued-patient', {
          filter: {
            status: 'pendingDischarge'
          },
          include: ['patientName', 'patientGenderId', 'visitDate', 'visitPurpose']
        }) // discharged: this.get('store').query('admitted-queued-patient', {
        //   filter: {
        //     status: 'discharged',
        //   },
        //   include:['patientName', 'patientGenderId']
        // }),

      });
    },
    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/facility-management/edit-facility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    addedInsurances: Ember.computed('model.insurances', function () {
      return this.get('model.insurances');
    }),
    // addedProfession: computed('model',function(){
    //   return this.get('store').query('facility-profession', {
    //     filter:{ facility: this.get('model.id')}
    //   })
    // }),
    removedInsurances: [],
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    //addedProfession:[],
    //removedProfession:[],
    actions: {
      back() {
        // console.log(this.get('model.id'))
        history.back();
      },

      submitForm() {
        this.get('submitForm')();
      },

      save() {
        let that = this;
        this.set('isSaving', true);
        this.get("model").save().then(record => {
          this.set('isSaving', false);
          that.notify.success('Saved Successfully'); // alert("Saved");
        }, failure => {
          this.set('isSaving', false);
          console.log(failure); // alert("Unable to saved:"+failure);

          that.notify.error('Unable to save');
        });
      }

    }
  });

  _exports.default = _default;
});
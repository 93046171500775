define("hmis/routes/hmis-app/admin-settings/departments/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    // model(params) {
    //     return {}
    //   }
    currentUser: Ember.inject.service(),

    model(params) {
      //return this.get('store').findRecord('department', this.get('currentUser.user.facility'));
      return this.get('store').query('department', {
        filter: {
          facility: "1"
        }
      }); //return {'test':2}
    }

  });

  _exports.default = _default;
});
define("hmis/mixins/visit-request-model ", ["exports", "ember-data", "hmis/utils/date-util"], function (_exports, _emberData, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    granterName: _emberData.default.attr(),
    requesterName: _emberData.default.attr(),
    requesterId: _emberData.default.attr(),
    granterId: _emberData.default.attr(),
    grantedDate: _emberData.default.attr(),
    requestedDate: _emberData.default.attr(),
    requestedDateTimeF: Ember.computed('requestedDate', function () {
      return _dateUtil.default.formatToDateTimeString(this.get('requestedDate'));
    }),
    grantedDateTimeF: Ember.computed('grantedDate', function () {
      return this.grantedDate ? _dateUtil.default.formatToDateTimeString(this.grantedDate) : 'n/a';
    })
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/table-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eukzJPwT",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"hasPerm\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"_route\"]]],null,{\"statements\":[[0,\"        \"],[5,\"link-to\",[[12,\"class\",\"table-link-link\"],[12,\"onclick\",[28,\"action\",[[23,0,[]],\"onLinkClick\"],null]]],[[\"@route\",\"@model\"],[[22,\"_route\"],[22,\"_model\"]]],{\"statements\":[[1,[22,\"text\"],false]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"a\",true],[10,\"href\",\"#\"],[10,\"class\",\"not-route table-link-link\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[8],[1,[22,\"text\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/table-link.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/hmis-app/facility-management/view-facility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0VkrWqxv",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n     \"],[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n            \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Cancel\",[28,\"action\",[[23,0,[]],\"back\"],null],\"close-fill\"]]],false],[0,\"\\n            \"],[4,\"if\",[[24,[\"oPerms\",\"op3\",\"view\"]]],null,{\"statements\":[[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Edit\",[28,\"action\",[[23,0,[]],\"edit\"],null],\"edit-fill\"]]],false]],\"parameters\":[]},null],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height\",true,\"Facility Information\"]],{\"statements\":[[0,\"            \"],[4,\"if\",[[28,\"or\",[[24,[\"oPerms\",\"op3\",\"add\"]],[24,[\"oPerms\",\"op3\",\"view\"]],[24,[\"oPerms\",\"op3\",\"edit\"]],[24,[\"oPerms\",\"op3\",\"invalidate\"]]],null]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"content display-bock\"],[8],[0,\"\\n                \"],[1,[28,\"object-viewer\",null,[[\"object\",\"properties\"],[[24,[\"model\"]],[24,[\"properties\"]]]]],false],[0,\"\\n            \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[1,[28,\"no-data-message\",null,[[\"message\"],[\"You don't have permissions to view facilities\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/facility-management/view-facility.hbs"
    }
  });

  _exports.default = _default;
});
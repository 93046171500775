define("hmis/components/controls/select-all-rows-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // layout,
    actions: {
      toggleAllSelection() {
        Ember.get(this, 'toggleAllSelection')();
      }

    }
  });

  _exports.default = _default;
});
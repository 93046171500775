define("hmis/components/controls/access-m-user-m-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    appData: Ember.inject.service(),
    modulePermission: Ember.computed('userModulePermission', function () {
      return this.appData.modulePermissions[this.userModulePermission.permissionId];
    }),
    actions: {
      onLock(value) {
        this.set('userModulePermission.allow', !value);
        this.set('user.hasChanges', true);
      },

      onRemove() {
        this.get('user.modulePermissions').removeObject(this.userModulePermission);
        this.set('user.hasChanges', true);
      }

    }
  });

  _exports.default = _default;
});
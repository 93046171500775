define("hmis/services/notify", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    notifications: Ember.inject.service('notification-messages'),
    serverResponse: Ember.inject.service(),
    successOptions: {
      autoClear: true,
      clearDuration: 3000,
      cssClasses: 'notification'
    },
    infoOptions: {// autoClear: true,
      // clearDuration: 1200
    },
    warningOptions: {// autoClear: true,
      // clearDuration: 1200
    },
    errorOptions: {
      autoClear: true,
      clearDuration: 8000
    },

    removeNotification(notification) {
      this.notifications.removeNotification(notification);
    },

    success(message, onClick = null) {
      return this.get('notifications').success(message, {
        autoClear: true,
        clearDuration: 3000,
        cssClasses: 'notification'
      });
    },

    info(message, onClick = null, autoClear = true) {
      return this.get('notifications').info(message, {
        autoClear: autoClear,
        clearDuration: 6000,
        onClick: onClick
      });
    },

    warning(message, onClick = null) {
      return this.get('notifications').warning(message, {
        onClick
      });
    },

    error(message, onClick = null) {
      console.error(message);
      return this.get('notifications').error(this.get('serverResponse').errorMessage(message), {
        autoClear: true,
        clearDuration: 8000,
        onClick: onClick
      });
    },

    confirmOptions(options, onConfirm, onCancel) {
      this.confirmOptionsAlert.confirm(options, onConfirm, onCancel);
    },

    confirmSimple(title, message = "Do you want to continue?", confirmCallback, confirmCancelCallback, options) {
      this.confirm(Object.assign({
        title: title,
        message: message
      }, options), confirmCallback, confirmCancelCallback, options);
    },

    confirm(options, confirmCallback, confirmCancelCallback) {
      this.confirmDialog.confirm(options, confirmCallback, confirmCancelCallback);
      this.set('confirmObject', options.viewerObject);
    },

    prompt(options, promptCallback, onCancelCallback) {
      this.promptDialog.show(options, promptCallback, onCancelCallback);
    }

  });

  _exports.default = _default;
});
define("hmis/helpers/empty-object", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emptyObject = emptyObject;
  _exports.default = void 0;

  function emptyObject() {
    return {};
  }

  var _default = Ember.Helper.helper(emptyObject);

  _exports.default = _default;
});
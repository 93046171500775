define("hmis/components/controls/transaction-cell", ["exports", "hmis/utils/date-util"], function (_exports, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { get } from '@ember/object';
  function concatWithProperty(strings, property) {
    if (property) {
      strings.push(property);
    }

    return strings.join(' ');
  }

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    },

    classNames: ['transaction-cell-comp'],
    classNameBindings: ['hide:hidden'],
    _disabled: Ember.computed('disabled', 'waiting', 'record.waiting', function () {
      return this.disabled || this.waiting || this.record.waiting;
    }),
    actions: {
      paymentDetails() {
        this.set('showDetails', true);
      },

      payDefault() {
        let action = this.get('onPayDefault');
        if (action) action(this.get('record'));
      },

      changePayment() {
        let action = this.get('onChangePayment');
        if (action) action(this.get('record'));
      }

    },
    properties: [{
      title: 'Transaction id',
      name: 'id'
    }, {
      title: 'Transaction date',
      name: 'dateF'
    }, {
      title: 'Price',
      name: 'price'
    }, {
      title: 'Quantity',
      name: 'quantity'
    }, {
      title: 'Payment status',
      name: 'paymentStatus'
    }, {
      title: 'Payment date',
      name: 'paymentDate'
    }, {
      title: 'Service Type',
      name: 'billableType'
    }, {
      title: 'Service item',
      name: 'billableItemName'
    }, {
      title: 'Service GDRG code',
      name: 'gdrgCode'
    }, {
      title: 'Payment account type',
      name: 'mopName'
    }, {
      title: 'Account name',
      name: 'patientMopName'
    }, {
      title: 'Account number',
      name: 'patientMopAccountNumber'
    }]
  });

  _exports.default = _default;
  const formats = {
    date: value => {
      return _dateUtil.default.formatToDateTimeString(value);
    }
  };
});
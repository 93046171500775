define("hmis/controllers/hmis-app/opd/consultation/index", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/page-error-handler"], function (_exports, _modelTableTheme, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pageErrorHandler.default, {
    tabIndex: 0,
    router: Ember.inject.service(),
    notify: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    serverResponse: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    onConfirm: 'empty',

    init() {
      this._super(...arguments);
    },

    actions: {
      empty() {},

      onActiveServeClick(activeServe) {
        if (this.get('oPerms.op14.allow')) {
          this.router.transitionTo("hmis-app.opd.consultation.consult", activeServe.id);
        } else {
          this.notify.error("You don't have permission to Serve a patient");
        }
      },

      onNewRequestTabClick(route) {
        this.set('requestStatus', 3);
      },

      onQueueClick(index, record) {
        if (this.get('oPerms.op14.allow')) {
          this.set('onConfirm', 'startQueueServe');
          this.set('recordTag', record);
          this.set('alertTitle', 'Attempt to serve ' + record.get('patientName'));
          this.set('alertMessage', 'Are you ready to start serving a new patient?');
          this.set('onAlert', true);
        } else {
          this.get('notify').error("You don't have permission to Serve a patient");
        }
      },

      startQueueServe(record) {
        this.appAjax.post('/startActiveServeFromQueue/' + record.id, null).then(data => {
          this.notify.success('You are now serving ', data.patientName);
          this.get("router").transitionTo('hmis-app.opd.consultation.consult', data.id);
        }).catch(err => {
          this.notify.error(err);
        });
      },

      startReferralServe(record) {
        this.appAjax.post('/startActiveServeFromReferral/' + record.id, null).then(data => {
          this.notify.success('You are now serving ', data.patientName);
          this.get("router").transitionTo('hmis-app.opd.consultation.consult', data.id);
        }).catch(err => {
          this.notify.error(err);
        });
      },

      viewQueue(record) {},

      onServedClick(index, record) {
        this.send('onActiveServeClick', record);
      },

      newAppointment() {
        this.appointmentManager.newAppointment({
          staff: this.currentUser.user
        }, appointment => {});
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "propertyName": "patientName",
      'className': 'main-column'
    }, {
      title: 'Purpose',
      'propertyName': 'visitPurpose'
    }, {
      title: 'Visit Date',
      "propertyName": "visitDateF"
    }, {
      "title": "Actions",
      "component": "manageRow"
    }],
    referralsColumn: [{
      "propertyName": "patientName",
      'className': 'main-column'
    }, {
      title: 'Purpose',
      'propertyName': 'visitPurpose'
    }, {
      title: 'Visit Date',
      "propertyName": "visitDateF"
    }, {
      title: 'Note',
      "propertyName": "note",
      component: 'controls/table-long-text'
    }, {
      "title": "Actions",
      "component": "manageRow"
    }],
    servedColumns: [{
      "propertyName": "patientName",
      'className': 'main-column'
    }, {
      title: 'Purpose',
      'propertyName': 'visitPurpose'
    }, {
      title: 'Time served',
      "propertyName": "endDateF"
    }]
  });

  _exports.default = _default;
});
define("hmis/helpers/report-count", ["exports", "hmis/utils/currency-util"], function (_exports, _currencyUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reportCount = reportCount;
  _exports.default = void 0;

  function reportCount([value, dataType = 'count', currency = 'GHS', decimalCount = 2, decimal = ".", thousands = ","]) {
    if (value == 0) return '-';

    if (dataType == 'count') {
      return value;
    } else if (dataType == 'amount') {
      return currency + ' ' + _currencyUtil.default.formatMoney(value, decimalCount, decimal, thousands);
    } else {
      return value;
    }
  }

  var _default = Ember.Helper.helper(reportCount);

  _exports.default = _default;
});
define("hmis/serializers/medicine", ["exports", "ember-data", "hmis/mixins/data-serializer"], function (_exports, _emberData, _dataSerializer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.RESTSerializer.extend(_dataSerializer.default, {
    normalize(typeClass, hash) {
      for (var field in hash) {
        if (Object.prototype.hasOwnProperty.call(hash, field)) {
          // do stuff
          if (field == 'prescribingLevels') {
            if (hash[field]) {
              hash[field] = hash[field].split(",");
            }
          }
        }
      }

      return this._super.apply(this, arguments);
    },

    serialize(snapshot, options) {
      let json = this._super(snapshot, options);

      let pl = json.prescribingLevels;

      if (pl) {
        //check for any levelCode with a comma in it. its invalid
        for (let index = 0; index < pl.length; index++) {
          if (pl[index].includes(',')) {
            console.error('Invalid prescribingLevel name', 'prescribingLevel names must not include a comma');
            return {};
          }
        }

        json.prescribingLevels = pl.join(",");
      }

      return json;
    }

  }); // import { underscore, camelize } from '@ember/string';
  // import DS from 'ember-data';
  // export default DS.JSONSerializer.extend({
  //   normalize(typeClass, hash) {
  //     for (var field in hash) {
  //       if (Object.prototype.hasOwnProperty.call(hash, field)) {
  //           // do stuff
  //           if (field.endsWith('_JSProp')) { 
  //             if(hash[field]){
  //               let newField= field.replace('_JSProp', 'Obj');
  //               if (typeof hash[field] === 'string' || hash[field] instanceof String)
  //                 hash[newField] = JSON.parse(hash[field]);
  //               else
  //                 hash[newField] = hash[field];
  //               delete hash[field];
  //             }
  //           }
  //       }
  //     }
  //     return this._super.apply(this, arguments);
  //   },
  //     keyForAttribute: function(attr) {
  //         if(attr=='createdAt' || attr=='updatedAt')
  //           return underscore(attr);
  //         else {
  //           return camelize(attr);
  //         }
  //     },
  // });


  _exports.default = _default;
});
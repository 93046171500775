define("hmis/components/sharing/consent-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['consent-group'],
    appData: Ember.inject.service(),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    tabIndex: 0,
    newlyCreated: false,
    showPicker: false,
    newOtherException: {},
    selectedWhatExceptions: [],
    selectedFacilityExceptions: [],
    selectedDoctorExceptions: [],
    selectedRelativeExceptions: [],
    consentKey: Ember.computed('patientConsents', 'group.id', function () {
      return 'patientConsents.' + this.get('group.id');
    }),
    consent: Ember.computed('patientConsents', 'consentKey', 'newlyCreated', function () {
      return this.get(this.get('consentKey'));
    }),
    shareWhatExceptions: Ember.computed('consent', 'consentKey', 'consent.shareWhatExceptions.@each', 'newlyCreated', function () {
      return this.get(this.get('consentKey') + ".shareWhatExceptions");
    }),
    shareWithExceptions: Ember.computed('consent', 'consentKey', 'consent.shareWithExceptions.@each', 'newlyCreated', function () {
      return this.get(this.get('consentKey') + ".shareWithExceptions");
    }),
    sharingWhatText: Ember.computed('group', function () {
      return 'all ' + this.get('group.title') + "consents";
    }),
    sharingWithText: Ember.computed('group', function () {
      return 'all with exceptions';
    }),
    actions: {
      onShareWhatChange(value) {
        this.send('checkConsentExistence');
        this.set('consent.shareWhat', value);
        this.send('onChange');
      },

      onShareWithChange(value) {
        this.send('checkConsentExistence');
        this.set('consent.shareWith', value);
        this.send('onChange');
      },

      checkConsentExistence() {
        if (!this.get(this.get('consentKey'))) {
          this.set(this.get('consentKey'), {});
          this.set('newlyCreated', !this.get('newlyCreated'));
        }
      },

      tabClick(index) {
        if (this.get('tabIndex') == index) this.set('tabIndex', -1);else this.set('tabIndex', index);
      },

      onChange() {
        if (this.get('onChange')) this.get('onChange')();
      },

      chooseWhatExceptionConfirm() {
        this.send('chooseExceptionConfirm', 'shareWhatExceptions', 'selectedWhatExceptions', (existingExc, element) => {
          return existingExc.filter(fItem => fItem['id'] == element['id']).length > 0;
        }, element => {
          return {
            id: element['id'],
            title: element['title']
          };
        });
      },

      chooseFacilityExceptionConfirm() {
        this.send('chooseExceptionConfirm', 'shareWithExceptions', 'selectedFacilityExceptions', (existingExc, element) => {
          return existingExc.filter(fItem => fItem['id'] == element['id'] && fItem['type'] == 'facility').length > 0;
        }, element => {
          return {
            id: element['id'],
            title: element['facilityName'],
            type: 'facility'
          };
        });
      },

      chooseDoctorExceptionConfirm() {
        this.send('chooseExceptionConfirm', 'shareWithExceptions', 'selectedDoctorExceptions', (existingExc, element) => {
          return existingExc.filter(fItem => fItem['id'] == element['id'] && fItem['type'] == 'doctor').length > 0;
        }, element => {
          return {
            id: element['id'],
            title: element['firstName'] + " " + element['lastName'] + " " + element['middleName'],
            type: 'doctor'
          };
        });
      },

      chooseRelativeExceptionConfirm() {
        this.send('chooseExceptionConfirm', 'shareWithExceptions', 'selectedRelativeExceptions', (existingExc, element) => {
          return existingExc.filter(fItem => fItem['id'] == element['id'] && fItem['type'] == 'relative').length > 0;
        }, element => {
          return {
            id: element['id'],
            title: element['firstName'] + " " + element['lastName'] + " " + element['middleName'],
            type: 'relative'
          };
        });
      },

      onAddOtherShare() {
        this.send('checkConsentExistence');
        let consentArrayName = 'shareWithExceptions';
        let newOtherException = this.get('newOtherException');
        let consentArray = this.get(this.get('consentKey') + '.' + consentArrayName);

        if (!consentArray || !consentArray.length) {
          this.set(this.get('consentKey') + '.' + consentArrayName, []);
          consentArray = this.get(this.get('consentKey') + '.' + consentArrayName);
        }

        if (consentArray.filter(fItem => fItem['id'] == newOtherException['phone'] && fItem['type'] == 'other').length > 0) return;
        consentArray.pushObject({
          id: newOtherException['phone'],
          title: newOtherException['name'],
          name: newOtherException['name'],
          phone: newOtherException['phone'],
          email: newOtherException['email'],
          info: newOtherException['info'],
          type: 'relative'
        });
        this.set('newOtherException', {});
        this.set('showOtherForm', false);
        this.send('onChange');
      },

      chooseExceptionConfirm(consentArrayName, selectedPickerItems, exists, transformException) {
        this.send('checkConsentExistence');
        let arr = this.get(selectedPickerItems);
        let consentArray = this.get(this.get('consentKey') + '.' + consentArrayName);

        if (!consentArray || !consentArray.length) {
          this.set(this.get('consentKey') + '.' + consentArrayName, []);
          consentArray = this.get(this.get('consentKey') + '.' + consentArrayName);
        }

        for (let index = 0; index < arr.length; index++) {
          const element = arr[index];
          if (!exists(consentArray, element)) consentArray.pushObject(transformException(element));
        }

        this.set(selectedPickerItems, []);
        this.send('onChange');
      },

      onDeleteShareWhatException(record) {
        this.get(this.get('consentKey') + '.shareWhatExceptions').removeObject(record);
        this.send('onChange');
      },

      onDeleteShareWithException(record) {
        this.get(this.get('consentKey') + '.shareWithExceptions').removeObject(record);
        this.send('onChange');
      }

    },
    pickerColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      propertyName: 'title'
    }, {
      name: 'description'
    }],
    pickerFacilityColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      propertyName: 'facilityName'
    }],
    pickerDoctorColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      propertyName: 'firstName'
    }, {
      title: 'other names',
      propertyName: 'lastName'
    }, {
      propertyName: 'middleName'
    }]
  });

  _exports.default = _default;
});
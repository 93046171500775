define("hmis/controllers/hmis-app/ipd", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    actions: {
      onMenuClick(route) {
        this.get("routing").transitionTo(route);
      }

    }
  });

  _exports.default = _default;
});
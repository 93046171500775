define("hmis/templates/components/controls/dropdown-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l+Q9+axN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"dd-wrapper\"],[11,\"style\",[29,[\"display:\",[28,\"if\",[[28,\"and\",[[25,1],[24,[\"isOpen\"]]],null],\"block\",\" none\"],null],\" \"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"dd-dropdown\"],[11,\"style\",[22,\"ddStyle\"]],[8],[0,\"\\n        \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"dd-triangle hasHeader\"],[8],[9],[0,\"         \\n    \\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/dropdown-page.hbs"
    }
  });

  _exports.default = _default;
});
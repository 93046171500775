define("hmis/helpers/pluralize-word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function pluralizeWord([noun, ...rest]
  /*, hash*/
  ) {
    return "".concat(noun).concat(noun.length !== 1 ? 's' : '');
  });

  _exports.default = _default;
});
define("hmis/components/controls/active-serve-list-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    // marginUp:true,
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    actions: {
      onActiveServeClick(activeServe) {
        if (this.get('oPerms.op14.allow')) {
          this.get("routing").transitionTo("hmis-app.opd.consultation.consult", [activeServe.id]);
        } else {
          this.notify.error("You don't have permission to Serve a patient");
        }
      }

    }
  });

  _exports.default = _default;
});
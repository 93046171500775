define("hmis/controllers/hmis-app/admin-settings/departments/manage", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service('store'),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    currentUser: Ember.inject.service(),
    selectedWards: [],
    tabStatus: 0,
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    batchItems: {},
    consultationUnits: Ember.computed(function () {
      return this.get('store').findAll('other-billable');
    }),
    onConfirm: 'deleteRecord',
    actions: {
      back() {
        history.back();
      },

      changeIsConsultation(value, record) {
        console.log(value, record);
        console.log('');
      },

      onNewTabClick(route) {
        this.set('tabStatus', 0);
      },

      onHistoryTabClick(route) {
        this.set('tabStatus', 1);
      },

      addDepartmentUnit() {
        //console.log(this.get('currentUser.user.facility.id'))
        let model = this.get('model');
        let store = this.get('store');
        let that = this;
        let post = store.createRecord('department-unit', {
          name: this.get('name'),
          department: this.get('model'),
          service: this.get('service'),
          isConsultation: this.get('isConsultation') //facility: this.get('currentUser.user.facility.id')

        });
        post.save().then(record => {
          that.notify.success('Saved Successfully');
        }).catch(failure => {
          that.notify.error('Unable to Save' + failure);
        });
      },

      addWard() {
        console.log('just add ward');
        let model = this.get('model');
        let store = this.get('store');
        let that = this;
        let post = store.createRecord('department-unit', {
          name: this.get('wardname'),
          isConsultation: 0,
          department: this.get('model'),
          type: '2'
        });
        post.save().then(record => {
          this.get('model.wards').pushObject(record);
          that.notify.success('Saved Successfully');
        }).catch(failure => {
          that.notify.error('Unable to Save' + failure);
        });
      },

      clickRecord(record) {
        console.log(this.get('routing').get('currentRouteName'));
        let str = this.get('routing.currentRouteName');
        let lastIndex = str.lastIndexOf(".");
        str = str.substring(0, lastIndex);
        lastIndex = str.lastIndexOf(".");
        let newstr = str.substring(0, lastIndex);

        if (str.substring(0, 1) == 'c') {
          this.get("routing").transitionTo(newstr + ".ward", [record.id]);
        } else {
          this.get("routing").transitionTo(str + ".ward", [record.id]);
        }
      },

      clickRecordDepUnit(record) {
        console.log(this.get('routing').get('currentRouteName')); //this.get("routing").transitionTo(this.get('routing').get('currentRouteName')+".ward",[record.id]);
        //this.get("routing").transitionTo(this.get('routing').get('currentRouteName')+".ward",[record.id]);
      },

      saveRecord(record) {
        record.save();
      },

      editRecord() {
        console.log('view msg');
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete a department unit: (' + record.get('name') + ')');
        this.set('onAlert', true);
      },

      deleteNow(record) {
        if (this.tabStatus === 1) {
          this.get("model.wards").removeObject(record);
          record.destroyRecord();
        } else {
          this.get("model.departmentUnits").removeObject(record);
          record.destroyRecord();
        }
      },

      deleteDiagnosis(record) {
        let diagnosesList = this.get("batch.diagnoses");
        diagnosesList.removeObject(record);
        record.destroyRecord();

        if (diagnosesList.get('length') == 0) {
          this.get('batch').destroyRecord();
          this.set('model.oldDiagnosisBatch', false);
        }
      },

      rowClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id');
        console.log('view msg'); // this.get("routing").transitionTo("hmis-app.opd.manage-patient",[rid]);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      'title': 'Batch date',
      "propertyName": "date",
      "editable": false
    }, {
      'title': 'Diagnosis recorded',
      "propertyName": "diagnosesString"
    }]
  });

  _exports.default = _default;
});
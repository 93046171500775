define("hmis/components/classic/c-chips", ["exports", "hmis/mixins/classic-component"], function (_exports, _classicComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_classicComponent.default, {
    className: 'c-chips',
    searchEnabled: false,
    actions: {
      onNew() {
        this.set('editMode', true);
        setTimeout(() => {
          this.$('.add-input').focus();
        }, 50);
      },

      onCancel() {
        this.set('editMode', false);
      },

      onAdd() {
        event.preventDefault();
        event.stopPropagation();
        if (!this.newItem || this.newItem == "") return;
        if (this.onAdd) this.onAdd();else this.get('options').pushObject(this.newItem);
        this.set('newItem', '');
        setTimeout(() => {
          this.$('.add-input').focus();
        }, 50);
        this.send('onChange');
      },

      onRemove(item) {
        if (this.onRemove) this.onRemove(item);else this.get('options').removeObject(item);
        this.send('onChange');
      },

      onChange() {
        if (this.onChange) this.onChange(this.options);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/empty-space", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    width: '10px',
    height: '10px'
  });

  _exports.default = _default;
});
define("hmis/initializers/navigation", ["exports", "hmis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize()
  /* application */
  {
    const application = arguments[1] || arguments[0];
    let {
      uiNavigator
    } = _environment.default;
    uiNavigator = uiNavigator || {};
    const injectionFactories = uiNavigator.injectionFactories || [];
    application.register('config:navigator', uiNavigator, {
      instantiate: false
    });

    if (injectionFactories.length > 0) {
      application.inject('service:navigator', 'uiNavigator', 'config:navigator');
      injectionFactories.forEach(factory => {
        application.inject(factory, 'navigator', 'service:navigator');
      });
    }
  }

  var _default = {
    name: 'navigator',
    initialize
  };
  _exports.default = _default;
});
define("hmis/templates/components/controls/access-m-role-m-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0C8PtiwM",
    "block": "{\"symbols\":[\"oPermission\"],\"statements\":[[0,\"\\n\"],[1,[28,\"controls/access-m-module-permission\",null,[[\"permitted\",\"modulePermission\",\"lockClick\"],[[24,[\"roleModulePermission\",\"allow\"]],[24,[\"appModule\"]],[28,\"action\",[[23,0,[]],\"lockClick\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"roleModulePermission\",\"allow\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"operations-list\"],[8],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"modulePermission\",\"relatedOperationPermissions\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"controls/access-m-role-o-permission\",null,[[\"role\",\"operationPermission\"],[[24,[\"role\"]],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/access-m-role-m-permission.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/configure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    // init(){
    //   this.transitionToRoute("configure.index",this.get('currentUser.user').belongsTo('facility').id());
    // },
    actions: {
      toggleQuickAction(event) {
        this.set("quickActionCollapsed", !this.get("quickActionCollapsed"));
      },

      logout() {
        this.get('session').invalidate();
      },

      back() {
        history.back();
      },

      save() {
        console.log('save');
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/dropdown-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      onMouseOver() {
        let action = this.get('mouseOverAction');
        if (action) action();
      },

      onMouseLeave() {
        let action = this.get('mouseLeaveAction');
        if (action) action();
      },

      onClick() {
        this.set('isOpen', true);
        let action = this.get('mouseClickAction');
        if (action) action();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/models/patient-payment-account", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    patient: (0, _relationships.belongsTo)('patient'),
    paymentMode: (0, _relationships.belongsTo)('payment-mode'),
    accountNumber: _emberData.default.attr(),
    paymentModeName: _emberData.default.attr(),
    paymentModeTypeName: _emberData.default.attr(),
    accountName: _emberData.default.attr()
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/diagnostics/xray/index", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/app-event-mixin", "hmis/mixins/visit-care-controller"], function (_exports, _modelTableTheme, _appEventMixin, _visitCareController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_appEventMixin.default, _visitCareController.default, {
    routing: Ember.inject.service('router'),
    // requestStatus:0,
    currentUser: Ember.inject.service(),

    onPermissionNotification(data) {
      if (data.permission == 'op27') //reply xray permission
        {
          this.send('refreshModel');
        }
    },

    actions: {
      onRequestsFilterClick(route) {
        this.set('requestStatus', 0);
      },

      onResultsFilterClick(route) {
        this.set('requestStatus', 1);
      },

      onRowClick(index, dataItem) {
        this.get("routing").transitionTo("hmis-app.diagnostics.xray.review", dataItem.id);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    //date , patient, requestor
    columns: [{
      'title': 'X-Ray Type',
      "propertyName": "xrayTypeName",
      className: 'main-column'
    }, {
      'title': 'Patient',
      "propertyName": "patientName"
    }, {
      'title': 'Requested By',
      "propertyName": "requesterName"
    }, {
      'title': 'Request Date',
      "propertyName": "requestedDateTimeF"
    }]
  });

  _exports.default = _default;
});
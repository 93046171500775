define("hmis/components/controls/settings-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['settings-row'],
    icon: 'settings-fill'
  });

  _exports.default = _default;
});
define("hmis/components/controls/date-filter-dropdown", ["exports", "hmis/utils/date-util"], function (_exports, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    viewMonthly: true,
    viewDateRange: false,
    Filter: 'Genrate',
    // isOpen:true,
    disabled: false,
    // disabled:computed('status',function(){
    //     return !this.get('status')
    // }),
    dfFrom: Ember.computed(function () {
      return _dateUtil.default.toSqlDateOnly(this.get('fromDate'));
    }),
    dfTo: Ember.computed(function () {
      return _dateUtil.default.toSqlDateOnly(this.get('toDate'));
    }),
    fromDateUnSaved: Ember.computed(function () {
      return this.get('dfFrom');
    }),
    toDateUnSaved: Ember.computed(function () {
      return this.get('dfTo');
    }),
    actions: {
      monthlyClick() {
        this.set('viewDateRange', false);
        this.set('viewMonthly', true);
      },

      dateRangeClick() {
        this.set('viewDateRange', true);
        this.set('viewMonthly', false);
      },

      filterNowClick() {
        let fromDateUnSaved = this.get('fromDateUnSaved');
        let toDateUnSaved = this.get('toDateUnSaved');
        this.set('dfFrom', new Date(fromDateUnSaved));
        this.set('dfTo', new Date(toDateUnSaved)); // console.log(fromDateUnSaved, toDateUnSaved)

        if (this.get('onSubmit')) {
          this.get('onSubmit')(this.get('dfFrom'), this.get('dfTo'));
        }

        this.set('isOpen', false);
      },

      onStatusChanged(status) {
        // 
        // {
        if (!this.get('viewMonthly') && !this.get('viewDateRange')) {
          this.set('viewMonthly', true);
        } // if(this.get('onStatusChanged'))
        //     this.get('onStatusChanged')(status)  
        // }


        this.set('status', status);
      }

    }
  });

  _exports.default = _default;

  function isValidDate(date) {
    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        return false;
      } else {
        // date is valid
        return true;
      }
    } else {
      // not a date
      return false;
    }
  }
});
define("hmis/controllers/hmis-app/admin-settings/facility-vitals/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      save() {
        let that = this;
        let model = this.get('model');
        this.set("facility", this.get('model.facility'));
        this.set('model', this.get('store').createRecord('vitals-facility-defaults', model)).save().then(record => {
          that.send('reloadModel');
          that.notify.success('Saved Successfully');
        }).catch(failure => {
          that.notify.error('Unable to Save' + failure);
        });
      },

      saveRecord(record) {
        record.save();
      },

      deleteRecord(record) {
        let that = this; //this.get("selectedItems").removeObject(record);

        record.destroyRecord().then(record => {
          that.notify.success('Deleted Successfully');
        });
      },

      editRecord(record) {
        // alert(record.id);
        this.get("routing").transitionTo("hmis-app.admin-settings.facility-vitals.edit", [record.id]);
      },

      newRecord() {
        let record = this.get('record');
        this.transitionToRoute("hmis-app.admin-settings.investigation-type.add");
      }

    }
  });

  _exports.default = _default;
});
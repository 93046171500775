define("hmis/mixins/paged-report-route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      if (!controller.filters.length) controller.send('loadFilters');
    }

  });

  _exports.default = _default;
});
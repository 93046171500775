define("hmis/controllers/hmis-app/admin-settings/questionnaire/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    routing: Ember.inject.service('router'),
    actions: {
      createQuestionnaire() {
        this.get("routing").transitionTo("hmis-app.admin-settings.questionnaire.q-new");
      },

      onItemClick(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.admin-settings.questionnaire.q-view", rid);
      },

      onItemDelete(record) {
        let cTitle = 'Attempt to delete \'' + record.get('title') + "\'";
        this.get('notify').confirm(cTitle, null, () => {
          record.destroyRecord();
        });
      },

      onCancel() {}

    }
  });

  _exports.default = _default;
});
define("hmis/models/department-specialist", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    departmentUnit: (0, _relationships.belongsTo)('department-unit'),
    //, { async: false }
    user: (0, _relationships.belongsTo)('user'),
    departmentUnitName: _emberData.default.attr(),
    departmentName: _emberData.default.attr(),
    specialistName: _emberData.default.attr(),
    specialties: _emberData.default.attr(),
    specialtiesString: Ember.computed('specialties', function () {
      var arr = this.get('specialties');
      var val = [];

      if (this.get('specialties')) {
        arr.forEach(element => {
          val.push(element.noun);
        });
        return val.toString(', ');
      }
    })
  });

  _exports.default = _default;
});
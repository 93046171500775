define("hmis/routes/hmis-app/records/patient/billing", ["exports", "hmis/utils/general-util"], function (_exports, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      var curPatient = this.modelFor("hmis-app.records.patient");
      return (0, _generalUtil.modelHash)({
        // insuranceCompanies: this.get('store').query('insurance-company', {}),
        patient: curPatient // patients: this.get('store').query('patient', {}),
        // patientInsurance: this.get('store').query('patient-insurance', {
        //   filter: {patientId: curPatient.get('id')},
        // }),
        // patientDependant: this.get('store').query('patient-dependant', {
        //   filter: {patientId: curPatient.get('id')},
        // }),
        // patientPayment: this.get('store').query('patient-payment-account', {
        //   filter: {patientId: curPatient.get('id')},
        // }),

      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
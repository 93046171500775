define("hmis/templates/components/submit-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y8m7AE8B",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"waiting\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[10,\"class\",\"waiting-text\"],[8],[0,\"Please wait...\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"text\"],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[24,[\"waiting\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"paper-progress-linear\",null,[[\"class\"],[\"waiting-progress\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/submit-button.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/mixins/rest-frontend-serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    keyForAttribute: function (attr) {
      if (attr == 'createdAt' || attr == 'updatedAt') return Ember.String.underscore(attr);else {
        return Ember.String.camelize(attr);
      }
    },

    modelNameFromPayloadKey(payloadKey) {
      // console.log('first', payloadKey );
      if (payloadKey.indexOf('Obj') !== -1) {
        // console.log('seen', payloadKey );
        return this._super(payloadKey.replace('Obj', ''));
      } else {
        return this._super(payloadKey);
      }
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      let data = {};
      let model = primaryModelClass.modelName;
      data[model] = payload;
      console.log('data', data);
      return this._super(store, primaryModelClass, data, id, requestType);
    },

    serializeIntoHash(data, type, record, options) {
      Ember.assign(data, this.serialize(record, options));
    }

  });

  _exports.default = _default;
});
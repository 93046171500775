define("hmis/routes/hmis-app/users/user/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({// model(params) {
    //   console.log('params.user_id',params.user_id);
    //     return this.get('store').findRecord('user', params.user_id);
    //   }
  });

  _exports.default = _default;
});
define("hmis/components/controls/row-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.rowOptionIsHidden = rowOptionIsHidden;
  _exports.rowOptionIsDisabled = rowOptionIsDisabled;
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',

    init() {
      this._super(...arguments);

      this.set('options', deepmerge([], this.options));
    },

    hidden: Ember.computed('hideOptions', 'value', function () {
      if (this.hideOptions) return this.hideOptions(this.record);
      return false;
    }),
    filteredOptions: Ember.computed('options', 'options.@each.hidden', 'value', function () {
      let inline = [],
          dropdown = [];
      this.options.forEach(opt => {
        if (!rowOptionIsHidden(opt, this.record, this.value)) {
          if (opt.inline) inline.push(opt);else dropdown.push(opt);
        }
      });
      return {
        inline,
        dropdown
      };
    }),
    actions: {
      stopPropagation() {
        event.preventDefault();
        event.stopPropagation();
      },

      optionClicked(option, dropdown) {
        if (dropdown) dropdown.actions.close();
        this.onOptionClick(option, this.record, this.options);
      }

    }
  });

  _exports.default = _default;

  function rowOptionIsHidden(option, record, value) {
    if (option.hidden == true) return true;

    if (option.hideWhen) {
      return option.hideWhen(record, value);
    } else {
      return false;
    }
  }

  function rowOptionIsDisabled(option, record, value) {
    if (option.disabledWhen) {
      return option.disabledWhen(record, value);
    } else {
      return false;
    }
  }
});
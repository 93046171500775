define("hmis/components/controls/date-filter-dropdown.1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    // viewMonthly:true,
    fromDate: Ember.computed(function () {
      return new Date();
    }),
    toDate: Ember.computed(function () {
      return new Date();
    }),
    actions: {
      onDateFilterClose(dropdown, event) {
        // console.log(event)
        if (event) {
          if (event.target.className.indexOf("pika") > -1 || event.target.className == '' || event.target.className == 'is-empty') {
            return false;
          } else {
            return true;
          }
        } // console.log(event.target.className)

      },

      onDateFilterOpen(dropdown, event) {
        this.set('filterDropdown', dropdown);
      },

      onFilter(fromDate, toDate) {
        console.log("frrff: s", fromDate, toDate, typeof fromDate);
        if (typeof fromDate == 'string') fromDate = new Date(fromDate);
        if (typeof toDate == 'string') toDate = new Date(toDate);

        if (!isValidDate(fromDate)) {
          alert('Complete date selection');
          return;
        }

        if (!isValidDate(toDate)) {
          alert('Select \'to\' date ');
          return;
        }

        this.get('filterDropdown').actions.close(); //   this.set('fromDate', fromDate)
        //   this.set('toDate', toDate)

        if (this.get('onSubmit')) {
          this.get('onSubmit')(fromDate, toDate);
        }
      },

      onStatusChanged(status) {
        if (this.get('onStatusChanged')) {
          if (!this.get('viewMonthly') && !this.get('viewDateRange')) {
            this.set('viewMonthly', true);
          }

          this.get('onStatusChanged')(status);
        }
      }

    }
  });

  _exports.default = _default;

  function isValidDate(date) {
    if (Object.prototype.toString.call(date) === "[object Date]") {
      // it is a date
      if (isNaN(date.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        return false;
      } else {
        // date is valid
        return true;
      }
    } else {
      // not a date
      return false;
    }
  }
});
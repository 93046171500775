define("hmis/components/controls/access-m-user-o-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { DS } from 'ember-data';
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    appData: Ember.inject.service(),
    tagName: '',
    operationPermission: Ember.computed('userOperationPermission', function () {
      return this.appData.operationPermissions[this.userOperationPermission.permissionId];
    }),
    actions: {
      onPermissionChange(prop, value) {
        this.set('userOperationPermission.' + prop, value);
        this.set('user.hasChanges', true);
      },

      onRemove() {
        this.get('user.operationPermissions').removeObject(this.userOperationPermission);
        this.set('user.hasChanges', true);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/facility-owner/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      save() {
        let that = this;
        this.get("model").save().then(record => {
          //alert("Saved");
          that.notify.success('Saved Successfully');
          history.back();
        }).catch(failure => {
          //alert("Unable to saved");
          that.notify.error('Unable to Save' + failure);
        });
      }

    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/records/patient/profession", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'Attachment'
    },

    model(params) {
      var curPatient = this.modelFor("hmis-app.records.patient");
      console.log('not null', !null);
      return Ember.RSVP.hash({
        patientDoc: this.get('store').query('patient-attachment', {
          filter: {
            patientId: curPatient.id
          }
        }),
        patientProcedureDoc: this.get('store').query('procedure-document', {
          filter: {
            patientId: curPatient.id
          }
        }),
        patientUltrasoundDoc: this.get('store').query('ultrasound-document', {
          filter: {
            patientId: curPatient.id
          }
        }),
        patientXrayDoc: this.get('store').query('xray-document', {
          filter: {
            patientId: curPatient.id
          }
        }),
        patient: curPatient
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      } //

    }
  }); // return this.get('store').findRecord('procedure',params.record_id)
  //             .then(function(qp){
  //                 return qp
  //         })


  _exports.default = _default;
});
define("hmis/utils/app-config/report-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ReportTypes = [{
    topic: 'OPD consultation',
    subs: [{
      id: 'r1',
      name: 'Diagnosis'
    }, {
      id: 'r2',
      name: 'Top 10 Diagnosis'
    }, {
      id: 'r3',
      name: 'Purpose of Visits'
    }],
    levels: 2
  }, {
    topic: 'IPD',
    subs: [{
      id: 'r4',
      name: 'Admissions'
    }, {
      id: 'r5',
      name: 'Top 20 Admissions'
    }],
    levels: 2
  }, {
    topic: 'CHAG',
    subs: [{
      id: 'r6',
      name: 'Purpose of Visits'
    }, {
      id: 'r7',
      name: 'Admissions'
    }],
    levels: 0
  }
  /*{
    topic: 'Stuffs',
    subs: [
      {id: 'staffs', name: 'Stuffs'},
    ]
  },
  {
    topic: 'Bills',
    subs: [
      {id: 'issued', name: 'Issued'},
      {id: 'paid', name: 'Paid'},
    ]
  },
  {
    topic: 'NHIS claims ',
    subs: [
      {id: 'submitted', name: 'Submitted'},
      {id: 'reimbursed', name: 'Reimbursed'},
    ]
  },
  {
    topic: 'PHI claims ',
    subs: [
      {id: 'submitted', name: 'Submitted'},
      {id: 'reimbursed', name: 'Reimbursed'},
    ]
  },*/
  ];
  var _default = ReportTypes;
  _exports.default = _default;
});
define("hmis/utils/download-util", ["exports", "jquery", "hmis/config/environment", "hmis/utils/server-util"], function (_exports, _jquery, _environment, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.exportBillToPDF = exportBillToPDF;
  _exports.printBill = printBill;
  _exports.default = void 0;
  const url = _serverUtil.serverUrl;

  function exportBillToPDF(billId) {
    window.open(url + '/exportBillToPdf/' + billId, '_blank');
  }

  function printBill(billId) {
    _jquery.default.get(url + '/printBill/' + billId).then(response => {
      // let content = "<html><head><title></title></head><body> i am to be printed </body>"
      let w = window.open();
      w.document.write(response);
      w.print();
      w.close();
    });
  }

  var _default = {
    exportBillToPDF,
    printBill
  };
  _exports.default = _default;
});
define("hmis/models/department-service", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    departmentUnit: (0, _relationships.belongsTo)('department-unit'),
    // , { async: false }
    serviceType: (0, _relationships.belongsTo)('service-type'),
    //, { async: false }
    departmentUnitName: _emberData.default.attr(),
    serviceTypeName: _emberData.default.attr(),
    departmentName: _emberData.default.attr()
  });

  _exports.default = _default;
});
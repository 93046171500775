define("hmis/controllers/hmis-app/opd/consultation/consult/episodes", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller", "hmis/utils/common-data-columns", "hmis/mixins/visit-care-controller"], function (_exports, _modelTableTheme, _formController, _commonDataColumns, _visitCareController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, _visitCareController.default, {
    store: Ember.inject.service('store'),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    currentUser: Ember.inject.service(),
    serverResponse: Ember.inject.service(),
    app: Ember.inject.service(),
    pickers: Ember.inject.service(),
    appAjax: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    diagnosisCols: _commonDataColumns.diagnosisColumns,
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      clickRecord() {},

      rowClick(index, record) {// this.send("viewRecord",record)
      },

      viewRecord(record) {
        this.set('selectedBatch', record);
        this.set('showRecord', true);
      },

      optionClick(option, record, options) {
        this.send(option.action, record, option, options);
      },

      endEpisode(record, option, options) {
        this.endEpisodeAlert.show({
          episodeId: record.id,
          source: this.model.source
        }, () => {
          Ember.set(option, 'hidden', true);
          this.send('refreshModel');
        }, () => {});
      },

      startNewEventFromEpisode(record, option, options) {
        this.container.startLoading();
        this.appAjax.post("startNewEventFromEpisode/".concat(record.id), {}).then(res => {
          this.notify.success('New Event has been created');
          options.forEach(opt => {
            if (opt.action == 'startNewEventFromEpisode' || opt.action == 'addEpisodeToExistingEvent') Ember.set(opt, 'hidden', true);
          });
        }).catch(err => {
          this.notify.error(err);
        }).finally(() => {
          this.container.stopLoading();
        });
      },

      addEpisodeToExistingEvent(record, option, options) {
        this.pickers.serverTable.show({
          modelName: 'visit-event',
          columns: _commonDataColumns.eventsColumns,
          title: 'Select the Event',
          query: {
            filter: {
              patientId: this.model.patientId
            },
            include: ['episodesCount', 'visitsCount']
          },
          selectable: true
        }, event => {
          this.container.startLoading();
          this.appAjax.post("addEpisodeToExistingEvent/".concat(record.id, "/").concat(event.id), {}).then(res => {
            this.notify.success('Episode has been added to Event');
            options.forEach(opt => {
              if (opt.action == 'startNewEventFromEpisode' || opt.action == 'addEpisodeToExistingEvent') Ember.set(opt, 'hidden', true);
            });
          }).catch(err => {
            this.notify.error(err);
          }).finally(() => {
            this.container.stopLoading();
          });
        }, () => {});
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    rowOptions: [{
      label: "End Episode",
      inline: true,
      class: 'primary-outline',
      action: "endEpisode",
      hideWhen: (record, value) => record.lastVisitDate
    }, {
      label: 'Start a new Event',
      action: 'startNewEventFromEpisode',
      hideWhen: (record, value) => record.eventId
    }, {
      label: 'Add to an Existing Event',
      action: 'addEpisodeToExistingEvent',
      hideWhen: (record, value) => record.eventId
    }],
    columns: _commonDataColumns.episodeColumns.concat([{
      'title': '',
      "component": "manageRow"
    }])
  });

  _exports.default = _default;
});
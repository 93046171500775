define("hmis/mixins/data-serializer-bk", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    keyForAttribute: function (attr) {
      if (attr == 'createdAt' || attr == 'updatedAt') return Ember.String.underscore(attr);else {
        return Ember.String.camelize(attr);
      }
    },

    keyForRelationship(key, relationship, method) {
      //going to
      // console.log('some relationship', relationship)
      if (relationship == 'belongsTo') return key + "Id";
      return key;
    },

    normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      payload[primaryModelClass.modelName] = payload.data;
      delete payload.data;
      console.log('payload', payload);
      return this._super(store, primaryModelClass, payload, id, requestType);
    },

    serializeIntoHash(data, type, record, options) {
      Ember.assign(data, this.serialize(record, options));
    }

  });

  _exports.default = _default;
});
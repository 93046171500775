define("hmis/templates/components/forms/staff-specialty-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G5aIr/ZF",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[4,\"alerts/form-dialog\",null,[[\"title\",\"waiting\",\"departmentVal\",\"show\",\"width\",\"height\",\"onConfirm\",\"onCancel\"],[[24,[\"alertTitle\"]],[24,[\"waiting\"]],[24,[\"departmentVal\"]],[24,[\"show\"]],\"60%\",\"50%\",[28,\"action\",[[23,0,[]],\"onRecordSubmit\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null]]],{\"statements\":[[0,\"    \"],[4,\"app-select\",null,[[\"class\",\"searchField\",\"searchEnabled\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"input_width\"]],\"name\",true,\"Specialty\",[24,[\"record\",\"specialty\"]],[24,[\"specialities\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"specialty\"]]],null]],null]]],{\"statements\":[[0,\" \"],[1,[23,1,[\"noun\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[1,[28,\"paper-input\",null,[[\"type\",\"class\",\"value\",\"onChange\"],[\"hidden\",[24,[\"input_width\"]],[24,[\"record\",\"user\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"user\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/forms/staff-specialty-alert.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/opd/consultation/consult/prescriptions", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller", "hmis/mixins/visit-care-controller", "hmis/mixins/app-event-mixin", "hmis/mixins/page-error-handler"], function (_exports, _modelTableTheme, _formController, _visitCareController, _appEventMixin, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, _visitCareController.default, _appEventMixin.default, {
    store: Ember.inject.service('store'),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    notify: Ember.inject.service(),
    serverResponse: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    app: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    tabStatus: Ember.computed('oPerms', function () {
      return this.get('oPerms.op21.allow') ? 0 : 1;
    }),
    checkStock: Ember.computed('medicine', function () {
      let gdrg = this.get('medicine') ? this.get('medicine').gdrgCode : null;

      if (gdrg) {
        console.log('gdrg', gdrg);
        this.set('saving', true);
        this.appAjax.getArray("/checkPrescriptionStockLevels/" + gdrg).then(data => {
          this.set('stockCard', data[0]);
          this.set('saving', false);
        }).catch(err => {
          this.notify.error(err);
          this.set('saving', false);
        });
      }
    }),
    medicineOptions: Ember.computed('model', function () {
      return this.get('store').findAll('medicine', {}).then(function (recs) {
        return recs;
      });
    }),
    dosageTypeOptions: Ember.computed('model', function () {
      return this.get('store').findAll('dosage-type', {}).then(function (recs) {
        return recs;
      });
    }),
    frequencyTypeOptions: Ember.computed('model', function () {
      return this.get('store').findAll('frequency-type', {}).then(function (recs) {
        return recs;
      });
    }),
    durationTypeOptions: Ember.computed('model', function () {
      return this.get('store').findAll('duration-type', {}).then(function (recs) {
        return recs;
      });
    }),
    batchItems: {},
    selectedPrescription: {},

    onUserNotification(e) {
      console.log('lalaal', e);

      if (e.data.visitId == this.model.visitId) {
        this.send('refreshModel');
      }
    },

    actions: {
      checkStoclevels(record) {
        this.set('quantity', parseInt(record));

        if (this.quantity >= this.stockCard.stockOnHand) {
          this.notify.warning('Quantity prescribed exceeds stock levels (' + this.stockCard.stockOnHand + ' units)');
        }
      },

      back() {
        history.back();
      },

      onNewTabClick(route) {
        this.set('tabStatus', 0);
      },

      onHistoryTabClick(route) {
        this.set('tabStatus', 1);
      },

      addRecord() {
        this.get('app').startLoading();
        this.set('saving', true);
        this.get('store').createRecord('prescription', {
          medicine: this.medicine,
          dosage: this.dosage,
          frequency: this.frequency,
          frequencyType: this.frequencyType,
          duration: this.duration,
          durationType: this.durationType,
          quantity: this.quantity,
          source: this.source,
          note: this.note,
          visitId: this.model.visitId
        }).save().then(() => {
          this.get('app').stopLoading();
          this.send('refreshModel');
          this.send('clearForm');
          this.notify.success('Request sent successfully');
        }, err => {
          this.get('app').stopLoading();
          this.notify.error(errr);
        }).finally(() => {
          this.set('saving', false);
        });
      },

      clearForm() {
        this.set('note', '');
        this.set('medicine', null);
        this.set('dosage', null);
        this.set('frequency', null);
        this.set('frequencyType', null);
        this.set('duration', null);
        this.set('durationType', null);
        this.set('quantity', null);
      },

      deletePrescription(record) {
        let prescriptionsList = this.get("model.prescriptionBatch.prescriptions");
        prescriptionsList.removeObject(record);
        record.destroyRecord();

        if (prescriptionsList.get('length') == 0) {
          this.get('model.prescriptionBatch').destroyRecord();
          this.set('model.prescriptionBatch', null);
          this.set('model.oldPrescriptionBatch', false);
        }
      },

      rowClick(index, record) {
        this.set('selectedPrescription', record);
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        this.viewer.show(record);
      }

    },
    themeInstance: _modelTableTheme.default.create({
      table: 'table'
      /* table:'table table-striped table-bordered table-condensed',*/

    }),
    columns: [{
      title: 'Prescription',
      "propertyName": "medicineName"
    }, {
      title: 'Requested By',
      "propertyName": "requesterName"
    }, {
      title: 'Requested Date',
      "propertyName": "requestedDateTimeF"
    }, {
      title: 'Dispensed By',
      "propertyName": "granterName"
    }, {
      title: 'Dispensed Date',
      "propertyName": "grantedDateTimeF"
    }, {
      title: 'Note',
      "propertyName": "note"
    }, {
      title: 'Status',
      "propertyName": "dispensedString"
    }]
  });

  _exports.default = _default;
});
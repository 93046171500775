define("hmis/models/visit-episode", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/visits-parent-model"], function (_exports, _emberData, _relationships, _dateUtil, _visitsParentModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitsParentModel.default, {
    patientId: _emberData.default.attr(),
    firstVisitId: _emberData.default.attr(),
    lastVisitId: _emberData.default.attr(),
    firstVisitDate: _emberData.default.attr(),
    lastVisitDate: _emberData.default.attr(),
    primaryDiagnosisId: _emberData.default.attr(),
    primaryDiagnosisTypeId: _emberData.default.attr(),
    primaryDiagnosisTypeName: _emberData.default.attr(),
    eventId: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    firstVisitDateF: Ember.computed('firstVisitDate', function () {
      return _dateUtil.default.formatToDateTimeString(this.get('firstVisitDate'));
    }),
    lastVisitDateF: Ember.computed('lastVisitDate', function () {
      if (this.lastVisitDate) return _dateUtil.default.formatToDateTimeString(this.get('lastVisitDate'));else return "ongoing";
    }),
    primaryDiagnosisNameF: Ember.computed('primaryDiagnosisId', 'primaryDiagnosisTypeName', function () {
      if (this.primaryDiagnosisId) return this.primaryDiagnosisTypeName;else return "n/a";
    })
  });

  _exports.default = _default;
});
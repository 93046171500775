define("hmis/routes/hmis-app/admin-settings/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    redirect: function () {
      if (this.get('currentUser.accessLevel') == 0) this.transitionTo('hmis-app.admin-settings.facility-types');else {
        this.transitionTo('hmis-app.admin-settings.scheduler.timeline');
      }
    }
  });

  _exports.default = _default;
});
define("hmis/models/facility-configuration", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    emergencyUnit: (0, _relationships.belongsTo)('ward')
  });

  _exports.default = _default;
});
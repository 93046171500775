define("hmis/components/object-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inputWidth: "flex-45 flex-xs-100 flex-sm-100",
    spaceOut: false,
    layout: Ember.computed('spaceOut', function () {
      let spaceOut = this.get('spaceOut');
      return ' layout-row layout-wrap ' + (spaceOut ? 'layout-align-space-between' : '');
    }),
    isEmberObject: true,
    viewSpaceOut: true,
    classNames: ['object-viewer'],
    grid: 'four',
    //inputWidth:"flex-20 flex-xs-100 flex-sm-100",
    attributes: Ember.computed('object', function () {
      if (this.get('isEmberObject')) {
        var attrNames = [];
        var obj = this.get('object');
        if (!obj) return {};

        if (obj.eachAttribute) {
          // Get attributes
          obj.eachAttribute(name => attrNames.push(name));
          var attrs = Ember.getProperties(obj, attrNames);
          return attrs;
        } else {
          return this.get('object');
        }
      } else {
        return [];
      }
    })
  });

  _exports.default = _default;
});
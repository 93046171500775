define("hmis/mixins/visit-request-review-route", ["exports", "hmis/utils/visit-service-util", "hmis/utils/general-util"], function (_exports, _visitServiceUtil, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    appAjax: Ember.inject.service(),

    model(params) {
      let docFilter = {};
      docFilter["".concat(this.serviceName, "Id")] = params.record_id;
      let newModel = {
        data: this.appAjax.ajaxGet("/".concat(this.serviceName, "s/").concat(params.record_id), {
          include: [this.getServiceTypeName() + "Name", this.getServiceTypeName() + "GdrgCode"].concat(_visitServiceUtil.visitServiceResultIncludes)
        }),
        documents: this.appAjax.ajaxGet("/".concat(this.serviceName, "Documents"), {
          filter: docFilter
        })
      };
      return (0, _generalUtil.modelHash)(newModel);
    },

    getServiceTypeName() {
      return this.serviceTypeName ? this.serviceTypeName : this.serviceName + "Type";
    },

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('serviceName', this.serviceName);
    },

    actions: {
      refreshModel() {
        this.refresh();
      }

    }
  });

  _exports.default = _default;
});
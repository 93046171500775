define("hmis/routes/hmis-app/facility-management/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    breadCrumb: {
      title: 'Registered Facilities'
    },

    beforeModel()
    /*transition*/
    {
      if (this.get('currentUser.accessLevel') == 2) {
        this.transitionTo('hmis-app.facility-management.facility', this.get('currentUser.facilityId'));
      }
    },

    model() {
      let accessLevel = this.get('currentUser.accessLevel');
      let oPerms = this.get('currentUser.operationalPermissions');
      return Ember.RSVP.hash({
        records: accessLevel == 0 ? this.get('store').query('facility', {
          include: ['typeName'],
          pageSize: 25
        }) : [],
        facility: accessLevel == 2 ? this.store.findRecord('facility', this.get('currentUser.facilityId')) : {}
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
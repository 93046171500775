define("hmis/controllers/hmis-app/admin-settings/role-permissions", ["exports", "jquery", "hmis/utils/app-config"], function (_exports, _jquery, _appConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DS from 'ember-data';
  var _default = Ember.Controller.extend({
    selectedUserType: '',
    store: Ember.inject.service(),
    appData: Ember.inject.service(),
    notify: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    isGroup: true,
    userTypes: Ember.computed(function () {
      return this.get('model.roles');
    }),
    selectedItems: [],
    disableSelection: Ember.observer('selectedItems.lenght', function () {
      // this.set('selectedItems',[]);
      alert('hiii');
    }),
    appModules: Ember.computed('model', function () {
      return _appConfig.default;
    }),
    operationPermissions: Ember.computed('appData.operationPermissions', function () {
      return Object.values(this.appData.operationPermissions);
    }),
    revertCopy: {},
    actions: {
      onRoleChange(role) {
        if (!this.selectedRole) {
          this.send('setSelectedRole', role);
        } else {
          if (this.selectedRole.hasChanges) {
            console.error('Role has changes');
            this.notify.error('Current Role has changes. Save or revert first');
          } else {
            this.send('setSelectedRole', role);
          }
        }
      },

      setSelectedRole(role) {
        this.set('revertCopy', {
          modulePermissions: JSON.parse(JSON.stringify(role.modulePermissions)),
          operationPermissions: JSON.parse(JSON.stringify(role.operationPermissions))
        });
        this.set('selectedRole', role);
      },

      onSave() {
        this.appAjax.post('/rolePermissions/' + this.selectedRole.id, {
          operationPermissions: this.selectedRole.operationPermissions,
          modulePermissions: this.selectedRole.modulePermissions
        }).then(res => {
          this.send('setSelectedRole', this.selectedRole);
          this.set('selectedRole.hasChanges', false);
        });
      },

      onRevert() {
        if (this.revertCopy.modulePermissions) this.set('selectedRole.modulePermissions', JSON.parse(JSON.stringify(this.revertCopy.modulePermissions)));
        if (this.revertCopy.operationPermissions) this.set('selectedRole.operationPermissions', JSON.parse(JSON.stringify(this.revertCopy.operationPermissions)));
        this.set('selectedRole.hasChanges', false);
      },

      scrollToGroup(groupId) {
        let parentElem = (0, _jquery.default)('#accordion_cont');
        let childElem = (0, _jquery.default)("#accordion_" + groupId);
        var position = childElem.position().top + parentElem.scrollTop() - parentElem.position().top;
        parentElem.animate({
          scrollTop: position
        });
      }

    }
  });

  _exports.default = _default;
});
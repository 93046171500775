define("hmis/components/mini-app/side-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routing: Ember.inject.service('-routing'),
    currentPathHook: '',

    didInsertElement() {
      this._super(...arguments);

      if (this.get('route') && !this.get('routingOff')) {
        if (this.get('routing.currentRouteName').includes(this.get('route'))) {
          // alert("hiii");
          this.set('active', true);
        } else {
          this.set('active', false);
        }
      }
    },

    // currentPathDidChange: function() {
    //     alert(this.get('navigator.currentPath'));
    //   }.observes('navigator.currentPath'),
    currentMenuChanged: Ember.observer('routing.currentRouteName', function () {
      if (this.get('route') && !this.get('routingOff')) {
        if (this.get('routing.currentRouteName').includes(this.get('route'))) {
          // alert("hiii");
          this.set('active', true);
        } else {
          this.set('active', false);
        }
      }
    }),
    actions: {
      click(event) {
        if (!this.get('disabled')) {
          this.attrs.onMenuClick(this.get('route'), this.get('title'));
        }
      },

      onArrowClick(event) {
        this.set("isSubShowing", !this.get("isSubShowing"));
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/questionnaire/q-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    editQuest: {},
    notify: Ember.inject.service(),
    reviewValueSet: {},
    actions: {
      onReview() {},

      onChange() {
        let moItems = JSON.stringify(JSON.parse(this.get('model.items')));
        let editItems = JSON.stringify(this.get('editQuest.items'));
        this.set('hasChange', moItems != editItems || this.get('model.title') != this.get('editQuest.title') || this.get('model.description') != this.get('editQuest.description') || this.get('model.patientInfo') != this.get('editQuest.patientInfo'));
      },

      onRevert() {
        this.set('editQuest', this.get('model').toStructure());
        this.set('hasChange', false);
      },

      onPublish() {
        let cTitle = 'Attempting to ' + (this.get('model.status') == 'published' ? 'Unpublish' : 'Publish') + ' this questionnaire';
        this.get('notify').confirm(cTitle, null, () => {
          if (this.get('model.status') == 'template') {
            console.error('Cant publish a template');
            return;
          }

          this.set('model.status', this.get('model.status') == 'published' ? 'draft' : 'published');
          this.send('onSave');
        });
      },

      onDelete() {
        let record = this.get('model');
        let cTitle = 'Attempting to delete \'' + record.get('title') + "\'";
        this.get('notify').confirm(cTitle, null, () => {
          record.destroyRecord();
          this.send('back');
        });
      },

      onSaveAsDraft() {
        let cTitle = 'Attempting to convert to draft';
        this.get('notify').confirm(cTitle, null, () => {
          this.set('model.status', 'draft');
          this.send('onSave');
        });
      },

      onSaveAsTemplate() {
        let cTitle = 'Attempting to convert to Template';
        this.get('notify').confirm(cTitle, null, () => {
          this.set('model.status', 'template');
          this.send('onSave');
        });
      },

      onSave() {
        this.get('model').saveStructure(this.get('editQuest'), () => {
          this.set('waiting', false);
          this.set('hasChange', false);
          this.get('notify').success("Saved");
        }, () => {
          this.set('showEdit', false);
          this.get('notify').error("Failed to save");
        });
      },

      back() {
        history.back();
      }

    }
  });

  _exports.default = _default;
});
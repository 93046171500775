define("hmis/templates/components/controls/dropdown-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OZ5bXQZK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"dd-content\"],[8],[0,\" \\n    \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/dropdown-content.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/no-data-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sFt68Ydb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"no-data-message\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"loading\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"svg-jar\",[\"loading\"],[[\"class\",\"width\",\"height\"],[\"icon dark-svg\",\"60px\",\"50px\"]]],false],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"message\"],[8],[0,\"Loading..\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"svg-jar\",[\"info\"],[[\"class\",\"width\",\"height\"],[\"icon dark-svg\",[24,[\"csize\",\"width\"]],[24,[\"csize\",\"height\"]]]]],false],[0,\"\\n    \"],[7,\"p\",true],[11,\"class\",[29,[\"message \",[22,\"size\"]]]],[8],[1,[22,\"message\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/no-data-message.hbs"
    }
  });

  _exports.default = _default;
});
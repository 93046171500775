define("hmis/templates/hmis-app/admin-settings/facility-level-codes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+F1ZiLkE",
    "block": "{\"symbols\":[\"filteredList\",\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n    \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onItemClick\",\"icon\"],[\"New\",[28,\"action\",[[23,0,[]],\"newRecord\"],null],\"/assets/image/toolbar/add-button-primary.svg\"]]],false],[0,\"\\n    \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"icon\"],[\"Delete\",\"/assets/image/toolbar/delete-primary.svg\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n        \\n        \"],[7,\"div\",true],[10,\"class\",\"full-stats-container full-height\"],[8],[0,\"\\n\"],[4,\"list-filter\",null,[[\"class\",\"placeholder\",\"data\",\"filterParams\"],[\"full-height\",\"Search\",[24,[\"model\"]],[28,\"array\",[\"name\"],null]]],{\"statements\":[[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"                    \"],[1,[28,\"controls/list-item\",null,[[\"main\",\"record\",\"onClick\",\"onEdit\",\"onDelete\"],[[23,2,[\"name\"]],[23,2,[]],[28,\"action\",[[23,0,[]],\"viewRecord\"],null],[28,\"action\",[[23,0,[]],\"editRecord\"],null],[28,\"action\",[[23,0,[]],\"deleteRecord\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/admin-settings/facility-level-codes/index.hbs"
    }
  });

  _exports.default = _default;
});
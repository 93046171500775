define("hmis/components/alerts/prompt-dialog", ["exports", "hmis/mixins/ref-component"], function (_exports, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    tagName: '',
    alertTag: 'div',
    title: 'Confirmation required',
    message: 'Do you want to continue?',
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    tag: '',

    // vOb: observer('show',function(){
    //     this.set('tempVal', this.get('value'))
    //     this.set('iniVal', this.get('value'))
    // }),
    show(options, promptCallback, onCancelCallback) {
      if (!promptCallback) {
        console.error('no promptCallback passed');
        return;
      }

      this.set('onConfirm', promptCallback);
      this.set('onCancel', onCancelCallback);
      this.set('iniVal', options.initialValue);
      this.set('tempVal', options.initialValue);
      this.set('title', options.title);
      this.set('textArea', options.textArea);
      this.set('message', options.message);
      this.set('confirmText', options.confirmText ? options.confirmText : "Done");
      this.set('_show', true);
    },

    actions: {
      onConfirm() {
        this.onConfirm && this.onConfirm(this.iniVal);
      },

      onCancel() {
        this.set('iniVal', this.get('tempVal'));
        this.onCancel && this.onCancel();
      }

    }
  });

  _exports.default = _default;
});
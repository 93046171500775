define("hmis/mixins/visit-request-review-controller", ["exports", "ember-data", "hmis/utils/date-util", "hmis/utils/string-util", "hmis/mixins/page-error-handler"], function (_exports, _emberData, _dateUtil, _stringUtil, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_pageErrorHandler.default, {
    appAjax: Ember.inject.service(),
    serverUploads: Ember.inject.service(),
    fileManager: Ember.inject.service(),
    notify: Ember.inject.service(),
    actions: {
      uploadDocument() {
        this.fileManager.pick({
          type: "image",
          accepts: "image/*, application/pdf"
        }, file => {
          let param = {
            name: file.name.split('.')[0],
            visitId: this.model.data.visitId,
            type: file.get('type')
          };
          param[this.serviceName + 'Id'] = this.model.data.id;
          this.appAjax.postFile("".concat(this.serviceName, "Documents"), file, param).then(res => {
            this.send('refreshModel');
          });
        }, null);
      },

      onRowClick(index, record) {
        console.log(record);
        this.fileManager.preview(record.location, {
          title: record.name
        });
      },

      onEditClick(even) {
        this.set('isEdit', true);
      },

      onCancelClick(event) {
        this.set('isEdit', false);
      },

      deleteRecord(record) {
        this.appAjax.delete("".concat(this.serviceName, "Documents/").concat(record.id)).then(response => {
          this.set('deleteWaiting', false);
          this.send('refreshModel');
        }).catch(err => {
          this.set('deleteWaiting', false);
          this.notify.error(err);
        });
      },

      downloadRecord(record) {
        let url = this.serverUploads.downloadUrl + '/' + record.location;
        this.appAjax.downloadFile(url, record.name);
      },

      saveResult(event) {
        if (!this.model.data.resultNote || !this.model.data.resultNote.length) {
          this.notify.error("Note field is empty");
          return;
        }

        this.notify.confirmSimple('You will not be able to make future changes after saving', null, () => {
          let procedure = this.get('model');
          this.set('saveWaiting', true);
          let data = {
            resultNote: this.model.data.resultNote
          };
          this.appAjax.post("/apply".concat((0, _stringUtil.capitalizeFirstLetter)(this.serviceName), "/").concat(this.model.data.id), data).then(response => {
            this.set('isEdit', false);
            this.set('saveWaiting', false);
            this.send('refreshModel');
            this.notify.success('Saved');
            this.send('refreshModel');
          }).catch(err => {
            this.set('saveWaiting', false);
            this.notify.error(err);
          });
        });
      }

    }
  });

  _exports.default = _default;
});
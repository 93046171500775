define("hmis/controllers/hmis-app/opd/consultation/consult/investigations", ["exports", "hmis/utils/model-table-theme", "hmis/utils/date-util", "hmis/mixins/form-controller", "hmis/mixins/app-event-mixin", "hmis/mixins/visit-care-controller"], function (_exports, _modelTableTheme, _dateUtil, _formController, _appEventMixin, _visitCareController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import modelTableTheme from '../../../../../utils/model-table-theme';
  var _default = Ember.Controller.extend(_formController.default, _visitCareController.default, _appEventMixin.default, {
    store: Ember.inject.service('store'),
    input_width: "flex-40 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    requestStatus: Ember.computed('oPerms', function () {
      return this.get('oPerms.op22.allow') ? 0 : 1;
    }),
    notify: Ember.inject.service(),
    serverResponse: Ember.inject.service(),

    onUserNotification(e) {
      console.log('lalaal', e);

      if (e.data.visitId == this.model.visitId) {
        this.send('refreshModel');
      }
    },

    data: Ember.computed('requestStatus', 'model.requestData', 'model.resultData', function () {
      let requestStatus = this.get('requestStatus');
      if (requestStatus == 1) return this.get('model.requestData');else return this.get('model.resultData');
    }),
    actions: {
      back() {
        history.back();
      },

      addRecord() {
        this.set('saving', true);
        this.get('store').createRecord('investigation', {
          visitId: this.model.visitId,
          investigationType: this.investigationType,
          requestNote: this.note,
          source: this.model.source
        }).save().then(savedInv => {
          this.send('refreshModel');
          this.set('note', '');
          this.notify.success('Request sent');
        }).catch(failure => {
          console.error(failure);
          this.notify.error(this.get('serverResponse').errorMessage(failure));
        }).finally(() => {
          this.set('saving', false);
        });
      },

      // searchInvestigations(term){
      //   this.get('store').query('diagnosis-type', {search: term, filter: { mdgCode:this.majorDiagnosisGroup.id} } )
      //   .then((recs)=> { this.set('diagnosisTypes', recs) })
      // },
      onRowClick(index, dataItem) {
        this.viewer.show(dataItem);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }) // columns: [
    //   {
    //     'title':'Investigation Type',
    //     "propertyName": "investigationTypeName",
    //     className:'main-column'
    //   },
    //   {
    //       'title':'Patient',
    //     "propertyName": "patientName"
    //   },
    //   {
    //       'title':'Requested By',
    //     "propertyName": "requesterName"
    //   },
    //   {
    //     'title':'Request Date',
    //     "propertyName": "requestedDateTimeF"
    //   },
    //   {
    //     'title':'Performed By',
    //     "propertyName": "granterName"
    //   },
    //   {
    //     'title':'Result Date',
    //     "propertyName": "grantedDateTimeF"
    //   },
    // ],

  });

  _exports.default = _default;
});
define("hmis/models/patient-insurance", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    patient: (0, _relationships.belongsTo)('patient'),
    insuranceProduct: (0, _relationships.belongsTo)('insurance-product'),
    insuranceCompany: (0, _relationships.belongsTo)('insurance-company'),
    insuranceNumber: _emberData.default.attr(),
    issueDate: _emberData.default.attr(),
    expiryDate: _emberData.default.attr(),
    threshold: _emberData.default.attr(),
    balance: _emberData.default.attr(),
    insuranceName: _emberData.default.attr(),
    insuranceProductName: _emberData.default.attr(),
    insuranceCompanyName: _emberData.default.attr()
  });

  _exports.default = _default;
});
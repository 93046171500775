define("hmis/components/controls/paged-report-table-rows", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    lastChildRow: Ember.computed('reportDataRows', function () {
      if (!this.get('reportDataRows')) return -1;
      return Number(this.get('reportDataRows').length) - 1;
    })
  });

  _exports.default = _default;
});
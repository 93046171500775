define("hmis/routes/hmis-app/users/add-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: {
      title: 'New System User'
    },

    model(params) {
      // return {
      // }
      return this.store.createRecord('user', {
        isSystemUser: true
      });
    },

    deactivate: function () {
      if (this.currentModel.get('isNew')) {
        if (this.get('currentModel').rollbackAttributes) this.get('currentModel').rollbackAttributes(); // this.get('currentModel').deleteRecord();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/dashboard/chart-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SFQXn52y",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"high-charts\",null,[[\"mode\",\"chartOptions\",\"content\",\"theme\"],[[24,[\"mode\"]],[24,[\"chartOptions\"]],[24,[\"chartData\"]],[24,[\"theme\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/dashboard/chart-one.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/hmis-side-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6rZzeWWE",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"hmis-side-menu-item \",[28,\"if\",[[24,[\"isActive\"]],\"active\",\"\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"badge\"]]],null,{\"statements\":[[0,\"        \"],[7,\"span\",true],[10,\"class\",\"floating ui orange label circular menu-badge\"],[8],[1,[22,\"badge\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"side-menu-item-text\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"module\",\"id\"]],\"13\"],null]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"currentUser\",\"accessLevel\"]],2],null]],null,{\"statements\":[[0,\"          Registered Facility\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[1,[24,[\"module\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[24,[\"module\",\"name\"]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"module\",\"hasChildren\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"side-menu-item-arrow text-center\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onArrowClick\"],null]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isSubShowing\"]]],null,{\"statements\":[[0,\"                \"],[7,\"i\",true],[10,\"class\",\"fa fa-angle-up\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                \"],[7,\"i\",true],[10,\"class\",\"fa fa-angle-down\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"count\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"side-menu-item-count\"],[8],[7,\"p\",true],[8],[1,[22,\"count\"],false],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/hmis-side-menu-item.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/list-filter-no-elem-server-paginated", ["exports", "hmis/utils/list-util"], function (_exports, _listUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    filterString: Ember.computed.alias('query'),
    pageSize: Ember.computed.alias('pageLimit'),
    currentPageNumber: Ember.computed.alias('pageIndex'),
    app: Ember.inject.service(),
    debounceDataLoadTime: 500,
    observedProperties: Ember.computed(function () {
      return ['currentPageNumber', 'pageSize', 'filterString'];
    }),
    lastSearchedFilteredContent: null,

    doQuery(store, modelName, query) {
      if (this.get('onQueryStart')) this.get('onQueryStart')();
      if (this.get('onQueryDo')) this.get('onQueryDo')(true);
      this.get('app').startLoading();
      return store.query(modelName, query).then(newData => {
        if (this.get('onQueryDone')) this.get('onQueryDone')(newData);
        Ember.set(this, 'lastSearchedFilteredContent', newData);
        Ember.set(this, 'filteredContent', newData);
      }).finally(err => {
        if (this.get('onQueryDo')) this.get('onQueryDo')(false);
        this.get('app').stopLoading();
      });
    },

    arrangedContentLength: Ember.computed('filteredContent.meta', function () {
      let meta = Ember.get(this, 'filteredContent.meta') || {};
      return Ember.get(meta, 'itemsCount') || 0;
    }),
    pagesCount: Ember.computed('filteredContent.meta', function () {
      let meta = Ember.get(this, 'filteredContent.meta') || {};
      return Ember.get(meta, 'pagesCount') || 1;
    }),
    lastIndex: Ember.computed('pageSize', 'currentPageNumber', 'arrangedContentLength', function () {
      let pageMax = parseInt(Ember.get(this, 'pageSize'), 10) * Ember.get(this, 'currentPageNumber');
      let itemsCount = Ember.get(this, 'arrangedContentLength');
      return Math.min(pageMax, itemsCount);
    }),
    _loadData: function () {
      let data = Ember.get(this, 'data');
      let currentPageNumber = Ember.get(this, 'currentPageNumber');
      let pageSize = Ember.get(this, 'pageSize');
      let columns = Ember.get(this, 'processedColumns');
      let filterString = Ember.get(this, 'filterString');

      if (!Ember.get(data, 'query')) {
        warn('You must use http://emberjs.com/api/data/classes/DS.Store.html#method_query for loading data', false, {
          id: '#emt-query-usage'
        });
        return;
      }

      let query = Object.assign({}, Ember.get(data, 'query'));
      let store = Ember.get(data, 'store');
      let modelName = Ember.get(data, 'type.modelName'); // Add pagination information

      query['page'] = currentPageNumber;
      query['pageSize'] = pageSize; // Add global filter

      if (filterString) {
        query['search'] = filterString;
      } else {
        delete query['saerch'];
      }

      Ember.setProperties(this, {
        isLoading: true,
        isError: false
      });
      return this.doQuery(store, modelName, query).then(() => Ember.setProperties(this, {
        isLoading: false,
        isError: false
      })).catch(() => Ember.setProperties(this, {
        isLoading: false,
        isError: true
      }));
    },

    didReceiveAttrs() {
      Ember.set(this, 'filteredContent', this.lastSearchedFilteredContent ? this.lastSearchedFilteredContent : this.data);
    },

    _addPropertyObserver() {
      Ember.run.debounce(this, this._loadData, Ember.get(this, 'debounceDataLoadTime'));
    },

    willInsertElement() {
      this._super(...arguments);

      let observedProperties = Ember.get(this, 'observedProperties');
      observedProperties.forEach(propertyName => this.addObserver(propertyName, this._addPropertyObserver));
    },

    willDestroyElement() {
      this._super(...arguments);

      let observedProperties = Ember.get(this, 'observedProperties');
      observedProperties.forEach(propertyName => {
        this.removeObserver(propertyName, this._addPropertyObserver);
      });
    }

  });

  _exports.default = _default;
});
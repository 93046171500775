define("hmis/components/controls/models-table", ["exports", "hmis/components/models-table"], function (_exports, _modelsTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelsTable.default.extend({
    actions: {
      clickOnRow(index, dataItem) {
        // assert('row index should be numeric', typeOf(index) === 'number');
        if (this.get('selectRowOnClick')) {
          let multipleSelect = this.get('multipleSelect');
          let selectedItems = this.get('selectedItems');

          if (selectedItems.includes(dataItem)) {
            selectedItems.removeObject(dataItem);
          } else {
            if (!multipleSelect && selectedItems.get('length') === 1) {
              this.get('selectedItems').clear();
            }

            this.get('selectedItems').pushObject(dataItem);
          }
        }

        let action = this.get('rowClickAction');
        let actionIsFunction = typeof action === 'function';

        if (actionIsFunction) {
          action(index, dataItem);
        }

        this.userInteractionObserver();
      }

    }
  });

  _exports.default = _default;
});
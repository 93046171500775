define("hmis/components/alerts/confirm-options", ["exports", "hmis/mixins/ref-component"], function (_exports, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    notify: Ember.inject.service(),
    tagName: '',
    isConfirm: true,
    title: 'Choose an option',
    message: '',
    closeOnConfirm: false,
    confirmText: 'Save',
    cancelText: 'Cancel',
    footerInfo: '',
    contentPadding: true,

    confirm(options, onConfirm, onCancel) {
      this.set('selectedOption', null);
      this.set('title', options.title || 'Choose an option');
      this.set('message', options.message);
      this.set('options', options.options);
      this.set('onConfirm', onConfirm);
      this.set('onCancel', onCancel);
      this.set('list', options.list);
      this.set('show', true);
    },

    actions: {
      onConfirm() {},

      onSelect(item) {
        this.set('selectedOption', item);
        this.set('show', false);
        this.onConfirm(item);
      },

      onCancel() {
        this.onCancel && this.onCancel();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/constants/referral-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REFERRABLE_TYPES = _exports.ReferalTypeDetails = _exports.REFERRAL_TYPES = void 0;
  const REFERRAL_TYPES = {
    INTERNAL_DEPARTMENTAL: 1,
    EXTERNAL_SYSTEM_FACILITY: 2,
    //facility picked from the system
    EXTERNAL_NON_SYSTEM_FACILITY: 3 //facility outside the system

  };
  _exports.REFERRAL_TYPES = REFERRAL_TYPES;
  const ReferalTypeDetails = [{
    title: "Internal",
    description: 'inter-departmental referals',
    id: REFERRAL_TYPES.INTERNAL_DEPARTMENTAL
  }, {
    title: "External",
    description: 'transfer from this facility to another facility'
  }];
  _exports.ReferalTypeDetails = ReferalTypeDetails;
  const REFERRABLE_TYPES = {
    ACTIVE_SERVE: 'active-serve'
  };
  _exports.REFERRABLE_TYPES = REFERRABLE_TYPES;
});
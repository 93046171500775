define("hmis/routes/hmis-app/diagnostics/investigations/index", ["exports", "hmis/mixins/visit-care-route", "hmis/mixins/route-error-handler", "hmis/utils/general-util"], function (_exports, _visitCareRoute, _routeErrorHandler, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_visitCareRoute.default, _routeErrorHandler.default, {
    breadCrumb: {
      title: 'Laboratory & Other Investigations'
    },
    serviceName: 'investigation',

    model(params) {
      return (0, _generalUtil.modelHash)({
        requestData: this.get('store').query('investigation', {
          filter: {
            status: 0
          },
          include: ['investigationTypeName', 'patientName', 'requesterName', 'granterName']
        }),
        resultData: this.get('store').query('investigation', {
          filter: {
            status: 1
          },
          include: ['investigationTypeName', 'patientName', 'requesterName', 'granterName']
        })
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/models/chronic-condition", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    name: _emberData.default.attr(),
    type: (0, _relationships.belongsTo)('diagnosis-major-group'),
    patient: (0, _relationships.belongsTo)('patient', {
      async: false
    }),
    typeName: _emberData.default.attr(),
    typeName: _emberData.default.attr(),
    diagnosisSubType: (0, _relationships.belongsTo)('diagnosis-sub-type'),
    diagnosisSubTypeIcdName: _emberData.default.attr(),
    diagnosisSubTypeIcdCode: _emberData.default.attr(),
    note: _emberData.default.attr(),
    idcDiagnosis: Ember.computed('diagnosisSubType.icdCode', 'diagnosisSubType.icdName', function () {
      return this.get('diagnosisSubType.id') ? "".concat(this.get('diagnosisSubType.icdCode'), " - ").concat(this.get('diagnosisSubType.icdName')) : '';
    }),
    mdgText: Ember.computed('diagnosisSubType.diagnosisTypeText', function () {
      return "".concat(this.get('diagnosisSubType.diagnosisTypeText'));
    })
  });

  _exports.default = _default;
});
define("hmis/components/file-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function findType(mimeType) {
    let valiImageTypes = ["image/gif", "image/jpeg", "image/png", "image/bmp", "image/jpg", "image/x-windows-bmp", "application/bmp"];

    if (valiImageTypes.includes(mimeType)) {
      return 'image';
    }

    if (mimeType == 'application/pdf') {
      return 'pdf';
    }

    return 'unknown';
  }

  var _default = Ember.Component.extend({
    serverUploads: Ember.inject.service(),
    classNames: ['file-preview'],
    classNameBindings: ['thumbnail:file-preview-thumbnail'],

    init() {
      this._super(...arguments);
    },

    linkLocation: Ember.computed('link', 'isServerUpload', function () {
      let link = this.get('link');

      if (this.get('isServerUpload')) {
        let sLink = this.get('serverUploads.downloadUrl') + '/' + link;
        let parent = this.get('parent');
        if (parent) parent.set('downloadLink', sLink);
        return sLink;
      } else {
        return link;
      }
    }),
    fileInfo: Ember.computed('linkLocation', function () {
      let url = this.get('linkLocation');
      var filename = url.replace(/^.*[\\\/]/, '');
      let ext = filename.split('.').pop();
      let mType = mimetypes.lookup(ext); // console.log('lalal',mType, this.get('thumbnail'));

      let type = findType(mType);
      if (type == 'pdf' && this.get('thumbnail')) type = 'unknown';
      return {
        type: type,
        mimeType: mType,
        extension: ext
      };
    }),
    isImage: Ember.computed('linkLocation', function () {
      let url = this.get('linkLocation');
      var filename = url.replace(/^.*[\\\/]/, '');
      let ext = filename.split('.').pop();
      let mType = mimetypes.lookup(ext);
      let ValidTypes = ["image/gif", "image/jpeg", "image/png", "image/bmp", "image/jpg", "image/x-windows-bmp", "application/bmp"];
      return ValidTypes.includes(mType);
    }),
    // tumbnail:
    wrapperClass: Ember.computed('property', function () {
      let cls = 'fp-wrapper';
      let thumbnail = this.get('thumbnail');

      if (thumbnail) {
        cls += ' fp-thumbnail fp-thumbnail-' + thumbnail;
      }

      return cls;
    }),
    actions: {}
  });

  _exports.default = _default;
});
define("hmis/utils/billable-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.priceReview = priceReview;
  _exports.insuranceProductPriceReview = insuranceProductPriceReview;
  _exports.saveFacilityPrice = saveFacilityPrice;
  _exports.saveFacilityInsurancePrice = saveFacilityInsurancePrice;
  _exports.saveChagInsurancePrices = saveChagInsurancePrices;
  _exports.default = void 0;

  function priceReview(ajaxRequest, billableName, billableId) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      ajaxRequest.makeRequest('GET', "/billablePriceReview/" + billableName + "/" + billableId, null, {
        success: response => {
          resolve(response);
        },
        error: error => {
          reject(error);
        }
      });
    });
  }

  function insuranceProductPriceReview(ajaxRequest, billableName, billableId, insuranceProductId) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      ajaxRequest.makeRequest('GET', "/billableInsurancePriceReview/" + insuranceProductId + "/" + billableName + "/" + billableId, null, {
        success: response => {
          resolve(response);
        },
        error: error => {
          reject(error);
        }
      });
    });
  }

  function saveFacilityPrice(ajaxRequest, billableName, billableId, price) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      ajaxRequest.makeRequest('POST', "/billableFacilityPrice/" + billableName + "/" + billableId, {
        price: price
      }, {
        success: response => {
          resolve(response);
        },
        error: error => {
          reject(error);
        }
      });
    });
  }

  function saveFacilityInsurancePrice(ajaxRequest, billableName, billableId, insuranceProductId, price) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      ajaxRequest.makeRequest('POST', "/billableFacilityInsurancePrice/" + insuranceProductId + "/" + billableName + "/" + billableId, {
        price: price
      }, {
        success: response => {
          resolve(response);
        },
        error: error => {
          reject(error);
        }
      });
    });
  }

  function saveChagInsurancePrices(ajaxRequest, billableName, billableId, insuranceProductId, pricesObject) {
    return new Ember.RSVP.Promise((resolve, reject) => {
      ajaxRequest.makeRequest('POST', "/billableChagInsurancePrices/" + insuranceProductId + "/" + billableName + "/" + billableId, {
        prices: pricesObject
      }, {
        success: response => {
          resolve(response);
        },
        error: error => {
          reject(error);
        }
      });
    });
  }

  var _default = {
    priceReview,
    insuranceProductPriceReview,
    saveFacilityPrice,
    saveFacilityInsurancePrice,
    saveChagInsurancePrices
  };
  _exports.default = _default;
});
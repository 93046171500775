define("hmis/components/controls/high-charts", ["exports", "ember-highcharts/components/high-charts"], function (_exports, _highCharts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _highCharts.default.extend({
    // contentDidChange: Ember.observer('content.@each.isLoaded', function() {
    //   // add redraw logic here. ex:
    //   var chart = this.get('chart');
    //   var seriesName = this.get('content')[0].name;
    //   chart.series[0].update({ name: seriesName, data: this.get('content')[0].data }, false);
    //   chart.setTitle(null, { text: seriesName }, false);
    //   chart.redraw();
    // }),
    chartOptionsDidChange: Ember.observer('chartOptions', function () {
      var chart = this.get('chart');
      let chartOptions = this.get('chartOptions'); //chartOptions.xAxis.categories
      // console.log('chartOptions', chartOptions);
      // if(chartOptions.xAxis){
      //   let categories = chartOptions.xAxis.categories//chartOptions.xAxis.categories
      //   chart.xAxis[0].setCategories(categories);
      // }
      // chart.setTitle({text: chartOptions.title.text})

      chart.update(chartOptions);
      chart.redraw();
    })
  });

  _exports.default = _default;
});
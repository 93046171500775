define("hmis/routes/hmis-app/opd/triage", ["exports", "hmis/mixins/route-error-handler"], function (_exports, _routeErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, {
    data: Ember.inject.service(),
    appAjax: Ember.inject.service(),

    model(params) {
      return {};
    }

  });

  _exports.default = _default;
});
define("hmis/mixins/app-event-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),

    init() {
      this._super(...arguments);

      this._addEvents();
    },

    _addEvents() {
      this.currentUser.on('user-notification', e => {
        this.userNotificaction(e);
      });
      this.currentUser.on('permission-notification', e => {
        this.permissionNotification(e);
      });
    },

    userNotificaction(e) {
      if (this.onUserNotification && !Ember.get(this, 'isDestroyed')) {
        this.onUserNotification(e);
      }
    },

    permissionNotification(e) {
      // console.log('new perm notification');
      if (this.onPermissionNotification && !Ember.get(this, 'isDestroyed')) {
        this.onPermissionNotification(e);
      }
    }

  });

  _exports.default = _default;
});
define("hmis/components/viewers/file-preview-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import mimetypes from "mime-types";
  // import mimetype from 'mime-types'
  var _default = Ember.Component.extend({
    ajaxRequest: Ember.inject.service(),
    actions: {
      downloadFile() {
        let url = this.get('downloadLink');
        var filename = url.replace(/^.*[\\\/]/, '');
        this.get('ajaxRequest').downloadFile(url, filename);
      }

    }
  });

  _exports.default = _default;
});
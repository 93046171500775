define("hmis/controllers/hmis-app/admin-settings/accreditation-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),
    selectedItems: [],
    allowSelect: true,
    data: Ember.computed.alias('model'),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    accreditationInstitution: Ember.computed('record', function () {
      console.log('record.accreditationInstitution', this.get('record.accreditationInstitution')); // return this.get('store').findAll('accreditation-institution')

      return this.get('store').query('accreditation-institution', {});
    }),
    onConfirm: 'deleteRecord',
    newRecord: {},
    record: {},
    actions: {
      back() {
        history.back();
      },

      deleteRecord(record) {
        console.log('ins cj', record);
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete: ' + record.get('name'));
        this.set('onAlert', true);
      },

      deleteNow(record) {
        record.destroyRecord();
        this.send('reloadModel');
      },

      saveRecord(record) {
        record.save().then(record => {
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
          this.send('reloadModel');
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error(failure);
        });
      },

      saveNewRecord() {
        this.set('listWait', true);
        let newRecord = this.get('store').createRecord('accreditation-type', this.get('newRecord'));
        newRecord.save().then(record => {
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
          this.send('reloadModel');
        }).catch(failure => {
          console.error(failure);
          newRecord.destroyRecord();
          this.set('listWait', false);
          this.notify.error(failure);
        });
      },

      reviewPrice(record) {
        this.get('previewBillable')(record, record.get('billableName'));
      },

      deleteRecords() {
        this.get("selectedItems").forEach((user, index) => {
          user.destroyRecord();
        });
        this.set("selectedItems", []);
      },

      bulkUpload() {
        this.set('uploadModal', true);
      },

      showChooser(event) {
        this.set('show', true);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/hmis-app", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gry9thDM",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[1,[28,\"controls/change-password\",null,[[\"show\",\"unClosable\",\"footerInfo\"],[[24,[\"changePassInt\"]],true,\"You need to change the system generated pasword\"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"style\",\"background-color: #F2F2F2;height:100%;\"],[10,\"class\",\"row\"],[8],[0,\"\\n  \"],[7,\"header\",true],[8],[1,[22,\"hmis-top-bar\"],false],[9],[0,\"\\n  \"],[7,\"header\",true],[8],[1,[28,\"hmis-side-menu\",null,[[\"class\",\"isCollaped\",\"currentPathHook\"],[\"full-height\",[24,[\"sideMenuCollapsed\"]],[24,[\"currentPathHook\"]]]]],false],[0,\" \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"style\",\"background-color: #f2f2f2;height:100%;\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[0,\"\\n\"],[0,\"  \\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/table-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3EMkpIXB",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[0,\"\\n\\n\"],[4,\"each\",[[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"h6\",true],[8],[1,[28,\"get\",[[23,1,[]],[24,[\"mainText\"]]],null],false],[9],[0,\"\\n    \"],[7,\"p\",true],[8],[1,[28,\"get\",[[23,1,[]],[24,[\"subText\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/table-list.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/alerts/prompt-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Im27taGC",
    "block": "{\"symbols\":[],\"statements\":[[4,\"alerts/confirm-dialog\",null,[[\"show\",\"title\",\"message\",\"confirmText\",\"cancelText\",\"promptDialog\",\"onConfirm\",\"onCancel\",\"confirmDisabled\",\"cancelDisabled\",\"footerInfo\",\"disableBackdropClick\"],[[24,[\"_show\"]],[24,[\"title\"]],[24,[\"message\"]],[24,[\"confirmText\"]],[24,[\"cancelText\"]],true,[28,\"action\",[[23,0,[]],\"onConfirm\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null],[24,[\"confirmDisabled\"]],[24,[\"cancelDisabled\"]],[24,[\"footerInfo\"]],[24,[\"disableBackdropClick\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"textArea\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"textarea\",null,[[\"class\",\"type\",\"value\"],[\"prompt-input text-area\",[24,[\"type\"]],[24,[\"iniVal\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[24,[\"hideInput\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"input\",null,[[\"class\",\"type\",\"value\",\"disabled\"],[\"prompt-input\",[24,[\"type\"]],[24,[\"iniVal\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/alerts/prompt-dialog.hbs"
    }
  });

  _exports.default = _default;
});
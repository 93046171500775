define("hmis/controllers/hmis-app/admin-settings/insurance-types/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('router'),
    currentUser: Ember.inject.service(),
    serverUploads: Ember.inject.service(),
    serverDownloads: Ember.inject.service(),
    notify: Ember.inject.service(),
    selectedItems: [],
    onConfirm: 'deleteRecord',
    allowSelect: true,
    data: Ember.computed.alias('model'),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    newRecord: {},
    actions: {
      back() {
        history.back();
      },

      deleteRecord(record) {
        console.log('ins', record);
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete: ' + record.get('name'));
        this.set('onAlert', true);
      },

      deleteNow(record) {
        record.destroyRecord();
      },

      saveRecord(record) {
        if (record.name == ' ') {
          //re.set('newRecord.name',null)
          this.send('reloadModel');
          return;
        } else {
          record.save();
        }
      },

      saveNewRecord() {
        if (this.get('newRecord.name') == ' ') {
          this.set('newRecord.name', null);
          return;
        }

        this.set('listWait', true);
        let newRecord = this.get('store').createRecord('insurance-type', this.get('newRecord'));
        newRecord.save().then(record => {
          // this.get('companies').pushObject(record)
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error('Unable to Save');
        });
      },

      reviewPrice(record) {
        this.get('previewBillable')(record, record.get('billableName'));
      },

      deleteRecords() {
        this.get("selectedItems").forEach((user, index) => {
          user.destroyRecord();
        });
        this.set("selectedItems", []);
      },

      bulkUpload() {
        this.set('uploadModal', true);
      },

      showChooser(event) {
        this.set('show', true);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/diagnostics/ultrasound/review", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/visit-request-review-controller"], function (_exports, _modelTableTheme, _visitRequestReviewController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_visitRequestReviewController.default, {
    actions: {
      back() {
        history.back();
      }

    },
    themeInstance: _modelTableTheme.default.create({
      table: 'table'
      /* table:'table table-striped table-bordered table-condensed',*/
      ,
      messages: {
        "noDataToShow": "No documents uploaded"
      }
    }),
    //date , patient, requestor
    columns: [{
      title: 'preview',
      component: 'filePreview'
    }, {
      'title': 'file image',
      "propertyName": "type" //   "component": "checkBox"

    }, {
      'title': 'Name',
      "propertyName": "name",
      className: 'main-column'
    }, {
      'title': 'file type',
      "propertyName": "type" //

    }, {
      'title': 'Date added',
      "propertyName": "dateF"
    }, {
      title: 'Actions',
      component: 'manageRow'
    }]
  });

  _exports.default = _default;
});
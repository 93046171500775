define("hmis/helpers/camelcase-to-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.camelcaseToNames = camelcaseToNames;
  _exports.default = void 0;

  function camelcaseToNames([value, ...rest]) {
    var result = value.replace(/([A-Z])/g, " $1");
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    return finalResult;
  }

  var _default = Ember.Helper.helper(camelcaseToNames);

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/departments/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    departmentSugList: ['Accident and emergency (A&E)', 'Anaesthetics', 'Cardiology', 'Diagnostic imaging', 'Ear nose and throat (ENT)', 'Elderly services department', 'Gastroenterology', 'General surgery', 'Gynaecology', 'Haematology', 'Maternity', 'Microbiology', 'Neonatal', 'Nephrology', 'Nutrition and dietetics', 'Oncology', 'Ophthalmology', 'Orthopaedics', 'Pharmacy', 'Physiotherapy', 'Radiotherapy', 'Rheumatology', 'Sexual health', 'Urology'],
    departmentList: Ember.computed(function () {
      return this.get('store').findAll('department');
    }),
    actions: {
      back() {
        history.back();
      },

      save() {
        let model = this.get('model');
        let store = this.get('store');
        let that = this;
        let post = store.createRecord('department', {
          name: this.get('name'),
          facility: this.get('currentUser.user').belongsTo('facility').id()
        });
        post.save().then(record => {
          that.notify.success('Saved Successfully');
        }).catch(failure => {
          that.notify.error('Unable to Save' + failure);
        });
      },

      clickRecord(record) {
        console.log(record.id);
        let that = this; //that.transitionToRoute("configure.department.manage");
        //this.get("routing").transitionTo(this.get('routing').get('currentRouteName')+".manage",[record.id]);

        this.get("routing").transitionTo("configure.department.manage", [record.id]);
      },

      saveRecord(record) {
        record.save();
      },

      editRecord() {
        console.log('view msg');
      },

      deleteRecord(record) {
        //console.log('del msg')
        record.destroyRecord().then(record => {
          that.notify.success('Deleted Successfully');
        });
      },

      deleteDiagnosis(record) {
        let diagnosesList = this.get("batch.diagnoses");
        diagnosesList.removeObject(record);
        record.destroyRecord();

        if (diagnosesList.get('length') == 0) {
          this.get('batch').destroyRecord();
          this.set('model.oldDiagnosisBatch', false);
        }
      },

      rowClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id');
        console.log('view msg'); // this.get("routing").transitionTo("hmis-app.opd.manage-patient",[rid]);
      }

    }
  });

  _exports.default = _default;
});
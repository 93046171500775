define("hmis/controllers/hmis-app/opd/assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service('store'),
    input_width: "flex-40 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    selectedItems: [],
    notify: Ember.inject.service(),
    patient: Ember.computed(function () {
      return this.get('model.queuedPatient').get('patient');
    }),
    countObserver: Ember.observer("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;

      if (lenght == 1) {
        this.set('oneItemSelected', true);
      } else if (lenght > 0) {
        this.set('oneItemSelected', false);
      } else {
        this.set('oneItemSelected', false);
      }
    }),
    departmentUnits: {},
    depObserver: Ember.observer('department', function () {
      // console.log('department here',this.get('department'))
      let that = this;
      if (this.get('department')) this.get('store').query('department-unit', {
        filter: {
          department: this.get('department.id')
        }
      }).then(function (records) {
        // console.log('department unit here',records)
        that.set('departmentUnits', records);
      });
    }),
    actions: {
      back() {
        history.back();
      },

      assign() {
        // console.log('This is the selected items : ',this.get('selectedItems').get('firstObject'));
        let unit = this.get('selectedItems').get('firstObject');
        let visit = this.get('model.queuedPatient.visit'); // console.log('This is the department unit : ',unit);
        // console.log('This is visit : ', visit);

        var that = this; // let queue = this.get('store').createRecord('queued-patient', {
        //   departmentUnit: unit,
        //   // visit: visit,
        // });

        let queue = this.get('model.queuedPatient');
        queue.set('dateServed', new Date().toISOString().slice(0, 19).replace('T', ' '));
        queue.save().then(function (qu_) {
          let newQueue = that.get('store').createRecord('queued-patient', {
            departmentUnit: unit,
            visit: visit
          });
          newQueue.save().then(function (sQp) {
            that.set('selectedItems', []); //alert('Assigned');

            that.notify.success('Assigned Successfully');
          }); // self.send('back')
        });
      },

      deleteRecord(record) {
        this.get("vitalBatch").removeObject(record);
        record.destroyRecord();
      },

      editRecord(record) {
        this.get("routing").transitionTo("hmis-app.patient-management.edit-patient", [record.id]);
      },

      rowDoubleClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.opd.manage-patient", [rid]);
      },

      deleteRecords() {
        this.get("selectedItems").forEach((patient, index) => {
          patient.destroyRecord();
        });
        this.set("selectedItems", []);
      },

      onSaveRow(param) {
        // return a truthy value (or Promise) for the row to exit edit mode
        return param.record.save();
      },

      // Example of ES6 destructuring parameters
      onCancelRow({
        record
      }) {
        record.rollbackAttributes(); // return a truthy value for the row to exit edit mode

        return true;
      }

    },
    columns: [{
      'title': 'Department Unit',
      "propertyName": "name"
    }, {
      "title": "Staffs Assigned",
      "propertyName": "assinedStaffs"
    }],
    sample: [{
      name: 'Consultation Room 1',
      assinedStaffs: 'Dr Harold, Dr Walter'
    }, {
      name: 'Consultation Room 2',
      assinedStaffs: 'Dr Amoani'
    }]
  });

  _exports.default = _default;
});
define("hmis/router", ["exports", "hmis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('sign-in', function () {// this.route('register');
    });
    this.route('hmis-app', function () {
      this.route('dashboard');
      this.route('bills');
      this.route('opd', function () {
        this.route('triage');
        this.route('consultation', function () {
          this.route('consult', {
            path: '/consult/:record_id'
          }, function () {
            this.route('visit-summary', {
              path: '/visit-summary/:record_id'
            });
            this.route('vital-requests');
            this.route('prescriptions');
            this.route('symptoms');
            this.route('diagnosis');
            this.route('investigations');
            this.route('procedures');
            this.route('xrays');
            this.route('ultrasounds');
            this.route('vitals');
            this.route('referrals');
            this.route('visits');
            this.route('episodes');
            this.route('events');
          });
        });
      });
      this.route('users', function () {
        this.route('add-user');
        this.route('edit-user', {
          path: '/edit-user/:user_id'
        });
        this.route('view-user', {
          path: '/view-user/:user_id'
        });
        this.route('user', {
          path: '/user/:user_id'
        }, function () {
          this.route('profile');
          this.route('specialty');
          this.route('payment');
          this.route('accreditation');
        });
      });
      this.route('facility-management', function () {
        this.route('add-facility');
        this.route('edit-facility', {
          path: '/edit-facility/:facility_id'
        });
        this.route('view-facility', {
          path: '/view-facility/:facility_id'
        });
        this.route('manage-ward');
        this.route('manage-department'); // this.route('facility', function() {
        //   this.route('services');
        //   this.route('specialist');
        //   this.route('general');
        // });

        this.route('facility', {
          path: '/facility/:facility_id'
        }, function () {
          this.route('services');
          this.route('specialist');
          this.route('general');
          this.route('bank');
          this.route('accreditation');
          this.route('payment');
        });
      }); // this.route('facility', function() {
      //   this.route('view-facility');
      // });

      this.route('records', function () {
        this.route('add-patient');
        this.route('edit-patient', {
          path: '/edit-patient/:patient_id'
        });
        this.route('view-patient', {
          path: '/view-patient/:patient_id'
        });
        this.route('manage-relations', {
          path: '/manage-relations/:patient_id'
        });
        this.route('visit', {
          path: '/visit/:patient_id'
        });
        this.route('patient', {
          path: '/patient/:patient_id'
        }, function () {
          this.route('index');
          this.route('general');
          this.route('billing');
          this.route('medical-info');
          this.route('history');
          this.route('questionnaires', function () {
            this.route('index');
            this.route('edit', {
              path: '/edit/:id'
            });
          });
          this.route('family');
          this.route('attachment');
          this.route('sharing');
          this.route('family-history');
          this.route('shared-data');
          this.route('visits');
          this.route('referrals');
        });
        this.route('patient-error');
        this.route('shared-data');
      });
      this.route('admin-settings', function () {
        this.route('facility-types', function () {
          this.route('view');
          this.route('edit', {
            path: '/edit/:record_id'
          });
          this.route('add');
        });
        this.route('system-config');
        this.route('insurance-companies', function () {
          this.route('edit', {
            path: '/edit/:record_id'
          });
          this.route('add');
        });
        this.route('insurance-products', function () {
          this.route('edit', {
            path: '/edit/:record_id'
          });
          this.route('add', {
            path: '/add/:record_id'
          });
        });
        this.route('insurance-types', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('departments', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
          this.route('manage', {
            path: '/manage/:record_id'
          });
          this.route('ward', {
            path: '/ward/:record_id'
          });
          this.route('departmentunit', {
            path: '/departmentunit/:record_id'
          });
        });
        this.route('medicines', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('medicine-pricing-units');
        this.route('diagnosis', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
          this.route('icd');
        });
        this.route('roles', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        }); // this.route('access-management', function() {
        //   this.route('view');
        //   this.route('edit', { path: '/edit/:record_id' });
        // });

        this.route('role-permissions');
        this.route('user-permissions');
        this.route('generic', function () {
          this.route('type-record', function () {
            this.route('edit', {
              path: '/:queryParams'
            });
            this.route('add', {
              path: '/:queryParams'
            });
          });
        });
        this.route('facility-owner', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('procedures', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
          this.route('types');
          this.route('addtype');
        });
        this.route('investigations', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('department-units', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('procedures-test-type', function () {
          this.route('add');
        });
        this.route('investigation-test-type', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('investigation-type', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('procedure-type', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('vitals-type', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('facility-vitals', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('vital-type', function () {});
        this.route('visit-purpose', function () {
          this.route('add');
          this.route('edit', {
            path: '/edit/:record_id'
          });
        });
        this.route('scheduler', function () {
          this.route('timeline');
          this.route('roster');
        });
        this.route('timeline');
        this.route('roster'); // this.route('insurance-management');

        this.route('insurance-management', function () {
          this.route('insurance', {
            path: '/insurance/:id'
          }, function () {
            // this.route('index');
            this.route('product', {
              path: '/product/:record_id'
            });
          });
        });
        this.route('other-billables');
        this.route('lab-types');
        this.route('xray-types');
        this.route('ultrasound-types');
        this.route('department-management', function () {
          this.route('department', {
            path: '/department/:id'
          }, function () {
            this.route('index');
            this.route('ward', {
              path: '/ward/:record_id'
            });
          });
        });
        this.route('facility-examination');
        this.route('icd-diagnosis');
        this.route('questionnaire', function () {
          this.route('index');
          this.route('q-view', {
            path: '/q-view/:id'
          });
          this.route('q-new');
        });
        this.route('service-types');
        this.route('specialties');
        this.route('accreditation-types');
        this.route('accreditation-institutions');
        this.route('payment-mode');
        this.route('payment-type');
        this.route('allergy-types');
        this.route('immunization-types');
      });
      this.route('app-settings', function () {
        this.route('generic', function () {
          this.route('type-record', function () {});
        });
      });
      this.route('bills', function () {
        this.route('bill', {
          path: '/bill/:record_id'
        });
      });
      this.route('claims', function () {
        this.route('claim', {
          path: '/claim/:record_id'
        });
        this.route('private', {
          path: '/private/:record_id'
        });
      });
      this.route('ipd', function () {
        this.route('patients');
        this.route('ward', {
          path: 'ward/:record_id'
        }, function () {
          this.route('visit-summary');
          this.route('status');
          this.route('other-services');
          this.route('prescriptions');
          this.route('diagnosis');
          this.route('procedures');
          this.route('xrays');
          this.route('ultrasounds');
          this.route('investigations');
          this.route('symptoms');
          this.route('vitals-history');
          this.route('visits');
          this.route('referrals');
        });
      });
      this.route('reports', function () {
        this.route('facilities');
        this.route('insurance');
        this.route('patientactivity');
        this.route('patientvisit');
        this.route('general', function () {});
        this.route('diagnosis');
        this.route('chag-facility-types');
        this.route('test-report');
        this.route('xrays');
        this.route('ultrasounds');
        this.route('labs');
        this.route('prescriptions');
        this.route('procedures');
        this.route('oop-and-insurance');
        this.route('opd-and-ipd');
        this.route('admissions');
      });
      this.route('diagnostics', function () {
        this.route('investigations', function () {
          this.route('review', {
            path: '/review/:record_id'
          });
        });
        this.route('xray', function () {
          this.route('review', {
            path: '/review/:record_id'
          });
        });
        this.route('ultrasound', function () {
          this.route('review', {
            path: '/review/:record_id'
          });
        });
      });
      this.route('procedures', function () {
        this.route('review', {
          path: '/review/:record_id'
        });
      });
      this.route('test-user');
      this.route('pharmacy', function () {
        this.route('dispense', {
          path: '/dispense/:record_id'
        });
      });
      this.route('transactions', function () {
        this.route('visit', {
          path: '/visit/:record_id'
        });
      });
      this.route('notifications');
      this.route('profile');
      this.route('management-report', function () {
        this.route('service-revenue');
        this.route('doctors-report');
        this.route('patients-report');
        this.route('diagnosis-report');
        this.route('prescriptions-report');
      });
    });
    this.route('layout-test');
    this.route('test-page');
    this.route('configure', function () {
      this.route('department', function () {
        this.route('manage', {
          path: '/manage/:record_id'
        }, function () {//this.route('index', { path: '/:record_id' });
        });
        this.route('ward', {
          path: '/ward/:record_id'
        });
      });
      this.route('general');
      this.route('vitalsigns');
      this.route('insurance', function () {
        this.route('product', {
          path: '/product/:record_id'
        });
      });
      this.route('user');
      this.route('role');
      this.route('finish');
    });
    this.route('page-not-found', {
      path: '/*path'
    });
    this.route('management-report', function () {});
  });
  var _default = Router;
  _exports.default = _default;
});
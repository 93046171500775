define("hmis/templates/components/mini-app/side-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ThYXn/Ie",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"slot\",\"background-color:#75B2BF;\"],[10,\"class\",\"mini-menu-container\"],[8],[0,\"\\n        \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/mini-app/side-menu.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/date-filter-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    viewMonthly: true,
    viewDateRange: false,
    disabled: Ember.computed('filterOn', function () {
      return !this.get('filterOn');
    }),
    dfFrom: Ember.computed(function () {
      return this.get('fromDate');
    }),
    dfTo: Ember.computed(function () {
      return this.get('toDate');
    }),
    fromDateUnSaved: Ember.computed(function () {
      return this.get('dfFrom');
    }),
    toDateUnSaved: Ember.computed(function () {
      return this.get('dfTo');
    }),
    actions: {
      monthlyClick() {
        this.set('viewDateRange', false);
        this.set('viewMonthly', true);
      },

      dateRangeClick() {
        this.set('viewDateRange', true);
        this.set('viewMonthly', false);
      },

      filterNowClick() {
        let fromDateUnSaved = this.get('fromDateUnSaved');
        let toDateUnSaved = this.get('toDateUnSaved');
        this.set('dfFrom', fromDateUnSaved);
        this.set('dfTo', toDateUnSaved); // console.log(fromDateUnSaved, toDateUnSaved)

        if (this.get('onSubmit')) {
          this.get('onSubmit')(this.get('dfFrom'), this.get('dfTo'));
        }
      },

      onStatusChanged(status) {
        if (this.get('onStatusChanged')) {
          this.get('onStatusChanged')(status);
        }
      }

    }
  });

  _exports.default = _default;
});
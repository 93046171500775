define("hmis/mixins/app-event-component", ["exports", "hmis/mixins/app-event-mixin"], function (_exports, _appEventMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_appEventMixin.default, {
    init() {
      this._super(...arguments);

      this._addEvents();
    }

  });

  _exports.default = _default;
});
define("hmis/models/diagnosis-type", ["exports", "ember-data", "hmis/mixins/billable-item", "hmis/mixins/model-access-right", "ember-data/relationships"], function (_exports, _emberData, _billableItem, _modelAccessRight, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_billableItem.default, _modelAccessRight.default, {
    nhiaCode: _emberData.default.attr(),
    gdrgCode: _emberData.default.attr(),
    genericName: _emberData.default.attr(),
    diagnosisSubTypes: (0, _relationships.hasMany)('diagnosis-sub-type'),
    //diagnoses: hasMany('diagnosis'),
    billableName: 'diagnosis'
  });

  _exports.default = _default;
});
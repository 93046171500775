define("hmis/templates/components/managers/insurance-company-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bejYfwa2",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"style\",\"margin: 5px\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-40\"],[10,\"style\",\"margin: 0 10px\"],[8],[0,\"\\n      \"],[1,[28,\"paper-input\",null,[[\"value\",\"onChange\",\"label\"],[[24,[\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"name\"]]],null]],null],\"Name\"]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-20\"],[10,\"style\",\"margin-left: 10px\"],[8],[0,\"\\n\"],[4,\"app-select\",null,[[\"label\",\"selected\",\"options\",\"onChange\"],[\"Type\",[24,[\"type\"]],[24,[\"types\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"type\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[23,1,[\"name\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"style\",\"margin: 0 20px\"],[8],[0,\"\\n      \"],[7,\"button\",true],[10,\"class\",\"submit-button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"save\"],null]],[8],[0,\"Add \"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\" \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/insurance-company-manager.hbs"
    }
  });

  _exports.default = _default;
});
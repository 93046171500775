define("hmis/controllers/hmis-app/admin-settings/other-billables", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    newRecord: {},
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    onConfirm: 'deleteRecord',
    actions: {
      back() {
        history.back();
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete: ' + record.get('name'));
        this.set('onAlert', true);
      },

      deleteNow(record) {
        record.destroyRecord();
        this.send('reloadModel');
      },

      saveRecord(record) {
        record.save().then(record => {
          this.set('listWait', false);
          this.notify.success('Saved Successfully');
          this.send('reloadModel');
        }).catch(failure => {
          this.set('listWait', false);
          this.notify.error(failure);
        });
      },

      saveNewRecord() {
        this.set('listWait', true);
        let newRecord = this.get('store').createRecord('other-billable', this.get('newRecord'));
        newRecord.save().then(record => {
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
          this.send('reloadModel');
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error('Unable to Save');
        });
      },

      editRecord(record) {
        // alert(record.id);
        this.get("routing").transitionTo("hmis-app.admin-settings.medicines.edit", [record.id]);
      },

      reviewPrice(record) {
        this.get('previewBillable')(record, record.get('billableName'));
      },

      bulkUpload() {
        this.set('uploadModal', true);
      },

      showChooser(event) {
        this.set('show', true);
      },

      uploadData(file) {
        this.set('show', false);
        let that = this;
        this.get('serverUploads').uploadMedicines(file, {
          success: response => {
            this.notify.success("Medicines Bulk Upload Successful");
          },
          error: err => {
            this.notify.error(err);
            console.log(err);
          }
        });
      }

    }
  });

  _exports.default = _default;
});
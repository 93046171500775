define("hmis/routes/hmis-app/diagnostics/ultrasound/index", ["exports", "hmis/mixins/route-error-handler", "hmis/utils/general-util", "hmis/mixins/visit-care-route"], function (_exports, _routeErrorHandler, _generalUtil, _visitCareRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { cloneServeModel } from '../../../../../utils/patient-serve-util';
  var _default = Ember.Route.extend(_routeErrorHandler.default, _visitCareRoute.default, {
    serviceName: 'ultrasound',

    model(params) {
      let newModel = []; //cloneServeModel(model);

      newModel['requestData'] = this.get('store').query('ultrasound', {
        filter: {
          status: 0
        },
        include: ['ultrasoundTypeName', 'patientName', 'requesterName', 'granterName'],
        pageSize: 100
      }), newModel['resultData'] = this.get('store').query('ultrasound', {
        filter: {
          status: 1
        },
        include: ['ultrasoundTypeName', 'patientName', 'requesterName', 'granterName'],
        pageSize: 100
      }); // return RSVP.hash({});	

      return (0, _generalUtil.modelHash)(newModel);
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
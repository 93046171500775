define("hmis/components/controls/paged-report-controls", ["exports", "hmis/utils/reports"], function (_exports, _reports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // tagName: '',
    classNames: ['report-controls', 'app-fixed-top', 'content-list'],
    show: true,

    init() {
      this._super(...arguments);

      let serverReport = this.get('serverReport');
      if (!this.get('selectedRows')) this.set('selectedRows', []);
      if (!this.get('selectedColumns')) this.set('selectedColumns', []);
    },

    reports: Ember.inject.service(),
    allSection: {
      id: 'none',
      label: 'All'
    },
    otherFilter: {},
    // otherFilter:computed('serverReport', function() {
    //   let serverReport = this.get('serverReport')
    //   if(!serverReport)
    //     return {};
    //   let obj = {};
    //   for (let key in serverReport.filterProps)
    //   {
    //     obj[serverReport.filterProps[key].key] = {id:'none', label: 'All'}
    //   }
    //   return obj;
    // }),
    filterPropsArray: Ember.computed('serverReport', function () {
      let serverReport = this.get('serverReport');
      if (!serverReport) return [];
      let arr = [];

      for (let key in serverReport.filterProps) {
        let filterProp = serverReport.filterProps[key];
        Ember.set(filterProp, 'key', key); // filterProp['key']=key

        arr.push(filterProp);
      }

      return arr;
    }),
    rowFilters: Ember.computed('filters.@each', 'columnFilterProp', function () {
      if (!this.filters) return [];
      if (!this.columnFilterProp) return this.filters;
      return this.filters.filter(item => item.key != this.columnFilterProp.key);
    }),
    columnFilters: Ember.computed('filters.@each', 'rowFilterProp', function () {
      if (!this.filters) return [];
      if (!this.rowFilterProp) return this.filters;
      return this.filters.filter(item => item.key != this.rowFilterProp.key);
    }),
    otherFiltersMap: [],
    actions: {
      rowFilterPropChange(rowFilterProp) {
        this.set('filterValues.selectedRows', []);
        this.set('filterValues.appSelections.selectedRows', []);
        this.set('filterValues.row', rowFilterProp.key);
        this.set('filterValues.appSelections.row', rowFilterProp);
      },

      columnFilterPropChange(columnFilterProp) {
        this.set('filterValues.selectedColumns', []);
        this.set('filterValues.appSelections.selectedColumns', []);
        this.set('filterValues.column', columnFilterProp.key);
        this.set('filterValues.appSelections.column', columnFilterProp);

        if (columnFilterProp.exceedsColumnLimit) {
          this.set('filterValues.allColumns', false);
        } else {
          this.set('filterValues.allColumns', true);
        }
      },

      aggregateFilterPropChange(aggregate) {
        this.set('filterValues.aggregate', aggregate.key);
        this.set('filterValues.appSelections.aggregate', aggregate);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/managers/investigation-test-type-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uF2F+YaT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"style\",\"margin: 10px\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-40\"],[10,\"style\",\"margin: 0px 20px\"],[8],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"name\"]]],null]],null],\"Name\",\"person-outline\"]]],false],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"lowerLimit\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"lowerLimit\"]]],null]],null],\"Lower Limit\",\"person-outline\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-40\"],[8],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"units\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"units\"]]],null]],null],\"Units\",\"person-outline\"]]],false],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"upperLimit\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"upperLimit\"]]],null]],null],\"Upper Limit\",\"person-outline\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/investigation-test-type-manager.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/helpers/date-time-string", ["exports", "hmis/utils/date-util"], function (_exports, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.dateTimeString = dateTimeString;
  _exports.default = void 0;

  function dateTimeString(date
  /*, hash*/
  ) {
    var d = new Date(date); // d.setSeconds(0,0);

    return _dateUtil.default.formatToDateTimeString(d);
  }

  var _default = Ember.Helper.helper(dateTimeString);

  _exports.default = _default;
});
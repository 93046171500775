define("hmis/routes/hmis-app/ipd/ward/visits", ["exports", "hmis/utils/patient-serve-util", "hmis/utils/general-util", "hmis/mixins/route-error-handler", "hmis/utils/visit-service-util", "hmis/mixins/visit-care-route"], function (_exports, _patientServeUtil, _generalUtil, _routeErrorHandler, _visitServiceUtil, _visitCareRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, _visitCareRoute.default, {
    controllerName: 'hmis-app.opd.consultation.consult.visits',
    templateName: 'hmis-app.opd.consultation.consult.visits',
    parentCareRoute: 'hmis-app.ipd.ward',

    model() {
      return (0, _visitServiceUtil.visitOpdIpdModel)(this);
    }

  });

  _exports.default = _default;
});
define("hmis/templates/components/questionnaire/q-edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NWi1gWpN",
    "block": "{\"symbols\":[\"item\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"ui grid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"stackable doubling two column row\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"structure\",\"items\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"column\"],[8],[0,\"        \\n            \"],[1,[28,\"questionnaire/q-edit-item\",null,[[\"item\",\"questionsMap\",\"isSub\",\"structure\",\"valueSet\",\"onChange\"],[[23,1,[]],[24,[\"questionsMap\"]],false,[24,[\"structure\"]],[24,[\"valueSet\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/questionnaire/q-edit.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/models/emergency-patient", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util"], function (_exports, _emberData, _relationships, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    firstName: _emberData.default.attr(),
    lastName: _emberData.default.attr(),
    isConscious: _emberData.default.attr(),
    note: _emberData.default.attr(),
    gender: (0, _relationships.belongsTo)('gender'),
    patient: (0, _relationships.belongsTo)('patient', {
      async: false
    }),
    ward: (0, _relationships.belongsTo)('ward', {
      async: false
    }),
    room: (0, _relationships.belongsTo)('room', {
      async: false
    }),
    wardName: _emberData.default.attr(),
    roomName: _emberData.default.attr(),
    genderName: _emberData.default.attr(),
    consciousState: Ember.computed('isConscious', function (params) {
      return this.isConscious ? "Conscious" : "unconscious";
    }),

    toPatientData() {
      return {
        firstName: this.firstName,
        lastName: this.lastName,
        gender: this.gender
      };
    }

  });

  _exports.default = _default;
});
define("hmis/templates/components/hmis-side-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YBZfjYDK",
    "block": "{\"symbols\":[\"module\"],\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"style\",\"overflow: hidden;padding-left: 20px;background-color: #CEECF2;\"],[8],[0,\" \\n\"],[4,\"each\",[[24,[\"appModules\"]]],null,{\"statements\":[[4,\"if\",[[28,\"show-hmis-side-menu-item\",[[24,[\"currentUser\"]],[23,1,[]]],null]],null,{\"statements\":[[4,\"if\",[[23,1,[\"hasChildren\"]]],null,{\"statements\":[[0,\"                    \"],[1,[28,\"hmis-side-menu-item\",null,[[\"currentPathHook\",\"module\",\"onMenuClick\",\"onHasSubMenuClick\"],[[24,[\"currentPathHook\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"onMenuClick\"],null],\"replace this with action\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"                    \"],[5,\"link-to\",[[12,\"style\",\"float: left;\"]],[[\"@route\"],[[29,[\"hmis-app.\",[23,1,[\"route\"]]]]]],{\"statements\":[[0,\"\\n\"],[0,\"                        \"],[1,[28,\"hmis-side-menu-item\",null,[[\"currentPathHook\",\"module\",\"onMenuClick\",\"onHasSubMenuClick\"],[[24,[\"currentPathHook\"]],[23,1,[]],[28,\"action\",[[23,0,[]],\"onMenuClick\"],null],\"replace this with action\"]]],false],[0,\"\\n                    \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/hmis-side-menu.hbs"
    }
  });

  _exports.default = _default;
});
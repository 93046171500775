define("hmis/constants/referal-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.REFERAL_TYPES = void 0;
  const REFERAL_TYPES = {
    INTERNAL_DEPARTMENTAL: 1,
    EXTERNAL_SYSTEM_FACILITY: 2,
    EXTERNAL_OUTSIDE_SYSTEM_FACILITY: 3
  };
  _exports.REFERAL_TYPES = REFERAL_TYPES;
});
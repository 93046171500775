define("hmis/helpers/modular-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.modularPermission = modularPermission;
  _exports.default = void 0;

  function modularPermission(params
  /*, hash*/
  ) {
    return params;
  }

  var _default = Ember.Helper.helper(modularPermission);

  _exports.default = _default;
});
define("hmis/components/controls/power-select", ["exports", "hmis/components/power-select"], function (_exports, _powerSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function concatWithProperty(strings, property) {
    if (property) {
      strings.push(property);
    }

    return strings.join(' ');
  }

  var _default = _powerSelect.default.extend({
    // classNameBindings:['inputClass:md-input'],
    // inputClass:true,
    concatenatedTriggerClasses: Ember.computed('triggerClass', 'publicAPI.isActive', function () {
      let classes = ['md-input', 'ember-power-select-trigger'];

      if (this.get('publicAPI.isActive')) {
        classes.push('ember-power-select-trigger--active');
      }

      return concatWithProperty(classes, this.get('triggerClass'));
    }),
    otherButtonText: 'Other',
    otherButtonIcon: 'fa fa-plus',
    actions: {
      addNew(e) {
        let action = this.get('onOtherClick');
        if (action) action();
        this.publicAPI.actions.close(e);
      }

    }
  });

  _exports.default = _default;
});
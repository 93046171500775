define("hmis/routes/hmis-app/management-report/doctors-report", ["exports", "hmis/mixins/paged-report-route"], function (_exports, _pagedReportRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_pagedReportRoute.default, {});

  _exports.default = _default;
});
define("hmis/routes/hmis-app/opd/assign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        departments: this.get('store').findAll('department'),
        queuedPatient: this.get('store').findRecord('queued-patient', params.record_id) // patient: this.get('store').findRecord('patient', this.get('model.queuedPatient.id')), //change request to get current patient instead,
        // visit: this.get('store').findRecord('visit', 1),

      });
    }

  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/admin-settings/questionnaire/q-new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, model) {
      this._super(controller, model);

      controller.set('editQuest', {});
      controller.set('newItem', {});
      controller.set('showForm', true);
    },

    model(params) {
      var parentModel = this.modelFor("hmis-app.admin-settings.questionnaire");
      return Ember.RSVP.hash({
        templates: parentModel.filterBy('status', 'template')
      });
    }

  });

  _exports.default = _default;
});
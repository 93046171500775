define("hmis/routes/hmis-app/reports/visits/visit-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.get('store').findRecord('bill-copy', params.record_id).then(function (qp) {
        return qp;
      }); //change request to get current patient instead,
    }

  });

  _exports.default = _default;
});
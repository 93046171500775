define("hmis/mixins/form-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    app: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    data: Ember.inject.service(),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    // data: Ember.inject.service(),
    record: {},

    // showChanged: observer('show', function() {
    //   if(!this.show)
    //       return;
    //   if(this.isNew){
    //     // this.init();
    //       // this.$('form').form('clear')
    //   }else{
    //       // setTimeout(function(){  
    //       //     this.$('.ui.form').form('validate form') 
    //       // }, 50);
    //   }
    // }),
    startWaiting() {
      this.set('waiting', true);
    },

    stopWaiting() {
      this.set('waiting', false);
    },

    isNew: Ember.computed('record.id', function () {
      return this.record && this.record.id ? false : true;
    }),
    alertTitle: Ember.computed('title', 'record.id', 'modelName', function () {
      if (this.title) return this.title;
      return (this.record && this.record.id ? "Edit " : "Add new ") + (this.modelTitle ? this.modelTitle : this.modelName);
    }),
    actions: {
      onRecordSubmit() {
        let record = this.get('record');
        this.set('waiting', true);
        if (!this.get('record.id')) record = this.get('store').createRecord(this.get('modelName'), record);
        record.save().then(rec => {
          console.log('saved rec', rec);
          this.set('record', {});
          this.set('show', false);

          if (this.get('onSaved')) {
            this.get('onSaved')(rec);
          }

          this.set('waiting', false);
          this.notify.success('saved');
        }, err => {
          this.set('waiting', false);
          console.log('error', err);
          this.notify.error(err);
        });
      },

      onCancel() {
        this.set('waiting', false);

        if (this.get('record.hasDirtyAttributes')) {
          this.get('record').rollbackAttributes();
        }
      }

    },
    emailValidation: [{
      message: 'Please provide email in a valid format',
      validate: inputValue => {
        let emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return inputValue ? emailPattern.test(inputValue) : true;
      }
    }],
    phoneValidation: [{
      message: 'Please provide phone in a valid format',
      validate: inputValue => {
        let pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
        return inputValue ? pattern.test(inputValue) : true;
      }
    }]
  });

  _exports.default = _default;
});
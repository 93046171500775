define("hmis/components/hmis-top-bar", ["exports", "jquery", "hmis/utils/app-config"], function (_exports, _jquery, _appConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service('store'),
    currentUser: Ember.inject.service(),
    app: Ember.inject.service(),
    router: Ember.inject.service(),
    guideModal: false,
    appModules: Ember.computed('model', function () {
      return _appConfig.default;
    }),

    init() {
      this._super(...arguments);

      this.set('isOpen', false);
    },

    actions: {
      invalidateSession() {
        this.get('session').invalidate();
      },

      guideAlert() {
        this.set('guideModal', true);
      },

      editUser(dd) {
        this.get("router").transitionTo("hmis-app.profile");
        dd.dropdown.actions.close();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/manage-relations", ["exports", "hmis/utils/date-util", "hmis/utils/model-table-theme"], function (_exports, _dateUtil, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notInSelection: Ember.computed("model", function () {
      console.log(this.get('model'));
    }),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    date: Ember.computed(function () {
      let timeDate = new Date();
      let date = new Date();
      date.setHours(timeDate.getHours());
      date.setMinutes(timeDate.getMinutes());
      date.setSeconds(timeDate.getSeconds());
      date = _dateUtil.default.toSqlDate(date);
      return date;
    }),
    relationshipTypes: Ember.computed(function () {
      return this.get('store').findAll('relationship-type');
    }),
    patientsData: Ember.computed(function () {
      return this.get('store').findAll('patient');
    }),
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      searchPatient() {
        this.set('findPatient', true);
      },

      choosePatientConfirm(patient) {
        // alert(patient.get('fullName'))
        // console.log(patient);
        this.set('relative', patient);
        this.set('findPatient', false);
      },

      save() {// console.log(this.get('model'));
        // this.get("model.newVisit").save()
        // .then((record)=>{
        //     // console.log(record);
        //     this.get('model.visits').pushObject(record);
        //     alert("Saved");
        // })
        // .catch((failure)=>{
        //     console.log("Could not save",failure)
        //     alert("Unable to saved");
        // });
      },

      addRecord() {
        let relationType = this.get('relation');
        let relative = this.get('relative');
        let patient = this.get('model');
        let that = this;

        if (!relationType) {
          this.notify.error('Choose a relation type');
          return;
        }

        if (!relative) {
          this.notify.error('Choose a relative');
          return;
        } // return


        let relation = this.get('store').createRecord('patient-relation', {
          patient: patient,
          relatedTo: relative,
          type: relationType
        });
        relation.save().then(function (savedRelation) {
          patient.get('relatedTo').pushObject(savedRelation);
          that.notify.success('Relative added');
        }, function (err) {
          that.notify.error(err);
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/
        ,
        messages: {
          "noDataToShow": "No documents uploaded"
        }
      });
    }),
    columns: [{
      title: 'Relative name',
      "propertyName": "name"
    }, {
      title: 'Relation type',
      "propertyName": "type"
    }],
    patientsPickColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      title: 'Patient name',
      "propertyName": "fullName"
    }, {
      "propertyName": "phoneNumber"
    }]
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/opd/vitals", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service('store'),
    input_width: "flex-40 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    selectedItems: [],
    vitalBatch: null,
    modelsObserver: Ember.observer('model', function () {
      let that = this;
      this.get('store').query('vitals-facility-default' //   ,{
      //   filter:{
      //       facility: 1
      //   }
      // }
      ).then(function (records) {
        let newVitals = [];
        records.forEach(function (item, index) {
          item.get('vitalType').then(function (vitalType) {
            newVitals.pushObject(that.get('store').createRecord('vital', {
              vitalType: vitalType
            }));
          });
        });
        that.set('model.newVitals', newVitals); // newVitals
      });
    }),
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      save() {
        var that = this;
        let vBatch = this.get('model.vitalBatch'); // console.log('vBatch', vBatch);

        if (!vBatch) {
          this.set('model.vitalBatch', this.get('store').createRecord('vital-batch', {
            visit: this.get('model.visit')
          }));
          vBatch = this.get('model.vitalBatch'); // console.log('IsNew', vBatch.get('isNew'));
        } // console.log('IsNew', vBatch.get('isNew'));


        if (vBatch.get('isNew')) {
          vBatch.save().then(function (batch) {
            let vSigns = that.get('model.newVitals');
            let length = vSigns.get('length');
            that.get('model.newVitals').forEach(function (vSign, index) {
              vSign.set('batch', batch);
              vSign.save();
              batch.get('vitals').pushObject(vSign);

              if (length == index + 1) {
                that.notify.success('Saved');
              }
            });
          });
        } else {
          let vSigns = that.get('model.newVitals');
          let length = vSigns.get('length');
          that.get('model.newVitals').forEach(function (vSign, index) {
            vSign.save();

            if (length == index + 1) {
              that.notify.success('Saved');
            }
          });
        }
      },

      deleteRecord(record) {
        this.get("model.vitalBatch.vitals").removeObject(record);
        record.destroyRecord();
      },

      editRecord(record) {
        this.get("routing").transitionTo("hmis-app.patient-management.edit-patient", [record.id]);
      },

      rowDoubleClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.opd.manage-patient", [rid]);
      },

      deleteRecords() {
        this.get("selectedItems").forEach((patient, index) => {
          patient.destroyRecord();
        });
        this.set("selectedItems", []);
      },

      onSaveRow(param) {
        // return a truthy value (or Promise) for the row to exit edit mode
        return param.record.save();
      },

      // Example of ES6 destructuring parameters
      onCancelRow({
        record
      }) {
        record.rollbackAttributes(); // return a truthy value for the row to exit edit mode

        return true;
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/table-props-concat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { get } from '@ember/object';
  function concatWithProperty(strings, property) {
    if (property) {
      strings.push(property);
    }

    return strings.join(' ');
  }

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    },

    text: Ember.computed('column.props', 'record', function () {
      let props = this.get('column.props');
      let separator = this.get('column.separator');
      let record = this.get('record');
      console.log();
      if (!props) return '...';
      let str = "";
      props.forEach(prop => {
        str += str == "" ? Ember.get(record, prop) : separator + Ember.get(record, prop);
      });
      return str;
    }),
    tagName: '',
    actions: {}
  });

  _exports.default = _default;
});
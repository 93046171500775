define("hmis/constants/blood-groups", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.BLOOD_GROUPS = void 0;
  const BLOOD_GROUPS = {
    A_POSITIVE: 'A RhD positive (A+)',
    A_NEGATIVE: 'A RhD negative (A-)',
    B_POSITIVE: 'B RhD positive (B+)',
    B_NEGBTIVE: 'B RhD negative (B-)',
    O_POSITIVE: 'O RhD positive (O+)',
    O_NEGOTIVE: 'O RhD negative (O-)',
    AB_POSITIVE: 'AB RhD positive (AB+)',
    AB_NEGABTIVE: 'AB RhD negative (AB-)'
  };
  _exports.BLOOD_GROUPS = BLOOD_GROUPS;
});
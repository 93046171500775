define("hmis/templates/hmis-app/reports/insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6xy+M7FX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height  has-main-table\",true,\"Insurance Companies\"]],{\"statements\":[[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content\"],[10,\"style\",\"width: 100%\"],[8],[0,\"\\n\\n      \"],[1,[28,\"viewers/reports/insurance-report\",null,[[\"record\"],[\"\"]]],false],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/reports/insurance.hbs"
    }
  });

  _exports.default = _default;
});
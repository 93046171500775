define("hmis/components/controls/allergy-input-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    icon: "person-outline",
    value: "",
    label: "",
    actions: {
      onTextChanged(value) {
        this.set("value", value);
        if (this.get('onChange')) this.attr.onChange(value);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/forms/patient-payment-alert", ["exports", "hmis/mixins/form-alert", "hmis/mixins/ref-component"], function (_exports, _formAlert, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, _refComponent.default, {
    modelName: 'patient-payment-account',
    paymentMode: Ember.computed('model', function () {
      return this.get('store').query('payment-mode', {
        include: ['paymentModeTypeName']
      });
    }),

    showForm(record, onSavedCallback) {
      this.set('record', record);
      this.set('show', true);
      this.set('onSaved', onSavedCallback);
    },

    // patients: Ember.computed( function() {
    //   return this.get('store').query('patient', { pageSize:10})
    // }),
    actions: {}
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/admin-settings/questionnaire/q-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.get('store').findRecord('questionnaire', params.id).then(rec => {
        return rec;
      });
    },

    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model); // Implement your custom setup after


      controller.set('editQuest', model.toStructure());
    }

  });

  _exports.default = _default;
});
define("hmis/services/app-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    store: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    session: Ember.inject.service(),
    facilities: Ember.computed(function () {
      return this.appAjax.getArray("/simpleJSONs/all_facilities");
    }),
    doctors: Ember.computed(function () {
      return this.appAjax.getArray("/simpleJSONs/facility_doctors");
    }),
    modulePermissions: {},
    operationPermissions: {}
  });

  _exports.default = _default;
});
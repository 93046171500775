define("hmis/controllers/hmis-app/opd/consultation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    title: 'Patient',
    input_width: 'flex-45' // actions:{
    //     onMenuClick(route, title)
    //     {
    //         try {
    //             this.get("routing").transitionTo(route);
    //             this.set('title',title);
    //         }
    //         catch(err) {
    //             console.log(err);
    //         }
    //     },
    // }

  });

  _exports.default = _default;
});
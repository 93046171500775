define("hmis/utils/questionnaire", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.newQuestion = newQuestion;
  _exports.newQuestionnaireStructure = newQuestionnaireStructure;
  _exports.getQuestionsMap = getQuestionsMap;
  _exports.getCalculatedValue = getCalculatedValue;
  _exports.pass = pass;
  _exports.default = void 0;

  function newQuestion(parentQuest) {
    let id = 1;
    if (parentQuest.items.length) id = Number(Math.max.apply(Math, parentQuest.items.map(function (o) {
      return o.id;
    }))) + 1;
    let link = parentQuest.link ? parentQuest.link + '.' + id : id;
    return {
      id: id,
      link: link,
      type: 'text',
      enableWhen: []
    };
  }

  function newQuestionnaireStructure(title, description = "") {
    return {
      title,
      description,
      items: []
    };
  }

  function getQuestionsMap(itemsMap, item) {
    if (!item || !item.items) return itemsMap;
    item.items.forEach(element => {
      itemsMap[element.link] = element; // Ember.set(itemsMap, element.link, element)

      if (element.items) getQuestionsMap(itemsMap, element);
    });
    return itemsMap;
  }

  function getCalculatedValue(patientModel, item, questionsMap, valueSet, fixedValues = null) {
    let calcString = item.calculation;
    let testRes = item.calculation.match(new RegExp('(\{.*?\})', 'g'));

    if (testRes) {
      for (let index = 0; index < testRes.length; index++) {
        const element = testRes[index];
        let link = element.substring(1, element.length - 1);
        let ans = fixedValues || (questionsMap[link].isEnabled ? valueSet[link] : null);

        if (ans + '' != '0' && !ans) {
          console.warn(item.link + ' calculation failed: question ' + link + ' has no value');
          ans = null;
        }

        calcString = calcString.replace(element, isNumeric(ans) ? ans : "'".concat(ans ? ans.toLowerCase() : ans, "'"));
      }
    } //remove nextlines


    calcString = calcString.replace(/(\r\n|\n|\r)/gm, ""); // console.info('calcString', calcString)

    let theAns = eval(calcString);
    console.log('theAns', theAns);

    if (item.decimalPlace != null && (item.decimalPlace + "").length > 0 && isNumeric(theAns)) {
      return Number(theAns).toFixed(Number(item.decimalPlace));
    } else return theAns;
  }

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function pass(a, o, b) {
    if (o == '=') return (a ? a.toLowerCase() : a) == (b ? b.toLowerCase() : b);
    if (o == '!=') return a != b;
    if (o == '>') return a > b;
    if (o == '<') return a < b;
    if (o == '>=') return a >= b;
    if (o == '<=') return a <= b;
    console.error(o, ': operator not fount');
  }

  var _default = {
    newQuestion,
    newQuestionnaireStructure,
    pass,
    getQuestionsMap,
    getCalculatedValue
  };
  _exports.default = _default;
});
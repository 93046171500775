define("hmis/components/viewers/patient-summary-viewer", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tabIndex: 0,
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),

    // ob:observer('visit',function(){
    //     this.send('filterToday')
    // }),
    init() {
      this._super(...arguments);

      let parent = this.get('parent');
      console.log('parent', parent);

      if (parent) {
        parent.set('showVisitSummary', visit => {
          this.set('visit', visit); //this.set('show', true)

          this.send('filterAllDays');
        });
      }
    },

    isToday: true,
    fromDate: new Date(),
    toDate: new Date(),
    actions: {
      back() {
        history.back();
      },

      printSummary() {
        this.set('printingSummary', true);
        this.get('ajaxRequest').printSummary(this.get('visit'), {
          success: response => {
            this.set('printingSummary', false);
          },
          error: error => {
            this.set('printingSummary', false);
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      sendToPDF() {
        this.set('exportingToPDF', true);
        this.get('ajaxRequest').exportSummaryToPdf(this.get('visit'), {
          success: response => {
            this.set('exportingToPDF', false);
          },
          error: error => {
            this.set('exportingToPDF', false);
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      sendToEmail() {
        this.set('sendingMail', true);
        this.get('ajaxRequest').emailSummary(this.get('visit'), {
          success: response => {
            this.notify.success('Email sent');
            this.set('sendingMail', false);
          },
          error: error => {
            this.set('sendingMail', false);
            this.notify.error(error);
          }
        });
      },

      onDateFilterSubmit(fromDate, toDate) {
        this.send('filterNow', fromDate, toDate);
        this.send('currentDateFilter', 'dateFilterActive');
      },

      filterToday() {
        var fd = new Date();
        fd.setHours(0, 0, 0, 0);
        var td = new Date();
        td.setHours(24, 0, 0, 0);
        this.send('filterNow', fd, td);
        this.send('currentDateFilter', 'isToday');
      },

      filterThisMonth() {
        let date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        let fd = new Date(y, m, 1);
        let td = new Date(y, m + 1, 0);
        fd.setHours(0, 0, 0, 0);
        td.setHours(24, 0, 0, 0);
        this.send('filterNow', fd, td);
        this.send('currentDateFilter', 'isThisMonth');
      },

      filterAllDays() {
        this.send('filterNow', null, null, false);
        this.send('currentDateFilter', 'dateFilterAll');
      },

      currentDateFilter(current) {
        this.set('isToday', false);
        this.set('isThisMonth', false);
        this.set('dateFilterActive', false);
        this.set('dateFilterAll', false);
        this.set(current, true);
      },

      filterNow(fromDate, toDate, addDate = true) {
        let visit = this.get('visit');
        if (!visit) return;
        let that = this;
        let filter = {
          patientId: visit
        };

        if (addDate) {
          fromDate.setHours(0, 0, 0, 0);
          toDate.setHours(24, 0, 0, 0);
          filter['dateRange'] = {
            fromDate: fromDate.toISOString().slice(0, 19).replace('T', ' '),
            toDate: toDate.toISOString().slice(0, 19).replace('T', ' ')
          };
        } //this.send('queryForSummary', this, 'vital-batch', 'vitalsSummary', filter)
        //this.send('queryForSummary', this, 'symptom-batch', 'symptomsSummary', filter)


        this.send('queryForSummary', this, 'diagnosis-batch', 'diagnosesSummary', filter);
        this.send('queryForSummary', this, 'prescription-batch', 'prescriptionsSummary', filter);
        this.send('queryForSummary', this, 'procedure', 'proceduresSummary', filter);
        this.send('queryForSummary', this, 'investigation', 'investigationsSummary', filter);
        this.send('queryForSummary', this, 'xray', 'xraySummary', filter);
        this.send('queryForSummary', this, 'ultrasound', 'ultrasoundSummary', filter);
      },

      queryForSummary(context, model, tableData, filter, then) {
        context.set(tableData, []);
        context.set(tableData + "Waiting", true);
        context.get('store').query(model, {
          filter: filter,
          pageSize: 100
        }).then(function (qp) {
          context.set(tableData, qp);
          context.set(tableData + "Waiting", false);
        });
      },

      moveToClaims() {
        let that = this;
        let patient = this.get('model').get('visit').get('patient');
        let insuranceProduct = patient.get('insuranceProduct');
        let insuranceId = insuranceProduct.get('id');
        console.log('insurance', insuranceId);

        if (!insuranceId) {
          alert('Patient has no insurance product\n Please set that first');
          return;
        }

        this.get('model').set('modeOfPayment', 'Insurance');
        this.get('model').save().then(function (record) {
          let insuranceType = record.get('insuranceType');

          if (insuranceType.get('id') == 1) //government
            {
              that.get("routing").transitionTo("hmis-app.claims.government", [record.get('id')]);
            } else {
            that.get("routing").transitionTo("hmis-app.claims.private", [record.get('id')]);
          }
        });
      },

      print() {
        downloadUtil.printBill(this.get('model.id'));
      },

      exportToPdf() {
        downloadUtil.exportBillToPDF(this.get('model.id'));
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    vitalsColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Vitals',
      propertyName: 'vitalsString',
      className: "vsv-summary-string-col"
    }],
    symptomsColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Symptoms',
      propertyName: 'symptomsString',
      className: "vsv-summary-string-col"
    }],
    diagnosesColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Diagnoses',
      propertyName: 'diagnosesString',
      className: "vsv-summary-string-col"
    }],
    prescriptionsColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Prescriptions',
      propertyName: 'prescriptionsString',
      className: "vsv-summary-string-col"
    }],
    proceduresColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Procedure',
      propertyName: 'procedureTypeName',
      className: "vsv-item-col"
    }, {
      title: 'Documents',
      propertyName: 'documentsString',
      className: "vsv-summary-string-col"
    }],
    investigationsColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Investigations',
      propertyName: 'investigationsString',
      className: "vsv-summary-string-col"
    }],
    xrayColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'X-Ray',
      propertyName: 'xrayType.genericName',
      className: "vsv-item-col"
    }, {
      title: 'Documents',
      propertyName: 'documentsString',
      className: "vsv-summary-string-col"
    }],
    ultrasoundColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Ultrasound',
      propertyName: 'ultrasoundType.genericName',
      className: "vsv-item-col"
    }, {
      title: 'Documents',
      propertyName: 'documentsString',
      className: "vsv-summary-string-col"
    }]
  });

  _exports.default = _default;
});
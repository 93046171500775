define("hmis/controllers/hmis-app/admin-settings/roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    serverUploads: Ember.inject.service(),
    serverDownloads: Ember.inject.service(),
    notify: Ember.inject.service(),
    selectedItems: [],
    allowSelect: true,
    data: Ember.computed.alias('model'),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    onConfirm: 'deleteRecord',
    roleType: ['Doctor', 'Nurse', 'Pharmacist'],
    newRecord: {},
    actions: {
      back() {
        history.back();
      },

      // deleteRecord (record) {
      // this.get("selectedItems").removeObject(record);
      // record.destroyRecord();
      // },
      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete: ' + record.get('name'));
        this.set('onAlert', true);
      },

      deleteNow(record) {
        record.destroyRecord();
      },

      saveRecord(record) {
        record.save().then(record => {
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
          this.send('reloadModel');
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error(failure);
        });
      },

      saveNewRecord() {
        this.set('listWait', true);
        let newRecord = this.get('store').createRecord('role', this.get('newRecord'));
        newRecord.save().then(record => {
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
          this.send('reloadModel');
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error(failure);
        });
      },

      reviewPrice(record) {
        this.get('previewBillable')(record, record.get('billableName'));
      },

      deleteRecords() {
        this.get("selectedItems").forEach((user, index) => {
          user.destroyRecord();
        });
        this.set("selectedItems", []);
      },

      bulkUpload() {
        this.set('uploadModal', true);
      },

      showChooser(event) {
        this.set('show', true);
      },

      downloadExcel() {
        this.set('bulkDownloading', true);
        this.get('serverDownloads').downloadInvestigationTypes(null, this.get('currentUser.accessLevel') == 2, {
          success: response => {
            this.set('bulkDownloading', false);
            this.send('reloadModel');
            this.notify.success("Medicines Download Successful");
          },
          error: err => {
            this.set('bulkDownloading', false);
            this.notify.error("Unable to upload. Something went wrong" + err);
            console.log(err);
          }
        });
      },

      uploadData(file) {
        this.set('show', false);
        let that = this;
        this.set('bulkUploading', true);
        this.get('serverUploads').uploadInvestigationTypes(file, null, this.get('currentUser.accessLevel') == 2, {
          success: response => {
            this.set('bulkUploading', false);
            this.notify.success("Medicines Bulk Upload Successful");
          },
          error: err => {
            this.set('bulkUploading', false);
            this.notify.error(err);
            console.log(err);
          }
        });
      }

    }
  });

  _exports.default = _default;
});
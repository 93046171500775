define("hmis/models/prescription", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/visit-service-model", "hmis/mixins/billable-model", "hmis/mixins/visit-request-model "], function (_exports, _emberData, _relationships, _dateUtil, _visitServiceModel, _billableModel, _visitRequestModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, _visitRequestModel.default, _billableModel.default, {
    dosage: _emberData.default.attr(),
    frequency: _emberData.default.attr(),
    frequencyType: (0, _relationships.belongsTo)('frequencyType', {
      async: false
    }),
    frequencyTypeId: _emberData.default.attr(),
    frequencyTypeName: _emberData.default.attr(),
    duration: _emberData.default.attr(),
    durationType: (0, _relationships.belongsTo)('durationType', {
      async: false
    }),
    durationTypeId: _emberData.default.attr(),
    durationTypeName: _emberData.default.attr(),
    medicine: (0, _relationships.belongsTo)('medicine', {
      async: false
    }),
    medicineId: _emberData.default.attr(),
    medicineName: _emberData.default.attr(),
    medicineGdrgCode: _emberData.default.attr(),
    medicineUnitOfPricing: _emberData.default.attr(),
    quantity: _emberData.default.attr(),
    updatedAt: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    dispensedQuantity: _emberData.default.attr(),
    source: _emberData.default.attr(),
    note: _emberData.default.attr(),
    dispensed: _emberData.default.attr(),
    externalDispensed: _emberData.default.attr(),
    dosageString: Ember.computed('dosage', 'medicineUnitOfPricing', function () {
      return this.get('dosage') + ' ' + this.get('medicineUnitOfPricing');
    }),
    frequencyString: Ember.computed('frequency', 'frequencyTypeName', function () {
      return this.get('frequency') + ' ' + this.get('frequencyTypeName');
    }),
    durationString: Ember.computed('duration', 'durationTypeName', function () {
      return this.get('duration') + ' ' + this.get('durationTypeName');
    }),
    dispensedString: Ember.computed('dispensed', function () {
      return this.get('dispensed') == 1 ? 'Dispensed' : 'Pending';
    })
  });

  _exports.default = _default;
});
define("hmis/components/stats-alert-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      onUnfocusedClick() {
        // alert('hiii')
        if (this.get('onUnfocusedClick')) this.get('onUnfocusedClick')();
      }

    }
  });

  _exports.default = _default;
});
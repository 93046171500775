define("hmis/models/facility-insurance", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    facility: (0, _relationships.belongsTo)('facility'),
    insuranceCompany: (0, _relationships.belongsTo)('insurance-company'),
    insuranceProduct: (0, _relationships.belongsTo)('insurance-product'),
    name: Ember.computed('insuranceCompany.name', function () {
      return "".concat(this.get('insuranceCompany.name'));
    })
  });

  _exports.default = _default;
});
define("hmis/components/controls/models-table-server-paginated", ["exports", "hmis/components/models-table-server-paginated"], function (_exports, _modelsTableServerPaginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelsTableServerPaginated.default.extend({
    app: Ember.inject.service(),
    observedProperties: Ember.computed(function () {
      return ['currentPageNumber', 'sortProperties.[]', 'pageSize', 'filterString', 'processedColumns.@each.filterString'];
    }),
    lastSearchedFilteredContent: null,

    doQuery(store, modelName, query) {
      if (this.get('onQueryDo')) this.get('onQueryDo')(true);
      if (this.get('onQueryStart')) this.get('onQueryStart')();
      this.get('app').startLoading();
      return new Promise((resolve, reject) => {
        store.query(modelName, query).then(newData => {
          if (this.get('onQueryDone')) this.get('onQueryDone')(newData);
          Ember.set(this, 'lastSearchedFilteredContent', newData);
          Ember.set(this, 'filteredContent', newData);

          if (this.data.fakeQuery) {
            this.set('data', newData);
          }

          resolve(newData);
        }).catch(err => {
          console.error(err);
          this.set('dataError', err);
          reject(err);
        }).finally(err => {
          if (this.get('onQueryDo')) this.get('onQueryDo')(false);
          this.get('app').stopLoading();
        });
      });
    },

    _addPropertyObserver() {
      Ember.run.debounce(this, this._loadData, Ember.get(this, 'debounceDataLoadTime'));
    },

    didReceiveAttrs() {
      Ember.set(this, 'filteredContent', this.lastSearchedFilteredContent ? this.lastSearchedFilteredContent : this.data);
    },

    init() {
      this._super(...arguments);

      this._loadIfFakeData();
    },

    dataObserve: Ember.observer('data', function () {
      this._loadIfFakeData();
    }),

    _loadIfFakeData() {
      if (this.data && this.data.fakeQuery) {
        Ember.run.debounce(this, this._loadData, 10);
      }
    },

    actions: {
      clickOnRow(index, dataItem) {
        console.log(event); // assert('row index should be numeric', typeOf(index) === 'number');

        if (this.get('selectRowOnClick') && !event.tableLink) {
          let multipleSelect = this.get('multipleSelect');
          let selectedItems = this.get('selectedItems');

          if (selectedItems.includes(dataItem)) {
            selectedItems.removeObject(dataItem);
          } else {
            if (!multipleSelect && selectedItems.get('length') === 1) {
              this.get('selectedItems').clear();
            }

            this.get('selectedItems').pushObject(dataItem);
          }
        }

        let action = this.get('rowClickAction');
        let actionIsFunction = typeof action === 'function';

        if (actionIsFunction) {
          action(index, dataItem);
        }

        this.userInteractionObserver();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/app-workspace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Boji2kPl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"pageError\"]]],null,{\"statements\":[[0,\"    \"],[5,\"error-page\",[],[[\"@errorResult\"],[[22,\"pageError\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[28,\"if\",[[24,[\"nested\"]],\"app-workspace-nested {{class}}\",\"app-workspace {{class}}\"],null]],[8],[0,\"\\n\"],[0,\"            \"],[14,1],[0,\"\\n\"],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/app-workspace.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/table-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "nm+7eEy1",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"controls/check-box\",null,[[\"disabled\",\"value\",\"onChange\"],[[24,[\"disabled\"]],[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null]],null]],null]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/table-check.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/facility-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    // init(){
    //     // this.set('filteredList',this.get("model"));
    // },
    actions: {// filterList(query) {
      //     this.set("filteredList",
      //         this.get("model").filter(item => {
      //             return item.get('name').toLowerCase().indexOf(query.toLowerCase()) > -1;
      //         })
      //     )
      // }
    }
  });

  _exports.default = _default;
});
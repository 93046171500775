define("hmis/templates/components/controls/table-doctor-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "98zh60fR",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"schedule-chip\"],[8],[0,\"\\n\\n\"],[4,\"paper-chips\",null,[[\"readOnly\",\"removeItem\",\"addItem\",\"placeholder\",\"content\",\"options\",\"searchField\",\"noMatchesMessage\"],[[24,[\"readOnly\"]],[28,\"action\",[[23,0,[]],\"removeDoc\"],null],[28,\"action\",[[23,0,[]],\"addDoc\"],null],\"Type to search\",[24,[\"schedules\"]],[24,[\"optionsDoc\"]],\"firstName\",\"Not found\"]],{\"statements\":[[0,\"      \"],[7,\"strong\",true],[8],[1,[23,1,[\"fullName\"]],false],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"staff\",\"id\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"schedule-cell-doc\"],[8],[0,\" \"],[1,[23,1,[\"staff\",\"fullName\"]],false],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"style\",true],[8],[0,\"\\n  .schedule-chip .md-chips md-chip{\\n    height: 24px;\\n  }\\n\"],[9],[0,\"\\n\"],[2,\"</div>\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/table-doctor-item.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/alerts/dialog-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    backdropClosable: true,
    actions: {
      onSubmit() {
        event.preventDefault();
        event.stopPropagation();
        if (this.get('onSubmit')) this.get('onSubmit')();
      },

      onUnfocusedClick() {
        if (!this.get('unClosable') && this.get('backdropClosable')) {
          this.set('show', false);
          if (this.get('onUnfocusedClick')) this.get('onUnfocusedClick')();
        }
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/transactions/visit", ["exports", "hmis/utils/download-util", "hmis/utils/model-table-theme", "hmis/utils/request-utils/bill", "hmis/mixins/page-error-handler"], function (_exports, _downloadUtil, _modelTableTheme, _bill, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_pageErrorHandler.default, {
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    selectedTransactions: [],
    unpaids: Ember.computed('model.transactions', 'model.transactions.@each.isPaid', function () {
      return this.get('model.transactions').filterBy('isPaid', false);
    }),
    medicine: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'prescription');
    }),
    diagnosis: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'diagnosis');
    }),
    lab: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'investigation');
    }),
    procedure: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'procedure');
    }),
    ultrasound: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'ultrasound');
    }),
    xray: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filterBy('billableType', 'xray');
    }),
    otherBillables: Ember.computed('model.transactions', function () {
      return this.get('model.transactions').filter((item, index, self) => item.get('billableType') == 'admission' || item.get('billableType') == 'consultation' || item.get('billableType') == 'ward');
      ;
    }),
    sum: arrs => {
      let total = 0;
      arrs.forEach(function (tran) {
        total += Number(tran.get('amount'));
      });
      return total;
    },
    totalMedicineCost: Ember.computed('medicine', function () {
      return this.get('sum')(this.get('medicine'));
    }),
    totalDiagnosisCost: Ember.computed('diagnosis', function () {
      return this.get('sum')(this.get('diagnosis'));
    }),
    totalLabCost: Ember.computed('lab', function () {
      return this.get('sum')(this.get('lab'));
    }),
    totalProcedureCost: Ember.computed('procedure', function () {
      return this.get('sum')(this.get('procedure'));
    }),
    totalUltrasoundCost: Ember.computed('ultrasound', function () {
      return this.get('sum')(this.get('ultrasound'));
    }),
    totalXrayCost: Ember.computed('xray', function () {
      return this.get('sum')(this.get('xray'));
    }),
    totalOtherBillablesCost: Ember.computed('otherBillables', function () {
      return this.get('sum')(this.get('otherBillables'));
    }),
    allSelected: Ember.computed('model.transactions.@each', 'selectedTransactions.@each', function () {
      return this.get('model.transactions.length') == this.get('selectedTransactions.length');
    }),
    canPayAll: Ember.computed('selectedTransactions.@each.isPaid', function () {
      if (!this.get('selectedTransactions.length')) return false;
      let array = this.get('selectedTransactions');

      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        if (element.get('isPaid')) return false;
      }

      return true;
    }),
    totalBill: Ember.computed('totalMedicineCost', 'totalDiagnosisCost', 'totalLabCost', 'totalProcedureCost', 'totalUltrasoundCost', 'totalXrayCost', 'totalOtherBillablesCost', function () {
      return Number(Number(this.get('totalMedicineCost')) + Number(this.get('totalDiagnosisCost')) + Number(this.get('totalLabCost')) + Number(this.get('totalProcedureCost')) + Number(this.get('totalUltrasoundCost')) + Number(this.get('totalXrayCost')) + Number(this.get('totalOtherBillablesCost'))).toFixed(2);
    }),
    choosePayment: true,
    actions: {
      back() {
        history.back();
      },

      checkAllToggle() {
        if (!this.get('allSelected')) {
          this.set('selectedTransactions', []);
          this.get('model.transactions').forEach(element => {
            this.get('selectedTransactions').pushObject(element);
          });
        } else {
          this.set('selectedTransactions', []);
        }
      },

      print() {
        this.set('printingBill', true);
        this.appAjax.printFile('exportVisitBills/print/' + this.model.visit.id).then(res => {
          this.set('printingBill', false);
        }).catch(err => {
          this.set('printingBill', false);
          this.notify.error(err);
        });
      },

      exportToPdf() {
        this.set('exportingBillToPDF', true);
        this.appAjax.downloadFile('exportVisitBills/pdf/' + this.model.visit.id).then(res => {
          this.set('exportingBillToPDF', false);
        }).catch(err => {
          this.set('exportingBillToPDF', false);
          this.notify.error(err);
        });
      },

      alertEmail() {
        this.set('sendingMail', true);
        this.appAjax.ajaxGet('exportVisitBills/email/' + this.model.visit.id, {}).then(res => {
          this.set('sendingMail', false);
        }).catch(err => {
          this.set('sendingMail', false);
          this.notify.error(err);
        });
      },

      claimTransaction(transaction) {
        this.notify.confirmSimple("Confirm!!", null, () => {
          transaction.set('waiting', true);
          this.appAjax.post("/claimTransaction/" + transaction.get('id'), {}).then(res => {
            transaction.copyFromJSObject(res);
            this.notify.success('Transaction moved to claim');
            transaction.set('waiting', false);
          }).catch(err => {
            this.notify.error(err);
            transaction.set('waiting', false);
          });
        });
      },

      payOutOfPockect(transaction) {
        this.notify.confirmSimple("Confirm!!", null, () => {
          transaction.set('waiting', true);
          this.appAjax.post("/payTransaction/" + transaction.get('id'), {}).then(res => {
            transaction.copyFromJSObject(res);
            this.notify.success('Transaction paid');
            transaction.set('waiting', false);
          }).catch(err => {
            this.notify.error(err);
            transaction.set('waiting', false);
          });
        });
      },

      payAll() //paymentWaiting
      {
        this.paymentPicker.show(this.get('selectedTransactions'), {
          showInsurance: false
        }, (paymentIndex, paymentOption, transaction) => {
          this.notify.confirmSimple("Confirm!!", null, () => {
            this.set('paymentWaiting', true);
            this.appAjax.post("/payTransactionsWithOopAccount/" + paymentOption.patientPaymentAccountId, {
              transactions: this.get('selectedTransactions').map(a => {
                a.set('waiting', false);
                return a.id;
              })
            }).then(res => {
              this.get('selectedTransactions').forEach(transaction => {
                if (res.paid && res.paid.includes(transaction.id)) transaction.copyFromJSObject(transaction);
                transaction.set('waiting', false);
              });
              this.notify.success((res.failed ? "Some " : '') + 'Transactions paid');
              if (res.failed) this.notify.error("Some transactions failed");
              this.paymentPicker.close();
              this.set('paymentWaiting', false);
              this.send('refreshModel');
            }).catch(err => {
              this.notify.error(err);
              this.get('selectedTransactions').forEach(transaction => {
                transaction.set('waiting', false);
              });
              this.set('paymentWaiting', false);
            });
          });
        });
      },

      payDefault(transaction) {
        if (transaction.isInsurance) this.send('claimTransaction', transaction);else {
          this.paymentPicker.show(transaction, {
            showInsurance: false
          }, (paymentIndex, paymentOption, transaction) => {
            this.notify.confirmSimple("Confirm!!", null, () => {
              this.set('paymentWaiting', true);
              transaction.set('waiting', true);
              this.appAjax.post("/payTransactionWithOopAccount/" + transaction.get('id') + "/" + paymentOption.patientPaymentAccountId, {}).then(res => {
                transaction.copyFromJSObject(res);
                this.notify.success('Transaction paid');
                this.paymentPicker.close();
                transaction.set('waiting', false);
                this.set('paymentWaiting', false);
              }).catch(err => {
                this.notify.error(err);
                this.set('paymentWaiting', false);
                transaction.set('waiting', false);
              });
            });
          });
        }
      },

      changePayment(transaction) {
        this.paymentPicker.show(transaction, {}, (paymentIndex, paymentOption, transaction) => {
          this.notify.confirmSimple("Confirm!!", null, () => {
            this.set('paymentWaiting', true);
            transaction.set('waiting', true);

            if (paymentIndex == 0) {
              //pay OOP
              this.appAjax.post("/payTransactionWithOopAccount/" + transaction.get('id') + "/" + paymentOption.patientPaymentAccountId, {}).then(res => {
                transaction.copyFromJSObject(res);
                this.notify.success('Transaction paid');
                this.paymentPicker.close();
                transaction.set('waiting', false);
                this.set('paymentWaiting', false);
              }).catch(err => {
                this.notify.error(err);
                this.set('paymentWaiting', false);
                transaction.set('waiting', false);
              });
            } else if (paymentIndex == 1) {
              //claim insurance
              this.appAjax.post("/claimTransactionWithInsurance/" + transaction.get('id') + "/" + paymentOption.patientInsuranceId, {}).then(res => {
                console.log('claim called', res);
                transaction.copyFromJSObject(res);
                this.notify.success('Transaction added to ' + res.insuranceName + ' claim');
                this.paymentPicker.close();
                transaction.set('waiting', false);
                this.set('paymentWaiting', false);
              }).catch(err => {
                this.notify.error(err);
                this.set('paymentWaiting', false);
                transaction.set('waiting', false);
              });
            }
          });
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "propertyName": "index",
      "component": "controls/select-row-checkbox",
      className: 'index-col'
    }, {
      "title": "Date of transaction",
      "propertyName": "dateF",
      className: 'trans-date-col'
    }, {
      "title": "description",
      "propertyName": "billableItemName"
    }, {
      "propertyName": "price"
    }, {
      "propertyName": "quantity"
    }, {
      'title': 'Total cost',
      "propertyName": "nAmount",
      className: 'total-cost-col'
    }, {
      "propertyName": "paymentStatus",
      className: 'payment-col'
    }, {
      title: "",
      component: "transCell",
      className: 'transaction-cell'
    }],
    columns2: [{
      "propertyName": "index",
      "component": "controls/select-row-checkbox",
      className: 'index-col'
    }, {
      "title": "Date of transaction",
      "propertyName": "dateF",
      className: 'trans-date-col'
    }, {
      "title": "description",
      "propertyName": "billableItemName"
    }, {
      'title': 'Cost',
      "propertyName": "nAmount",
      className: 'total-cost-col'
    }, {
      "propertyName": "paymentStatus",
      className: 'payment-col'
    }, {
      title: "",
      component: "transCell",
      className: 'transaction-cell'
    }],
    columns3: [{
      "propertyName": "index",
      "component": "controls/select-row-checkbox",
      className: 'index-col'
    }, {
      "title": "Date of transaction",
      "propertyName": "dateF",
      className: 'trans-date-col'
    }, {
      "title": "description",
      "propertyName": "billableSummary"
    }, {
      'title': 'Cost',
      "propertyName": "nAmount",
      className: 'total-cost-col'
    }, {
      "propertyName": "paymentStatus",
      className: 'payment-col'
    }, {
      title: "",
      component: "transCell",
      className: 'transaction-cell'
    }]
  });

  _exports.default = _default;
});
define("hmis/models/allergies", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    type: (0, _relationships.belongsTo)('allergy-type'),
    patient: (0, _relationships.belongsTo)('patient'),
    facility: _emberData.default.attr(),
    diagnosisSubType: (0, _relationships.belongsTo)('diagnosis-sub-type'),
    note: _emberData.default.attr(),
    idcDiagnosis: Ember.computed('diagnosisSubType.icdCode', 'diagnosisSubType.icdName', function () {
      return this.get('diagnosisSubType.id') ? "".concat(this.get('diagnosisSubType.icdCode'), " - ").concat(this.get('diagnosisSubType.icdName')) : '';
    })
  });

  _exports.default = _default;
});
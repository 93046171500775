define("hmis/routes/hmis-app/ipd/ward/other-services", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    controllerName: 'hmis-app.opd.consultation.consult.diagnosis',
    templateName: 'hmis-app.opd.consultation.consult.diagnosis'
  });

  _exports.default = _default;
});
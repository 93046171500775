define("hmis/components/controls/progress-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    actions: {
      onClick(event) {
        if (this.get('onItemClick')) this.get('onItemClick')(this.get('record'));
      },

      onRemove(event) {
        if (this.get('onRemove')) this.get('onRemove')(this.get('record'));
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/error-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "duEe0dR+",
    "block": "{\"symbols\":[],\"statements\":[[5,\"no-data-message\",[],[[\"@message\"],[[22,\"message\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/error-viewer.hbs"
    }
  });

  _exports.default = _default;
});
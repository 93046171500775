define("hmis/controllers/hmis-app/reports/general/index", ["exports", "jquery", "hmis/utils/app-config/report-types", "hmis/utils/model-table-theme"], function (_exports, _jquery, _reportTypes, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    getDate: moment().format("YYYY-MM-DD"),
    reportTypes: Ember.computed('model', function () {
      return _reportTypes.default;
    }),
    isYearly: true,
    columns: 0,
    activeReport: '',
    chartLabel: [],
    chartOptions: {
      chart: {
        type: 'bar',
        height: 645,
        exporting: {
          enabled: true
        }
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: []
      },
      yAxis: {// title: {
        //   text: 'Fruit eaten'
        // }
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical',
        x: 0,
        y: 100
      },
      plotOptions: {
        series: {
          stacking: 'normal'
        }
      },
      exporting: {
        enabled: true
      }
    },
    chartData2: [],
    tabStatus: 0,
    repArray: {},
    chartDataSet: {},
    pdfTableDataSet: [],
    line: false,
    bar: false,
    hbar: false,

    init() {
      var date = new Date();
      var firstDay = new Date(date.getFullYear(), 0, 1);
      var lastDay = new Date(date.getFullYear(), 11 + 1, 0);
      this.get('ajaxRequest').generalfacilityReport(moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD"), {
        success: response => {
          console.log("Reports: ", response);
          this.repArray = response;
        },
        error: error => {
          console.log("Unable to get reports: ", failure);
        }
      });
    },

    chartData: Ember.computed(function () {
      return this.chartDataSet;
    }),
    actions: {
      onNewTabClick(route) {
        this.set('tabStatus', 0);
      },

      onHistoryTabClick(route) {
        this.set('tabStatus', 1);
      },

      filterYearly() {
        this.set('columns', this.get('columnsYearly'));
        this.set('isYearly', true);
        this.set('isMonthly', false);
        this.set('isWeekly', false);
        this.set('chartOptions.xAxis.categories', []);
        this.send('getChartData', this.get('activeReport'));

        if (this.get('tabStatus') === 1) {
          this.set('tabStatus', 0);
        }
      },

      filterMonthly() {
        this.set('columns', 1);
        this.set('isYearly', false);
        this.set('isMonthly', true);
        this.set('isWeekly', false);
        this.set('chartOptions.xAxis.categories', []);
        this.send('getChartData', this.get('activeReport'));

        if (this.get('tabStatus') === 1) {
          this.set('tabStatus', 0);
        }
      },

      filterWeekly() {
        this.set('columns', 2);
        this.set('isYearly', false);
        this.set('isMonthly', false);
        this.set('isWeekly', true);
        this.set('chartOptions.xAxis.categories', []);
        this.send('getChartData', this.get('activeReport'));

        if (this.get('tabStatus') === 1) {
          this.set('tabStatus', 0);
        }
      },

      reportMenuClick(reportId) {
        if (this.get('tabStatus') === 1) {
          this.set('tabStatus', 0);
        }

        this.set('activeReport', reportId);
        this.send('getTableData', reportId);
        this.send('getChartData', reportId);
        this.send('getPDFData', reportId);
      },

      getTableData(reportID) {
        let report = this.repArray.findBy('id', reportID);
        this.set('selectedReport', report);
      },

      getChartData(reportID) {
        let report = this.repArray.findBy('id', reportID);
        this.set('chartOptions.title.text', report.name);
        let chartData2 = [];
        let chartLabel = [];

        if (this.get('columns') === 1) {
          report.value.monthly.forEach(item => {
            chartData2.push({
              name: item['name'],
              data: [Number(item['Week 1']), Number(item['Week 2']), Number(item['Week 3']), Number(item['Week 4'])]
            });
          });
          chartLabel.push('Week 1', 'Week 2', 'Week 3', 'Week 4');
        } else if (this.get('columns') === 2) {
          report.value.weekly.forEach(item => {
            chartData2.push({
              name: item['name'],
              data: [Number(item['Mon']), Number(item['Tue']), Number(item['Wed']), Number(item['Thu']), Number(item['Fri']), Number(item['Sat']), Number(item['Sun'])]
            });
          });
          chartLabel.push('Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun');
        } else {
          report.value.yearly.forEach(item => {
            chartData2.push({
              name: item['name'],
              data: [Number(item['Jan']), Number(item['Feb']), Number(item['Mar']), Number(item['Apr']), Number(item['May']), Number(item['Jun']), Number(item['Jul']), Number(item['Aug']), Number(item['Sep']), Number(item['Oct']), Number(item['Nov']), Number(item['Dec'])]
            });
          });
          chartLabel.push('Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec');
        }

        this.set('chartOptions.xAxis.categories', []);
        this.set('chartOptions.xAxis.categories', chartLabel);
        this.set('chartData2', chartData2);
      },

      getPDFData(reportID) {
        let report = this.repArray.findBy('id', reportID);
        report.value.yearly.forEach(item => {
          this.pdfTableDataSet.push([item['name'], item['Jan'], item['Feb'], item['Mar'], item['Apr'], item['May'], item['Jun'], item['Jul'], item['Aug'], item['Sep'], item['Oct'], item['Nov'], item['Dec'], item['total'], item['percent']]);
        });
      },

      saveexcel(type) {
        console.log((0, _jquery.default)('.table'));
        (0, _jquery.default)('.table').tableExport({
          filename: 'table_%DD%-%MM%-%YY%',
          format: type,
          bootstrap: false
        });
      },

      savepdf() {
        var doc = new jsPDF('l', 'pt');
        doc.setFontSize(15);
        doc.setTextColor(0, 10, 255);
        doc.text(40, 50, 'REPORT: ' + this.get('selectedReport.name'));
        doc.setFontSize(8);
        doc.autoTable(['Name', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Total', 'Percent'], this.get('pdfTableDataSet'), {
          margin: {
            top: 70
          }
        });
        doc.addPage();
        var canvas = (0, _jquery.default)('div.highcharts-root');
        console.log(canvas);
        var image = new Image(); // image.src = canvas.get(0).toDataURL("image/png")

        image.src = canvas.get(1).toDataURL("image/png");
        doc.addImage(image, 'JPEG', 40, 40, 750, 450);
        doc.save(this.get('selectedReport.name'), '.pdf');
      },

      rowClick(index, record) {
        this.set('batchItems', record);
        this.set('showPrescription', true);
      },

      onDateFilterSubmit(fromDate, toDate) {
        let firstDay = '';
        let lastDay = '';

        if (this.get('columns') === 0) {
          firstDay = new Date(fromDate.getFullYear(), 0, 1);
          lastDay = new Date(fromDate.getFullYear(), 11 + 1, 0);
        } else {
          firstDay = fromDate;
          lastDay = toDate;
        }

        console.log(moment(fromDate).format("YYYY-MM-DD") + '-' + moment(toDate).format("YYYY-MM-DD"));
        this.get('ajaxRequest').generalfacilityReport(moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD"), {
          success: response => {
            console.log("Reports: ", response);
            this.repArray = response;
            this.set('selectedReport', []);
            this.set('chartData2', []);

            if (this.get('activeReport') != '') {
              console.log('filter rep', this.get('activeReport'));
              this.send('reportMenuClick', this.get('activeReport'));
            }
          },
          error: error => {
            console.log("Unable to get reports: ", failure);
          }
        }); // if(this.get('activeReport') != ''){
        //   console.log('filter rep',this.get('activeReport') )
        //   // this.set('selectedReport',[])
        //   // this.set('chartData2',[])
        //   this.send('getTableData',this.get('activeReport'))
        //   this.send('getChartData',this.get('activeReport'))
        //   this.send('getPDFData',this.get('activeReport'))
        // }
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/
        ,
        messages: {
          noDataToShow: 'No records - select report to populate table'
        }
      });
    }),
    columnsYearly: [{
      "propertyName": "name"
    }, {
      "propertyName": "Jan"
    }, {
      "propertyName": "Feb"
    }, {
      "propertyName": "Apr"
    }, {
      "propertyName": "May"
    }, {
      "propertyName": "Jun"
    }, {
      "propertyName": "Jul"
    }, {
      "propertyName": "Aug"
    }, {
      "propertyName": "Sep"
    }, {
      "propertyName": "Oct"
    }, {
      "propertyName": "Nov"
    }, {
      "propertyName": "Dec"
    }, {
      "title": "Total",
      "propertyName": "total"
    }, {
      "title": " Per (%) ",
      "propertyName": "percent"
    }],
    columnsMonthly: [{
      "propertyName": "name"
    }, {
      "propertyName": "Week 1"
    }, {
      "propertyName": "Week 2"
    }, {
      "propertyName": "Week 3"
    }, {
      "propertyName": "Week 4"
    }, {
      "title": "Total",
      "propertyName": "total"
    }, {
      "title": " Per (%) ",
      "propertyName": "percent"
    }],
    columnsWeekly: [{
      "propertyName": "name"
    }, {
      "propertyName": "Mon"
    }, {
      "propertyName": "Tue"
    }, {
      "propertyName": "Wed"
    }, {
      "propertyName": "Thu"
    }, {
      "propertyName": "Fri"
    }, {
      "propertyName": "Sat"
    }, {
      "propertyName": "Sun"
    }, {
      "title": "Total",
      "propertyName": "total"
    }, {
      "title": " Per (%) ",
      "propertyName": "percent"
    }]
  });

  _exports.default = _default;
});
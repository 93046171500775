define("hmis/routes/hmis-app/opd/index", ["exports", "hmis/utils/date-util"], function (_exports, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({// model(params) {
    //     return RSVP.hash({
    //         queuedPatients: this.get('store').query('queued-patient', {
    //             filter: {
    //               departmentUnit: 'opd',
    //               today: true,
    //               served: 0,
    //             },
    //           })
    //           // .then(function(pat) {
    //           //   return pat;
    //           // }), //change request to get current patient instead,
    //           ,
    //         vitalsRequests: this.get('store').query('vitals-request', {
    //           filter: {
    //             served: 0,
    //           },
    //         })
    //         // patient: this.get('store').findRecord('patient', params.record_id), //change request to get current patient instead,
    //     });			
    //   }
  });

  _exports.default = _default;
});
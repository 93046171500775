define("hmis/models/patient", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util"], function (_exports, _emberData, _relationships, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    picture: _emberData.default.attr(),
    firstName: _emberData.default.attr(),
    title: _emberData.default.attr(),
    middleName: _emberData.default.attr(),
    lastName: _emberData.default.attr(),
    fullName: _emberData.default.attr(),
    gender: (0, _relationships.belongsTo)('gender'),
    genderName: _emberData.default.attr(),
    dateOfBirth: _emberData.default.attr(),
    maritalStatus: _emberData.default.attr(),
    isEmergency: _emberData.default.attr(),
    educationalLevel: _emberData.default.attr(),
    nationalIdType: _emberData.default.attr(),
    nationalIdNumber: _emberData.default.attr(),
    nationality: _emberData.default.attr(),
    countryOfResidence: _emberData.default.attr(),
    patientStatus: _emberData.default.attr(),
    modeOfPayment: _emberData.default.attr(),
    postAddress: _emberData.default.attr(),
    physicalAddress: _emberData.default.attr(),
    contactEmail: _emberData.default.attr(),
    region: _emberData.default.attr(),
    town: _emberData.default.attr(),
    phoneNumber: _emberData.default.attr(),
    alternatePhoneNumber: _emberData.default.attr(),
    nameOfNextOfKin: _emberData.default.attr(),
    nextOfKinEmail: _emberData.default.attr(),
    nextOfKinPhoneNumber: _emberData.default.attr(),
    nextOfKinAlternatePhoneNumber: _emberData.default.attr(),
    occupation: _emberData.default.attr(),
    emergencyContactName: _emberData.default.attr(),
    emergencyTypeOfContact: _emberData.default.attr(),
    emergencyContactTelephone: _emberData.default.attr(),
    bloodGroup: _emberData.default.attr(),
    // age:DS.attr(),
    age: Ember.computed('dateOfBirth', function () {
      var dob = new Date(this.get('dateOfBirth'));
      var diff_ms = Date.now() - dob.getTime();
      var age_dt = new Date(diff_ms);
      return Math.abs(age_dt.getUTCFullYear() - 1970); //return `${this.get('dateOfBirth')} ${this.get('middleName')?this.get('middleName'):''}  ${this.get('lastName')}`;
    }),
    folderNumber: _emberData.default.attr(),
    relatedTo: (0, _relationships.hasMany)('patient-relation', {
      inverse: 'patient'
    }),
    relativeOf: (0, _relationships.hasMany)('patient-relation', {
      inverse: 'relatedTo'
    }),
    relatives: Ember.computed('relatedTo.@each', 'relativeOf.@each', function () {
      let relatives = [];
      let that = this;
      this.get('relatedTo').then(function (records) {
        records.forEach(relation => {
          // console.log("reeeelllllllaaaaattiiivee; ",relation)
          relatives.pushObject({
            name: relation.get('relatedTo.fullName'),
            type: relation.get('type.name'),
            relation: relation,
            relationType: 0
          });
        });
      });
      this.get('relativeOf').then(function (records) {
        records.forEach(relation => {
          relation.get('patient').then(function (patient) {
            patient.get('gender').then(function (gender) {
              if (gender.get('id') == 1) //if male{
                {
                  relatives.pushObject({
                    name: patient.get('fullName'),
                    type: relation.get('type.maleInverse.name'),
                    relation: relation,
                    relationType: 1
                  });
                } else {
                relatives.pushObject({
                  name: patient.get('fullName'),
                  type: relation.get('type.femaleInverse.name'),
                  relation: relation,
                  relationType: 0
                });
              }
            });
          });
        });
      });
      return relatives;
    }),
    insuranceExpiryF: Ember.computed('insuranceExpiry', function () {
      // return `${this.get('firstName')} ${this.get('lastName')}`;
      if (this.get('insuranceExpiry') === null) {
        return null;
      } else {
        return _dateUtil.default.formatToDateTimeString(this.get('insuranceExpiry'));
      }
    }),
    dateOfBirthF: Ember.computed('dateOfBirth', function () {
      return _dateUtil.default.formatToDateTimeString(this.get('dateOfBirth'));
    }),
    visits: (0, _relationships.hasMany)('visit')
  });

  _exports.default = _default;
});
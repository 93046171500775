define("hmis/models/threshold", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    opd: _emberData.default.attr(),
    ipd: _emberData.default.attr(),
    type: _emberData.default.attr(),
    opdInfinite: _emberData.default.attr(),
    ipdInfinite: _emberData.default.attr(),
    insuranceProduct: (0, _relationships.belongsTo)('insurance-product'),
    itemId: _emberData.default.attr(),
    itemName: _emberData.default.attr()
  });

  _exports.default = _default;
});
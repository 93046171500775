define("hmis/helpers/is-perm-true", ["exports", "hmis/utils/is-perm-true-util"], function (_exports, _isPermTrueUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isPermTrue([val]
  /*, hash*/
  ) {
    return (0, _isPermTrueUtil.default)(val);
  });

  _exports.default = _default;
});
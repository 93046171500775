define("hmis/controllers/hmis-app/facility-management/add-facility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    addedInsurances: [],
    deletedInsurances: [],
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    actions: {
      back() {
        history.back();
      },

      submitForm() {
        if (navigator.onLine) {
          this.get('submitForm')();
        } else {
          this.notify.error('No Internet Connection! Please check and try again');
        }
      },

      save() {
        let model = this.get('model');
        this.set('model', this.get('store').createRecord('facility', model));
        this.set('isSaving', true);
        this.get("model").save().then(record => {
          this.set('isSaving', false); //alert("Saved");

          this.notify.success('Saved Successfully'); // console.log('saved product')

          this.set('model', {
            servicesOffered: [],
            professions: []
          });
        }).catch(failure => {
          this.set('isSaving', false);
          console.log(failure); // alert("Unable to saved:"+failure);

          this.notify.error('Unable to save');
        });
      }

    }
  }); // function Logger(id) {
  //   this.el = document.getElementById('log');
  // }
  // Logger.prototype.log = function(msg) {
  //   var fragment = document.createDocumentFragment();
  //   fragment.appendChild(document.createTextNode(msg));
  //   fragment.appendChild(document.createElement('br'));
  //   this.el.appendChild(fragment);
  // };
  //
  // Logger.prototype.clear = function() {
  //   this.el.textContent = '';
  // };
  //
  // var logger = new Logger('log');
  //
  // function updateConnectionStatus(msg, connected) {
  //   var el = document.querySelector('#connection');
  //   if (connected) {
  //     if (el.classList) {
  //       el.classList.add('connected');
  //       el.classList.remove('disconnected');
  //     } else {
  //       el.addClass('connected');
  //       el.removeClass('disconnected');
  //     }
  //   } else {
  //     if (el.classList) {
  //       el.classList.remove('connected');
  //       el.classList.add('disconnected');
  //     } else {
  //       el.removeClass('connected');
  //       el.addClass('disconnected');
  //     }
  //   }
  //   el.innerHTML = msg + '<div></div>';
  // }
  //
  // window.addEventListener('load', function(e) {
  //   if (navigator.onLine) {
  //     updateConnectionStatus('Online', true);
  //   } else {
  //     updateConnectionStatus('Offline', false);
  //   }
  // }, false);
  //
  // window.addEventListener('online', function(e) {
  //   logger.log("And we're back :)");
  //   updateConnectionStatus('Online', true);
  //   // Get updates from server.
  // }, false);
  //
  // window.addEventListener('offline', function(e) {
  //   logger.log("Connection is flaky.");
  //   updateConnectionStatus('Offline', false);
  //   // Use offine mode.
  // }, false);


  _exports.default = _default;
});
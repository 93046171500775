define("hmis/routes/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    tagName: '',
    currentUser: Ember.inject.service(),
    redirect: function () {
      let that = this; // let currentUser = that.get('currentUser.user')
      // console.log('curent user here: ',currentUser)
      // if(currentUser.get('setupUser')==1){
      //   //that.transitionTo('configure',that.get('currentUser.user').belongsTo('facility').id());
      //   that.transitionTo('configure');
      // }else {
      //   //return 'hmis-app/dashboard';

      that.transitionTo('hmis-app'); // }
      //   this.transitionTo('configure');
      // this.transitionTo('layout-test');
    }
  });

  _exports.default = _default;
});
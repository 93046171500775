define("hmis/controllers/hmis-app/admin-settings/user-permissions", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    selectedUserType: '',
    store: Ember.inject.service(),
    appData: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    isGroup: true,
    userTypes: Ember.computed(function () {
      return this.get('model.users');
    }),
    groupSelections: [],
    disableSelection: Ember.observer('selectedItems.lenght', function () {
      // this.set('selectedItems',[]);
      alert('hiii');
    }),
    modulePermissions: Ember.computed(function () {
      return Object.values(this.appData.modulePermissions);
    }),
    operationPermissions: Ember.computed(function () {
      return Object.values(this.appData.operationPermissions);
    }),
    availableModulePermissions: Ember.computed('selectedUser.modulePermissions.@each', 'modulePermissions', function () {
      let avail = [];
      if (!this.selectedUser.modulePermissions) return this.modulePermissions;
      this.modulePermissions.forEach(perm => {
        let match = this.selectedUser.modulePermissions.find((uMPerm, index, array) => {
          return uMPerm.permissionId == perm.id;
        });
        if (!match) avail.pushObject(perm);
      });
      return avail;
    }),
    availableOperationPermissions: Ember.computed('selectedUser.operationPermissions.@each', 'operationPermissions', function () {
      let avail = [];
      if (!this.selectedUser.operationPermissions) return this.operationPermissions;
      this.operationPermissions.forEach(perm => {
        //'permission.id', perm.get('id')
        let match = this.selectedUser.operationPermissions.find((uOPerm, index, array) => {
          return uOPerm.permissionId == perm.id;
        }); // console.log('match',match);

        if (!match) avail.pushObject(perm);
      });
      return avail;
    }),
    revertCopy: {},
    actions: {
      onUserChange(user) {
        if (!this.selectedUser) {
          this.send('setSelectedUser', user);
        } else {
          if (this.selectedUser.hasChanges) {
            console.error('User has changes');
            this.notify.error('Current User has changes. Save or revert first');
          } else {
            this.send('setSelectedUser', user);
          }
        }
      },

      setSelectedUser(user) {
        console.log(user);
        this.set('revertCopy', {
          modulePermissions: JSON.parse(JSON.stringify(user.modulePermissions)),
          operationPermissions: JSON.parse(JSON.stringify(user.operationPermissions))
        });
        console.log('revertCopy', this.revertCopy);
        this.set('selectedUser', user);
      },

      onSave() {
        this.appAjax.post('/userPermissions/' + this.selectedUser.id, {
          operationPermissions: this.selectedUser.operationPermissions,
          modulePermissions: this.selectedUser.modulePermissions
        }).then(res => {
          this.send('setSelectedUser', this.selectedUser);
          this.set('selectedUser.hasChanges', false);
        });
      },

      onRevert() {
        this.set('selectedUser.modulePermissions', JSON.parse(JSON.stringify(this.revertCopy.modulePermissions)));
        this.set('selectedUser.operationPermissions', JSON.parse(JSON.stringify(this.revertCopy.operationPermissions)));
        this.set('selectedUser.hasChanges', false);
      },

      newModPermission(modPermission, activeValue) {
        this.get('selectedUser.modulePermissions').pushObject({
          permissionId: modPermission.id,
          allow: activeValue
        }); // this.set('conSearchFocus', true)

        this.set('selectedUser.hasChanges', true);
        event.stopPropagation();
        event.preventDefault();
      },

      newOpPermission(opPermission, props) {
        this.get('selectedUser.operationPermissions').pushObject({
          permissionId: opPermission.id,
          add: props.add,
          edit: props.edit,
          view: props.view,
          inactivate: props.inactivate,
          allow: props.allow
        }); // this.set('conSearchFocus', true)

        this.set('selectedUser.hasChanges', true);
        event.stopPropagation();
        event.preventDefault();
      }

    },
    userColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      title: 'Name',
      "propertyName": "name"
    }, {
      "propertyName": "phoneNumber"
    }]
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/opd/consultation/consult/vitals", ["exports", "hmis/utils/model-table-theme", "hmis/utils/bmi-calc", "hmis/mixins/visit-care-controller", "hmis/mixins/app-event-mixin", "hmis/utils/notifications-util"], function (_exports, _modelTableTheme, _bmiCalc, _visitCareController, _appEventMixin, _notificationsUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_visitCareController.default, _appEventMixin.default, {
    store: Ember.inject.service('store'),
    consultationService: Ember.inject.service('consultation'),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    selectedItems: [],

    //refreshModel
    init() {
      this._super(...arguments);

      this.get('consultationService').on('vitalsChanged', () => {
        this.send('refreshModel');
      });
    },

    onPermissionNotification(e) {
      if (e.actionValue == _notificationsUtil.NOTIFICATION_ACTION_VALUES.VITALS && e.data.visitId == this.model.visitId) {
        this.send('refreshModel', true);
      }
    },

    actions: {
      rowClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        this.set('selectedBatch', record);
        this.set('showRecord', true);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      title: 'Date',
      "propertyName": "dateTimeF"
    }, {
      title: 'Examination Results',
      "propertyName": "vitalsString"
    }, {
      title: 'Taken By ',
      "propertyName": "userName"
    } // {
    //   title:'BMI',
    //   "propertyName": "bmi"
    // }
    ]
  });

  _exports.default = _default;
});
define("hmis/templates/components/viewers/file-preview-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Bq+6X6yT",
    "block": "{\"symbols\":[],\"statements\":[[4,\"alerts/confirm-dialog\",null,[[\"show\",\"title\",\"message\",\"width\",\"height\",\"confirmText\",\"cancelText\",\"onConfirm\"],[[24,[\"show\"]],[24,[\"title\"]],[24,[\"note\"]],\"80%\",\"80%\",\"Download\",\"Close\",[28,\"action\",[[23,0,[]],\"downloadFile\"],null]]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"file-preview\",null,[[\"link\",\"isServerUpload\",\"parent\"],[[24,[\"link\"]],[24,[\"isServerUpload\"]],[23,0,[]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/viewers/file-preview-alert.hbs"
    }
  });

  _exports.default = _default;
});
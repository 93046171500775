define("hmis/components/controls/paper-form", ["exports", "hmis/components/paper-form"], function (_exports, _paperForm) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperForm.default.extend({
    classNames: ['layout-row', 'layout-wrap', 'layout-align-space-between'],

    didInsertElement() {
      this._super(...arguments);

      let parent = this.get('parent');

      if (parent) {
        parent.set('submitForm', () => {
          this.send('onSubmit');
        });
        parent.set('resetForm', () => {
          console.log('resetting');
          this.get('childComponents').setEach('validationErrorMessages', null);
          this.get('childComponents').setEach('isNativeInvalid', false);
          this.get('childComponents').invoke('init');
        });
      }
    },

    //onValidFormSubmit
    actions: {
      onSubmit() {
        if (this.get('isInvalid')) {
          this.get('childComponents').setEach('isTouched', true);
          this.sendAction('onInvalid');
        } else {
          this.sendAction('onValidFormSubmit');
          this.get('childComponents').setEach('isTouched', false);
        }
      }

    }
  });

  _exports.default = _default;
});
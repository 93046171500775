define("hmis/templates/components/controls/progress-list-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VxSO5ln6",
    "block": "{\"symbols\":[\"filteredList\",\"item\",\"index\",\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"showSearch\"]]],null,{\"statements\":[[1,[28,\"input\",null,[[\"class\",\"placeholder\",\"value\",\"placeholder\"],[\"searchbox\",\"Search\",[24,[\"query\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"list-filter\",null,[[\"data\",\"query\",\"filterParams\"],[[24,[\"list\"]],[24,[\"query\"]],[24,[\"filterParams\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[4,\"controls/progress-list-item\",null,[[\"iconText\",\"main\",\"subText\",\"record\",\"progress\",\"onClick\",\"onRemove\",\"onDoubleClick\",\"hideRemove\"],[[28,\"get\",[[23,2,[]],[24,[\"iconText\"]]],null],[28,\"get\",[[23,2,[]],[24,[\"main\"]]],null],[28,\"get\",[[23,2,[]],[24,[\"sub\"]]],null],[23,2,[]],[28,\"get\",[[23,2,[]],[24,[\"progress\"]]],null],[28,\"action\",[[23,0,[]],\"clickRecord\"],null],[28,\"action\",[[23,0,[]],\"removeRecord\"],null],[28,\"action\",[[23,0,[]],\"doubleClickRecord\"],null],[24,[\"hideDelete\"]]]],{\"statements\":[[0,\"                \"],[14,4],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[2,3]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/progress-list-view.hbs"
    }
  });

  _exports.default = _default;
});
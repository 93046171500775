define("hmis/models/admitted-queued-patient", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/utils/request-utils/ipd", "hmis/mixins/visit-service-model", "hmis/mixins/billable-model"], function (_exports, _emberData, _relationships, _dateUtil, _ipd, _visitServiceModel, _billableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, _billableModel.default, {
    admissionDate: _emberData.default.attr(),
    dischargedDate: _emberData.default.attr(),
    isPendingDischarge: _emberData.default.attr(),
    admissionNote: _emberData.default.attr(),
    suggestedWard: (0, _relationships.belongsTo)('department-unit', {
      async: false
    }),
    admissionWard: (0, _relationships.belongsTo)('department-unit', {
      async: false
    }),
    admissionType: _emberData.default.attr(),
    departmentUnit: (0, _relationships.belongsTo)('ward', {
      async: false
    }),
    diagnosisSummary: _emberData.default.attr(),
    admissionDateF: Ember.computed('admissionDate', function () {
      return _dateUtil.default.formatToDateTimeString(this.get('admissionDate'));
    }),
    dischargeDateF: Ember.computed('dischargeDate', function () {
      return _dateUtil.default.formatToDateTimeString(this.get('dischargeDate'));
    })
  });

  _exports.default = _default;
});
define("hmis/components/controls/sticky-table", ["exports", "hmis/mixins/after-render", "hmis/components/models-table"], function (_exports, _afterRender, _modelsTable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelsTable.default.extend(_afterRender.default, {
    // init() {
    //     this._super(...arguments);
    //     if(!this.get('indexOnEdit'))
    //     {
    //         this.set('indexOnEdit', -1)
    //     }
    // },
    //
    afterRenderEvent: function () {
      let container = '.sticky-header-table';
      $(container).prepend(" <tr class='st-header'>  </tr> ");
      $(container + " table thead tr").each(function () {
        $('th', this).each(function () {
          // console.log($(this).width())
          var $newCell = $("<th>", {
            id: "",
            "class": "st-header-cell"
          });
          $newCell.text($(this).text());
          $newCell.outerWidth($(this).outerWidth());
          $(this).resize(function () {
            console.log('xxx');
          });
          $(container + ' .st-header').append($newCell); // $(container+' .st-header').append( "<th style='width:"+$(this).width()+"px; '>"+$(this).text()+'</th>' )
        });
      });
      $(container + ' .sticky-header-table-inner').scroll(function () {
        var y = $(this).scrollTop();
        $('#testId').text(y);

        if (y > 40) {
          $('.st-header').fadeIn();
        } else {
          $('.st-header').fadeOut();
        }
      });
    }
  });

  _exports.default = _default;
});
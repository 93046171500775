define("hmis/routes/hmis-app/procedures/review", ["exports", "hmis/mixins/visit-request-review-route", "hmis/mixins/route-error-handler"], function (_exports, _visitRequestReviewRoute, _routeErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_visitRequestReviewRoute.default, _routeErrorHandler.default, {
    breadCrumb: null,
    serviceName: 'procedure'
  });

  _exports.default = _default;
});
define("hmis/templates/hmis-app/records/patient/history", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RtO3xQXU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Back\",[28,\"action\",[[23,0,[]],\"back\"],null],\"back-fill\"]]],false],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"disabled\",\"icon\",\"onClick\"],[\"Revert changes\",[24,[\"disabled\"]],\"undo\",[28,\"action\",[[23,0,[]],\"onRevert\"],null]]]],false],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"waiting\",\"disabled\",\"icon\",\"onClick\"],[\"Save changes\",[24,[\"waiting\"]],[24,[\"disabled\"]],\"save\",[28,\"action\",[[23,0,[]],\"onSave\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\",\"message\"],[\"full-height\",true,\"History Information\",\"[has  ]\"]],{\"statements\":[[0,\"\\n\"],[0,\"        \"],[7,\"div\",true],[10,\"class\",\"content content-list\"],[8],[0,\"\\n            \"],[1,[28,\"questionnaire/q-edit\",null,[[\"structure\",\"valueSet\"],[[24,[\"data\",\"patientHistoryQuestionnaireStructure\"]],[24,[\"valueSet\"]]]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/records/patient/history.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/forms/insurance-alert", ["exports", "hmis/mixins/form-alert", "hmis/mixins/ref-component"], function (_exports, _formAlert, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, _refComponent.default, {
    modelName: 'patient-insurance',
    store: Ember.inject.service('store'),
    ajaxRequest: Ember.inject.service(),
    notify: Ember.inject.service(),
    type: "add",
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    isAdd: Ember.computed('record.id', function () {
      return this.record.id;
    }),
    maxDate: moment().format("YYYY-MM-DD"),
    //-MM-DD
    insuranceExpiryYearLimit: Ember.computed(function () {
      var today = new Date();
      var year = today.getFullYear() + 30;
      return year;
    }),
    dateToday: new Date(),
    insuranceVeriInfo: '',
    insuranceError: false,
    insuranceCompanies: Ember.computed(function () {
      return this.get('store').query('insurance-company', {
        pageSize: 10
      });
    }),
    insuranceProducts: Ember.computed('record.insuranceCompany', function () {
      return this.get('store').query('insurance-product', {
        filter: {
          insuranceCompanyId: this.get('record.insuranceCompany') ? this.get('record.insuranceCompany.id') : 'n/a'
        },
        pageSize: 10
      });
    }),

    showForm(record, onSavedCallback) {
      this.set('record', record);
      this.set('show', true);
      this.set('onSaved', onSavedCallback);
    },

    actions: {
      insuranceCompanyChange(rec) {
        this.set('record.insuranceCompany', rec);
        this.set('record.insuranceProduct', null);
      },

      onSubmit() {
        let record = this.record;
        this.set('waiting', true);
        if (!this.get('record.id')) record = this.get('store').createRecord(this.modelName, record);
        record.save().then(rec => {
          this.set('record', {});
          this.set('show', false);
          if (this.onSaved) this.onSaved(rec);
          this.notify.success('Added');
          this.set('waiting', false);
        }, err => {
          this.set('waiting', false);
          this.notify.error(err);
        });
      }

    }
  });

  _exports.default = _default;
});
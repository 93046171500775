define("hmis/utils/model-table-theme", ["exports", "ember-models-table/themes/bootstrap3"], function (_exports, _bootstrap) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import DefaultTheme from './default';
  // /**
  //  * @class Bootstrap3
  //  * @namespace Themes
  //  * @extends Themes.Default
  //  */
  var _default = _bootstrap.default.extend({});

  _exports.default = _default;
});
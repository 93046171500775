define("hmis/templates/hmis-app/opd/vitals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "URH1VwTc",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n\"],[0,\"    \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Back\",[28,\"action\",[[23,0,[]],\"back\"],null],\"back-fill\"]]],false],[0,\"\\n    \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Save\",[28,\"action\",[[23,0,[]],\"save\"],null],\"save-fill\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\\n\"],[0,\"\\n\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height  has-main-table\",true,\"Vitals\"]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"app-fixed-top\"],[8],[1,[28,\"controls/component-title\",null,[[\"first\",\"title\",\"info\"],[true,[24,[\"model\",\"patientName\"]],[28,\"concat\",[\"\",[24,[\"model\",\"visit\",\"purpose\",\"name\"]]],null]]]],false],[9],[0,\"\\n        \\n        \"],[1,[28,\"managers/vitals-batch-manager\",null,[[\"vitalSigns\",\"tagName\"],[[24,[\"model\",\"newVitals\"]],\"\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/opd/vitals.hbs"
    }
  });

  _exports.default = _default;
});
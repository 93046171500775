define("hmis/components/app-mini-workspace", ["exports", "hmis/mixins/page-error-handler"], function (_exports, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_pageErrorHandler.default, {
    tagName: '',
    isClosed: false,
    onInit: true,
    actions: {
      close() {
        this.set('isClosed', true);
        this.set('onInit', false);
      },

      open() {
        this.set('isClosed', false);
        this.set('onInit', false);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/change-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2fpYq+Mo",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"alerts/confirm-dialog\",null,[[\"waiting\",\"show\",\"unClosable\",\"onConfirm\",\"title\",\"message\",\"confirmDisabled\",\"width\",\"height\",\"confirmText\",\"closeOnConfirm\",\"footerInfo\"],[[24,[\"changing\"]],[24,[\"show\"]],[24,[\"unClosable\"]],[28,\"action\",[[23,0,[]],\"onSave\"],null],\"Setting New Password\",\"\",[24,[\"disableSave\"]],\"30%\",\"50%\",\"Change Password\",false,[24,[\"footerInfo\"]]]],{\"statements\":[[4,\"controls/paper-form\",null,[[\"onValidFormSubmit\",\"parent\"],[[28,\"action\",[[23,0,[]],\"onValidFormSubmit\"],null],[23,0,[]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"layout-column flex\"],[8],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"type\",\"class\",\"required\",\"value\",\"onChange\",\"label\",\"icon\"],[\"password\",\"flex-100\",true,[24,[\"oldPassword\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"oldPassword\"]]],null]],null],\"Old Password\",\"lock\"]]],false],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"type\",\"class\",\"required\",\"value\",\"onChange\",\"label\",\"icon\"],[\"password\",\"flex-100\",true,[24,[\"newPassword\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"newPassword\"]]],null]],null],\"New Password\",\"lock\"]]],false],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"type\",\"class\",\"customValidations\",\"required\",\"value\",\"onChange\",\"label\",\"icon\"],[\"password\",\"flex-100\",[24,[\"verifyPasswordValidation\"]],true,[24,[\"verifyPassword\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"verifyPassword\"]]],null]],null],\"Confirm Password\",\"lock\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/change-password.hbs"
    }
  });

  _exports.default = _default;
});
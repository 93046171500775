define("hmis/services/ajax-request", ["exports", "jquery", "hmis/config/environment", "hmis/utils/date-util", "hmis/utils/server-util"], function (_exports, _jquery, _environment, _dateUtil, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const url = _serverUtil.serverUrl;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    serverResponse: Ember.inject.service(),

    // token: computed('session.data.authenticated.access_token', function() {
    //   return this.get('session.data.authenticated.access_token')
    // }),
    makeAuthorizedRequest(context, requestType, urlExt, data, callBack) {
      let token = context.get('session.data.authenticated.access_token'); // console.log('session.data.authenticated', context.get('session.data.authenticated'));

      let headers = {
        Authorization: 'Bearer ' + token
      };
      return _jquery.default.ajax({
        headers: headers,
        type: requestType,
        url: url + urlExt,
        data: data
      }).then((response, status, xhr) => {
        if (callBack) return callBack.success(response, status, xhr);
      }, error => {
        if (callBack) return callBack.error(context.get('serverResponse').errorMessage(error));
        console.log("Unable to sent request: ", error);
      }); // });
    },

    makeAuthorizedXmlRequest(context, urlExt) {
      context.get('session').authorize('authorizer:oauth2', (headerName, headerValue) => {
        let token = context.get('session.data.authenticated.access_token');
        let headers = {
          Authorization: 'Bearer ' + token
        };
        headers[headerName] = headerValue;
        window.open(url + urlExt, '_blank');
      });
    },

    makeRequest(requestType, url, data, callBack) {
      this.get('makeAuthorizedRequest')(this, requestType, url, data, {
        success: response => {
          if (callBack) return callBack.success(response);
        },
        error: error => {
          if (callBack) return callBack.error(error);
        }
      });
    },

    downloadFile(url, filename) {
      _jquery.default.ajax({
        url: url,
        method: 'GET',
        xhrFields: {
          responseType: 'blob'
        },
        success: function (data) {
          var a = document.createElement('a');
          var url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = filename;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });
    },

    //////////////////////////////////////////////////////////////////////////////
    //  OPD Requests
    //////
    appConfigs(callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/appConfigurations", null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    saveAppConfigs(data, callBack) {
      this.get('makeAuthorizedRequest')(this, 'POST', "/appConfigurations", data, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    // endActiveServe(activeServe, callBack){
    //   this.get('makeAuthorizedRequest')(this,'POST', "/endActiveServe/"+ activeServe.get('id'), null, {
    //     success: (response)=>{
    //       activeServe.set('endDate', dateUtil.toSqlDate(new Date()))
    //         console.log("request success: ",response)
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    changePassword(oldPassword, newPassword, callBack) {
      let userId = this.get('currentUser.user.id'); // console.log('user id:', userId);

      this.get('makeAuthorizedRequest')(this, 'POST', "/changePassword/" + userId, {
        oldPassword,
        newPassword
      }, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          console.log(error);
          if (callBack) callBack.error(error);
        }
      });
    },

    resetPassword(userId, callBack) {
      this.get('makeAuthorizedRequest')(this, 'POST', "/resetPassword/" + userId, null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    applyProcedure(procedure, callBack) {
      let data = {
        documents: [],
        // remarks:procedure.get('remarks'),
        resultNote: procedure.get('resultNote')
      };
      console.log(data);
      this.get('makeAuthorizedRequest')(this, 'POST', "/applyProcedure/" + procedure.get('id'), data, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    // applyXray(xray, callBack){
    //   let data={
    //     documents: [],
    //     resultNote: xray.get('resultNote')
    //   }
    //   console.log(data);
    //   this.get('makeAuthorizedRequest')(this,'POST', "/applyXray/"+ xray.get('id'), data, {
    //     success: (response)=>{
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    // applyUltrasound(ultrasound, callBack){
    //   let data={
    //     documents: [],
    //     resultNote: ultrasound.get('resultNote')
    //   }
    //   console.log(data);
    //   this.get('makeAuthorizedRequest')(this,'POST', "/applyUltrasound/"+ ultrasound.get('id'), data, {
    //     success: (response)=>{
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    // applyInvestigation(investigation, callBack){
    //   let data={ result: investigation.get('result'), warning: investigation.get('warning'), requestNote: investigation.get('requestNote'), resultNote: investigation.get('resultNote')
    //   }
    //   this.get('makeAuthorizedRequest')(this,'POST', "/applyInvestigation/"+ investigation.get('id'), data, {
    //     success: (response)=>{
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    // dispensePrescriptionBatch(prescriptionBatch, callBack){
    //   let prescriptions=[]
    //   prescriptionBatch.get('prescriptions').forEach(prescription => {
    //     prescriptions.push({id:prescription.get('id'), dispensedQuantity:prescription.get('dispensedQuantity') })
    //   });
    //   for (var i = 0; i < prescriptions.length; i++) {
    //     if(prescriptions[i].dispensedQuantity<0){
    //       callBack.error('Quantity dispensed can not be a negative value')
    //       return;
    //     }
    //     if(prescriptions[i].dispensedQuantity==''){
    //       callBack.error('Quantity dispensed can not be empty. Value should be greater than or equal to zero')
    //       return;
    //     }
    //   }
    //   this.get('makeAuthorizedRequest')(this,'POST', "/dispensePrescriptionBatch/"+ prescriptionBatch.get('id'), {prescriptions}, {
    //     success: (response)=>{
    //       prescriptionBatch.set('dispensed', true)
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    //////////////////////////////////////////////////////////////////////////////
    //  IPD Requests
    //////
    // requestAdmission(visit, note, admissionType, callBack){
    //   let data={ 'visit': visit.get('id'), 'admissionNote': note, 'admissionType':admissionType }
    //   this.get('makeAuthorizedRequest')(this,'POST', "/requestAdmission/"+visit.get('id'), data, {
    //     success: (response)=>{
    //       // visit.set('status', 2)
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    //   // Ember.$.post(url+"/requestAdmission", { 'visit': visitId, 'admissionNote': note })
    // },
    // admitPatients(admittedQueuedPatientIds, room, callBack){
    //   let data={ admittedQueuedPatients: admittedQueuedPatientIds, room: room.get('id') }
    //   this.get('makeAuthorizedRequest')(this,'POST', "/admitPatients", data, {
    //     success: (response)=>{
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    //     // Ember.$.post(url+"/admitPatient", { ids: admittedQueuedPatientIds , ward: departmentUnit})
    // },
    requestDischarge(admittedQueuedPatient, callBack) {
      this.get('makeAuthorizedRequest')(this, 'POST', "/requestDischarge/" + admittedQueuedPatient.get('id'), null, {
        success: response => {
          admittedQueuedPatient.set('isPendingDischarge', 1);
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      }); // Ember.$.post(url+"/requestDischarge", { 'visit': visitId,'outcome':outcomeId , 'note': note })
    },

    addBeds(bedName, room, numberOfBeds, callBack) {
      let data = {
        'bedName': bedName,
        'roomId': room.get('id'),
        'numberOfBeds': numberOfBeds
      };
      this.get('makeAuthorizedRequest')(this, 'POST', "/bedsMulti", data, {
        success: response => {
          let beds = response;
          let store = this.get('store');
          let mBeds = room.get('beds');
          beds.forEach(bed => {
            let nPayload = store.normalize('bed', bed);
            let b_model = store.push(nPayload);
            mBeds.pushObject(b_model);
          });
          if (callBack) callBack.success(mBeds);
        },
        error: xhr => {
          if (callBack) callBack.error(xhr);
        }
      });
    },

    // dischargePatient(admittedQueuedPatient, outcome, note, callBack){
    //     let data={ 'outcome':outcome.get('id') , 'note': note }
    //     this.get('makeAuthorizedRequest')(this,'POST', "/dischargePatient/"+admittedQueuedPatient.get('id'), data, {
    //       success: (response)=>{
    //         admittedQueuedPatient.set('dischargedDate', new Date())
    //         if(callBack)
    //           callBack.success(response)
    //       },
    //       error:(error)=>{
    //         if(callBack)
    //           callBack.error(error)
    //       }
    //     })
    // },
    // rejectPatient(queuedPatient){
    //   // let data={
    //   //   documents: [],
    //   //   resultNote: investigation.get('resultNote')
    //   // }
    //   // this.get('makeAuthorizedRequest')(this,'POST', "/applyInvestigation/"+ investigation.get('id'), data, {
    //   //   success: (response)=>{
    //   //     if(callBack)
    //   //       callBack.success(response)
    //   //   },
    //   //   error:(error)=>{
    //   //     if(callBack)
    //   //       callBack.error(error)
    //   //   }
    //   // })
    // },
    //////////////////////////////////////////////////////////////////////////////
    //  Transacton Requests
    //////
    // payTransaction(transaction, callBack){
    //   this.get('makeAuthorizedRequest')(this,'POST', "/payTransaction/"+transaction.get('id'), null, {
    //     success: (response)=>{
    //       transaction.set('isPaid', true)
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    // payMultiTransactions(transactions, callBack){
    //   let transactionIds=[]
    //   let transSent=[]
    //   transactions.forEach(trans => {
    //     if(!trans.get('isPaid')){
    //       transactionIds.push(trans.get('id'))
    //       transSent.push(trans)
    //     }
    //   });
    //   this.get('makeAuthorizedRequest')(this,'POST', "/payTransactions", {transactions: transactionIds}, {
    //     success: (response)=>{
    //       transSent.forEach(trans => {
    //         trans.set('isPaid', true)
    //         trans.set('hasChange', !trans.get('hasChange'))
    //       });
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    // moveTransactionToBills(transaction, callBack){
    //   this.get('makeAuthorizedRequest')(this,'POST', "/moveTransactionToBills/"+transaction.get('id'), null, {
    //     success: (response)=>{
    //       transaction.set('isInsurance', false)
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    // moveTransactionToClaims(transaction , insuranceProductId, callBack){
    //   this.get('makeAuthorizedRequest')(this,'POST', "/moveTransactionToClaims/"+transaction.get('id'), {insuranceProduct:insuranceProductId}, {
    //     success: (response)=>{
    //       transaction.set('isInsurance', true)
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    //////////////////////////////////////////////////////////////////////////////
    //  _____ Requests
    //////
    // exportBillToPdf(visit, callBack){
    //   window.open(url+"/exportBillToPdf/"+visit.get('id'),'_blank')
    // },
    // exportClaimToPdf(visit, callBack){
    //   window.open(url+"/exportClaimToPdf/"+visit.get('id'),'_blank')
    // },
    // printBill(visit, callBack){
    //   this.get('makeAuthorizedRequest')(this,'GET', "/printPatientBill/"+visit.get('id'), null, {
    //     success: (response)=>{
    //       let w=window.open(); w.document.write(response); 
    //       setTimeout(function() {
    //         w.print(); w.close();
    //       }, 250);
    //       if(callBack)
    //         callBack.success(response)
    //     },
    //     error:(error)=>{
    //       if(callBack)
    //         callBack.error(error)
    //     }
    //   })
    // },
    exportSummaryToPdf(visit, callBack) {
      window.open(url + "/exportVisitSummary/" + visit.get('id'), '_blank');
      if (callBack) callBack.success();
    },

    printSummary(visit, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/printVisitSummary/" + visit.get('id'), null, {
        success: response => {
          let w = window.open();
          w.document.write(response);
          setTimeout(function () {
            w.print();
            w.close();
          }, 250);
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    printPrescription(visit, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/printExtPrescription/" + visit.get('id'), null, {
        success: response => {
          let w = window; //w.print();

          console.log('print issue');
          setTimeout(function () {// w.open()
            // w.document.write(response); 
            // w.print(); //w.close();
          }, 250);
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    emailSummary(visit, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/mailVisitSummary/" + visit.get('id'), null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    printClaim(visit, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/printPatientClaim/" + visit.get('id'), null, {
        success: response => {
          let w = window.open();
          w.document.write(response);
          setTimeout(function () {
            w.print();
            w.close();
          }, 250);
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    emailBill(visit, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/sendBillMail/" + visit.get('id'), null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    emailClaim(visit, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/sendClaimMail/" + visit.get('id'), null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    endFacilitySetup(callBack) {
      this.get('makeAuthorizedRequest')(this, 'POST', "/endFacilitySetup", null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    submitClaim(fac, startdate, enddate, format, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/exportNhisClaim?facility=" + fac + "&startDate=" + startdate + "&endDate=" + enddate + "&format=" + format, null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    updateSubmittedClaimStatus(startdate, enddate, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/submitClaim?startDate=" + startdate + "&endDate=" + enddate, null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    xmlDownloadClaim(fac, startdate, enddate, format) {
      this.get('makeAuthorizedXmlRequest')(this, "/exportNhisClaim?facility=" + fac + "&startDate=" + startdate + "&endDate=" + enddate + "&format=" + format);
    },

    //context,requestType, urlExt, data
    sendExtClaim(data, callBack) {
      this.url = function () {
        return 'http://10.76.3.190';
      }();

      this.get('makeAuthorizedRequest')(this, 'POST', "/chagws/datatransfer/submitNHIAClaims", data, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    getDeactivatedFacilities(callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/deactivatedFacilities", null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    getArchivedUsers(callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/archivedusers", null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    activateArchivedUser(usr, callBack) {
      this.get('makeAuthorizedRequest')(this, 'POST', "/activateArchivedUser/" + usr, null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    getArchivedPatients(callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/archivedPatients", null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    activateArchivedPatient(patId, callBack) {
      this.get('makeAuthorizedRequest')(this, 'POST', "/activateArchivedPatient/" + patId, null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    activateDeactivatedFacility(fac, callBack) {
      this.get('makeAuthorizedRequest')(this, 'POST', "/activateFacility/" + fac, null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    //----------------------General Rports Request------------------->
    generalfacilityReport(fdate, edate, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/reportsold/" + this.get('currentUser.facilityId') + "/" + fdate + "/" + edate, null, {
        success: response => {
          if (callBack) callBack.success(response[0]['reports']);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    generateReport(reportType, fdate, edate, callBack, pageIndex = 1, paginationMeta = null) {
      this.get('makeAuthorizedRequest')(this, 'POST', "/reports/" + reportType + "/" + fdate + "/" + edate, {
        pageIndex: pageIndex,
        pageMeta: paginationMeta
      }, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    //----------------------------------------------------------------
    ////////
    verifyNhisNumber(number, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', "/verifyNhis/" + number, null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          // console.log('errrr')
          if (callBack) callBack.error(error.responseJSON);
        }
      });
    },

    billablePriceReview(url, callBack) {
      this.get('makeAuthorizedRequest')(this, 'GET', url, null, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    }

  });

  _exports.default = _default;
});
define("hmis/components/forms/emergency-patient-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    modelName: 'emergency-patient',
    wardColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false,
      "className": 'allergy-checkbox-width'
    }, {
      "propertyName": "name",
      'className': 'main-column'
    }],
    roomColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false,
      "className": 'allergy-checkbox-width'
    }, {
      "propertyName": "name",
      'className': 'main-column'
    }]
  });

  _exports.default = _default;
});
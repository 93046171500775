define("hmis/templates/sign-in/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UI05Dpzv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"form\",true],[10,\"class\",\"sign-form\"],[8],[0,\"\\n\\n\"],[7,\"h4\",true],[8],[0,\"Sign Up\"],[9],[0,\"\\n\\n\\n\"],[1,[28,\"paper-input\",null,[[\"label\",\"type\",\"value\",\"icon\",\"onChange\"],[\"Name\",\"name\",[24,[\"uname\"]],\"person-outline\",[28,\"action\",[[23,0,[]],\"onNameChanged\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"paper-input\",null,[[\"label\",\"type\",\"value\",\"icon\",\"onChange\"],[\"Email\",\"email\",[24,[\"uname\"]],\"mail-outline\",[28,\"action\",[[23,0,[]],\"onNameChanged\"],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"paper-input\",null,[[\"label\",\"type\",\"value\",\"icon\",\"onChange\"],[\"Date of birth\",\"date\",[24,[\"uname\"]],\"date-range\",[28,\"action\",[[23,0,[]],\"onNameChanged\"],null]]]],false],[0,\"\\n\\n\\n\"],[1,[28,\"paper-input\",null,[[\"label\",\"type\",\"value\",\"icon\",\"onChange\"],[\"Password\",\"password\",[24,[\"upass\"]],\"lock-outline\",[28,\"action\",[[23,0,[]],\"onPassChanged\"],null]]]],false],[0,\"\\n\"],[1,[28,\"paper-input\",null,[[\"label\",\"type\",\"value\",\"icon\",\"onChange\"],[\"Verify Password\",\"password\",[24,[\"upass\"]],\"lock-outline\",[28,\"action\",[[23,0,[]],\"onPassChanged\"],null]]]],false],[0,\"\\n\\n\\n\\n\"],[7,\"button\",true],[10,\"class\",\"icon-button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onSubmit\"],null]],[8],[0,\"Create Account\"],[7,\"i\",true],[10,\"class\",\"fa fa-lock\"],[8],[9],[0,\" \"],[9],[0,\"\\n\"],[0,\"\\n\\n\"],[4,\"link-to\",null,[[\"class\",\"route\"],[\"sign-refer sign-toggle\",\"sign-in\"]],{\"statements\":[[0,\"        Already a user? Sign In.\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/sign-in/register.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/charts/chart-with-keys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WaNElAnM",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"ember-chart\",null,[[\"type\",\"options\",\"data\"],[[24,[\"type\"]],[24,[\"options\"]],[24,[\"data\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/charts/chart-with-keys.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/services/server-response", ["exports", "hmis/config/environment", "hmis/utils/date-util"], function (_exports, _environment, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function handleError(errors) {
    //
    if (Array.isArray(errors)) {
      let errorObject = errors[0];

      if (errorObject.status == 500) //laravel error
        {
          return 'Sorry! Unexpected server error occured';
        }

      return validErrorMessage(errors[0].detail);
    }

    return 'Sorry! somthing went wrong';
  }

  function validErrorMessage(errorString) {
    return errorString.length > 200 ? 'Sorry! somthing went wrong' : errorString;
  }

  var _default = Ember.Service.extend({
    errorMessage(response) {
      //Finding the error object
      if (typeof response === 'string') {
        return validErrorMessage(response);
      }

      if (response.body) {
        //ember data      
        return handleError(response.body.errors);
      }

      if (response.errors) {
        //ember data
        return handleError(response.errors);
      }

      if (response.responseJSON) {
        //ajaxRequest
        if (response.responseJSON.errors) {
          return handleError(response.responseJSON.errors);
        }
      }

      return 'Sorry! somthing went wrong';
    }

  });

  _exports.default = _default;
});
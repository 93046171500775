define("hmis/components/dashboard/main-butt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    mbStyle: Ember.computed(function () {
      return "color: ".concat(this.get('color'), "; ").htmlSafe();
    }),
    // ddStyle:function(){
    //     let left = this.get('left')
    //     let right = this.get('right')
    //     let width = this.get('width')
    //     return (`
    //         ${left?'left:'+left+'; ': 'right:'+right}
    //         ${width?'; width:'+width:''}
    //     `).htmlSafe()
    // }.property('left', 'right', 'width'),
    actions: {
      onClick() {
        if (this.get('onClick')) {
          this.get('onClick')(this.get('tag'));
        }
      }

    }
  });

  _exports.default = _default;
});
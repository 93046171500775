define("hmis/models/sharing-consent", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    patientId: _emberData.default.attr(),
    valueSet: _emberData.default.attr(),
    //json
    meta: _emberData.default.attr(),

    //json
    toAnswer() {
      return JSON.parse(this.get('valueSet'));
    },

    saveAnswer(valueSet, successCallback = null, failedCallback) {
      this.set('valueSet', JSON.stringify(valueSet));
      this.save().then(rec => {
        if (successCallback) successCallback(rec);
      }, err => {
        if (failedCallback) failedCallback(err);
      });
    }

  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/dashboard-count", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0dynK026",
    "block": "{\"symbols\":[\"sub\"],\"statements\":[[4,\"if\",[[24,[\"skeleton\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\" dashboard-count count-item-\",[22,\"index\"],\" \",[24,[\"count\",\"colorCss\"]]]]],[8],[0,\"\\n\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"dashboard-count count-item-\",[22,\"index\"],\" \",[24,[\"count\",\"colorCss\"]]]]],[8],[0,\"\\n\"],[0,\"        \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 count-center\"],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"count-wrapper\"],[8],[0,\"\\n            \"],[7,\"p\",true],[10,\"class\",\"count\"],[8],[1,[24,[\"count\",\"count\"]],false],[9],[0,\"\\n            \"],[9],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"infos\"],[8],[0,\"\\n                \"],[7,\"span\",true],[10,\"class\",\"text title\"],[8],[0,\" \"],[1,[24,[\"count\",\"title\"]],false],[0,\" \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"count\",\"subs\"]]],null,{\"statements\":[[0,\"                    \"],[7,\"div\",true],[11,\"class\",[29,[\"sub-wrapper \",[28,\"if\",[[28,\"lt\",[[23,1,[\"count\"]],0],null],\"negative\",\"\"],null]]]],[8],[0,\"\\n                    \"],[7,\"span\",true],[10,\"class\",\"sublabel\"],[8],[1,[23,1,[\"label\"]],false],[9],[7,\"span\",true],[10,\"class\",\"subvalue\"],[8],[1,[23,1,[\"count\"]],false],[9],[0,\"\\n                    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"            \"],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"col-sm-12 discription\"],[8],[1,[24,[\"count\",\"description\"]],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/dashboard-count.hbs"
    }
  });

  _exports.default = _default;
});
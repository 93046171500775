define("hmis/services/reports", ["exports", "hmis/mixins/high-chart-manager"], function (_exports, _highChartManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend(_highChartManager.default, {
    getChartTypesMap() {
      return this.chartTypesToMap();
    },

    //rows: an array of rows with boolean callback to validate dataItem
    //columns: an array of columns with boolean callback to validate dataItem
    serverReportToChatCountData(serverReport, columnReferenceName, rowReferenceName, otherFilters = [], selectedRowsArray = null, selectedColumnsArray = null, countType = 'count', showNoDataRowsColumns) {
      let processedReport = this.serverReportToProcessedData(serverReport, columnReferenceName, rowReferenceName, otherFilters, processedRow => {
        return {
          name: processedRow.row.label,
          data: processedRow.dataArray,
          rowTotal: processedRow.rowTotal,
          rowPercentage: processedRow.rowPercentage,
          rowsTotal: processedRow.rowsTotal
        };
      }, processedColumn => {
        return processedColumn.column.label;
      }, selectedRowsArray, selectedColumnsArray, countType, showNoDataRowsColumns); // console.log(processedReport);

      return {
        categories: processedReport.columns,
        series: processedReport.rows
      };
    },

    serverReportToChatPercentageData(serverReport, columnReferenceName, rowReferenceName, otherFilters = [], selectedRowsArray = null, selectedColumnsArray = null, countType = 'count', showNoDataRowsColumns) {
      let processedReport = this.serverReportToProcessedData(serverReport, columnReferenceName, rowReferenceName, otherFilters, processedRow => {
        return {
          name: processedRow.row.label,
          y: Number(processedRow.rowPercentage)
        };
      }, processedColumn => {
        return {
          name: processedColumn.column.label,
          y: Number(processedColumn.columnPercentage)
        };
      }, selectedRowsArray, selectedColumnsArray, countType, showNoDataRowsColumns);
      let colFilterProp = serverReport.filterProps[columnReferenceName];
      let rowFilterProp = serverReport.filterProps[rowReferenceName];
      return {
        columnSeries: [{
          name: colFilterProp.label,
          colorByPoint: true,
          data: processedReport.columns
        }],
        rowSeries: [{
          name: rowFilterProp.label,
          colorByPoint: true,
          data: processedReport.rows
        }]
      };
    },

    //rows: an array of rows with boolean callback to validate dataItem
    //columns: an array of columns with boolean callback to validate dataItem
    serverReportToTableData(serverReport, columnReferenceName, rowReferenceName, otherFilters = [], selectedRowsArray = null, selectedColumnsArray = null, countType = 'amount', showNoDataRowsColumns) {
      let processedReport = this.serverReportToProcessedData(serverReport, columnReferenceName, rowReferenceName, otherFilters, processedRow => {
        console.log('processedRow.drillDown', processedRow.drillDown);
        return {
          label: processedRow.row.label,
          data: processedRow.dataArray,
          referenceName: processedRow.row.referenceName,
          drillDownIndex: processedRow.row.drillDownIndex,
          isDrillDownParent: processedRow.isDrillDownParent,
          drillDown: processedRow.drillDown,
          rowTotal: processedRow.rowTotal,
          rowPercentage: processedRow.rowPercentage,
          rowsTotal: processedRow.rowsTotal
        };
      }, processedColumn => {
        return processedColumn;
      }, selectedRowsArray, selectedColumnsArray, countType, showNoDataRowsColumns); //process display columns

      let displayColumns = this.getRowDrillDownColumns(serverReport, rowReferenceName);
      let dataColumns = [];
      processedReport.columns.forEach(item => {
        displayColumns.push({
          label: item.column.label,
          isDrillDown: item.isDrillDown,
          columnTotal: item.columnTotal,
          columnPercentage: item.columnPercentage,
          columnsTotal: item.columnsTotal,
          column: item.column,
          isCountColumn: true
        }), dataColumns.push({
          label: item.column.label,
          isDrillDown: item.isDrillDown,
          columnTotal: item.columnTotal,
          columnPercentage: item.columnPercentage,
          columnsTotal: item.columnsTotal,
          column: item.column,
          isCountColumn: true
        });
      });
      console.log(displayColumns);
      processedReport['displayColumns'] = displayColumns;
      processedReport['dataColumns'] = dataColumns; // console.log('processedReport',processedReport);

      return processedReport;
    },

    reportReferenceToFilterArray(serverReport, referenceName, drillDownIndex = 0, drillDownParent = null, referenceItemsFilter = null, selectedReferenceArray) {
      // console.log('selectedReferenceArray', selectedReferenceArray);
      let filters = [];

      if (selectedReferenceArray && selectedReferenceArray.length) {
        selectedReferenceArray.forEach((refItem, index) => {
          let data = {
            name: 'ref' + index,
            label: refItem.label,
            referenceItem: refItem,
            referenceName: referenceName,
            drillDownIndex: drillDownIndex,
            filter: dataItem => {
              let filterProp = serverReport.filterProps[referenceName];
              return this.validateDataItem(dataItem, filterProp, refItem);
            }
          }; //check for drillDown //drillDown status is can be know in the reference's corresponding filterProps

          let drillDownName = serverReport.filterProps[referenceName].drillDown;
          if (drillDownName) data['isDrillDownParent'] = true;
          data['drillDown'] = this.reportReferenceToFilterArray(serverReport, drillDownName, drillDownIndex + 1, data, (refItem, drillDownParent) => {
            return refItem[drillDownParent.referenceName] == drillDownParent.referenceItem.id;
          });
          filters.push(data);
        });
      } else {
        let reference = serverReport.references[referenceName];
        if (reference) Object.keys(reference).forEach((key, index) => {
          let refItem = reference[key];
          let validRefItem = referenceItemsFilter ? referenceItemsFilter(refItem, drillDownParent) : true;

          if (validRefItem) {
            let data = {
              name: 'ref' + index,
              label: refItem.label,
              referenceItem: refItem,
              referenceName: referenceName,
              sort: refItem.sort ? refItem.sort : refItem.id,
              drillDownIndex: drillDownIndex,
              filter: dataItem => {
                let filterProp = serverReport.filterProps[referenceName];
                return this.validateDataItem(dataItem, filterProp, refItem);
              }
            }; //check for drillDown //drillDown status is can be know in the reference's corresponding filterProps

            let drillDownName = serverReport.filterProps[referenceName].drillDown;
            if (drillDownName) data['isDrillDownParent'] = true;
            data['drillDown'] = this.reportReferenceToFilterArray(serverReport, drillDownName, drillDownIndex + 1, data, (refItem, drillDownParent) => {
              return refItem[drillDownParent.referenceName] == drillDownParent.referenceItem.id;
            });
            filters.push(data);
          }
        });
      }

      return filters;
    },

    validateDataItem(dataItem, filterProp, referenceItem) {
      return dataItem[filterProp.dataProp] == (filterProp.dataValueType == 'refProp' ? referenceItem[filterProp.dataValue] : filterProp.dataValue);
    },

    getRowDrillDownColumns(serverReport, filterPropName, tableColumns = [], isDrillDown = false) {
      if (!serverReport) return tableColumns;
      let filterProp = serverReport.filterProps[filterPropName];

      if (!filterProp) {
        console.error('no filter prop found for: ', filterPropName);
      } // console.log('filterProp', filterProp);


      tableColumns.push({
        label: filterProp.label,
        isDrillDown: isDrillDown
      });

      if (filterProp.drillDown) {
        tableColumns = this.getRowDrillDownColumns(serverReport, filterProp.drillDown, tableColumns, true);
      }

      return tableColumns;
    },

    serverReportToProcessedData(serverReport, columnReferenceName, rowReferenceName, otherFilters = [], rowDataCallback, columnCallback, selectedRowsArray = null, selectedColumnsArray = null, countType = 'count', showNoDataRowsColumns) {
      // console.log('before columns', this.reportReferenceToFilterArray(serverReport, columnReferenceName, 0, null, null,  selectedColumnsArray));
      let columns = this.reportReferenceToFilterArray(serverReport, columnReferenceName, 0, null, null, selectedColumnsArray).sort((a, b) => {
        return Number(a.sort) - Number(b.sort);
      });
      let rows = this.reportReferenceToFilterArray(serverReport, rowReferenceName, 0, null, null, selectedRowsArray).sort((a, b) => {
        return Number(a.sort) - Number(b.sort);
      }); // console.log('columns', columns);

      let columnDataMap = [];
      let validColumns = [];
      let columnData = [];
      let columnsTotal = 0;
      serverReport.data.forEach(dataItem => {
        //looping through the whole data once
        let filters = [];
        otherFilters.forEach(function (oFilter) {
          filters.push(oFilter.filter);
        });

        if (this.validDataItem(dataItem, filters)) //validate by other filters
          {
            columns.forEach(column => {
              if (column.filter(dataItem)) {
                //validate by column
                if (!columnDataMap[column.name]) {
                  columnDataMap[column.name] = [];
                }

                columnsTotal += Number(this.getColumnCount(dataItem, countType));
                columnDataMap[column.name].push(dataItem);
              }
            });
          }
      });
      columns.forEach(column => {
        let colDataItems = columnDataMap[column.name];
        let colTotal = colDataItems ? this.getCount(colDataItems, countType) : 0;
        let tempColPencentate = colTotal / columnsTotal * 100;
        let colPercentage = tempColPencentate ? tempColPencentate.toFixed(2) : 0;
        let col = columnCallback({
          column: column,
          columnTotal: colTotal,
          columnPercentage: colPercentage,
          columnsTotal: columnsTotal
        });

        if (showNoDataRowsColumns || colTotal) {
          columnData.push(col);
          validColumns.push(column);
        }
      });
      return {
        reportFilterColumns: columns,
        reportFilterRows: rows,
        columns: columnData,
        totalCount: columnsTotal,
        rows: this.getRowsData(rows, validColumns, columnDataMap, rowDataCallback, null, countType, showNoDataRowsColumns)
      };
    },

    validDataItem(dataItem, filters) {
      for (var i = 0; i < filters.length; i++) {
        if (!filters[i](dataItem)) return false;
      }

      return true;
    },

    getColumnCount(dataItem, countType) {
      if (countType == 'count') {
        return 1;
      } else if (countType == 'amount') {
        if (!dataItem.amount) {
          console.error('Data item has no amount property ');
        }

        let amounts = Number(dataItem.amount);
        return amounts ? amounts.toFixed(2) : amounts;
      } else if (countType == 'quantity') {
        if (!dataItem.quantity) {
          console.error('Data item has no quantity property ');
        }

        let quantity = Number(dataItem.quantity);
        return quantity;
      } else {
        if (!dataItem[countType]) {
          console.warn('Data item has no ' + countType + ' property ');
        }

        let numb = Number(dataItem[countType]); // console.log('numb',numb);

        return numb || 0; // console.error('Unkown countType: '+countType)
      }
    },

    getCount(filteredDataList, countType) {
      if (countType == 'count') {
        return filteredDataList.length;
      } else if (countType == 'amount') {
        let amounts = 0;
        filteredDataList.forEach(fDataItem => {
          if (!fDataItem.amount) console.error('Data item has no amount property ');
          amounts += Number(fDataItem.amount);
        });
        return amounts ? amounts.toFixed(2) : amounts;
      } else if (countType == 'quantity') {
        let quantity = 0;
        filteredDataList.forEach(fDataItem => {
          if (!fDataItem.quantity) console.error('Data item has no quantity property ');
          quantity += Number(fDataItem.quantity);
        });
        return quantity;
      } else {
        let numb = 0;
        filteredDataList.forEach(fDataItem => {
          if (!fDataItem[countType]) console.warn('Data item has no ' + countType + ' property ');
          numb += Number(fDataItem[countType]);
        });
        return numb; // console.error('Unkown countType: '+countType)
      }
    },

    getRowsData(rows, columns, columnDataMap, rowDataCallback, parentFilter = null, countType = 'amount', showNoDataRowsColumns) {
      let rowsData = [];
      let tempRowsData = [];
      let rowsTotal = 0;

      for (let i = 0; i < rows.length; i++) {
        //looping through each rows to get its data in each row
        // console.log('rowFilters count', i);
        let dataArrTotal = 0;
        let row = rows[i];
        let rowFilter = row.filter;
        let dataArr = [];

        for (var j = 0; j < columns.length; j++) {
          //looping throu columns for thier names to fetch the columnDataMap
          let column = columns[j];
          let columnDataItems = columnDataMap[column.name];

          if (columnDataItems) {
            let fList = columnDataItems.filter(function (item) {
              //filter columnDataMap by row's filter
              let verifiedParent = !parentFilter ? true : parentFilter(item); //check if a drilldown passes parent filter

              return rowFilter(item) && verifiedParent;
            });
            let count = Number(this.getCount(fList, countType));
            dataArrTotal += count;
            dataArr.push(count);
          } else {
            dataArr.push(0);
          }
        }

        if (showNoDataRowsColumns || dataArrTotal) {
          rowsTotal += Number(dataArrTotal);
          let data = {
            rowTotal: Number(dataArrTotal),
            row: row,
            dataArray: dataArr
          };
          if (row.isDrillDownParent) data['isDrillDownParent'] = true;

          if (row.drillDown) {
            data['drillDown'] = this.getRowsData(row.drillDown, columns, columnDataMap, rowDataCallback, row.filter, countType, showNoDataRowsColumns);
          }

          tempRowsData.push(data);
        }
      } //formatData


      for (let i = 0; i < tempRowsData.length; i++) {
        //looping through each rows to get its data in each row
        let processedRow = tempRowsData[i];
        let tempPercent = processedRow.rowTotal / rowsTotal * 100;
        let rowPercentage = tempPercent ? tempPercent.toFixed(2) : 0; // console.log('processedRow.rowTotal/rowsTotal', processedRow.rowTotal, rowsTotal);

        processedRow['rowPercentage'] = rowPercentage;
        processedRow['rowsTotal'] = rowsTotal;
        let data = rowDataCallback(processedRow);
        rowsData.push(data);
      }

      return rowsData;
    }

  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/input-icon-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NfyxJo1y",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-icon-container\"],[8],[0,\"\\n\"],[7,\"i\",true],[11,\"class\",[29,[\"fa \",[22,\"icon\"],\" input-icon\"]]],[8],[9],[0,\" \\n\"],[7,\"div\",true],[10,\"class\",\"input-icon-content\"],[8],[0,\"\\n\"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/input-icon-container.hbs"
    }
  });

  _exports.default = _default;
});
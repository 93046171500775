define("hmis/routes/hmis-app/claims/claim", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { visitOpdIpdModel } from '../../../../../utils/visit-service-util';
  //import { visitServiceIncludes } from '../../../../utils/visit-service-util';
  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        // visit: this.get('store').findRecord('visit', {
        //   filter: { id: params.record_id },
        //   include: ['statusName'],
        // }),
        visit: this.get('store').query('visit', {
          filter: {
            id: params.record_id
          },
          include: ['purposeName', 'patientName', 'patientDateOfBirth', 'patientGender', 'patientPhysicalAddress', 'outstandingBills']
        }).then(res => res.get('firstObject')),
        transactions: this.get('store').query('transaction', {
          filter: {
            visitId: params.record_id,
            isInsurance: 1
          }
        })
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/components/forms/end-visit-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    modelName: 'emergency-patient',
    actions: {
      onRecordSubmit() {// let record = this.get('record')
        // this.set('waiting', true)
        // if(!this.get('record.id')){
        //   record = this.get('store').createRecord('department-specialist', record)
        // }
        // record.save().then((rec)=>{
        //     this.set('record', {})
        //     this.set('show', false)
        //     if(this.get('onSaved')){
        //         this.get('onSaved')(rec)
        //     }
        //     this.set('waiting', false)
        // }, (err)=> {
        //   this.set('waiting', false)
        //   console.log('error', err);
        // })
      }

    },
    endVisitAlertProps: [{
      title: 'Patient',
      name: 'patientName'
    }, {
      title: 'Visit Purpose',
      name: 'visit.purpose.name'
    }, {
      title: 'Visit Date',
      name: 'visitDate'
    } //{title:'Visit', name: 'visit'},
    // {title: 'Amount', name: 'amountF'},
    ]
  });

  _exports.default = _default;
});
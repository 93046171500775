define("hmis/routes/hmis-app/admin-settings/insurance-management/insurance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return this.get('store').query('insurance-product', {
        filter: {
          insuranceCompanyId: params.id
        },
        include: ['insuranceCompanyName'],
        pageSize: 25
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/stats-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AGiJpKoN",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"pageError\"]]],null,{\"statements\":[[0,\"        \"],[5,\"error-viewer\",[],[[\"@errorResult\"],[[22,\"pageError\"]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"controls/overlay-wait\",null,[[\"waiting\",\"progress\"],[[24,[\"waiting\"]],[24,[\"waitProgress\"]]]],{\"statements\":[[0,\"\\n\"],[4,\"controls/overlay-wait\",null,[[\"waiting\"],[[24,[\"waiting\"]]]],{\"statements\":[[4,\"if\",[[24,[\"multiPages\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"component\",[[28,\"get\",[[28,\"get\",[[24,[\"pages\"]],[24,[\"pageIndex\"]]],null],\"component\"],null]],[[\"props\",\"statsContainer\",\"tagName\"],[[28,\"get\",[[28,\"get\",[[24,[\"pages\"]],[24,[\"pageIndex\"]]],null],\"props\"],null],[23,0,[]],\"\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[14,1,[[23,0,[]]]],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n      \\n    \"],[7,\"div\",true],[11,\"style\",[28,\"html-safe\",[\"height:14px; \"],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/stats-container.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/access-manage-table-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DJxof/jU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-switch\",null,[[\"value\",\"onChange\"],[[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/access-manage-table-switch.hbs"
    }
  });

  _exports.default = _default;
});
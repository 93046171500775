define("hmis/routes/hmis-app/users/index", ["exports", "hmis/utils/general-util"], function (_exports, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model() {
      return (0, _generalUtil.modelHash)({
        records: this.get('store').query('user', {
          include: ['roleName'],
          pageSize: 25
        }),
        archiveRecords: this.get('store').query('user', {
          filter: {
            archived: 1
          },
          include: ['roleName'],
          pageSize: 25
        })
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      } //

    }
  });

  _exports.default = _default;
});
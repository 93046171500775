define("hmis/routes/hmis-app/admin-settings/facility-examination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        facilityVitals: this.get('store').query('vitals-facility-default', {
          include: ['vitalTypeName', 'unitOfMeasureName'],
          pageSize: 25
        }),
        vitalsTypes: this.get('store').query('vital-type', {}),
        unitOfMeasure: this.get('store').query('vitals-unit-of-measure', {})
      });
    },

    actions: {
      reloadModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/models/diagnosis", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/visit-service-model", "hmis/mixins/billable-model"], function (_exports, _emberData, _relationships, _dateUtil, _visitServiceModel, _billableModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, _billableModel.default, {
    diagnosisTypeId: _emberData.default.attr(),
    note: _emberData.default.attr(),
    source: _emberData.default.attr(),
    visitId: _emberData.default.attr(),
    icdCode: _emberData.default.attr(),
    icdName: _emberData.default.attr(),
    mdgCode: _emberData.default.attr(),
    diagnosisTypeName: _emberData.default.attr(),
    diagnosisTypeGdrgCode: _emberData.default.attr()
  });

  _exports.default = _default;
});
define("hmis/components/viewers/threshold-summary-alert", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    appAjax: Ember.inject.service(),

    // rec: [{
    //     thType: 'medi threshold',
    //     thValue: '5000',
    //     thList: [
    //         {itmName: 'MD88', itmVal: '300', itmBal: '100'},
    //         {itmName: 'MD98', itmVal: '200', itmBal: '200'},
    //         {itmName: 'MD58', itmVal: '500', itmBal: '100'},
    //     ]
    // },{
    //     thType: 'procedure threshold',
    //     thValue: '5000',
    //     thList: [
    //         {itmName: 'MD88', itmVal: '300', itmBal: '100'},
    //         {itmName: 'MD98', itmVal: '200', itmBal: '200'},
    //         {itmName: 'MD58', itmVal: '500', itmBal: '100'},
    //     ]
    // },{
    //     thType: 'medi 2',
    //     thValue: '5000',
    //     thList: [
    //         {itmName: 'MD88', itmVal: '300', itmBal: '100'},
    //         {itmName: 'MD98', itmVal: '200', itmBal: '200'},
    //         {itmName: 'MD58', itmVal: '500', itmBal: '100'},
    //     ]
    // }],
    // rec: computed('model', function(){
    //     let mod = this.get('model')
    //     this.appAjax.getObject("/patientThresholds/"+mod.get('patient.id')).then(res=>{
    //         return res
    //       }).catch(err=>{
    //         this.notify.error(err)
    //     })
    // }),
    //rec:[],
    init() {
      this._super(...arguments);

      let mod = this.get('model');
      this.appAjax.getObject("/patientThresholds/" + mod.get('patient.id')).then(res => {
        let mrec = [];
        res.forEach(th => {
          let gpth = th.thresholds;
          let structTh = [];

          for (var property in gpth) {
            structTh.push({
              'name': property.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1") + '(s) threshold',
              'thData': gpth[property]
            });
          }

          mrec.push({
            'insuranceName': th.insuranceName,
            'generalBalance': th.generalBalance,
            'prodOPDThreshold': th.prodOPDThreshold,
            'prodIPDThreshold': th.prodIPDThreshold,
            'thresholds': structTh
          });
        });
        console.log('mrec', mrec);
        this.set('rec', mrec);
      }).catch(err => {
        console.log('patientThresholds err', err);
      });
    },

    // {
    //     "insuranceProductId":"1",
    //     "insuranceName":null,
    //     "generalBalance":null,
    //     "thresholds":{
    //         "type":"medicine",
    //         "thresholdId":"a1744968-9bd7-457c-9728-3e04a791bfbc",
    //         "itemId":"4588",
    //         "opdThreshold":"444",
    //         "ipdThreshold":"444",
    //         "opdBalance":"TBC",
    //         "ipdBalance":"TBC"
    //     }
    // }
    isToday: true,
    fromDate: new Date(),
    toDate: new Date(),
    actions: {
      back() {
        history.back();
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    vitalsColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Vitals',
      propertyName: 'vitalsString',
      className: "vsv-summary-string-col"
    }],
    columns: [{
      title: 'Name',
      propertyName: 'itemName',
      className: "vsv-summary-string-col"
    }, {
      propertyName: 'opdThreshold'
    }, {
      propertyName: 'ipdThreshold'
    }, {
      propertyName: 'opdBalance'
    }, {
      propertyName: 'ipdBalance'
    }],
    propsToDisplay: [{
      name: 'picture',
      type: 'image'
    }, {
      name: 'firstName'
    }, {
      name: 'middleName'
    }, {
      name: 'lastName'
    }, {
      name: 'gender.name',
      title: 'Gender'
    }]
  });

  _exports.default = _default;
});
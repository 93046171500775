define("hmis/routes/hmis-app/pharmacy/dispense", ["exports", "hmis/utils/general-util"], function (_exports, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    appAjax: Ember.inject.service(),

    setupController(controller, model) {
      this._super(...arguments);

      controller.set('selectedItems', []);
    },

    model(params) {
      return (0, _generalUtil.modelHash)({
        visit: (0, _generalUtil.innerModelPromise)(this.appAjax.ajaxGet('visits/' + params.record_id, {
          include: ['outstandingBills', 'patientDateOfBirth', 'patientGender', 'patientName', 'purposeName', 'statusName']
        })),
        prescriptions: this.get('store').query('prescription', {
          filter: {
            visitId: params.record_id
          },
          include: ['medicineName', 'medicineGdrgCode', 'medicineUnitOfPricing', 'frequencyTypeName', 'durationTypeName']
        })
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
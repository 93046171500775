define("hmis/components/controls/progress-list-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showSearch: true,
    actions: {
      // itemEvent(record){
      // }
      removeRecord(record) {
        let action = this.get('onRemove');
        if (action) action(record);else {
          let list = this.get('list');
          if (list) list.removeObject(record);
        }
      },

      clickRecord(record) {
        let action = this.get('onClick');
        if (action) action(record);
      },

      doubleClickRecord(record) {
        let action = this.get('onDoubleClick');
        if (action) action(record);
      }

    }
  });

  _exports.default = _default;
});
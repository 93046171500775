define("hmis/controllers/hmis-app/pharmacy/index", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-component", "hmis/mixins/app-event-mixin", "hmis/utils/general-util"], function (_exports, _modelTableTheme, _formComponent, _appEventMixin, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_appEventMixin.default, {
    store: Ember.inject.service('store'),
    routing: Ember.inject.service('router'),
    ajaxRequest: Ember.inject.service(),
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    input_width2: "flex-10 flex-xs-100 flex-sm-100",
    app: Ember.inject.service(),
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    tabStatus: 0,
    dispenceDisabled: false,
    selectedItems: [],
    batch: [],
    counter: 1,
    batchItems: {},
    fromBVDate: Ember.computed(function () {
      return new Date();
    }),
    toBVDate: Ember.computed(function () {
      return new Date();
    }),

    init() {
      this._super(...arguments);
    },

    onPermissionNotification(data) {
      if (data.permission == 'op30') //dispense permission
        {
          this.send('filterVisits', this.visitsSelectedDates, true);
          this.send('filterDispensed', this.dispensedSelectedDates, true);
        }
    },

    actions: {
      back() {
        history.back();
      },

      filterVisits(selectedDates, force = false) {
        if (this.visitsSelectedDates === selectedDates && !force) return;
        this.set('visitsSelectedDates', selectedDates);
        let dateRange = (0, _generalUtil.selectedDatesToDaterange)(selectedDates);
        let filter = (0, _generalUtil.trimNullProps)({
          dateRange
        });
        this.set('prescriptionVisits', this.app.query('visit', {
          filter,
          include: ['patientName', 'purposeName', 'statusName']
        }));
      },

      filterDispensed(selectedDates, force = false) {
        if (this.dispensedSelectedDates === selectedDates && !force) return;
        this.set('dispensedSelectedDates', selectedDates);
        let dateRange = (0, _generalUtil.selectedDatesToDaterange)(selectedDates);
        let filter = (0, _generalUtil.trimNullProps)({
          dateRange,
          dispensed: 1
        });
        this.set('dispensedPrescriptions', this.app.query('prescription', {
          filter,
          include: ['medicineName', 'patientName', 'visitPurpose', 'requesterName', 'granterName']
        }));
      },

      onNewTabClick(route) {
        this.set('tabStatus', 0);
      },

      onHistoryTabClick(route) {
        this.set('tabStatus', 1);
      },

      deletePrescription(record) {
        let prescriptionsList = this.get("batch.prescriptions");
        prescriptionsList.removeObject(record);
        record.destroyRecord();

        if (prescriptionsList.get('length') == 0) {
          this.get('batch').destroyRecord();
          this.set('model.oldPrescriptionBatch', false);
        }
      },

      rowClick(index, record) {
        if (this.get('oPerms.op30.allow')) {
          console.log(record);
          this.set('batchItems', record);
          this.set('showPrescription', true);
          this.set('dispenceDisabled', false);
        } else {
          this.notify.error("You don't have permission to Serve a patient");
        }
      },

      rowClickVisits(index, visit) {
        let rid = visit.get('id');
        this.get("routing").transitionTo("hmis-app.pharmacy.dispense", rid);
      },

      rowClickDespensed(index, record) {
        this.prescriptionViewer.show(record);
      },

      onConfirm() {
        console.log(this.get('batchItems').serialize());
        let presBatch = this.get('batchItems');
        this.set('servingPrescription', true);
        this.get('ajaxRequest').dispensePrescriptionBatch(presBatch, {
          success: response => {
            this.set('servingPrescription', false);
            this.set('showPrescription', false);
            this.notify.success('Dispensed');
            this.send('refreshModel');
          },
          error: error => {
            this.set('servingPrescription', false);
            this.notify.error(error);
          }
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns1: [{
      'title': 'Date of visit',
      "propertyName": "dateF",
      'className': 'main-column'
    }, {
      'title': 'Patient',
      'propertyName': 'patientName'
    }, {
      'title': 'Purpose of visit',
      'propertyName': 'purposeName'
    }, {
      "title": "Visit status",
      "propertyName": "statusName"
    }],
    columns2: [{
      "title": 'Prescriptions',
      "propertyName": "medicineName"
    }, {
      "title": 'Patient Name',
      "propertyName": "patientName"
    }, {
      "title": 'Visit Purpose',
      "propertyName": "visitPurpose"
    }, {
      "title": 'Requested By',
      "propertyName": "requesterName"
    }, {
      "title": 'Request Date',
      "propertyName": "reqDateTimeF"
    }, {
      "title": 'Dispensed By',
      "propertyName": "granterName"
    }, {
      "title": 'Dispensed Date',
      "propertyName": "grantedDateTimeF"
    }]
  });

  _exports.default = _default;
});
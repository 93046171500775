define("hmis/templates/components/alerts/dialog-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Rd7IkAVD",
    "block": "{\"symbols\":[\"dropdown\",\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"alert-actions\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"basic-dropdown\",null,null,{\"statements\":[[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"trigger\"]],\"expected `dropdown.trigger` to be a contextual component but found a string. Did you mean `(component dropdown.trigger)`? ('hmis/templates/components/alerts/dialog-actions.hbs' @ L4:C15) \"],null]],[[\"class\"],[\"alert-actions-trigger\"]],{\"statements\":[[0,\"                \"],[4,\"if\",[[24,[\"tooltip\"]]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"tooltiptext\"],[8],[1,[22,\"tooltip\"],false],[9]],\"parameters\":[]},null],[0,\"\\n                \"],[1,[22,\"title\"],false],[0,\" \"],[4,\"if\",[[24,[\"icon\"]]],null,{\"statements\":[[7,\"i\",true],[11,\"class\",[29,[[22,\"icon\"],\" icon\"]]],[8],[9]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"content\"]],\"expected `dropdown.content` to be a contextual component but found a string. Did you mean `(component dropdown.content)`? ('hmis/templates/components/alerts/dialog-actions.hbs' @ L8:C15) \"],null]],[[\"class\",\"onClick\"],[\"dropdown-list-content\",[23,1,[\"actions\",\"close\"]]]],{\"statements\":[[0,\"                \"],[14,2,[[28,\"hash\",null,[[\"dropdown\"],[[23,1,[]]]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/alerts/dialog-actions.hbs"
    }
  });

  _exports.default = _default;
});
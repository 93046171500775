define("hmis/models/investigation-type", ["exports", "ember-data", "ember-data/relationships", "hmis/mixins/billable-item", "hmis/mixins/model-access-right"], function (_exports, _emberData, _relationships, _billableItem, _modelAccessRight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_billableItem.default, _modelAccessRight.default, {
    // investigationTestTypes: hasMany('investigation-test-type'),
    nhiaCode: _emberData.default.attr(),
    gdrgCode: _emberData.default.attr(),
    genericName: _emberData.default.attr(),
    upperLimit: _emberData.default.attr(),
    lowerLimit: _emberData.default.attr(),
    billableName: 'investigation'
  });

  _exports.default = _default;
});
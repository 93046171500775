define("hmis/models/room", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    ajaxRequest: Ember.inject.service(),
    name: _emberData.default.attr(),
    ward: (0, _relationships.belongsTo)('ward'),
    //wardId : DS.attr(),
    price: _emberData.default.attr(),
    wardName: _emberData.default.attr(),
    beds: Ember.computed(function () {
      return _emberData.default.PromiseArray.create({
        promise: new Ember.RSVP.Promise((resolve, reject) => {
          this.get('ajaxRequest').makeRequest('GET', "/bedsByRoom/" + this.get('id'), {}, {
            success: response => {
              let beds = response;
              let store = this.get('store');
              let mBeds = [];
              beds.forEach(bed => {
                let nPayload = store.normalize('bed', bed);
                let b_model = store.push(nPayload);
                mBeds.push(b_model);
              });
              this.set('beds', mBeds);
              resolve(mBeds);
            },
            error: err => {
              console.log('error:', err);
              reject(err);
            }
          });
        })
      });
    })
  });

  _exports.default = _default;
});
define("hmis/components/controls/paged-report-chart", ["exports", "ember-data", "hmis/mixins/paged-high-chart-manager"], function (_exports, _emberData, _pagedHighChartManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const dataKnownKeys = ['name', 'total', 'id'];

  var _default = Ember.Component.extend(_pagedHighChartManager.default, {
    chatData: Ember.computed('dataReport', function () {
      let cData = [];

      if (this.dataReport.tableData) {
        this.dataReport.tableData.forEach(element => {
          const item = {
            name: element.name,
            data: []
          };
          Object.keys(element).forEach(dKey => {
            if (!dataKnownKeys.includes(dKey)) {
              item.data.push(element[dKey] ? Number(element[dKey]) : 0);
            }
          });
          cData.push(item);
        });
      }

      console.log('cdata', cData);
      return cData;
    }),
    categories: Ember.computed('dataReport', 'dataReport.columnReferences', function () {
      let cData = [];

      if (this.dataReport.columnReferences) {
        this.dataReport.columnReferences.forEach(element => {
          cData.push(element.label);
        });
      }

      console.log('columnReferences', cData);
      return cData;
    }),
    reportDataType: 'count',
    chartOptions: Ember.computed('selectedChartType', function () {
      return this.get(this.get('selectedChartType.options'));
    }),
    chartOptions2: Ember.computed('selectedChartType', function () {
      return this.get(this.get('selectedChartType.options2'));
    })
  });

  _exports.default = _default;
});
define("hmis/templates/components/file-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OR7r3H27",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"fileInfo\",\"type\"]],\"image\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"wrapperClass\"]]]],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"class\",[29,[\"concatinatedClass \",[28,\"if\",[[28,\"not-eq\",[[24,[\"fileInfo\",\"type\"]],\"image\"],null],\"not-image\",\"\"],null]]]],[11,\"src\",[22,\"linkLocation\"]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"eq\",[[24,[\"fileInfo\",\"type\"]],\"pdf\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"controls/pdf-preview\",null,[[\"class\",\"link\"],[\"flex-100\",[28,\"concat\",[[24,[\"linkLocation\"]],\"#zoom=150\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"fileInfo\",\"type\"]],\"unknown\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[22,\"wrapperClass\"]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"ext-preview-wrapper\"],[8],[0,\"\\n        \"],[7,\"div\",true],[11,\"class\",[29,[\"ext-preview \",[28,\"if\",[[28,\"not-eq\",[[24,[\"fileInfo\",\"type\"]],\"unknown\"],null],\"not-ext\",\"\"],null]]]],[8],[0,\"\\n            \"],[7,\"p\",true],[10,\"class\",\"ext-text\"],[8],[0,\" \"],[1,[24,[\"fileInfo\",\"extension\"]],false],[0,\" \"],[9],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/file-preview.hbs"
    }
  });

  _exports.default = _default;
});
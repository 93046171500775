define("hmis/mixins/visits-parent-model", ["exports", "ember-data", "hmis/utils/date-util"], function (_exports, _emberData, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    visitsCount: _emberData.default.attr()
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/period-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iKH/iGGY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"strong\",true],[8],[0,\" \"],[1,[24,[\"record\",\"name\"]],false],[0,\" \"],[9],[0,\" \"],[7,\"br\",true],[8],[9],[0,\"\\n\"],[1,[24,[\"record\",\"periodF\"]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/period-view.hbs"
    }
  });

  _exports.default = _default;
});
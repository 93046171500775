define("hmis/templates/components/managers/department-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QALey9x4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"form-container\",null,[[\"class\"],[\"full-width\"]],{\"statements\":[[0,\"\\n\\n\"],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"style\",\"margin-top: 20px\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-50\"],[10,\"style\",\"margin: 0 20px\"],[8],[0,\"\\n\"],[0,\"        \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"name\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"name\"]]],null]],null],\"Name\",\"person-outline\"]]],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"style\",\"margin: 0 20px\"],[8],[0,\"\\n        \"],[7,\"button\",true],[10,\"class\",\"submit-button float-right\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"save\"],null]],[8],[0,\"Add Department\"],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[0,\" \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[2,\"<div class=\\\"layout-row\\\" style=\\\"margin-top: 20px\\\">\"],[0,\"\\n      \"],[2,\"<div class=\\\"layout-column flex-40\\\" style=\\\"margin: 0 20px\\\">\"],[0,\"\\n\"],[0,\"      \"],[2,\"</div>\"],[0,\"\\n      \"],[2,\"<div style=\\\"margin: 0 20px\\\">\"],[0,\"\\n        \"],[2,\"<button class=\\\"submit-button float-right\\\" onclick={{!action \\\"save\\\"}}>Add Department<i class=\\\"fa fa-plus\\\"></i> </button>\"],[0,\"\\n      \"],[2,\"</div>\"],[0,\"\\n    \"],[2,\"</div>\"],[0,\"\\n\\n\\n\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/department-manager.hbs"
    }
  });

  _exports.default = _default;
});
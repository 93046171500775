define("hmis/helpers/make-false", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.makeFalse = makeFalse;
  _exports.default = void 0;

  function makeFalse(params) {
    // return params;
    params = false;
  }

  var _default = Ember.Helper.helper(makeFalse);

  _exports.default = _default;
});
define("hmis/controllers/configure/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      save() {
        this.get("model").save().then(record => {
          this.notify.success('Saved Successfully');
        }).catch(failure => {
          console.log(failure); // alert("Unable to saved:"+failure);

          this.notify.error('Unable to save');
        });
      } // save(){
      //   console.log(this.get('currentUser.user.facility'))
      //   //let that = this;
      //   //let model = this.get('currentUser.user.facility')
      //   this.set('model',this.get('currentUser.user.facility').data())
      //     this.get('model').save().then((record)=>{
      //
      //       // let length = that.get('addedInsurances.length')
      //       // if(length>0){
      //       //   that.get('addedInsurances').forEach(function(item, index, enumerable) {
      //       //
      //       //     // record.get('insurances').removeObject(item)
      //       //     item.save().then(function(){
      //       //       if(index+1==length){
      //       //         that.get('removedInsurances').invoke('destroyRecord')
      //       //         that.notify.success('Saved Successfully')
      //       //       }
      //       //     })
      //       //   });
      //       // }else{
      //       //   that.get('removedInsurances').invoke('destroyRecord')
      //       //   that.notify.success('Saved Successfully')
      //       // }
      //       that.notify.success('Saved Successfully')
      //     },(failure)=>{
      //       console.log(failure)
      //       that.notify.error('Unable to save')
      //     })
      // }


    }
  });

  _exports.default = _default;
});
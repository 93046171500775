define("hmis/components/report-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);

      var date = new Date();
      var firstDay = new Date(date.getFullYear(), 0, 1);
      var lastDay = new Date(date.getFullYear(), 11 + 1, 0);
      this.set('fmDate', moment(firstDay).format("YYYY-MM-DD"));
      this.set('toDate', moment(lastDay).format("YYYY-MM-DD"));
      this.set('otherFilters', []);
      this.loadReport(moment(firstDay).format("YYYY-MM-DD"), moment(lastDay).format("YYYY-MM-DD"));
    },

    tagName: '',
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    reports: Ember.inject.service(),
    tabIndex: 0,

    loadReport(fromDate, toDate) {
      let reportType = this.get('reportType');
      this.get('ajaxRequest').generateReport(reportType, fromDate, toDate, {
        success: serverReport => {
          this.set('rowReferenceName', 'facilityType');
          this.set('columnReferenceName', 'diagnosisType');
          this.set('serverReport', serverReport);
        },
        error: err => {}
      });
    },

    actions: {}
  });

  _exports.default = _default;
});
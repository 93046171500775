define("hmis/components/viewers/procedure-viewer", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/ref-component", "hmis/utils/general-util"], function (_exports, _modelTableTheme, _refComponent, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_refComponent.default, {
    input_width: 'flex-xs-100 flex-sm-50 flex-md-30  flex-lg-10',
    input_width_full: 'flex-100',
    appAjax: Ember.inject.service(),
    store: Ember.inject.service(),

    show(record) {
      if (this.record != record) {
        this.set('record', record);
        this.set('documents', null);
      }

      this.set('showModal', true);
    },

    actions: {
      onDetailsTab() {},

      onDocumentsTab() {
        if (!this.documents) (0, _generalUtil.promiseSet)(this, 'documents', this.store.query('procedure-document', {
          filter: {
            procedureId: this.record.id
          }
        }), 'docWaiting');
      },

      onRowClick(index, record) {
        this.set('previewFile', record.get('location'));
        this.set('previewTitle', record.get('name'));
        this.set('showPreviewFile', true);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    //date , patient, requestor
    columns: [{
      title: 'preview',
      component: 'filePreview'
    }, {
      'title': 'file image',
      "propertyName": "type" //   "component": "checkBox"

    }, {
      'title': 'Name',
      "propertyName": "name",
      className: 'main-column'
    }, {
      'title': 'file type',
      "propertyName": "type" //

    }, {
      'title': 'Date added',
      "propertyName": "dateTimeF"
    }]
  });

  _exports.default = _default;
});
define("hmis/templates/components/configure-side-menu1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qc4rRrrs",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"hmis-side-menu \"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"menus\"],[8],[0,\"\\n\\n        \"],[7,\"div\",true],[8],[0,\"\\n            \"],[7,\"div\",true],[10,\"style\",\"height:10px; width:3px;\"],[8],[9],[0,\"\\n            \"],[1,[28,\"hmis-side-menu-item\",null,[[\"text\",\"currentPathHook\",\"route\",\"onMenuClick\",\"icon\"],[\"General\",[24,[\"currentPathHook\"]],\"hmis-app.dashboard\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null],\"dashboard\"]]],false],[0,\"\\n            \"],[1,[28,\"hmis-side-menu-item\",null,[[\"text\",\"currentPathHook\",\"route\",\"onMenuClick\",\"icon\"],[\"Departments\",[24,[\"currentPathHook\"]],\"hmis-app.records\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null],\"patient\"]]],false],[0,\"\\n            \"],[1,[28,\"hmis-side-menu-item\",null,[[\"text\",\"currentPathHook\",\"route\",\"onMenuClick\",\"icon\"],[\"Vital Signs\",[24,[\"currentPathHook\"]],\"hmis-app.opd\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null],\"medical-result\"]]],false],[0,\"\\n            \"],[1,[28,\"hmis-side-menu-item\",null,[[\"text\",\"currentPathHook\",\"route\",\"onMenuClick\",\"icon\"],[\"Insurance\",[24,[\"currentPathHook\"]],\"hmis-app.consultation\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null],\"doctor\"]]],false],[0,\"\\n            \"],[1,[28,\"hmis-side-menu-item\",null,[[\"text\",\"currentPathHook\",\"route\",\"onMenuClick\",\"icon\"],[\"User\",[24,[\"currentPathHook\"]],\"hmis-app.ipd\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null],\"ipd\"]]],false],[0,\"\\n            \"],[1,[28,\"hmis-side-menu-item\",null,[[\"text\",\"currentPathHook\",\"route\",\"onMenuClick\",\"icon\"],[\"Role & Permission\",[24,[\"currentPathHook\"]],\"hmis-app.pharmacy\",[28,\"action\",[[23,0,[]],\"onMenuClick\"],null],\"medicine\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/configure-side-menu1.hbs"
    }
  });

  _exports.default = _default;
});
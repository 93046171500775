define("hmis/utils/general-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.promiseSet = promiseSet;
  _exports.safeSet = safeSet;
  _exports.isNumeric = isNumeric;
  _exports.hasPermission = hasPermission;
  _exports.modelTableFilterParams = modelTableFilterParams;
  _exports.selectedDatesToDaterange = selectedDatesToDaterange;
  _exports.trimNullProps = trimNullProps;
  _exports.modelHash = modelHash;
  _exports.innerModelPromise = innerModelPromise;
  _exports.visitServiceToVisitData = visitServiceToVisitData;
  _exports.isFunction = isFunction;
  _exports.dateToPaperDate = dateToPaperDate;

  function promiseSet(context, property, promise, waitingProp = null) {
    console.log('promise', promise);
    if (waitingProp) safeSet(context, waitingProp, true);
    return promise.then(res => {
      safeSet(context, property, promise.arrangedContent);
      safeSet(context, property + ".error", null);
    }).catch(err => {
      if (!Ember.get(context, property)) safeSet(context, property, {});
      safeSet(context, property + ".error", err);
      console.log('err model', Ember.get(context, property));
    }).finally(() => {
      if (waitingProp) safeSet(context, waitingProp, false);
    });
  }

  function safeSet(context, prop, value) {
    if (!Ember.get(context, 'isDestroyed')) {
      Ember.set(context, prop, value);
    }
  }

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  function hasPermission(permissions, operationalPermissions, modularPermissions) {
    if (permissions) {
      for (let index = 0; index < permissions.length; index++) {
        const perm = permissions[index];
        const permToSearch = perm.includes('op') ? operationalPermissions : modularPermissions;

        if (Ember.get(permToSearch, perm)) {
          return true;
        }
      }

      return false;
    }

    return true;
  }

  function modelTableFilterParams(params, search, selectedDates) {
    //for only server-paginated
    let dateRange = selectedDatesToDaterange(selectedDates);

    if (dateRange || search) {
      if (!params.filter) params.filter = {};
      if (search) params.search = search;
      if (dateRange) params.filter.dateRange = dateRange;
    }

    return params;
  }

  function selectedDatesToDaterange(selectedDates) {
    return selectedDates ? {
      fromDate: selectedDates.fromDate.toISOString().slice(0, 19).replace('T', ' '),
      toDate: selectedDates.toDate.toISOString().slice(0, 19).replace('T', ' ')
    } : null;
  }

  function trimNullProps(obj) {
    Object.keys(obj).forEach(function (key, index) {
      if (obj[key] == null || obj[key] == undefined) delete obj[key];
    });
    return obj;
  }

  function modelHash(model) {
    return Ember.RSVP.hash(model).then(res => res).catch(res => {
      console.error("model error", res);
      return res;
    });
  }

  function innerModelPromise(promise) {
    return promise.then(res => res).catch(res => {
      console.error("model error", res);
      return {
        error: res
      };
    });
  }

  function visitServiceToVisitData(serviceModel) {
    return {
      id: serviceModel.visitId,
      patientName: serviceModel.patientName,
      dateF: serviceModel.visitDate
    };
  }

  function isFunction(functionToCheck) {
    return typeof functionToCheck === "function";
  }

  function dateToPaperDate(date) {
    var dd = date.getDate();
    var mm = date.getMonth() + 1; //January is 0!

    var yyyy = date.getFullYear();

    if (dd < 10) {
      dd = '0' + dd;
    }

    if (mm < 10) {
      mm = '0' + mm;
    }

    return yyyy + '-' + mm + '-' + dd;
  }
});
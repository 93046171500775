define("hmis/templates/configure/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "erTDXtN4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n  \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Save\",[28,\"action\",[[23,0,[]],\"back\"],null],\"close-fill\"]]],false],[0,\"\\n  \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Save & Continue\",[28,\"action\",[[23,0,[]],\"save\"],null],\"save-fill\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height  has-main-table\",true,\"Facility Configuration\"]],{\"statements\":[[0,\"\\n    \"],[2,\"<div class=\\\"content\\\">looking</div>\"],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content display-bock\"],[8],[0,\"\\n      \"],[1,[28,\"facility-manager\",null,[[\"type\",\"record\",\"addedInsurances\",\"removedInsurances\"],[\"add\",[24,[\"model\"]],[24,[\"addedInsurances\"]],[24,[\"removedInsurances\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/configure/general.hbs"
    }
  });

  _exports.default = _default;
});
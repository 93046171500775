define("hmis/components/forms/allergy-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    modelName: 'allergy',
    // majorGroup:computed('model',function(){
    //   let patient = this.get('parent.patient')
    //   return this.get('store').findAll('diagnosis-major-group', {
    //   }).then(function(recs) {
    //     let mdg = []
    //     recs.forEach(mdiag => {
    //       let gender = patient.get('gender')?patient.get('gender'):'0'
    //       let patAge = patient.get('age')?patient.get('age').toString():'0'
    //       let age = Number(patAge.slice(0, 2))
    //       if(gender.get('id') == '1' && mdiag.id=='OBGY') {
    //         return
    //       }else if(age > 12 && mdiag.id=='PAED' || mdiag.id=='PSUR'){
    //         return
    //       }else{
    //         mdg.push(mdiag)
    //       }
    //     });
    //     return mdg.toArray();
    //     //return recs;
    //   })
    // }),
    // mediList:computed('model',function(){
    //   return this.get('store').findAll('medicine')
    // }),
    // optionTypes:computed('majorItem', function(){
    //     return this.get('store').query('diagnosis-type', {
    //       filter: {
    //         mdgCode: this.get('majorItem.id')?this.get('majorItem.id'):'MEDI'
    //       }
    //     }).then(function(recs) {
    //       var gDiag = []
    //       recs.forEach(diag => {
    //         var gname = diag.get('gdrgCode') +' - '+ diag.get('genericName')
    //         gDiag.push({ groupName: gname, options: diag.get('diagnosisSubTypes').toArray() })
    //         //console.log('diagnosisSubTypes',diagnosisSubTypes)
    //       });
    //       return gDiag.toArray();
    //     });
    // }),
    majorDiagnosisTypeType: Ember.computed(function () {
      return this.get('store').query('diagnosis-type', {
        filter: {},
        pageSize: 10
      });
    }),
    icdDiagnosisTypes: Ember.computed('majorDiagnosisType.id', function () {
      return this.get('store').query('diagnosis-sub-type', {
        filter: {
          diagnosisTypeId: this.majorDiagnosisType ? this.majorDiagnosisType.id : 'n/a'
        },
        pageSize: 10
      });
    }),
    actions: {
      myMatcher(subDiag, term) {
        let searchProp = "".concat(subDiag.get('icdName'), " ").concat(subDiag.get('icdCode'), " ").concat(subDiag.get('diagnosisTypeId.genericName'), " ").concat(subDiag.get('diagnosisTypeId.gdrgCode'));
        return searchProp.toLowerCase().indexOf(term.toLowerCase());
      },

      getMedName(item) {
        console.log('item.genericName', item.genericName);
        this.set('record.name', item.genericName);
        this.set('selMed', item);
      },

      majorTypeChange(record) {
        console.log(this.record);
        this.set('majorDiagnosisType', record);
        this.set('icdDiagnosis', null);
      } // getICDDiagnosis(item){
      //   //this.set('', item);
      // },
      // getMajorGroup(item){
      //   this.set('majorItem', item);
      // },


    },
    allergyColumns: [{
      propertyName: 'name'
    }]
  });

  _exports.default = _default;
});
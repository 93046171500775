define("hmis/controllers/hmis-app/reports/diagnosis", ["exports", "hmis/mixins/report-controller"], function (_exports, _reportController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_reportController.default, {
    reportType: 'diagnosis',
    actions: {}
  });

  _exports.default = _default;
});
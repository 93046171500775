define("hmis/components/controls/con-display-search", ["exports", "ember-data", "hmis/utils/events"], function (_exports, _emberData, _events) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onFocusIn(event) {
        this.set('isFocus', true);
        (0, _events.onClickOutside)('#con-display-search', () => {
          this.set('isFocus', false); // console.log('outside clicked')

          event.preventDefault();
          event.stopPropagation();
        });
      },

      onFocusOut() {
        console.log('focusOut');
      },

      setFocus() {
        this.set('stayFocus', true);
        console.log('am setting focus'); // var $ = Ember.$;
        // setTimeout(function() {$('#search-input').focus()}, 10);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/input-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7mzcVWts",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"halfFullWidth\"]],\"halfOfParentWidth\",\"\"],null]]]],[8],[0,\"\\n\"],[1,[28,\"paper-input\",null,[[\"class\",\"label\",\"value\",\"icon\",\"onChange\",\"margin\"],[\"form-input\",[24,[\"label\"]],[24,[\"value\"]],[24,[\"icon\"]],[28,\"action\",[[23,0,[]],\"onTextChanged\"],null],0]]],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/input-text.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/add-patient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    app: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    actions: {
      back() {
        history.back();
      },

      submitForm() {
        this.get('submitForm')();
      },

      save() {
        let that = this;
        let model = this.get('model');
        this.set('saving', true);
        this.set('model', this.get('store').createRecord('patient', model));
        this.get("model").save().then(record => {
          '';

          this.get('app').loadDashboardStats();
          this.set('saving', false);
          this.set('model', {});
          that.notify.success('Saved Successfully');
        }).catch(failure => {
          this.set('saving', false);
          that.notify.error('Unable to Save' + failure);
        });
      }

    }
  });

  _exports.default = _default;
});
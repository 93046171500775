define("hmis/templates/hmis-app/admin-settings/procedure-type/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aZ0OZ8Cu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n  \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Cancel\",[28,\"action\",[[23,0,[]],\"back\"],null],\"close\"]]],false],[0,\"\\n  \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"raised\",\"primary\",\"onClick\",\"icon\"],[\"Save\",true,true,[28,\"action\",[[23,0,[]],\"save\"],null],\"save\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height\",true,\"Add Procedure Type\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\n      \"],[1,[28,\"managers/procedure-type-manager\",null,[[\"form\",\"record\",\"class\",\"type\"],[[24,[\"form\"]],[24,[\"model\"]],\"full-width\",\"add\"]]],false],[0,\"\\n\"],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/admin-settings/procedure-type/add.hbs"
    }
  });

  _exports.default = _default;
});
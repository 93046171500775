define("hmis/templates/layout-test", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/zNNIOBM",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"app-layout\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"app-header\"],[8],[0,\"\\n        header\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-menu\"],[8],[0,\"\\n        menu\\n    \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"app-mini-menu\"],[8],[0,\"\\n        mini-menu\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-breadcrumbs\"],[8],[0,\"\\n        breadcrumbs\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n        toolbar\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n        workspace\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-quick-action\"],[8],[0,\"\\n    quick-action\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-footer\"],[8],[0,\"\\n    footer\\n    \"],[9],[0,\"\\n\\n\\n\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/layout-test.hbs"
    }
  });

  _exports.default = _default;
});
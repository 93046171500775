define("hmis/templates/components/mini-app/side-menu-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Y7sHFSZG",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"mini-side-menu-item \",[28,\"if\",[[24,[\"active\"]],\"active\"],null],\" \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null]]]],[3,\"action\",[[23,0,[]],\"click\"]],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"item-text\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n\"],[0,\"\\n    \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/mini-app/side-menu-item.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/overlay-wait", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ye2zRxNx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"waiting\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ow-overlay loading\"],[10,\"style\",\"position:absolute; left:0; top:0; height:100%; font-size:20px;\\n    width:100%; background:rgba(255, 255, 255, 0.45); display:flex; justify-content:center; align-items:center; z-index:1; \"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"loading-icon-wrapper\"],[8],[0,\"\\n            \"],[7,\"i\",true],[11,\"class\",[29,[[22,\"icon\"],\" loading icon primary-color\"]]],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"progress\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"ow-overlay\"],[10,\"style\",\"position:absolute; left:0; top:0; height:100%; \\n    width:100%; background:rgba(255, 255, 255, 0.45); display:flex; justify-content:center; align-items:center; z-index:1; \"],[8],[0,\" \\n        \"],[7,\"h3\",true],[8],[1,[22,\"progress\"],false],[0,\" %\"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[14,1]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/overlay-wait.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/opd/index", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    selectedItems: [],
    allowSelect: true,
    data: Ember.computed.alias('model'),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    selectedCount: Ember.computed("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    tabIndex: 0,
    notify: Ember.inject.service(),
    selectedPatient: '',
    vitalsSuccessAction: 'empty',
    actions: {
      empty() {},

      addVitalFromQueuedPatient(event) {
        let record = this.get('selectedItems').get('firstObject');
        this.set('selectedPatient', record);
        this.set('vitalsDataTag', record);
        this.set('visitForVitals', record.get('visit'));
        this.set('vitalsSuccessAction', 'qpVitalsSuccess');
        this.set('takeVitals', true);
      },

      addVitalFromRequest(index, record) {
        this.set('visitForVitals', record.get('visit'));
        this.set('vitalsDataTag', record);
        this.set('requestOnResult', record);
        this.set('vitalsSuccessAction', 'reqVitalsSuccess');
        this.set('takeVitals', true);
      },

      qpVitalsSuccess(vBatch, dataTag) {},

      reqVitalsSuccess(vBatch, dataTag) {
        // let req = this.get('requestOnResult')
        let req = dataTag;
        req.set('result', vBatch);
        req.save().then(request => {
          this.get('model.vitalsRequests').removeObject(req);
        }).catch(failure => {
          console.log(failure);
          this.notify.error('Unable to send request');
        });
      },

      assign(event) {
        let record = this.get('selectedItems').get('firstObject');
        this.set('showAssignToDoc', true);
      },

      assignDoctorOnConfirm() {
        var that = this;
        let unit = this.get('selectedDepartmentUnit');
        let queuedPatient = this.get('selectedItems').get('firstObject');
        let visit = queuedPatient.get('visit');

        if (!unit || !queuedPatient || !visit) {
          that.notify.error('Something is null');
          return;
        }

        queuedPatient.set('dateServed', new Date().toISOString().slice(0, 19).replace('T', ' '));
        queuedPatient.save().then(function (qu_) {
          let newQueue = that.get('store').createRecord('queued-patient', {
            departmentUnit: unit,
            visit: visit
          });
          newQueue.save().then(function (sQp) {
            that.set('selectedItems', []);
            that.get('model.queuedPatients').removeObject(queuedPatient);
            that.notify.success('Assigned');
          });
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      "propertyName": "patientName",
      'className': 'main-column'
    }, {
      title: 'Purpose',
      'propertyName': 'visitPurposeName'
    }, {
      "propertyName": "visitDate"
    }],
    requestColumns: [{
      'title': 'Date ',
      "propertyName": "dateF",
      className: 'main-column'
    }, {
      'title': 'Patient name ',
      "propertyName": "patientName",
      className: 'main-column'
    }, {
      'title': 'Note',
      "propertyName": "note"
    }]
  });

  _exports.default = _default;
});
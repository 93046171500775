define("hmis/mixins/rest-serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    keyForAttribute: function (attr) {
      if (attr == 'createdAt' || attr == 'updatedAt') return Ember.String.underscore(attr);else {
        return Ember.String.camelize(attr);
      }
    },

    modelNameFromPayloadKey(payloadKey) {
      // console.log('first', payloadKey );
      if (payloadKey.indexOf('Obj') !== -1) {
        // console.log('seen', payloadKey );
        return this._super(payloadKey.replace('Obj', ''));
      } else {
        return this._super(payloadKey);
      }
    },

    serialize(snapshot, options) {
      var json = {
        id: snapshot.id
      };
      snapshot.eachAttribute((key, attribute) => {
        json[key] = snapshot.attr(key);
      });
      snapshot.eachRelationship((key, relationship) => {
        if (relationship.kind === 'belongsTo') {
          json[key] = snapshot.belongsTo(key, {
            id: true
          });
        } //  else if (relationship.kind === 'hasMany') {
        //   json[key] = snapshot.hasMany(key, { ids: true });
        // }

      }); // console.log('serialized', json);

      return json;
    },

    serializeIntoHash(data, type, record, options) {
      Ember.assign(data, this.serialize(record, options));
    }

  });

  _exports.default = _default;
});
define("hmis/models/patient-dependant", ["exports", "ember-data", "ember-data/relationships", "hmis/mixins/patient-property-model"], function (_exports, _emberData, _relationships, _patientPropertyModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_patientPropertyModel.default, {
    patient: (0, _relationships.belongsTo)('patient'),
    insuranceProduct: (0, _relationships.belongsTo)('patient-insurance'),
    billTo: (0, _relationships.belongsTo)('patient'),
    billToName: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    insuranceName: _emberData.default.attr()
  });

  _exports.default = _default;
});
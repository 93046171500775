define("hmis/models/queued-patient", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/visit-service-model"], function (_exports, _emberData, _relationships, _dateUtil, _visitServiceModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, {
    departmentUnit: _emberData.default.attr(),
    dateServed: _emberData.default.attr(),
    departmentUnitName: _emberData.default.attr(),
    visit: (0, _relationships.belongsTo)('visit', {
      async: false
    }),
    patient: (0, _relationships.belongsTo)('patient', {
      async: false
    }) // end(){
    //     this.set('dateServed', dateUtil.toSqlDate(new Date()))
    //     this.save()
    // },
    // createActiveServe( callBack){
    //     let record = this;
    //     let visit = record.get('visit')
    //     record.newActiveServeFromVisit(visit, callBack)
    // },
    // newActiveServeFromVisit(visit, callBack){
    //     this.get('store').createRecord('active-serve', {
    //         visit: visit,
    //         staff: null,
    //         startDate:dateUtil.toSqlDate(new Date()),
    //         }).save().then((activeServe)=>{
    //             this.end()
    //             if(callBack.success)
    //                 callBack.success(activeServe)
    //         },
    //         function(err){
    //             if(callBack.failure)
    //             callBack.failure(err)
    //         }
    //     )
    // }

  });

  _exports.default = _default;
});
define("hmis/routes/configure/user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    controllerName: 'hmis-app.users.add-user',
    templateName: 'hmis-app.users.add-user'
  });

  _exports.default = _default;
});
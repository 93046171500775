define("hmis/controllers/hmis-app/diagnostics/investigations/review", ["exports", "hmis/utils/request-utils/ipd", "hmis/utils/model-table-theme", "hmis/utils/date-util", "hmis/mixins/visit-request-review-controller"], function (_exports, _ipd, _modelTableTheme, _dateUtil, _visitRequestReviewController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_visitRequestReviewController.default, {
    routing: Ember.inject.service('-routing'),
    ajaxRequest: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    // isEdit:true,
    actions: {
      back() {
        history.back();
      },

      onEditClick(even) {
        this.set('isEdit', true);
      },

      onSaveClick() {
        this.get('submitForm')();
      },

      saveResult() {
        this.notify.confirmSimple('You will not be able to make future changes after saving', null, () => {
          let investigation = this.get('model.data');
          this.set('saveWaiting', true);
          let data = {
            result: investigation.result,
            resultNote: investigation.resultNote
          };
          this.appAjax.post("/applyInvestigation/" + investigation.id, data).then(response => {
            this.set('isEdit', false);
            this.set('saveWaiting', false);
            this.send('refreshModel');
            this.notify.success('Saved');
            this.send('refreshModel');
          }).catch(err => {
            this.set('saveWaiting', false);
            this.notify.error(err);
          });
        });
      },

      onCancelClick(event) {
        this.set('isEdit', false);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    //date , patient, requestor
    columns: [{
      'title': 'Sequence',
      "propertyName": "sequence"
    }, {
      'title': 'Warning',
      "propertyName": "warning",
      "component": "checkBox"
    }, {
      'title': 'Excluded',
      "propertyName": "excluded",
      "component": "checkBox"
    }, {
      'title': 'Test',
      "propertyName": "investigationTestTypeName",
      className: 'main-column'
    }, {
      'title': 'Lower limt',
      "propertyName": "investigationTestType.lowerLimit" // "component": "tableEditable"

    }, {
      'title': 'Upper limit',
      "propertyName": "investigationTestType.upperLimit" // "component": "tableEditable"

    }, {
      'title': 'units',
      "propertyName": "investigationTestType.units"
    }, {
      'title': 'Results',
      "propertyName": "result",
      "disabledPropertyName": "excluded",
      "component": "tableEditable"
    }, {
      'title': 'Remarks',
      "propertyName": "remark",
      "disabledPropertyName": "excluded",
      "component": "tableEditable",
      textArea: true
    }]
  });

  _exports.default = _default;
});
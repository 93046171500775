define("hmis/components/controls/table-doctor-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    selectedDoc: [],
    removedDoc: [],
    optionsDoc: Ember.computed(function () {
      return this.get('store').findAll('user');
    }),

    init() {
      this._super(...arguments);

      console.log('tab item dep ', this.get('column.departmentUnit.id'));
      console.log('record ', this.get('record.id'));
      let period = this.get('record.id');
      let date = this.get('date');
      let depUnit = this.get('column.departmentUnit.id');
      this.get('store').query('schedule', {
        filter: {
          facilityperiodId: period,
          departmentUnitId: depUnit,
          day: date
        }
      }).then(list => {
        console.log('schedule list ', list.length);
        this.set('schedules', list.toArray());
      });
    },

    actions: {
      onOpen(event) {
        this.set('isOpen', true);
      },

      onConfirm(newVal) {
        // this.set('value')
        let record = this.get('record');
        let property = this.get('column.propertyName');
        record.set(property, newVal);
      },

      selDoctor(doc) {//let gd = this.get('')
        //$('#selDoc').html(doc)
        // this.set('value')
        // let record = this.get('record')
        // let property = this.get('column.propertyName')
        // record.set(property, newVal)
      },

      addDoc(doc) {
        let that = this;
        let period = this.get('record');
        let date = this.get('date');
        let depUnit = this.get('column.departmentUnit');
        let newSch = this.get('store').createRecord('schedule', {
          staff: doc,
          day: date,
          departmentUnit: depUnit,
          facilityPeriod: period,
          startTime: period.get('startTime'),
          endTime: period.get('endTime')
        });
        newSch.save().then(savedSch => {
          this.get("schedules").pushObject(savedSch);
        });
      },

      removeDoc(doc) {
        console.log(doc);
        doc.destroyRecord().then(removedItem => {
          this.get("schedules").removeObject(removedItem);
        }); //this.get("removedDoc").pushObject(doc);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/models/diagnosis-sub-type", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    icdCode: _emberData.default.attr(),
    gdrgCode: _emberData.default.attr(),
    icdName: _emberData.default.attr(),
    mdgCode: _emberData.default.attr(),
    diagnosisTypeId: _emberData.default.attr(),
    diagnosisType: (0, _relationships.belongsTo)('diagnosis-type'),
    diagnosisTypeName: _emberData.default.attr(),
    diagnosisMajorGroupName: _emberData.default.attr(),
    displayName: Ember.computed("diagnosisTypeId.genericName", function () {
      return "".concat(this.icdCode, " - ").concat(this.icdName);
    }) // diagnosisTypeText: computed("diagnosisTypeId.genericName", function(){
    //     return `${this.get('diagnosisTypeId.genericName')}`;
    // })

  });

  _exports.default = _default;
});
define("hmis/models/operation-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    hasAdd: _emberData.default.attr('boolean'),
    hasView: _emberData.default.attr('boolean'),
    hasEdit: _emberData.default.attr('boolean'),
    hasInactivate: _emberData.default.attr('boolean'),
    hasAllow: _emberData.default.attr('boolean'),
    description: _emberData.default.attr() // crudCode: DS.attr(), 

  });

  _exports.default = _default;
});
define("hmis/components/controls/report-controls", ["exports", "hmis/utils/reports"], function (_exports, _reports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    // tagName: '',
    classNames: ['report-controls', 'app-fixed-top', 'content-list'],
    show: true,

    init() {
      this._super(...arguments);

      let serverReport = this.get('serverReport');
      if (!this.get('selectedRows')) this.set('selectedRows', []);
      if (!this.get('selectedColumns')) this.set('selectedColumns', []);
    },

    reports: Ember.inject.service(),
    allSection: {
      id: 'none',
      label: 'All'
    },
    otherFilter: {},
    // otherFilter:computed('serverReport', function() {
    //   let serverReport = this.get('serverReport')
    //   if(!serverReport)
    //     return {};
    //   let obj = {};
    //   for (let key in serverReport.filterProps)
    //   {
    //     obj[serverReport.filterProps[key].key] = {id:'none', label: 'All'}
    //   }
    //   return obj;
    // }),
    filterPropsArray: Ember.computed('serverReport', function () {
      let serverReport = this.get('serverReport');
      if (!serverReport) return [];
      let arr = [];

      for (let key in serverReport.filterProps) {
        let filterProp = serverReport.filterProps[key];
        Ember.set(filterProp, 'key', key); // filterProp['key']=key

        arr.push(filterProp);
      }

      return arr;
    }),
    rowFilterProps: Ember.computed('filterPropsArray', 'columnFilterProp', function () {
      let filterPropsArray = this.get('filterPropsArray');
      if (!filterPropsArray) return [];
      let arr = [];
      filterPropsArray.forEach(item => {
        if (item.key != this.get('columnFilterProp.key')) arr.push(item);
      });
      return arr;
    }),
    columnFilterProps: Ember.computed('filterPropsArray', 'rowFilterProp', function () {
      let filterPropsArray = this.get('filterPropsArray');
      if (!filterPropsArray) return [];
      let arr = [];
      filterPropsArray.forEach(item => {
        if (item.key != this.get('rowFilterProp.key')) arr.push(item);
      });
      return arr;
    }),
    otherFiltersMap: [],
    actions: {
      rowFilterPropChange(rowFilterProp) {
        this.set('selectedRows', []);
        this.set('rowFilterProp', rowFilterProp);
      },

      columnFilterPropChange(columnFilterProp) {
        this.set('selectedColumns', []);
        this.set('columnFilterProp', columnFilterProp);
      },

      otherFilterChanged(referenceName, referenceItem) {
        this.set('otherFilter.' + referenceName, referenceItem);
        let serverReport = this.get('serverReport');

        if (referenceItem.id == 'none') {
          this.get('otherFiltersMap').forEach(item => {
            if (item.filterKey == referenceName) this.get('otherFiltersMap').removeObject(item);
          });
        } else {
          let hasFilter = false;

          for (let i = 0; i < this.get('otherFiltersMap').length; i++) {
            let item = this.get('otherFiltersMap')[i];

            if (item.filterKey == referenceName) {
              let otherFiltersMap = this.get('otherFiltersMap')[i];
              Ember.set(otherFiltersMap, 'filter', dataItem => {
                let filterProp = serverReport.filterProps[referenceName];
                return this.get('reports').validateDataItem(dataItem, filterProp, referenceItem);
              });
              hasFilter = true;
              break;
            }
          }

          if (!hasFilter) {
            this.get('otherFiltersMap').pushObject({
              filterKey: referenceName,
              filter: dataItem => {
                let filterProp = serverReport.filterProps[referenceName];
                return this.get('reports').validateDataItem(dataItem, filterProp, referenceItem);
              }
            });
          }
        }

        this.send('setOtherFilters');
      },

      setOtherFilters() {
        let otherFiltersMap = this.get('otherFiltersMap'); // let arr = [];
        // otherFiltersMap.forEach(item=>{
        //   var filterFunc = item.filter;
        //   if(filterFunc)
        //   arr.push(filterFunc)
        // })

        this.set('otherFilters', otherFiltersMap);
        console.log();
      }

    }
  });

  _exports.default = _default;
});
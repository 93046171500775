define("hmis/templates/components/questionnaire/q-edit-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CtcTwTQz",
    "block": "{\"symbols\":[\"itm\",\"idx\"],\"statements\":[[4,\"if\",[[24,[\"isEnabled\"]]],null,{\"statements\":[[4,\"if\",[[28,\"or\",[[28,\"not\",[[28,\"eq\",[[24,[\"item\",\"type\"]],\"computation\"],null]],null],[28,\"and\",[[28,\"eq\",[[24,[\"item\",\"type\"]],\"computation\"],null],[24,[\"item\",\"visible\"]]],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"q-item-wrapper\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"main-row\"],[8],[0,\" \"],[7,\"div\",true],[11,\"class\",[29,[\"q-label \",[28,\"if\",[[24,[\"isSub\"]],\"is-sub\",\"\"],null]]]],[8],[1,[24,[\"item\",\"text\"]],false],[0,\"?\"],[9],[0,\" \"],[1,[28,\"questionnaire/q-edit-item-input\",null,[[\"item\",\"structure\",\"valueSet\",\"onChange\"],[[24,[\"item\"]],[24,[\"structure\"]],[24,[\"valueSet\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]]],false],[0,\" \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"item\",\"items\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"item\",\"items\"]]],null,{\"statements\":[[0,\"            \"],[1,[28,\"questionnaire/q-edit-item\",null,[[\"item\",\"questionsMap\",\"isSub\",\"structure\",\"valueSet\",\"parentItem\",\"onChange\"],[[23,1,[]],[24,[\"questionsMap\"]],true,[24,[\"structure\"]],[24,[\"valueSet\"]],[24,[\"item\"]],[24,[\"onChange\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/questionnaire/q-edit-item.hbs"
    }
  });

  _exports.default = _default;
});
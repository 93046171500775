define("hmis/routes/hmis-app/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    appAjax: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);

      this.appAjax.post("/read-notifications").then(res => {
        this.currentUser.notifications.forEach(item => Ember.set(item, 'read', 1));
      });
    }

  });

  _exports.default = _default;
});
define("hmis/components/forms/immunization-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    modelName: 'immunization',
    immuStatus: ['Completed', 'Ongoing'],
    immunList: Ember.computed('model', function () {
      return this.get('store').findAll('immunization-type');
    }),
    actions: {},
    immunColumns: [{
      propertyName: 'name'
    }]
  });

  _exports.default = _default;
});
define("hmis/components/managers/procedure-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    flex_level: 'flex-45 flex-xs-100 flex-sm-100'
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/table-long-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gvj5vzGn",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[0,\"    \"],[1,[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"tooLong\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"btn-show-all\"],[8],[0,\"\\n        \"],[7,\"button\",true],[11,\"onClick\",[28,\"action\",[[23,0,[]],\"showAlll\"],null]],[8],[0,\"Show all\"],[9],[0,\"\\n    \"],[9],[0,\"\\n        \\n\"]],\"parameters\":[]},null],[4,\"alerts/confirm-dialog\",null,[[\"show\",\"title\",\"hideConfirm\",\"cancelText\",\"message\"],[[24,[\"isOpen\"]],[24,[\"alertTitle\"]],true,\"Close\",null]],{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[1,[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/table-long-text.hbs"
    }
  });

  _exports.default = _default;
});
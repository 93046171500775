define("hmis/components/stack-pages/ins-man-products", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    products: Ember.computed('company', function () {
      this.set('loadingList', true);
      return _emberData.default.PromiseArray.create({
        promise: this.get('store').query('insurance-product', {
          filter: {
            insuranceCompanyId: this.get('company.id')
          }
        }).then(records => {
          this.set('loadingList', false);
          return records.toArray();
        }, err => this.set('loadingList', false))
      });
    }),
    newRecord: {},
    actions: {
      deleteRecord(record) {
        record.destroyRecord();
      },

      saveRecord(record) {
        record.save();
      },

      saveNewRecord() {
        this.set('listWait', true);
        let data = this.get('newRecord');
        data.insuranceCompany = this.get('company');
        let newRecord = this.get('store').createRecord('insurance-product', data);
        newRecord.save().then(record => {
          this.get('products').pushObject(record);
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error('Unable to Save');
        });
      },

      onItemClick(record) {
        if (this.get('onProductClick')) this.get('onProductClick')(record);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/users/view-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    notify: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    actions: {
      back() {
        history.back();
      },

      resetPassword() {
        this.set('resetingPassword', true);
        this.get('ajaxRequest').resetPassword(this.get('model.id'), {
          success: () => {
            this.notify.success('Password reset successful');
            this.set('resetingPassword', false);
          },
          error: err => {
            this.set('resetingPassword', false);
            this.notify.error(err);
          }
        });
      },

      edit() {
        var record = this.get("model");
        this.get("routing").transitionTo("hmis-app.users.edit-user", [record.id]);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/admin-settings/icd-diagnosis", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        diagnosisSubTypes: this.get('store').query('diagnosis-sub-type', {
          include: ['diagnosisTypeName'],
          pageSize: 25
        }),
        diagnosisTypes: this.get('store').query('diagnosis-type', {
          pageSize: 25
        })
      });
    },

    actions: {
      reloadModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/dropdown-trigger", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "60bGCwvf",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"dd-trigger \",[22,\"class\"]]]],[11,\"onmouseover\",[28,\"action\",[[23,0,[]],\"onMouseOver\"],null]],[11,\"onmouseout\",[28,\"action\",[[23,0,[]],\"onMouseLeave\"],null]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onClick\"],null]],[8],[0,\"\\n    \"],[14,1],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/dropdown-trigger.hbs"
    }
  });

  _exports.default = _default;
});
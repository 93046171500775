define("hmis/components/report-pages/_chag-facility-types-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    reports: Ember.inject.service(),
    reportData: {},
    filterProps: [{
      region: "Eastern"
    }],
    chartOptions: Ember.computed('chartColums', function () {
      let chartColums = this.get('chartColums');
      return {
        chart: {
          type: 'bar',
          height: 645,
          exporting: {
            enabled: true
          }
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: chartColums
        },
        yAxis: {// title: {
          //   text: 'Fruit eaten'
          // }
        },
        legend: {
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 100
        },
        plotOptions: {
          series: {
            stacking: 'normal'
          }
        },
        exporting: {
          enabled: true
        }
      };
    }),
    someArray: ['jejejje', 'jdjdjjdj'],
    chartColums: Ember.computed('serverReport', function () {
      let serverReport = this.get('serverReport');
      if (!serverReport) return [];
      let columnFilters = this.get('columnFilters');
      let chartColums = [];
      columnFilters.forEach(column => {
        chartColums.push(column.label);
      });
      return chartColums;
    }),
    chatData: Ember.computed('serverReport', function () {
      let serverReport = this.get('serverReport');
      if (!serverReport) return {
        categories: []
      };
      let columnFilters = this.get('columnFilters');
      let rowFilters = this.get('rowFilters');
      let formatedData = this.get('reports').serverReportToChatData(serverReport, columnFilters, rowFilters);
      return formatedData;
    })
  });

  _exports.default = _default;
});
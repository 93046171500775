define("hmis/templates/components/forms/chronic-diseases-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WC7Vmw3B",
    "block": "{\"symbols\":[],\"statements\":[[4,\"alerts/form-dialog\",null,[[\"title\",\"waiting\",\"show\",\"width\",\"height\",\"onConfirm\",\"onCancel\"],[[24,[\"alertTitle\"]],[24,[\"waiting\"]],[24,[\"show\"]],\"60%\",\"50%\",[28,\"action\",[[23,0,[]],\"onRecordSubmit\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null]]],{\"statements\":[[0,\"\\n    \"],[1,[28,\"pickers/diagnosis-sub-type-input\",null,[[\"required\",\"selected\",\"selectedChange\"],[[24,[\"required\"]],[24,[\"record\",\"diagnosisSubType\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"diagnosisSubType\"]]],null]],null]]]],false],[0,\"\\n\\n    \"],[1,[28,\"paper-input\",null,[[\"class\",\"placeholder\",\"label\",\"textarea\",\"passThru\",\"type\",\"value\",\"onChange\"],[\"flex-100\",\"eg. symptoms\",\"Notes\",true,[28,\"hash\",null,[[\"rows\",\"maxRows\"],[3,6]]],\"text\",[24,[\"record\",\"note\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"note\"]]],null]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/forms/chronic-diseases-alert.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/report-table-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FJNhXIBC",
    "block": "{\"symbols\":[\"count\"],\"statements\":[[0,\"\\n\"],[7,\"td\",true],[8],[1,[24,[\"dataItem\",\"label\"]],false],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"dataItem\",\"data\"]]],null,{\"statements\":[[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/report-table-row.hbs"
    }
  });

  _exports.default = _default;
});
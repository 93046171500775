define("hmis/templates/components/managers/appointment/appointment-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "l7lHt/pA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"alerts/confirm-dialog\",null,[[\"show\",\"title\",\"message\",\"confirmText\",\"cancelText\",\"width\",\"height\",\"confirmDisabled\",\"onConfirm\"],[[24,[\"show\"]],[24,[\"title\"]],null,[24,[\"confirmText\"]],[24,[\"cancelText\"]],\"80%\",\"80%\",[28,\"unless\",[[24,[\"validAppointment\"]],true,false],null],[24,[\"onConfirm\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"managers/appointment-manager\",null,[[\"appointment\",\"validInputs\",\"appointment\",\"hideStaff\"],[null,[24,[\"validAppointment\"]],[24,[\"appointment\"]],[24,[\"hideStaff\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/appointment/appointment-alert.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/department-management/department/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    store: Ember.inject.service('store'),
    currentUser: Ember.inject.service(),
    tagName: '',
    tabStatus: 0,
    routing: Ember.inject.service('router'),
    notify: Ember.inject.service(),
    consultationUnits: Ember.computed(function () {
      return this.get('store').findAll('other-billable');
    }),
    genderList: Ember.computed(function () {
      return this.get('store').findAll('gender');
    }),
    departmentName: Ember.computed('model.departmentUnits', function () {
      let dpun = this.get('model.departmentUnits').get('firstObject');
      let dn = dpun ? dpun.departmentName : '';
      return "Department: " + dn;
    }),
    newDeptUnit: {},
    newWard: {},
    onConfirm: 'deleteRecord',
    actions: {
      addDepartmentUnit() {
        let data = this.get('newDeptUnit');
        data.department = this.get('model.departmentUnits').get('firstObject').get('department');
        console.log('dep data', data);
        let post = this.get('store').createRecord('department-unit', data);
        this.set('duListWait', true);
        post.save().then(record => {
          this.set('duListWait', false);
          this.set('newDeptUnit', {});
          this.notify.success('Saved Successfully');
          this.send('reloadModel');
        }, failure => {
          this.set('duListWait', false);
          this.notify.error('Unable to Save' + failure);
        });
      },

      addWard() {
        let data = this.get('newWard');
        data.department = this.get('model.departmentUnits').get('firstObject').get('department');
        let post = this.get('store').createRecord('ward', data);
        this.set('wListWait', true);
        post.save().then(record => {
          this.set('wListWait', false);
          this.set('newWard', {});
          this.notify.success('Saved Successfully');
          this.send('reloadModel');
        }, failure => {
          this.set('wListWait', false);
          this.notify.error('Unable to Save' + failure);
        });
      },

      wardClick(record) {
        // this.get('statsContainer').addPage('stack-pages/department-ward-rooms', record.get('name'),{ward: record})
        this.get("routing").transitionTo("hmis-app.admin-settings.department-management.department.ward", record.id);
      },

      saveRecord(record) {
        console.log('old ward uprec', record);
        record.save().then(record => {
          this.set('listWait', false);
          this.notify.success('Saved Successfully');
          this.send('reloadModel');
        }).catch(failure => {
          this.set('listWait', false);
          this.notify.error(failure);
        });
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete a department unit: (' + record.get('name') + ')');
        this.set('onAlert', true);
      },

      deleteNow(record) {
        record.destroyRecord();
        this.send('reloadModel'); // let department = this.get('props.department');
        // if(this.tabStatus === 1){
        //   department.get("wards").removeObject(record);
        //   record.destroyRecord();
        // }else {
        //   department.get("departmentUnits").removeObject(record);
        //   record.destroyRecord();
        // }
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/table-prop-format", ["exports", "hmis/utils/date-util"], function (_exports, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { get } from '@ember/object';
  function concatWithProperty(strings, property) {
    if (property) {
      strings.push(property);
    }

    return strings.join(' ');
  }

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    },

    text: Ember.computed('column.format', 'column.propertyName', 'record', function () {
      let format = this.get('column.format');
      let propertyName = this.get('column.propertyName');
      let value = this.get('record.' + propertyName); // console.log(value, propertyName);

      if (typeof format === "function") {
        return format(value, this.record);
      } else if (formats[format]) return formats[format](value);

      return value;
    }),
    tagName: '',
    actions: {}
  });

  _exports.default = _default;
  const formats = {
    date: value => {
      return _dateUtil.default.formatToDateTimeString(value);
    }
  };
});
define("hmis/components/managers/facility-vitals-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    facilities: Ember.computed('model', function () {
      return this.get('store').findAll('facility');
    }),
    vitaltypes: Ember.computed('model', function () {
      return this.get('store').findAll('vital-type');
    })
  });

  _exports.default = _default;
});
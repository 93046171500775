define("hmis/templates/components/alerts/dialog-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tx+x/1kx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"alert-extra-message\"]],{\"statements\":[[0,\"    \"],[7,\"span\",true],[8],[0,\"\\n        \"],[14,1],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/alerts/dialog-message.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/forms/dependant-alert", ["exports", "hmis/mixins/form-alert", "hmis/mixins/ref-component"], function (_exports, _formAlert, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, _refComponent.default, {
    modelName: 'patient-dependant',
    store: Ember.inject.service('store'),
    ajaxRequest: Ember.inject.service(),
    type: "add",
    input_width: "flex-45 flex-xs-100 flex-sm-100",

    showForm(patientId, record, onSavedCallback) {
      this.set('patientId', patientId);
      this.set('record', record);
      this.set('show', true);
      this.set('onSaved', onSavedCallback);
    },

    insuranceProducts: Ember.computed('patientId', function () {
      return this.get('store').query('patient-insurance', {
        filter: {
          patientId: this.patientId
        },
        pageSize: 10
      });
    }),
    patients: Ember.computed(function () {
      return this.get('store').query('patient', {
        pageSize: 10
      });
    }),
    actions: {},
    columns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      title: 'Id',
      "propertyName": "folderNumber"
    }, {
      "propertyName": "title"
    }, {
      "propertyName": "fullName"
    }, {
      "propertyName": "patientStatus"
    }, {
      "propertyName": "age"
    }, {
      "propertyName": "genderName",
      title: 'Gender'
    }, {
      "propertyName": "physicalAddress"
    }, {
      "propertyName": "phoneNumber"
    } // {
    //   "title": "Actions",
    //   "component": "manageRow"
    // }
    ]
  });

  _exports.default = _default;
});
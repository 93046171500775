define("hmis/routes/configure/department/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    currentUser: Ember.inject.service(),
    controllerName: 'hmis-app.admin-settings.department-management',
    templateName: 'hmis-app.admin-settings.department-management',

    model(params) {
      return this.get('store').findAll('department');
    }

  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/ipd/patients", ["exports", "hmis/utils/model-table-theme", "hmis/utils/request-utils/ipd", "hmis/utils/common-data-columns", "hmis/utils/general-util", "hmis/constants/visit-outcomes"], function (_exports, _modelTableTheme, _ipd, _commonDataColumns, _generalUtil, _visitOutcomes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    ajaxRequest: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    app: Ember.inject.service(),
    data: Ember.inject.service(),
    selectedPendings: [],
    selectedPendingDischarges: [],
    visitOutcomes: Ember.computed(function () {
      return this.get('store').findAll('visit-outcome');
    }),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    actions: {
      showVisitTransactions() {
        this.get('showVisitTransactions')(this.get('selectedPendingDischarges.firstObject.visit'));
      },

      admitAlert(rec) {
        this.set('pickerPendings', rec.get('firstObject'));
        this.set('onAdmitAlert', true);
      },

      admissionRowClick(index, record) {
        this.send("openWard", record);
      },

      admissionViewRecord(record) {
        this.send("openWard", record);
      },

      openWard(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.ipd.ward", [rid]);
      },

      filterDischarged(selectedDates, force = false) {
        if (this.dischargedSelectedDates === selectedDates && !force) return;
        this.set('dischargedSelectedDates', selectedDates);
        let dischargedDateRange = (0, _generalUtil.selectedDatesToDaterange)(selectedDates);
        let filter = (0, _generalUtil.trimNullProps)({
          dischargedDateRange,
          status: 'discharged'
        });
        this.set('dischargedData', this.app.query('admitted-queued-patient', {
          filter,
          include: ['patientName', 'patientGenderId', 'visitDate', 'visitPurpose']
        }));
      },

      admitPatient(record) {
        this.data.serverTable({
          modelName: 'ward',
          columns: _commonDataColumns.wardPickerColumns,
          title: 'Pick a ward',
          query: {
            filter: {
              genderId: record.patientGenderId
            }
          }
        }, ward => {
          this.data.serverTable({
            modelName: 'room',
            columns: _commonDataColumns.roomPickercolumns,
            title: 'Pick a room',
            query: {
              filter: {
                wardId: ward.id
              }
            }
          }, room => {
            this.app.startLoading();
            let data = {
              admittedQueuedPatients: [record.id],
              room: room.id
            };
            this.appAjax.post("admitPatients", data).then(res => {
              this.send('refreshModel');
              this.set('selectedPendings', []);
              this.app.stopLoading();
            }).catch(err => {
              this.notify.error(err);
              this.app.stopLoading();
            });
          });
        });
      },

      discharge(record) {
        this.notify.confirmOptions({
          title: "Select the outcome",
          options: _visitOutcomes.VISIT_OUTCOME_OBJECTS,
          list: true
        }, outcome => {
          this.notify.prompt({
            textArea: true,
            title: "Enter Discharge note",
            message: "Discharging " + record.patientName + " with an outcome of '" + outcome.title + "'"
          }, note => {
            this.set('discharging', true);
            let data = {
              note,
              outcome: outcome.id
            };
            this.appAjax.post("dischargePatient/" + record.id, data).then(res => {
              this.send('refreshModel');
              this.set('discharging', false);
              this.app.stopLoading();
            }).catch(err => {
              this.notify.error(err);
              this.set('discharging', false);
              this.app.stopLoading();
            });
          });
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    pendingColumns: [{
      "propertyName": "patientName",
      'className': 'main-column'
    }, {
      title: 'Time of request',
      "propertyName": "dateTimeF"
    }, {
      "title": 'Note',
      "propertyName": "admissionNote"
    }, {
      "component": "manageRow"
    }],
    admittedColumns: [{
      "propertyName": "patientName",
      'className': 'main-column'
    }, {
      title: 'Date of Admission',
      "propertyName": "admissionDateF"
    }, {
      title: 'Date of visit',
      "propertyName": "visitDateF"
    }, {
      "title": 'Reason for visit',
      "propertyName": "visitPurpose"
    }, {
      // "title": "manager",
      "component": "manageRow"
    }],
    dischargedColumns: [{
      "propertyName": "patientName",
      'className': 'main-column'
    }, {
      title: 'Admission date',
      "propertyName": "admissionDateF"
    }, {
      title: 'Discharged date',
      "propertyName": "dischargeDateF"
    }, {
      title: 'Date of visit',
      "propertyName": "visitDateF"
    }, {
      "title": 'Reason for visit',
      "propertyName": "visitPurpose"
    }, {
      // "title": "manager",
      "component": "manageRow"
    }],
    pendingDischargeColumns: [{
      "propertyName": "patientName",
      'className': 'main-column'
    }, {
      title: 'Date of Admission',
      "propertyName": "admissionDateF"
    }, {
      title: 'Date of visit',
      "propertyName": "visitDateF"
    }, {
      "title": 'Reason for visit',
      "propertyName": "visitPurpose"
    }, {
      // "title": "manager",
      "component": "manageRow"
    }]
  });

  _exports.default = _default;
});
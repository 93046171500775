define("hmis/components/app-multi-select", ["exports", "hmis/components/paper-input"], function (_exports, _paperInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _paperInput.default.extend({
    searchField: '',
    onchange: Ember.computed.alias('onChange'),
    optionsComponent: 'paper-select-options',
    triggerComponent: 'paper-select-trigger',
    beforeOptionsComponent: 'paper-select-search',
    classNameBindings: ['isInvalidAndTouched:md-input-invalid', 'selected:md-input-has-value', 'focusedAndSelected:md-input-focused', 'appSelect:app-select'],
    appSelect: true,
    searchEnabled: false,
    validationProperty: 'selected',
    isTouched: false,
    isInvalidAndTouched: Ember.computed.and('isInvalid', 'isTouched'),
    attributeBindings: ['parentTabindex:tabindex'],
    shouldShowLabel: Ember.computed.and('label', 'selected'),
    focusedAndSelected: Ember.computed.and('focused', 'selected'),
    concatenatedTriggerClasses: Ember.computed('triggerClass', 'publicAPI.isActive', function () {
      let classes = ['ember-power-select-trigger'];

      if (this.get('isInvalid')) {
        classes.push('ng-invalid');
      }

      if (this.get('isTouched')) {
        classes.push('ng-dirty');
      }

      if (this.get('publicAPI.isActive')) {
        classes.push('ember-power-select-trigger--active');
      }

      return concatWithProperty(classes, this.get('triggerClass'));
    })
  });

  _exports.default = _default;
});
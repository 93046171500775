define("hmis/helpers/paged-report-count", ["exports", "hmis/utils/currency-util"], function (_exports, _currencyUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.reportCount = reportCount;
  _exports.default = void 0;

  function reportCount([value, aggregate, decimalCount = 2, decimal = ".", thousands = ","]) {
    let prefix = aggregate['valuePrefix'] || '';
    let dataType = aggregate['valueType'] || 'count';
    if (!value) return '-';

    if (dataType == 'count') {
      return prefix + value;
    } else if (dataType == 'amount') {
      return prefix + _currencyUtil.default.formatMoney(value, decimalCount, decimal, thousands);
    } else {
      return prefix + value;
    }
  }

  var _default = Ember.Helper.helper(reportCount);

  _exports.default = _default;
});
define("hmis/models/user-operation-permission", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user'),
    operationPermission: _emberData.default.belongsTo('operation-permission'),
    add: _emberData.default.attr('boolean'),
    edit: _emberData.default.attr('boolean'),
    view: _emberData.default.attr('boolean'),
    inactivate: _emberData.default.attr('boolean'),
    allow: _emberData.default.attr('boolean') // permissionId:DS.attr()

  });

  _exports.default = _default;
});
define("hmis/templates/components/classic/c-chips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9CTrEMkE",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[7,\"label\",true],[8],[1,[22,\"label\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"c-chips-wrapper m-input\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"c-chip m-input classic\"],[8],[0,\" \\n            \"],[7,\"button\",false],[12,\"class\",\"c-close\"],[3,\"action\",[[23,0,[]],\"onRemove\",[23,1,[]]]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[9],[0,\"\\n            \"],[14,2,[[23,1,[]]]],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"c-new-wrapper\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"editMode\"]]],null,{\"statements\":[[0,\"            \"],[7,\"form\",true],[11,\"onsubmit\",[28,\"action\",[[23,0,[]],\"onAdd\"],null]],[8],[1,[28,\"classic/c-input\",null,[[\"type\",\"inputClass\",\"value\",\"onChange\"],[\"text\",\"add-input\",[24,[\"newItem\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"newItem\"]]],null]],null]]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"editMode\"]]],null,{\"statements\":[[0,\"            \"],[7,\"button\",true],[10,\"class\",\"c-add m-input\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onAdd\"],null]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-save\"],[8],[9],[9],[0,\"\\n            \"],[7,\"button\",true],[10,\"class\",\"c-add m-input\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onCancel\"],null]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-times\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[7,\"button\",true],[10,\"class\",\"c-add m-input\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onNew\"],null]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-plus\"],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/classic/c-chips.hbs"
    }
  });

  _exports.default = _default;
});
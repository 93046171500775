define("hmis/models/vitals-request", ["exports", "ember-data", "hmis/utils/date-util", "hmis/mixins/visit-service-model", "ember-data/relationships", "hmis/mixins/visit-request-model "], function (_exports, _emberData, _dateUtil, _visitServiceModel, _relationships, _visitRequestModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, _visitRequestModel.default, {
    visit: (0, _relationships.belongsTo)('visit', {
      async: false
    }),
    result: (0, _relationships.belongsTo)('vital-batch', {
      async: false
    }),
    resultId: _emberData.default.attr(),
    visitId: _emberData.default.attr(),
    note: _emberData.default.attr(),
    vitals: (0, _relationships.hasMany)('vital', {
      async: false
    }) // date: DS.attr(),//provided by the server 
    // dateF :computed('date', function() {
    //     return dateUtil.formatToDateTimeString(this.date);
    // }),

  });

  _exports.default = _default;
});
define("hmis/components/controls/progress/bp-chart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chartOptions: {
      chart: {
        height: 200,
        type: 'line'
      },
      title: {
        text: null
      },
      xAxis: {
        categories: ['Day 1', 'Day 2', 'Day 3', 'Day 4', 'Day 5', 'Day 6', 'Day 7']
      },
      yAxis: {
        title: {
          text: 'Temperature (°C)'
        }
      },
      legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true
          },
          enableMouseTracking: false
        }
      }
    },
    chartData: [{
      name: 'Tokyo',
      data: [7.0, 6.9, 9.5, 14.5, 18.4, 21.5, 25.2]
    }],
    chartData2: [{
      name: 'Tokyo',
      data: [11.9, 15.2, 17.0, 3.9, 4.2, 5.7, 8.5]
    }]
  });

  _exports.default = _default;
});
define("hmis/helpers/relative-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function relativeType([patientId, relationObject]
  /*, hash*/
  ) {
    let relativeType = "";

    if (patientId == relationObject.patientId) {
      relativeType = relationObject.typeName;
    } else {
      relativeType = relationObject.patientGenderId == 1 ? relationObject.typeMaleInverseName : relationObject.typeFemaleInverseName;
    }

    return relativeType;
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/ipd/ward/symptoms", ["exports", "hmis/utils/patient-serve-util", "hmis/utils/general-util", "hmis/mixins/route-error-handler", "hmis/utils/visit-service-util", "hmis/mixins/visit-care-route"], function (_exports, _patientServeUtil, _generalUtil, _routeErrorHandler, _visitServiceUtil, _visitCareRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, _visitCareRoute.default, {
    controllerName: 'hmis-app.opd.consultation.consult.symptoms',
    templateName: 'hmis-app.opd.consultation.consult.symptoms',
    parentCareRoute: 'hmis-app.ipd.ward',

    model(params) {
      return (0, _visitServiceUtil.symptomsOpdIpdModel)(this);
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/utils/request-utils/bill", ["exports", "jquery", "hmis/config/environment", "hmis/utils/server-util"], function (_exports, _jquery, _environment, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.emailBill = emailBill;
  _exports.default = void 0;
  const url = _serverUtil.serverUrl;

  function emailBill(patientId, callBack) {
    _jquery.default.get(url + "/sendBillMail/" + patientId).then(response => {
      console.log("Sent mail: ", response);

      if (callBack) {
        try {
          callBack.success(response);
        } catch (error) {
          console.error(error);
        }
      }
    }, error => {
      if (callBack) {
        try {
          callBack.failure(error);
        } catch (tError) {
          console.error(tError);
        }
      }

      console.log("Unable to send mail: ", error);
    });
  }

  var _default = {
    emailBill
  };
  _exports.default = _default;
});
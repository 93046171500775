define("hmis/models/questionnaire", ["exports", "ember-data", "hmis/mixins/model-access-right"], function (_exports, _emberData, _modelAccessRight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelAccessRight.default, {
    title: _emberData.default.attr(),
    description: _emberData.default.attr(),
    status: _emberData.default.attr(),
    isAppData: _emberData.default.attr(),
    patientInfo: _emberData.default.attr(),
    meta: _emberData.default.attr(),
    //json
    items: _emberData.default.attr(),
    //json
    readOnly: Ember.computed('facility', 'currentUser', 'status', function () {
      return this.get('currentUser.facilityId') != this.get('facility') || this.get('status') == 'published';
    }),

    toStructure() {
      return {
        title: this.get('title'),
        description: this.get('description'),
        patientInfo: this.get('patientInfo'),
        items: JSON.parse(this.get('items'))
      };
    },

    saveStructure(structure, successCallback = null, failedCallback) {
      this.set('title', structure.title);
      this.set('description', structure.description);
      this.set('patientInfo', structure.patientInfo);
      this.set('items', JSON.stringify(structure.items));
      this.save().then(rec => {
        if (successCallback) successCallback(rec);
      }, err => {
        if (failedCallback) failedCallback(err);
      });
    }

  });

  _exports.default = _default;
});
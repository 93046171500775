define("hmis/components/controls/report-chart", ["exports", "ember-data", "hmis/mixins/high-chart-manager"], function (_exports, _emberData, _highChartManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_highChartManager.default, {
    reports: Ember.inject.service(),
    reportData: {},
    reports: Ember.inject.service(),
    reportDataType: 'count',
    chatReportData: Ember.computed('serverReport', 'columnReferenceName', 'rowReferenceName', 'otherFilters.@each.filter', 'selectedChartType', 'selectedRowsArray.length', 'selectedColumnsArray.length', 'reportDataType', 'showNoDataRowsColumns', function () {
      return _emberData.default.PromiseObject.create({
        promise: new Ember.RSVP.Promise((resolve, reject) => {
          setTimeout(() => {
            let serverReport = this.get('serverReport');
            let columnReferenceName = this.get('columnReferenceName');
            let rowReferenceName = this.get('rowReferenceName');
            let otherFilters = this.get('otherFilters');
            let selectedRowsArray = this.get('selectedRowsArray');
            let selectedColumnsArray = this.get('selectedColumnsArray');
            let showNoDataRowsColumns = this.get('showNoDataRowsColumns');
            if (!serverReport || !columnReferenceName || !rowReferenceName) return [];
            let formatedData = this.get('selectedChartType.dataType') == 'count' ? this.get('reports').serverReportToChatCountData(serverReport, columnReferenceName, rowReferenceName, otherFilters, selectedRowsArray, selectedColumnsArray, this.get('reportDataType'), showNoDataRowsColumns) : this.get('reports').serverReportToChatPercentageData(serverReport, columnReferenceName, rowReferenceName, otherFilters, selectedRowsArray, selectedColumnsArray, this.get('reportDataType'), showNoDataRowsColumns); // console.log('formatedData',formatedData);

            resolve(formatedData);
          }, 1);
        })
      });
    }),
    content: Ember.computed('chatReportData', 'chatReportData.isFulfilled', 'selectedChartType', function () {
      let chatReportData = this.get('chatReportData');
      if (!chatReportData.isFulfilled) return null;
      return chatReportData.get(this.get('selectedChartType.seriesProp'));
    }),
    chartOptions: Ember.computed('selectedChartType', 'columnReferenceName', 'selectedColumnsArray.length', 'showNoDataRowsColumns', 'reportDataType', 'chatReportData.isFulfilled', function () {
      return this.get(this.get('selectedChartType.options'));
    }),
    content2: Ember.computed('chatReportData', 'chatReportData.isFulfilled', 'selectedChartType', function () {
      let chatReportData = this.get('chatReportData');
      if (!chatReportData.isFulfilled) return null;
      return chatReportData.get(this.get('selectedChartType.series2Prop'));
    }),
    chartOptions2: Ember.computed('selectedChartType', 'columnReferenceName', 'selectedColumnsArray.length', 'showNoDataRowsColumns', 'reportDataType', 'chatReportData.isFulfilled', function () {
      return this.get(this.get('selectedChartType.options2'));
    })
  });

  _exports.default = _default;
});
define("hmis/components/forms/facility-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    modelName: 'facility',
    store: Ember.inject.service('store'),
    ajaxRequest: Ember.inject.service(),
    type: "add",
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    organisationTypes: ["Hosptal", "Clinic", "Community Hospital"],
    store: Ember.inject.service('store'),
    serverUploads: Ember.inject.service(),
    notify: Ember.inject.service(),
    serviceOptions: Ember.computed('model', function () {
      return this.get('store').findAll('offered-service');
    }),
    professionOptions: Ember.computed('model', function () {
      return this.get('store').findAll('profession-type');
    }),
    healthInsuranceOptions: Ember.computed('model', function () {
      return this.get('store').findAll('insurance-company', {}).then(function (recs) {
        return recs;
      });
    }),
    facilityTypes: Ember.computed('model', function () {
      return this.get('store').findAll('facility-type', {}).then(function (recs) {
        return recs;
      });
    }),
    facilityLevelCodes: Ember.computed('model', function () {
      return this.get('store').findAll('level-code');
    }),
    denomination: Ember.computed('model', function () {
      return this.get('store').findAll('denomination', {}).then(function (recs) {
        return recs;
      });
    }),
    addedProfession: Ember.computed('record.professions', function () {
      return this.get('store').findAll('facility-profession');
    }),
    addedService: Ember.computed('record.servicesOffered', function () {
      return this.get('store').findAll('facility-offered-service');
    }),
    isAdd: Ember.computed('type', function () {
      return this.get("type") != "edit";
    }),
    addedInsurances: [],
    removedInsurances: [],
    addProp: Ember.computed('record', function () {
      return this.get('record.professions');
    }),
    curAddedServices: [],
    removedServices: [],
    addedProfession: [],
    removedProfession: [],
    actions: {
      addProfession(profession) {
        this.get("record.professions").pushObject(profession);
      },

      removeProfession(profession) {
        this.get("record.professions").removeObject(profession);
      },

      addService(service) {
        this.get("record.servicesOffered").pushObject(service);
      },

      removeService(service) {
        this.get("record.servicesOffered").pushObject(service);
      },

      addInsurance(insurance) {
        let fInsurance = this.get('store').createRecord('facility-insurance', {
          insuranceCompany: insurance
        });
        this.get("addedInsurances").pushObject(fInsurance);
      },

      removeInsurance(fInsurance) {
        this.get("removedInsurances").pushObject(fInsurance);
        this.get("addedInsurances").removeObject(fInsurance);
      },

      onPictureChange(file) {
        // console.log(file);
        this.get('serverUploads').uplaodFacilityImage(this.record, file, {
          success: response => {
            this.notify.success("Logo changed");
          },
          error: err => {
            this.notify.error("Unable to change logo. Something went wrong");
            console.log(err);
          }
        });
      }

    },
    regions: ['Greater Accra', 'Central', 'Eastern', 'Western', 'Ashanti', 'Northern', 'Upper East', 'Upper West', 'Volta', 'Brong Ahafo']
  });

  _exports.default = _default;
});
define("hmis/components/viewers/reports/patient-activity-report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    fromDate: new Date(),
    toDate: new Date(),
    pidId: "34",

    init() {
      this._super(...arguments);

      let date = new Date(),
          y = date.getFullYear(),
          m = date.getMonth();
      let fd = new Date(y, m, 1);
      let td = new Date(y, m + 1, 0);
      this.set('fromDate', fd);
      this.set('toDate', td);
      this.send('filterNow', fd, td);
    },

    actions: {
      back() {
        history.back();
      },

      onDateFilterSubmit(fromDate, toDate) {
        this.send('filterNow', fromDate, toDate);
        this.set('isToday', false);
        this.set('isThisMonth', false);
      },

      filterToday() {
        var fd = new Date();
        fd.setHours(0, 0, 0, 0);
        var td = new Date();
        td.setHours(24, 0, 0, 0);
        this.send('filterNow', fd, td);
        this.set('fromDate', fd);
        this.set('toDate', td);
        this.set('isToday', true);
        this.set('isThisMonth', false);
        this.set('dateFilterStatus', false);
      },

      filterThisMonth() {
        let date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        let fd = new Date(y, m, 1);
        let td = new Date(y, m + 1, 0);
        fd.setHours(0, 0, 0, 0);
        td.setHours(24, 0, 0, 0);
        this.send('filterNow', fd, td);
        this.set('isToday', false);
        this.set('isThisMonth', true);
        this.set('dateFilterStatus', false);
      },

      filterNow(fromDate, toDate) {
        this.set('fromDate', fromDate);
        this.set('toDate', toDate);
        let that = this;
        let filter = {
          dateRange: {
            fromDate: fromDate.toISOString().slice(0, 19).replace('T', ' '),
            toDate: toDate.toISOString().slice(0, 19).replace('T', ' ')
          }
        };
        this.visualizeReport(fromDate.toISOString().slice(0, 19).replace('T', ' '), toDate.toISOString().slice(0, 19).replace('T', ' '), this.get('pidId'));
      },

      patientRender(pid) {
        let date1 = new Date(this.get('fromDate')),
            y = date1.getFullYear(),
            m = date1.getMonth(),
            d = date1.getDay();
        let fd = new Date(y, m, d);

        let date2 = new Date(this.get('toDate')),
            _y = date2.getFullYear(),
            _m = date2.getMonth(),
            _d = date2.getDay();

        let td = new Date(_y, _m, _d);
        this.set('pidId', pid.id);
        this.send('filterNow', fd, td);
      },

      queryForSummary(context, model, tableData, filter, then) {
        context.get('store').query(model, {
          filter: filter
        }).then(function (qp) {
          context.set(tableData, qp);
        });
      }

    },

    visualizeReport(fd, td, dp = "") {
      visualize({
        auth: {
          name: "tradeuser",
          password: "pass_99word"
        }
      }, function (v) {
        //render report from provided resource
        v("#container_patientactivity").report({
          resource: "/CHAG/Reports/CHAG_ServicesMain",
          //scale: "container",
          params: {
            "date1": [fd],
            "date2": [td],
            "patientID": [dp]
          },
          error: handleError
        }); //show error

        function handleError(err) {
          alert(err.message);
        }
      });
    }

  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/opd/consultation/consult", ["exports", "hmis/utils/visit-service-util"], function (_exports, _visitServiceUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    breadCrumb: null,

    model(params) {
      return this.get('store').query('active-serve', {
        filter: {
          id: params.record_id
        },
        include: ['visitStatusId', 'departmentUnitName'].concat(_visitServiceUtil.visitServiceIncludes)
      }).then(function (res) {
        let qp = res.get('firstObject');
        qp.set('modelEndProp', 'endDate');
        qp.set('modelEndMessage', 'Serve has been ended');
        qp.set('source', 'opd');
        return qp;
      });
    },

    actions: {
      refreshServeModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/admin-settings/department-management/department/ward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let dep = this.modelFor('hmis-app.admin-settings.department-management.department');
      return Ember.RSVP.hash({
        rooms: this.get('store').query('room', {
          filter: {
            wardId: params.record_id
          },
          include: ['wardName'],
          pageSize: 25
        }),
        departmentName: dep.departmentUnits.get('firstObject').departmentName
      });
    },

    actions: {
      reloadModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/settings-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2VaR+kHG",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[0,\"  \"],[1,[28,\"svg-jar\",[[24,[\"icon\"]]],[[\"class\",\"width\"],[\"sr-icon\",\"24px\"]]],false],[0,\"\\n\"],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"sr-label-wrapper\"],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"sr-label\"],[8],[1,[22,\"label\"],false],[0,\" \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"span\",true],[10,\"class\",\"line\"],[8],[9],[0,\"\\n\"],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/settings-row.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/bills/index", ["exports", "hmis/utils/model-table-theme", "ember-data"], function (_exports, _modelTableTheme, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    notify: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    app: Ember.inject.service(),
    selectedItems: [],
    transactRecord: [],
    allowSelect: true,
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    tabIndex: 0,
    fromBTDate: Ember.computed(function () {
      return new Date();
    }),
    toBTDate: Ember.computed(function () {
      return new Date();
    }),
    fromBVDate: Ember.computed(function () {
      return new Date();
    }),
    toBVDate: Ember.computed(function () {
      return new Date();
    }),
    isToday: true,
    isTodayBV: true,
    BTModel: Ember.computed('fromBTDate', 'toBTDate', 'isToday', 'isThisMonth', 'isDateRange', function () {
      let fromDate = this.get('fromBTDate');
      let toDate = this.get('toBTDate');
      this.get('app').startLoading();
      return _emberData.default.PromiseArray.create({
        promise: this.get('store').query('transaction', {
          filter: {
            isInsurance: 0,
            dateRange: {
              fromDate: fromDate.toISOString().slice(0, 19).replace('T', ' '),
              toDate: toDate.toISOString().slice(0, 19).replace('T', ' ')
            }
          }
        }).then(qp => {
          this.get('app').stopLoading();
          return qp;
        }, err => {
          this.get('app').stopLoading();
        })
      });
    }),
    BVModel: Ember.computed('fromBVDate', 'toBVDate', 'isTodayBV', 'isThisMonthBV', 'isDateRangeBV', function () {
      let fromDate = this.get('fromBVDate');
      let toDate = this.get('toBVDate');

      if (this.get('isLoadInit')) {
        this.get('app').startLoading();
      } else {
        this.set('isLoadInit', true);
      }

      return _emberData.default.PromiseArray.create({
        promise: this.get('store').query('visit', {
          filter: {
            dateRange: {
              fromDate: fromDate.toISOString().slice(0, 19).replace('T', ' '),
              toDate: toDate.toISOString().slice(0, 19).replace('T', ' ')
            },
            hasBill: true
          }
        }).then(qp => {
          if (this.get('isLoadInit')) this.get('app').stopLoading();
          return qp;
        }, err => {
          if (this.get('isLoadInit')) this.get('app').stopLoading();
        })
      });
    }),

    init() {
      this._super(...arguments);

      var fd = new Date();
      fd.setHours(0, 0, 0, 0);
      var td = new Date();
      td.setHours(24, 0, 0, 0);
      this.send('filterBVNow', fd, td);
      this.send('filterBTNow', fd, td);
    },

    actions: {
      payTransaction() {
        let transaction = this.get('transactRecord');
        this.get('ajaxRequest').payTransaction(transaction, {
          success: response => {
            this.notify.success('Payment successful');
            transaction.set('hasChange', !transaction.get('hasChange')); // that.get('store').findRecord('visit', visitId, {reload: true});
          },
          error: error => {
            this.notify.error('Sorry! Something went wrong');
          }
        });
      },

      filterBVNow(fromDate, toDate) {
        this.set('fromBVDate', fromDate);
        this.set('toBVDate', toDate);
      },

      filterBTNow(fromDate, toDate) {
        this.set('fromBTDate', fromDate);
        this.set('toBTDate', toDate);
      },

      rowBVClick(index, visit) {
        let rid = visit.get('id');
        this.get("routing").transitionTo("hmis-app.bills.bill", [rid]);
      },

      rowBTClick(index, record) {
        this.set('transactRecord', record);
        this.set('billModal', true);
      },

      clickTest() {
        alert(''); // this.get("routing").transitionTo("hmis-app.bills.bill");
      }

    },
    billAlertProps: [{
      title: 'Date of bill',
      name: 'dateF'
    }, {
      title: 'Patient',
      name: 'patient.fullName'
    }, {
      title: 'Trasaction Type',
      name: 'billableType'
    }, {
      title: 'Bill for',
      name: 'billableItemName'
    }, {
      title: 'Amount',
      name: 'amountF'
    }],
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      'title': 'Transaction time',
      "propertyName": "dateF",
      className: 'main-column'
    }, {
      title: 'Transaction type',
      propertyName: 'billableType'
    }, {
      title: 'Transaction item',
      propertyName: 'billableItemName'
    }, {
      title: 'Patient',
      propertyName: "patientName"
    }, {
      "propertyName": "paymentStatus"
    }],
    columns1: [{
      'title': 'Date of visit',
      "propertyName": "dateF",
      'className': 'main-column'
    }, {
      'title': 'Patient',
      'propertyName': 'patient.fullName'
    }, {
      'title': 'Purpose of visit',
      'propertyName': 'purposeName'
    }, {
      "title": "Visit status",
      "propertyName": "status.name"
    }]
  });

  _exports.default = _default;
});
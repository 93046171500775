define("hmis/components/stepper/sharing-stepper", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      saveModel() {
        console.log('save model');
      }

    }
  });

  _exports.default = _default;
});
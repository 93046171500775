define("hmis/templates/components/managers/procedure-type-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+2hwBSrJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"form-container\",null,[[\"class\"],[\"full-width\"]],{\"statements\":[[0,\"\\n  \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"genericName\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"genericName\"]]],null]],null],\"Generic Name\",\"person-outline\"]]],false],[0,\"\\n  \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"nhiaCode\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"nhiaCode\"]]],null]],null],\"NHIA Code\",\"person-outline\"]]],false],[0,\"\\n  \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"gdrgCode\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"gdrgCode\"]]],null]],null],\"G-DRG Code\",\"person-outline\"]]],false],[0,\"\\n  \"],[1,[28,\"paper-input\",null,[[\"class\",\"value\",\"onChange\",\"label\",\"icon\"],[[24,[\"flex_level\"]],[24,[\"record\",\"price\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"price\"]]],null]],null],\"Price (GHS)\",\"person-outline\"]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/procedure-type-manager.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/table-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ERaFbb9i",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[4,\"alerts/prompt-dialog\",null,[[\"show\",\"title\",\"textArea\",\"message\",\"value\",\"confirmDisabled\",\"disabled\",\"onConfirm\"],[[24,[\"isOpen\"]],[24,[\"column\",\"propertyName\"]],[24,[\"column\",\"textArea\"]],\"Enter value\",[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null],[24,[\"disabled\"]],[24,[\"disabled\"]],[28,\"action\",[[23,0,[]],\"onConfirm\"],null]]],{\"statements\":[],\"parameters\":[]},null],[4,\"if\",[[24,[\"readOnly\"]]],null,{\"statements\":[[0,\"        \"],[7,\"p\",true],[8],[1,[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"unless\",[[24,[\"readOnly\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"table-inline-edit\"],[8],[0,\"\\n            \"],[1,[28,\"input\",null,[[\"disabled\",\"class\",\"type\",\"required\",\"value\",\"min\",\"input\"],[[24,[\"disabled\"]],[24,[\"cancatinatedInputClasses\"]],[24,[\"type\"]],[24,[\"required\"]],[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null],[24,[\"min\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]]],false],[0,\"\\n            \"],[7,\"div\",true],[10,\"class\",\"tie-open\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"onOpen\"],null]],[8],[7,\"i\",true],[10,\"class\",\"fa fa-external-link\"],[8],[9],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/table-editable.hbs"
    }
  });

  _exports.default = _default;
});
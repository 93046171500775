define("hmis/routes/hmis-app/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    setupController(controller, post) {
      this._super(controller, post);

      this.set('currentUser.dashboardCount', (this.get('currentUser.dashboardCount') || 0) + 1);
      this.loadDepartment(controller);
    },

    currentUser: Ember.inject.service(),
    session: Ember.inject.service('session'),
    appAjax: Ember.inject.service(),
    // ajaxRequest: Ember.inject.service(''),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),

    model(params) {
      let oPerms = this.get('oPerms');
      return Ember.RSVP.hash({
        activeServes: (oPerms.op14 ? oPerms.op14.allow : false) && this.get('currentUser.accessLevel') == 2 ? this.get('store').query('active-serve', {
          filter: {
            served: 0
          },
          include: ['patientName']
        }) : []
      });
    },

    loadDepartment(controller) {
      controller.set('loadingMyDepartment', true);
      controller.set('myDeparment', null);
      this.appAjax.ajaxGet('users/myDepartment', {}).then(res => {
        controller.set('myDeparment', res);
        controller.set('loadingMyDepartment', false);
      }).catch(err => {
        controller.set('myDeparment', null);
        controller.set('loadingMyDepartment', false);
      });
    }

  });

  _exports.default = _default;
});
define("hmis/models/ipd-care", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util"], function (_exports, _emberData, _relationships, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  var _default = Model.extend({
    // patient:belongsTo('patient'), 
    user: (0, _relationships.belongsTo)('user', {
      async: false
    }),
    // visit:belongsTo('visit'), 
    visitId: _emberData.default.attr(),
    careType: _emberData.default.attr(),
    value: _emberData.default.attr(),
    note: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    dateF: Ember.computed('date', function () {
      return _dateUtil.default.formatToDateString(this.get('createdAt'));
    }) // admin: computed('userId', function() {
    //     let usr = this.get('store').findRecord('user', this.get('userId'), {reload: true})
    //     .then(function(rec){
    //         console.log('model rec',rec.fullName)
    //         //usr = rec.fullName
    //         return rec
    //     });
    //     console.log('usr',usr);
    //     // let fv =  usr.then(function(v){
    //     //     return v
    //     // })
    //     return usr
    // }),

  });

  _exports.default = _default;
});
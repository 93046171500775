define("hmis/utils/common-data-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.eventsColumns = _exports.episodeColumns = _exports.visitsColumns = _exports.diagnosisSubTypePickerColumns = _exports.departmentUnitPickerColumns = _exports.roomPickercolumns = _exports.wardPickerColumns = _exports.diagnosisColumns = _exports.patientSelectableColumns = _exports.patientPickerColumns = void 0;
  const patientPickerColumns = [{
    "component": "controls/select-row-checkbox",
    "useFilter": false,
    "mayBeHidden": false
  }, {
    //title: 'Id',
    "propertyName": "folderNumber"
  }, {
    "propertyName": "title"
  }, {
    "propertyName": "fullName",
    component: 'controls/table-link',
    route: 'hmis-app.records.patient',
    permissions: ['op1.view']
  }, {
    "propertyName": "patientStatus"
  }, {
    "propertyName": "age"
  }, {
    "propertyName": "genderName",
    title: 'Gender'
  }, {
    "propertyName": "physicalAddress"
  }, {
    "propertyName": "phoneNumber"
  }];
  _exports.patientPickerColumns = patientPickerColumns;
  const patientSelectableColumns = patientPickerColumns.concat([{
    "title": "Actions",
    "component": "manageRow"
  }]);
  _exports.patientSelectableColumns = patientSelectableColumns;
  const diagnosisColumns = [{
    'title': 'Date',
    "propertyName": "dateTimeF",
    "editable": false
  }, {
    'title': 'Diagnosis',
    "propertyName": "diagnosisTypeName"
  }, {
    propertyName: 'note'
  }];
  _exports.diagnosisColumns = diagnosisColumns;
  const wardPickerColumns = [{
    "component": "controls/select-row-checkbox",
    "useFilter": false,
    "mayBeHidden": false
  }, {
    'title': 'Ward Name',
    "propertyName": "name"
  }];
  _exports.wardPickerColumns = wardPickerColumns;
  const roomPickercolumns = [{
    "component": "controls/select-row-checkbox",
    "useFilter": false,
    "mayBeHidden": false
  }, {
    'title': 'Room',
    "propertyName": "name"
  }];
  _exports.roomPickercolumns = roomPickercolumns;
  const departmentUnitPickerColumns = [{
    "component": "controls/select-row-checkbox",
    "useFilter": false,
    "mayBeHidden": false
  }, {
    'title': 'Department Unit',
    "propertyName": "name"
  }, {
    "title": "Service Offered",
    "propertyName": "serviceName"
  }];
  _exports.departmentUnitPickerColumns = departmentUnitPickerColumns;
  const diagnosisSubTypePickerColumns = [{
    "component": "controls/select-row-checkbox",
    "useFilter": false,
    "mayBeHidden": false
  }, {
    'title': 'Diagnosis (ICD10)',
    "propertyName": "displayName"
  }, {
    "title": "Diagnosis type",
    "propertyName": "diagnosisTypeName"
  }, {
    "title": "Major diagnosis group",
    "propertyName": "diagnosisMajorGroupName"
  }];
  _exports.diagnosisSubTypePickerColumns = diagnosisSubTypePickerColumns;
  const visitsColumns = [{
    title: 'Purpose',
    "propertyName": "purposeName"
  }, {
    title: 'Date of Visit',
    propertyName: 'visitDateF',
    className: 'vsv-date-col',
    component: 'controls/table-link',
    formatText: (text, record) => record.dateF,
    viewer: 'visit',
    viewerOptions: record => record
  }];
  _exports.visitsColumns = visitsColumns;
  const episodeColumns = [{
    'title': 'First visit date',
    "propertyName": "firstVisitDateF",
    "editable": false
  }, {
    'title': 'Last visit date',
    "propertyName": "lastVisitDateF",
    "editable": false
  }, {
    'title': 'Primary Diagnosis',
    "propertyName": "primaryDiagnosisNameF"
  }, {
    title: 'Visits',
    propertyName: 'visitsCount',
    className: 'vsv-date-col',
    component: 'controls/table-link',
    formatText: (text, record) => record.visitsCount + " visit" + (record.visitsCount > 1 ? 's' : ''),
    viewer: 'serverTable',
    viewerOptions: record => {
      return {
        title: "Event visits ",
        modelName: 'visit',
        query: {
          filter: {
            episodeId: record.id
          },
          include: ['purposeName']
        },
        columns: visitsColumns
      };
    }
  }];
  _exports.episodeColumns = episodeColumns;
  const eventsColumns = [{
    'title': 'First visit date',
    "propertyName": "firstVisitDateF",
    "editable": false
  }, {
    'title': 'Last visit date',
    "propertyName": "lastVisitDateF",
    "editable": false
  }, {
    'title': 'Primary Diagnosis',
    "propertyName": "primaryDiagnosisNameF"
  }, {
    title: 'Episode',
    propertyName: 'episodesCount',
    className: 'vsv-date-col',
    component: 'controls/table-link',
    formatText: (text, record) => record.episodesCount + " episode" + (record.episodesCount > 1 ? 's' : ''),
    viewer: 'serverTable',
    viewerOptions: record => {
      return {
        title: "Event Episodes ",
        modelName: 'visit-episode',
        query: {
          filter: {
            eventId: record.id
          },
          include: ['visitsCount']
        },
        columns: episodeColumns
      };
    }
  }, {
    title: 'Visits',
    propertyName: 'visitsCount',
    className: 'vsv-date-col',
    component: 'controls/table-link',
    formatText: (text, record) => record.visitsCount + " visit" + (record.visitsCount > 1 ? 's' : ''),
    viewer: 'serverTable',
    viewerOptions: record => {
      return {
        title: "Event visits ",
        modelName: 'visit',
        query: {
          filter: {
            eventId: record.id
          },
          include: ['purposeName']
        },
        columns: visitsColumns
      };
    }
  }];
  _exports.eventsColumns = eventsColumns;
});
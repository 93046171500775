define("hmis/components/controls/progress-chart", ["exports", "ember-data", "hmis/mixins/high-chart-manager", "hmis/utils/model-table-theme"], function (_exports, _emberData, _highChartManager, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    tabStatus: 0,
    vitalBatch: Ember.computed('model', function () {
      let mod = this.get('model') ? this.get('model') : [];
      return this.get('store').query('vital-batch', {
        filter: {
          visit: mod.visit.get('id')
        }
      }, {
        reload: true
      }).then(function (recs) {
        let s_recs = recs.toArray().sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
        let gropVital = [];
        s_recs.forEach(rec => {
          rec.vitals.forEach(vit => {
            gropVital.pushObject(vit);
          });
        });
        let gvitals = gropVital.reduce((result, currentValue) => {
          //grouping vitals
          (result[currentValue['vitalTypeName']] = result[currentValue['vitalTypeName']] || []).push(currentValue);
          return result;
        }, {});
        let strVitals = [];
        Object.entries(gvitals).forEach(([key, value]) => {
          // chart data struct
          let vCat = [];
          let vArr = [];
          let vData = [];
          let vlabel = key;
          let mUnit = '';
          let nm = [];
          let dm = [];
          let bpVal = '';
          let label = '';
          value.forEach(dat => {
            mUnit = dat.unitOfMeasure.name;
            vCat.push(dat.batch.get('dateF'));

            if (dat.vitalType.get('id') === '3') {
              bpVal = dat.vitalType.get('id');
              let tempVal = dat.value.split("/");
              nm.push(Math.floor(tempVal[0]));
              dm.push(Math.floor(tempVal[1]));
            } else {
              vArr.push(Math.floor(dat.value));
            }
          });
          let dset = {};

          if (bpVal === '3') {
            dset = [{
              data: nm
            }, {
              data: dm
            }];
          } else {
            dset = [{
              data: vArr
            }];
          }

          vData = dset;
          console.log('mUnit', mUnit);
          label = vlabel + ' (' + mUnit + ')';
          strVitals.pushObject({
            label,
            vCat,
            vData
          });
        });
        return strVitals;
      });
    }),
    bpData: Ember.computed('chartOptions', 'vitalBatch', function () {
      let vitals = this.get('vitalBatch');
      let vChart = [];
      vitals.then(function (v) {
        v.forEach(vital => {
          let options = {
            chart: {
              height: 200,
              type: 'line'
            },
            title: {
              text: null
            },
            xAxis: {
              categories: vital.vCat
            },
            yAxis: {
              title: {
                text: vital.label
              }
            },
            legend: {
              enabled: false
            },
            exporting: {
              enabled: false
            },
            plotOptions: {
              line: {
                dataLabels: {
                  enabled: true
                },
                enableMouseTracking: false
              }
            }
          };
          let chartData = vital.vData;
          vChart.pushObject({
            options,
            chartData
          });
        });
      });
      return vChart;
    }),
    careList: Ember.computed('model', function () {
      let md = this.get('model');
      let pt = md.patientId ? md.patientId : null;
      let vt = md.visit.get('id') ? md.visit.get('id') : null;
      return this.get('store').query('ipd-care', {
        filter: {
          patientId: pt,
          visitId: vt
        }
      });
    }),
    actions: {
      onNewTabClick() {},

      onHistoryTabClick() {},

      rowClick() {}

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      "title": "Administered",
      "propertyName": "value"
    }, {
      "title": "Type",
      "propertyName": "careType"
    }, {
      "title": "Time",
      "propertyName": "dateF"
    }, {
      "title": "Note",
      "propertyName": "note"
    }, {
      "title": "Administered By",
      "propertyName": "user.fullName"
    }]
  });

  _exports.default = _default;
});
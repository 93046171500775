define("hmis/templates/components/list-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "S7H1kZkK",
    "block": "{\"symbols\":[\"pagedList\",\"filteredList\",\"&default\"],\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"style\",\"height: 0px\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"list\"],[8],[0,\"\\n\"],[4,\"list-filter-no-elem\",null,[[\"data\",\"query\",\"filterParams\",\"pageIndex\",\"pageLimit\"],[[24,[\"data\"]],[24,[\"query\"]],[24,[\"filterParams\"]],[24,[\"pageIndex\"]],[24,[\"pageLimit\"]]]],{\"statements\":[[0,\"        \"],[14,3,[[23,1,[]],[23,2,[]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/list-filter.hbs"
    }
  });

  _exports.default = _default;
});
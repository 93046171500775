define("hmis/templates/components/controls/select-row-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DYAwpXmF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"isSelected\"]],[24,[\"themeInstance\",\"select-row\"]],[24,[\"themeInstance\",\"deselect-row\"]]],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"clickOnRow\",[24,[\"index\"]],[24,[\"record\"]]],null]],[8],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/select-row-checkbox.hbs"
    }
  });

  _exports.default = _default;
});
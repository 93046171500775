define("hmis/components/forms/threshold-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    modelName: 'threshold',
    opdInfinite: Ember.computed('record.opdInfinite', function () {
      return this.get('record.opdInfinite');
    }),
    ipdInfinite: Ember.computed('record.ipdInfinite', function () {
      return this.get('record.ipdInfinite');
    }),
    actions: {
      opdInfinite(record) {
        if (record) {
          this.set('opdInfinite', true);
          this.set('record.opdInfinite', 1);
        } else {
          this.set('opdInfinite', false);
          this.set('record.opdInfinite', 0);
        }

        console.log('record', record);
        console.log('record opdInfinite', this.get('record.opdInfinite'));
      },

      ipdInfinite(record) {
        if (record) {
          this.set('ipdInfinite', true);
          this.set('record.ipdInfinite', 1);
        } else {
          this.set('ipdInfinite', false);
          this.set('record.ipdInfinite', 0);
        }

        console.log('record', record);
        console.log('record ipdInfinite', this.get('record.ipdInfinite'));
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/facility-management/facility/general", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    selectedItems: [],
    allowSelect: true,
    data: Ember.computed.alias('model'),
    // berries: (function() {
    //   this.get('data').filterProperty('type', 'berry');
    // }).property('content.@each.type'),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    selectedCount: Ember.computed("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    deactFac: [],
    onConfirm: 'deleteRecord',
    actions: {
      back() {
        history.back();
      },

      edit() {
        var record = this.get("model");
        this.set('editRecord', record);
        this.set('editType', 'edit');
        this.set('editRecordDialog', true);
      },

      deleteRecord(record) {
        console.log(record);
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to deactivate a facility (' + record.get('facilityName') + ')');
        this.set('onAlert', true);
      },

      deleteNow(recordTag) {
        this.get("selectedItems").removeObject(recordTag);
        recordTag.destroyRecord();
      },

      editRecord(record) {
        this.get("routing").transitionTo("hmis-app.facility-management.edit-facility", [record.id]);
      },

      rowDoubleClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.facility-management.facility", [rid]);
      },

      deleteRecords() {
        let pCount = parseInt(this.get("selectedItems").length);
        this.set('onConfirm', 'deleteRecordsNow');
        this.set('alertTitle', 'Attempting to delete ' + pCount + ' facilit' + (pCount > 1 ? 'ies' : 'y'));
        this.set('onAlert', true);
      },

      deleteRecordsNow() {
        this.get("selectedItems").forEach((patient, index) => {
          patient.destroyRecord();
        });
        this.set("selectedItems", []);
      },

      activateRecords() {
        let pCount = parseInt(this.get("selectedItems").length);
        this.set('onConfirm', 'activateRecordsNow');
        this.set('alertTitle', 'Attempting to activate ' + pCount + ' user' + (pCount > 1 ? 's' : ''));
        this.set('onAlert', true);
      },

      activateRecordsNow() {
        this.get("selectedItems").forEach((record, index) => {
          record.set('deleteStatus', 0);
          record.save();
        });
        this.set("selectedItems", []);
      },

      activeDeleteRecord(record) {
        // record.set('deleteStatus',1);
        // record.save()
        // // console.log(record)
        // console.log('activated')
        let that = this;
        this.get('ajaxRequest').activateDeactivatedFacility(record.id, {
          success: function success() {
            that.set('deactivatedModal', false);
            that.notify.success('Facility Activated Successfully');
            that.send('refreshModel'); //that.get('currentModel').reload()
          },
          error: function error(err) {
            that.notify.error('Unable to save');
          }
        });
      },

      viewDeletedFacilities() {
        let that = this;
        this.get('ajaxRequest').getDeactivatedFacilities({
          success: function success(response) {
            console.log(response);
            that.set('deactFac', response);
          },
          error: function error(_error) {
            return _error;
          }
        });
        this.set('deactivatedModal', true);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/
        ,
        noDataCell: 'no-data-cell'
      });
    }),
    columns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false
    }, {
      "title": "facility Type",
      "propertyName": "typeName",
      className: 'main-column'
    }, {
      "propertyName": "facilityName",
      className: 'main-column'
    }, {
      "propertyName": "postAddress"
    }, {
      "propertyName": "physicalAddress"
    }, {
      "propertyName": "contactEmail"
    }, {
      "propertyName": "phoneNumber"
    }, // {
    //   "propertyName": "alternativePhoneNumber"
    // },
    // {
    //   "propertyName": "contactPerson1Name"
    // },
    // {
    //   "propertyName": "contactPerson1PhoneNumber"
    // },
    // {
    //   "propertyName": "contactPerson1Email"
    // },
    // {
    //   "propertyName": "contactPerson2Name"
    // },
    {
      "title": "Actions",
      "component": "manageRow"
    }],
    columns1: [// {
    //   "component": "controls/select-row-checkbox",
    //   "useFilter": false,
    //   "mayBeHidden": false,
    // },
    // {
    //   "title": "Facility Type",
    //   "propertyName": "typeName",
    //   className:'main-column'
    // },
    {
      "propertyName": "facilityName",
      className: 'main-column'
    }, {
      "propertyName": "physicalAddress"
    }, {
      "propertyName": "contactEmail"
    }, {
      "propertyName": "phoneNumber"
    }, {
      "title": "Actions",
      "component": "manageRow"
    }],
    properties: [{
      title: 'Logo',
      name: 'pictureUrl',
      type: 'picture',
      class: 'flex-100'
    }, {
      title: 'Facility Type',
      name: 'facilityType.name'
    }, {
      title: 'Facility Level Code',
      name: 'facilityLevelCode'
    }, {
      title: 'facility Name',
      name: 'facilityName'
    }, {
      name: 'postAddress'
    }, {
      name: 'physicalAddress'
    }, {
      name: 'contactEmail'
    }, {
      name: 'phoneNumber'
    }, {
      name: 'alternativePhoneNumber'
    }, {
      name: 'contactPerson1Name'
    }, {
      name: 'contactPerson1Email'
    }, {
      name: 'contactPerson1PhoneNumber'
    }, {
      title: 'contactPerson1AlternativePhoneNumber',
      name: ''
    }, {
      name: 'contactPerson2Name'
    }, {
      name: 'contactPerson2Email'
    }, {
      name: 'contactPerson2PhoneNumber'
    }, {
      name: 'contactPerson2AlternativePhoneNumber'
    }, {
      name: 'contactPerson3Name'
    }, {
      name: 'contactPerson3Email'
    }, {
      name: 'contactPerson3PhoneNumber'
    }, {
      name: 'contactPerson3AlternativePhoneNumber'
    }, // {
    //   title:'servicesOffered',
    //   name:'servicesOffered',
    //   type:'array',
    //   arrayKey:'name'
    // },
    {
      name: 'numberOfBeds'
    }, {
      name: 'insurances',
      title: 'insurances',
      type: 'array',
      arrayKey: 'insuranceProduct.name'
    }, // {
    //   name:'professions',
    //   title:'professions',
    //   type:'array',
    //   arrayKey:'name'
    // },
    {
      name: 'region'
    }, {
      name: 'town'
    }, {
      title: 'TIN Number',
      name: 'tin'
    }, {
      title: 'Ghana Post GPS',
      name: 'gps'
    }, {
      name: 'accreditationNumber'
    }, {
      title: 'denomination',
      name: 'denomination.name'
    }]
  });

  _exports.default = _default;
});
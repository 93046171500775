define("hmis/controllers/hmis-app/opd/consultation/consult", ["exports", "hmis/mixins/visit-care-controller", "hmis/utils/general-util", "hmis/constants/referral-types"], function (_exports, _visitCareController, _generalUtil, _referralTypes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_visitCareController.default, {
    router: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    app: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    data: Ember.inject.service(),
    appData: Ember.inject.service(),
    notify: Ember.inject.service(),
    consultationService: Ember.inject.service('consultation'),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    patientName: Ember.computed('model.patientName', function () {
      return this.get('model.patientName');
    }),
    visitOutcomes: Ember.computed(function () {
      return this.get('store').findAll('visit-outcome');
    }),
    bills: Ember.computed('model.visit', 'model.visit.status', function () {
      return this.get('store').query('transaction', {
        filter: {
          visit: this.model.visitId,
          isInsurance: 0
        }
      });
    }),
    outstandingBills: Ember.computed('bills', 'bills.@each.isPaid', function () {
      return this.get('bills').filterBy('isPaid', false);
    }),
    hasOutstandingBills: Ember.computed('outstandingBills.length', function () {
      return this.get('outstandingBills').length ? true : false;
    }),
    departmentUnit: Ember.computed('model', function () {
      return this.get('store').findAll('department-unit');
    }),
    disableServeMessage: '',
    notValidForAdmissionRequest: Ember.computed('model.visitStatusId', function () {
      if (this.get('model.visitStatusId') != 1) return true;
      if (this.get('disableServe')) return true;
      return false;
    }),
    disableDischarge: Ember.computed('model.visitStatusId', function () {
      if (this.get('model.visitStatusId') != 3) return true;
      if (this.get('disableServe')) return true;
      return false;
    }),
    title: 'Patient',
    input_width: 'flex-45',
    label: 'Change Department',
    onConfirm: 'empty',
    onCancel: 'empty',
    refUploadFiles: [],
    rescheduleDate: {},
    admissionType: null,
    admissionTypes: [{
      code: 'CRO',
      name: 'CRO (Chronic Follow-up)'
    }, {
      code: 'EME',
      name: 'EME (Emergency)'
    }, {
      code: 'ACU',
      name: 'ACU (Acute Episode)'
    }],
    actions: {
      empty() {},

      changeServiceDepartment() {
        this.set('findRecord', true);
      },

      chooseConfirm(record) {
        this.send('setRecord', record);
        this.set('findRecord', false);
      },

      onAutocompleteChange(record) {
        this.send('setRecord', record);
      },

      // changeDeptUnit(){
      //   this.data.serverTable({
      //     modelName: 'departmentUnit',columns: departmentUnitPickerColumns, title: 'Pick a Department unit',
      //     query: { filter:{ isConsultation:1 }, include:['serviceName'] }
      //   }, deptUnit=>{
      //     this.notify.confirm({message: "This will change the department unit tied to the patient's visit"}, ()=>{
      //     })
      //   })
      // },
      showVisitSummary() {
        this.visitViewer.show((0, _generalUtil.visitServiceToVisitData)(this.model));
      },

      showThresholdSummary() {
        this.set('thresholdTitle', this.get('model.patientName') + "'s Threshold");
        this.set('thresholdDialog', true);
      },

      takeVitals() {
        this.vitalsTaker.show((0, _generalUtil.visitServiceToVisitData)(this.model), null, res => {});
      },

      rescheduleServe() {
        this.appointmentManager.rescheduleActiveServe(this.model, appointment => {
          history.back();
        });
      },

      admitRequestClick() {
        this.set('onAdmitAlert', true);
      },

      admitRequestConfirm(value) {
        if (!this.get('admissionType')) {
          this.set('onAdmitAlert', true);
          this.notify.error('please enter admission type');
          return;
        }

        this.notify.confirmSimple('Requesting to admit ' + this.get('model.patientName'), null, () => {
          this.send('sendAdmitRequest');
        });
      },

      sendAdmitRequest() {
        this.set('requestingAdmission', true);
        this.app.startLoading();
        let data = {
          'visitId': this.model.visitId,
          'admissionNote': this.admitNote,
          'admissionType': this.admissionType.code
        };
        this.appAjax.post("/requestAdmission/" + this.model.visitId, data).then(res => {
          this.notify.success("Admission Request sent");
          this.set('requestingAdmission', false);
          this.set('admissionType', null);
          this.set('admitNote', null);
          this.app.stopLoading();
          this.send('reloadModel');
        }).catch(error => {
          this.app.stopLoading();
          this.set('requestingAdmission', false);
          this.notify.error(error);
        });
      },

      back() {
        history.back(); // this.get("router").transitionTo("hmis-app.consultation");
      },

      onRefFileAdded(file) {
        this.set('refUploadFiles', []);
        this.get('refUploadFiles').pushObject(file);
      },

      referPatient() {
        this.set('refering', true);
        let options = {
          visitId: this.model.visitId,
          referrableType: _referralTypes.REFERRABLE_TYPES.ACTIVE_SERVE,
          referrableId: this.model.id,
          source: this.model.source
        };
        this.referralAlert.show(options, res => {
          this.send('refreshServeModel');
        }, () => {
          this.set('refering', false);
        });
      },

      reassigToDoctor(doctor) {
        this.set('reassigning', true);
        this.notify.confirmSimple('Confirm!', "Do you want to continue to assign patient to Dr. ".concat(doctor.firstName, " ").concat(doctor.lastName, " ?"), () => {
          this.appAjax.post('/reassignActiveServe/' + this.get('model.id') + "/" + doctor.id, null).then(data => {
            this.notify.success("Dr. ".concat(doctor.firstName, " ").concat(doctor.lastName, " is now serving ").concat(this.get('model.patientName')));
            this.router.transitionTo('hmis-app.opd.consultation');
          }, err => this.notify.error(err)).finally(() => {
            this.set('reassigning', false);
          });
        });
      },

      onMenuClick(route, title) {
        try {
          this.get("router").transitionTo(route);
          this.set('title', title);
        } catch (err) {
          console.error(err);
        }
      },

      showVisitTransactionsForView() {
        this.visitTransViewer.show((0, _generalUtil.visitServiceToVisitData)(this.model), tVisit => {});
      },

      endServe() {
        this.set('endingServe', true);
        this.notify.confirmSimple('About to end serve for ' + this.model.patientName, null, () => {
          this.appAjax.post('activeServes/' + this.model.id + '/endServe').then(response => {
            this.set('endingServe', false);
            this.notify.success('Serve ended');
            history.back();
          }).catch(error => {
            this.set('endingServe', false);
            this.notify.error(error);
          });
        }, () => {
          this.set('endingServe', false);
        });
      },

      recheduleSuccess(appointment, endedActiveServe) {
        history.back();
      },

      vitalsSuccessAction() {
        this.get('consultationService').vitalsChanged();
      }

    },
    docsColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false //   "componentForSortCell": "select-all-rows-checkbox"

    }, {
      "propertyName": "firstName"
    }, {
      "propertyName": "lastName"
    }],
    columns: [{
      title: 'Unit',
      "propertyName": "name"
    }, {
      title: 'Department',
      "propertyName": "department.name"
    }]
  });

  _exports.default = _default;
});
define("hmis/utils/date-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isValidDate = isValidDate;
  _exports.dateToAgeInYears = dateToAgeInYears;
  _exports.dateToAgeString = dateToAgeString;
  _exports.formatToTimeString = formatToTimeString;
  _exports.formatToDateString = formatToDateString;
  _exports.formatToDateTimeString = formatToDateTimeString;
  _exports.toSqlDate = toSqlDate;
  _exports.toSqlDateOnly = toSqlDateOnly;
  _exports.stringTimeDifference = stringTimeDifference;
  _exports.default = void 0;

  function isValidDate(d) {
    return d instanceof Date && !isNaN(d);
  }

  function dateToAgeInYears(_date) {
    return moment().diff(_date, 'years');
  }

  function dateToAgeString(date) {
    // var date = _date;
    var str = "year";
    var age = moment().diff(date, 'years');

    if (age < 1) {
      age = moment().diff(date, 'months');
      str = 'month';

      if (age < 1) {
        age = moment().diff(date, 'weeks');
        str = 'week';

        if (age < 1) {
          age = moment().diff(date, 'days');
          str = 'day';
        }
      }
    }

    return "".concat(age, " ").concat(str).concat(age > 1 ? 's' : '');
  }

  function formatToTimeString(date) {
    let somDate = new Date(date);
    var hours = somDate.getHours();
    var minutes = somDate.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    minutes = ('0' + minutes).slice(-2);
    var strTime = hours + ':' + minutes + ' ' + ampm;

    if (dateDiffInDays(somDate, new Date()) == 0) {
      //if today's date
      let today = new Date();
      var diff = today - somDate;
      var msec = diff;
      var hh = Math.floor(msec / 1000 / 60 / 60);
      msec -= hh * 1000 * 60 * 60;
      var mm = Math.floor(msec / 1000 / 60);
      msec -= mm * 1000 * 60;
      var ss = Math.floor(msec / 1000);
      msec -= ss * 1000; // let mDiff = today.getMinutes() - somDate.getMinutes()

      if (hh == 0 && mm == 0) {
        strTime = 'now';
      } else if (hh < 5) {
        if (hh == 0) {
          if (mm > 0) {
            strTime = mm + ' ' + pluralise('min', Math.abs(mm) > 0) + ' ago';
          } else {
            strTime = Math.abs(mm) + ' ' + pluralise('min', Math.abs(mm) > 0) + ' more';
          }
        } else if (hh > 0) {
          //hours ago
          strTime = hh + ' ' + pluralise('hr', Math.abs(hh) > 0) + ' ' + mm + ' ' + pluralise('min', Math.abs(mm) > 0) + ' ago';
        } else if (hh < 0) {
          strTime = Math.abs(hh) + ' ' + pluralise('hr', Math.abs(hh) > 0) + ' ' + Math.abs(mm) + ' ' + pluralise('min', Math.abs(mm) > 0) + ' more';
        }
      }
    }

    return strTime;
  }

  function pluralise(text, plural) {
    return plural ? text + 's' : text;
  }

  var _MS_PER_DAY = 1000 * 60 * 60 * 24; // a and b are javascript Date objects


  function dateDiffInDays(a, b) {
    // Discard the time and time-zone information.
    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor((utc2 - utc1) / _MS_PER_DAY);
  }

  function formatToDateString(date) {
    // let somDate = new Date(date)
    // let dString =somDate.toDateString();
    // let dDiff = dateDiffInDays(somDate , new Date());
    //
    // if(dDiff ==-1)
    // {
    //   dString = 'Tomorrow'
    // }
    // else if(dDiff==0)
    // {
    //   dString = 'Today'
    // }else if(dDiff==1){
    //   dString = 'Yesterday'
    // }else if(dDiff >1 && dDiff <7)
    // {
    //   dString = dDiff +' days ago'
    // }
    //
    // return `${dString}`
    return moment(date).calendar();
  }

  function formatToDateTimeString(date) {
    return moment(date).calendar(); // return moment(date).calendar(null, {
    //   sameDay: '[Today]',
    //   nextDay: '[Tomorrow]',
    //   nextWeek: 'dddd',
    //   lastDay: '[Yesterday]',
    //   lastWeek: '[Last] dddd',
    //   sameElse: 'DD-MM-YYYY'
    // });
    // return `${formatToDateString(date)}. ${formatToTimeString(date)}`
  }

  function toSqlDate(date) {
    return date.toISOString().slice(0, 19).replace('T', ' ');
  }

  function toSqlDateOnly(date) {
    let somDate = new Date(date);
    return somDate.getFullYear() + '-' + (somDate.getMonth() + 1) + '-' + somDate.getDate(); // return '2018-05-29'
  }

  function toSeconds(time_str) {
    if (!(typeof time_str === 'string' || time_str instanceof String)) return 0; // Extract hours, minutes and seconds

    let parts = time_str.split(':'); // return parts[0]
    // compute  and return total seconds

    return parts[0] * 3600 + // an hour has 3600 seconds
    parts[1] * 60 + // a minute has 60 seconds
    +(parts[2] ? parts[2] : 0); // seconds
  }

  function stringTimeDifference(a, b) {
    let difference = Math.abs(toSeconds(a) - toSeconds(b)); // compute hours, minutes and seconds

    let result = [// an hour has 3600 seconds so we have to compute how often 3600 fits
    // into the total number of seconds
    Math.floor(difference / 3600), // HOURS
    // similar for minutes, but we have to "remove" the hours first;
    // this is easy with the modulus operator
    Math.floor(difference % 3600 / 60), // MINUTES
    // the remainder is the number of seconds
    difference % 60 // SECONDS
    ]; // return Math.floor(difference % 60 );

    let hrs = Math.floor(difference / 3600);
    let min = Math.floor(difference % 3600 / 60);
    let sec = difference % 60;
    return "".concat(hrs > 0 ? hrs + (hrs == 1 ? ' hr' : ' hrs') : '', " ").concat(min > 0 ? min + (min == 1 ? ' min' : ' mins') : '', " ").concat(sec > 0 ? sec + (sec == 1 ? ' sec' : ' secs') : '');
  }

  var _default = {
    formatToDateString: formatToDateString,
    formatToDateTimeString: formatToDateTimeString,
    formatToTimeString,
    toSqlDate,
    toSqlDateOnly,
    stringTimeDifference
  };
  _exports.default = _default;
});
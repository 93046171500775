define("hmis/controllers/hmis-app/reports/visits/visit-summary", ["exports", "hmis/utils/download-util", "hmis/utils/model-table-theme"], function (_exports, _downloadUtil, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    ob: Ember.observer('model', function () {
      this.send('filterToday');
    }),
    isToday: true,
    actions: {
      back() {
        history.back();
      },

      onDateFilterSubmit(fromDate, toDate) {
        this.send('filterNow', fromDate, toDate);
        this.set('isToday', false);
        this.set('isThisMonth', false);
      },

      filterToday() {
        var fd = new Date();
        fd.setHours(0, 0, 0, 0);
        var td = new Date();
        td.setHours(24, 0, 0, 0);
        this.send('filterNow', fd, td);
        this.set('isToday', true);
        this.set('isThisMonth', false);
        this.set('dateFilterStatus', false);
      },

      filterThisMonth() {
        let date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        let fd = new Date(y, m, 1);
        let td = new Date(y, m + 1, 0);
        fd.setHours(0, 0, 0, 0);
        td.setHours(24, 0, 0, 0);
        this.send('filterNow', fd, td);
        this.set('isToday', false);
        this.set('isThisMonth', true);
        this.set('dateFilterStatus', false);
      },

      filterNow(fromDate, toDate) {
        this.set('fromDate', fromDate);
        this.set('toDate', toDate);
        let visit = this.get('model.visit');
        let that = this;
        let filter = {
          visit: visit.get('id'),
          dateRange: {
            fromDate: fromDate.toISOString().slice(0, 19).replace('T', ' '),
            toDate: toDate.toISOString().slice(0, 19).replace('T', ' ')
          }
        };
        this.send('queryForSummary', this, 'vital-batch', 'vitalsSummary', filter);
        this.send('queryForSummary', this, 'symptom', 'symptomsSummary', filter);
        this.send('queryForSummary', this, 'diagnosis-batch', 'diagnosesSummary', filter);
        this.send('queryForSummary', this, 'prescription-batch', 'prescriptionsSummary', filter);
        this.send('queryForSummary', this, 'procedure', 'proceduresSummary', filter);
        this.send('queryForSummary', this, 'investigation', 'investigationsSummary', filter);
      },

      queryForSummary(context, model, tableData, filter, then) {
        context.get('store').query(model, {
          filter: filter
        }).then(function (qp) {
          context.set(tableData, qp);
        });
      },

      moveToClaims() {
        let that = this;
        let patient = this.get('model').get('visit').get('patient');
        let insuranceProduct = patient.get('insuranceProduct');
        let insuranceId = insuranceProduct.get('id');
        console.log('insurance', insuranceId);

        if (!insuranceId) {
          alert('Patient has no insurance product\n Please set that first');
          return;
        }

        this.get('model').set('modeOfPayment', 'Insurance');
        this.get('model').save().then(function (record) {
          let insuranceType = record.get('insuranceType');

          if (insuranceType.get('id') == 1) //government
            {
              that.get("routing").transitionTo("hmis-app.claims.government", [record.get('id')]);
            } else {
            that.get("routing").transitionTo("hmis-app.claims.private", [record.get('id')]);
          }
        });
      },

      print() {
        _downloadUtil.default.printBill(this.get('model.id'));
      },

      exportToPdf() {
        _downloadUtil.default.exportBillToPDF(this.get('model.id'));
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    vitalsColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF'
    }, {
      title: 'Vitals',
      propertyName: 'vitalsString'
    }],
    symptomsColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF'
    }, {
      title: 'Symptoms',
      propertyName: 'symptomsString'
    }],
    diagnosesColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF'
    }, {
      title: 'Diagnoses',
      propertyName: 'diagnosesString'
    }],
    prescriptionsColumn: [{
      title: ' Batch Date',
      propertyName: 'dateTimeF'
    }, {
      title: 'Prescriptions',
      propertyName: 'prescriptionsString'
    }],
    proceduresColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF'
    }, {
      title: 'Documents',
      propertyName: 'documentsString'
    }],
    investigationsColumn: [{
      title: ' Batch Date',
      propertyName: 'requestedDateTimeF'
    }, {
      title: 'Investigations',
      propertyName: 'investigationsString'
    }],
    columns: [{
      "propertyName": "index",
      "component": "controls/table-index"
    }, {
      "propertyName": "date"
    }, {
      "title": "description",
      "propertyName": "description"
    }, {
      "propertyName": "unitCost"
    }, {
      "propertyName": "quantity"
    }, {
      'title': 'Total cost',
      "propertyName": "costF"
    }],
    columns2: [{
      "propertyName": "index",
      "component": "controls/table-index"
    }, {
      "propertyName": "date"
    }, {
      "title": "description",
      "propertyName": "description"
    }, {
      'title': 'Cost',
      "propertyName": "costF"
    }]
  });

  _exports.default = _default;
});
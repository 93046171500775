define("hmis/components/top-bar-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    right: '70%',
    width: '220px',
    actions: {
      onMouseOver() {
        this.set('isOnHover', true);
      },

      onMouseLeave() {
        this.set('isOnHover', false);
      },

      onClick(event) {
        // alert('dd')
        // if(this.get('hasBlock'))
        this.set('isOpen', true);
        let action = this.get('onClick');
        if (action) action(); // event.preventDefault();
        // event.stopPropation();
      }

    }
  });

  _exports.default = _default;
});
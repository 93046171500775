define("hmis/templates/components/grouping-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "U/Z31iGO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"p\",true],[8],[1,[22,\"currentGroupingPropertyName\"],false],[0,\": \\n    \"],[1,[22,\"groupedValue\"],false],[0,\" \\n    \"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/grouping-row.hbs"
    }
  });

  _exports.default = _default;
});
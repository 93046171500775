define("hmis/templates/components/controls/select-all-rows-checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+tDPDIkP",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\",false],[12,\"class\",[29,[[28,\"if\",[[28,\"is-equal\",[[24,[\"selectedItems\",\"length\"]],[24,[\"data\",\"length\"]]],null],[24,[\"themeInstance\",\"select-all-rows\"]],[24,[\"themeInstance\",\"deselect-all-rows\"]]],null]]]],[3,\"action\",[[23,0,[]],\"toggleAllSelection\"]],[8],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/select-all-rows-checkbox.hbs"
    }
  });

  _exports.default = _default;
});
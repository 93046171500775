define("hmis/constants/vital-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.VITAL_TYPES = void 0;
  const VITAL_TYPES = {
    WEIGHT: 2,
    BLOOD_PREASSURE: 3,
    HEIGHT: 5,
    PULSE: 9,
    RESPIRATION: 10
  };
  _exports.VITAL_TYPES = VITAL_TYPES;
});
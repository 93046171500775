define("hmis/mixins/page-error-handler", ["exports", "hmis/utils/general-util"], function (_exports, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    currentUser: Ember.inject.service(),
    pageError: Ember.computed('error', 'permissions.@each', function (params) {
      if (this.error) return this.error;
      if (!(0, _generalUtil.hasPermission)(this.permissions, this.currentUser.operationalPermissions, this.currentUser.modularPermissions)) return "Sorry! You don't have permissions to access this content";else return false; // if(this.permissions){
      //   for (let index = 0; index < this.permissions.length; index++) {
      //     const perm = this.permissions[index];
      //     const permString = perm.includes('op')?'currentUser.operationalPermissions' : 'currentUser.modularPermissions'
      //     if(!this.get(permString+'.'+perm)){
      //       return "Sorry! You don't have permissions to access this content";
      //     }else{
      //       // console.log('per ------', permString+'.'+perm, this.get(permString+'.'+perm));
      //     }
      //   }
      // }
      // return false;
    })
  });

  _exports.default = _default;
});
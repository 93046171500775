define("hmis/routes/hmis-app/records/patient/questionnaires/index", ["exports", "hmis/mixins/route-error-handler"], function (_exports, _routeErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_routeErrorHandler.default, {
    model() {
      return this.get('store').query('questionnaire', {
        filter: {
          patientInfo: 1,
          status: 'published'
        }
      });
    }

  });

  _exports.default = _default;
});
define("hmis/utils/app-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let appModules = [{
    id: '1',
    name: 'Dashboard',
    route: 'dashboard',
    hasChildren: false,
    icon: 'dashboard',
    perm: 'mp1',
    levels: [0, 1, 2]
  }, {
    id: '2',
    name: 'Registration',
    route: 'records',
    hasChildren: false,
    icon: 'patient',
    perm: 'mp2',
    levels: [2]
  }, // {
  //   id:'18',
  //   name:'OPD',
  //   route:'opd',
  //   icon:'medical-result',
  //   hasChildren:true,
  //   perm:'mp18',
  //   levels:[2],
  //   childMenus:[
  {
    id: '3',
    name: 'Triage',
    route: 'opd.triage',
    icon: 'medical-result',
    perm: 'mp3',
    levels: [2]
  }, {
    id: '4',
    name: 'Consultation',
    route: 'opd.consultation',
    icon: 'doctor',
    perm: 'mp4',
    levels: [2]
  }, //   ]
  // },
  {
    id: '5',
    name: 'Admission',
    route: 'ipd',
    hasChildren: false,
    icon: 'ipd',
    perm: 'mp5',
    levels: [2]
  }, {
    id: '6',
    name: 'Pharmacy',
    route: 'pharmacy',
    hasChildren: false,
    icon: 'medicine',
    perm: 'mp6',
    levels: [2]
  }, {
    id: '9',
    name: 'Procedures',
    route: 'procedures',
    hasChildren: false,
    icon: 'scientist',
    perm: 'mp9',
    levels: [2]
  }, // {
  //   id:'10',
  //   name:'Diagnostics',
  //   route:'diagnostics',
  //   hasChildren:true,
  //   icon:'microscope',
  //   perm:'mp10',
  //   levels:[2],
  //   //array of children
  //   childMenus:[
  {
    id: '15',
    name: 'Laboratory',
    route: 'diagnostics.investigations',
    hasChildren: false,
    isChildMenu: true,
    parent: 'diagnostics',
    perm: 'mp15',
    levels: [2]
  }, // {
  //   id:'17',
  //   name:'X-Ray',
  //   route:'diagnostics.xray',
  //   hasChildren:false,
  //   isChildMenu:true,
  //   parent:'diagnostics',
  //   perm:'mp17',
  //   levels:[2],
  // },
  // {
  //   id:'16',
  //   name:'Ultrasound',
  //   route:'diagnostics.ultrasound',
  //   hasChildren:false,
  //   isChildMenu:true,
  //   parent:'diagnostics',
  //   perm:'mp16',
  //   levels:[2],
  // },
  //   ]
  // },
  {
    id: '7',
    name: 'Payment',
    route: 'transactions',
    hasChildren: false,
    icon: 'bills',
    perm: 'mp7',
    levels: [2]
  }, {
    id: '8',
    name: 'Claims',
    route: 'claims',
    hasChildren: false,
    icon: 'claims',
    perm: 'mp8',
    levels: [2]
  }, // {
  //   id:'29',
  //   name:'Profile',
  //   isAdmin:true,
  //   route:'profile',
  //   icon:'man-user',
  //   perm:'alwaysAllow',
  //   levels:[0,1,2],
  // },
  // {
  //   id:'30',
  //   name:'Notifications',
  //   isAdmin:true,
  //   route:'notifications',
  //   icon:'bell-fill',
  //   perm:'alwaysAllow',
  //   levels:[0,1,2],
  // },
  // {
  //   id:'12',
  //   name:'Registered Users',
  //   isAdmin:true,
  //   route:'users',
  //   icon:'users',
  //   perm:'mp12',
  //   levels:[0,2],
  // },
  // {
  //   id:'13',
  //   name:'Registered Facilities',
  //   isAdmin:true,
  //   route:'facility-management',
  //   icon:'hospital-buildings',
  //   perm:'mp13',
  //   levels:[0,2],
  // },
  // {
  //   id:'11',
  //   name:'Management Reports',
  //   route:'management-report',
  //   hasChildren:true,
  //   icon:'report',
  //   perm:'mp11',
  //   noChildPermissions:false,
  //   levels:[0,1,2],
  //   //array of children 
  //   childMenus:[
  //     {
  //       id:'19',
  //       name:'Service revenue',
  //       route:'management-report.service-revenue',
  //       isChildMenu:true,
  //       parent:'11',
  //       perm:'mp19',
  //       levels:[0,1, 2],
  //     },
  //     {
  //       id:'20',
  //       name:'Doctors report',
  //       route:'management-report.doctors-report',
  //       isChildMenu:true,
  //       parent:'11',
  //       perm:'mp20',
  //       levels:[0,1,2],
  //     },
  //     {
  //       id:'21',
  //       name:'Patients Report',
  //       route:'management-report.patients-report',
  //       isChildMenu:true,
  //       parent:'11',
  //       perm:'mp21',
  //       levels:[0,1,2],
  //     },
  //     {
  //       id:'22',
  //       name:'Diagnosis reports',
  //       route:'management-report.diagnosis-report',
  //       isChildMenu:true,
  //       parent:'11',
  //       perm:'mp22',
  //       levels:[0,1,2],
  //     },
  //     {
  //       id:'24',
  //       name:'Prescriptions report',
  //       route:'management-report.prescriptions-report',
  //       isChildMenu:true,
  //       parent:'11',
  //       perm:'mp24',
  //       levels:[0,1,2],
  //     },
  //   ]
  // },
  // {
  //   id:'11',
  //   name:'Reports & Analytics',
  //   route:'reports',
  //   hasChildren:true,
  //   icon:'report',
  //   perm:'mp11',
  //   noChildPermissions:false,
  //   levels:[0,1,2],
  //   //array of children 
  //   childMenus:[
  // {
  //   id:'19',
  //   name:'Facility Types Report',
  //   route:'reports.chag-facility-types',
  //   isChildMenu:true,
  //   parent:'11',
  //   perm:'mp19',
  //   levels:[0,1],
  // },
  // {
  //   id:'20',
  //   name:'Diagnosis Report',
  //   route:'reports.diagnosis',
  //   isChildMenu:true,
  //   parent:'11',
  //   perm:'mp20',
  //   levels:[0,1,2],
  // },
  // {
  //   id:'21',
  //   name:'Prescriptions Report',
  //   route:'reports.prescriptions',
  //   isChildMenu:true,
  //   parent:'11',
  //   perm:'mp21',
  //   levels:[0,1,2],
  // },
  // {
  //   id:'22',
  //   name:'Laboratory & Other Investigations Report',
  //   route:'reports.labs',
  //   isChildMenu:true,
  //   parent:'11',
  //   perm:'mp22',
  //   levels:[0,1,2],
  // },
  // {
  //   id:'23',
  //   name:'Procedures Report',
  //   route:'reports.procedures',
  //   isChildMenu:true,
  //   parent:'11',
  //   perm:'mp23',
  //   levels:[0,1,2],
  // },
  // {
  //   id:'24',
  //   name:'X-rays Report',
  //   route:'reports.xrays',
  //   isChildMenu:true,
  //   parent:'11',
  //   perm:'mp24',
  //   levels:[0,1,2],
  // },
  // {
  //   id:'25',
  //   name:'Ultrasounds Report',
  //   route:'reports.ultrasounds',
  //   isChildMenu:true,
  //   parent:'11',
  //   perm:'mp25',
  //   levels:[0,1,2],
  // },
  // {
  //   id:'26',
  //   name:'Bills and Claims Report',
  //   route:'reports.oop-and-insurance',
  //   isChildMenu:true,
  //   parent:'11',
  //   perm:'mp26',
  //   levels:[0,1,2],
  // },
  // {
  //   id:'27',
  //   name:'OPD - IPD Report',
  //   route:'reports.opd-and-ipd',
  //   isChildMenu:true,
  //   parent:'11',
  //   perm:'mp27',
  //   levels:[0,1,2],
  // },
  // {
  //   id:'28',
  //   name:'Admissions Report',
  //   route:'reports.admissions',
  //   isChildMenu:true,
  //   parent:'11',
  //   perm:'mp28',
  //   levels:[0,1,2],
  // },
  //   ]
  // },
  {
    id: '14',
    name: 'Settings',
    isAdmin: true,
    route: 'admin-settings',
    icon: 'settings',
    perm: 'mp14',
    levels: [0, 2]
  }];
  var _default = appModules;
  _exports.default = _default;
});
define("hmis/controllers/hmis-app/users", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    columns: [{
      "component": "select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false //   "componentForSortCell": "select-all-rows-checkbox"

    }, {
      "propertyName": "index"
    }, {
      "propertyName": "firstName"
    }, {
      "propertyName": "lastName"
    }, {
      "propertyName": "age"
    }, {
      "propertyName": "city"
    }]
  });

  _exports.default = _default;
});
define("hmis/components/tabs-container", ["exports", "hmis/mixins/page-error-handler"], function (_exports, _pageErrorHandler) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_pageErrorHandler.default, {
    init() {
      this._super(...arguments);

      this.set('tabs', []);
    },

    didInsertElement() {
      this._super(...arguments);

      if (!this.tabIndex) this.send('tabClicked', 0);
    },

    _tabs: Ember.computed('tabs', function () {
      return this.tabs;
    }),
    initCount: [],
    tagName: "",
    actions: {
      tabClicked(index) {
        // console.log('tabs---', this.tabs);
        // console.log('tabsClick', index);
        this.set('tabIndex', index);
        let action = this.get("onTab" + index + "Select");
        if (action) action();
      }

    }
  });

  _exports.default = _default;
});
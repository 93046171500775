define("hmis/templates/configure/finish", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "POSun/kW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height\",true,\"Complete Configuration\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[10,\"style\",\"text-align: center\"],[8],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[10,\"style\",\"margin-top: 20px;text-align: center\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"show\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-40\"],[10,\"style\",\"margin-left: 25%;text-align: center;color: #777\"],[8],[0,\"\\n            \"],[7,\"h2\",true],[10,\"style\",\"margin: 60px 20px\"],[8],[1,[22,\"msg\"],false],[0,\" click on the button to login \"],[9],[0,\"\\n            \"],[7,\"button\",true],[10,\"class\",\"submit-button float-right btn-success\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"logout\"],null]],[8],[0,\" Login\"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-40\"],[10,\"style\",\"margin-left: 25%;text-align: center;color: #777\"],[8],[0,\"\\n          \"],[7,\"h2\",true],[10,\"style\",\"margin: 60px 20px\"],[8],[0,\"To complete setup and configuration of this facility click on the button below \"],[9],[0,\"\\n          \"],[7,\"button\",true],[10,\"class\",\"submit-button float-right btn-success\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"save\"],null]],[8],[0,\" Finish\"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/configure/finish.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/patient/sharing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    tabIndex: 0,
    session: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    notify: Ember.inject.service(),
    actions: {
      onChange() {
        if (!this.get('answerRecord')) {
          this.set('hasChange', true);
          return;
        }

        let moItems = this.get('answerRecord').toAnswer();
        let editItems = this.get('patientConsents');
        this.set('hasChange', JSON.stringify(moItems) != JSON.stringify(editItems));
      },

      onRevert() {
        if (!this.get('answerRecord')) {
          this.set('patientConsents', {});
          this.set('hasChange', false);
          return;
        }

        this.set('patientConsents', this.get('answerRecord').toAnswer());
        this.set('hasChange', false);
      },

      onSave() {
        let answerRecord = this.get('answerRecord');

        if (!answerRecord) {
          answerRecord = this.store.createRecord('sharing-consent', {
            patientId: this.get('model.patient.id')
          });
        }

        this.set('saving', false);
        answerRecord.saveAnswer(this.get('patientConsents'), rec => {
          this.set('answerRecord', rec);
          this.set('hasChange', false);
          this.set('saving', false);
          this.get('notify').success("Saved");
        }, () => {
          this.set('saving', false);
          this.get('notify').error("Failed to save");
        });
      },

      back() {
        history.back();
      }

    }
  });

  _exports.default = _default;
});
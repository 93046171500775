define("hmis/utils/server-util", ["exports", "hmis/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.imagePlaceHolder = _exports.notificationURL = _exports.downloadUrl = _exports.uploadsUrl = _exports.serverUrl = _exports.apiNamespace = _exports.apiUrl = void 0;

  function currentUrl() {
    let host = window.location.host.split(':')[0];
    return window.location.protocol + "//" + host;
  }

  const apiUrl = function () {
    if (_environment.default.APP.sameApiIpAddress) {
      if (!_environment.default.APP.apiPort) console.error('APP.sameApiIpAddress==true but APP.apiPort has not been set in environment config');
      return currentUrl() + ':' + _environment.default.APP.apiPort;
    } else return _environment.default.APP.apiURL;
  }();

  _exports.apiUrl = apiUrl;

  const apiNamespace = function () {
    return _environment.default.APP.apiNamespace;
  }();

  _exports.apiNamespace = apiNamespace;

  const serverUrl = function () {
    return apiUrl + '/' + _environment.default.APP.apiNamespace;
  }();

  _exports.serverUrl = serverUrl;

  const uploadsUrl = function () {
    return apiUrl + '/storage';
  }();

  _exports.uploadsUrl = uploadsUrl;
  const downloadUrl = uploadsUrl;
  _exports.downloadUrl = downloadUrl;

  const notificationURL = function () {
    if (_environment.default.APP.sameApiIpAddress) {
      if (!_environment.default.APP.notificationPort) console.error('APP.sameApiIpAddress==true but APP.notificationPort has not been set in environment config');
      return currentUrl() + ':' + _environment.default.APP.notificationPort;
    } else return _environment.default.APP.notificationURL;
  }();

  _exports.notificationURL = notificationURL;
  const imagePlaceHolder = '/assets/image/dummy/man-placeholder.jpg';
  _exports.imagePlaceHolder = imagePlaceHolder;
  var _default = {
    apiUrl,
    serverUrl,
    uploadsUrl,
    imagePlaceHolder,
    notificationURL,
    apiNamespace
  };
  _exports.default = _default;
});
define("hmis/templates/components/status-indicator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0NRxadvC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"status-indicator \",[28,\"if\",[[24,[\"isActive\"]],\"active\",\"\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"isIn\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"in\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isOut\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"out\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"stay\"],[8],[9],[0,\"\\n    \"]],\"parameters\":[]}]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/status-indicator.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/text-value-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DhjHMCmg",
    "block": "{\"symbols\":[],\"statements\":[[0,\" \"],[7,\"div\",true],[10,\"class\",\"text-value\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"tv-text\"],[8],[1,[28,\"camelcase-to-names\",[[24,[\"text\"]]],null],false],[0,\":\"],[9],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"class\",\"tv-value\"],[8],[1,[28,\"if\",[[24,[\"value\"]],[24,[\"value\"]],\"......\"],null],false],[9],[0,\"\\n    \\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/text-value-view.hbs"
    }
  });

  _exports.default = _default;
});
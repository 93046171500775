define("hmis/components/managers/appointment-alert copy", ["exports", "hmis/utils/date-util"], function (_exports, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    app: Ember.inject.service(),

    init() {
      this._super(...arguments);

      if (this.get('staff')) {
        this.set('data.staff', this.get('staff'));
      }
    },

    appointObserv: Ember.observer('show', function () {
      if (!this.get('show')) return;

      if (this.get('reschedule')) {
        let appointment = this.get('appointment');
        let patient = this.get('patient');

        if (this.get('fromActiveServe')) {
          let activeServe = this.get('activeServe');

          if (!activeServe) {
            console.error("no activeServe provided for rescheduling. Provide activeServe or if trying to reschedule from a appointment then do not pass 'fromActiveServe=true'");
          } else {
            this.set('data.patient', activeServe.get('visit.patient'));
            this.set('data.purpose', activeServe.get('visit.purpose'));
          }
        } else {
          if (!appointment) {
            console.error("no appointment provided for rescheduling. Provide appointment or if trying to reschedule from a activeServe then pass 'fromActiveServe=true'");
          } else {
            this.set('data.patient', appointment.get('patient'));
            this.set('data.staff', appointment.get('staff'));
            this.set('data.purpose', appointment.get('purpose'));
          }
        }
      }
    }),
    data: {},
    actions: {
      onConfirm() {
        if (this.get('reschedule')) {
          if (this.get('fromActiveServe')) {
            let activeServe = this.get('activeServe');

            if (!activeServe) {
              console.error("no activeServe provided for rescheduling. Provide activeServe or if trying to reschedule from a appointment then do not pass 'fromActiveServe=true'");
              return;
            }
          } else {
            let appointment = this.get('appointment');

            if (!appointment) {
              console.error("no appointment provided for rescheduling. Provide appointment or if trying to reschedule from a activeServe then pass 'fromActiveServe=true'");
              return;
            }
          }
        }

        let data = this.get('data');
        let that = this;
        this.get('store').createRecord('appointment', {
          schedule: data.schedule,
          purpose: data.purpose,
          patient: data.patient
        }).save().then(appointment => {
          if (!this.get('reschedule')) {
            //for  new ppointment only
            this.set('data.patient', null);
            this.set('data.purpose', null);
            this.notify.success('Appointment created for ' + data.schedule.get('dueRange'));
            let action = this.get('onSuccess');
            if (action) action(appointment);
          } else {
            //for rescheduling
            if (this.get('fromActiveServe')) {
              //from activeServe
              let activeServe = this.get('activeServe'); //activeServe.get('visit').end()

              console.log('active visit ended'); //let activeServe = this.get('model')
              //this.set('endingServe', true)

              this.get('ajaxRequest').endActiveServe(activeServe, {
                success: oldAS => {
                  // this.set('endingServe', false)
                  // this.notify.success('Serve ended')
                  // history.back()
                  that.notify.success('Appointment rescheduled for ' + data.schedule.get('dueRange'));
                  let action = that.get('onSuccess');
                  if (action) action(appointment, oldAS);
                },
                error: error => {
                  // this.set('endingServe', false)
                  // this.notify.error('Sorry! Something went wrong')
                  that.notify.error('Something went wrong when trying to end active serve');
                  console.log(error);
                }
              }); // activeServe.end({
              //     success(oldAS){
              //         that.notify.success('Appointment rescheduled for '+data.schedule.get('dueRange'))
              //         let action = that.get('onSuccess')
              //         if(action)
              //             action(appointment, oldAS);
              //     },
              //     failure(error){
              //         that.notify.error('Something went wrong when trying to end active serve')
              //         console.log(error)
              //     }
              // })
            } else {
              //from an existing appoinmetn
              this.set('appointment.endDate', _dateUtil.default.toSqlDate(new Date()));
              this.get('appointment').save().then(oldAppointment => {
                that.notify.success('Appointment rescheduled for ' + data.schedule.get('dueRange'));
                let action = that.get('onSuccess');
                this.get('app').loadDashboardStats();
                if (action) action(appointment, oldAppointment);
              }, error => {
                that.notify.error('Something went wrong when trying to end previous appointment');
                console.log(error);
              });
            }
          }

          this.get('app').loadDashboardStats();
        }, error => {
          this.notify.error('Something went wrong');
          console.log(error);
        });
      }

    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/diagnostics/investigations/review", ["exports", "hmis/mixins/visit-request-review-route", "hmis/mixins/route-error-handler", "hmis/utils/visit-service-util", "hmis/utils/general-util"], function (_exports, _visitRequestReviewRoute, _routeErrorHandler, _visitServiceUtil, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_visitRequestReviewRoute.default, _routeErrorHandler.default, {
    breadCrumb: null,
    serviceName: 'investigation',

    model(params) {
      let docFilter = {};
      docFilter["".concat(this.serviceName, "Id")] = params.record_id;
      let newModel = {
        data: this.appAjax.ajaxGet("/".concat(this.serviceName, "s/").concat(params.record_id), {
          include: [this.getServiceTypeName() + "Name", this.getServiceTypeName() + "GdrgCode", 'investigationTypeUpperLimit', 'investigationTypeLowerLimit'].concat(_visitServiceUtil.visitServiceResultIncludes)
        })
      };
      return (0, _generalUtil.modelHash)(newModel).then(res => res);
    }

  });

  _exports.default = _default;
});
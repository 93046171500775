define("hmis/models/accreditation-type", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    accreditationInstitution: (0, _relationships.belongsTo)('accreditation-institution'),
    accreditationInstitutionName: _emberData.default.attr()
  });

  _exports.default = _default;
});
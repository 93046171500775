define("hmis/templates/components/viewers/viewers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a+beWHk/",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"pickers/table-picker-server-paginated\",null,[[\"ref\",\"parent\"],[\"serverTable\",[24,[\"viewers\"]]]]],false],[0,\"\\n\"],[1,[28,\"viewers/visit-summary-viewer-alert\",null,[[\"ref\",\"parent\"],[\"visit\",[24,[\"viewers\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/viewers/viewers.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/utils/is-perm-true-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isPermTrueUtil;

  function isPermTrueUtil(val) {
    if (!val) return false;
    return val == true || val == 'true' || val == 1 || val == '1';
  }
});
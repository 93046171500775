define("hmis/templates/components/safe-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u+FGIftE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"model\",\"hasError\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"no-data-message\",null,[[\"message\"],[[24,[\"model\",\"error\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/safe-model.hbs"
    }
  });

  _exports.default = _default;
});
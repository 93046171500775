define("hmis/mixins/visit-care-controller", ["exports", "hmis/utils/deep-merge", "hmis/utils/patient-serve-util", "hmis/utils/string-util", "hmis/mixins/page-error-handler", "hmis/constants/visit-statuses"], function (_exports, _deepMerge, _patientServeUtil, _stringUtil, _pageErrorHandler, _visitStatuses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create(_pageErrorHandler.default, {
    initCare() {
      if (this.serviceName) {
        this.set('requestColumns', this.getRequestColumns());
        this.set('resultColumns', this.getResultColumns());
      } else {
        console.error('serviceName not found');
      }
    },

    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    serviceTypeName: Ember.computed('serviceName', function (params) {
      return this.serviceName + "Type";
    }),

    getRequestColumns() {
      return [{
        'title': (0, _stringUtil.capitalizeFirstLetter)(this.serviceName) + ' Type',
        "propertyName": this.serviceName + "TypeName",
        className: 'main-column'
      }, {
        'title': 'Patient',
        "propertyName": "patientName"
      }, {
        'title': 'Requested By',
        "propertyName": "requesterName"
      }, {
        'title': 'Request Date',
        "propertyName": "requestedDateTimeF"
      }];
    },

    getResultColumns() {
      return [{
        'title': (0, _stringUtil.capitalizeFirstLetter)(this.serviceName) + ' Type',
        "propertyName": this.serviceName + "TypeName",
        className: 'main-column'
      }, {
        'title': 'Patient',
        "propertyName": "patientName"
      }, {
        'title': 'Requested By',
        "propertyName": "requesterName"
      }, {
        'title': 'Request Date',
        "propertyName": "requestedDateTimeF"
      }, {
        'title': 'Performed By',
        "propertyName": "granterName"
      }, {
        'title': 'Result Date',
        "propertyName": "grantedDateTimeF"
      }];
    },

    disableServeMessage: '',
    disableServe: Ember.computed('model', 'model.visitStatusId', 'model.dischargeDate', 'model.endDate', function () {
      console.log('visitStatusId-------', this.model.visitStatusId);

      if (this.get('model.visitStatusId') == _visitStatuses.VISIT_STATUSES.PENDING_DISCHARGE) //isPendingDischarge
        {
          this.set('disableServeMessage', 'Patient is pending discharge');
          return 'Patient is pending discharge';
        }

      if (this.get('model.' + this.get('model.modelEndProp'))) //isPendingDischarge
        {
          this.set('disableServeMessage', this.get('model.modelEndMessage'));
          return this.get('model.modelEndMessage');
        }

      if (this.get('model.visitStatusId') == _visitStatuses.VISIT_STATUSES.DISCHARGED) //isDischarged
        {
          this.set('disableServeMessage', 'Patient has been discharge');
          return 'Patient has been discharge';
        }

      if (this.get('model.visitStatusId') != _visitStatuses.VISIT_STATUSES.ACTIVE) //isDischarged
        {
          return 'Patient cannot be seen';
        }

      return false;
    })
  });

  _exports.default = _default;
});
define("hmis/utils/notifications-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.NOTIFICATION_ACTION_VALUES = void 0;
  const NOTIFICATION_ACTION_VALUES = {
    XRAY: 'xray',
    ULTRASOUND: 'ultrasound',
    PROCEDURE: 'procedure',
    DIAGNOSIS: 'diagnosis',
    PRESCRIPTION: 'prescription',
    APPOINTMENT: 'appointment',
    VITALS: 'vitals',
    VITALS_REQUEST: 'vitals-request',
    NEW_VISIT: 'new-visit'
  };
  _exports.NOTIFICATION_ACTION_VALUES = NOTIFICATION_ACTION_VALUES;
});
define("hmis/controllers/hmis-app/records/patient/billing", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller", "hmis/utils/general-util"], function (_exports, _modelTableTheme, _formController, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, {
    notify: Ember.inject.service(),
    routing: Ember.inject.service('router'),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    selectedItems: [],
    allowSelect: false,
    //data: Ember.computed.alias('model'),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    selectedCount: Ember.computed("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    data: Ember.computed(function () {
      return this.get('store').findAll('allergy-type');
    }),
    isToday: true,
    fromDate: new Date(),
    toDate: new Date(),
    tabIndex: 0,
    selectedPatient: '',
    vitalsSuccessAction: 'empty',
    onConfirm: 'deleteRecord',
    actions: {
      back() {
        history.back();
      },

      onInsuranceTab(force = false) {
        if (!this.patientInsurances || force) (0, _generalUtil.promiseSet)(this, 'patientInsurances', this.store.query('patient-insurance', {
          filter: {
            patientId: this.model.patient.id
          },
          include: ['insuranceCompanyName', 'insuranceProductName']
        }), 'insuranceWaiting');
      },

      onDependantsTab(force = false) {
        if (!this.patientDependants || force) (0, _generalUtil.promiseSet)(this, 'patientDependants', this.store.query('patient-dependant', {
          filter: {
            patientId: this.model.patient.id
          },
          include: ['billToName', 'insuranceName']
        }, 'dependantsWaiting'));
      },

      onPaymetsTab(force = false) {
        if (!this.patientPayments || force) (0, _generalUtil.promiseSet)(this, 'patientPayments', this.store.query('patient-payment-account', {
          filter: {
            patientId: this.model.patient.id
          },
          include: ['paymentModeName', 'paymentModeTypeName']
        }, 'paymentsWaiting'));
      },

      addInsurance() {
        this.insuranceForm.showForm({
          patient: this.get('model.patient')
        }, () => {
          this.send('onInsuranceTab', true);
        });
      },

      editInsurance(record) {
        this.insuranceForm.showForm(record, () => {
          this.send('onInsuranceTab', true);
        });
      },

      addDependant() {
        this.dependantForm.showForm(this.model.patient.id, {
          patient: this.get('model.patient')
        }, () => {
          this.send('onDependantsTab', true);
        });
      },

      editDependant(record) {
        this.dependantForm.showForm(this.model.patient.id, record, () => {
          this.send('onDependantsTab', true);
        });
      },

      addPayment() {
        this.paymentForm.showForm({
          patient: this.get('model.patient')
        }, () => {
          this.send('onPaymetsTab', true);
        });
      },

      editPayment(record) {
        this.paymentForm.showForm(record, () => {
          this.send('onPaymetsTab', true);
        });
      },

      onSaved(record) {
        this.send('refreshModel');
      },

      deleteInsurance(rec) {
        this.notify.confirmSimple('Confirm!', 'About to delete ' + rec.insuranceName, () => {
          rec.destroyRecord();
        });
      },

      deleteDependent(rec) {
        this.notify.confirmSimple('Confirm!', 'About to delete ' + rec.patientName, () => {
          rec.destroyRecord();
        });
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record); //this.set('alertTitle','Attempting to archive allergy ('+ record.get('name')+ '-' +record.get('type.name')+')')

        this.set('alertTitle', 'Attempting to archive item');
        this.set('onAlert', true);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    patientInsuranceColumns: [{
      'title': 'Insurance Company',
      "propertyName": "insuranceCompanyName",
      'className': 'main-column'
    }, {
      'title': 'Product',
      "propertyName": "insuranceProductName",
      'className': 'main-column'
    }, {
      'title': 'Number',
      "propertyName": "insuranceNumber",
      'className': 'main-column'
    }, {
      'title': 'Issued Date',
      "propertyName": "issueDate",
      'className': 'main-column'
    }, {
      'title': 'Expiry Date',
      "propertyName": "expiryDate",
      'className': 'main-column'
    }, {
      "title": "Balance",
      "propertyName": "threshold",
      'className': 'main-column'
    }, {
      "title": "Actions",
      "component": "manageRow",
      'className': 'main-column'
    }],
    // patientInsuranceColumns2: [
    //   {
    //     'title':'Insurance Company',
    //     "propertyName": "insuranceProduct.insuranceCompany.name",
    //     'className':'main-column'
    //   },
    //   {
    //     'title':'Product',
    //     "propertyName": "insuranceProduct.name",
    //     'className':'main-column'
    //   },
    //   {
    //     'title':'Number',
    //     "propertyName": "insuranceNumber",
    //     'className':'main-column'
    //   },
    //   {
    //     'title':'Issued Date',
    //     "propertyName": "issueDate",
    //     'className':'main-column'
    //   },
    //   {
    //     'title':'Expiry Date',
    //     "propertyName": "expiryDate",
    //     'className':'main-column'
    //   },
    //   {
    //     "title": "Threshold",
    //     "propertyName": "threshold",
    //     'className':'main-column'
    //   }
    // ],
    patientDependantColumns: [{
      'title': 'Dependant',
      "propertyName": "billToName",
      'className': 'main-column'
    }, {
      'title': 'Insurance',
      "propertyName": "insuranceName",
      'className': 'main-column'
    }, {
      "title": "Actions",
      "component": "manageRow",
      'className': 'main-column'
    }],
    paymentColumns: [{
      'title': 'Payment Mode',
      "propertyName": "paymentModeName",
      'className': 'main-column'
    }, {
      'title': 'Type',
      "propertyName": "paymentModeTypeName",
      'className': 'main-column'
    }, {
      'title': 'Account Number',
      "propertyName": "accountNumber",
      'className': 'main-column'
    }, {
      'title': 'Account Name',
      "propertyName": "accountName",
      'className': 'main-column'
    }, {
      "title": "Actions",
      "component": "manageRow",
      'className': 'main-column'
    }]
  });

  _exports.default = _default;
});
define("hmis/components/forms/specialist-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    modelName: 'department-specialist',
    specialists: Ember.computed('model', function () {
      // return this.get('store').findAll('user')
      return this.get('store').query('user', {
        include: ['specialties']
      });
    }),
    specilistList: Ember.computed.filter('specialists.@each.specialties', function (specialist, index, array) {
      return specialist.get('specialties').length;
    }),
    specialities: Ember.computed('model', function () {
      return this.get('store').findAll('specialty');
    }),
    department: Ember.computed('model', function () {
      return this.get('store').findAll('department');
    }),
    // departmentChange:observer('departmentVal',function(){
    //   this.set('record.departmentUnitId',{})
    // }),
    departmentUnit: Ember.computed('departmentVal', 'department', function () {
      console.log('department', this.get('departmentVal')); // this.set('record.departmentUnitId','')

      let that = this;
      return this.get('store').query('department-unit', {
        filter: {
          departmentId: this.get('departmentVal.id')
        }
      });
    }),
    actions: {
      getDepartmentUnit(item) {//this.set('department', item);
      },

      onRecordSubmit() {
        let record = this.get('record');
        this.set('waiting', true);

        if (!this.get('record.id')) {
          record = this.get('store').createRecord('department-specialist', record);
        }

        record.save().then(rec => {
          this.set('record', {});
          this.set('show', false);

          if (this.get('onSaved')) {
            this.get('onSaved')(rec);
          }

          this.set('waiting', false);
        }, err => {
          this.set('waiting', false);
          console.log('error', err);
        });
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/patient/attachment", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/page-error-handler", "hmis/utils/general-util"], function (_exports, _modelTableTheme, _pageErrorHandler, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  //import { requestAdmission, requestDischarge } from '../../../utils/request-utils/ipd';
  //import dateUtil from '../../../utils/date-util';
  var _default = Ember.Controller.extend(_pageErrorHandler.default, {
    fileManager: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    // consultationService: service('consultation'),
    // currentUser: service(),
    // //showBillPaymentStatus: Ember.inject.service(),
    // mPerms: computed('currentUser.modularPermissions', function(){
    //   return this.get('currentUser.modularPermissions')
    // }),
    // oPerms: computed('currentUser.operationalPermissions', function(){
    //   return this.get('currentUser.operationalPermissions')
    // }),
    // patientName:computed('model.patientName',function(){
    //     return this.get('model.patientName');
    // }),
    // visitOutcomes:computed(function(){
    //     return this.get('store').findAll('visit-outcome');
    // }),
    // title:'Patient',
    // input_width:'flex-45',
    // input_width_full:'flex-100',
    // transactions:[],
    // onConfirm:'empty',
    // onCancel:'empty',
    // tabIndex:0,
    // isEdit:true,
    notify: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      onOthersTab(force = false) {
        if (!this.others || force) (0, _generalUtil.promiseSet)(this, 'others', this.store.query('patient-attachment', {
          filter: {
            patientId: this.model.patient.id
          },
          include: []
        }), 'othersWaiting');
      },

      onProceduresTab(force = false) {
        if (!this.procedures || force) (0, _generalUtil.promiseSet)(this, 'procedures', this.store.query('procedure-document', {
          filter: {
            patientId: this.model.patient.id
          },
          include: []
        }), 'proceduresWaiting');
      },

      onUltrasoundsTab(force = false) {
        if (!this.ultrasounds || force) (0, _generalUtil.promiseSet)(this, 'ultrasounds', this.store.query('ultrasound-document', {
          filter: {
            patientId: this.model.patient.id
          },
          include: []
        }), 'ultrasoundsWaiting');
      },

      onXraysTab(force = false) {
        if (!this.xrays || force) (0, _generalUtil.promiseSet)(this, 'xrays', this.store.query('xray-document', {
          filter: {
            patientId: this.model.patient.id
          },
          include: []
        }), 'xraysWaiting');
      },

      uploadOthers() {
        this.fileManager.pick({
          type: "image",
          accepts: "image/*, application/pdf"
        }, file => {
          let param = {
            name: file.name,
            patientId: this.model.patient.id,
            type: file.get('type')
          };
          this.appAjax.postFile("patientAttachments", file, param).then(res => {
            this.send('onOthersTab', true);
          });
        }, null);
      },

      onRowClick(index, record) {
        this.fileManager.preview(record.location, {
          title: record.name
        });
      },

      // onEditClick(even){
      //     this.set('isEdit', true)
      // },
      deleteRecord(record) {
        record.destroyRecord();
      },

      downloadRecord(record) {
        record.downloadFile(); // this.get('ajaxRequest').downloadFile(record.get('fileUrl'), record.get('name'))
      } // onSaveClick(event)
      // {
      //     // this.get('notify').notifyInfo('Saving please wait', true)
      //     let record = this.get('model')
      //     this.get('ajaxRequest').applyProcedure(record, {
      //       success: (response)=>{
      //         this.set('isEdit', false)
      //         this.notify.success('Saved')
      //         this.get('app').loadDashboardStats()
      //       },
      //       error: (error)=>{
      //         this.notify.error('Sorry! Something went wrong')
      //       }
      //     })
      // },
      // onPictureChange(file){
      //   this.get('serverUploads').uplaodPatientDocument(this.get('model.patient'), file, {
      //     success:(response)=>{
      //         this.notify.success("file uploaded")
      //         this.send('refreshModel')
      //     },
      //     error:(error)=>{
      //       this.notify.error("Unable to upload. Something went wrong"+ xhr)
      //       console.log('sd', xhr)
      //     }
      //   })
      // },
      // onCancelClick(event){
      //     this.set('isEdit', false)
      // },


    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/
        ,
        messages: {
          "noDataToShow": "No documents uploaded"
        }
      });
    }),
    //date , patient, requestor
    columns: [{
      title: 'preview',
      component: 'filePreview'
    }, // {
    //     'title':'file image',
    //   "propertyName": "type",
    // //   "component": "checkBox"
    // },
    {
      'title': 'Name',
      "propertyName": "name",
      className: 'main-column'
    }, {
      'title': 'file type',
      "propertyName": "type" //

    }, {
      'title': 'Date added',
      "propertyName": "dateF"
    }, {
      title: 'Actions',
      component: 'manageRow'
    }]
  });

  _exports.default = _default;
});
define("hmis/controllers/sign-in/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    uname: "",
    upass: '',
    actions: {
      onNameChanged(value) {
        //   alert(value)
        this.set('uname', value);
      },

      onPassChanged(value) {
        //   alert(value)
        this.set('upass', value);
      },

      onSubmit(event) {
        event.preventDefault();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/managers/billable-price", ["exports", "hmis/utils/billable-util"], function (_exports, _billableUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement() {
      this._super(...arguments);

      let parent = this.get('parent');

      if (parent) {
        parent.set('previewBillable', (billable, editMode = false) => {
          this.send('onPreview', billable, editMode);
        });
      }
    },

    store: Ember.inject.service(),
    levelCodes: Ember.computed(function () {
      return this.get('store').findAll('level-code');
    }),
    billableNameProp: 'genericName',
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    actions: {
      onPreview(billable, billableName, editMode) {
        this.set('showPriceReview', true);
        this.set('showPriceReviewLoading', true);
        this.set('editMode', false);
        this.set('billableName', billableName);
        let insuranceProduct = this.get('insuranceProduct');

        if (!insuranceProduct) {
          console.log('new review');
          this.set('priceReviewRecord', billable);
          this.set('priceReviewObject', null);

          _billableUtil.default.priceReview(this.get('ajaxRequest'), billableName, Ember.get(billable, 'id')).then(response => {
            // this.set('billable', billable)
            console.log('hii ', response);
            this.set('priceReviewObject', response);
            this.set('showPriceReviewLoading', false);
            if (editMode) this.send('editModeOnPreview');
          });
        } else {
          console.log('old review');
          this.set('priceReviewRecord', billable);
          this.set('insuranceProductPrice', null);

          _billableUtil.default.insuranceProductPriceReview(this.get('ajaxRequest'), billableName, Ember.get(billable, 'id'), insuranceProduct.get('id')).then(response => {
            // this.set('billable', billable)
            this.set('priceReviewObject', response);
            this.set('showPriceReviewLoading', false);
            if (editMode) this.send('editModeOnPreview');
          });
        }
      },

      editModeOnPreview() {
        if (this.get('currentUser.accessLevel') == 2) {
          //facility
          this.set('facilityInsEditValue', this.get('priceReviewObject'));
          this.set('editMode', true);
        } else {
          this.send('editPrices');
        }
      },

      editPrices() {
        this.set('editMode', true);
        console.log('edit prices called');
        this.get('levelCodes').forEach(lCode => {
          let levCode = lCode.get('id');
          let valName = levCode + 'EditValue';
          this.set(valName, this.get('priceReviewObject.' + levCode));
        });
      },

      onFacilityPriceSave(value) {
        let billable = this.get('priceReviewRecord');
        let billableName = this.get('billableName');

        _billableUtil.default.saveFacilityPrice(this.get('ajaxRequest'), billableName, Ember.get(billable, 'id'), value).then(response => {
          this.set('priceReviewObject.facilityPrice', value);
        });
      },

      onFacilityInsurancePriceSave(value) {
        let billable = this.get('priceReviewRecord');
        console.log('value++==', billable);
        let billableName = this.get('billableName');
        let parentList = this.get('parent.priceList');

        _billableUtil.default.saveFacilityInsurancePrice(this.get('ajaxRequest'), billableName, Ember.get(billable, 'id'), this.get('insuranceProduct.id'), value).then(response => {
          this.get('ajaxRequest').makeRequest('GET', "/insuranceProductBillables/" + billableName + "/" + this.get('insuranceProduct.id'), null, {
            success: response => {
              // parentList.pushObject(response[response.length-1])
              // parentList.pushObject(response.filter(rec => rec.id === billable.id))
              this.set('parent.priceList', response);
              resolve(response);
            },
            error: error => {
              reject(error);
            }
          });
          this.set('priceReviewObject', value);
          console.log('saved parent', this.get('parent'));
        });
      },

      onChagInsurancePricesSave() {
        let prices = {};
        this.get('levelCodes').forEach(lCode => {
          let valName = lCode.get('id') + 'EditValue';
          prices[lCode.get('id')] = this.get(valName);
        });
        let billable = this.get('priceReviewRecord');
        let billableName = this.get('billableName');

        _billableUtil.default.saveChagInsurancePrices(this.get('ajaxRequest'), billableName, Ember.get(billable, 'id'), this.get('insuranceProduct.id'), prices).then(response => {
          this.set('priceReviewObject', response);
          this.set('editMode', false);
        });
      }

    }
  });

  _exports.default = _default;
});
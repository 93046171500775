define("hmis/components/pickers/date-range-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { computed } from '@ember/object';
  var _default = Ember.Component.extend({
    tagName: '',
    isNone: true,
    enableNoFilter: true,
    selectedDates: null,

    didInsertElement() {
      this._super(...arguments);

      if (this.state) {
        this.setSelections(this.state.selectedFilterType); // let fType = this.state.selectedFilterType
        // if(fType=='isDateRange')
        //   this.send(this.filterTypes[fType], this.state.selectedDates.fromDate, this.state.selectedDates.toDate)
        // else
        //   this.send(this.filterTypes[fType])
      } else {
        this.set('state', null);
        if (this.enableNoFilter) this.send('noFilter');else this.send('filterToday');
      }
    },

    filterTypes: {
      'isNone': 'filterToday',
      'isToday': 'filterToday',
      'isThisMonth': 'filterThisMonth',
      'isDateRange': 'onDateFilterSubmit'
    },

    setSelections(selectedFilterType) {
      Object.keys(this.filterTypes).forEach(element => {
        if (element == selectedFilterType) this.set(element, true);else this.set(element, false);
      });
    },

    actions: {
      noFilter() {
        console.log(this.state);
        this.set('selectedDates', null);
        this.send('onChange', 'isNone');
      },

      filterToday() {
        var fd = new Date();
        fd.setHours(0, 0, 0, 0);
        var td = new Date();
        td.setHours(24, 0, 0, 0);
        this.set('selectedDates', {
          fromDate: fd,
          toDate: td
        });
        this.send('onChange', 'isToday');
      },

      filterThisMonth() {
        let date = new Date(),
            y = date.getFullYear(),
            m = date.getMonth();
        let fd = new Date(y, m, 1);
        let td = new Date(y, m + 1, 0);
        fd.setHours(0, 0, 0, 0);
        td.setHours(24, 0, 0, 0);
        this.set('selectedDates', {
          fromDate: fd,
          toDate: td
        });
        this.send('onChange', 'isThisMonth');
      },

      onDateFilterSubmit(fromDate, toDate) {
        let fd = new Date(fromDate);
        let td = new Date(toDate);
        fd.setHours(0, 0, 0, 0);
        td.setHours(24, 0, 0, 0);
        this.set('selectedDates', {
          fromDate: fd,
          toDate: td
        });
        this.send('onChange', 'isDateRange');
      },

      onChange(selectedFilterType) {
        this.set('state', {
          selectedDates: this.selectedDates,
          selectedFilterType
        });
        this.setSelections(selectedFilterType);
        if (this.onChange) this.onChange(this.selectedDates);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/dashboard/chart-one", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    chartOptions: {
      chart: {
        type: 'bar'
      },
      title: {
        text: false
      },
      xAxis: {
        categories: ['Apples', 'Bananas', 'Oranges']
      },
      legend: {
        enabled: false
      },
      yAxis: {
        title: {
          text: 'Fruit eaten'
        }
      },
      exporting: {
        menuItemDefinitions: {
          // Custom definition
          label: {
            onclick: function () {
              this.renderer.label('You just clicked a custom menu item', 100, 100).attr({
                fill: '#a4edba',
                r: 5,
                padding: 10,
                zIndex: 10
              }).css({
                fontSize: '1.5em'
              }).add();
            },
            text: 'Show label'
          },
          typeChange: {
            onclick: function () {//return ' '
              //chart.type = 'lines'
              //alert('new chart')
              // this.set('chartOptions.chart.type','lines')
            },
            text: 'Bar Chart'
          }
        },
        buttons: {
          contextButton: {
            menuItems: ['downloadPNG', 'downloadSVG', 'downloadPDF', 'separator', 'typeChange', 'label']
          }
        }
      }
    },
    chartData: [{
      name: 'Jane',
      data: [1, 0, 4]
    }, {
      name: 'John',
      data: [5, 7, 3]
    }]
  });

  _exports.default = _default;
});
define("hmis/helpers/changeset", ["exports", "ember-changeset/helpers/changeset"], function (_exports, _changeset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _changeset.default;
    }
  });
  Object.defineProperty(_exports, "changeset", {
    enumerable: true,
    get: function () {
      return _changeset.changeset;
    }
  });
});
define("hmis/adapters/application", ["exports", "ember-data", "ember-simple-auth/mixins/data-adapter-mixin", "hmis/utils/server-util"], function (_exports, _emberData, _dataAdapterMixin, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ENV from '../config/environment';
  var _default = _emberData.default.RESTAdapter.extend(_dataAdapterMixin.default, {
    namespace: _serverUtil.apiNamespace,
    host: _serverUtil.apiUrl,
    //---deployment
    // host: ENV.APP.apiURL, //---deployment
    authorizer: 'authorizer:oauth2',
    // headers: { withCredentials: true } ,
    // crossOriginWhiteList:['*'],
    // session: Ember.inject.service('session'), 
    // authorize(xhr) {
    // 	let { access_token } = this.get('session.data.authenticated');
    // 	console.log('access_token: ',access_token);
    // 	xhr.setRequestHeader('Authorization', `Bearer ${access_token}`);
    // },
    ajaxOptions: function (url, type, hash) {
      hash = hash || {};

      if (type == 'PUT' || type == 'PATCH') {
        type = 'POST'; // hash.data['_method'] = type;
        // dataType: 'JSON';
      } // console.log("From ajax options: ", hash.data);


      return this._super(url, type, hash);
    }
  });

  _exports.default = _default;
});
define("hmis/components/managers/vitals-batch-manager", ["exports", "hmis/utils/model-table-theme"], function (_exports, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      'title': 'Vital type',
      "propertyName": "vitalTypeName",
      "editable": false
    }, {
      "propertyName": "value",
      "component": "tableEditable"
    }, {
      title: 'Measure',
      "propertyName": "vitalType.unitOfMeasure.name",
      "editable": false
    } // {
    //   "title": "Edit",
    //   "component": "editRow",
    //   "editable": false
    // },
    // {
    //   "title": "Delete",
    //   "component": "manageRow",
    //   "editable": false
    // }
    ]
  });

  _exports.default = _default;
});
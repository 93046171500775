define("hmis/components/controls/image-chooser-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    label: "Select Image",
    buttonText: "Choose image",
    imageName: "no image selected",
    imagePath: '',
    // image:null,
    actions: {
      readURL(event) {
        // alert(input);
        var reader = new FileReader();
        var self = this;

        reader.onload = function () {
          console.log(event.target.files[0]);
          self.set('imagePath', reader.result); // console.log('reader.result', reader.result)
          // self.set('image', reader.result)
          // self.set('image', reader.result)

          self.set('imageName', event.target.files[0].name); // if(self.get('onFileLoad'))
          // {
          //     self.attr.onFileLoad(event.target.files[0].name, reader.result)
          // }

          let action = self.get('onChange');

          if (action) {
            action(self.get('imagePath'));
          }
        };

        reader.readAsDataURL(event.target.files[0]); // console.log(event);
      }

    }
  });

  _exports.default = _default;
});
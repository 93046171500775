define("hmis/controllers/hmis-app/records/index", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/page-error-handler", "hmis/utils/common-data-columns"], function (_exports, _modelTableTheme, _pageErrorHandler, _commonDataColumns) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const NEW_PATIENT = 1;
  const emergencyResolveOptions = [{
    title: 'New Patient Record',
    description: 'Convert emergency patient to a new patient record',
    id: NEW_PATIENT
  }, {
    title: 'Merge with existing Record',
    description: 'merge emergency patient with an existing patient record',
    id: 2
  }];

  var _default = Ember.Controller.extend(_pageErrorHandler.default, {
    init() {
      this._super(...arguments);
    },

    tabIndex: 0,
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    app: Ember.inject.service(),
    store: Ember.inject.service(),
    data: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    routing: Ember.inject.service('-routing'),
    selectedItems: [],
    allowSelect: true,
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    isOneItemSelected: Ember.computed("selectedItems.length", function () {
      if (this.get('selectedItems.length') == 1) return this.get('selectedItems.length');
    }),
    patients: Ember.computed("model.records", function () {
      return this.get('model.records');
    }),
    selectedCount: Ember.computed('selectedItems', 'selectedItems.length', function () {
      return this.get('selectedItems.length');
    }),
    archivePCount: Ember.computed('selectedItems', 'selectedItems.length', function () {
      if (this.get('selectedItems.length') > 0) return 'Archive ' + this.get('selectedItems.length') + ' Patient(s)';else return 'Archive Patient(s)';
    }),
    optionTypes: Ember.computed('majorItem', function () {
      return this.get('store').query('diagnosis-type', {
        filter: {
          mdgCode: this.get('majorItem.id') ? this.get('majorItem.id') : 'MEDI'
        }
      }).then(function (recs) {
        var gDiag = [];
        recs.forEach(diag => {
          var gname = diag.get('gdrgCode') + ' - ' + diag.get('genericName');
          gDiag.push({
            groupName: gname,
            options: diag.get('diagnosisSubTypes').toArray()
          }); //console.log('diagnosisSubTypes',diagnosisSubTypes)
        });
        return gDiag.toArray();
      });
    }),
    // emergencyWard:computed(function(){
    //   return this.get('store').findAll('facility-configuration')
    //   .then(function(conf) {
    //     let ward = conf.get("firstObject");
    //     return ward.emergencyUnit
    //   });
    // }),
    onConfirm: 'deleteRecord',
    actions: {
      addRecord() {
        console.log('icd item');
      },

      myMatcher(subDiag, term) {
        let searchProp = "".concat(subDiag.get('icdName'), " ").concat(subDiag.get('icdCode'), " ").concat(subDiag.get('diagnosisTypeId.genericName'), " ").concat(subDiag.get('diagnosisTypeId.gdrgCode')); // console.log(searchProp)

        return searchProp.toLowerCase().indexOf(term.toLowerCase());
      },

      getICDDiagnosis(item) {
        console.log('icd item', item);
        this.set('diagnosisType', item);
        this.set('icdDiagnosis', item);
      },

      getGender(item) {
        console.log('gender item', item); //this.set('diagnosisType', item);
        //this.set('icdDiagnosis', item);
      },

      onSaved(record) {
        this.notify.success('Saved Successfully');
        this.send('refreshModel');
      },

      archivedRecords() {
        let that = this; // this.get('ajaxRequest').getArchivedPatients({
        //   success: function success(response) {
        //     console.log('arcPatients: ',response)
        //     //response.set('firstName',response.firstName+" "+response.lastName)
        //     that.set('arcPatients',response.toArray())
        //   },
        //   error: function error(_error) { return _error}
        // })

        this.send('refreshModel');
        this.set('deactivatedModal', true);
      },

      activatePatient(record) {
        console.log(record.id);
        let that = this;
        this.get('ajaxRequest').activateArchivedPatient(record.id, {
          success: response => {
            // this.get('patients').pushObject(record)
            that.get('model.arcPatients').removeObject(record);
            that.notify.success('User Activated Successfully');
            that.send('refreshModel');
          },
          error: error => {
            console.log(error);
          }
        });
      },

      onCancel() {
        console.log('onCanceled');
        this.send('refreshModel');
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to archive a patient (' + record.get('fullName') + ')');
        this.set('onAlert', true);
      },

      deleteNow(recordTag) {
        this.get("selectedItems").removeObject(recordTag);
        recordTag.destroyRecord(); // reload stats data

        setTimeout(this.get('ajaxRequest').makeRequest('POST', "/dashboardStatistics/day", {}, {
          success: response => {
            this.set('app.dashboardStats', response);
            console.log('archive donfirmed: data auto load');
          },
          error: error => {
            console.log('data auto load error');
          }
        }), 10000);
      },

      createPatient() {
        this.patientAlert.new({}, saved => {
          this.send('refreshModel');
        });
      },

      editPatient(record) {
        this.patientAlert.edit(record, {}, saved => {
          this.send('refreshModel');
        });
      },

      createEPatient() {
        this.set('editEPatient', {
          isConscious: '0'
        });
        this.set('ePatientDialog', true);
      },

      editEPatient(record) {
        this.set('editEPatient', record);
        this.set('ePatientDialog', true);
      },

      onEmSaved(record) {
        // alert('dd')
        this.data.reloadEmergencyPatients();
        this.notify.success('Saved Successfully');
      },

      rowDoubleClick(index, record) {
        this.send("viewRecord", record);
      },

      resolveEPatient(record) {
        //as new or existing patient
        this.notify.confirmOptions({
          options: emergencyResolveOptions
        }, selectedOption => {
          //as new
          if (selectedOption.id == NEW_PATIENT) {
            //open new patient form
            this.patientAlert.new({
              title: 'Fill additional data to Proceed',
              saveOnSubmit: false,
              data: record.toPatientData()
            }, modifiedRecord => {
              modifiedRecord.genderId = Ember.get(modifiedRecord, 'gender.id');
              delete modifiedRecord.gender;
              this.set('resolvingEPatient', true);
              this.patientAlert.startWaiting(); //save

              this.appAjax.post("resolveEmergencyPatient/new/".concat(record.id), modifiedRecord).then(res => {
                this.patientAlert.stopWaiting();
                this.patientAlert.close();
                this.send('refreshModel');
                this.set('resolvingEPatient', false);
                this.notify.success('Patient record has been created');
              }).catch(err => {
                this.notify.error(err);
                this.set('resolvingEPatient', false), this.patientAlert.stopWaiting();
              });
            });
          } //as existing
          else {
              //pick patient
              this.data.serverTable({
                modelName: 'patient',
                columns: _commonDataColumns.patientPickerColumns,
                title: 'Select the Existing Patient Record',
                query: {
                  filter: {}
                }
              }, patient => {
                //request confirmation
                this.notify.confirm({
                  title: 'Confirmation!',
                  message: "About to merge Emergency patient: '".concat(record.firstName, " ").concat(record.lastName, "' with the patient record of '").concat(patient.fullName, "'.")
                }, () => {
                  this.set('resolvingEPatient', true); //save        

                  this.appAjax.post("resolveEmergencyPatient/existing/".concat(record.id, "/").concat(patient.id), {}).then(data => {
                    this.send('refreshModel');
                    this.set('resolvingEPatient', false);
                    this.notify.success('Patient record has been merged');
                  }).catch(err => {
                    this.notify.error(err);
                    this.set('resolvingEPatient', false);
                  });
                });
              });
            }
        });
      },

      viewRecord(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.records.patient", [rid]);
      },

      deleteRecords() {
        let pCount = parseInt(this.get("selectedItems").length);
        this.notify.confirm({
          title: "Attempting to archive ".concat(pCount, " patient").concat(pCount > 1 ? 's' : '')
        }, () => {}); // this.set('onConfirm','deleteRecordsNow')
        // this.set('alertTitle','Attempting to archive '+pCount+' patient'+(pCount>1?'s':''))
        // this.set('onAlert',true)
      },

      deleteRecordsNow() {
        this.get("selectedItems").forEach((patient, index) => {
          patient.destroyRecord();
        });
        this.send('refreshModel');
        this.set("selectedItems", []);
      },

      createVisit() {
        let record = this.get('selectedItems').get('firstObject');
        this.get("routing").transitionTo("hmis-app.records.visit", [record.id]);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: _commonDataColumns.patientSelectableColumns,
    emColumns: [{
      "propertyName": "firstName"
    }, {
      "propertyName": "lastName"
    }, {
      "propertyName": "wardName",
      title: 'Ward'
    }, {
      "propertyName": "genderName",
      title: 'Gender'
    }, {
      "propertyName": "consciousState",
      title: 'Conscious state'
    }, {
      "propertyName": "note"
    }, {
      "title": "Actions",
      "component": "manageRow"
    }],
    columns1: [{
      "propertyName": "folderNumber"
    }, {
      "propertyName": "title"
    }, {
      "propertyName": "firstName"
    }, {
      "propertyName": "lastName"
    }, {
      "propertyName": "patientStatus"
    }, {
      "propertyName": "age"
    }, {
      "propertyName": "genderName",
      title: 'Gender'
    }, {
      "propertyName": "physicalAddress"
    }, {
      "propertyName": "phoneNumber"
    }, {
      "title": "Actions",
      "component": "manageRow"
    }]
  });

  _exports.default = _default;
});
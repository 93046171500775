define("hmis/controllers/hmis-app/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    actions: {
      back() {
        history.back();
      },

      submitForm() {
        this.get('submitForm')();
      }

    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/records/patient/sharing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    data: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    session: Ember.inject.service(),

    setupController(controller, model) {
      this._super(controller, model);

      controller.set('loading', true);
      controller.set('hasChange', false);

      if (this.get('data.cache.shareConsents.pat' + model.patient.id)) {
        controller.set('answerRecord', this.get('data.cache.shareConsents.pat' + model.patient.id));
        controller.set('patientConsents', this.get('data.cache.shareConsents.pat' + model.patient.id).toAnswer());
        controller.set('loading', false);
      } else {
        this.store.query('sharing-consent', {
          filter: {
            patientId: model.patient.id
          }
        }).then(recs => {
          controller.set('loading', false);

          if (recs.get('firstObject')) {
            this.set('data.cache.shareConsents.pat' + model.patient.id, recs.get('firstObject'));
            controller.set('answerRecord', recs.get('firstObject'));
            controller.set('patientConsents', recs.get('firstObject').toAnswer());
          } else {
            controller.set('answerRecord', null);
            controller.set('patientConsents', {});
          }
        }, error => {
          controller.set('pageError', error);
        });
      }

      if (this.get('data.cache.patientRelatives.pat' + model.patient.id)) {
        controller.set('patientRelatives', this.get('data.cache.patientRelatives.pat' + model.patient.id));
      } else {
        this.get('ajaxRequest.makeAuthorizedRequest')(this, 'GET', "/simpleJSONs/patient_families/" + model.patient.id, null, {
          success: response => {
            this.set('data.cache.patientRelatives.pat' + model.patient.id, response);
            controller.set('patientRelatives', response);
          },
          error: error => {
            controller.set('pageError', error);
          }
        });
      }
    },

    model(params) {
      var curPatient = this.modelFor("hmis-app.records.patient");
      return Ember.RSVP.hash({
        patient: curPatient,
        consentGroupList: this.get('data.consentGroupList')
      });
    }

  });

  _exports.default = _default;
});
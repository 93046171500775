define("hmis/controllers/hmis-app/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('router'),
    chartTitle: 'Diagnosis by Region',

    //theme: defaultTheme,
    init() {
      this._super(...arguments);

      this.set('appointments', []);
      this.set('chartTypes', this.get('reports').getChartTypesMap());
      if (!this.app.loadedDashbord) this.loadPeriodicStats(0);
    },

    fakeCounts: ['', '', '', '', ''],
    fakeCharts: ['', '', '', '', ''],
    chartTypes: [],
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    reports: Ember.inject.service(),
    app: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    dashbordStatsIndex: 0,
    dashbordChartIndex: 0,
    loadingDashboardPeriodic: false,

    loadPeriodicStats(periodIndex) {
      this.set('loadingDashboardPeriodic', true); // this.get('app').startLoading()

      this.app.loadDashboardPeriodicStats(periodIndex, res => {
        this.set('loadingDashboardPeriodic', false);
        this.get('app').stopLoading();
      }, error => {
        this.set('loadingDashboardPeriodic', false);
        this.get('app').stopLoading();
      }); // this.get('ajaxRequest').makeRequest('POST', "/dashboardStatistics/"+(periodIndex==0?'day':'week'), {}, {
      //   success: (response)=>{
      //     this.set('app.dashboardStats', response)
      //     this.set('loadingDashboard', false)
      //     this.get('app').stopLoading()
      //   },
      //   error:(error)=>{
      //     this.set('loadingDashboard', false)
      //     this.get('app').stopLoading()
      //   }
      // })
    },

    isLoadingFixed: Ember.computed('app.dashboardFixedStats', function () {
      if (!this.app.get('dashboardFixedStats')) return true;
      return false;
    }),
    isLoadingPeriodic: Ember.computed('app.dashboardPeriodicStats', 'loadingDashboardPeriodic', function () {
      if (!this.app.get('dashboardPeriodicStats')) return true;
      return this.get('loadingDashboardPeriodic');
    }),
    actions: {
      addFacility() {
        this.set('editFacilityRecord', {});
        this.set('editFacilityRecordDialog', true);
      },

      addUser() {
        this.set('editUserRecord', {});
        this.set('editUserRecordDialog', true);
      },

      onFacilitySaved() {
        this.get("routing").transitionTo("hmis-app.facility-management");
      },

      onUserSaved() {
        this.get("routing").transitionTo("hmis-app.users");
      },

      dashboardIndexChange(index) {
        this.set('dashbordStatsIndex', index);
        this.set('app.dashbordStatsIndex', index);
        this.loadPeriodicStats(index);
      },

      dashboardChartIndexChange(index) {
        this.set('dashbordChartIndex', index);
      }

    }
  });

  _exports.default = _default;
});
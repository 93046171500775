define("hmis/components/stats-container", ["exports", "hmis/mixins/page-error-handler", "hmis/mixins/ref-component"], function (_exports, _pageErrorHandler, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_pageErrorHandler.default, _refComponent.default, {
    classNames: 'stats-container',
    classNameBindings: ['fullHeight:full-height', 'styled'],
    styled: true,
    breadcrumbs: Ember.computed('pages.@each', function () {
      let pages = this.get('pages');
      if (!this.get('multiPages')) return [];
      let bc = [];

      for (var i = 0; i < pages.length; i++) {
        bc.pushObject(pages[i].title);
      }

      return bc;
    }),

    startLoading() {
      this.set('waiting', true);
    },

    stopLoading() {
      this.set('waiting', false);
    },

    pages: Ember.computed(function () {
      return [{
        title: this.get('title'),
        component: this.get('iniComponent'),
        props: this.get('iniComponentProps')
      }];
    }),
    pageIndex: 0,

    addPage(component, title, props) {
      this.get('pages').pushObject({
        component: component,
        title: title,
        props: props
      });
      this.set('pageIndex', this.get('pages').length - 1);
    },

    actions: {
      breadCrumbClick(title, index) {
        if (this.get('multiPages')) {
          this.set('pages', this.get('pages').slice(0, index + 1));
          this.set('pageIndex', index);
        } else {
          this.set('breadcrumbs', this.get('breadcrumbs').slice(0, index + 1));
          this.set('breadcrumbIndex', index);
        }
      }

    }
  });

  _exports.default = _default;
});
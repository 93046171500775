define("hmis/routes/hmis-app/admin-settings/insurance-management", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      return Ember.RSVP.hash({
        insuranceCompanies: this.get('store').query('insurance-company', {
          pageSize: 25
        }),
        insuranceType: this.get('store').query('insurance-type', {
          pageSize: 25
        })
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
define("hmis/components/questionnaire/q-edit-item", ["exports", "hmis/utils/questionnaire"], function (_exports, _questionnaire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['q-edit-item'],
    classNameBindings: ['isSub:is-sub', 'layout'],
    isSub: false,
    layout: Ember.computed(function () {
      return !this.isSub ? 'flex-50 flex-md-100 flex-xs-100 flex-sm-100' : '';
    }),

    didInsertElement() {
      this._super(...arguments);

      this.set("isIni", true);
    },

    isIni: false,
    valueChangedObserver: Ember.observer('isIni', 'valueSet.valueChanged', 'item.enableWhen', function () {
      this.set('isEnabled', this.checkIsEnabled());
      this.set('item.isEnabled', this.isEnabled);

      if (this.item.calculation && this.item.calculation.length > 0) {
        try {
          this.valueSet[this.item.link] = (0, _questionnaire.getCalculatedValue)(this.patientModel, this.item, this.questionsMap, this.valueSet);
          console.log('this.valueSet[this.item.link]', this.valueSet[this.item.link]);
        } catch (error) {
          console.error(error);
          this.valueSet[this.item.link] = null;
        }
      } // console.log('this.item.calculation', this.item.calculation);

    }),

    setLinkValue(link, value) {
      if (value != this.valueSet[link]) {
        this.valueSet[link] = value;
      }
    },

    checkIsEnabled() {
      if (!this.item.enableWhen) return true;

      for (let index = 0; index < this.item.enableWhen.length; index++) {
        const when = this.item.enableWhen[index]; // console.log('pass:', pass(this.valueSet[when.question], when.operator ,when.answer));

        if (!(0, _questionnaire.pass)(this.get('valueSet')[when.question], when.operator, when.answer)) {
          return false;
        }
      }

      return true;
    },

    actions: {
      onChange(value) {
        if (this.get('onChange')) {
          this.get('onChange')(this.get('item'));
        }
      }

    }
  });

  _exports.default = _default;
});
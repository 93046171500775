define("hmis/templates/sign-in/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "N4rRqblK",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"controls/change-password\",null,[[\"show\",\"unClosable\"],[[24,[\"changePassword\"]],false]]],false],[0,\"\\n\"],[4,\"controls/paper-form\",null,[[\"class\",\"onValidFormSubmit\"],[\"sign-form\",[28,\"action\",[[23,0,[]],\"authenticate\"],null]]],{\"statements\":[[0,\"\\n\"],[0,\"\\n\"],[7,\"h3\",true],[10,\"style\",\"text-transform: uppercase;\"],[8],[0,\"SIGN IN\"],[9],[0,\"\\n\\n\"],[1,[28,\"paper-input\",null,[[\"label\",\"disabled\",\"required\",\"value\",\"onChange\"],[\"Username\",[24,[\"authenticating\"]],true,[24,[\"username\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"username\"]]],null]],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"paper-input\",null,[[\"label\",\"disabled\",\"required\",\"type\",\"value\",\"onChange\"],[\"Password\",[24,[\"authenticating\"]],true,\"password\",[24,[\"password\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"password\"]]],null]],null]]]],false],[0,\"\\n\\n\"],[1,[28,\"submit-button\",null,[[\"text\",\"onclick\",\"waiting\"],[\"LOGIN\",[28,\"action\",[[23,0,[]],\"submitForm\"],null],[24,[\"authenticating\"]]]]],false],[0,\"\\n\\n    \"],[7,\"p\",true],[10,\"style\",\"text-align: center; margin-top: 13px;\"],[11,\"class\",[29,[[28,\"if\",[[24,[\"error\"]],\"red-color\",\"green-color\"],null]]]],[8],[1,[22,\"info\"],false],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/sign-in/index.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/patient/visits", ["exports", "ember-data", "hmis/utils/model-table-theme"], function (_exports, _emberData, _modelTableTheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),

    init() {
      this._super(...arguments);
    },

    input_width: "flex-45 flex-xs-100 flex-sm-100",
    actions: {
      rowClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        Ember.set(record, 'patientName', this.model.patientName);
        this.visitViewer.show(record);
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    columns: [{
      title: 'Purpose',
      "propertyName": "purposeName"
    }, {
      title: 'Date',
      "propertyName": "dateF"
    }, {
      "title": "",
      "component": "manageRow"
    }]
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/users/user", ["exports", "hmis/utils/date-util", "hmis/mixins/visit-care-controller"], function (_exports, _dateUtil, _visitCareController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { requestAdmission, requestDischarge } from '../../../../utils/request-utils/ipd';
  var _default = Ember.Controller.extend(_visitCareController.default, {
    routing: Ember.inject.service('router'),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    consultationService: Ember.inject.service('consultation'),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    actions: {
      empty() {},

      takeVitals() {
        //console.log('ll');
        this.get('takeNewVitals')();
      },

      admitRequestClick() {
        this.set('onAdmitAlert', true);
      },

      back() {
        history.back(); // this.get("routing").transitionTo("hmis-app.consultation");
      },

      onRefFileAdded(file) {
        this.set('refUploadFiles', []);
        this.get('refUploadFiles').pushObject(file);
      },

      onMenuClick(route, title) {
        try {
          this.get("routing").transitionTo(route);
          this.set('title', title);
        } catch (err) {
          console.log(err);
        }
      }

    }
  });

  _exports.default = _default;
});
define("hmis/models/module-permission", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    // description: DS.attr(),
    // allow: DS.attr(),
    // route: DS.attr(),
    // isChildMenu: DS.attr(),
    // parentMenu: DS.belongsTo('module-permission', { inverse: 'childMenus' }),
    icon: _emberData.default.attr(),
    //will be deprecated
    relatedOperationPermissions: (0, _relationships.hasMany)('operation-permission') // childMenus: hasMany('module-permission', { inverse: 'parentMenu' }),

  });

  _exports.default = _default;
});
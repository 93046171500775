define("hmis/authorizers/oauth2", ["exports", "ember-simple-auth/authorizers/oauth2-bearer"], function (_exports, _oauth2Bearer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2Bearer.default.extend({// authorize(data, block) {
    //     // alert('hii auth');
    //   const { access_token }  = data;
    //   console.log('auth data: ', data);
    //   if (!isEmpty(access_token)) {
    //     block('Authorization', `Bearer ${access_token}`);
    //   }
    // }
  });

  _exports.default = _default;
});
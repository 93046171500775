define("hmis/components/stack-pages/ins-man-companies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    types: Ember.computed(function () {
      if (!this.get('loadingList')) // this.set('loadingList', true)
        return this.get('store').findAll('insurance-type', {}).then(recs => {
          // this.set('loadingList', false)
          return recs;
        }, err => {// this.set('loadingList', false)
        });
    }),
    newRecord: {},
    actions: {
      deleteRecord(record) {
        record.destroyRecord();
      },

      saveNewRecord() {
        this.set('listWait', true);
        let newRecord = this.get('store').createRecord('insurance-company', this.get('newRecord'));
        newRecord.save().then(record => {
          // this.get('companies').pushObject(record)
          this.set('newRecord', {});
          this.notify.success('Saved Successfully');
          this.set('listWait', false);
        }).catch(failure => {
          console.error(failure);
          this.set('listWait', false);
          this.notify.error('Unable to Save');
        });
      },

      saveRecord(record) {
        record.save();
      },

      onItemClick(record) {
        if (this.get('onCompanyClick')) this.get('onCompanyClick')(record);
      }

    }
  });

  _exports.default = _default;
});
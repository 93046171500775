define("hmis/services/server-uploads", ["exports", "hmis/config/environment", "hmis/utils/date-util", "hmis/utils/server-util"], function (_exports, _environment, _dateUtil, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const url = _serverUtil.serverUrl;

  const dowloadUrl = function () {
    return _serverUtil.serverUrl + '/storage';
  }();

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    store: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    downloadUrl: Ember.computed(function () {
      return dowloadUrl;
    }),

    makeAuthorizedUploadRequest(context, requestType = 'POST', urlExt, file, data, callBack) {
      // context.get('session').authorize('authorizer:oauth2', (headerName, headerValue) => {
      // let token = context.get('session.data.authenticated.access_token');
      // let headers = {
      //   Authorization: 'Bearer ' + token,
      // }
      //   file.upload(url+urlExt,{
      //     headers: headers,
      //     data:data,
      //     method:'POST',
      //     fileKey:'picture'
      //   }).then(
      //     (response, status, xhr) => {
      //       if(callBack)
      //         callBack.success(response, status, xhr)
      //     },
      //     (error)=>{
      //       if(callBack)
      //         callBack.error(error)
      //         console.log("Unable to sent request: ",error)
      //     }
      //   );
      context.appAjax.postFile(urlExt, file, data, 'picture').then((response, status, xhr) => {
        if (callBack) callBack.success(response, status, xhr);
      }, error => {
        if (callBack) callBack.error(error);
        console.log("Unable to sent request: ", error);
      }); // });
    },

    makeAuthorizedBulkUploadRequest(context, requestType = 'POST', urlExt, file, data, callBack) {
      context.appAjax.postFile(urlExt, file, data, 'file').then((response, status, xhr) => {
        if (callBack) callBack.success(response, status, xhr);
      }, error => {
        if (callBack) callBack.error(error);
        console.log("Unable to sent request: ", error);
      }); // });
    },

    uplaodFacilityImage(facilty, file, callBack) {
      this.get('makeAuthorizedUploadRequest')(this, 'POST', "/uploadFacilityLogo/" + facilty.get('id'), file, null, {
        success: response => {
          console.log(response);
          facilty.set('picture', response.body.picture);
          console.log("request success: ", response);
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    uplaodUserImage(user, file, callBack) {
      this.get('makeAuthorizedUploadRequest')(this, 'POST', "/uploadUserImage/" + user.get('id'), file, null, {
        success: response => {
          console.log(response);
          user.set('picture', response.body.picture);
          console.log("request success: ", response);
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    uplaodPatientDocument(doc, file, callBack) {
      this.get('makeAuthorizedUploadRequest')(this, 'POST', "/patientAttachments", file, {
        name: file.get('name'),
        patientId: doc.get('id'),
        type: file.get('type')
      }, {
        success: response => {
          let store = this.get('store');
          let nPayload = store.normalize('patient-attachment', response.body);
          let doc = store.push(nPayload);
          if (callBack) callBack.success(response);
        },
        error: xhr => {
          if (callBack) callBack.error(xhr);
        }
      });
    },

    uplaodProcedureDocument(procedure, file, callBack) {
      this.get('makeAuthorizedUploadRequest')(this, 'POST', "/procedureDocuments", file, {
        procedure: procedure.get('id'),
        name: file.get('name'),
        visit: procedure.get('visit.id'),
        patientId: procedure.get('patientId.id'),
        type: file.get('type')
      }, {
        success: response => {
          let store = this.get('store');
          let nPayload = store.normalize('procedure-document', response.body);
          let doc = store.push(nPayload); //procedure.get('documents').pushObject(doc)

          if (callBack) callBack.success(response);
        },
        error: xhr => {
          if (callBack) callBack.error(xhr);
        }
      });
    },

    uplaodAccreditationDocument(accreditation, file, callBack) {
      this.get('makeAuthorizedUploadRequest')(this, 'POST', "/facilityAccreditations", file, {
        type: accreditation.type,
        accreditationNumber: accreditation.accreditationNumber,
        issueDate: accreditation.issueDate,
        expiryDate: accreditation.expiryDate,
        accreditationFee: accreditation.accreditationFee
      }, {
        success: response => {
          // let store=this.get('store')
          // let nPayload = store.normalize('facility-accreditation', response.body);
          // let doc = store.push(nPayload)
          if (callBack) callBack.success(response);
        },
        error: xhr => {
          if (callBack) callBack.error(xhr);
        }
      });
    },

    uplaodUltrasoundDocument(ultrasound, file, callBack) {
      this.get('makeAuthorizedUploadRequest')(this, 'POST', "/ultrasoundDocuments", file, {
        ultrasound: ultrasound.get('id'),
        name: file.get('name'),
        type: file.get('type'),
        visit: ultrasound.get('visit.id'),
        patientId: ultrasound.get('patientId.id')
      }, {
        success: response => {
          let store = this.get('store');
          let nPayload = store.normalize('ultrasound-document', response.body);
          let doc = store.push(nPayload);
          ultrasound.get('documents').pushObject(doc);
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    uplaodXrayDocument(xray, file, callBack) {
      this.get('makeAuthorizedUploadRequest')(this, 'POST', "/xrayDocuments", file, {
        xray: xray.get('id'),
        name: file.get('name'),
        type: file.get('type'),
        visit: xray.get('visit.id'),
        patientId: xray.get('patientId.id')
      }, {
        success: response => {
          let store = this.get('store');
          let nPayload = store.normalize('xray-document', response.body);
          let doc = store.push(nPayload);
          xray.get('documents').pushObject(doc);
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    uploadBillableItems(file, billableItemsName, insuranceProduct, withPrices, callBack) {
      let data = {};
      if (insuranceProduct) data['insuranceProduct'] = insuranceProduct;
      this.get('makeAuthorizedBulkUploadRequest')(this, 'POST', "/" + billableItemsName + "ExcelUpload" + (withPrices ? 'WithPrices' : ''), file, data, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    uploadMedicines(file, insuranceProduct, withPrices, callBack) {
      let data = {};
      if (insuranceProduct) data['insuranceProduct'] = insuranceProduct;
      this.get('makeAuthorizedBulkUploadRequest')(this, 'POST', "/medicinesExcelUpload" + (withPrices ? 'WithPrices' : ''), file, data, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    uploadProcedureTypes(file, insuranceProduct, withPrices, callBack) {
      let data = {};
      if (insuranceProduct) data['insuranceProduct'] = insuranceProduct;
      this.get('makeAuthorizedBulkUploadRequest')(this, 'POST', "/procedureTypesExcelUpload" + (withPrices ? 'WithPrices' : ''), file, data, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    uploadXrayTypes(file, insuranceProduct, withPrices, callBack) {
      let data = {};
      if (insuranceProduct) data['insuranceProduct'] = insuranceProduct;
      this.get('makeAuthorizedBulkUploadRequest')(this, 'POST', "/xrayTypesExcelUpload" + (withPrices ? 'WithPrices' : ''), file, data, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    uploadUltrasoundTypes(file, insuranceProduct, withPrices, callBack) {
      let data = {};
      if (insuranceProduct) data['insuranceProduct'] = insuranceProduct;
      this.get('makeAuthorizedBulkUploadRequest')(this, 'POST', "/ultrasoundTypesExcelUpload" + (withPrices ? 'WithPrices' : ''), file, data, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    uploadInvestigationTypes(file, insuranceProduct, withPrices, callBack) {
      let data = {};
      if (insuranceProduct) data['insuranceProduct'] = insuranceProduct;
      this.get('makeAuthorizedBulkUploadRequest')(this, 'POST', "/investigationTypesExcelUpload" + (withPrices ? 'WithPrices' : ''), file, data, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    },

    uploadDiagnosisTypes(file, insuranceProduct, withPrices, callBack) {
      let data = {};
      if (insuranceProduct) data['insuranceProduct'] = insuranceProduct;
      this.get('makeAuthorizedBulkUploadRequest')(this, 'POST', "/diagnosisTypesExcelUpload" + (withPrices ? 'WithPrices' : ''), file, data, {
        success: response => {
          if (callBack) callBack.success(response);
        },
        error: error => {
          if (callBack) callBack.error(error);
        }
      });
    }

  });

  _exports.default = _default;
});
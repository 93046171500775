define("hmis/services/app-ajax", ["exports", "jquery", "hmis/config/environment", "ember-data", "hmis/utils/file-types-util", "hmis/utils/server-util"], function (_exports, _jquery, _environment, _emberData, _fileTypesUtil, _serverUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const url = _serverUtil.serverUrl;

  var _default = Ember.Service.extend({
    session: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    store: Ember.inject.service(),
    notify: Ember.inject.service(),
    cache: Ember.inject.service(),
    serverResponse: Ember.inject.service(),

    postFile(urlExt, file, data, fileKey = 'picture') {
      return new Promise((resolve, reject) => {
        let token = this.get('session.data.authenticated.access_token');
        let headers = {
          Authorization: 'Bearer ' + token
        };
        if (urlExt.charAt(0) != '/') urlExt = "/" + urlExt;
        file.upload(url + urlExt, {
          headers: headers,
          data: data,
          method: 'POST',
          fileKey: fileKey
        }).then((response, status, xhr) => {
          resolve(response.data ? response.data : response);
        }).catch(error => {
          reject(this.get('serverResponse').errorMessage(error));
          console.error("Unable to sent request: ", error);
        });
      });
    },

    downloadFile(urlExt, data, filename) {
      let token = this.get('session.data.authenticated.access_token');
      let headers = {
        Authorization: 'Bearer ' + token
      };
      if (urlExt.charAt(0) != '/') urlExt = "/" + urlExt;
      return _jquery.default.ajax({
        headers: headers,
        url: url + urlExt,
        method: 'GET',
        xhrFields: {
          responseType: 'blob'
        },
        data: data
      }).then(function (data) {
        if (!filename) filename = 'download';
        if (!filename.includes('.')) filename += "." + ((0, _fileTypesUtil.mimeTypeToExtension)(data.type) || '');
        console.log('filename', filename);
        console.log('data.type', data.type);
        console.log('mimeTypeToExtension(data.type)', (0, _fileTypesUtil.mimeTypeToExtension)(data.type)); // alert(data.type)

        var a = document.createElement('a');
        var url = window.URL.createObjectURL(data);
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      }).catch(err => {
        console.log(err);
      });
    },

    printFile(url, data = {}) {
      return this._print("GET", url, data);
    },

    postPrintFile(url, data = {}) {
      return this._print("POST", url, data);
    },

    _print(requestType, url, data) {
      return new Promise((resolve, reject) => {
        this.makeAuthorizedRequest(requestType, url, data, response => {
          console.log('b4 print', response);
          let w = window.open();
          w.document.write(response);
          setTimeout(function () {
            w.print();
            w.close();
          }, 250);
          resolve(response);
        }, error => {
          reject(error);
        });
      });
    },

    makeAuthorizedRequest(requestType, urlExt, data, successCallback, errorCallback) {
      let token = this.get('session.data.authenticated.access_token');
      let headers = {
        Authorization: 'Bearer ' + token
      };
      if (urlExt.charAt(0) != '/') urlExt = "/" + urlExt;
      return _jquery.default.ajax({
        headers: headers,
        type: requestType,
        url: url + urlExt,
        data: data
      }).then((response, status, xhr) => {
        if (successCallback) {
          return successCallback(response.data ? response.data : response, status, xhr);
        }
      }, error => {
        let derror = error.responseJSON || {}; //check for warning

        if (derror.isConfirmWarning == true) {
          let confirmOptions = Object.assign({}, derror.warning.confirmOptions ? derror.warning.confirmOptions : {});
          confirmOptions['warning'] = true;
          let sendCancelRequest = derror.warning.confirmOptions ? derror.warning.confirmOptions.sendCancelRequest : false;
          this.notify.confirmSimple(derror.warning.title, derror.warning.message, () => {
            this.replyWarning(requestType, derror, successCallback, errorCallback, 'confirm');
          }, () => {
            if (sendCancelRequest) {
              this.replyWarning(requestType, derror, successCallback, errorCallback, 'cancel');
            } else {
              return errorCallback("Action cancelled");
            }
          }, confirmOptions);
        } else {
          if (errorCallback) return errorCallback(error.responseJSON ? error.responseJSON : error);
        }
      });
    },

    replyWarning(requestType, derror, successCallback, errorCallback, action, _params = {}) {
      let errParams = Array.isArray(derror.warning.params) ? {} : derror.warning.params;
      let params = Object.assign(errParams || {}, _params);
      derror.passedWarningIds[derror.warningId] = action;
      params['_passedWarningIds_'] = derror.passedWarningIds;
      params['_currentWarningId_'] = derror.warningId;
      console.log('allalal', params);
      this.makeAuthorizedRequest(requestType, "/" + derror.warning.confirmUrl, params, (response, status, xhr) => {
        if (successCallback) return successCallback(response.data ? response.data : response, status, xhr);
      }, error => {
        if (errorCallback) return errorCallback(this.get('serverResponse').errorMessage(error));
      });
    },

    doArray(type, url, data, transformResponse) {
      return _emberData.default.PromiseArray.create({
        promise: new Promise((resolve, reject) => {
          this.makeAuthorizedRequest(type, url, data, response => {
            if (transformResponse) response = transformResponse(response);
            resolve(response);
          }, error => {
            reject(error);
          });
        })
      });
    },

    doObject(type, url, data, transformResponse) {
      return _emberData.default.PromiseObject.create({
        promise: new Promise((resolve, reject) => {
          this.makeAuthorizedRequest(type, url, data, response => {
            if (transformResponse) response = transformResponse(response);
            resolve(response);
          }, error => {
            reject(error);
          });
        })
      });
    },

    do(type, url, data, transformResponse) {
      return new Promise((resolve, reject) => {
        this.makeAuthorizedRequest(type, url, data, response => {
          if (transformResponse) response = transformResponse(response);
          resolve(response);
        }, error => {
          reject(error);
        });
      });
    },

    ajaxGet(url, data, transformResponse) {
      return this.do('GET', url, data, transformResponse);
    },

    getArray(url, data, transformResponse) {
      return this.doArray('GET', url, transformResponse);
    },

    getObject(url, data, transformResponse) {
      return this.doObject('GET', url, data, transformResponse);
    },

    post(url, data, transformResponse) {
      return this.do('POST', url, data, transformResponse);
    },

    delete(url, data, transformResponse) {
      return this.do('DELETE', url, data, transformResponse);
    },

    nomalizeData(model, data) {
      return this.store.normalize(model, data);
    },

    getCacheable(url, data, cacheLink, transformResponse) {
      return new Promise((resolve, reject) => {
        if (this.cache.get(cacheLink)) resolve(this.cache.get(cacheLink));else this.do("GET", url, data).then(response => {
          this.cache.set(cacheLink, response);
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      });
    },

    setCacheable(cacheLink, data) {
      this.cache.set(cacheLink, data);
    },

    getCacheableArray(url, data, cacheLink, transformResponse) {
      return _emberData.default.PromiseArray.create({
        promise: this.getCacheable(url, data, cacheLink, transformResponse)
      });
    },

    getCacheableObject(url, data, cacheLink, transformResponse) {
      return _emberData.default.PromiseObject.create({
        promise: this.getCacheable(url, data, cacheLink, transformResponse)
      });
    }

  });

  _exports.default = _default;
});
define("hmis/components/controls/paged-report-viewer", ["exports", "jquery", "hmis/mixins/high-chart-manager"], function (_exports, _jquery, _highChartManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import ReportController from "../../mixins/report-controller"
  var _default = Ember.Component.extend(_highChartManager.default, {
    selectedChartType: {
      id: 'stackBar',
      label: 'Stack bar',
      dataType: 'count',
      options: 'stackBarOptions',
      seriesProp: 'series'
    },
    showNoDataRowsColumns: true,
    tagName: '',
    tabIndex: 0,
    reportDataType: 'count',
    exportOptions: [{
      label: 'Excel xls',
      type: 'xlsx'
    }, {
      label: 'Csv',
      type: 'csv'
    }, {
      label: 'Text',
      type: 'txt'
    }],
    actions: {
      exportTable(type, dropdown) {
        dropdown.actions.close();
        var instance = (0, _jquery.default)('#report-table').tableExport({
          filename: 'table_%DD%-%MM%-%YY%',
          format: type,
          exportButtons: false
        });
        let exportData = instance.getExportData()['report-table'][type];
        instance.export2file(exportData.data, exportData.mimeType, exportData.filename, exportData.fileExtension);
      }

    }
  });

  _exports.default = _default;
});
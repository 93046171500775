define("hmis/components/viewers/administer-care-alert", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-alert", "hmis/constants/blood-groups", "hmis/mixins/ref-component"], function (_exports, _modelTableTheme, _formAlert, _bloodGroups, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, _refComponent.default, {
    modelName: 'ipd-care',
    store: Ember.inject.service('store'),
    notify: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    appData: Ember.inject.service(),
    currentUser: Ember.inject.service(),

    show(options, onSaved) {
      if (!options.visitId) {
        console.error('options.visitId not found');
        return;
      }

      this.set('record', {
        visitId: options.visitId,
        careType: 'medicine',
        user: this.currentUser.user
      });
      this.set('_show', true);
      this.set('onSaved', onSaved);
    },

    careType: ['medicine', 'blood', 'other'],
    medicineOptions: Ember.computed(function () {
      return this.store.query('medicine', {});
    }),
    bloodGroupOptions: Ember.computed(function () {
      return Object.values(_bloodGroups.BLOOD_GROUPS);
    }),

    didInsertElement() {
      this._super();

      this.set('recuser', this.get('currentUser.user'));
    },

    input_width: 'flex-45',
    actions: {
      back() {
        history.back();
      },

      changeCareType(type) {
        this.set('record.careType', type);
        this.set('record.value', null);
      },

      onRecordSubmit() {
        let record = this.record;
        if (record.careType == 'medicine') Ember.set(record, 'value', record.value.genericName);
        console.log('care rec', record);
        this.set('waiting', true);

        if (!this.get('record.id')) {
          record = this.get('store').createRecord('ipd-care', record);
        }

        record.save().then(rec => {
          this.set('record.value', null);
          this.set('record.note', null);

          if (this.get('onSaved')) {
            this.get('onSaved')(rec);
          }

          this.set('waiting', false);
          this.notify.success('Saved');
        }, err => {
          this.set('waiting', false);
          this.notify.error(err);
          console.log('error', err);
        });
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    docsColumns: [{
      "component": "controls/select-row-checkbox",
      "useFilter": false,
      "mayBeHidden": false //   "componentForSortCell": "select-all-rows-checkbox"

    }, {
      "propertyName": "firstName"
    }, {
      "propertyName": "lastName"
    }]
  });

  _exports.default = _default;
});
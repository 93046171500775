define("hmis/templates/components/controls/input-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HvEaicZH",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"flex-row\"],[8],[0,\"\\n    \"],[1,[28,\"paper-input\",null,[[\"class\",\"required\",\"min\",\"label\",\"type\",\"value\",\"onChange\"],[\"flex-1 padding-right-0\",[24,[\"required\"]],0,[28,\"concat\",[\"Enter \",[24,[\"label\"]]],null],\"number\",[24,[\"value\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null]],null]]]],false],[0,\"\\n\"],[4,\"app-select\",null,[[\"required\",\"class\",\"options\",\"selected\",\"label\",\"onChange\"],[[24,[\"required\"]],\"fi-options-width padding-left-0\",[24,[\"options\"]],[24,[\"selected\"]],[24,[\"label\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selected\"]]],null]],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"get\",[[23,1,[]],[24,[\"selectionName\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/input-options.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/components/forms/accredidation-alert", ["exports", "hmis/mixins/form-alert", "hmis/mixins/ref-component"], function (_exports, _formAlert, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, _refComponent.default, {
    modelName: 'user-accreditation',
    serverUploads: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    accreditationType: Ember.computed(function () {
      return this.get('store').query('accreditation-type', {
        include: ['accreditationInstitutionName']
      });
    }),
    selectedFile: null,

    show(record, onSuccessCallback, onFailedCallback = null) {
      this.set('record', record);
      this.set('_show', true);
      this.set('onSuccess', onSuccessCallback);
    },

    //accreditationType: ['a','b','c'],
    actions: {
      getDepartmentUnit(item) {//this.set('department', item);
      },

      uploadImage(file) {
        if (!this.get('multiple')) {
          this.set('selectedFile', file);
          file.readAsDataURL().then(url => {
            console.log('url', url);
            this.set('imgAcc', url);
            this.set('filePath', url);
            console.log('file', file);
          });
        } else {
          fileList.pushObject(file);
        }
      },

      onRecordSubmit(record) {
        let params = Object.assign({}, this.record);
        params.accreditaionTypeId = this.get('record.accreditationTypeId.id');
        this.appAjax.postFile('/userAccreditations', this.selectedFile, params).then(res => {
          this.notify.success("Saved Successfully");
          this.set('_show', false);
          if (this.onSuccess) this.onSuccess();
        }).catch(error => {
          this.notify.error("Unable to upload. Something went wrong" + error);
          console.log('error', error);
        });
      }

    }
  });

  _exports.default = _default;
});
define("hmis/mixins/high-chart-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    chartTypes: [{
      id: 'basicBar',
      label: 'Basic bar',
      dataType: 'count',
      options: 'basicBarOptions',
      seriesProp: 'series'
    }, {
      id: 'line',
      label: 'Line',
      dataType: 'count',
      options: 'lineOptions',
      seriesProp: 'series'
    }, {
      id: 'stackBar',
      label: 'Stack bar',
      dataType: 'count',
      options: 'stackBarOptions',
      seriesProp: 'series'
    }, {
      id: 'pieChart',
      label: 'Pie Chart',
      dataType: 'percentage',
      options: 'pieChartOptions',
      seriesProp: 'columnSeries',
      options2: 'pieChartRowOptions',
      series2Prop: 'rowSeries'
    }],

    chartTypesToMap() {
      let m = [];
      this.chartTypes.forEach(item => {
        m[item.id] = item;
      });
      return m;
    },

    chartRowTitle: Ember.computed('serverReport', 'rowReferenceName', function () {
      if (!this.get('serverReport')) return {};
      return this.get('serverReport.filterProps')[this.get('rowReferenceName')].label;
    }),
    chartColumnTitle: Ember.computed('serverReport', 'columnReferenceName', function () {
      if (!this.get('serverReport')) return '';
      return this.get('serverReport.filterProps')[this.get('columnReferenceName')].label;
    }),
    chartTitle: Ember.computed('chartRowTitle', 'chartColumnTitle', function () {
      if (!this.get('serverReport')) return '';
      return this.get('chartRowTitle') + ' by ' + this.get('chartColumnTitle') + ' (' + this.get('serverReport.reportDetails.startDate') + ' - ' + this.get('serverReport.reportDetails.endDate') + ')';
    }),
    lineOptions: Ember.computed('chartTitle', 'chatReportData', 'chatReportData.isFulfilled', 'chartColumnTitle', function () {
      let categories = this.get('chatReportData.categories');
      return {
        chart: {
          type: 'line'
        },
        title: {
          text: this.get('chartTitle')
        },
        xAxis: {
          categories: categories
        },
        yAxis: {
          min: 0,
          title: {
            text: this.get('isDashborad') ? '' : this.get('chartColumnTitle'),
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        tooltip: {
          valueSuffix: ''
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          },
          series: {
            stacking: ''
          }
        },
        legend: this.get('isDashborad') ? false : {
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 100
        }
      };
    }),
    basicBarOptions: Ember.computed('chartTitle', 'chatReportData', 'chatReportData.isFulfilled', 'chartColumnTitle', function () {
      let categories = this.get('chatReportData.categories');
      return {
        chart: {
          type: 'bar'
        },
        title: {
          text: this.get('chartTitle')
        },
        xAxis: {
          categories: categories
        },
        yAxis: {
          min: 0,
          title: {
            text: this.get('isDashborad') ? '' : this.get('chartColumnTitle'),
            align: 'high'
          },
          labels: {
            overflow: 'justify'
          }
        },
        tooltip: {
          valueSuffix: ''
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: true
            }
          },
          series: {
            stacking: ''
          }
        },
        legend: this.get('isDashborad') ? false : {
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 100
        }
      };
    }),
    stackBarOptions: Ember.computed('chartTitle', 'chatReportData', 'chatReportData.isFulfilled', 'chartColumnTitle', function () {
      let categories = this.get('chatReportData.categories');
      return {
        chart: {
          type: 'bar'
        },
        title: {
          text: this.get('chartTitle')
        },
        xAxis: {
          categories: categories
        },
        yAxis: {
          min: 0,
          title: {
            text: this.get('isDashborad') ? '' : this.get('chartColumnTitle'),
            align: ''
          },
          labels: {}
        },
        plotOptions: {
          bar: {
            dataLabels: {
              enabled: false
            }
          },
          series: {
            stacking: 'normal'
          }
        },
        legend: this.get('isDashborad') ? false : {
          reversed: true,
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 100
        }
      };
    }),
    pieChartOptions: Ember.computed('chartTitle', 'chartColumnTitle', 'chatReportData.isFulfilled', function () {
      let chatReportData = this.get('chatReportData');
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: this.get('chartColumnTitle')
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                color: Highcharts.theme && Highcharts.theme.contrastTextColor || 'black'
              }
            }
          }
        }
      };
    }),
    pieChartRowOptions: Ember.computed('chartTitle', 'chartRowTitle', 'chatReportData.isFulfilled', function () {
      let chatReportData = this.get('chatReportData');
      return {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: 'pie'
        },
        title: {
          text: this.get('chartRowTitle')
        },
        tooltip: {
          pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
              enabled: true,
              format: '<b>{point.name}</b>: {point.percentage:.1f} %',
              style: {
                color: Highcharts.theme && Highcharts.theme.contrastTextColor || 'black'
              }
            }
          }
        }
      };
    })
  });

  _exports.default = _default;
});
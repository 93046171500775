define("hmis/models/facility-period", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util"], function (_exports, _emberData, _relationships, _dateUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr(),
    break: _emberData.default.attr(),
    startTime: _emberData.default.attr(),
    endTime: _emberData.default.attr(),
    periodF: Ember.computed('name', 'startTime', 'endTime', function () {
      return this.get('startTime') + ' - ' + this.get('endTime');
    }),
    schedules: (0, _relationships.hasMany)('schedule'),
    duration: Ember.computed('startTime', 'endTime', function () {
      return _dateUtil.default.stringTimeDifference(this.get('startTime'), this.get('endTime'));
    })
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/access-m-user-o-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Mqc5QrHG",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[0,\"\\n    \"],[1,[28,\"controls/access-m-operation-permission\",null,[[\"operationPermission\",\"permissionChange\",\"removeClick\",\"removeMode\",\"view\",\"add\",\"edit\",\"inactivate\",\"allow\"],[[24,[\"operationPermission\"]],[28,\"action\",[[23,0,[]],\"onPermissionChange\"],null],[28,\"action\",[[23,0,[]],\"onRemove\"],null],true,[24,[\"userOperationPermission\",\"view\"]],[24,[\"userOperationPermission\",\"add\"]],[24,[\"userOperationPermission\",\"edit\"]],[24,[\"userOperationPermission\",\"inactivate\"]],[24,[\"userOperationPermission\",\"allow\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/access-m-user-o-permission.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/mixins/form-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    input_width: "flex-45 flex-xs-100 flex-sm-100",
    actions: {
      submitForm() {
        this.get('submitForm')(); // console.log( this.get('childComponents').isEvery('isValid'))
      }

    }
  });

  _exports.default = _default;
});
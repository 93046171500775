define("hmis/components/stack-pages/department-man", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    currentUser: Ember.inject.service(),
    tagName: '',
    tabStatus: 0,
    notify: Ember.inject.service(),
    consultationUnits: Ember.computed(function () {
      return this.get('store').findAll('other-billable');
    }),
    newDeptUnit: {},
    newWard: {},
    onConfirm: 'deleteRecord',
    actions: {
      addDepartmentUnit() {
        let store = this.get('store');
        let department = this.get('props.department');
        let data = this.get('newDeptUnit');
        data.department = department;
        let post = store.createRecord('department-unit', data);
        this.set('duListWait', true);
        post.save().then(record => {
          this.set('duListWait', false);
          this.set('newDeptUnit', {});
          department.get('departmentUnits').pushObject(record);
          this.notify.success('Saved Successfully');
        }, failure => {
          this.set('duListWait', false);
          this.notify.error('Unable to Save' + failure);
        });
      },

      addWard() {
        let store = this.get('store');
        let department = this.get('props.department');
        let data = this.get('newWard');
        data.department = department;
        let post = store.createRecord('ward', data);
        this.set('wListWait', true);
        post.save().then(record => {
          this.set('wListWait', false);
          this.set('newWard', {});
          department.get('wards').pushObject(record);
          this.notify.success('Saved Successfully');
        }, failure => {
          this.set('wListWait', false);
          this.notify.error('Unable to Save' + failure);
        });
      },

      wardClick(record) {
        this.get('statsContainer').addPage('stack-pages/department-ward-rooms', record.get('name'), {
          ward: record
        });
      },

      saveRecord(record) {
        record.save();
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to delete a department unit: (' + record.get('name') + ')');
        this.set('onAlert', true);
      },

      deleteNow(record) {
        let department = this.get('props.department');

        if (this.tabStatus === 1) {
          department.get("wards").removeObject(record);
          record.destroyRecord();
        } else {
          department.get("departmentUnits").removeObject(record);
          record.destroyRecord();
        }
      }

    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/ipd/ward", ["exports", "hmis/utils/visit-service-util"], function (_exports, _visitServiceUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      let that = this;
      return this.get('store').query('admitted-queued-patient', {
        filter: {
          id: params.record_id
        },
        include: ['visitStatusId'].concat(_visitServiceUtil.visitServiceIncludes)
      }).then(function (res) {
        let qp = res.get('firstObject');
        qp.set('modelEndProp', 'dischargeDate');
        qp.set('modelEndMessage', 'Serve has been ended');
        qp.set('source', 'ipd');
        return qp;
      }); //change request to get current patient instead,
    },

    actions: {
      reloadModel: function () {
        this.refresh();
      },
      refreshServeModel: function () {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});
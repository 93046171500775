define("hmis/components/submit-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['submit-button'],
    attributeBindings: ['isDisabled:disabled'],
    isDisabled: Ember.computed('disabled', 'waiting', function () {
      return this.get('disabled') || this.get('waiting');
    })
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/edit-patient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    notInSelection: Ember.computed("model", function () {
      console.log(this.get('model'));
    }),
    notify: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),

    validate(model) {
      var errors = '';
      if (!model.title) errors = 'Enter a Title';else if (!model.firstName) errors = 'Enter First Name';else if (!model.lastName) errors = 'Enter Last Name';else if (!model.gender) errors = 'Enter Gender information';else if (!model.dateOfBirth) errors = 'Enter Date of Birth';else if (model.nationalIdType && !model.nationalIdNumber) errors = 'Enter a National ID Number for the chosen National ID Type';else if (!model.nationality) errors = 'Enter Nationality';else if (!model.countryOfResidence) errors = 'Enter Country of Residence';else if (!model.modeOfPayment) errors = 'Select a Mode of Payment';else if (model.modeOfPayment !== 'Cash') {
        if (!model.insuranceCompany) errors = 'Enter Insurance Company for the chosen Mode of Payment';else if (!model.insuranceProduct) errors = 'Enter Insurance Product for the chosen Insurance Company';else if (!model.insuranceNumber) errors = 'Enter Insurance Number for the chosen Insurance Product';else if (!model.insuranceExpiry) errors = 'Enter Expiry Date for the chosen Insurance Number';
      }
      return errors;
    },

    actions: {
      back() {
        // this.get('model.transaction').rollback()
        history.back();
      },

      submitForm() {
        this.get('submitForm')();
      },

      save() {
        let that = this;
        let model = this.get("model");
        this.set('insuranceExpiry', model.get('insuranceExpiry'));
        let errors = this.validate(model.toJSON());

        if (errors) {
          //alert(errors);
          that.notify.warning(errors);
          return;
        }

        this.set('saving', true);
        model.save().then(record => {
          this.set('saving', false);
          that.notify.success('Saved Successfully');
          history.back();
        }).catch(failure => {
          this.set('saving', false);
          that.notify.error('Unable to Save' + failure);
        });
      }

    }
  });

  _exports.default = _default;
});
define("hmis/models/insurance-company", ["exports", "ember-data", "ember-data/relationships", "hmis/mixins/model-access-right"], function (_exports, _emberData, _relationships, _modelAccessRight) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_modelAccessRight.default, {
    name: _emberData.default.attr(),
    type: (0, _relationships.belongsTo)('insurance-type'),
    physicalAddress: _emberData.default.attr(),
    contactEmail: _emberData.default.attr(),
    phoneNumber: _emberData.default.attr(),
    api: _emberData.default.attr()
  });

  _exports.default = _default;
});
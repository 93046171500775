define("hmis/components/configure-side-menu", ["exports", "ember-perfect-scrollbar"], function (_exports, _emberPerfectScrollbar) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routing: Ember.inject.service('router'),
    menus: {},
    isCollaped: true,
    showMenuSubs: false,
    testa: "",
    currentPathHook: '',

    didInsertElement() {
      this._super(...arguments); // alert("didRender changed: "+this.get("routing.currentRouteName"));


      this.set("currentPathHook", this.get("routing.currentRouteName"));
    },

    // currentPathDidChange: function() {
    //     alert(this.get('navigator.currentPath'));
    //   }.observes('navigator.currentPath'),
    currentMenuChanged: Ember.observer('routing.currentRouteName', function () {
      // deal with the change
      this.set("currentPathHook", this.get("routing.currentRouteName")); // alert("route changed: "+this.get("routing.currentRouteName"));
    }),
    actions: {
      onMenuClick(route) {
        // alert(tag)
        try {
          this.get("routing").transitionTo(route);
        } catch (err) {
          console.log(err);
        } // this.set("testa",route)
        // for (var menu in this.get('menus')) {
        //     this.set("menus."+menu,false)
        // }
        // this.set("menus."+route,true)

      },

      toggleMenu() {
        this.set("isCollaped", !this.get("isCollaped"));
        this.set("showMenuSubs", false); // alert(this.get("isCollaped"));
      },

      psScrollLeft() {}

    }
  });

  _exports.default = _default;
});
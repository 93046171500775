define("hmis/templates/hmis-app/facility-management/facility/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8wYBck0Y",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n    \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Back\",[28,\"action\",[[23,0,[]],\"back\"],null],\"back-fill\"]]],false],[0,\"\\n    \"],[4,\"if\",[[24,[\"oPerms\",\"op3\",\"view\"]]],null,{\"statements\":[[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Edit\",[28,\"action\",[[23,0,[]],\"edit\"],null],\"edit-fill\"]]],false]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"forms/facility-alert\",null,[[\"type\",\"show\",\"model\",\"record\"],[[24,[\"editType\"]],[24,[\"editRecordDialog\"]],[24,[\"model\"]],[24,[\"editRecord\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height has-main-table\",true,[28,\"if\",[[28,\"eq\",[[24,[\"currentUser\",\"accessLevel\"]],0],null],\"Facilities\",\"Facility Information\"],null]]],{\"statements\":[[0,\"\\n        \"],[4,\"if\",[[28,\"or\",[[24,[\"oPerms\",\"op3\",\"add\"]],[24,[\"oPerms\",\"op3\",\"view\"]],[24,[\"oPerms\",\"op3\",\"edit\"]],[24,[\"oPerms\",\"op3\",\"invalidate\"]],[24,[\"oPerms\",\"op40\",\"allow\"]]],null]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"content display-bock\"],[8],[0,\"\\n            \"],[1,[28,\"object-viewer\",null,[[\"object\",\"properties\"],[[24,[\"model\"]],[24,[\"properties\"]]]]],false],[0,\"\\n\"],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[28,\"no-data-message\",null,[[\"message\"],[\"You don't have permissions to view facilities\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/facility-management/facility/general.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/configure/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MqYDtyqv",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n\"],[0,\"  \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Save\",[28,\"action\",[[23,0,[]],\"save\"],null],\"save-fill\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height  has-main-table\",true,\"Facility\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content display-bock\"],[8],[0,\"\\n      \"],[1,[28,\"facility-manager\",null,[[\"type\",\"record\"],[\"edit\",[24,[\"model\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/configure/index.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/utils/patient-serve-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.cloneServeModel = cloneServeModel;

  // export function visitStatusIds(amount){
  //   return `GH₵ ${parseFloat(Math.round(amount)).toFixed(2)}`;
  // }
  function cloneServeModel(model) {
    let newModel = model.toJSON();
    newModel['visit'] = model.get('visit');
    newModel['modelEndProp'] = model.modelEndProp;
    newModel['modelEndMessage'] = model.modelEndMessage;
    newModel['source'] = model.source;
    return newModel;
  }
});
define("hmis/mixins/classic-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    classNames: 'classic-component',
    classNameBindings: ['cLayout', 'className'],
    cLayout: 'c-column'
  });

  _exports.default = _default;
});
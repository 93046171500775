define("hmis/components/managers/appointment/appointment-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    confirmText: 'Confirm',
    cancelText: 'Cancel',
    actions: {
      newAppointmentConfirm() {}

    }
  });

  _exports.default = _default;
});
define("hmis/models/patient-relation", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    patient: (0, _relationships.belongsTo)('patient'),
    patientId: _emberData.default.attr(),
    relatedTo: (0, _relationships.belongsTo)('patient'),
    relatedToId: _emberData.default.attr(),
    relatedToName: _emberData.default.attr(),
    patientName: _emberData.default.attr(),
    patientGenderId: _emberData.default.attr(),
    relatedToGenderId: _emberData.default.attr(),
    typeName: _emberData.default.attr(),
    typeMaleInverseName: _emberData.default.attr(),
    typeFemaleInverseName: _emberData.default.attr(),
    type: (0, _relationships.belongsTo)('relationship-type'),
    typeId: _emberData.default.attr()
  });

  _exports.default = _default;
});
define("hmis/components/questionnaire/q-gen-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function truncateText(text, length = 40) {
    return text.length < length ? text : text.substring(0, length) + "...";
  }

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments); // setTimeout(() => { this.$('.c-quest-input').focus(); }, 50);

    },

    didInsertElement() {
      this._super(...arguments);
    },

    tagName: 'li',
    collapsed: false,
    classNames: ['q-gen-item'],
    classNameBindings: ['isSub:is-sub', 'layout'],
    isSub: false,
    data: Ember.inject.service(),
    enableWhen: {
      operator: '='
    },
    newOptionIni: {
      operator: '='
    },
    // typeOptions:['text', 'choice', 'integer', 'decimal'],
    layout: Ember.computed(function () {
      return ""; //!this.isSub? 'flex-50 flex-md-100 flex-xs-100 flex-sm-100':''
    }),
    questionNumber: Ember.computed('index', 'parentIndex', 'parentComp.questionNumber', function () {
      if (this.parentIndex == -1) return Number(this.index) + 1;
      return this.parentComp.get('questionNumber') + '.' + (Number(this.index) + 1);
    }),
    answerOptionsText: Ember.computed('item.answerValueSet', 'item.answerValueSet.@each', function () {
      if (!this.item.answerValueSet || !this.item.answerValueSet.length) return 'add options';
      let str = "";

      for (let index = 0; index < this.item.answerValueSet.length; index++) {
        const element = this.item.answerValueSet[index];
        if (str == "") str = element;else str += ", " + element;
      }

      return truncateText(str);
    }),
    iniValuesText: Ember.computed('item.iniValues', 'item.iniValues.@each', function () {
      if (!this.item.iniValues || !this.item.iniValues.length) return 'add linked ansers';
      let str = "";

      for (let index = 0; index < this.item.iniValues.length; index++) {
        const element = this.item.iniValues[index];
        if (str == "") str = "[" + this.item.link + " " + element.operator + " " + element.value + "? " + element.question + "=" + element.answer + "]";else str += ", [" + this.item.link + " " + element.operator + " " + element.value + "? " + element.question + "=" + element.answer + "]";
      }

      return truncateText(str);
    }),
    conditionsText: Ember.computed('item.enableWhen', 'item.enableWhen.@each', function () {
      if (!this.item.enableWhen || !this.item.enableWhen.length) return 'add conditions';
      let str = "";

      for (let index = 0; index < this.item.enableWhen.length; index++) {
        const element = this.item.enableWhen[index];
        if (str == "") str = "[" + element.question + " " + element.operator + " " + element.answer + "]";else str += ", [" + element.question + " " + element.operator + " " + element.answer + "]";
      }

      return truncateText(str);
    }),
    actions: {
      mainRowClick() {
        if (this.onSelect) this.onSelect(this.item);
      },

      onItemTextChange(value) {
        this.set('item.text', value);
        this.send('onChange');
      },

      onTypeChange(value) {
        this.set('item.type', value);
        this.send('onChange');
      },

      addQuestionBefore(dropdown) {
        dropdown.actions.close();
        if (this.addQuestionBefore) this.addQuestionBefore(this.item, this.index, this.parentItem);
        this.send('onChange');
      },

      addQuestionAfter(dropdown) {
        dropdown.actions.close();
        if (this.addQuestionAfter) this.addQuestionAfter(this.item, this.index, this.parentItem);
        this.send('onChange');
      },

      addSubQuestion(dropdown) {
        dropdown.actions.close();
        if (this.addSubQuestion) this.addSubQuestion(this.item, this.index, this.parentItem);
        this.send('onChange');
      },

      deleteQuestion(dropdown) {
        dropdown.actions.close();
        if (this.addQuestionBefore) this.deleteQuestion(this.item, this.index, this.parentItem);
        this.send('onChange');
      },

      //////////////////////
      onAddEnableWhen() {
        event.stopPropagation();
        event.preventDefault();
        if (!this.get('item.enableWhen')) this.set('item.enableWhen', []);
        this.get('item.enableWhen').pushObject({
          question: this.enableWhen.question,
          operator: this.enableWhen.operator,
          answer: this.enableWhen.answer
        });
        this.set('enableWhen', {
          operator: '='
        });
        this.send('onChange');
      },

      onRemoveEnableWhen(item) {
        if (this.get('item.enableWhen')) this.get('item.enableWhen').removeObject(item);
        this.send('onChange');
      },

      //////////////////////
      onAddValueLink() {
        event.stopPropagation();
        event.preventDefault();
        if (!this.get('item.iniValues')) this.set('item.iniValues', []);
        this.get('item.iniValues').pushObject({
          operator: this.get('newOptionIni.operator'),
          value: this.get('newOptionIni.value'),
          question: this.get('newOptionIni.question'),
          answer: this.get('newOptionIni.answer')
        });
        this.set('newOptionIni', {
          operator: '='
        });
        this.send('onChange');
      },

      onRemoveValueLink(item) {
        if (this.get('item.iniValues')) this.get('item.iniValues').removeObject(item);
        this.send('onChange');
      },

      ///////////////
      onAddAnswerValueSet() {
        event.stopPropagation();
        event.preventDefault();
        if (!this.get('item.answerValueSet')) this.set('item.answerValueSet', []);
        this.get('item.answerValueSet').pushObject(this.get('newAnsOption'));
        this.set('newAnsOption', '');
        this.send('onChange');
      },

      onRemoveAnswerValueSet(item) {
        if (this.get('item.answerValueSet')) this.get('item.answerValueSet').removeObject(item);
        this.send('onChange');
      },

      onChange() {
        if (this.get('onChange')) this.get('onChange')(this);
      }

    }
  });

  _exports.default = _default;
});
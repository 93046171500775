define("hmis/routes/configure/insurance/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    controllerName: 'hmis-app.admin-settings.insurance-management',
    templateName: 'hmis-app.admin-settings.insurance-management',
    currentUser: Ember.inject.service(),

    model(params) {
      return Ember.RSVP.hash({
        insuranceCompanies: this.get('store').findAll('insurance-company')
      });
    }

  });

  _exports.default = _default;
});
define("hmis/models/visit-investigation", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    investigationType: (0, _relationships.belongsTo)('investigation'),
    batch: (0, _relationships.belongsTo)('investigation-batch'),
    note: _emberData.default.attr(),
    investigationTypeName: Ember.computed('investigationType.genericName', function () {
      // return `${this.get('firstName')} ${this.get('lastName')}`;
      return this.get('investigationType.genericName');
    })
  });

  _exports.default = _default;
});
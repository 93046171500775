define("hmis/components/questionnaire/q-options", ["exports", "hmis/utils/questionnaire"], function (_exports, _questionnaire) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function truncateText(text, length = 40) {
    return text.length < length ? text : text.substring(0, length) + "...";
  }

  var _default = Ember.Component.extend({
    init() {
      this._super(...arguments);
    },

    classNames: ['q-options'],
    data: Ember.inject.service(),
    enableWhen: {
      operator: '='
    },
    newOptionIni: {
      operator: '='
    },
    calculationError: Ember.computed('item.calculation', function () {
      console.log('sksk');
      if (!this.item.calculation || this.item.calculation.length == 0) return null; // let calcString = this.item.calculation;
      // let testRes = this.item.calculation.match(new RegExp('(\{.*?\})', 'g'));
      // if(testRes)
      // for (let index = 0; index < testRes.length; index++) {
      //     const element = testRes[index];
      //     let link = element.substring(1, element.length-1);
      //     calcString = calcString.replace(element, 10)
      // }

      try {
        (0, _questionnaire.getCalculatedValue)({}, this.item, null, null, 10);
      } catch (error) {
        return error;
      }
    }),
    actions: {
      onItemTextChange(value) {
        this.set('item.text', value);
        this.send('onChange');
      },

      onTypeChange(value) {
        this.set('item.type', value);
        this.send('onChange');
      },

      addQuestionBefore(dropdown) {
        dropdown.actions.close();
        if (this.addQuestionBefore) this.addQuestionBefore(this.item, this.index, this.parentItem);
        this.send('onChange');
      },

      addQuestionAfter(dropdown) {
        dropdown.actions.close();
        if (this.addQuestionAfter) this.addQuestionAfter(this.item, this.index, this.parentItem);
        this.send('onChange');
      },

      addSubQuestion(dropdown) {
        dropdown.actions.close();
        if (this.addSubQuestion) this.addSubQuestion(this.item, this.index, this.parentItem);
        this.send('onChange');
      },

      deleteQuestion(dropdown) {
        dropdown.actions.close();
        if (this.addQuestionBefore) this.deleteQuestion(this.item, this.index, this.parentItem);
        this.send('onChange');
      },

      //////////////////////
      onAddEnableWhen() {
        event.stopPropagation();
        event.preventDefault();
        if (!this.get('item.enableWhen')) this.set('item.enableWhen', []);
        this.get('item.enableWhen').pushObject({
          question: this.enableWhen.question,
          operator: this.enableWhen.operator,
          answer: this.enableWhen.answer
        });
        this.set('enableWhen', {
          operator: '='
        });
        this.send('onChange');
      },

      onRemoveEnableWhen(item) {
        if (this.get('item.enableWhen')) this.get('item.enableWhen').removeObject(item);
        this.send('onChange');
      },

      //////////////////////
      onAddValueLink() {
        event.stopPropagation();
        event.preventDefault();
        if (!this.get('item.iniValues')) this.set('item.iniValues', []);
        this.get('item.iniValues').pushObject({
          operator: this.get('newOptionIni.operator'),
          value: this.get('newOptionIni.value'),
          question: this.get('newOptionIni.question'),
          answer: this.get('newOptionIni.answer')
        });
        this.set('newOptionIni', {
          operator: '='
        });
        this.send('onChange');
      },

      onRemoveValueLink(item) {
        if (this.get('item.iniValues')) this.get('item.iniValues').removeObject(item);
        this.send('onChange');
      },

      ///////////////
      onAddAnswerValueSet() {
        event.stopPropagation();
        event.preventDefault();
        if (!this.get('item.answerValueSet')) this.set('item.answerValueSet', []);
        this.get('item.answerValueSet').pushObject(this.get('newAnsOption'));
        this.set('newAnsOption', '');
        this.send('onChange');
      },

      onRemoveAnswerValueSet(item) {
        if (this.get('item.answerValueSet')) this.get('item.answerValueSet').removeObject(item);
        this.send('onChange');
      },

      clearUpperLower() {
        let item = this.item;
        this.set('item.lowerLimit', null); //to make textbox empty

        this.set('item.upperLimit', null); //to make textbox empty

        delete item.lowerLimit;
        delete item.upperLimit;
        this.set('item', item);
        this.send('onChange');
      },

      changeUpperLimit(value) {
        this.set('item.upperLimit', value);
        this.send('onChange');
      },

      changeLowerLimit(value) {
        this.set('item.lowerLimit', value);
        this.send('onChange');
      },

      changeDecimalPlace(value) {
        this.set('item.decimalPlace', value);
        this.send('onChange');
      },

      onVisibilityChange(val) {
        // this.get('item.visible', val)
        this.send('onChange');
      },

      onChange() {
        if (this.get('onChange')) this.get('onChange')(this);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/controls/access-m-operation-permission", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { DS } from 'ember-data';
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',
    // roleOperationPermission:computed('role','role.roleOperationPermissions', 'permission', function(){
    //     let role = this.get('role')
    //     let permission = this.get('permission')
    //     return DS.PromiseObject.create({
    //         promise: role.get('roleOperationPermissions').then((permissions) => {
    //             let rolePerm = permissions.findBy('permission.id', permission.get('id'));
    //             return rolePerm
    //         })
    //     })
    // }),
    add: false,
    view: false,
    edit: false,
    inactivate: false,
    allow: false,
    actions: {
      onDone() {
        let action = this.get('doneClick');
        if (action) action({
          add: this.get('add'),
          view: this.get('view'),
          edit: this.get('edit'),
          inactivate: this.get('inactivate'),
          allow: this.get('allow')
        });
      },

      onChange(prop, value) {
        if (this.get('addMode')) {
          this.set(prop, value);
        } else {
          let action = this.get('permissionChange');
          if (action) action(prop, value);
        }
      },

      onRemove(value) {
        let action = this.get('removeClick');
        if (action) action(value);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/models/vital-batch", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/utils/bmi-calc", "hmis/mixins/visit-service-model"], function (_exports, _emberData, _relationships, _dateUtil, _bmiCalc, _visitServiceModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_visitServiceModel.default, {
    vitals: (0, _relationships.hasMany)('vital'),
    createdAt: _emberData.default.attr(),
    firstVitals: Ember.computed('vitals.firstObject', function () {
      console.log(this.get('vitals.firstObject'));
      return this.get('vitals.firstObject');
    }),
    // bmi: computed('vitals', 'vitals.@each.value', 'vitals.@each.vitalTypeName','vitals.@each.unitOfMeasureName', function() {
    //   let vitals  = this.get('vitals');
    //   // console.log('vitals', vitals);
    //   let vs = ''
    //   let i=0
    //   let w = {}
    //   let h = {}
    //   vitals.forEach(function(vt) {
    //     if(!vt)
    //       return
    //     if(i!=0)
    //       if(vt.vitalTypeName && vt.get('vitalTypeName').toLowerCase() =='weight'){
    //         w = {val: vt.get('value'), unit: vt.get('unitOfMeasureName')}
    //       }
    //       if(vt.vitalTypeName && vt.get('vitalTypeName').toLowerCase() =='height/length'){
    //         h = {val: vt.get('value'), unit: vt.get('unitOfMeasureName')}
    //       }
    //     i++;
    //   });
    //   let bv = bmiCalculator(w.val, w.unit, h.val, h.unit);
    //   //vs+=', BMI: '+bv
    //   return bv;
    // }),
    // vitalsList: Ember.computed.alias('vitals'),
    vitalsString: Ember.computed('vitals', 'vitals.@each.value', 'vitals.@each.vitalTypeName', function () {
      let vitals = this.get('vitals'); // console.log('vitals', vitals);

      let vs = '';
      let i = 0;
      vitals.forEach(function (vt) {
        if (!vt) return;
        if (i != 0) vs += ", ";
        vs += vt.get('vitalTypeName') + ': ' + vt.get('value');
        i++;
      });

      if (this.get('bmi') && vs) {
        vs += ', BMI:' + this.get('bmi');
      }

      return vs;
    })
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/records/patient/general", ["exports", "hmis/utils/general-util"], function (_exports, _generalUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model(params) {
      var curPatient = this.modelFor("hmis-app.records.patient");
      console.log('curPatient', curPatient.id);
      return (0, _generalUtil.modelHash)({
        patient: curPatient,
        occupations: (0, _generalUtil.innerModelPromise)(this.get('store').query('patient-occupation', {
          filter: {
            patientId: curPatient.id
          }
        }))
      });
    },

    actions: {
      refreshModel: function () {
        this.refresh();
      } //

    }
  });

  _exports.default = _default;
});
define("hmis/templates/hmis-app/records/view-patient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DkA1vfH2",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Back\",[28,\"action\",[[23,0,[]],\"back\"],null],\"back-fill\"]]],false],[0,\"\\n        \"],[4,\"if\",[[24,[\"oPerms\",\"op1\",\"edit\"]]],null,{\"statements\":[[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Edit\",[28,\"action\",[[23,0,[]],\"edit\"],null],\"edit-fill\"]]],false]],\"parameters\":[]},null],[0,\"\\n        \"],[4,\"if\",[[28,\"or\",[[24,[\"oPerms\",\"op9\",\"view\"]],[24,[\"oPerms\",\"op9\",\"allow\"]]],null]],null,{\"statements\":[[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Manage relations\",[28,\"action\",[[23,0,[]],\"manageRelations\"],null],\"users-relation-fill\"]]],false]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"app-workspace\"],[8],[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\"],[\"full-height\",true,\"Patient Information\"]],{\"statements\":[[4,\"if\",[[28,\"or\",[[24,[\"oPerms\",\"op1\",\"add\"]],[24,[\"oPerms\",\"op1\",\"edit\"]],[24,[\"oPerms\",\"op1\",\"view\"]],[24,[\"oPerms\",\"op1\",\"inactivate\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n            \"],[1,[28,\"object-viewer\",null,[[\"object\",\"properties\",\"inputWidth\"],[[24,[\"model\"]],[24,[\"propsToDisplay\"]],\"flex-20 flex-xs-100 flex-sm-100\"]]],false],[0,\" \"],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            \"],[1,[28,\"no-data-message\",null,[[\"message\"],[\"You don't have permissions to view patients\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/records/view-patient.hbs"
    }
  });

  _exports.default = _default;
});
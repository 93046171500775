define("hmis/templates/components/managers/billable-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sZxCZpD4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[8],[0,\"\\n  Billable: \\n  \"],[1,[24,[\"currentUser\",\"user\",\"accessLevel\"]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/billable-item.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    authenticationRoute: 'sign-in',
    app: Ember.inject.service(),
    appData: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    appAjax: Ember.inject.service(),

    // willTransition(transition)
    // {
    //   // if (this.controller.get('userHasEnteredData') &&
    //   //     !confirm('Are you sure you want to abandon progress?')) {
    //   //   transition.abort();
    //   // } else {
    //   //   // Bubble the `willTransition` action so that
    //   //   // parent routes can decide whether or not to abort.
    //   //   return true;
    //   // }
    //   console.log('some transition', transition);
    // },
    setupController(controller, model) {
      // Call _super for default behavior
      this._super(controller, model);

      this.get('app').startLongPolling(); // window.onbeforeunload =  (e)=> {
      //   e.preventDefault();
      //   e.stopPropagation();
      //     e = e || window.event;
      //     console.log('e',e  );
      //     console.log('window.event', window.event);
      //     this.get('session').invalidate();
      //     console.log('after invali');
      //     // For IE and Firefox prior to version 4
      //     if (e) {
      //         e.returnValue = 'Sure?';
      //     }
      //     // For Safari
      //     return 'Sure?';
      // };

      this.appAjax.getObject("/simpleJSONs/module_permissions", null).then(response => {
        this.set('appData.modulePermissions', response);
      });
      this.appAjax.getObject("/simpleJSONs/operation_permissions", null).then(response => {
        this.set('appData.operationPermissions', response); // this.currentUser.startNotification();
      });
    },

    actions: {
      loading(transition, route) {
        this.app.startLoading();
        transition.finally(() => {
          this.app.stopLoading();
        });
      }

    }
  });

  _exports.default = _default;
});
define("hmis/mixins/ref-component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    didInsertElement() {
      this._super(...arguments);

      if (this.parent) {
        if (this.ref) Ember.set(this.parent, this.ref, this);else console.warn('ref component no ref property set');
      } else {
        console.warn('ref component no parent property set');
      }
    }

  });

  _exports.default = _default;
});
define("hmis/routes/hmis-app/admin-settings/role-permissions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    appAjax: Ember.inject.service(),

    model(params) {
      return Ember.RSVP.hash({
        roles: this.appAjax.getArray('/rolePermissions')
      });
    }

  });

  _exports.default = _default;
});
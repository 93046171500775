define("hmis/templates/components/managers/appointment/new-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dtdieONa",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[1,[28,\"managers/appointment/appointment-alert\",null,[[\"show\",\"onConfirm\",\"title\",\"confirmText\",\"hideStaff\",\"appointment\"],[[24,[\"show\"]],[28,\"action\",[[23,0,[]],\"onConfirm\"],null],\"New Appointment\",\"Create Appointment\",[24,[\"hideStaff\"]],[24,[\"appointment\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/managers/appointment/new-alert.hbs"
    }
  });

  _exports.default = _default;
});
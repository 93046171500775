define("hmis/utils/list-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.filterList = filterList;
  _exports.orderByKeys = orderByKeys;
  _exports.default = void 0;

  function filterList(query, list, filterParams) {
    let result = [];

    if (query) {
      result = list.filter(item => {
        for (var i = 0; i < filterParams.length; i++) {
          let key = filterParams[i]; // if (item.get(key)){

          if (Ember.get(item, key)) {
            // let itemParam =item.get(key).toLowerCase();
            let itemParam = Ember.get(item, key).toLowerCase();
            let ind = itemParam.indexOf(query.toLowerCase());
            if (ind > -1) return true;
          }
        }

        return false;
      });
    } else {
      result = list;
    }

    return result;
  }

  ;

  function orderByKeys(unordered, compare) {
    const ordered = {};
    let keys = compare ? Object.keys(unordered).sort((a, b) => compare(a, b)) : Object.keys(unordered).sort();
    keys.forEach(function (key) {
      ordered[key] = unordered[key];
    });
    return ordered;
  } // arr.sort(function(a, b) {
  //   var keyA = new Date(a.updated_at),
  //     keyB = new Date(b.updated_at);
  //   // Compare the 2 dates
  //   if (keyA < keyB) return -1;
  //   if (keyA > keyB) return 1;
  //   return 0;
  // });


  var _default = {
    filterList
  };
  _exports.default = _default;
});
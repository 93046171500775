define("hmis/components/controls/tlm-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    startTimeField: 'startTime',
    endTimeField: 'endTime',

    init() {
      this._super(...arguments);

      if (this.item.isNewItem) {
        this.send('onEdit');
        this.set('item.isNewItem', false);
      }
    },

    // observeFocus:observer('indexOnEdit', function(){
    //     let focus = this.get('indexOnEdit')==this.get('index');
    //     if(!focus){
    //         this.set('onEdit', false)
    //         if(this.get('allowRollBack')){
    //             // this.get('item').rollback()
    //             this.get('item').rollbackAttributes()
    //         }
    //     }
    // }),
    actions: {
      onEdit() {
        this.set('onEdit', true);
        this.set('parentOnEdit', true);
        this.set('indexOnEdit', this.get('index'));
        this.set('itemOnEdit', this.get('item'));
      },

      onAdd() {
        let action = this.get('addAction');
        if (action) action(this.get('item'), this.get('index'));
      },

      onRemove() {
        // console.log(this.get('item'));
        let action = this.get('removeAction');
        if (action) action(this.get('item'), this.get('index'));
      },

      onConfirm() {
        this.onItemSave && this.onItemSave();
        this.set('onEdit', false);
        this.set('parentOnEdit', false);
        this.set('indexOnEdit', -1);
      },

      onCancel() {
        this.set('onEdit', false);
        this.set('parentOnEdit', false);
        let action = this.get('cancelAction');
        if (action) action(this.get('item'), this.get('indexOnEdit'));
        this.set('indexOnEdit', -1);
      },

      onClick() {
        let action = this.get('clickAction');
        if (action) action(this.get('item'), this.get('index'));
      }

    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/admin-settings/system-config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ajaxRequest: Ember.inject.service(),
    appAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    noChanges: true,
    currentUser: Ember.inject.service(),
    insuranceProducts: Ember.computed(function () {
      return this.get('store').findAll('insurance-product');
    }),
    configUrl: Ember.computed('currentUser.', function () {
      return this.currentUser.accessLevel == 2 ? '/facilityConfigurations' : '/appConfigurations';
    }),
    configEmergencyUnit: Ember.computed(function () {
      return this.get('store').findAll('facility-configuration').then(function (v) {
        let wobj = v.get('firstObject');
        return wobj.get('emergencyUnit');
      });
    }),
    wards: Ember.computed(function () {
      return this.get('store').findAll('ward');
    }),

    init() {
      this._super(...arguments);

      this.appAjax.getObject(this.configUrl).then(res => {
        console.log('rrrrrrrrrrrrrrrrrrrrrrrrrr', res);
        this.set('appConfigs', res);
      }).catch(err => console.error(err)); // this.get('ajaxRequest').appConfigs({
      //   success: (response)=>{
      //     this.set('appConfigs', response);
      //   },
      //   error:(error)=>{
      //       console.error(error);
      //   }
      // })
    },

    appConfigs: {},
    appConfigs: {},
    newWard: {},
    // appConfigs:computed( function() {
    //   this.get('ajaxRequest').makeRequest('GET', "/appConfigurations/", null, {
    //     success: (response)=>{
    //       this.set('appConfigs', response);
    //     },
    //     error:(error)=>{
    //         console.error(error);
    //     }
    //   })
    // }),
    nhisProduct: Ember.computed('appConfigs', 'appConfigs.nhisProduct', 'insuranceProducts.@each', function () {
      let prods = this.get('insuranceProducts');
      let pId = this.get('appConfigs.nhisProduct');
      console.log('pid', this.get('appConfigs'));

      for (var i = 0; i < this.get('insuranceProducts.length'); i++) {
        let ob = prods.objectAt(i);
        if (ob.get('id') == pId) return ob;
      }

      return null;
    }),
    emergencyUnit: Ember.computed('appConfigs', 'appConfigs.emergencyUnit', 'wards.@each', function () {
      let wards = this.get('wards');
      let pId = this.get('appConfigs.emergencyUnit');
      console.log('emunit wards', wards);
      console.log('emunit pid', pId);

      for (var i = 0; i < this.get('wards.length'); i++) {
        let ob = wards.objectAt(i);
        if (ob.get('id') == pId) return ob;
      }

      return null;
    }),
    actions: {
      nhisProductChange(record) {
        this.set('noChanges', false);
        this.set('appConfigs.nhisProduct', record.get('id'));
      },

      taxRateChange(value) {
        this.set('noChanges', false);
        this.set('appConfigs.taxRate', value);
      },

      inputPropChange(prop, value) {
        this.set('noChanges', false);
        this.set('appConfigs.' + prop, value);
      },

      wardChange(record) {
        this.set('noChanges', false);
        this.set('appConfigs.emergencyUnit', record.get('id'));
      },

      notificationStatusChange(value) {
        this.set('noChanges', false);
        this.set('appConfigs.notificationStatus', value);
      },

      onSave() {
        this.set('saving', true);
        this.appAjax.post(this.configUrl, this.appConfigs).then(response => {
          this.set('saving', false);
          this.set('noChanges', true);
          this.notify.success('Saved');
        }).catch(error => {
          this.set('saving', false);
          this.notify.error('Unable to Save');
          console.log(error);
        });
      } // wardChange(record){
      //   this.set('noChanges', false)
      //   this.set('newWard', this.get('configEmergencyUnit'))
      //   this.set('configEmergencyUnit', record)
      // },
      // onWardSave(){
      //   this.set('saving', true)
      //   let that = this
      //   let nw = this.get('store').findAll('facility-configuration')
      //   .then(function(v){
      //     let wobj = v.get('firstObject')
      //     return wobj
      //   });
      //   console.log('newWard',this.get('newWard'));
      //   nw.then(function(value) {
      //     console.log('value',value.id);
      //     that.get('store').findRecord('facility-configuration', value.id)
      //     .then(function(con) {
      //       console.log('configEmergencyUnit', that.get('configEmergencyUnit'))
      //       con.set('emergencyUnit', that.get('configEmergencyUnit'))
      //       con.set('deleteStatus',0)
      //       con.save()
      //       that.set('saving', false)
      //       that.set('noChanges', true)
      //       that.notify.success('Saved')
      //     });
      //   }); 
      //   // this.get('configEmergencyUnit').save().then(function(){
      //   //   that.set('saving', false)
      //   //   that.set('noChanges', true)
      //   //   that.notify.success('Saved')
      //   // })
      // },


    }
  });

  _exports.default = _default;
});
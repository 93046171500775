define("hmis/components/controls/table-long-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { get } from '@ember/object';
  function concatWithProperty(strings, property) {
    if (property) {
      strings.push(property);
    }

    return strings.join(' ');
  }

  var _default = Ember.Component.extend({
    classNames: ['table-long-text'],

    didInsertElement() {
      this._super(...arguments);

      let parentElem = this.$(this.element).parent()[0];
      parentElem.classList.add("table-long-text-wrapper");
      var $element = this.$(this.element);
      var $c = $element.clone().css({
        display: 'inline',
        width: 'auto',
        visibility: 'hidden'
      }).appendTo('body');

      if ($c.width() > $element.width()) {
        this.set('tooLong', true);
      }

      $c.remove();
    },

    alertTitle: Ember.computed(function () {
      return this.column.title || this.column.propertyName;
    }),
    actions: {
      showAlll() {
        event.preventDefault();
        event.stopPropagation();
        this.set('isOpen', true);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/mixins/patient-serve-controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    init() {
      this._super(...arguments);
    },

    disableServeMessage: '',
    disableServe: Ember.computed('model', 'model.visit', 'model.visit.status', 'model.dischargeDate', 'model.endDate', function () {
      if (this.get('model.visit.status.id') == 4) //isPendingDischarge
        {
          this.set('disableServeMessage', 'Patient is pending discharge');
          return true;
        }

      if (this.get('model.' + this.get('model.modelEndProp'))) //isPendingDischarge
        {
          this.set('disableServeMessage', this.get('model.modelEndMessage'));
          return true;
        }

      if (this.get('model.visit.status.id') == 5) //isDischarged
        {
          this.set('disableServeMessage', 'Patient has been discharge');
          return true;
        }

      return false;
    })
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/facility-management/view-facility", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    actions: {
      back() {
        history.back();
      },

      edit() {
        var record = this.get("model");
        this.get("routing").transitionTo("hmis-app.facility-management.edit-facility", [record.id]);
      }

    },
    properties: [{
      title: 'Logo',
      name: 'pictureUrl',
      type: 'picture',
      class: 'flex-100'
    }, {
      title: 'Facility Type',
      name: 'facilityType.name'
    }, {
      title: 'Facility Level Code',
      name: 'facilityLevelCode'
    }, {
      title: 'facility Name',
      name: 'facilityName'
    }, {
      name: 'postAddress'
    }, {
      name: 'physicalAddress'
    }, {
      name: 'contactEmail'
    }, {
      name: 'phoneNumber'
    }, {
      name: 'alternativePhoneNumber'
    }, {
      name: 'contactPerson1Name'
    }, {
      name: 'contactPerson1Email'
    }, {
      name: 'contactPerson1PhoneNumber'
    }, {
      title: 'contactPerson1AlternativePhoneNumber',
      name: ''
    }, {
      name: 'contactPerson2Name'
    }, {
      name: 'contactPerson2Email'
    }, {
      name: 'contactPerson2PhoneNumber'
    }, {
      name: 'contactPerson2AlternativePhoneNumber'
    }, {
      title: 'servicesOffered',
      name: 'servicesOffered',
      type: 'array',
      arrayKey: 'name'
    }, {
      name: 'numberOfBeds'
    }, {
      name: 'insurances',
      title: 'insurances',
      type: 'array',
      arrayKey: 'name'
    }, {
      name: 'professions',
      title: 'professions',
      type: 'array',
      arrayKey: 'name'
    }, {
      name: 'region'
    }, {
      name: 'town'
    }, {
      name: 'accreditationNumber'
    }, {
      title: 'denomination',
      name: 'denomination.name'
    }]
  });

  _exports.default = _default;
});
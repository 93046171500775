define("hmis/controllers/hmis-app/facility-management/facility/payment", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller"], function (_exports, _modelTableTheme, _formController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_formController.default, {
    notify: Ember.inject.service(),
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    selectedItems: [],
    allowSelect: false,
    //data: Ember.computed.alias('model'),
    notInSelection: Ember.computed("selectedItems.length", function () {
      return this.get("selectedItems").length < 1;
    }),
    selectedCount: Ember.computed("selectedItems.length", function () {
      var lenght = this.get("selectedItems").length;
      if (lenght > 0) return " " + lenght + " ";else return " ";
    }),
    isToday: true,
    fromDate: new Date(),
    toDate: new Date(),
    tabIndex: 0,
    selectedPatient: '',
    vitalsSuccessAction: 'empty',
    onConfirm: 'deleteRecord',
    visit: null,

    init() {
      this._super(...arguments);
    },

    actions: {
      back() {
        history.back();
      },

      addPayment() {
        this.set('editPayment', {});
        this.set('paymentDialog', true);
      },

      editPayment(record) {
        this.set('editPayment', record);
        this.set('paymentDialog', true);
      },

      onSaved(record) {
        this.send('reloadModel');
        this.notify.success('Saved Successfully');
      },

      deleteRecord(record) {
        this.set('onConfirm', 'deleteNow');
        this.set('recordTag', record);
        this.set('alertTitle', 'Attempting to archive item');
        this.set('onAlert', true);
      },

      deleteNow(recordTag) {
        recordTag.destroyRecord();
        this.send('reloadModel');
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    }),
    paymentColumns: [{
      'title': 'Payment Mode',
      "propertyName": "paymentModeName",
      'className': 'main-column'
    }, {
      'title': 'Type',
      "propertyName": "paymentModeTypeName",
      'className': 'main-column'
    }, {
      'title': 'Account Number',
      "propertyName": "accountNumber",
      'className': 'main-column'
    }, {
      'title': 'Account Name',
      "propertyName": "accountName",
      'className': 'main-column'
    }, {
      "title": "Actions",
      "component": "manageRow",
      'className': 'main-column'
    }]
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/month-chooser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HRBTe6Fw",
    "block": "{\"symbols\":[\"month\",\"index\",\"monthlyYear\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"month-chooser\"],[8],[0,\"\\n\"],[4,\"app-select\",null,[[\"disabled\",\"class\",\"label\",\"selected\",\"options\",\"onChange\"],[[24,[\"disabled\"]],\"full-width margin-top-20\",\"Year\",[24,[\"selectedYear\"]],[24,[\"monthlyYearOptions\"]],[28,\"action\",[[23,0,[]],\"yearChanged\"],null]]],{\"statements\":[[0,\"        \"],[1,[23,3,[]],false],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flex-row-wrap\"],[10,\"style\",\"margin-top: -16px;\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"months\"]]],null,{\"statements\":[[0,\"            \"],[7,\"button\",true],[11,\"disabled\",[22,\"disabled\"]],[11,\"class\",[29,[\"month \",[28,\"if\",[[28,\"eq\",[[24,[\"selectedMonth\"]],[23,2,[]]],null],\"selected\",\"\"],null]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"monthClick\",[23,1,[]],[23,2,[]]],null]],[8],[1,[23,1,[]],false],[9],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/month-chooser.hbs"
    }
  });

  _exports.default = _default;
});
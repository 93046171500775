define("hmis/templates/components/controls/table-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9wPT+AF+",
    "block": "{\"symbols\":[],\"statements\":[[4,\"paper-switch\",null,[[\"value\",\"onChange\"],[[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[28,\"get\",[[24,[\"record\"]],[24,[\"column\",\"propertyName\"]]],null]],null]],null]]],{\"statements\":[],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/table-switch.hbs"
    }
  });

  _exports.default = _default;
});
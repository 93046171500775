define("hmis/components/forms/end-episode-alert", ["exports", "hmis/mixins/form-alert", "hmis/mixins/ref-component", "hmis/constants/referral-types", "hmis/components/controls/table-link"], function (_exports, _formAlert, _refComponent, _referralTypes, _tableLink) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // import { departmentUnitPickerColumns } from '../../utils/common-data-columns';
  var _default = Ember.Component.extend(_formAlert.default, _refComponent.default, {
    modelName: 'user-accreditation',
    appAjax: Ember.inject.service(),
    notify: Ember.inject.service(),
    simpleJsons: Ember.inject.service(),
    title: "Ending an Episode",

    //   columnComponents=(
    //     hash tableLink=(
    //         component "controls/table-link"
    //         onClick=(action "diagnosisClick")
    //     )
    // )
    show(options, onSuccessCallback, onFinallyCallback = null) {
      this.set('onSuccess', onSuccessCallback);
      this.set('onFinallyCallback', onFinallyCallback);

      if (!options.episodeId) {
        console.error('options.episodeId is null');
        return;
      }

      this.set('episodeId', options.episodeId);
      this.set('record', {
        episodeId: options.episodeId
      });
      let vColumns = [{
        component: "controls/select-row-checkbox",
        "useFilter": false,
        "mayBeHidden": false
      }, {
        title: 'DIagnosis',
        propertyName: 'diagnosisTypeName'
      }, {
        title: 'Icd Code',
        propertyName: 'icdCode'
      }, {
        title: 'Visit',
        propertyName: 'visitDateF',
        className: 'vsv-date-col',
        component: 'controls/table-link',
        onClick: record => {
          this.visitViewer.show({
            id: record.visitId
          });
        },

        formatText(text, record) {
          return record.dateF + " : " + record.visitPurpose;
        }

      }];
      this.data.serverTable({
        modelName: 'diagnosis',
        columns: vColumns,
        title: 'Select the primary diagnosis for this episode',
        query: {
          filter: {
            episodeId: this.record.episodeId
          },
          include: ['visitPurpose', 'diagnosisTypeName', 'visitDate']
        }
      }, diagnosis => {
        this.appAjax.post("endVisitEpisodes/".concat(this.record.episodeId), {
          primaryDiagnosisId: diagnosis.id
        }).then(res => {
          this.notify.success('Episode has been ended');
          this.set('_show', false);
          this.onSuccess && this.onSuccess(res);
          this.onFinallyCallback && this.onFinallyCallback();
        }).catch(err => {
          this.notify.error(err);
          this.onFinallyCallback && this.onFinallyCallback();
        });
      }, () => {
        onFinallyCallback && onFinallyCallback();
      });
    },

    //accreditationType: ['a','b','c'],
    actions: {
      onSystemFacilityChange(value) {
        // record.systemFacility
        this.set('record.systemFacility', value);
        this.set('record.toId', null);
        this.set('record.toName', null);
      },

      onFacilitySelect(facility) {
        this.set('selectedFacility', facility);
        this.set('record.toId', facility.id);
        this.set('record.toName', facility.facilityName);
      },

      onRecordSubmit(record) {
        let data = Object.assign({}, this.record);
        data.typeId = data.systemFacility ? _referralTypes.REFERRAL_TYPES.EXTERNAL_SYSTEM_FACILITY : _referralTypes.REFERRAL_TYPES.EXTERNAL_NON_SYSTEM_FACILITY; // delete data.systemFacility;

        this.refer(data);
      },

      onCancel() {
        this.set('waiting', false);
        this.onFinallyCallback && this.onFinallyCallback();
      }

    },
    visitColumns: [{
      title: 'Date',
      propertyName: 'dateTimeF',
      className: 'vsv-date-col'
    }, {
      title: 'Diagnoses',
      propertyName: 'diagnosisTypeName',
      className: "vsv-summary-string-col"
    }],
    diagnosisColumns: []
  });

  _exports.default = _default;
});
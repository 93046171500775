define("hmis/components/pickers/payment-picker", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/ref-component"], function (_exports, _modelTableTheme, _refComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function defaultTrue(value) {
    return value == false ? false : true;
  }

  var _default = Ember.Component.extend(_refComponent.default, {
    title: 'Choose mode of payment',
    confirmText: 'Choose',
    cancelText: 'Close',
    // closeOnConfirm:true,
    width: '65%',
    height: '75%',
    multiSelect: false,
    selectedItems: [],
    tagName: '',
    filterParams: [],
    tabIndex: 0,
    appAjax: Ember.inject.service(),
    notify: Ember.inject.service(),

    // init() {
    //   this._super(...arguments);
    //   let parent = this.get('parent')
    //   if (parent){
    //     parent.set('closePayment', ()=>{
    //       this.set('show', false)
    //       this.set('waiting', false)
    //     })
    //     parent.set('choosePayment', (transaction, onConfirm, options)=>{
    //       this.set('selectedItems', [])
    //       this.set('transaction', transaction)
    //       this.set('show', true)
    //       this.set('onConfirm', onConfirm)
    //       this.set('showOop', defaultTrue(options.showOop))
    //       this.set('showInsurance', defaultTrue(options.showInsurance))
    //       if(!defaultTrue(options.showOop) && defaultTrue(options.showInsurance))
    //         this.set('tabIndex', 1)
    //       if(defaultTrue(options.showOop) && !defaultTrue(options.showInsurance))
    //         this.set('tabIndex', 0)
    //       this.send('getOptions')
    //     })
    //   }
    // },
    show(transaction, options, onConfirm, onCancel) {
      this.set('selectedItems', []);
      this.set('transaction', transaction);
      this.set('_show', true);
      this.set('onConfirm', onConfirm);
      this.set('onCancel', onCancel);
      this.set('showOop', defaultTrue(options.showOop));
      this.set('showInsurance', defaultTrue(options.showInsurance));
      if (!defaultTrue(options.showOop) && defaultTrue(options.showInsurance)) this.set('tabIndex', 1);
      if (defaultTrue(options.showOop) && !defaultTrue(options.showInsurance)) this.set('tabIndex', 0);
      this.send('getOptions');
    },

    close() {
      this.set('_show', false);
    },

    _waiting: Ember.computed('waiting', 'waiting1', 'waiting2', function () {
      return this.get('waiting') || this.get('waiting1') || this.get('waiting2');
    }),
    oopTransactionsGrid: Ember.computed('oopTransactions', function () {
      if (this.oopTransactions && this.oopTransactions.length) {
        let trans = [{
          title: 'Total',
          value: this.totalPrice
        }];
        this.oopTransactions.forEach(element => {
          trans.push({
            title: element.billableName,
            value: element.price
          });
        });
        return trans;
      } else return [];
    }),
    actions: {
      getOptions() {
        if (this.get('showInsurance')) {
          this.set('waiting1', true);
          this.set('insuranceList', []);
          this.appAjax.getArray('/availableTransactionInsurances/' + this.get('transaction.id')).then(data => {
            this.set('insuranceList', data);
            this.set('waiting1', false);
          }).catch(err => {
            this.notify.error(err);
            this.set('waiting2', false);
          });
        }

        if (this.get('showOop')) {
          this.set('oopPrice', '...');
          this.set('oopList', []);
          this.set('waiting2', true);

          if (Array.isArray(this.get('transaction'))) {
            this.appAjax.getObject('/availableTransactionsOOP', {
              transactions: this.get('transaction').map(a => a.id)
            }).then(data => {
              this.set('oopPrice', data.price);
              this.set('oopList', data.accounts);
              this.set('totalPrice', data.totalPrice.toFixed(2));
              this.set('oopTransactions', data.transactions);
              this.set('waiting2', false);
            }).catch(err => {
              this.notify.error(err);
              this.set('waiting2', false);
            });
          } else {
            this.appAjax.getObject('/availableTransactionOOP/' + this.get('transaction.id')).then(data => {
              this.set('oopPrice', data.price);
              this.set('oopList', data.accounts);
              this.set('waiting2', false);
            }).catch(err => {
              this.notify.error(err);
              this.set('waiting2', false);
            });
          }
        }
      },

      onConfirm() {
        // let action = this.get('tabIndex')==0? this.get('onOopConfirm'): this.get('onInsuranceConfirm')
        let action = this.get('onConfirm');

        if (action) {
          let selected = this.multiSelect ? this.get('selectedItems') : this.get('selectedItems.firstObject');
          action(this.get('tabIndex'), selected, this.get('transaction'));
        }
      }

    },
    themeInstance: Ember.computed(function () {
      return _modelTableTheme.default.create({
        table: 'table'
        /* table:'table table-striped table-bordered table-condensed',*/

      });
    })
  });

  _exports.default = _default;
});
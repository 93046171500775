define("hmis/templates/components/report-pages/chag-facility-types-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JFasmSxH",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"controls/report-table\",null,[[\"fixedColumnNames\",\"serverReport\",\"columnFilters\",\"rowFilters\",\"otherFilters\",\"parent\"],[[24,[\"fixedColumnNames\"]],[24,[\"serverReport\"]],[24,[\"columnFilters\"]],[24,[\"rowFilters\"]],[24,[\"otherFilters\"]],[23,0,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/report-pages/chag-facility-types-table.hbs"
    }
  });

  _exports.default = _default;
});
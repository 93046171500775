define("hmis/controllers/hmis-app/admin-settings/insurance-products/add", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    medOptions: Ember.computed(function () {
      return this.get('store').findAll('medicine');
    }),
    prodList: Ember.computed('model', function () {
      return this.get('store').query('insurance-product', {
        filter: {
          insuranceCompany: this.get('model.id')
        }
      }); //.then( recs=>{ return recs.serialize() })
    }),
    notify: Ember.inject.service(),
    addedMaps: [],
    removedMaps: [],
    actions: {
      back() {
        history.back();
      },

      save() {
        //console.log(this.get('model'))
        let model = this.get('model.icname');
        let that = this; //this.set('model',

        that.get('store').createRecord('insurance-product', {
          name: that.get('model.icname'),
          insuranceCompany: that.get('model')
        }) //)
        //this.get("model")
        .save().then(record => {
          let store = this.get('store');
          let selData = this.get('addedMaps'); // console.log(selData)

          selData.forEach(function (item) {
            let map = store.createRecord('insurance-product-medicine', {
              insuranceProduct: record,
              medicine: item
            });
            map.save().then(function (record) {
              console.log('Test items saved' + record);
            });
          });
          this.get('prodList').pushObject(record);
          that.notify.success('Saved Successfully');
        }).catch(failure => {
          that.notify.error('Unable to Saved' + failure);
        });
      },

      addInv(invTestType) {
        this.get("addedMaps").pushObject(invTestType);
      },

      removeInv(testTypeMap) {
        let removedMaps = this.get('removedMaps');
        let addedMaps = this.get('addedMaps');
        let invType = this.get("model");
        let testTypes = invType.get("insuranceProductMedicines");
        removedMaps.pushObject(testTypeMap);
        testTypes.removeObject(testTypeMap);
        addedMaps.removeObject(testTypeMap);
      },

      deleteRecord(record) {
        // this.set("allowSelect",false)
        this.get("selectedItems").removeObject(record);
        record.destroyRecord();
      },

      editRecord(record) {// alert(record.id);
        // his.get("routing").transitionTo("hmis-app.admin-settings.insurance-products.edit",[record.id]);
      },

      rowDoubleClick(index, record) {
        this.send("viewRecord", record);
      },

      viewRecord(record) {
        let rid = record.get('id');
        this.get("routing").transitionTo("hmis-app.insurance-products.view", [rid]);
      }

    }
  });

  _exports.default = _default;
});
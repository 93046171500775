define("hmis/templates/components/forms/dependant-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EU3OiDvZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"alerts/form-dialog\",null,[[\"title\",\"show\",\"width\",\"height\",\"onConfirm\",\"onCancel\"],[[24,[\"alertTitle\"]],[24,[\"show\"]],\"70%\",\"35%\",[28,\"action\",[[23,0,[]],\"onRecordSubmit\"],null],[28,\"action\",[[23,0,[]],\"onCancel\"],null]]],{\"statements\":[[0,\"\\n\"],[0,\"\\n        \"],[1,[28,\"pickers/input-picker-server-paginated\",null,[[\"class\",\"data\",\"required\",\"textField\",\"searchEnabled\",\"label\",\"selected\",\"selectedChange\"],[[24,[\"input_width\"]],[24,[\"insuranceProducts\"]],true,\"insuranceName\",true,[28,\"concat\",[\"Insurance Product\"],null],[24,[\"record\",\"insuranceProduct\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"insuranceProduct\"]]],null]],null]]]],false],[0,\"\\n\\n        \"],[1,[28,\"pickers/input-picker-server-paginated\",null,[[\"label\",\"required\",\"textField\",\"selected\",\"data\",\"columns\",\"class\",\"onConfirm\"],[\"Dependant\",true,\"fullName\",[24,[\"record\",\"billTo\"]],[24,[\"patients\"]],[24,[\"columns\"]],[24,[\"input_width\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"record\",\"billTo\"]]],null]],null]]]],false],[0,\"\\n\\n\"],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/forms/dependant-alert.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/models/user-payment-mode", ["exports", "ember-data", "ember-data/relationships"], function (_exports, _emberData, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    user: (0, _relationships.belongsTo)('user'),
    paymentMode: (0, _relationships.belongsTo)('payment-mode'),
    paymentModeTypeName: _emberData.default.attr(),
    paymentModeName: _emberData.default.attr(),
    accountNumber: _emberData.default.attr(),
    accountName: _emberData.default.attr()
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/records/patient/general", ["exports", "hmis/utils/model-table-theme", "hmis/mixins/form-controller", "ember-data"], function (_exports, _modelTableTheme, _formController, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    //occupation: false,
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    notify: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    onConfirm: 'deleteRecord',
    selectedItems: [],
    allowSelect: false,
    actions: {
      back() {
        history.back();
      },

      addOccupation() {
        this.set('editOccupation', {
          patient: this.get('model.patient')
        });
        this.set('occupationDialog', true);
      },

      editOccupation(record) {
        this.set('editOccupation', record);
        this.set('occupationDialog', true);
      },

      onSaved(record) {
        this.send('refreshModel');
      },

      deleteRecord(record) {
        this.set('alertTitle', 'Attempting to archive occupation (' + record.get('occupation') + ')');
        this.notify.confirmSimple('Attempting to archive occupation (' + record.get('occupation') + ')', null, () => {
          this.get("selectedItems").removeObject(record);
          record.destroyRecord();
        });
      },

      edit() {
        this.patientAlert.edit(this.model.patient, {}, saved => {
          this.send('refreshModel');
        });
      },

      manageRelations(route) {
        var record = this.get("model");
        this.get("routing").transitionTo("hmis-app.records.manage-relations", [record.id]);
      }

    },
    themeInstance: _modelTableTheme.default.create({
      table: 'table'
      /* table:'table table-striped table-bordered table-condensed',*/

    }),
    propsToDisplay: [{
      name: 'picture',
      type: 'image'
    }, {
      name: 'firstName'
    }, {
      name: 'middleName'
    }, {
      name: 'lastName'
    }, {
      name: 'genderName',
      title: 'Gender'
    }, {
      name: 'bloodGroup',
      title: 'Blood Group'
    }, {
      name: 'dateOfBirthF',
      title: 'Date of Birth'
    }, {
      name: 'maritalStatus'
    }, {
      name: 'educationalLevel'
    }, {
      name: 'nationalIdType'
    }, {
      name: 'nationalIdNumber'
    }, {
      name: 'nationality'
    }, {
      name: 'countryOfResidence'
    }, {
      name: 'patientStatus'
    }, {
      name: 'modeOfPayment'
    }, {
      name: 'insuranceCompany.name',
      title: 'insuranceCompany'
    }, {
      name: 'insuranceProduct.name',
      title: 'insuranceProduct'
    }, {
      name: 'insuranceExpiryF',
      //type:'date',
      title: 'Insurance Expiry'
    }, {
      name: 'insuranceNumber'
    }, {
      name: 'postAddress'
    }, {
      name: 'physicalAddress'
    }, {
      name: 'contactEmail'
    }, {
      name: 'region'
    }, {
      name: 'town'
    }, {
      name: 'phoneNumber'
    }, {
      name: 'alternatePhoneNumber'
    }, {
      name: 'nameOfNextOfKin'
    }, {
      name: 'nextOfKinEmail'
    }, {
      name: 'nextOfKinEmail'
    }, {
      name: 'nextOfKinPhoneNumber'
    }, {
      name: 'nextOfKinAlternatePhoneNumber'
    }, {
      name: 'occupation'
    }, {
      name: 'emergencyContactName'
    }, {
      name: 'emergencyTypeOfContact'
    }, {
      name: 'emergencyContactTelephone'
    }],
    patientOccupationColumns: [{
      "propertyName": "occupation",
      'className': 'main-column'
    }, {
      'title': 'Status',
      "propertyName": "employmentStatus",
      'className': 'main-column'
    }, {
      "propertyName": "company",
      'className': 'main-column'
    }, {
      "propertyName": "contract",
      'className': 'main-column'
    }, {
      "propertyName": "position",
      'className': 'main-column'
    }, {
      "propertyName": "school",
      'className': 'main-column'
    }, {
      'title': 'Year of Registration',
      "propertyName": "yearRegistration",
      'className': 'main-column'
    }, {
      'title': 'Year of Completion',
      "propertyName": "yearCompletion",
      'className': 'main-column'
    }, {
      "title": "Actions",
      "component": "manageRow",
      'className': 'main-column'
    }]
  });

  _exports.default = _default;
});
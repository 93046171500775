define("hmis/templates/components/app-mini-workspace", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fvn7fefK",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"unless\",[[24,[\"pageError\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"app-mini-workspace amw-when-closed \",[28,\"if\",[[24,[\"isClosed\"]],\"is-closed\",\"is-opened\"],null],\" \",[28,\"if\",[[24,[\"onInit\"]],\"on-init\"],null]]]],[8],[0,\" \\n        \"],[7,\"button\",true],[10,\"class\",\"mini-workspace-toggle mwt-open\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"open\"],null]],[8],[0,\"\\n            \"],[1,[28,\"svg-jar\",[\"list\"],[[\"class\",\"width\",\"height\"],[\"icon\",\"100%\",\"100%\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"app-mini-workspace amw-when-opened \",[28,\"if\",[[24,[\"isClosed\"]],\"is-closed\",\"is-opened\"],null],\" \",[28,\"if\",[[24,[\"onInit\"]],\"on-init\"],null]]]],[8],[0,\" \\n        \"],[7,\"button\",true],[10,\"class\",\"mini-workspace-toggle mwt-close\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"close\"],null]],[8],[0,\"\\n            \"],[1,[28,\"svg-jar\",[\"cancel-times\"],[[\"class\",\"width\",\"height\"],[\"icon\",\"100%\",\"100%\"]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[14,1],[0,\" \\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/app-mini-workspace.hbs"
    }
  });

  _exports.default = _default;
});
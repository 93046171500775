define("hmis/components/type-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    type: "add",
    isAdd: Ember.computed('type', function () {
      return this.get("type") != "edit";
    }),
    actions: {// addHealthCondition(item){
      //     var record = this.get("record")
      //     record.get("chronicHealthConditions").pushObject(item);
      // },
      // removeHealthCondition(item)
      // {
      //     this.get("record.chronicHealthConditions").popObject(item);
      // },
      // addHabit(item){
      //     var record = this.get("record")
      //     record.get("habits").pushObject(item);
      // },
      // removeHabit(item)
      // {
      //     var record = this.get("record")
      //     record.get("habits").popObject(item);
      // },
    }
  });

  _exports.default = _default;
});
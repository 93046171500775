define("hmis/models/visit", ["exports", "ember-data", "ember-data/relationships", "hmis/utils/date-util", "hmis/mixins/patient-property-model"], function (_exports, _emberData, _relationships, _dateUtil, _patientPropertyModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend(_patientPropertyModel.default, {
    date: _emberData.default.attr(),
    admissionType: _emberData.default.attr(),
    purpose: (0, _relationships.belongsTo)('visit-purpose', {
      async: false
    }),
    purposeId: _emberData.default.attr(),
    purposeName: _emberData.default.attr(),
    status: (0, _relationships.belongsTo)('visit-status', {
      async: false
    }),
    statusId: _emberData.default.attr(),
    statusName: _emberData.default.attr(),
    outcome: (0, _relationships.belongsTo)('visit-outcome', {
      async: false
    }),
    outcomeId: _emberData.default.attr(),
    outcomeName: _emberData.default.attr(),
    primaryInsurance: (0, _relationships.belongsTo)('insurance-product', {
      async: false
    }),
    billStatus: (0, _relationships.belongsTo)('bill-status', {
      async: false
    }),
    vitalBatchList: (0, _relationships.hasMany)('vital-batch', {
      async: false
    }),
    isOOP: _emberData.default.attr('boolean'),
    //is Out Of Pocket
    hasClaim: _emberData.default.attr(),
    createdAt: _emberData.default.attr(),
    queuedAt: _emberData.default.attr(),
    queueId: _emberData.default.attr(),
    patientId: _emberData.default.attr(),
    invoiceNumber: _emberData.default.attr(),
    departmentUnit: (0, _relationships.belongsTo)('department-unit', {
      async: false
    }),
    episodeId: _emberData.default.attr(),
    eventId: _emberData.default.attr(),
    outstandingBills: _emberData.default.attr(),
    opdQueuedId: _emberData.default.attr(),
    dateF: Ember.computed.alias('dateTimeF'),
    dateTimeF: Ember.computed('date', function () {
      return _dateUtil.default.formatToDateTimeString(this.get('date'));
    }) // bills:computed( 'id', 'status', 'billStatus',function() {
    //   return DS.PromiseArray.create({
    //       promise: this.get('store').query('transaction',
    //       {
    //         filter: {
    //           visit: this.get('id'),
    //           isInsurance: 0,
    //         }
    //       }).then(bills=>{
    //         return bills;
    //         // this.set('transactions', transactions.sortBy('isPaid'))
    //       })
    //     });
    // }),

  });

  _exports.default = _default;
});
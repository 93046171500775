define("hmis/components/forms/service-alert", ["exports", "hmis/mixins/form-alert"], function (_exports, _formAlert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_formAlert.default, {
    currentUser: Ember.inject.service(),
    ajaxRequest: Ember.inject.service(),
    modelName: 'department-service',
    serviceType: Ember.computed('model', function () {
      return this.get('store').findAll('service-type');
    }),
    department: Ember.computed('model', function () {
      return this.get('store').findAll('department');
    }),
    departmentUnit: Ember.computed('departmentVal', function () {
      return this.get('store').query('department-unit', {
        filter: {
          departmentId: this.get('departmentVal.id')
        }
      });
    }),
    actions: {
      getDepartmentUnit(item) {//this.set('department', item);
      }

    }
  });

  _exports.default = _default;
});
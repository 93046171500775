define("hmis/components/classic/c-select", ["exports", "hmis/mixins/classic-component"], function (_exports, _classicComponent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_classicComponent.default, {
    className: 'c-select',
    searchEnabled: false,
    actions: {
      inputChanged(event) {
        this.send('valueChanged', event.target.value);
      },

      onChange(item) {
        let action = this.onChange;
        if (action) action(item);else {
          this.set('selected', item);
        }
      }

    }
  });

  _exports.default = _default;
});
define("hmis/components/dashboard-skeleton", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dashboard-skeleton'],
    counts: ['', '', '', ''],
    charts: ['', '', '', '']
  });

  _exports.default = _default;
});
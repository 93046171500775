define("hmis/components/grouping-row", ["exports", "hmis/components/models-table/row-group-toggle"], function (_exports, _rowGroupToggle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _rowGroupToggle.default.extend({
    classNames: ['grouping-cell']
  });

  _exports.default = _default;
});
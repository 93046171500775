define("hmis/controllers/hmis-app/records/view-patient", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    routing: Ember.inject.service('-routing'),
    currentUser: Ember.inject.service(),
    mPerms: Ember.computed('currentUser.modularPermissions', function () {
      return this.get('currentUser.modularPermissions');
    }),
    oPerms: Ember.computed('currentUser.operationalPermissions', function () {
      return this.get('currentUser.operationalPermissions');
    }),
    propsToDisplay: [{
      name: 'picture',
      type: 'image'
    }, {
      name: 'firstName'
    }, {
      name: 'middleName'
    }, {
      name: 'lastName'
    }, {
      name: 'gender.name',
      title: 'Gender'
    }, {
      name: 'dateOfBirthF',
      title: 'Date of Birth'
    }, {
      name: 'maritalStatus'
    }, {
      name: 'educationalLevel'
    }, {
      name: 'nationalIdType'
    }, {
      name: 'nationalIdNumber'
    }, {
      name: 'nationality'
    }, {
      name: 'countryOfResidence'
    }, {
      name: 'patientStatus'
    }, {
      name: 'modeOfPayment'
    }, {
      name: 'insuranceCompany.name',
      title: 'insuranceCompany'
    }, {
      name: 'insuranceProduct.name',
      title: 'insuranceProduct'
    }, {
      name: 'insuranceExpiryF',
      //type:'date',
      title: 'Insurance Expiry'
    }, {
      name: 'insuranceNumber'
    }, {
      name: 'postAddress'
    }, {
      name: 'physicalAddress'
    }, {
      name: 'contactEmail'
    }, {
      name: 'region'
    }, {
      name: 'town'
    }, {
      name: 'phoneNumber'
    }, {
      name: 'alternatePhoneNumber'
    }, {
      name: 'nameOfNextOfKin'
    }, {
      name: 'nextOfKinEmail'
    }, {
      name: 'nextOfKinEmail'
    }, {
      name: 'nextOfKinPhoneNumber'
    }, {
      name: 'nextOfKinAlternatePhoneNumber'
    }, {
      name: 'occupation'
    }, {
      name: 'emergencyContactName'
    }, {
      name: 'emergencyTypeOfContact'
    }, {
      name: 'emergencyContactTelephone'
    }],
    actions: {
      back() {
        history.back();
      },

      edit() {
        var record = this.get("model");
        this.get("routing").transitionTo("hmis-app.records.edit-patient", [record.id]);
      },

      manageRelations(route) {
        var record = this.get("model");
        this.get("routing").transitionTo("hmis-app.records.manage-relations", [record.id]);
      }

    }
  });

  _exports.default = _default;
});
define("hmis/mixins/data-serializer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    keyForAttribute: function (attr) {
      if (attr == 'createdAt' || attr == 'updatedAt') return Ember.String.underscore(attr);else {
        return Ember.String.camelize(attr);
      }
    },

    keyForRelationship(key, relationship, method) {
      //going to
      if (relationship == 'belongsTo') return key + "Id";
      return key;
    },

    normalizeSingleResponse(store, primaryModelClass, payload, id, requestType) {
      // let model= primaryModelClass.modelName
      // payload[model]=payload['data'];
      // delete payload.data
      let newPayload = {
        meta: payload['meta'] ? payload['meta'] : {}
      };
      let modelName = primaryModelClass.modelName;
      newPayload[modelName] = [payload['data']]; // delete payload.data

      let modelRelationships = [];
      Ember.get(primaryModelClass, 'relationships').forEach(function (kind, field) {
        newPayload[field] = [];
        kind.forEach(function (elem, index) {
          modelRelationships.push({
            name: elem.name,
            relation: field,
            kind: elem.kind
          });
        });
      });
      let element = payload['data'];
      modelRelationships.forEach(relation => {
        let elemProp = element[relation.name];

        if (element[relation.name + '_JSProp']) {
          elemProp = element[relation.name + '_JSProp'];
          delete element[relation.name + '_JSProp'];
        }

        if (elemProp) {
          if (relation.kind == 'hasMany') {
            element[relation.name] = [];
            elemProp.forEach(elemPropItem => {
              if (elemPropItem.id) {
                newPayload[relation.relation].push(elemPropItem);
                element[relation.name].push(elemPropItem.id);
              }
            });
          } else {
            element[relation.name] = elemProp.id;
            newPayload[relation.relation].push(elemProp);
          }
        }
      }); // console.log('newPayload', newPayload);

      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },

    normalizeArrayResponse(store, primaryModelClass, payload, id, requestType) {
      let newPayload = {
        meta: payload['meta'] ? payload['meta'] : {}
      };
      let modelName = primaryModelClass.modelName;
      newPayload[modelName] = [];
      payload[modelName] = payload['data']; // delete payload.data

      let modelRelationships = [];
      Ember.get(primaryModelClass, 'relationships').forEach(function (kind, field) {
        newPayload[field] = [];
        kind.forEach(function (elem, index) {
          modelRelationships.push({
            name: elem.name,
            relation: field,
            kind: elem.kind
          });
        });
      });

      if (payload.data) {
        payload.data.forEach(element => {
          if (element.id) {
            modelRelationships.forEach(relation => {
              let elemProp = element[relation.name];

              if (element[relation.name + '_JSProp']) {
                elemProp = element[relation.name + '_JSProp'];
                delete element[relation.name + '_JSProp'];
              }

              if (elemProp) {
                if (relation.kind == 'hasMany') {
                  element[relation.name] = [];
                  elemProp.forEach(elemPropItem => {
                    if (elemPropItem.id) {
                      newPayload[relation.relation].push(elemPropItem);
                      element[relation.name].push(elemPropItem.id);
                    }
                  });
                } else {
                  element[relation.name] = elemProp.id;
                  newPayload[relation.relation].push(elemProp);
                }
              }
            });
            newPayload[modelName].push(element);
          }
        });
      } // console.log('newPayload', newPayload);


      return this._super(store, primaryModelClass, newPayload, id, requestType);
    },

    serializeIntoHash(data, type, record, options) {
      Ember.assign(data, this.serialize(record, options));
    }

  });

  _exports.default = _default;
});
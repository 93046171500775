define("hmis/templates/components/controls/active-serve-list-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fQ8FWHSg",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"active-serve-list margin-bottom-20\"],[8],[0,\"\\n\"],[4,\"unless\",[[24,[\"collapsed\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[11,\"class\",[29,[\"as-\",[28,\"if\",[true,\"grid\",\"list\"],null],\"-wrapper\"]]],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"items\"]]],null,{\"statements\":[[0,\"                \"],[1,[28,\"controls/active-serve-item\",null,[[\"item\",\"onClick\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"onActiveServeClick\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/active-serve-list-view.hbs"
    }
  });

  _exports.default = _default;
});
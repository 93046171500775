define("hmis/templates/hmis-app/records/patient/questionnaires/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VMfY2zAg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"app-toolbar\"],[8],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"onClick\",\"icon\"],[\"Back\",[28,\"action\",[[23,0,[]],\"back\"],null],\"back-fill\"]]],false],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"disabled\",\"icon\",\"onClick\"],[\"Revert changes\",[28,\"not\",[[24,[\"hasChange\"]]],null],\"undo\",[28,\"action\",[[23,0,[]],\"onRevert\"],null]]]],false],[0,\"\\n        \"],[1,[28,\"toolbar-item\",null,[[\"text\",\"waiting\",\"disabled\",\"icon\",\"onClick\"],[\"Save changes\",[24,[\"saving\"]],[28,\"not\",[[24,[\"hasChange\"]]],null],\"save\",[28,\"action\",[[23,0,[]],\"onSave\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[5,\"app-workspace\",[],[[\"@error\"],[[22,\"pageError\"]]],{\"statements\":[[0,\"\\n\"],[4,\"stats-container\",null,[[\"class\",\"fullHeight\",\"title\",\"message\",\"waiting\"],[\"full-height\",true,\"History Information\",\"[has  ]\",[24,[\"loading\"]]]],{\"statements\":[[0,\"\\n\"],[0,\"        \"],[7,\"div\",true],[10,\"class\",\"content content-list\"],[8],[0,\"\\n            \"],[1,[28,\"questionnaire/q-edit\",null,[[\"structure\",\"valueSet\",\"onChange\"],[[24,[\"model\",\"structure\"]],[24,[\"valueSet\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n        \\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/hmis-app/records/patient/questionnaires/edit.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/controllers/hmis-app/management-report/prescriptions-report", ["exports", "hmis/mixins/management-report-controller"], function (_exports, _managementReportController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_managementReportController.default, {
    reportType: 'prescriptions' // showFilterModal: true,

  });

  _exports.default = _default;
});
define("hmis/templates/configure", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tF4XBrye",
    "block": "{\"symbols\":[],\"statements\":[[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"row\"],[10,\"style\",\"display: inline-block;\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"main-app-bar-wormhole\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"app-layout\"],[8],[0,\"\\n    \"],[1,[28,\"notification-container\",null,[[\"position\"],[\"top-right\"]]],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"app-header\"],[8],[0,\"\\n      \"],[1,[22,\"hmis-top-bar\"],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"app-menu \",[28,\"if\",[[24,[\"sideMenuCollapsed\"]],\"app-menu-collapsed\",\"\"],null]]]],[8],[0,\"\\n      \"],[1,[28,\"configure-side-menu\",null,[[\"class\",\"isCollaped\",\"currentPathHook\"],[\"full-height\",[24,[\"sideMenuCollapsed\"]],[24,[\"currentPathHook\"]]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"app-breadcrumbs\"],[8],[0,\"\\n      \"],[1,[28,\"bread-crumbs\",null,[[\"tagName\",\"outputStyle\",\"linkable\"],[\"ol\",\"bootstrap\",true]]],false],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n\\n\"],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n  \"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/configure.hbs"
    }
  });

  _exports.default = _default;
});
define("hmis/templates/components/controls/row-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0gesDRyl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"li-actions\"],[11,\"style\",[29,[\"min-width:\",[22,\"minWidth\"]]]],[8],[0,\"\\n    \"],[4,\"if\",[[24,[\"view\"]]],null,{\"statements\":[[0,\" \"],[7,\"button\",true],[10,\"class\",\"outline-button li-button green-outline\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"clicked\"]],\"onView\"],null]],[8],[1,[22,\"viewText\"],false],[9]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"if\",[[24,[\"edit\"]]],null,{\"statements\":[[0,\" \"],[7,\"button\",true],[10,\"class\",\"outline-button li-button blue-outline\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"clicked\"]],\"onEdit\"],null]],[8],[1,[22,\"editText\"],false],[9]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"if\",[[24,[\"delete\"]]],null,{\"statements\":[[0,\" \"],[7,\"button\",true],[10,\"class\",\"outline-button li-button red-outline\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[24,[\"clicked\"]],\"onDelete\"],null]],[8],[1,[22,\"deleteText\"],false],[9]],\"parameters\":[]},null],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "hmis/templates/components/controls/row-manager.hbs"
    }
  });

  _exports.default = _default;
});